import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';
import isEmpty from "util/isEmpty"



const saveBroadbandPlans = planDetails => ({
    type: 'SAVE_BROADBAND_PLANS',
    planDetails,
});

const error = error => ({
    type: 'ERROR',
    error,
});

const newPlan = planDetails => ({
    type: 'NEW_BROADBAND_PLAN',
    planDetails,
});

const updatePlan = planDetails => ({
    type: 'UPDATE_BROADBAND_PLAN',
    planDetails,
})

const saveSelectedPlanOnState = planDetails => ({
    type: 'SET_SELECTED_BROADBAND_PLANS',
    planDetails,
})


const resetBroadbandPlans = () => ({
    type: 'REST_BROADBAND_PLANS',
});

const deleteBroadbandPlan = () => ({
    type: 'DELETE_BROADBAND_PLANS',
})

const setToDefaultStatus = () => ({
    type: 'REST_BROADBAND_PLANS_CREATE_STATUS',
})


const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const clearErrors = (data) => dispath => {

    dispath(error(null));
}

export const clearAll = () => dispath => {

    dispath(resetBroadbandPlans());
    dispath(error(null));

}


export const setCreateBroadbandStatusToDefault = () => dispath => {

    dispath(setToDefaultStatus());
}


// Get All Broadband Plans
export const getAllBroadbandPlanDetails = () => dispath => {

    axios.get(`${apidetails.API_URL}plansBroadband`)
        .then(res => {
            dispath(saveBroadbandPlans(res.data))
        })
        .catch(err => {
            dispath(error(!isEmpty(err.response.data.message) ? err.response.data.message : 'ERROR'));
        });

}

// Delete Broadband Plans
export const deleteBroadbandPlans = (id) => dispath => {
    axios.delete(`${apidetails.API_URL}plansBroadband/${id}`)
        .then(res => {
            dispath(deleteBroadbandPlan());
        })
        .catch(err => {
            dispath(error(err.response.data.message || 'ERROR'));
        });
}

// Create New Broadband Fee Record 
export const createBroadbandPlan = (data) => dispath => {

    axios.post(`${apidetails.API_URL}plansBroadband`, qs.stringify(data), config)
        .then(res => {
            dispath(newPlan(res.data.result));
        })
        .catch(err => {
            dispath(error(err.response.data.message || 'ERROR'));
        });
}

// Update existing Broadband Fee Record 
export const updateBroadbandPlan = (data) => dispath => {

    axios.put(`${apidetails.API_URL}plansBroadband`, qs.stringify(data), config)
        .then(res => {
            dispath(updatePlan(res.data.result));
        })
        .catch(err => {
            dispath(error(err.response.data.message || 'ERROR'));
        });
}

export const setSelectedBroadbandPlan = (data) => dispath => {
    dispath(saveSelectedPlanOnState(data))
}

export const searchBroadbandPlanRecords = (data) => dispath => {

    axios.post(apidetails.API_URL + `plansBroadband/` + 'search', qs.stringify(data), config)
        .then(res => {

            dispath(saveBroadbandPlans(res.data));

        })
        .catch(err => {

            dispath(error(err.response.data.message || 'ERROR'));

        });
}

export const getBroadbandPlanById = (id) => dispath => {

    axios.get(apidetails.API_URL + `plansBroadband/${id}`,)
        .then(res => {

            dispath(saveSelectedPlanOnState(res.data.result[0]));

        })
        .catch(err => {

            dispath(error(err.response.data.message || 'ERROR'));

        });
}