import React from "react";
import { connect } from "react-redux";
import { Breadcrumb, Divider, Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, InputNumber, DatePicker, Tag, Collapse, Upload, Icon, Tabs } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getRecords, searchRecords, updateSingleRecord, createRecord, deleteRecord, uploadRecords, wipeAndUploadRecords, clearError } from "appRedux/actions/admin/signupScripts";
import { getRetailerList } from "appRedux/actions/admin/retailers";
// import "./index.css";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import exportToCSV from 'util/exportToCSV'
import swal from 'sweetalert2';

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const { TextArea } = Input;
const { TabPane } = Tabs;

const moduleName = "Signup Scripts"
const moduleNameUPPER = "SIGNUPSCRIPTS"

const showHeader = true;
const pagination = { position: 'bottom' };

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

class ManagePDFs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editingKey: '',
            recordtoEdit: null,
            recordtoDelete: null,
            formFunction: null,
            isAddingUpdating: false,
            isSearching: false,
            recordsEdited: false,
            expand: false,
            dataSource: [],
            addingNewRecord: false,
            showAddEditPanel: false,
            showSearchPanel: true,
            showWarning: false,
            warningMessage: "",
            showSuccess: false,
            successMessage: "",
            showError: false,
            errorMessage: "",
            showWaiting: false,
            showWaitingOkButton: false,
            bordered: true,
            loading: false,
            pagination,
            size: 'middle',
            title: undefined,
            showHeader,
            footer: undefined,
            rowSelection: {},
            singleRecordDetails: {},
            fileList: [],
            fieldState: {
                'id': false,
                'is_active': false,
                'retailer': false,
                'script_type': false,
                'script': false,
                'section': false,
                'script_category': false,
                'is_mandatory': false,
                'mandatory_answer': false,
                'other_notes': false,
                'states': false,
                'customer_type': false,
                'fuel_type': false,
                'product_names': false,
                'move_in': false,
                'solar': false,
                'concession': false,
                'life_support': false,
                'created': false,
                'updated': false,
            },
        };
        this.columns = [
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 150,
                render: (text, record) => {
                    const { editingKey } = this.state;
                    const editable = this.isEditing(record);
                    return (
                        <span>
                            <center>
                                <a onClick={() => this.viewRecord(record)}>
                                    <i className="icon icon-search-new" />
                                </a>
                                <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.editRecord(record)}>
                                    <i className="icon icon-edit" />
                                </a>
                                <a style={{ marginLeft: 10, color: "#52c41a" }} disabled={editingKey !== ''} onClick={() => this.duplicateRecord(record)}>
                                    <i className="icon icon-copy" />
                                </a>
                                <a style={{ marginLeft: 10, color: "#f5222d" }} disabled={editingKey !== ''} onClick={() => this.confirmDeleteRecord(record)}>
                                    <i className="icon icon-close-circle" />
                                </a>
                            </center>
                        </span>
                    );
                },
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 150,
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: 'Created Date',
                dataIndex: 'created',
                key: 'created',
                align: 'center',
                width: 150,
                sorter: (a, b) => moment(a.created, "YYYY-MM-DDTHH:mm:ss.SSSZ") - moment(b.created, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
                render: (text, record) => {
                    return <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"geekblue"} key={text}>
                        {moment(text).format('YYYY-MM-DD h:mm:ss A')}
                    </Tag>
                }
            },
            {
                title: 'Retailer',
                dataIndex: 'retailer',
                key: 'retailer',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.retailer.localeCompare(b.retailer) },
            },
            {
                title: 'Script',
                dataIndex: 'script',
                key: 'script',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.script.localeCompare(b.script) },
            },
            {
                title: 'Script Category',
                dataIndex: 'script_category',
                key: 'script_category',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.script_category.localeCompare(b.script_category) },
            },
            {
                title: 'States',
                dataIndex: 'states',
                key: 'states',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.states.localeCompare(b.states) },
            },
            {
                title: 'Customer Type',
                dataIndex: 'customer_type',
                key: 'customer_type',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.customer_type.localeCompare(b.customer_type) },
            },
            {
                title: 'Fuel Type',
                dataIndex: 'fuel_type',
                key: 'fuel_type',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.fuel_type.localeCompare(b.fuel_type) },
            },
            {
                title: 'Product Names',
                dataIndex: 'product_names',
                key: 'product_names',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.product_names.localeCompare(b.product_names) },
            },
        ];

    }

    isEditing = record => record.key === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    handleImportFromCSV = (info) => {

        // this.setState({
        //     showWaiting: true,
        // })

        swal.fire({
            title: "Please Wait..",
            text: `System is currently uploading and processing your file - ${info.file.name}`,
            showConfirmButton: this.state.showWaitingOkButton,
        });

        const data = new FormData();

        data.append('file', info.file);

        this.props.uploadRecords(data);

        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            console.log(`${info.file.name} file upload failed.`);
        }


    };

    handleWipeImportFromCSV = (info) => {

        swal.fire({
            title: "Please Wait..",
            text: `System is currently uploading and processing your file - ${info.file.name}`,
            showConfirmButton: this.state.showWaitingOkButton,
        });

        const data = new FormData();

        data.append('file', info.file);

        this.props.wipeAndUploadRecords(data);

        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            console.log(`${info.file.name} file upload failed.`);
        }


    };

    handleExportToCSV = (e) => {

        var dataToExport = this.state.dataSource;

        dataToExport.map((record, key) => {
            record.created = moment(record.created, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("YYYY-MM-DD HH:mm:ss");
        });


        const fileName = `${moduleNameUPPER}_` + moment().format("DDMMYYYY_HHmmss");

        exportToCSV(fileName, dataToExport);
    };

    disableAllFields = () => {

        this.setState({
            fieldState: {
                'id': true,
                'is_active': true,
                'retailer': true,
                'script_type': true,
                'script': true,
                'section': true,
                'script_category': true,
                'is_mandatory': true,
                'mandatory_answer': true,
                'other_notes': true,
                'states': true,
                'customer_type': true,
                'fuel_type': true,
                'product_names': true,
                'move_in': true,
                'solar': true,
                'concession': true,
                'life_support': true,
                'created': true,
                'updated': true,
            }
        })
    };

    enableAllFields = () => {

        this.setState({
            fieldState: {
                'id': false,
                'is_active': false,
                'retailer': false,
                'script_type': false,
                'script': false,
                'section': false,
                'script_category': false,
                'is_mandatory': false,
                'mandatory_answer': false,
                'other_notes': false,
                'states': false,
                'customer_type': false,
                'fuel_type': false,
                'product_names': false,
                'move_in': false,
                'solar': false,
                'concession': false,
                'life_support': false,
                'created': false,
                'updated': false,
            }
        })
    };

    duplicateRecord(record) {

        this.enableAllFields();

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    }

    editRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.enableAllFields();

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Update",
            fieldState: {
                ...({ created: true })
            }
        });
    }

    viewRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "View",
        });
        this.disableAllFields();
    }

    confirmDeleteRecord = (record) => {
        this.setState({ recordtoDelete: record.id });
        this.setState({
            showWarning: true,
            warningMessage: `Are you sure you want delete record ID ${record.id}?`,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    onCancelDelete = () => {
        this.setState({ recordtoDelete: null });
        this.setState({
            showWarning: false,
            warningMessage: ``,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    handleDelete = () => {
        this.state.dataSource = [];

        this.props.deleteRecord(this.state.recordtoDelete);
        console.log("Delete Record");
    };

    onConfirm = () => {
        this.setState({
            showSuccess: false,
            showError: false,
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleAdd = () => {
        this.handleReset();
        this.enableAllFields();
        this.setState({
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    };

    removeSearchValuesFromPayload = (values) => {

        delete values.txtStateSearch;
        delete values.txtCustTypeSearch;
        delete values.txtFuelTypeSearch;
        delete values.txtRetailerSearch;

        return values;
    };

    handleAddRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (!err) {
                values = this.removeSearchValuesFromPayload(values);

                values.created = moment(values.created, "DD-MM-YYYY").format("DD/MM/YYYY");

                if (values.created == "Invalid date")
                    values.created = moment().format("DD/MM/YYYY");

                values.updated = moment(values.updated, "DD-MM-YYYY").format("DD/MM/YYYY");

                if (values.updated == "Invalid date")
                    values.updated = moment().format("DD/MM/YYYY");

                if (values.states.length > 0)
                    values.states = JSON.stringify(values.states);
                else
                    values.states = "";

                if (values.customer_type.length > 0)
                    values.customer_type = JSON.stringify(values.customer_type);
                else
                    values.customer_type = "";

                if (values.fuel_type.length > 0)
                    values.fuel_type = JSON.stringify(values.fuel_type);
                else
                    values.fuel_type = "";

                this.setState({
                    isAddingUpdating: true,
                    showSearchPanel: false,
                });
                this.state.dataSource = [];
                this.props.createRecord(values);
            }


        });
    };

    handleUpdateRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            delete values.created;

            values.id = this.state.singleRecordDetails.id;

            values.created = moment(values.created, "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:ss");

            if (values.created == "Invalid date")
                values.created = moment().format("YYYY-MM-DD HH:mm:ss");

            values.updated = moment(values.updated, "DD-MM-YYYY").format("YYYY-MM-DD HH:mm:ss");

            if (values.updated == "Invalid date")
                values.updated = moment().format("YYYY-MM-DD HH:mm:ss");

            if (values.states.length > 0)
                values.states = JSON.stringify(values.states);
            else
                values.states = "";

            if (values.customer_type.length > 0)
                values.customer_type = JSON.stringify(values.customer_type);
            else
                values.customer_type = "";

            if (values.fuel_type.length > 0)
                values.fuel_type = JSON.stringify(values.fuel_type);
            else
                values.fuel_type = "";

            values = this.removeSearchValuesFromPayload(values);

            this.setState({
                isAddingUpdating: true,
                showSearchPanel: false,
            });
            this.state.dataSource = [];
            this.props.updateSingleRecord(values);
        });
    };

    onAddUpdateCancel = () => {
        this.setState({
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleClearSearch = () => {
        this.handleReset();
    };

    componentDidMount() {
        this.props.getRetailerList();
        this.props.getRecords();
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log(values);

            const filtersForSearch = {
                ...(values.txtRetailerSearch != "-1" && { retailer: values.txtRetailerSearch }),
                ...(values.txtStateSearch != "-1" && { states: values.txtStateSearch }),
                ...(values.txtCustTypeSearch != "-1" && { customer_type: values.txtCustTypeSearch }),
                ...(values.txtFuelTypeSearch != "-1" && { fuel_type: values.txtFuelTypeSearch }),

            }
            this.setState({ isSearching: true });

            if (!isEmpty(filtersForSearch))
                this.props.searchRecords(filtersForSearch);
            else {
                this.props.getRecords();
                this.setState({ isSearching: false });
            }
        });
    };

    handleReset = () => {
        this.props.form.resetFields();

        this.setState({
            singleRecordDetails: null,
            showSearchPanel: true,
            showAddEditPanel: false,
        });
        this.setState({ recordtoEdit: null });

        this.props.getRecords();
    };

    componentWillReceiveProps(nextProps) {
        if (!isEmpty(nextProps.signupScriptsRecords)) {
            this.setState({ loading: true });

            this.state.dataSource = nextProps.signupScriptsRecords;

            this.setState({
                loading: false,
                isSearching: false
            });
        }
        else {
            this.setState({ loading: true });

            this.state.dataSource = [];

            this.setState({
                loading: false,
                isSearching: false
            });
        }

        if (!isEmpty(nextProps.recordUpdateStatus)) {
            if (nextProps.recordUpdateStatus.status == "Success") {
                this.setState({
                    successMessage: nextProps.recordUpdateStatus.message,
                    showSuccess: true,
                    showWarning: false,
                    warningMessage: "",
                });
                this.setState({
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                swal.close();
                console.log("New Update Request");
                this.props.getRecords();
            }
        }

        if (!isEmpty(nextProps.error)) {
            if (nextProps.error != "") {
                this.setState({
                    errorMessage: nextProps.error,
                    showError: true,
                    successMessage: "",
                    showSuccess: false,
                });
                this.setState({
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                console.log("Error");
                this.props.clearError();
                this.props.getRecords();
            }
        }
    }


    render() {

        let { dataSource } = this.state;
        const { fileList } = this.state;

        const uploadProps = {
            name: 'file',
            accept: '.csv',
            multiple: false,
            showUploadList: false,
            onChange: (this.handleImportFromCSV),
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        const uploadPropsWipe = {
            name: 'file',
            accept: '.csv',
            multiple: false,
            showUploadList: false,
            onChange: (this.handleWipeImportFromCSV),
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-main-content">
                <ContainerHeader title={`Manage ${moduleName}`} />
                <Row>
                    {this.state.showSearchPanel && <Col span={24}>
                        <Spin spinning={this.state.isSearching}>
                            <Card className="gx-card" title={`Search ${moduleName} Records`} style={{ maxHeight: "550px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                                >
                                    <Row>
                                        <Col lg={12} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="State" >
                                                    {getFieldDecorator('txtStateSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select State"
                                                        >
                                                            <Option value="-1">All States</Option>
                                                            <Option value="VIC">Victoria</Option>
                                                            <Option value="NSW">New South Wales</Option>
                                                            <Option value="ACT">Australian Capital Territory</Option>
                                                            <Option value="QLD">Queensland</Option>
                                                            <Option value="SA">South Australia</Option>
                                                            <Option value="WA">Western Australia</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Customer Type" >
                                                    {getFieldDecorator('txtCustTypeSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Options"
                                                        >
                                                            <Option value="-1">All Type</Option>
                                                            <Option value="RES">RES</Option>
                                                            <Option value="SME">SME</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Retailer" >
                                                    {getFieldDecorator('txtRetailerSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select a Retailer"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option value="-1">All Retailers</Option>
                                                            {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                                                return <option key={key} value={retailer.systemName}>{retailer.systemName}</option>;
                                                            })}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Fuel Type" >
                                                    {getFieldDecorator('txtFuelTypeSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Options"
                                                        >
                                                            <Option value="-1">All Types</Option>
                                                            <Option value="Dual">Dual Fuel</Option>
                                                            <Option value="Gas">Gas</Option>
                                                            <Option value="Elec">Elec</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            <Button type="primary" onClick={this.handleSearch}>
                                                Search
                                            </Button>
                                            <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                                                Clear
                                            </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    {this.state.showAddEditPanel && <Col span={24}>
                        <Spin spinning={this.state.isAddingUpdating}>
                            <Card className="gx-card" title={`${this.state.formFunction} ${moduleName} Details`} style={{ maxHeight: "650px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "500px", marginBottom: "20px" }}
                                >
                                    <Tabs type="card" defaultActiveKey={['1']}>
                                        <TabPane tab="Script Details" key="1" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>

                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Retailer" >
                                                            {getFieldDecorator('retailer', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.retailer : undefined,
                                                                rules: [{ required: true, message: 'Please select a Retailer!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.retailer}
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Retailer"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                >
                                                                    {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                                                        return <option key={key} value={retailer.systemName}>{retailer.systemName}</option>;
                                                                    })}
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Script Type" >
                                                            {getFieldDecorator('script_type', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.script_type : "",
                                                                rules: [{ required: true, message: 'Please enter Script Type!' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.script_type} style={{ width: '100%' }} placeholder="Enter Type" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Script" >
                                                            {getFieldDecorator('script', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.script : "",
                                                                rules: [{ required: true, message: 'Please enter Script!' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.script} style={{ width: '100%' }} placeholder="Enter Script" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Section" >
                                                            {getFieldDecorator('section', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.section : undefined,
                                                                rules: [{ required: true, message: 'Please select Section!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.section}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Section"
                                                                >
                                                                    <Option value="Billing and Marketing Details">Billing and Marketing Details</Option>
                                                                    <Option value="Concession Details">Concession Details</Option>
                                                                    <Option value="Consent Questions">Consent Questions</Option>
                                                                    <Option value="Identification Details">Identification Details</Option>
                                                                    <Option value="Life Support Details">Life Support Details</Option>
                                                                    <Option value="Move In Details">Move In Details</Option>
                                                                    <Option value="Plan Details">Plan Details</Option>
                                                                    <Option value="Rates">Rates</Option>
                                                                    <Option value="Secondary Contact Details">Secondary Contact Details</Option>
                                                                    <Option value="Solar">Solar</Option>
                                                                    <Option value="Terms and Conditions (Retailer)">Terms and Conditions (Retailer)</Option>
                                                                    <Option value="Terms and Conditions (Vendor)">Terms and Conditions (Vendor)</Option>
                                                                    <Option value="Wrap Up">Wrap Up</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Script Category" >
                                                            {getFieldDecorator('script_category', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.script_category : undefined,
                                                                rules: [{ required: true, message: 'Please select Option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.script_category}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Category"
                                                                >
                                                                    <Option value="Information">Information</Option>
                                                                    <Option value="Question">Question</Option>
                                                                    <Option value="Script">Script</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Is Mandatory" >
                                                            {getFieldDecorator('is_mandatory', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.is_mandatory : undefined,
                                                                rules: [{ required: true, message: 'Please select Option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.is_mandatory}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option value="Y">Yes</Option>
                                                                    <Option value="N">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Mandatory Answer" >
                                                            {getFieldDecorator('mandatory_answer', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.mandatory_answer : undefined,
                                                                rules: [{ required: false, message: 'Please select an Answer!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.mandatory_answer}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Answer"
                                                                >
                                                                    <Option value="N/A">Not Applicable</Option>
                                                                    <Option value="Yes">Yes</Option>
                                                                    <Option value="Yes/No">Yes/No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Other Notes" >
                                                            {getFieldDecorator('other_notes', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.other_notes : "",
                                                                rules: [{ required: false, message: 'Please enter a note!' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.other_notes} style={{ width: '100%' }} placeholder="Enter Note" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="States" >
                                                            {getFieldDecorator('states', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? JSON.parse(this.state.singleRecordDetails.states) : undefined,
                                                                rules: [{ required: true, message: 'Please select  States!' }],
                                                            })(
                                                                <Select
                                                                    mode="multiple"
                                                                    disabled={this.state.fieldState.states}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select States"
                                                                >
                                                                    <Option value="VIC">Victoria</Option>
                                                                    <Option value="NSW">New South Wales</Option>
                                                                    <Option value="ACT">Australian Capital Territory</Option>
                                                                    <Option value="QLD">Queensland</Option>
                                                                    <Option value="SA">South Australia</Option>
                                                                    <Option value="WA">Western Australia</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Product Name" >
                                                            {getFieldDecorator('product_names', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.product_names : "All",
                                                                rules: [{ required: true, message: 'Please enter the Product Name!' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.product_names} style={{ width: '100%' }} placeholder="Enter Product Name" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </TabPane>
                                        <TabPane tab="Script Options" key="2" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Customer Types" >
                                                            {getFieldDecorator('customer_type', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? JSON.parse(this.state.singleRecordDetails.customer_type) : undefined,
                                                                rules: [{ required: true, message: 'Please select Customer Type!' }],
                                                            })(
                                                                <Select
                                                                    mode="multiple"
                                                                    disabled={this.state.fieldState.customer_type}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Type"
                                                                >
                                                                    <Option value="RES">RES</Option>
                                                                    <Option value="SME">SME</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Fuel Types" >
                                                            {getFieldDecorator('fuel_type', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? JSON.parse(this.state.singleRecordDetails.fuel_type) : undefined,
                                                                rules: [{ required: true, message: 'Please select Fuel Type!' }],
                                                            })(
                                                                <Select
                                                                    mode="multiple"
                                                                    disabled={this.state.fieldState.customer_type}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Type"
                                                                >
                                                                    <Option value="Dual">Dual Fuel</Option>
                                                                    <Option value="Gas">Gas</Option>
                                                                    <Option value="Elec">Elec</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Transfer Type" >
                                                            {getFieldDecorator('move_in', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.move_in : undefined,
                                                                rules: [{ required: true, message: 'Please select Transfer Type!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.move_in}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Type"
                                                                >
                                                                    <Option value="Y/N">Both</Option>
                                                                    <Option value="Y">Move In</Option>
                                                                    <Option value="N">Transfer</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Solar Available" >
                                                            {getFieldDecorator('solar', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.solar : undefined,
                                                                rules: [{ required: true, message: 'Please select Option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.solar}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option value="Y/N">Both</Option>
                                                                    <Option value="Y">Yes</Option>
                                                                    <Option value="N">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Concession" >
                                                            {getFieldDecorator('concession', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.concession : undefined,
                                                                rules: [{ required: true, message: 'Please select Option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.move_in}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option value="Y/N">Both</Option>
                                                                    <Option value="Y">Yes</Option>
                                                                    <Option value="N">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Life Support" >
                                                            {getFieldDecorator('life_support', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.life_support : undefined,
                                                                rules: [{ required: true, message: 'Please select Option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.life_support}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option value="Y/N">Both</Option>
                                                                    <Option value="Y">Yes</Option>
                                                                    <Option value="N">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Active Status" >
                                                            {getFieldDecorator('is_active', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.is_active : "Y",
                                                                rules: [{ required: true, message: 'Please select status!' }],
                                                            })(
                                                                <Select
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Status"
                                                                >
                                                                    <Option value="Y">Active</Option>
                                                                    <Option value="N">Inactive</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {this.state.formFunction != "Add" &&
                                                <Row>
                                                    <Col lg={8} md={8} sm={12} xs={24}>
                                                        <div className="gx-form-row0">
                                                            <FormItem {...formItemLayout2} label="Created Date" >
                                                                {getFieldDecorator('created', {
                                                                    initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.created, "YYYY-MM-DD HH:mm:ss") : moment(),
                                                                    rules: [{ required: true, message: 'Please select Created Date!' }],
                                                                })(
                                                                    <DatePicker disabled={this.state.fieldState.created} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'} />
                                                                )}
                                                            </FormItem>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </TabPane>
                                    </Tabs>

                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            {this.state.formFunction == "Add" &&
                                                <Button type="primary" onClick={this.handleAddRecord}>
                                                    Add
                                                </Button>
                                            }
                                            {this.state.formFunction == "Update" &&
                                                <Button type="primary" onClick={this.handleUpdateRecord}>
                                                    Update
                                                </Button>
                                            }
                                            <Button style={{ marginLeft: 8 }} onClick={this.onAddUpdateCancel}>
                                                Cancel
                                            </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    <Col span={24}>
                        <Card className="gx-card" title={`${moduleName} Details`}>
                            <Button onClick={this.handleAdd} type="primary" className="gx-btn-cyan" style={{ marginBottom: 16 }}>
                                <Icon type="plus" />  Add New {moduleName} Details
                            </Button>
                            <Button onClick={this.handleExportToCSV} type="primary" style={{ marginBottom: 16, marginRight: 0, float: "right" }}>
                                <Icon type="download" /> Export Displayed Results
                            </Button>
                            <Upload {...uploadProps}>
                                <Button type="primary">
                                    <Icon type="upload" /> Add/Update by Uploading a CSV
                                </Button>
                            </Upload>
                            <Upload {...uploadPropsWipe}>
                                <Button type="danger" style={{ marginLeft: "10px" }}>
                                    <Icon type="upload" /> Wipe and Add by Uploading a CSV
                                </Button>
                            </Upload>
                            <Table
                                className="gx-table-responsive"
                                {...this.state}
                                pagination={{ pageSize: 10 }}
                                columns={columns}
                                dataSource={dataSource} />
                        </Card>
                    </Col>


                </Row>
                <SweetAlert show={this.state.showWaiting}
                    title={'Please wait..'}
                    buttons={false}
                />
                <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showError} error title={this.state.errorMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showWarning}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={this.state.warningMessage}
                    onConfirm={this.handleDelete}
                    onCancel={this.onCancelDelete}
                ></SweetAlert>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    retailerList: state.retailers.retailerList,

    signupScriptsRecords: state.signupScripts.resultList,
    recordUpdateStatus: state.signupScripts.recordUpdateStatus,
    error: state.signupScripts.error,
});

const mapDispatchToProps = dispath => ({

    getRetailerList: (data) => dispath(getRetailerList(data)),

    getRecords: () => dispath(getRecords()),
    searchRecords: (data) => dispath(searchRecords(data)),
    updateSingleRecord: (data) => dispath(updateSingleRecord(data)),
    createRecord: (data) => dispath(createRecord(data)),
    deleteRecord: (data) => dispath(deleteRecord(data)),
    uploadRecords: (data) => dispath(uploadRecords(data)),
    wipeAndUploadRecords: (data) => dispath(wipeAndUploadRecords(data)),

    clearError: () => dispath(clearError()),

});

const WrappedAdvancedSearch = Form.create()(ManagePDFs);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);