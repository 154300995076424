import { Alert } from 'antd'
import React from 'react'

const AlertMessage = ({ variant, message, description, showIcon }) => {
    return (
        <div>
            <Alert
                message={message ? message : ''}
                description={description ? description : ''}
                type={variant ? variant : "success"}
                showIcon={showIcon ? showIcon : false}
            />
        </div>
    )
}

export default AlertMessage