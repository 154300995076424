import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, Upload, Divider, Tooltip, Icon, Alert, Tag } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getLeadDataListNames, uploadLeadData } from "appRedux/actions/admin/leadData";
import { getLeadForNewDataPack, removeLeadForNewDataPack } from "appRedux/actions/admin/leadData";

import { getWashersAvailableCount, createNewDiallerPack, getDiallerPacks, updateDiallerPacks } from "appRedux/actions/admin/diallerPacks";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import * as apidetails from 'util/api';

const FormItem = Form.Item;
const Dragger = Upload.Dragger;
const Option = Select.Option;
const moment = extendMoment(Moment);
let retailerState = "";

const {TextArea} = Input;

//const title = () => 'Here is title';
const showHeader = true;
//const footer = () => 'Here is footer';
//const scroll = {y: 500};
const pagination = { position: 'bottom' };

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const EditableContext = React.createContext();

class EditableCell extends React.Component {

  getInput = () => {
    const {
      dataIndex,
      record,
    } = this.props;

    function onChange(checked) {
    }

    function handleChange(value) {
      retailerState = value.toString().split(',').join('|');
    }

    if (this.props.inputType === 'statusSelection') {
      return <Switch disabled={false} defaultChecked={record.packIssued == 1 ? true : false} onChange={onChange} />;
    }
    else if (this.props.inputType === 'notes') {
      return <TextArea style={{ minWidth: '150px' }} />;
    }
    else if(this.props.inputType === 'vendorDropDown') {
      return  <Select
                showSearch
                style={{ width: '100%', minWidth: 150 }}
                placeholder="Select Vendor"
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value="-1">Select</Option>
              </Select>;
    }
    return <Input style={{ minWidth: '150px' }} />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    function getInitialValue(dataIndex) {
      if (dataIndex == "packIssued") {
        return record[dataIndex] == 1 ? true : false;
      }
      else {
        return record[dataIndex];
      }
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0, width: '100%', display:'block',}}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: getInitialValue(dataIndex),
            })(this.getInput())}
          </Form.Item>
        ) : (
            children
          )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class ManageDataPacks extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showGenerateNewDataPackPanel: false,
      showNewDataPackDataListPanel: false,
      washersAvailableCount: null,
      newDataPackDataSource: [],
      isWashingAndExporting: false,

      fileList: [],
      showSuccess: false,
      successMessage: "",
      uploading: false,
      showUploadPanel: false,
      fields: {
        listName: {
          value: '-1',
        },
        newListName: {
          value: '',
        },
        source: {
          value: '',
        },
        reqextwash: {
          value: false,
        },
      },

      editingKey: '',
      isAdding: false,
      isSearching: false,
      recordsEdited: false,
      expand: false,
      dataSource: [],
      addingNewRecord: false,
      leadRecordIdToDelete: null,

      showWarning: false,
      warningMessage: "",
      showSuccess: false,
      successMessage: "",
      bordered: true,
      loading: true,
      pagination,
      size: 'middle',
      title: undefined,
      showHeader,
      footer: undefined,
      rowSelection: {},
    };
    this.columns = [
      
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        width: 60,
        render: (text, record) =>{
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <center>
                <EditableContext.Consumer>
                  {form => (
                    <a
                      onClick={() => this.save(form, record.key)}
                      
                    >
                      <i className="icon icon-check-circle-o"/>
                    </a>
                  )}
                </EditableContext.Consumer>
                <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                  <a style={{ marginLeft: 10 }}><i className="icon icon-close-circle"/></a>
                </Popconfirm>
              </center>
            </span>
          ) : (
            <span>
              <center>
                <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
                  <i className="icon icon-edit"/>
                </a>
                {/* <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.confirmDelete(record.id, record.postcode)}>
                  <i className="icon icon-trash"/>
                </a> */}
              </center>
            </span>
          );
        },
      },      
      {
        title: 'Vendor',
        dataIndex: 'vendor',
        key: 'vendor',
        editable: true,
        align: 'center',
        width: 200,  
      },
      {
        title: 'Pack Issued',
        dataIndex: 'packIssued',
        key: 'packIssued',
        align: 'center',
        width: 150,  
        editable: true,
        sorter: (a, b) => a.packIssued - b.packIssued,
        render: (text, record) => {
          return <Switch disabled={true} checked={text == 1 ? true : false} onChange={{}} />
        }
      },
      {
        title: 'Export ID',
        dataIndex: 'exportID',
        key: 'exportID',
        align: 'center',
        width: 100, 
        sorter: (a, b) => a.exportID - b.exportID,
      },
      {
        title: 'Created Date',
        dataIndex: 'createdDate',
        key: 'createdDate',
        align: 'center',
        width: 200, 
        sorter: (a, b) => a.createdDate - b.createdDate,
        render: (text, record) => {
          return text!="-" && <Tag color={'blue'} key={text}>
                  {text.toUpperCase()}
                </Tag>
        },
      },
      {
        title: 'Updated Date',
        dataIndex: 'updatedDate',
        key: 'updatedDate',
        align: 'center',
        width: 200, 
        sorter: (a, b) => a.updatedDate - b.updatedDate,
        render: (text, record) => {
          return text!="-" && <Tag color={'orange'} key={text}>
                  {text.toUpperCase()}
                </Tag>
        },
      },
      {
        title: 'Expiry Date',
        dataIndex: 'expiryDate',
        key: 'expiryDate',
        align: 'center',
        width: 200, 
        render: (text, record) => {
          return text!="-" && <Tag color={'red'} key={text}>
                  {text.toUpperCase()}
                </Tag>
        },
      },
      {
        title: 'Export Count',
        dataIndex: 'exportCount',
        key: 'exportCount',
        align: 'center',
        width: 80, 
      },
      {
        title: 'Dilable Count',
        dataIndex: 'dilableCount',
        key: 'dilableCount',
        align: 'center',
        width: 80, 
      },
      {
        title: 'Export File Name',
        dataIndex: 'exportFileName',
        key: 'exportFileName',
        align: 'center',
        width: 220, 
        render: (text, record) => {
          return <a
            target="_blank"
            href={apidetails.API_URL + "download/diallerPack/" + record.exportFileName}
          >
            {text}
          </a>
        },
      },
      {
        title: 'Wash File Name',
        dataIndex: 'washFileName',
        key: 'washFileName',
        align: 'center',
        width: 220, 
        render: (text, record) => {
          return <a
            target="_blank"
            href={apidetails.API_URL + "download/washReceipt/" + record.washFileName}
          >
            {text}
          </a>
        },
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
        align: 'center',
        width: 300,  
        editable: true,
        render: (text, record) => {
          return <p>{text}</p>
        },
      },
      // {
      //   title: 'Download',
      //   dataIndex: 'download',
      //   key: 'download',
      //   align: 'center',
      //   render: (text, record) => {
      //     return <a
      //       target="_blank"
      //       href={apidetails.API_URL + "download/diallerPack/" + record.exportFileName}
      //     >
      //       Download
      //     </a>
      //   },
      // },
    ];

    this.columnsDataPackLeads = [
      {
        title: 'Lead ID',
        dataIndex: 'leadID',
        key: 'leadID',
        align: 'center',
        sorter: (a, b) => a.leadID - b.leadID,
      },
      {
        title: 'Updated Date',
        dataIndex: 'updatedDate',
        key: 'updatedDate',
        align: 'center',
        sorter: (a, b) => a.updatedDate - b.updatedDate,
      },
      {
        title: 'List Name',
        dataIndex: 'listName',
        key: 'listName',
        align: 'center',
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        align: 'center',
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
        align: 'center',
      },
      {
        title: 'Street',
        dataIndex: 'street',
        key: 'street',
        align: 'center',
      },
      {
        title: 'Suburb',
        dataIndex: 'suburb',
        key: 'suburb',
        align: 'center',
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        align: 'center',
      },
      {
        title: 'Postcode',
        dataIndex: 'postcode',
        key: 'postcode',
        align: 'center',
      },
      {
        title: 'Mobile',
        dataIndex: 'phoneMobile',
        key: 'phoneMobile',
        align: 'center',
      },
      {
        title: 'Mobile Last Washed',
        dataIndex: 'phoneMobileLastWashed',
        key: 'phoneMobileLastWashed',
        align: 'center',
      },
      {
        title: 'Home',
        dataIndex: 'phoneHome',
        key: 'phoneHome',
        align: 'center',
      },
      {
        title: 'Home Last Washed',
        dataIndex: 'phoneHomeLastWashed',
        key: 'phoneHomeLastWashed',
        align: 'center',
      },
      {
        title: 'Work',
        dataIndex: 'phoneWork',
        key: 'phoneWork',
        align: 'center',
      },
      {
        title: 'Work Last Washed',
        dataIndex: 'phoneWorkLastWashed',
        key: 'phoneWorkLastWashed',
        align: 'center',
      },
    ];

  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }



      const newData = [...this.state.dataSource];
      const index = newData.findIndex(item => key === item.key);

      if (index > -1) {
        const item = newData[index];

        console.log(item);

        const valuesToUpdate = {
          exportID: item.exportID,
          notes: row.notes,
          packIssued: row.packIssued ? 1 : 0,
          vendorID: row.vendor
        }

        console.log(valuesToUpdate);

        this.setState({ loading: true });
        this.props.updateDiallerPacks(valuesToUpdate);

        retailerState = "";

        this.setState({
          editingKey: '',
          recordsEdited: true
        });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  // confirmDelete = (key) => {
  //   this.setState({ leadRecordIdToDelete: key });
  //   this.setState({ showWarning: true });

  // };

  // onCancelDelete = key => {
  //   this.setState({ leadRecordIdToDelete: null });
  //   this.setState({ showWarning: false });
  // };

  // handleDelete = () => {
  //   const deleteInfo = {
  //     id: this.state.leadRecordIdToDelete,
  //   }
  //   this.props.deleteDistributorTranslationList(deleteInfo);
  //   this.setState({ showWarning: false });
  // };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showGenerateNewDataPackPanel: false,
    });
    this.handleReset();
  };

  handleAdd = () => {
    this.props.getWashersAvailableCount();
    this.setState({
      showGenerateNewDataPackPanel: true,
    });
  };

  onGenerateDataPackCancel = () => {
    this.setState({
      showGenerateNewDataPackPanel: false,
      showNewDataPackDataListPanel: false,
    });
    this.handleReset();
  };

  handleListNameChange = (value) => {

    const { fields } = { ...this.state };
    const currentState = fields;

    currentState.listName.value = value;

    this.setState({ fields: currentState });

    if (value !== 0)
      this.showUploadPanel();
    else
      this.setState({ showUploadPanel: false });
  }

  handleNewListNameChange = (e) => {

    const { fields } = { ...this.state };
    const currentState = fields;

    currentState.newListName.value = e.target.value;
    this.setState({ fields: currentState });

    this.showUploadPanel();
  }

  handleSourceChange = (e) => {

    const { fields } = { ...this.state };
    const currentState = fields;

    currentState.source.value = e.target.value;

    this.setState({ fields: currentState });
  }

  handleRequireWashChange = (value) => {

    const { fields } = { ...this.state };
    const currentState = fields;

    currentState.reqextwash.value = value;

    this.setState({ fields: currentState });
  }

  showUploadPanel = () => {
    if (this.state.fields.listName.value === 0) {
      if (!isEmpty(this.state.fields.newListName.value)) {
        this.setState({ showUploadPanel: true });
      }
      else
        this.setState({ showUploadPanel: false });
    }
    else if (this.state.fields.listName.value !== -1) {
      this.setState({ showUploadPanel: true });
    }
    else {
      this.setState({ showUploadPanel: false });
    }
  }

  handleReset = () => {
    this.props.form.resetFields();
    this.props.getLeadDataListNames();
    this.props.getDiallerPacks();
    this.props.removeLeadForNewDataPack();
    this.setState({ 
      showUploadPanel: false,
      showGenerateNewDataPackPanel: false,
      showNewDataPackDataListPanel: false,
      newDataPackDataSource: [],
      isWashingAndExporting: false,
      washersAvailableCount: null,
    });

    const { fields } = { ...this.state };
    const currentState = fields;

    currentState.listName.value = '';
    currentState.source.value = '';
    currentState.reqextwash.value = false;
    currentState.newListName.value = '';

    this.setState({ fields: currentState });
  };

  uploadFile = (info) => {

    //console.log(info);
    this.setState({
      uploading: true,
    });

    const uploadInfo = {
      listId: this.state.fields.listName.value,
      source: this.state.fields.source.value,
      reqextwash: this.state.fields.reqextwash.value,
      csvData: null,
      userID: 0,
      fileType: 'Lead Data',
      fileName: null,
      listName: this.state.fields.newListName.value,
    }

    this.props.uploadLeadData(uploadInfo, info.file);
  };


  componentDidMount() {
    this.props.getLeadDataListNames();

    this.props.getWashersAvailableCount();
    this.props.getDiallerPacks();
  }

  washAndExportDataPack = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      const dataForExport = {
        listId: values.drpList,
        listcount: values.drpDataCount,
        vendorId: values.drpVendor,
        dataToWash: JSON.stringify(this.state.newDataPackDataSource),
      }

      this.setState({ isWashingAndExporting: true });
      this.props.createNewDiallerPack(dataForExport);
    });
  };


  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      const filtersForSearch = {
        listId: values.drpList,
        listcount: values.drpDataCount,
        toWash: true,
        state: values.drpState,
        // name: values.txtName,
        // accessLevel: values.drpAccessLevel,
      }

      this.setState({ isSearching: true });
      this.props.getLeadForNewDataPack(filtersForSearch);
    });
  };

  checkColoumnType = (field) => {
    switch (field) {
      case 'packIssued': return "statusSelection";
      case 'vendor': return "vendorDropDown";
      case 'notes': return "notes";
      default: return "text";
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.leadDataForNewDataPack)) {
      this.setState({ loading: true });
      this.state.newDataPackDataSource = [];
      let count = 1;
        nextProps.leadDataForNewDataPack.map((leadData, key) => {
          let record = {
            key: count.toString(),
            leadID: !isEmpty(leadData.leadId) ? leadData.leadId : "-",
            updatedDate: !isEmpty(leadData.updatedDate) ? leadData.updatedDate : "-",
            listName: !isEmpty(leadData.listName) ? leadData.listName : "-",
            firstName: !isEmpty(leadData.firstName) ? leadData.firstName : "-",
            lastName: !isEmpty(leadData.lastName) ? leadData.lastName : "-",
            street: !isEmpty(leadData.street) ? leadData.street : "-",
            suburb: !isEmpty(leadData.suburb) ? leadData.suburb : "-",
            state: !isEmpty(leadData.state) ? leadData.state : "-",
            postcode: !isEmpty(leadData.postcode) ? leadData.postcode : "-",
            phoneMobile: !isEmpty(leadData.phoneMobile) ? leadData.phoneMobile : "-",
            phoneHome: !isEmpty(leadData.phoneHome) ? leadData.phoneHome : "-",
            phoneWork: !isEmpty(leadData.phoneWork) ? leadData.phoneWork : "-",
            phoneMobileLastWashed: !isEmpty(leadData.phoneMobileLastWashed) ? moment(leadData.phoneMobileLastWashed).format('YYYY-MM-DD h:mm:ss A') : "-",
            phoneHomeLastWashed: !isEmpty(leadData.phoneHomeLastWashed) ? moment(leadData.phoneHomeLastWashed).format('YYYY-MM-DD h:mm:ss A') : "-",
            phoneWorkLastWashed: !isEmpty(leadData.phoneWorkLastWashed) ? moment(leadData.phoneWorkLastWashed).format('YYYY-MM-DD h:mm:ss A') : "-",
          };
          this.state.newDataPackDataSource.push(record);
          count++;
        });
        this.setState({ loading: false });
        this.setState({ isSearching: false });
        this.setState({ showNewDataPackDataListPanel: true });      
    }
    else {      
      this.setState({ loading: true });
      this.state.newDataPackDataSource = [];
      this.setState({ loading: false });
      this.setState({ isSearching: false });
      this.setState({ showNewDataPackDataListPanel: false });
    }

    if (!isEmpty(nextProps.recordUpdateStatus)) {
      if (nextProps.recordUpdateStatus.status == "Success") {
        this.setState({
          successMessage: nextProps.recordUpdateStatus.message,
          showSuccess: true,
        });
        this.props.getDiallerPacks();
        this.setState({
          isAdding: false,
          showGenerateNewDataPackPanel: false,
        });
      }
    }

    if (!isEmpty(nextProps.leadDataUploadStatus)) {
      if (nextProps.leadDataUploadStatus.status === 'Success') {
        this.setState({
          successMessage: nextProps.leadDataUploadStatus.message,
          showSuccess: true,
          uploading: false,
        });
      }
    }

    if (!isEmpty(nextProps.diallerPackGenerationStatus)) {
      try{
        if (nextProps.diallerPackGenerationStatus.status === 'Success') {
          this.setState({
            successMessage: nextProps.diallerPackGenerationStatus.message,
            showSuccess: true,
            uploading: false,
            isWashingAndExporting: false,
          });
          this.handleReset();
        }
      }
      catch(err){}
    }

    if (!isEmpty(nextProps.washersAvailable)) {
      if (nextProps.washersAvailable === 'Fetching') {
        this.setState({
          isSearching : true,
        });
      }
      else{
        this.setState({
        washersAvailableCount: nextProps.washersAvailable,
        isSearching : false,
      });
      }
    }

    if (!isEmpty(nextProps.diallerPackList)) {
      this.setState({ loading: true });
      this.state.dataSource = [];
      let count = 1;
      nextProps.diallerPackList.map((diallerPack, key) => {
        let record = {
          key: count.toString(),
          exportID: !isEmpty(diallerPack.exportID) ? diallerPack.exportID : "-",
          createdDate: !isEmpty(diallerPack.createdDate) ? moment(diallerPack.createdDate).format('YYYY-MM-DD h:mm:ss A') : "-",  
          updatedDate : !isEmpty(diallerPack.updatedDate) ? moment(diallerPack.updatedDate).format('YYYY-MM-DD h:mm:ss A') : "-",        
          exportCount: !isEmpty(diallerPack.exportCount) ? diallerPack.exportCount : "-",
          dilableCount: !isEmpty(diallerPack.dialableCount) ? diallerPack.dialableCount : "-",
          exportFileName: !isEmpty(diallerPack.exportFileName) ? diallerPack.exportFileName : "-",
          washFileName: !isEmpty(diallerPack.washFileName) ? diallerPack.washFileName : "-",
          notes: !isEmpty(diallerPack.notes) ? diallerPack.notes : "-",
          vendor: !isEmpty(diallerPack.vendorID) ? diallerPack.vendorID : "-",
          expiryDate: !isEmpty(diallerPack.createdDate) ? moment(diallerPack.createdDate).add(30,'days').format('YYYY-MM-DD h:mm:ss A') : "-",
          packIssued: diallerPack.packIssued,
        };
        this.state.dataSource.push(record);
        count++;
      });
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }
    else {
      this.setState({ loading: true });
      this.state.dataSource = [];
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }
  }


  render() {

    let { dataSource, newDataPackDataSource } = this.state;
    const { fileList } = this.state;

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columnsDataPackLeads = this.columnsDataPackLeads.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: this.checkColoumnType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          retailerList: this.props.retailerList,
          distributorList: this.props.distributorList,
          inputType: this.checkColoumnType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });


    const draggerProps = {
      name: 'file',
      multiple: true,
      onChange: (this.uploadFile),
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-main-content">
        <ContainerHeader title="Manage Data Packs" />
        <Row>
          {/* <Col span={24}>
            <Spin spinning={this.state.isSearching}>
              <Card className="gx-card" title="Search Retailer">
                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleSearch}
                >
                  <Row>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" htmlType="submit">Search</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                          Clear
                    </Button>
                      </center>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Spin>
          </Col> */}

          {!this.state.showGenerateNewDataPackPanel &&
            <Col span={24}>
              <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Generate New Data Pack
              </Button>
            </Col>
          }

          {this.state.showGenerateNewDataPackPanel && <Col span={24}>
            <Spin spinning={this.state.isSearching}>
              <Card className="gx-card" title="Generate New Data Pack">
                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleSearch}
                >
                  <Row>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Remaining Washers">
                          {getFieldDecorator('txtRemainingWashers', {
                            initialValue: this.state.washersAvailableCount,
                            rules: [{ required: false, message: '' }],
                          })(
                            <Input disabled />
                          )}
                        </FormItem>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Vendor">
                          {getFieldDecorator('drpVendor', {
                            rules: [{ required: true, message: 'Please select a Vendor!' }],
                          })(
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select a Vendor"
                            >
                              <Option value="1">Amrish</Option>
                              {/* {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                return <option key={key} value={retailer.id}>{retailer.retailerName}</option>;
                              })} */}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Data Count">
                          {getFieldDecorator('drpDataCount', {
                            rules: [{ required: true, message: 'Please select a Data Count!' }],
                          })(
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select a Count"
                            >
                              {!isEmpty(this.state.washersAvailableCount) && this.state.washersAvailableCount > 2 && <Option value="2">2</Option>}  
                              {!isEmpty(this.state.washersAvailableCount) && this.state.washersAvailableCount > 5 && <Option value="5">5</Option>}
                              {!isEmpty(this.state.washersAvailableCount) && this.state.washersAvailableCount > 10 && <Option value="10">10</Option>}
                              {!isEmpty(this.state.washersAvailableCount) && this.state.washersAvailableCount > 50 && <Option value="50">50</Option>}
                              {!isEmpty(this.state.washersAvailableCount) && this.state.washersAvailableCount > 100 && <Option value="100">100</Option>}
                              {!isEmpty(this.state.washersAvailableCount) && this.state.washersAvailableCount > 500 && <Option value="500">500</Option>}
                              {!isEmpty(this.state.washersAvailableCount) && this.state.washersAvailableCount > 1000 && <Option value="1000">1000</Option>}
                              {!isEmpty(this.state.washersAvailableCount) && this.state.washersAvailableCount > 2000 && <Option value="2000">2000</Option>}
                              {!isEmpty(this.state.washersAvailableCount) && this.state.washersAvailableCount > 5000 && <Option value="5000">5000</Option>}
                              {!isEmpty(this.state.washersAvailableCount) && this.state.washersAvailableCount > 7500 && <Option value="7500">7500</Option>}
                              {!isEmpty(this.state.washersAvailableCount) && this.state.washersAvailableCount > 10000 && <Option value="10000">10000</Option>}
                              {!isEmpty(this.state.washersAvailableCount) && this.state.washersAvailableCount > 15000 && <Option value="15000">15000</Option>}
                              {!isEmpty(this.state.washersAvailableCount) && this.state.washersAvailableCount > 20000 && <Option value="20000">20000</Option>}
                              {!isEmpty(this.state.washersAvailableCount) && this.state.washersAvailableCount > 25000 && <Option value="25000">25000</Option>}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Type of Data">
                          {getFieldDecorator('drpTypeOfData', {
                            initialValue: "1",
                            rules: [{ required: true, message: 'Please select a Data Type!' }],
                          })(
                            <Select
                              disabled
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select a Type of Data"
                            >
                              <Option value="0">All Lists</Option>
                              <Option value="1">Specific List</Option>
                              {/* {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                return <option key={key} value={retailer.id}>{retailer.retailerName}</option>;
                              })} */}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Lists">
                          {getFieldDecorator('drpList', {
                            rules: [{ required: true, message: 'Please select a List!' }],
                          })(
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select a List"
                            >
                              {!isEmpty(this.props.leadDataListDetails) && this.props.leadDataListDetails.map((list, key) => {
                                return <option key={key} value={list.listId}>{list.listName}</option>;
                              })}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="State" >
                          {getFieldDecorator('drpState', {
                            rules: [{ required: false, message: 'Please select a State!' }],
                          })(
                            <Select
                              showSearch
                              mode="multiple"
                              style={{ width: "100%" }}
                              placeholder="Select a State"
                            >
                              <Option value="VIC">VIC</Option>
                              <Option value="NSW">NSW</Option>
                              <Option value="QLD">QLD</Option>
                              <Option value="ACT">ACT</Option>
                              <Option value="SA">SA</Option>
                              <Option value="WA">WA</Option>
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" htmlType="submit">Generate Data Pack</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.onGenerateDataPackCancel}>
                          Cancel
                    </Button>
                      </center>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Spin>
          </Col>
          }

          {this.state.showNewDataPackDataListPanel &&
            <Col span={24}>
              <Card className="gx-card" title="New Data Pack Leads">
              <Spin spinning={this.state.isWashingAndExporting}>
                <Row>
                  <Col span={24}>
                    <EditableContext.Provider value={this.props.form}>
                      <Table
                        components={components}
                        rowClassName="editable-row"
                        className="gx-table-responsive"
                        {...this.state}
                        columns={columnsDataPackLeads}
                        dataSource={newDataPackDataSource} />
                    </EditableContext.Provider>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col span={24}>
                    <center>
                      <Button type="primary"onClick={this.washAndExportDataPack}>Wash and Export Data Pack</Button>
                      <Button style={{ marginLeft: 8 }} onClick={this.onGenerateDataPackCancel}>
                        Cancel
                      </Button>
                    </center>
                  </Col>
                </Row>
              </Spin>
              </Card>
            </Col>
          }

          <Col span={24}>
            <Card className="gx-card" title="All Generated Data Pack Details">

              <EditableContext.Provider value={this.props.form}>
                <Table
                  components={components}
                  rowClassName="editable-row"
                  className="gx-table-responsive"
                  {...this.state}
                  columns={columns}
                  dataSource={dataSource}
                  scroll={{ x: 1500 }} />
              </EditableContext.Provider>
            </Card>
          </Col>
        </Row>
        <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
        <SweetAlert show={this.state.showWarning}
          warning
          showCancel
          confirmBtnText="Yes Delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.handleDelete}
          onCancel={this.onCancelDelete}
        ></SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  leadDataListDetails: state.leadData.leadDataListDetails,
  leadDataUploadStatus: state.leadData.leadDataUploadStatus,

  leadDataForNewDataPack: state.leadData.leadDataForNewDataPack,

  washersAvailable: state.diallerPacks.washersAvailable,
  diallerPackList: state.diallerPacks.diallerPackList,
  diallerPackGenerationStatus: state.diallerPacks.diallerPackGenerationStatus,
  recordUpdateStatus: state.diallerPacks.recordUpdateStatus,
});

const mapDispatchToProps = dispath => ({
  uploadLeadData: (info, file) => dispath(uploadLeadData(info, file)),
  getLeadDataListNames: (data) => dispath(getLeadDataListNames(data)),

  getLeadForNewDataPack: (data) => dispath(getLeadForNewDataPack(data)),
  removeLeadForNewDataPack: (data) => dispath(removeLeadForNewDataPack(data)),

  getWashersAvailableCount: (data) => dispath(getWashersAvailableCount(data)),
  createNewDiallerPack: (data) => dispath(createNewDiallerPack(data)),
  getDiallerPacks: (data) => dispath(getDiallerPacks(data)),
  updateDiallerPacks: (data) => dispath(updateDiallerPacks(data)),
});

const WrappedAdvancedSearch = Form.create({
  onValuesChange(_, values) {
    //console.log(values);
  },
})(ManageDataPacks);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);