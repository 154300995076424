import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Tag, Input, Row, Button, Select, DatePicker } from "antd";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";
import IconWithTextCard from "components/Metrics/IconWithTextCard";

import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { getSalesDataPerHour } from "appRedux/actions/sales/salesInformation";

import isEmpty from "util/isEmpty";

import Widget from "components/Widget/index";

import randomColor from "randomcolor";

import { GoFlame } from 'react-icons/go';
import { TiFlash } from 'react-icons/ti';
import { FaTruck, FaAmbulance, FaTruckLoading } from 'react-icons/fa';
import { GiClockwiseRotation, GiWifiRouter, GiHealthNormal } from 'react-icons/gi';
import { BsWifi } from 'react-icons/bs'
import { RiHomeWifiLine } from 'react-icons/ri'

const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const RangePicker = DatePicker.RangePicker;

var momentTimezone = require('moment-timezone');

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class HourlySalesStats extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isFetchingData: false,
      threeWeekDaySaleStats: [],
      fiveWorkingDaysaleStats: [],
      salesPerHourData: [],
      salesSummaryData: {
        totalSales: 0,
        totalElec: 0,
        totalGas: 0,
        totalBroadbandSales: 0,
        totalBroadbandLeads: 0,
        totalHealthLeads: 0,
        totalHealthSales: 0
      },
      isSearching: false,
      showWarning: false,
      warningMessage: "",
      showSuccess: false,
      successMessage: "",
      startDate: null,
      endDate: null,
    };
  }

  componentDidMount() {

    this.setState({
      isFetchingData: true,
    })

    this.props.getSalesDataPerHour();
  }

  handleDateRangeChange = (dates, dateStrings) => {
    this.setState({
      startDate: dateStrings[0],
      endDate: dateStrings[1],
    })
  };

  enumerateDaysBetweenDates = (startDate, endDate) => {
    startDate = moment(startDate);
    endDate = moment(endDate);

    var now = startDate, dates = [];

    while (now.isBefore(endDate) || now.isSame(endDate)) {

      if (now.day() != 0) {
        dates.push(now.format('YYYY-MM-DD'));
      }

      //dates.push(now.format('YYYY-MM-DD'));
      now.add(1, 'days');
    }
    return dates;
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      const filtersForSearch = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
      }
      this.setState({
        isFetchingData: true,
        isSearching: true,
        salesPerHourData: [],
        threeWeekDaySaleStats: [],
        fiveWorkingDaysaleStats: [],
        salesSummaryData: {
          totalSales: 0,
          totalElec: 0,
          totalGas: 0,
          totalBroadbandSales: 0,
          totalBroadbandLeads: 0,
          totalHealthLeads: 0,
          totalHealthSales: 0,
        },
      });
      this.props.getSalesDataPerHour(filtersForSearch);
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
  };

  componentWillReceiveProps(nextProps) {

    if (!isEmpty(nextProps.fiveWorkingDaysaleStats)) {
      this.setState({
        fiveWorkingDaysaleStats: nextProps.fiveWorkingDaysaleStats,
        isFetchingData: false,
        isSearching: false,
      });
    }

    if (!isEmpty(nextProps.salesPerHourList)) {
      this.setState({
        salesPerHourData: nextProps.salesPerHourList,
        isFetchingData: false,
        isSearching: false,
      });
    }

    if (!isEmpty(nextProps.threeWeekDaySaleStats)) {
      this.setState({
        threeWeekDaySaleStats: nextProps.threeWeekDaySaleStats,
        isFetchingData: false,
        isSearching: false,
      });
    }

    if (!isEmpty(nextProps.salesSummaryData)) {
      this.setState({
        salesSummaryData: nextProps.salesSummaryData,
      });
    }
  }

  render() {


    const { getFieldDecorator } = this.props.form;

    var fiveDayWorkingDayList = []

    if (!isEmpty(this.state.startDate)) {
      fiveDayWorkingDayList = this.enumerateDaysBetweenDates(momentTimezone(this.state.startDate, "YYYY/MM/DD").add(-5, "days"), momentTimezone(this.state.startDate, "YYYY/MM/DD"));
    }
    else {
      fiveDayWorkingDayList = this.enumerateDaysBetweenDates(momentTimezone().add(-5, "days"), momentTimezone());
    }



    return (
      <div className="gx-main-content">
        <ContainerHeader title="Sales Information" />
        <Row>
          <Col span={24}>
            <Spin spinning={this.state.isSearching}>
              <Card className="gx-card" title="Search Sales Information">
                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleSearch}
                >
                  <Row>
                    <Col lg={18} md={18} sm={18} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Sales Date Range">
                          {getFieldDecorator('drpDateRange', {
                            rules: [{ required: false, message: 'Please select a Date Range' }],
                          })(
                            <RangePicker className="gx-w-100"
                              ranges={{ Today: [moment(), moment()], 'This Month': [moment(), moment().endOf('month')] }}
                              format="YYYY/MM/DD"
                              defaultValue={[moment(), moment()]}
                              onChange={this.handleDateRangeChange}
                            />
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" htmlType="submit">Search</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                          Clear
                        </Button>
                      </center>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Spin>
          </Col>
          <Col span={24} className="summary-view">
            <Spin spinning={this.state.isFetchingData}>
              <Card className="gx-card" title="Sales Summary">
                <Row>
                  <Col xl={8} lg={8} md={8} sm={24} xs={24} className="gx-col-full">
                    <Row>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-col-full">
                        <Widget>
                          <div className="gx-media  gx-align-items-center gx-flex-nowrap">
                            <div className="gx-mr-lg-4 gx-mr-3">
                              <TiFlash color={"#1493C3"} size={40} /><GoFlame className="gx-mr-3 gx-ml-3" color={"#E59D2D"} size={40} /><GiWifiRouter className="gx-mr-3 gx-ml-3" color={"#26d16d"} size={40} /><GiHealthNormal className="gx-mr-3 gx-ml-3" color={"#d12626"} size={40} />
                            </div>
                            <div className="gx-media-body">
                              <h1 style={{ fontSize: 50 }} className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{this.state.salesSummaryData.totalSales}</h1>
                              <p className="gx-text-grey gx-mb-0">Total Sales</p>
                            </div>
                          </div>
                        </Widget>
                        {/* <IconWithTextCard icon="growth" iconColor="success" title={this.state.salesSummaryData.totalSales} subTitle="Total Accounts" /> */}
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={16} lg={16} md={16} sm={24} xs={24} className="gx-col-full">
                    <Row>
                      <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                        <Widget>
                          <div className="gx-media gx-align-items-center gx-flex-nowrap">
                            <div className="gx-mr-lg-4 gx-mr-3">
                              <TiFlash color={"#1493C3"} size={40} />
                            </div>
                            <div className="gx-media-body">
                              <h1 style={{ fontSize: 50 }} className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{this.state.salesSummaryData.totalElec}</h1>
                              <p className="gx-text-grey gx-mb-0">Electricity</p>
                            </div>
                          </div>
                        </Widget>
                        {/* <IconWithTextCard icon="growth" iconColor="primary" title={this.state.salesSummaryData.totalElec} subTitle="Elec Accounts" /> */}
                      </Col>
                      <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                        <Widget>
                          <div className="gx-media gx-align-items-center gx-flex-nowrap">
                            <div className="gx-mr-lg-4 gx-mr-3">
                              <GoFlame color={"#E59D2D"} size={40} />
                            </div>
                            <div className="gx-media-body">
                              <h1 style={{ fontSize: 50 }} className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{this.state.salesSummaryData.totalGas}</h1>
                              <p className="gx-text-grey gx-mb-0">Gas</p>
                            </div>
                          </div>
                        </Widget>
                      </Col>
                      <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                        <Widget>
                          <div className="gx-media gx-align-items-center gx-flex-nowrap">
                            <div className="gx-mr-lg-4 gx-mr-3">
                              <GiWifiRouter color={"#26d16d"} size={40} />
                            </div>
                            <div className="gx-media-body">
                              <h1 style={{ fontSize: 50 }} className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{this.state.salesSummaryData.totalBroadbandSales}</h1>
                              <p className="gx-text-grey gx-mb-0">Broadband</p>
                            </div>
                          </div>
                        </Widget>
                      </Col>
                      <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
                        <Widget>
                          <div className="gx-media gx-align-items-center gx-flex-nowrap">
                            <div className="gx-mr-lg-4 gx-mr-3">
                              <GiHealthNormal color={"#d12626"} size={40} />
                            </div>
                            <div className="gx-media-body">
                              <h1 style={{ fontSize: 50 }} className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{this.state.salesSummaryData.totalHealthSales}</h1>
                              <p className="gx-text-grey gx-mb-0">Health</p>
                            </div>
                          </div>
                        </Widget>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col>
          <Col span={24}>
            <Spin spinning={this.state.isFetchingData}>
              <Card className="gx-card" title="Sales Per Hour Information (sales = elec + gas)">
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={this.state.salesPerHourData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <XAxis dataKey="section" interval={"preserveStartEnd"} />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    {/* <Line type="monotone" dataKey="sales" stroke="#66000c" activeDot={{r: 12}}/> */}
                    {/* <Line type="monotone" dataKey="elec" stroke="#3293a8" activeDot={{r: 6}}/>
                    <Line type="monotone" dataKey="gas" stroke="#3257a8" activeDot={{r: 6}}/> */}
                    {
                      !isEmpty(this.state.salesPerHourData) && Object.keys(this.state.salesPerHourData[0]).map((category, key) => {
                        if (category != "type" && category != "section" && category != "elec" && category != "gas") {
                          return <Line type="monotone" name={category == "sales" ? "Total Sales" : category} dataKey={category}
                            stroke={randomColor({
                              luminosity: 'dark',
                              format: 'hex' // e.g. 'rgba(9, 1, 107, 0.6482447960879654)'
                            })} strokeWidth={1.5} activeDot={{ r: 6 }} />
                        }

                      })
                    }
                    {/* <Line type="monotone" dataKey="Phone" stroke="#3257a8" activeDot={{r: 6}}/> */}
                    {/* <Line type="monotone" dataKey="webleads" stroke="#3257a8" activeDot={{r: 6}}/>
                    <Line type="monotone" dataKey="finderleads" stroke="#3257a8" activeDot={{r: 6}}/> */}
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </Spin>
          </Col>
          <Col span={24}>
            <Spin spinning={this.state.isFetchingData}>
              <Card className="gx-card" title="Historical Sales per Hour Trail">
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={this.state.threeWeekDaySaleStats}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <XAxis dataKey="section" interval={"preserveStartEnd"} />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="thisWeek" name={'This Week'} stroke="#005295" strokeWidth={5} activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="lastWeek" name={'Last Week'} stroke="#01a900" strokeWidth={1.5} activeDot={{ r: 6 }} />
                    <Line type="monotone" dataKey="weekBeforeLast" name={'Week Before Last'} stroke="#5b3475" strokeWidth={1.5} activeDot={{ r: 4 }} />
                    <Line type="monotone" dataKey="twoWeeksBeforeLastDayTotalSales" name={'Two Weeks Before Last'} stroke="#ed1c25" strokeWidth={1.5} activeDot={{ r: 4 }} />
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </Spin>
          </Col>
          <Col span={24}>
            <Spin spinning={this.state.isFetchingData}>
              <Card className="gx-card" title="Historical Total Sales Trail">
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={this.state.threeWeekDaySaleStats}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <XAxis dataKey="section" interval={"preserveStartEnd"} />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="thisWeekCummulative" name={'This Week'} stroke="#005295" strokeWidth={5} activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="lastWeekCummulative" name={'Last Week'} stroke="#01a900" strokeWidth={1.5} activeDot={{ r: 6 }} />
                    <Line type="monotone" dataKey="weekBeforeLastCummulative" name={'Week Before Last'} stroke="#5b3475" strokeWidth={1.5} activeDot={{ r: 4 }} />
                    <Line type="monotone" dataKey="twoWeeksBeforeLastDayTotalSalesCummulative" name={'Two Weeks Before Last'} stroke="#ed1c25" strokeWidth={1.5} activeDot={{ r: 4 }} />
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </Spin>
          </Col>
          <Col md={24}>
            <Spin spinning={this.state.isFetchingData}>
              <Card className="gx-card" title="Hourly Sales Stats of Last 5 Working Days">
                <ResponsiveContainer width="100%" height={500}>
                  <LineChart data={this.state.fiveWorkingDaysaleStats}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <XAxis dataKey="section" interval={"preserveStartEnd"} />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey={`${fiveDayWorkingDayList[4]}`} name={`${fiveDayWorkingDayList[4]}`} stroke="#005295" strokeWidth={5} activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey={`${fiveDayWorkingDayList[3]}`} name={`${fiveDayWorkingDayList[3]}`} stroke="#01a900" strokeWidth={1.5} activeDot={{ r: 6 }} />
                    <Line type="monotone" dataKey={`${fiveDayWorkingDayList[2]}`} name={`${fiveDayWorkingDayList[2]}`} stroke="#5b3475" strokeWidth={1.5} activeDot={{ r: 4 }} />
                    <Line type="monotone" dataKey={`${fiveDayWorkingDayList[1]}`} name={`${fiveDayWorkingDayList[1]}`} stroke="#ed1c25" strokeWidth={1.5} activeDot={{ r: 4 }} />
                    <Line type="monotone" dataKey={`${fiveDayWorkingDayList[0]}`} name={`${fiveDayWorkingDayList[0]}`} stroke="#34cde6" strokeWidth={1.5} activeDot={{ r: 4 }} />
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </Spin>
          </Col>
          <Col md={24}>
            <Spin spinning={this.state.isFetchingData}>
              <Card className="gx-card" title="Total Sales Stats Last 5 Working Days">
                <ResponsiveContainer width="100%" height={500}>
                  <LineChart data={this.state.fiveWorkingDaysaleStats}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <XAxis dataKey="section" interval={"preserveStartEnd"} />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey={`Day4Total`} name={`${fiveDayWorkingDayList[4]}`} stroke="#005295" strokeWidth={5} activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey={`Day3Total`} name={`${fiveDayWorkingDayList[3]}`} stroke="#01a900" strokeWidth={1.5} activeDot={{ r: 6 }} />
                    <Line type="monotone" dataKey={`Day2Total`} name={`${fiveDayWorkingDayList[2]}`} stroke="#5b3475" strokeWidth={1.5} activeDot={{ r: 4 }} />
                    <Line type="monotone" dataKey={`Day1Total`} name={`${fiveDayWorkingDayList[1]}`} stroke="#ed1c25" strokeWidth={1.5} activeDot={{ r: 4 }} />
                    <Line type="monotone" dataKey={`Day0Total`} name={`${fiveDayWorkingDayList[0]}`} stroke="#34cde6" strokeWidth={1.5} activeDot={{ r: 4 }} />
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </Spin>
          </Col>
        </Row>
        <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
        <SweetAlert show={this.state.showWarning}
          warning
          showCancel
          confirmBtnText="Yes Delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.handleDelete}
          onCancel={this.onCancelDelete}
        ></SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fiveWorkingDaysaleStats: state.salesinformation.fiveWorkingDaysaleStats,
  threeWeekDaySaleStats: state.salesinformation.threeWeekDaySaleStats,
  salesPerHourList: state.salesinformation.salesPerHourList,
  salesSummaryData: state.salesinformation.salesSummaryData,
});

const mapDispatchToProps = dispath => ({
  getSalesDataPerHour: (data) => dispath(getSalesDataPerHour(data)),
});

const WrappedAdvancedSearch = Form.create()(HourlySalesStats);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);