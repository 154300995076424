import React from "react";
import {Route, Switch} from "react-router-dom";
import ManageUsers from "./ManageUsers";
import ManageToolsUsers from "./ManageToolsUsers";

const Users = ({match}) => (
  <Switch>
    <Route path={`${match.url}/manageUsers`} component={ManageUsers}/>
    <Route path={`${match.url}/manageToolsUsers`} component={ManageToolsUsers}/>
  </Switch>
);

export default Users;
