import React, { useState } from 'react'
import { Card, Col, Form, Input, Row, Button, Select, } from "antd";
import streetType from '../../../static/StreetTypes';
import getStateFromCode from '../../../util/getStateFromCode';
import { connect } from "react-redux";
import styles from './styles.module.less'

import { WidgetInput, Address } from 'react-addressfinder';
import { addressFinderKey } from '../../../util/AddressFinderSettings';
import { addressLine1Decode, addressLine2Decode } from '../../../util/addressLineDecode';
import { getUnitValue } from '../../../util/handleUnitValues';
import { getStreetTypeLabel, getStreetTypeValue } from '../../../util/handleStreetTypes';
import { getStateTitle } from '../../../util/handleState';
import { stringToCapitalCase } from '../../../util/handleString';
import { states } from '../../../static/formSupport';
import qs from 'querystring'
import axios from 'axios';
import * as apidetails from 'util/api';
import { validateAddress, getSuburbByPostcodeId } from '../../../appRedux/actions/tools/address';

const { Option } = Select;

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};


class AddressFinder extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            manualAddressInput: false,
            showSearchPanel: false,
            newLead: false,
            current: 0,
            addressDetail: {
                "Suburb_x0028_Supply_x0029_": { "_text": "" },//Werribee
                "Unit_x0028_Supply_x0029_": { "_text": "" },//2
                "UnitType_x0028_Supply_x0029_": { "_text": "" },//U
                "StreetNumber_x0028_Supply_x0029_": { "_text": "" },//65
                "StreetName_x0028_Supply_x0029_": { "_text": "" }, //Market
                "StreetType_x0028_Supply_x0029_": { "_text": "" }, //RD
                "Postcode_x0028_Supply_x0029_": { "_text": "" }, //3030
                "State_x0028_Supply_x0029_": { "_text": "" }, //VIC
                "state": { "_text": "" }, //Victoria
                "SupplyFullAddress": { "_text": "" },//2/65 Market Rd, Werribee VIC 3030, Australia
            },
            form: {},
            suburb: [],
            states: [],
            suburbList: {},
            validatedAddress: {},
            errorMessage: "",
            showError: false,
            newAddress: false,
            usePreviousAddress: false,
            previousAddress: ''
        }
    }

    setManualAddressInput = () => {

        this.setState({
            manualAddressInput: true
        })

    }

    setAutoAddressInput = () => {

        this.setState({
            manualAddressInput: false
        })

    }

    fetchAddress = () => {


        if (this.props.data.leadDetails && (this.props.data.leadDetails.SupplyFullAddress || this.state.usePreviousAddress) && !this.state.newAddress) {

            const addressDetails = {
                Suburb_x0028_Supply_x0029_: this.props.data.leadDetails.Suburb_x0028_Supply_x0029_,
                Unit_x0028_Supply_x0029_: this.props.data.leadDetails.Unit_x0028_Supply_x0029_,
                UnitType_x0028_Supply_x0029_: this.props.data.leadDetails.UnitType_x0028_Supply_x0029_,
                StreetNumber_x0028_Supply_x0029_: this.props.data.leadDetails.StreetNumber_x0028_Supply_x0029_,
                StreetName_x0028_Supply_x0029_: this.props.data.leadDetails.StreetName_x0028_Supply_x0029_,
                StreetType_x0028_Supply_x0029_: this.props.data.leadDetails.StreetType_x0028_Supply_x0029_,
                Postcode_x0028_Supply_x0029_: this.props.data.leadDetails.Postcode_x0028_Supply_x0029_,
                State_x0028_Supply_x0029_: this.props.data.leadDetails.State_x0028_Supply_x0029_,
                state: this.props.data.leadDetails.state,
                SupplyFullAddress: this.state.usePreviousAddress ? this.state.previousAddress : this.props.data.leadDetails.SupplyFullAddress,
            }

            this.props.validateAddress({ addressDetails: { ...addressDetails } })

            this.setState({ addressDetail: { ...addressDetails } })

        } else {

            this.props.validateAddress({ addressDetails: { ...this.state.addressDetail } })

        }


    }

    formatAddress = (data) => {

        let streetDetails = null;

        let unitDetails = null;

        let unit = null;

        if (data.line2) {

            unitDetails = addressLine1Decode(data.line1)

            streetDetails = addressLine2Decode(data.line2)

            unit = getUnitValue(unitDetails.type)

        } else {

            streetDetails = addressLine2Decode(data.line1)
        }

        const streetType = getStreetTypeValue(streetDetails.streetType)

        const stateTitle = getStateTitle(data.state)

        const suburb = stringToCapitalCase(data.suburb)

        const tempAddressObject = { ...this.state.addressDetails }
        tempAddressObject["Suburb_x0028_Supply_x0029_"] = { _text: suburb }
        tempAddressObject["State_x0028_Supply_x0029_"] = { _text: data.state }
        tempAddressObject["Postcode_x0028_Supply_x0029_"] = { _text: data.postcode }
        tempAddressObject["Unit_x0028_Supply_x0029_"] = { _text: unitDetails ? unitDetails.number : '-' };
        tempAddressObject["UnitType_x0028_Supply_x0029_"] = { _text: unitDetails ? unit : '-' }
        tempAddressObject["StreetNumber_x0028_Supply_x0029_"] = { _text: streetDetails.streetNumber }
        tempAddressObject["StreetName_x0028_Supply_x0029_"] = { _text: streetDetails.streetName }
        tempAddressObject["StreetType_x0028_Supply_x0029_"] = { _text: streetType }
        tempAddressObject["state"] = { _text: stateTitle }
        tempAddressObject["SupplyFullAddress"] = { _text: data.fullAddress }

        return tempAddressObject

    }


    onSelectAddress = (fullAddress, address) => {
 
        const addressData = this.formatAddress({ ...address, fullAddress })

        this.setState({
            addressDetail: { ...addressData },
            newAddress: true
        })


    }

    onChangeInput = (e) => {

    }

    handleSubmit = (e) => {

        this.setState({ manualAddressInput: false })


        e.preventDefault();

        this.props.form.validateFields((err, values) => {

            if (!err) {

                const selectedSuburb = values.suburb

                const selectedState = values.state

                const selectedStateLabel = getStateTitle(values.state)

                const selectedStreetType = getStreetTypeLabel(values.streetType)

                const tempAddressObject = { ...this.state.addressDetails }

                const fullAddress = { _text: `${values.unitNumber ? values.unitNumber + ',' : ""}${values.streetNumber} ${values.streetName} ${selectedStreetType ? selectedStreetType : ''} ${selectedSuburb} ${selectedState} ${values.postcode}` }

                tempAddressObject["Suburb_x0028_Supply_x0029_"] = { _text: selectedSuburb }

                tempAddressObject["State_x0028_Supply_x0029_"] = { _text: selectedState }

                tempAddressObject["Postcode_x0028_Supply_x0029_"] = { _text: values.postcode }

                if (values.unitNumber) {

                    tempAddressObject["Unit_x0028_Supply_x0029_"] = { _text: values.unitNumber };

                    tempAddressObject["UnitType_x0028_Supply_x0029_"] = { _text: 'U' }

                }

                tempAddressObject["StreetNumber_x0028_Supply_x0029_"] = { _text: values.streetNumber ? values.streetNumber : '' }

                tempAddressObject["StreetName_x0028_Supply_x0029_"] = { _text: values.streetName ? values.streetName : '' }

                tempAddressObject["StreetType_x0028_Supply_x0029_"] = { _text: selectedStreetType }

                tempAddressObject["state"] = { _text: selectedStateLabel }

                tempAddressObject["SupplyFullAddress"] = { ...fullAddress }

                this.setState({ addressDetail: { ...tempAddressObject } })

                this.props.validateAddress({ addressDetails: { ...tempAddressObject } })
            }
        });
    };

    searchByPostcode = (e) => {

        const value = e.target.value

        this.props.getSuburbByPostcodeId(value)

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.address && nextProps.address.validatedAddress && nextProps.address.validatedAddress.status === 'Success' && !this.state.manualAddressInput) {


            this.props.onComplete({ ...this.state.addressDetail })
        }

        if (nextProps.address && nextProps.address.suburbList) {
       

            this.setState({ suburbList: nextProps.address.suburbList })

        }

    }
    selectSuburb = (suburbName) => {

        if (suburbName) {
            const selectedSuburb = this.state.suburbList.result.filter((suburb) => {

                return suburb.suburb === suburbName
            })

            const state = getStateFromCode(selectedSuburb[0].state)

            const availableStateList = []

            availableStateList.push(state)

            this.setState({ states: availableStateList })
        }

    }

    getFullAddress() {

        const addressArray = []

        let fullAddress = ''

        if (this.props.data.leadDetails && this.props.data.leadDetails.SupplyFullAddress && this.props.data.leadDetails.SupplyFullAddress._text) {


            fullAddress = this.props.data.leadDetails.SupplyFullAddress._text

            if (this.props.addressSearchedAlready && !this.state.usePreviousAddress) {

                this.setState({ usePreviousAddress: true })

                this.setState({ previousAddress: fullAddress })

            }
            if (this.state.usePreviousAddress && !this.props.addressSearchedAlready) {

                this.setState({ usePreviousAddress: false })

                this.setState({ previousAddress: '' })

            }


        } else {

            if (this.props.data.leadDetails) {

                let tempPrvAddress = ``

                if (this.props.data.leadDetails && this.props.data.leadDetails.Unit_x0028_Supply_x0029_ && this.props.data.leadDetails.Unit_x0028_Supply_x0029_._text !== "-") {

                    tempPrvAddress = tempPrvAddress + `UNIT ${this.props.data.leadDetails.Unit_x0028_Supply_x0029_._text}, `
                    // addressArray.push(this.props.data.leadDetails.Unit_x0028_Supply_x0029_._text)
                }

                if (this.props.data.leadDetails && this.props.data.leadDetails.StreetNumber_x0028_Supply_x0029_) {
                    tempPrvAddress = tempPrvAddress + `${this.props.data.leadDetails.StreetNumber_x0028_Supply_x0029_._text}, `
                    // addressArray.push(this.props.data.leadDetails.StreetNumber_x0028_Supply_x0029_._text)
                }

                if (this.props.data.leadDetails && this.props.data.leadDetails.StreetName_x0028_Supply_x0029_) {
                    tempPrvAddress = tempPrvAddress + `${this.props.data.leadDetails.StreetName_x0028_Supply_x0029_._text} `
                    // addressArray.push(this.props.data.leadDetails.StreetName_x0028_Supply_x0029_._text)
                }

                if (this.props.data.leadDetails && this.props.data.leadDetails.StreetType_x0028_Supply_x0029_) {
                    tempPrvAddress = tempPrvAddress + `${getStreetTypeLabel(this.props.data.leadDetails.StreetType_x0028_Supply_x0029_._text)}, `
                    // addressArray.push(this.props.data.leadDetails.StreetType_x0028_Supply_x0029_._text)
                }

                if (this.props.data.leadDetails && this.props.data.leadDetails.Suburb_x0028_Supply_x0029_) {
                    tempPrvAddress = tempPrvAddress + `${this.props.data.leadDetails.Suburb_x0028_Supply_x0029_._text}, `
                    // addressArray.push(this.props.data.leadDetails.Suburb_x0028_Supply_x0029_._text)
                }

                if (this.props.data.leadDetails && this.props.data.leadDetails.State_x0028_Supply_x0029_) {
                    tempPrvAddress = tempPrvAddress + `${this.props.data.leadDetails.State_x0028_Supply_x0029_._text} `
                    // addressArray.push(this.props.data.leadDetails.State_x0028_Supply_x0029_._text)
                }

                if (this.props.data.leadDetails && this.props.data.leadDetails.Postcode_x0028_Supply_x0029_) {
                    tempPrvAddress = tempPrvAddress + `${this.props.data.leadDetails.Postcode_x0028_Supply_x0029_._text}`
                    // addressArray.push(this.props.data.leadDetails.Postcode_x0028_Supply_x0029_._text)
                }


                if (!this.state.usePreviousAddress) {

                    this.setState({ usePreviousAddress: true })

                    // this.setState({ previousAddress: addressArray.length > 0 ? addressArray.join(',') : fullAddress })
                    this.setState({ previousAddress: tempPrvAddress ? tempPrvAddress : fullAddress })
                }




            }
        }

        return addressArray.length > 0 ? addressArray.join(',') : fullAddress
    }

    render() {

        const { manualAddressInput } = this.state
        const { getFieldDecorator } = this.props.form

        this.getFullAddress()

        return (
            <Col span={24}>
                <Card className="gx-card" title={`Search Address`} style={{ maxHeight: "550px", overflow: "hidden" }}>
                    <Form
                        className="ant-advanced-search-form"
                        style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                    >
                        {
                            !manualAddressInput && (

                                <>

                                    <Row>
                                        <Col lg={24} md={24} sm={24} xs={24}>

                                            <center>

                                                <h3>Enter Address to Search</h3>

                                                <div className="gx-form-row0">

                                                    <FormItem {...formItemLayout2} >

                                                        <WidgetInput
                                                            placeholder='Enter address here'
                                                            defaultValue={this.state.previousAddress}
                                                            addressFinderKey={addressFinderKey}
                                                            inputClassName={styles.customSearchInput}
                                                            id={'addressFinder'}
                                                            name={'addressFinder'}
                                                            style={{ width: '50%', height: "50px", fontSize: "25px", textAlign: "center" }}
                                                            onSelected={this.onSelectAddress}
                                                        />

                                                    </FormItem>

                                                </div>

                                            </center>

                                        </Col>

                                    </Row>

                                    <Row>

                                        <Col span={24} className="gx-text-right">

                                            <center>

                                                <Button
                                                    type="primary"
                                                    onClick={this.fetchAddress}
                                                    disabled={this.props.disabledAction ? this.props.disabledAction : false}
                                                    loading={this.props.disabledAction ? this.props.disabledAction : false}
                                                >
                                                    {
                                                        this.props.disabledAction ? 'Fetching Address...' : 'Fetch Address'
                                                    }

                                                </Button>

                                            </center>

                                        </Col>

                                    </Row>

                                </>
                            )}

                    </Form>

                    <Form
                        className="ant-advanced-search-form"
                        style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                        onSubmit={this.handleSubmit}
                    >
                        {
                            manualAddressInput && (
                                <>

                                    <Row>

                                        <Col lg={8} md={8} sm={8} xs={24}>

                                            <Form.Item {...formItemLayout2} name="unitNumber" label="Unit Number" rules={[{ required: true }]}>

                                                {getFieldDecorator('c', {
                                                    initialValue: this.props.data.leadDetails && this.props.data.leadDetails.Unit_x0028_Supply_x0029_ ? this.props.data.leadDetails.Unit_x0028_Supply_x0029_._text : '',
                                                    rules: [{ required: true, message: 'Enter Unit Number!' }],
                                                })(
                                                    <Input name='unitNumber' id='unitNumber' />
                                                )}

                                            </Form.Item>

                                        </Col>

                                        <Col lg={8} md={8} sm={8} xs={24}>

                                            <Form.Item {...formItemLayout2} name="streetNumber" label="Street Number" rules={[{ required: true }]}>

                                                {getFieldDecorator('streetNumber', {
                                                    initialValue: this.props.data.leadDetails && this.props.data.leadDetails.StreetNumber_x0028_Supply_x0029_ ? this.props.data.leadDetails.StreetNumber_x0028_Supply_x0029_._text : '',
                                                    rules: [{ required: true, message: 'Enter Street Number!' }],
                                                })(
                                                    <Input name='streetNumber' id='streetNumber' />
                                                )}

                                            </Form.Item>

                                        </Col>

                                        <Col lg={8} md={8} sm={8} xs={24}>

                                            <Form.Item {...formItemLayout2} name="streetName" label="Street Name" rules={[{ required: true }]}>

                                                {getFieldDecorator('streetName', {
                                                    initialValue: this.props.data.leadDetails && this.props.data.leadDetails.StreetName_x0028_Supply_x0029_ ? this.props.data.leadDetails.StreetName_x0028_Supply_x0029_._text : '',
                                                    rules: [{ required: true, message: 'Enter Street Name!' }],
                                                })(
                                                    <Input name='streetName' id='streetName' />
                                                )}


                                            </Form.Item>

                                        </Col>

                                        <Col lg={8} md={8} sm={8} xs={24}>

                                            <Form.Item  {...formItemLayout2} name="streetType" label="Street Type" rules={[{ required: true }]}>

                                                {getFieldDecorator('streetType', {
                                                    initialValue: this.props.data.leadDetails && this.props.data.leadDetails.StreetType_x0028_Supply_x0029_ ? this.props.data.leadDetails.StreetType_x0028_Supply_x0029_._text : '',
                                                    rules: [{ required: true, message: 'Select Street Type!' }],
                                                })(
                                                    <Select
                                                        showSearch
                                                        name={"streetType"}
                                                        id={"streetType"}
                                                        placeholder="Select Select street type"
                                                        allowClear
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {
                                                            streetType && streetType.map((street, index) => {
                                                                return <Option value={street.StreetAbbrev} key={index}>{street.Description}</Option>
                                                            })
                                                        }


                                                    </Select>
                                                )}

                                            </Form.Item>

                                        </Col>

                                        <Col lg={8} md={8} sm={8} xs={24}>

                                            <Form.Item {...formItemLayout2} name="postcode" label="Post Code" rules={[{ required: true }]}>

                                                {getFieldDecorator('postcode', {
                                                    initialValue: this.props.data.leadDetails && this.props.data.leadDetails.Postcode_x0028_Supply_x0029_ ? this.props.data.leadDetails.Postcode_x0028_Supply_x0029_._text : '',
                                                    rules: [{ required: true, message: 'Enter Postcode!' }],
                                                })(
                                                    <Input name='postcode' id='postcode' onChange={this.searchByPostcode} />
                                                )}

                                            </Form.Item>

                                        </Col>

                                        <Col lg={8} md={8} sm={8} xs={24}>

                                            <Form.Item  {...formItemLayout2} name="suburb" label="Suburb" rules={[{ required: true }]}>

                                                {getFieldDecorator('suburb', {
                                                    initialValue: this.props.data.leadDetails && this.props.data.leadDetails.Suburb_x0028_Supply_x0029_ ? this.props.data.leadDetails.Suburb_x0028_Supply_x0029_._text : '',
                                                    rules: [{ required: true, message: 'Select Suburb!' }],
                                                })(
                                                    <Select
                                                        name={"suburb"}
                                                        id={"suburb"}
                                                        placeholder="Select Select street type"
                                                        allowClear
                                                        disabled={!this.state.suburbList}
                                                        onChange={this.selectSuburb}
                                                    >
                                                        {
                                                            this.state.suburbList && this.state.suburbList.recordCount > 0 && this.state.suburbList.result.map((item, index) => {
                                                                return <Option value={item.suburb} key={index}>{item.suburb}</Option>
                                                            })
                                                        }

                                                    </Select>
                                                )}

                                            </Form.Item>

                                        </Col>

                                        <Col lg={8} md={8} sm={8} xs={24}>

                                            <Form.Item  {...formItemLayout2} name="state" label="State" rules={[{ required: true }]}>

                                                {getFieldDecorator('state', {
                                                    initialValue: this.props.data.leadDetails && this.props.data.leadDetails.State_x0028_Supply_x0029_ ? this.props.data.leadDetails.State_x0028_Supply_x0029_._text : '',
                                                    rules: [{ required: true, message: 'Select Suburb!' }],
                                                })(
                                                    <Select
                                                        name={"state"}
                                                        id={"state"}
                                                        placeholder="Select Select street type"
                                                        allowClear
                                                        disabled={!this.state.states}
                                                    >
                                                        {
                                                            this.state.states && this.state.states.map((state, index) => {
                                                                return <Option value={state.code} key={index}>{state.title}</Option>
                                                            })
                                                        }

                                                    </Select>
                                                )}

                                            </Form.Item>

                                        </Col>

                                    </Row>

                                    <Row>

                                        <Col span={24} className="gx-text-right">

                                            <center>

                                                <Button type="primary" htmlType="submit">

                                                    Continue
                                                </Button>

                                            </center>
                                        </Col>
                                    </Row>
                                </>

                            )
                        }
                        <Row>

                            <Col xs={24}>
                                <center>
                                    {
                                        !manualAddressInput && (
                                            <div>
                                                If you are unable to find your address in the address search dropdown above,
                                                <Button
                                                    className={styles.link}
                                                    onClick={this.setManualAddressInput}
                                                    type="link">
                                                    Click here to try searching your address manually
                                                </Button>
                                            </div>

                                        )
                                    }

                                    {
                                        manualAddressInput && (
                                            <div>
                                                <Button
                                                    className={styles.link}
                                                    onClick={this.setAutoAddressInput}
                                                    type="link">
                                                    Search Address
                                                </Button>
                                            </div>

                                        )
                                    }

                                </center>
                            </Col>
                        </Row>

                    </Form>

                </Card>
            </Col>
        )
    }
}

const mapStateToProps = state => ({
    address: state.address
});

const mapDispatchToProps = dispath => ({
    validateAddress: (data) => dispath(validateAddress(data)),
    getSuburbByPostcodeId: (postcode) => dispath(getSuburbByPostcodeId(postcode))
});

const WrappedAddressForm = Form.create()(AddressFinder);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAddressForm);