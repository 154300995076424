import React from "react";
import {Route, Switch} from "react-router-dom";
import LeadSourceVolumeSummary from "./LeadSourceVolumeSummary";

const Sales = ({match}) => (
  <Switch>
    <Route path={`${match.url}/leadSourceVolumeSummary`} component={LeadSourceVolumeSummary}/>
  </Switch>
);

export default Sales;
