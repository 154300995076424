import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "util/isEmpty";
import { Form, Row, Col } from "antd";
import Widget from "components/Widget";

import Moment from 'moment';
import { extendMoment } from 'moment-range';

import {  FcHighPriority } from 'react-icons/fc';

const moment = extendMoment(Moment);
var momentTimezone = require('moment-timezone');

// const getStatus = (status) => {
//   const statusData = taskStatus.filter((taskStatus, index) => status === taskStatus.id)[0];
//   return <Aux>
//     <span className="gx-nonhover">
//       <i className={`icon icon-circle gx-fs-sm gx-text-${statusData.color}`}/>
//     </span>
//     <span className={`gx-badge gx-hover gx-mb-0 gx-text-white gx-badge-${statusData.color}`}>
//       {statusData.title}
//     </span>
//   </Aux>
// };

const FixupSaleItem = ({data}) => {  

  return (
    <div key={"FixupSale" + data.Id["_text"]} className="gx-media gx-task-list-item gx-flex-nowrap">
      {/* <Avatar className="gx-mr-3 gx-size-36" src={avatar}/> */}
      <div className="gx-media-body gx-task-item-content">
        <div className="gx-task-item-content-left">
          <h5 className="gx-text-truncate gx-task-item-title">
            <p>{data.Id["_text"]}</p>
            <a href={`https://lm.prod.velocify.com/Web/LeadAddEdit.aspx?LeadId=${data.Id["_text"]}`} target="_blank">
              {data.LeadType["_text"]} ({data.FuelType["_text"]}) - {(!isEmpty(data["Move-inorTransfer"]) && data["Move-inorTransfer"]["_text"] !== "Transfer") ? moment(data["Move-inDate"]["_text"], "YYYY-MM-DDT00:00:00").format('DD/MM/YYYY') : ""} {(!isEmpty(data["Move-inorTransfer"]["_text"]) && data["Move-inorTransfer"]["_text"] !== "Transfer") ? `(${data.FuelType["_text"] === "Dual" ? `Elec - ${data.ElecConnectionFeeType["_text"]} | Gas - ${data.GasConnectionFeeType["_text"]}` : data.FuelType["_text"] === "Elec" ? `Elec - ${data.ElecConnectionFeeType["_text"]}` : `Gas - ${data.GasConnectionFeeType["_text"]}`})` : ``}  
            </a>
          </h5>
          <p key={data.Id["_text"]} className="gx-text-grey gx-fs-sm gx-mb-0">{data.LastAction["_text"]} - {data.LastActionNote["_text"]} </p>
        </div>
        <div className="gx-task-item-content-right">
          {/* {getStatus(data)} */}
        </div>
      </div>
    </div>

  );
};

class FixupList extends Component {
  
  

  render() {

    let fixupData = this.props.fixupSales;

    return (
      <Widget title={
        <h2 className="h2 gx-text-capitalize gx-mb-0 gx-text-red">
          <FcHighPriority size={18} /> {" "}Pending Fixups</h2>
      } styleName="gx-card-ticketlist"
        //       extra={<h5 className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
        //         See all tickets <i
        //         className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle"/>
        // </h5>}
      >
        {
          fixupData.map((fixup, index) => <FixupSaleItem key={index} data={fixup}/>)
        }
        {/* <div className="gx-task-list-item gx-d-block gx-d-sm-none"><h5
          className="gx-text-primary gx-mb-0 gx-pointer">See all tickets <i
          className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle"/></h5>
        </div> */}
      </Widget>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispath => ({

});

const WrappedAdvancedSearch = Form.create()(FixupList);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);
