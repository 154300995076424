import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';

export const error = error => ({
  type: 'ERROR',
  error,
});

export const saveCsvData = csvData => ({
  type: 'SAVE_CSV_DATA',
  csvData,
});

export const csvUploadStatus = csvDataStatus => ({
  type: 'SAVE_CSV_DATA_UPLOAD_STATUS',
  csvDataStatus,
});

const config = {
  headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
  }
}
let csvResults = "";

function setCSVData(results) {
    csvResults = results;
}

export const uploadCsvFile = (uploadInfo, file) => dispath =>{

  dispath(error(null));

  dispath(csvUploadStatus("Uploading"));
  Papa.parse(file,{
      header: true,
      dynamicTyping: true,
      complete: function (results) {
          setCSVData(results)
          uploadInfo.csvData = JSON.stringify(csvResults.data);
          uploadInfo.fileName = file.name;

          if(uploadInfo !== undefined)
          {
              let formData = new FormData();

              for(let key in uploadInfo)
              {
                  formData.append(key, uploadInfo[key]);
              }

              axios.post(apidetails.API_URL + 'rateToolExport/', qs.stringify(uploadInfo), config)
              .then(res => {
                  dispath(saveCsvData(res.data.results.exportFileName));
                  dispath(csvUploadStatus("Pending"));
              })
              .catch(err => {
                  console.log(err.response.data.message);
                  dispath(error(err.response.data.message));
                  dispath(error(null));
              });


          }
      }
  });
}
