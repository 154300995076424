import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';

export const saveAccessLevelList = accessLevelList => ({
    type: 'SAVE_ACCESS_LEVEL_LIST',
    accessLevelList,
});

export const saveRecordUpdateStatus = recordUpdateStatus => ({
    type: 'SAVE_RECORD_UPDATE_STATUS',
    recordUpdateStatus,
});

export const error = error => ({
    type: 'ERROR',
    error,
});

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const getAccessLevels = (data) => dispath =>{

    if(data !== undefined)
    {
        // axios.post(apidetails.API_URL + 'users/search', qs.stringify(data), config)
        // .then(res => {
        //     dispath(savePostcodeDistributorList(res.data.electricityPostcodeDistributors));
        // })
        // .catch(err => {
        //     dispath(error(err.message || 'ERROR'));
        // });
    }
    else
    {
        axios.get(apidetails.API_URL + 'accessLevels/')
        .then(res => {
            dispath(saveAccessLevelList(res.data.accessLevel));
        })
        .catch(err => {
            dispath(error(err.message || 'ERROR'));
        });
    }    
}

export const updateAccessLevel = (data) => dispath =>{

    // axios.post(apidetails.API_URL + 'elecPostcodeDistributors/update', qs.stringify(data), config)
    // .then(res => {
    //     dispath(saveRecordUpdateStatus(res.data));
    //     dispath(saveRecordUpdateStatus(null));
    // })
    // .catch(err => {
    //     dispath(error(err.message || 'ERROR'));
    // });  
}

export const addAccessLevel = (data) => dispath =>{

    // axios.post(apidetails.API_URL + 'elecPostcodeDistributors/delete', qs.stringify(data), config)
    // .then(res => {
    //     dispath(saveRecordUpdateStatus(res.data));
    //     dispath(saveRecordUpdateStatus(null));
    // })
    // .catch(err => {
    //     dispath(error(err.message || 'ERROR'));
    // });  
}


export const deleteAccessLevel = (data) => dispath =>{

    // axios.post(apidetails.API_URL + 'elecPostcodeDistributors/delete', qs.stringify(data), config)
    // .then(res => {
    //     dispath(saveRecordUpdateStatus(res.data));
    //     dispath(saveRecordUpdateStatus(null));
    // })
    // .catch(err => {
    //     dispath(error(err.message || 'ERROR'));
    // });  
}
