import React, { Component } from 'react'

import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, message, Switch, Icon, Steps } from 'antd'
import { connect } from 'react-redux'
import TextArea from 'antd/lib/input/TextArea';

import isEmpty from "util/isEmpty";
import { createBroadbandPlan, getBroadbandPlanById, setCreateBroadbandStatusToDefault, updateBroadbandPlan } from '../../../../appRedux/actions/admin/broadbandPlans'
import { getRetailerList } from "appRedux/actions/admin/retailers";
import moment from 'moment';

const { Step } = Steps;

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const { Option } = Select;

class ManageBroadbandPlans extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editMode: false,
            current: 0,
            selectedPlan: {}
        }
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id) {

            this.props.getBroadbandPlanById(id)
            this.setState({ editMode: true })

        } else {

            this.setState({ editMode: false })

        }

        this.props.getRetailerList();
    }

    componentWillReceiveProps(nextProps) {

        console.log("triggered");
        if (this.state.editMode) {

            if (this.state.selectedPlan && this.state.selectedPlan.id) {

            } else {

                this.setState({ selectedPlan: nextProps.selectedFeePlan })

            }

        }

        // Check the broadband Create status and  if it is success redirect to broadband fee index page
        if (nextProps.createStatus === 'success') {

            this.props.setCreateBroadbandStatusToDefault()

            message.success(this.state.editMode ? `Broadband Plan Id:${this.state.selectedPlan.id} Updated` : `New Broadband Fee Record Added!`);

            setTimeout(() => {

                this.props.history.replace('/admin/manage/broadbandPlans')

            }, 2000);

        }
    }


    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {


            if (this.state.editMode) {

                this.props.updateBroadbandPlan({ ...this.state.selectedPlan, id: this.state.selectedPlan.id, ...values })

            } else {

                this.props.createBroadbandPlan({ ...this.state.selectedPlan, ...values })

            }

        })

    }

    separateStringToArray = (key, value) => {

        if (value) {
            console.log(value, key);
            if (key === 'state') {

                return value.split("|")

            } else {

                return value.split(',')
            }
        } else {
            return []
        }


    }

    next = () => {
        this.props.form.validateFields((err, values) => {
            console.log(err);

            if (err === null) {

                if (this.state.current === 0) {

                    let tempValues = values

                    const dateChanges = {
                        plan_start: moment(values.plan_start).format('YYYY-MM-DD'),
                        plan_expiry: moment(values.plan_expiry).format('YYYY-MM-DD')
                    }

                    const recommendedHouHoldSize = { recommended_household_sizes: values.recommended_household_sizes.toString() }

                    const recommendedUsageLevel = { recommended_usage_level: values.recommended_usage_level.toString() }

                    const state = { state: values.state.join('|') }

                    const technology = { technology: values.technology.toString() }

                    const connection_type = { connection_type: values.connection_type.toString() }

                    const isActive = {
                        isActive: values.isActive === true ? 'YES' : 'NO'
                    }

                    tempValues = { ...tempValues, ...dateChanges, ...recommendedHouHoldSize, ...recommendedUsageLevel, ...technology, ...connection_type, ...state, ...isActive }

                    this.setState({ selectedPlan: { ...this.state.selectedPlan, ...tempValues } })
                } else {
                    this.setState({ selectedPlan: { ...this.state.selectedPlan, ...values } })
                }

                this.setState({ current: this.state.current + 1 })
            } else {

            }
        })

    }

    prev = () => {
        this.setState({ current: this.state.current - 1 })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const { editMode, selectedPlan, current } = this.state

        const config = {
            rules: [{ type: 'object', required: true, message: 'Please select time!' }],
        };

        console.log(selectedPlan);
        return (
            <Card className="gx-card" title={`${editMode ? 'Edit Broadband Plan' : 'Add New Broadband Plan'}`} style={{ maxHeight: "100%", overflow: "hidden" }}>
                <Form
                    name="broadbandPlan"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                >
                    <Steps current={current}>
                        <Step key={'generalDetails'} title={'General Details'} />
                        <Step key={'planPriceDetails'} title={'Package and Price Details'} />
                        <Step key={'planLinks'} title={'Plan Links'} />
                        <Step key={'planDescriptions'} title={'Plan Descriptions'} />
                    </Steps>
                    <div className="steps-content" style={{ marginTop: '20px' }}>{
                        current === 0 && <Row>
                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Retailer" hasFeedback>
                                    {getFieldDecorator('retailer', {
                                        rules: [{ required: true, message: 'Please select Retailer' }],
                                        initialValue: selectedPlan ? selectedPlan.retailer : ''
                                    })(
                                        <Select placeholder="Please select a Retailer">
                                            {/* <Option value="-1">All Retailers</Option> */}
                                            {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                                return <option key={key} value={retailer.retailerFriendlyName}>{retailer.retailerName}</option>;
                                            })}
                                        </Select>,
                                    )}
                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Product Name" hasFeedback>
                                    {getFieldDecorator('product_name', {
                                        rules: [{ required: true, message: 'Please select Product Name' }],
                                        initialValue: selectedPlan ? selectedPlan.product_name : ''
                                    })(
                                        <Input placeholder='Please Enter Product Name' />
                                    )}
                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="States" hasFeedback mode="multiple">
                                    {getFieldDecorator('state', {
                                        rules: [{ required: true, message: 'Please select States' }],
                                        initialValue: selectedPlan ? this.separateStringToArray("state", selectedPlan.state) : []
                                    })(
                                        <Select placeholder="Please select States" mode='multiple'>
                                            <Option value="VIC">Victoria</Option>
                                            <Option value="NSW">New South Wales</Option>
                                            <Option value="ACT">Australian Capital Territory</Option>
                                            <Option value="QLD">Queensland</Option>
                                            <Option value="SA">South Australia</Option>
                                            <Option value="WA">Western Australia</Option>
                                            <Option value="NT">Northern Territory</Option>
                                            <Option value="TAS">Tasmania</Option>
                                        </Select>,
                                    )}
                                </Form.Item>

                            </Col>

                            <Col sm={6} xs={24}>

                                <Form.Item {...formItemLayout2} label="Res/sme" hasFeedback mode="multiple">
                                    {getFieldDecorator('res_sme', {
                                        rules: [{ required: true, message: 'Please select States' }],
                                        initialValue: selectedPlan ? selectedPlan.res_sme : ''
                                    })(
                                        <Radio.Group>
                                            <Radio value="RES">RES</Radio>
                                            <Radio value="SME">SME</Radio>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>

                            </Col>

                            <Col sm={6} xs={24}>

                                <Form.Item {...formItemLayout2} label="Active Status">
                                    {getFieldDecorator('isActive', {
                                        rules: [{ required: true, message: 'Please select States' }],
                                        initialValue: selectedPlan ? selectedPlan.isActive === 'YES' ? true : false : false,
                                        valuePropName: "checked"
                                    })(
                                        <Switch checkedChildren="Active" unCheckedChildren="Inactive" />

                                    )}
                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Special Logo Link">
                                    {getFieldDecorator('special_logo', {
                                        initialValue: selectedPlan ? selectedPlan.special_logo : ''
                                    })(<Input />)}

                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Plan Eligibility">
                                    {getFieldDecorator('plan_eligibility', {
                                        initialValue: selectedPlan ? selectedPlan.plan_eligibility : ''
                                    })(<Input />)}

                                </Form.Item>

                            </Col>

                            <Col sm={6} xs={24}>

                                <Form.Item {...formItemLayout2} label="Recommended Household Sizes" hasFeedback mode="multiple">
                                    {getFieldDecorator('recommended_household_sizes', {
                                        rules: [{ required: true, message: 'Please select Household Sizes' }],
                                        initialValue: selectedPlan ? this.separateStringToArray("recommended_household_sizes", selectedPlan.recommended_household_sizes) : []
                                    })(
                                        <Select placeholder="Please select Household Sizes" mode="multiple">
                                            <Option value="1">1</Option>
                                            <Option value="2">2</Option>
                                            <Option value="3">3</Option>
                                            <Option value="4">4</Option>
                                            <Option value="5">5</Option>
                                            <Option value="6">6</Option>
                                        </Select>,
                                    )}
                                </Form.Item>

                            </Col>

                            <Col sm={6} xs={24}>

                                <Form.Item {...formItemLayout2} label="Recommended Usage Level" hasFeedback mode="multiple">
                                    {getFieldDecorator('recommended_usage_level', {
                                        rules: [{ required: true, message: 'Please select Recommended Usage Level' }],
                                        initialValue: selectedPlan ? this.separateStringToArray("recommended_usage_level", selectedPlan.recommended_usage_level) : []
                                    })(
                                        <Select placeholder="Please select Recommended Usage Level" mode="multiple">
                                            <Option value="Low">Low</Option>
                                            <Option value="Medium">Medium</Option>
                                            <Option value="High">High</Option>
                                        </Select>,
                                    )}
                                </Form.Item>

                            </Col>

                            <Col sm={6} xs={24}>

                                <Form.Item {...formItemLayout2} label="Connection Type" hasFeedback mode="multiple">
                                    {getFieldDecorator('connection_type', {
                                        rules: [{ required: true, message: 'Please select Connection Type' }],
                                        initialValue: selectedPlan ? this.separateStringToArray("connection_type", selectedPlan.connection_type) : []
                                    })(
                                        <Select placeholder="Please select Connection Type" mode="multiple">
                                            <Option value="NBN">NBN</Option>
                                        </Select>,
                                    )}
                                </Form.Item>

                            </Col>

                            <Col sm={6} xs={24}>

                                <Form.Item {...formItemLayout2} label="Technology" hasFeedback mode="multiple">
                                    {getFieldDecorator('technology', {
                                        rules: [{ required: true, message: 'Please select Technology' }],
                                        initialValue: selectedPlan ? this.separateStringToArray("technology", selectedPlan.technology) : []
                                    })(
                                        <Select placeholder="Please select Technology" mode="multiple">
                                            <Option value="FTTP">FTTP</Option>
                                            <Option value="FTTC">FTTC</Option>
                                            <Option value="FTTN">FTTN</Option>
                                            <Option value="HFC">HFC</Option>
                                            <Option value="FTTB">FTTB</Option>
                                        </Select>,
                                    )}
                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Contract Term">
                                    {getFieldDecorator('contract_term', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please Enter contract term',
                                            },
                                        ],
                                        initialValue: selectedPlan ? selectedPlan.contract_term : ''
                                    })(<Input />)}

                                </Form.Item>

                            </Col>

                            <Col sm={6} xs={24}>

                                <Form.Item {...formItemLayout2} label="Satellite Included">
                                    {getFieldDecorator('satelllite_included', {
                                        initialValue: selectedPlan ? selectedPlan.satelllite_included : ''
                                    })(<Input />)}

                                </Form.Item>

                            </Col>

                            <Col sm={6} xs={24}>

                                <Form.Item {...formItemLayout2} label="Typical Evening Download Speed">
                                    {getFieldDecorator('typical_evening_download_speed', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please enter typical_evening_download_speed',
                                            },
                                        ],
                                        initialValue: selectedPlan ? selectedPlan.typical_evening_download_speed : ''
                                    })(<Input />)}

                                </Form.Item>

                            </Col>

                            <Col sm={6} xs={24}>

                                <Form.Item {...formItemLayout2} label="Online Signup Promo Code">
                                    {getFieldDecorator('online_signup_promo_code', {
                                        initialValue: selectedPlan ? selectedPlan.online_signup_promo_code : ''
                                    })(<Input />)}

                                </Form.Item>

                            </Col>

                            <Col sm={6} xs={24}>

                                <Form.Item {...formItemLayout2} label="Offline Signup Promo Code">
                                    {getFieldDecorator('offline_signup_promo_code', {
                                        initialValue: selectedPlan ? selectedPlan.offline_signup_promo_code : ''
                                    })(<Input />)}

                                </Form.Item>

                            </Col>

                            <Col sm={6} xs={24}>

                                <Form.Item {...formItemLayout2} label="Plan start">
                                    {getFieldDecorator('plan_start', {
                                        rules: [{ type: 'object', required: true, message: 'Please select Date!' }],
                                        initialValue: selectedPlan ? moment(selectedPlan.plan_start) : ''
                                    })(<DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />,)}

                                </Form.Item>

                            </Col>


                            <Col sm={6} xs={24}>

                                <Form.Item {...formItemLayout2} label="Plan Expiry">
                                    {getFieldDecorator('plan_expiry', {
                                        rules: [{ type: 'object', required: true, message: 'Please select Date!' }],
                                        initialValue: selectedPlan ? moment(selectedPlan.plan_expiry) : ''
                                    })(<DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />,)}

                                </Form.Item>

                            </Col>


                        </Row>
                    }

                        {
                            current === 1 && <Row>
                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Upload Speed">
                                        {getFieldDecorator('upload_speed', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please enter upload speed',
                                                },
                                            ],
                                            initialValue: selectedPlan ? selectedPlan.upload_speed : ''
                                        })(<Input />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Data Inclusion" hasFeedback >
                                        {getFieldDecorator('data_inclusion', {
                                            // rules: [{ required: true, message: 'Please select data inclusion' }],
                                            initialValue: selectedPlan ? selectedPlan.data_inclusion : ''
                                        })(
                                            <Select placeholder="Please select data inclusion">
                                                <Option value="Unlimited">Unlimited</Option>
                                            </Select>,
                                        )}
                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Peak Data Inclusion" hasFeedback >
                                        {getFieldDecorator('peak_data_inclusion', {
                                            initialValue: selectedPlan ? selectedPlan.peak_data_inclusion : ''
                                        })(
                                            <Select placeholder="Please select peak data inclusion">
                                                <Option value="Unlimited">Unlimited</Option>
                                            </Select>,
                                        )}
                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Off Peak Data Inclusion" hasFeedback >
                                        {getFieldDecorator('offpeak_data_inclusion', {
                                            initialValue: selectedPlan ? selectedPlan.offpeak_data_inclusion : ''
                                        })(
                                            <Select placeholder="Please select off peak data inclusion" >
                                                <Option value="Unlimited">Unlimited</Option>
                                            </Select>,
                                        )}
                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Data Inclusion Description" hasFeedback >
                                        {getFieldDecorator('data_inclusion_description', {
                                            initialValue: selectedPlan ? selectedPlan.data_inclusion_description : ''
                                        })(
                                            <Select placeholder="Please select data inclusion description" >
                                                <Option value="Unlimited">Unlimited</Option>
                                            </Select>,
                                        )}
                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Monthly Charge">
                                        {getFieldDecorator('monthly_charge', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please enter monthly charge',
                                                },
                                            ],
                                            initialValue: selectedPlan ? selectedPlan.monthly_charge : ''
                                        })(<Input addonBefore="$" />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Discounted Monthly Charge">
                                        {getFieldDecorator('discounted_monthly_charge', {
                                            initialValue: selectedPlan ? selectedPlan.discounted_monthly_charge : ''
                                        })(<Input addonBefore="$" />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Discount">
                                        {getFieldDecorator('discount', {
                                            initialValue: selectedPlan ? selectedPlan.discount : ''
                                        })(<Input addonBefore="$" />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Discount Type" hasFeedback >
                                        {getFieldDecorator('discount_type', {
                                            initialValue: selectedPlan ? selectedPlan.discount_type : ''
                                        })(
                                            <Select placeholder="Please select discount type">
                                                <Option value="dollar">Dollar</Option>
                                            </Select>,
                                        )}
                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Discount Period Months">
                                        {getFieldDecorator('discount_period_months', {
                                            initialValue: selectedPlan ? selectedPlan.discount_period_months : ''
                                        })(<Input type='number' />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Discount Applies">
                                        {getFieldDecorator('discount_applies', {
                                            initialValue: selectedPlan ? selectedPlan.discount_applies : ''
                                        })(<Input />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Standard Activation Fee">
                                        {getFieldDecorator('standard_activation_fee', {
                                            initialValue: selectedPlan ? selectedPlan.standard_activation_fee : ''
                                        })(<Input addonBefore="$" />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Early Termination Charge">
                                        {getFieldDecorator('early_termination_charge', {
                                            initialValue: selectedPlan ? selectedPlan.early_termination_charge : ''
                                        })(<Input addonBefore="$" />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Minimum Tolal Cost">
                                        {getFieldDecorator('minimum_tolal_cost', {
                                            initialValue: selectedPlan ? selectedPlan.minimum_tolal_cost : ''
                                        })(<Input addonBefore="$" />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Home Calling Plan" hasFeedback >
                                        {getFieldDecorator('home_calling_plan', {
                                            initialValue: selectedPlan ? selectedPlan.home_calling_plan : ''
                                        })(
                                            <Radio.Group>
                                                <Radio value="Yes">Yes</Radio>
                                                <Radio value="No">No</Radio>
                                            </Radio.Group>,
                                        )}
                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Home Calling Plan name">
                                        {getFieldDecorator('home_calling_plan_name', {
                                            initialValue: selectedPlan ? selectedPlan.home_calling_plan_name : ''
                                        })(<Input />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Home Calling Plan Cost">
                                        {getFieldDecorator('home_calling_plan_cost', {
                                            initialValue: selectedPlan ? selectedPlan.home_calling_plan_cost : ''
                                        })(<Input addonBefore="$" />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Home Calling Plan Inclusions" hasFeedback >
                                        {getFieldDecorator('home_calling_plan_inclusions', {
                                            initialValue: selectedPlan ? selectedPlan.home_calling_plan_inclusions : ''
                                        })(
                                            <Select placeholder="Please select home calling plan inclusions">
                                                <Option value="Unlimited">Unlimited</Option>
                                            </Select>,
                                        )}
                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Home Calling Plan Inclusions Help">
                                        {getFieldDecorator('home_calling_plan_inclusions_help', {
                                            initialValue: selectedPlan ? selectedPlan.home_calling_plan_inclusions_help : ''
                                        })(<Input />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Local Calls Untimed">
                                        {getFieldDecorator('local_calls_untimed', {
                                            initialValue: selectedPlan ? selectedPlan.local_calls_untimed : ''
                                        })(<Input />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="National Calls Per Minute">
                                        {getFieldDecorator('national_calls_per_minute', {
                                            initialValue: selectedPlan ? selectedPlan.national_calls_per_minute : ''
                                        })(<Input />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Australian Mobile Calls Per Minutes">
                                        {getFieldDecorator('australian_mobile_calls_per_minutes', {
                                            initialValue: selectedPlan ? selectedPlan.australian_mobile_calls_per_minutes : ''
                                        })(<Input />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Standard Two Min Aus Mobile">
                                        {getFieldDecorator('standard_two_min_aus_mobile', {
                                            initialValue: selectedPlan ? selectedPlan.standard_two_min_aus_mobile : ''
                                        })(<Input />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Special Calls Per Call">
                                        {getFieldDecorator('special_calls_per_call', {
                                            initialValue: selectedPlan ? selectedPlan.special_calls_per_call : ''
                                        })(<Input />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Flag Fall Calls">
                                        {getFieldDecorator('flag_fall_calls', {
                                            initialValue: selectedPlan ? selectedPlan.flag_fall_calls : ''
                                        })(<Input />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Timed Call Connection Fee">
                                        {getFieldDecorator('timed_call_connection_fee', {
                                            initialValue: selectedPlan ? selectedPlan.timed_call_connection_fee : ''
                                        })(<Input addonBefore="$" />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Timed Call Billing Increament">
                                        {getFieldDecorator('timed_call_billing_increament', {
                                            initialValue: selectedPlan ? selectedPlan.timed_call_billing_increament : ''
                                        })(<Input addonBefore="$" />)}

                                    </Form.Item>

                                </Col>

                            </Row>
                        }

                        {
                            current === 2 && <Row>

                                <Col sm={12} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Home Phone Critical Information Summary Link">
                                        {getFieldDecorator('home_phone_critical_information_summary_link', {
                                            initialValue: selectedPlan ? selectedPlan.home_phone_critical_information_summary_link : ''
                                        })(<Input />)}

                                    </Form.Item>

                                </Col>


                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Critical Information Summary Link">
                                        {getFieldDecorator('critical_information_summary_link', {
                                            initialValue: selectedPlan ? selectedPlan.critical_information_summary_link : ''
                                        })(<Input />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="NBN Key Fact Sheet Link">
                                        {getFieldDecorator('nbn_key_fact_sheet_link', {
                                            initialValue: selectedPlan ? selectedPlan.nbn_key_fact_sheet_link : ''
                                        })(<Input />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Online Signup Link">
                                        {getFieldDecorator('online_signup_link', {
                                            initialValue: selectedPlan ? selectedPlan.online_signup_link : ''
                                        })(<Input />)}

                                    </Form.Item>

                                </Col>

                                <Col sm={6} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Offline Signup Link">
                                        {getFieldDecorator('offline_signup_link', {
                                            initialValue: selectedPlan ? selectedPlan.offline_signup_link : ''
                                        })(<Input />)}

                                    </Form.Item>

                                </Col>

                            </Row>
                        }

                        {
                            current === 3 && <Row>
                                <Col sm={12} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Product Description" hasFeedback mode="multiple">
                                        {getFieldDecorator('product_description', {
                                            // rules: [{ required: true, message: 'Please Enter product description' }],
                                            initialValue: selectedPlan ? selectedPlan.product_description : ''
                                        })(
                                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                        )}
                                    </Form.Item>

                                </Col>

                                <Col sm={12} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Typical Evening Download Speed Description" hasFeedback>
                                        {getFieldDecorator('typical_evening_download_speed_description', {
                                            // rules: [{ required: true, message: 'Please Enter typical evening download speed description' }],
                                            initialValue: selectedPlan ? selectedPlan.typical_evening_download_speed_description : ''
                                        })(
                                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                        )}
                                    </Form.Item>

                                </Col>

                                <Col sm={12} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Upload Speed Description" hasFeedback>
                                        {getFieldDecorator('upload_speed_description', {
                                            initialValue: selectedPlan ? selectedPlan.upload_speed_description : ''
                                        })(
                                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                        )}
                                    </Form.Item>

                                </Col>

                                <Col sm={12} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Monthly Charge Description" hasFeedback>
                                        {getFieldDecorator('monthly_charge_description', {
                                            initialValue: selectedPlan ? selectedPlan.monthly_charge_description : ''
                                        })(
                                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                        )}
                                    </Form.Item>

                                </Col>

                                <Col sm={12} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Standard Activation Fee Description" hasFeedback>
                                        {getFieldDecorator('standard_activation_fee_description', {
                                            initialValue: selectedPlan ? selectedPlan.standard_activation_fee_description : ''
                                        })(
                                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                        )}
                                    </Form.Item>

                                </Col>

                                <Col sm={12} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Early Termination Charge Description" hasFeedback>
                                        {getFieldDecorator('early_termination_charge_description', {
                                            initialValue: selectedPlan ? selectedPlan.early_termination_charge_description : ''
                                        })(
                                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                        )}
                                    </Form.Item>

                                </Col>

                                <Col sm={12} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Minimum Total Cost Description" hasFeedback>
                                        {getFieldDecorator('minimum_tolal_cost_description', {
                                            initialValue: selectedPlan ? selectedPlan.minimum_tolal_cost_description : ''
                                        })(
                                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                        )}
                                    </Form.Item>

                                </Col>

                                <Col sm={12} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Important Info" hasFeedback>
                                        {getFieldDecorator('important_info', {
                                            initialValue: selectedPlan ? selectedPlan.important_info : ''
                                        })(
                                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                        )}
                                    </Form.Item>

                                </Col>


                                <Col sm={12} xs={24}>

                                    <Form.Item {...formItemLayout2} label="Benefit Description" hasFeedback>
                                        {getFieldDecorator('benefit_description', {
                                            initialValue: selectedPlan ? selectedPlan.benefit_description : ''
                                        })(
                                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                        )}
                                    </Form.Item>

                                </Col>

                            </Row>
                        }
                    </div>


                    <Row>



                        <Col span={24} className="gx-text-right">

                            <center>

                                <div className='contentCenter'>
                                    {current < 3 && (
                                        <Button type="primary" onClick={() => this.next()}>
                                            Next
                                        </Button>
                                    )}

                                    {
                                        current === 3 && <Button type="primary" htmlType="submit">
                                            {editMode ? 'Update' : 'Save'}
                                        </Button>
                                    }


                                    {current > 0 && (
                                        <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                                            Previous
                                        </Button>
                                    )}

                                </div>

                            </center>

                        </Col>

                    </Row>

                </Form>

            </Card>
        )
    }
}

const mapStateToProps = (state) => ({
    retailerList: state.retailers.retailerList,
    createStatus: state.broadbandPlans.createNewStatus,
    selectedFeePlan: state.broadbandPlans.selectedPlan
})

const mapDispatchToProps = dispath => ({
    getRetailerList: (data) => dispath(getRetailerList(data)),
    createBroadbandPlan: (data) => dispath(createBroadbandPlan(data)),
    setCreateBroadbandStatusToDefault: () => dispath(setCreateBroadbandStatusToDefault()),
    updateBroadbandPlan: (data) => dispath(updateBroadbandPlan(data)),
    getBroadbandPlanById: (id) => dispath(getBroadbandPlanById(id))
})

const WrappedManageBroadbandPlans = Form.create()(ManageBroadbandPlans);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedManageBroadbandPlans)
