import React, { Component } from "react";
import { Menu, Badge } from "antd";
import { Link, Redirect } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { connect } from "react-redux";

import isEmpty from "util/isEmpty";

import {
  SelectOutlined,
  PlusCircleOutlined,
  WifiOutlined,
} from '@ant-design/icons';

import { FcStatistics, FcNews } from 'react-icons/fc';
import { MdOutlinePendingActions } from 'react-icons/md';
import { BiNews } from 'react-icons/bi';
import { FaClipboardList } from 'react-icons/fa';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { RiSendPlaneFill } from 'react-icons/ri';
import { SiGoogletagmanager } from 'react-icons/si';


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  getRenderStatusMenuItem = (menuName) => {
    const { modulesAccess, accessLevelDetails } = this.props;

    var showMenu = false;

    if (!isEmpty(accessLevelDetails) && !isEmpty(modulesAccess)) {

      if (menuName === 'manageBroadbandFees' || menuName === 'manageBroadbandModemOption' || menuName === 'manageBroadbandPlans') {
        showMenu = true;
      }
      if (modulesAccess.find(data => data.module == menuName)) {

        if ((modulesAccess.find(data => data.module == menuName)).moduleType == "Page" && modulesAccess.find(data => data.module == menuName).status == 1) {
          showMenu = true;
        }
        else if (modulesAccess.find(data => data.module == menuName).status == 1) {
          showMenu = true;
        }
        else {
          if (accessLevelDetails[0].id == 8) {
            showMenu = true;
          }
        }
      }
    }

    return showMenu;
  }

  render() {
    const { themeType, navStyle, pathname, modulesAccess, accessLevelDetails } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    const { userDetails } = this.props

    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile />
            <AppsNavigation />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">

              {/* <MenuItemGroup key="#" className="gx-menu-group" title={<IntlMessages id="Legend" />}>
                  <Menu.Item key="#">
                    <Link to="#">
                      <IntlMessages id="Ready" /><Badge style={{ marginLeft: '3%' }} status="success" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="#">
                    <Link to="#">
                      <IntlMessages id="Testing" /><Badge style={{ marginLeft: '3%' }} status="warning" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="#">
                    <Link to="#">
                      <IntlMessages id="Developing" /><Badge style={{ marginLeft: '3%' }} status="error" />
                    </Link>
                  </Menu.Item>
                </MenuItemGroup> */}

              {this.getRenderStatusMenuItem("home") &&
                <MenuItemGroup key="home" className="gx-menu-group" title={<IntlMessages id="Home" />}>
                  {this.getRenderStatusMenuItem("news") &&
                    <Menu.Item key="home/dashboard">
                      <Link to="/home/dashboard"><BiNews size={25} className="gx-mr-3" style={{ position: "relative", top: "6px" }} />
                        <IntlMessages id="News" /></Link>
                    </Menu.Item>
                  }
                  {this.getRenderStatusMenuItem("floorDashboard") &&
                    <Menu.Item key="home/floorDashboard">
                      <Link to="/home/floorDashboard"><i className="icon icon-chart" />
                        <IntlMessages id="Live Sales Dashboard" /></Link>
                    </Menu.Item>
                  }
                  {this.getRenderStatusMenuItem("agentDashboard") &&
                    <Menu.Item key="home/agentDashboard">
                      <Link to="/home/agentDashboard"><AiOutlineFundProjectionScreen size={25} className="gx-mr-3" style={{ position: "relative", top: "6px" }} />
                        <IntlMessages id={accessLevelDetails[0].id == 12 ? `My Dashboard` : `Agent Summary`} />
                      </Link>
                    </Menu.Item>
                  }
                  {this.getRenderStatusMenuItem("systemStatus") &&
                    <Menu.Item key="home/systemStatus">
                      <Link to="/home/systemStatus"><i className="icon icon-thumb-up" />
                        <IntlMessages id="System Status" /></Link>
                    </Menu.Item>
                  }
                </MenuItemGroup>
              }

              {this.getRenderStatusMenuItem("tools") &&
                <MenuItemGroup key="tools" className="gx-menu-group" title={<IntlMessages id="Tools" />}>
                  {/* <Menu.Item key="tools/scriptTool">
                    <Link to="/tools/scriptTool"><i className="icon icon-auth-screen" />
                      <IntlMessages id="Script Tool" /></Link>
                  </Menu.Item> */}
                  {/* <Menu.Item key="tools/originNMIMIRNTool">
                    <Link to="/tools/originNMIMIRNTool"><i className="icon icon-orders" />
                      <IntlMessages id="Origin NMI/MIRN" /></Link>
                  </Menu.Item> */}
                  {/* <Menu.Item key="tools/bpidGeneratorTool">
                    <Link to="/tools/bpidGeneratorTool"><i className="icon icon-orders" />
                      <IntlMessages id="BPID Generator" /></Link>
                  </Menu.Item> */}
                  <Menu.Item key="tools/internalNMIMIRNTool">
                    <Link to="/tools/internalNMIMIRNTool"><i className="icon icon-search" />
                      <IntlMessages id="MIRN Tool" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tools/assignCompareBroadbandLeads">
                    <Link to="/tools/assignCompareBroadbandLeads"><i className="icon icon-orders" />
                      <IntlMessages id="CB / DR Leads" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tools/addToDNCRegister">
                    <Link to="/tools/addToDNCRegister"><PlusCircleOutlined />
                      <IntlMessages id="Add to DNC Register" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="EW Agent Tools">
                    <a href="https://tools.electricitywizard.com.au/" target="_blank"><SelectOutlined />
                      <IntlMessages id="EW Agent Tools" />
                    </a>
                  </Menu.Item>
                  <Menu.Item key="EW HUB">
                    <a href="https://hub.electricitywizard.com.au/" target="_blank"><SelectOutlined />
                      <IntlMessages id="EW HUB" />
                    </a>
                  </Menu.Item>
                  <Menu.Item key="Lumo MIRN Tool">
                    <a href="https://externalmirner.lumoenergy.com.au/" target="_blank"><SelectOutlined />
                      <IntlMessages id="Lumo Mirn Tool" />
                    </a>
                  </Menu.Item>
                  {this.getRenderStatusMenuItem("compareBroadband") &&
                  <Menu.Item key="tools/broadbandComparison">
                    <Link to="/tools/broadbandComparison"><WifiOutlined />
                      <IntlMessages id="Compare Broadband" />
                    </Link>
                  </Menu.Item>}
                </MenuItemGroup>
              }

              {this.getRenderStatusMenuItem("leads") &&
                <MenuItemGroup key="leads" className="gx-menu-group" title={<IntlMessages id="Leads" />}>
                  {/* {this.getRenderStatusMenuItem("salesReports") &&
                      <SubMenu key="salesReports" className={this.getNavStyleSubMenuClass(navStyle)}
                        title={<span> <i className="icon icon-timeline" />
                          <IntlMessages id="Sales Reports" /></span>}>

                      </SubMenu>
                    } */}
                  {this.getRenderStatusMenuItem("leadVolumeSummary") &&
                    <Menu.Item key="leads/leadSourceVolumeSummary">
                      <Link to="/leads/leadSourceVolumeSummary">
                        <i className="icon icon-timeline" />
                        <IntlMessages id="Lead Source Volumes" />
                      </Link>
                    </Menu.Item>
                  }
                </MenuItemGroup>
              }


              {this.getRenderStatusMenuItem("sales") &&
                <MenuItemGroup key="sales" className="gx-menu-group" title={<IntlMessages id="Sales" />}>
                  {this.getRenderStatusMenuItem("salesReports") &&
                    <SubMenu key="salesReports" className={this.getNavStyleSubMenuClass(navStyle)}
                      title={<span> <i className="icon icon-timeline" />
                        <IntlMessages id="Sales Reports" /></span>}>
                      {this.getRenderStatusMenuItem("hourlySalesStats") &&
                        <Menu.Item key="sales/salesReports/hourlySalesStats">
                          <Link to="/sales/salesReports/hourlySalesStats">
                            <i className="icon icon-timeline" />
                            <IntlMessages id="Hourly Stats" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("salesDistribution") &&
                        <Menu.Item key="sales/salesReports/salesDistribution">
                          <Link to="/sales/salesReports/salesDistribution">
                            <i className="icon icon-timeline" />
                            <IntlMessages id="Sales Distribution" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("demographicStats") &&
                        <Menu.Item key="sales/salesReports/demographicStats">
                          <Link to="/sales/salesReports/demographicStats">
                            <i className="icon icon-timeline" />
                            <IntlMessages id="Dmographic Stats" />
                          </Link>
                        </Menu.Item>
                      }
                    </SubMenu>
                  }
                </MenuItemGroup>
              }

              {this.getRenderStatusMenuItem("admin") &&
                <MenuItemGroup key="admin" className="gx-menu-group" title={<IntlMessages id="Admin" />}>
                  {this.getRenderStatusMenuItem("postcodeMapping") &&
                    <SubMenu key="postcodeMapping" className={this.getNavStyleSubMenuClass(navStyle)}
                      title={<span> <i className="icon icon-map-google" />
                        <IntlMessages id="Postcode Mapping" /></span>}>
                      {this.getRenderStatusMenuItem("viewPostCodeDistributorMap") &&
                        <Menu.Item key="admin/postcodeMapping/viewPostCodeDistributorMap">
                          <Link to="/admin/postcodeMapping/viewPostCodeDistributorMap">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Postcode List (Elec)" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("viewPostCodeDistributorMap") &&
                        <Menu.Item key="#">
                          <Link to="#">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Postcode List (Gas)" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("uploadRetailerPostcodeFile") &&
                        <Menu.Item key="admin/postcodeMapping/uploadRetailerPostcodeFile">
                          <Link to="/admin/postcodeMapping/uploadRetailerPostcodeFile">
                            <i className="icon icon-upload" />
                            <IntlMessages id="Upload File" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("mapPostcodesRetailer") &&
                        <Menu.Item key="admin/postcodeMapping/mapPostcodesRetailer">
                          <Link to="/admin/postcodeMapping/mapPostcodesRetailer">
                            <i className="icon icon-map-clustering" />
                            <IntlMessages id="Retailer Mapping" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("distributorTranslation") &&
                        <Menu.Item key="admin/postcodeMapping/distributorTranslation">
                          <Link to="/admin/postcodeMapping/distributorTranslation">
                            <i className="icon icon-chart-tree" />
                            <IntlMessages id="Distributor Translation" />
                          </Link>
                        </Menu.Item>
                      }
                    </SubMenu>
                  }

                  {this.getRenderStatusMenuItem("dataManagement") &&
                    <SubMenu key="dataManagement" className={this.getNavStyleSubMenuClass(navStyle)}
                      title={<span> <i className="icon icon-table-data" />
                        <IntlMessages id="Data Management" /></span>}>
                      {this.getRenderStatusMenuItem("manageLeads") &&
                        <Menu.Item key="admin/dataManagement/manageLeads">
                          <Link to="/admin/dataManagement/manageLeads">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Manage Leads" />
                          </Link>
                        </Menu.Item>
                      }
                      {/* {this.getRenderStatusMenuItem("manageInternalDNC") &&
                          <Menu.Item key="admin/dataManagement/manageInternalDNC">
                            <Link to="/admin/dataManagement/manageInternalDNC">
                              <i className="icon icon-table-data" />
                              <IntlMessages id="Manage Internal DNC" /><Badge style={{ marginLeft: '3%' }} status="error" />
                            </Link>
                          </Menu.Item>
                        } */}

                      {this.getRenderStatusMenuItem("manageDataPacks") &&
                        <Menu.Item key="admin/dataManagement/manageDataPacks">
                          <Link to="/admin/dataManagement/manageDataPacks">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Manage Data Packs" />
                          </Link>
                        </Menu.Item>
                      }
                    </SubMenu>
                  }

                  {this.getRenderStatusMenuItem("retailers") &&
                    <Menu.Item key="admin/retailers">
                      <Link to="/admin/retailers"><i className="icon icon-all-contacts" />
                        <IntlMessages id="Manage Retailers" />
                      </Link>
                    </Menu.Item>
                  }

                  {this.getRenderStatusMenuItem("distributors") &&
                    <Menu.Item key="admin/distributors">
                      <Link to="/admin/distributors"><i className="icon icon-all-contacts" />
                        <IntlMessages id="Manage Distributors" />
                      </Link>
                    </Menu.Item>
                  }


                  {this.getRenderStatusMenuItem("manageSystem") &&
                    <SubMenu key="manage" className={this.getNavStyleSubMenuClass(navStyle)}
                      title={<span> <i className="icon icon-table-data" />
                        <IntlMessages id="Manage System" /></span>}>
                      <Menu.Item key="admin/manage/rateToolUpdates">
                        <Link to="/admin/manage/rateToolUpdates">
                          <i className="icon icon-table-data" />
                          <IntlMessages id="Rate Tool Updates" />
                        </Link>
                      </Menu.Item>
                      {this.getRenderStatusMenuItem("managePlans") &&
                        <Menu.Item key="admin/manage/plans">
                          <Link to="/admin/manage/plans">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Plans" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("manageScripts") &&
                        <Menu.Item key="admin/manage/signupScripts">
                          <Link to="/admin/manage/signupScripts">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Signup Scripts" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("manageElecRates") &&
                        <Menu.Item key="admin/manage/elecRates">
                          <Link to="/admin/manage/elecRates">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Electricity Rates" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("manageElecBIPDs") &&
                        <Menu.Item key="admin/manage/elecBPIDs">
                          <Link to="/admin/manage/elecBPIDs">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Elec BPIDs" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("manageGasRates") &&
                        <Menu.Item key="admin/manage/gasRates">
                          <Link to="/admin/manage/gasRates">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Gas Rates" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("manageGasBIPDs") &&
                        <Menu.Item key="admin/manage/gasBPIDs">
                          <Link to="/admin/manage/gasBPIDs">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Gas BPIDs" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("manageDMOVDO") &&
                        <Menu.Item key="admin/manage/dmoVdo">
                          <Link to="/admin/manage/dmoVdo">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="DMO VDO" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("manageTariffs") &&
                        <Menu.Item key="admin/manage/tariffs">
                          <Link to="/admin/manage/tariffs">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Tariffs" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("manageProducts") &&
                        <Menu.Item key="admin/manage/products">
                          <Link to="/admin/manage/products">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Products" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("managePDFs") &&
                        <Menu.Item key="admin/manage/PDFs">
                          <Link to="/admin/manage/PDFs">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="PDFs" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("manageConsumptions") &&
                        <Menu.Item key="admin/manage/consumptions">
                          <Link to="/admin/manage/consumptions">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Consumptions" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("manageDNCRegister") &&
                        <Menu.Item key="admin/manage/dncRegister">
                          <Link to="/admin/manage/dncRegister">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="DNC Register" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("manageBroadbandPlans") &&
                        <Menu.Item key="admin/manage/broadbandPlans">
                          <Link to="/admin/manage/broadbandPlans">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Broadband Plans" /><Badge style={{ marginLeft: '3%' }} status="success" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("manageBroadbandModems") &&
                        <Menu.Item key="admin/manage/broadbandModems">
                          <Link to="/admin/manage/broadbandModems">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Broadband Modems" /><Badge style={{ marginLeft: '3%' }} status="success" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("manageBroadbandFees") &&
                        <Menu.Item key="admin/manage/broadbandFees">
                          <Link to="/admin/manage/broadbandFees">
                            <i className="icon icon-table-data" />
                            <IntlMessages id="Broadband Fees" /><Badge style={{ marginLeft: '3%' }} status="success" />
                          </Link>
                        </Menu.Item>
                      }
                    </SubMenu>
                  }

                  {this.getRenderStatusMenuItem("manageSales") &&
                    <SubMenu key="manageSales" className={this.getNavStyleSubMenuClass(navStyle)}
                      title={<span> <SiGoogletagmanager size={25} className="gx-mr-3" style={{ position: "relative", top: "6px" }} />
                        <IntlMessages id="Manage Sales" /></span>}>
                      {this.getRenderStatusMenuItem("processSales") &&
                        <Menu.Item key="admin/manageSales/processSales">
                          <Link to="/admin/manageSales/processSales"><RiSendPlaneFill size={25} className="gx-mr-3" style={{ position: "relative", top: "6px" }} />
                            <IntlMessages id="Process Sales via API" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("processSaleLogs") &&
                        <Menu.Item key="admin/manageSales/processSaleLogs">
                          <Link to="/admin/manageSales/processSaleLogs"><FaClipboardList size={25} className="gx-mr-3" style={{ position: "relative", top: "6px" }} />
                            <IntlMessages id="View Sale Logs" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("viewSalesInProgress") &&
                        <Menu.Item key="admin/manageSales/salesInProgress">
                          <Link to="/admin/manageSales/salesInProgress"><MdOutlinePendingActions size={25} className="gx-mr-3" style={{ position: "relative", top: "6px" }} />
                            <IntlMessages id="Sales In Progress" />
                          </Link>
                        </Menu.Item>
                      }
                      {/* {this.getRenderStatusMenuItem("processSaleBatchDetails") &&
                            <Menu.Item key="admin/manageSales/processSaleBatchDetails">
                              <Link to="/admin/manageSales/processSaleBatchDetails"><i className="icon icon-all-contacts" />
                                <IntlMessages id="Batch Details" /><Badge style={{ marginLeft: '3%' }} status="error" />
                              </Link>
                            </Menu.Item>
                          } */}
                    </SubMenu>
                  }

                  {this.getRenderStatusMenuItem("users") &&
                    <SubMenu key="users" className={this.getNavStyleSubMenuClass(navStyle)}
                      title={<span> <i className="icon icon-user" />
                        <IntlMessages id="Users" /></span>}>
                      {this.getRenderStatusMenuItem("manageUsers") &&
                        <Menu.Item key="admin/users/manageUsers">
                          <Link to="/admin/users/manageUsers">
                            <i className="icon icon-avatar" />
                            <IntlMessages id="Manage Users" />
                          </Link>
                        </Menu.Item>
                      }
                      {this.getRenderStatusMenuItem("manageToolsUsers") &&
                        <Menu.Item key="admin/users/manageToolsUsers">
                          <Link to="/admin/users/manageToolsUsers">
                            <i className="icon icon-avatar" />
                            <IntlMessages id="Manage Tools Users" />
                          </Link>
                        </Menu.Item>
                      }
                      {/* {this.getRenderStatusMenuItem("accessLevels") &&
                          <Menu.Item key="#">
                            <Link to="#">
                              <i className="icon icon-auth-screen" />
                              <IntlMessages id="Access Levels" /><Badge style={{ marginLeft: '3%' }} status="error" />
                            </Link>
                          </Menu.Item>
                        }
                        {this.getRenderStatusMenuItem("moduleAccess") &&
                          <Menu.Item key="#">
                            <Link to="#">
                              <i className="icon icon-custom-view" />
                              <IntlMessages id="Module Access" /><Badge style={{ marginLeft: '3%' }} status="error" />
                            </Link>
                          </Menu.Item>
                        } */}

                                        </SubMenu>
                                    }

                                    {this.getRenderStatusMenuItem("settings") &&
                                        <SubMenu key="settings" className={this.getNavStyleSubMenuClass(navStyle)}
                                            title={<span> <i className="icon icon-table-data" />
                                                <IntlMessages id="Settings" /></span>}>
                                            {this.getRenderStatusMenuItem("manageStatus") &&
                                                <Menu.Item key="admin/settings/manageStatus">
                                                    <Link to="/admin/settings/manageStatus">
                                                        <i className="icon icon-table-data" />
                                                        <IntlMessages id="Manage Status" />
                                                    </Link>
                                                </Menu.Item>
                                            }
                                            {this.getRenderStatusMenuItem("manageMailiningLists") &&
                                                <Menu.Item key="admin/settings/manageMailiningLists">
                                                    <Link to="/admin/settings/manageMailiningLists">
                                                        <i className="icon icon-table-data" />
                                                        <IntlMessages id="Manage Mailing Lists" />
                                                    </Link>
                                                </Menu.Item>
                                            }
                                            {this.getRenderStatusMenuItem("manageAccessLevels") &&
                                                <Menu.Item key="admin/settings/manageAccessLevels" disabled="true">
                                                    <Link to="/admin/settings/manageAccessLevels">
                                                        <i className="icon icon-table-data" />
                                                        <IntlMessages id="Manage Access Levels" /><Badge style={{ marginLeft: '3%' }} status="error" />
                                                    </Link>
                                                </Menu.Item>
                                            }
                                            {this.getRenderStatusMenuItem("manageModules") &&
                                                <Menu.Item key="admin/settings/manageModules">
                                                    <Link to="/admin/settings/manageModules">
                                                        <i className="icon icon-table-data" />
                                                        <IntlMessages id="Manage Modules" />
                                                    </Link>
                                                </Menu.Item>
                                            }
                                        </SubMenu>
                                    }

                                </MenuItemGroup>
                            }

                        </Menu>
                    </CustomScrollbars>
                </div>
            </Auxiliary>

        );
    }

}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  const { modulesAccess, accessLevelDetails, userDetails } = auth;
  return { navStyle, themeType, locale, pathname, modulesAccess, accessLevelDetails, userDetails }
};
export default connect(mapStateToProps)(SidebarContent);
