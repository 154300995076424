import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';
import isEmpty from "util/isEmpty"

export const saveSingleLeadDetails = leadDetails => ({
  type: 'SAVE_SINGLE_LEAD_DETAILS',
  leadDetails,
});

export const requestSingleLeadDetails = leadDetails => ({
  type: 'REQUEST_SINGLE_LEAD_DETAILS',
});

export const resetLeadDetails = () => ({
  type: 'REST_LEAD_DETAILS',

});

export const saveLeadAssignmentMessage = leadAssignmentToAgentMessage => ({
  type: 'LEAD_ASSIGNED_TO_AGENT_MESSAGE',
  leadAssignmentToAgentMessage,
});


export const requestToCreateNewLead = recordUpdateStatus => ({
  type: 'REQUEST_SINGLE_LEAD_CREATE',
});

export const saveSingleLeadCreate = recordUpdateStatus => ({
  type: 'SAVE_SINGLE_LEAD_CREATE',
  recordUpdateStatus,
});

export const error = error => ({
  type: 'ERROR',
  error,
});


export const saveLeadDetails = leadDetails => ({
  type: 'SAVE_LOCAL-SAVED_LEAD_DETAILS',
  leadDetails,
});


const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

export const clearErrors = (data) => dispath => {

  dispath(error(null));
}

export const clearAll = () => dispath => {

  localStorage.removeItem('leadDetails')
  dispath(saveSingleLeadCreate(null));
  dispath(error(null));

}


export const setLeadDetailsReset = () => dispath => {

  localStorage.removeItem('leadDetails')

  console.log("resetting LeadDetails");

  dispath(resetLeadDetails());


}

export const getSingleLeadDetails = (leadId) => dispath => {

  dispath(requestSingleLeadDetails(null));

  axios.get(apidetails.API_URL + `leads/${leadId}`)
    .then(res => {
      dispath(saveSingleLeadDetails(res.data));
    })
    .catch(err => {
      //console.log(err);
      dispath(error(!isEmpty(err.response.data.message) ? err.response.data.message : 'ERROR'));
    });
}

export const getSingleBBLeadDetails = (leadId, agentEmail) => dispath => {

  dispath(requestSingleLeadDetails(null));

  axios.get(apidetails.API_URL + `leads/fetchBBLead/${leadId}/${agentEmail}`)
    .then(res => {
      dispath(saveSingleLeadDetails(res.data));
    })
    .catch(err => {
      //console.log(err);
      dispath(error(!isEmpty(err.response.data.message) ? err.response.data.message : 'ERROR'));
    });
}

export const createSingleLeadDetail = (data) => dispath => {

  dispath(requestToCreateNewLead());

  axios.post(apidetails.API_URL + `leads`, qs.stringify(data), config)
    .then(res => {
      let dataObj = res.data.result;
      if (dataObj.ImportResults.ImportResult._attributes.result === "Success") {

        console.log('create new lead success');
        dispath(saveSingleLeadDetails(res.data.leadDetails));
        dispath(saveSingleLeadCreate(dataObj));
      }
      else {
        dispath(error('Lead Create Process Faild'));
      }
    })
    .catch(err => {
      //console.log(err);
      dispath(error('ERROR'));
    });
}

export const assignLeadToAgent = (data) => dispath => {

  dispath(saveLeadAssignmentMessage(null));
  axios.post(apidetails.API_URL + `leads/assignToAgent`, qs.stringify(data), config)
    .then(res => {
      dispath(saveLeadAssignmentMessage(res.data.message));
    })
    .catch(err => {
      //console.log(err);
      dispath(error(!isEmpty(err.response.data.message) ? err.response.data.message : 'ERROR'));
    });
}

export const getSavedLeadDetails = (id) => dispath => {

  console.log("here");
  if (id) {

    // api call goes here
  } else {

    const leadDetails = JSON.parse(localStorage.getItem('leadDetails'));

    if (leadDetails) {

      dispath(saveLeadDetails(leadDetails))

    }
  }

}

export const updateLeadDetails = (data) => dispath => {
  const leadDetails = qs.stringify(data)

  console.log(leadDetails);
  axios.post(apidetails.API_URL + `leads`, leadDetails, config)
    .then(res => {
      const result = res.data;

      console.log(result);
      // if (dataObj.ImportResults.ImportResult._attributes.result === "Success") {

      //   console.log('create new lead success');
      //   dispath(saveSingleLeadCreate(dataObj));
      // }
      // else {
      //   dispath(error('Lead Create Process Faild'));
      // }
    })
    .catch(err => {
      //console.log(err);
      dispath(error('ERROR'));
    });

}
