import React from 'react'


import { Spin, Card, Col, Form, Input, Row, Select, Button, Radio } from "antd";
import TextArea from 'antd/lib/input/TextArea';



const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const { Option } = Select;


const LinksDetails = (props) => {

    const handleSubmit = () => {

        props.next()
    }

    const { getFieldDecorator } = props.form

    return (
        <Col span={24}>

            <Card className="gx-card" title={`Broadband Plans General Details`} style={{ maxHeight: "640px", overflow: "hidden" }}>

                <Form
                    name="basic"
                    labelCol={{
                        xs: { span: 24 },
                        sm: { span: 4 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 20 },
                    }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <Row>

                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="Online Signup Link">
                                {getFieldDecorator('online_signup_link', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Online Signup Link',
                                        },
                                    ],
                                })(<Input />)}

                            </Form.Item>

                        </Col>

                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="NBN Key Fact Sheet Link">
                                {getFieldDecorator('nbn_key_fact_sheet_link', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter NBN Key Fact Sheet Link',
                                        },
                                    ],
                                })(<Input />)}

                            </Form.Item>

                        </Col>

                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="Offline Signup Link">
                                {getFieldDecorator('offline_signup_link', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Offline Signup Link',
                                        },
                                    ],
                                })(<Input />)}

                            </Form.Item>

                        </Col>


                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="Home Phone Critical Information Summary Link">
                                {getFieldDecorator('home_phone_critical_information_summary_link', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Home Phone Critical Information Summary Link',
                                        },
                                    ],
                                })(<Input />)}

                            </Form.Item>

                        </Col>

                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="Critical Information Summary Link">
                                {getFieldDecorator('critical_information_summary_link', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Critical Information Summary Link',
                                        },
                                    ],
                                })(<Input />)}

                            </Form.Item>

                        </Col>


                        <Col span={24} className="gx-text-right">

                            <center>

                                <div className='contentCenter'>

                                    <Button onClick={props.back}>
                                        Previous
                                    </Button>

                                    <Button type="primary" htmlType="submit">
                                        Continue
                                    </Button>

                                </div>
                            </center>
                        </Col>

                    </Row>

                </Form>
            </Card>
        </Col>
    )
}

const WrappedLinksDetails = Form.create()(LinksDetails);

export default WrappedLinksDetails

