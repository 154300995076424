import React from "react";
import {Route, Switch} from "react-router-dom";
import Dashboard from "./Dashboard";
import SystemStatus from "./SystemStatus";
import FloorDashboard from "./FloorDashboard";
import AgentDashboard from "./AgentDashboard";

const Home = ({match}) => (
  <Switch>
    <Route path={`${match.url}/dashboard`} component={Dashboard}/>
    <Route path={`${match.url}/floorDashboard`} component={FloorDashboard} />
    <Route path={`${match.url}/agentDashboard`} component={AgentDashboard}/>
    <Route path={`${match.url}/systemStatus`} component={SystemStatus}/>
  </Switch>
);

export default Home;
