import firebase from "firebase";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyCKWukUZcNoyYnvbKQxTixe-MVrOuRzakM",
    authDomain: "electricity-wizard-cms.firebaseapp.com",
    databaseURL: "https://electricity-wizard-cms.firebaseio.com",
    projectId: "electricity-wizard-cms",
    storageBucket: "",
    messagingSenderId: "483336304875",
    appId: "1:483336304875:web:8a7463d5f9516c9d1e26ae"
};

firebase.initializeApp(config);
const auth = firebase.auth();

const  googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.database();

export {
  database,
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
