import React from "react";
import { connect } from "react-redux";
import { Col, Form, Row,} from "antd";
import Iframe from 'react-iframe'
import WelComeCard from "components/dashboard/CRM/WelComeCard";
import {ResponsiveContainer } from "recharts";

class SystemStatus extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showWarning: false,
            warningMessage: "",
            showSuccess: false,
            successMessage: "",
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-main-content">
                <Row>
                    <Col span={24}>
                    <ResponsiveContainer width="100%" height={1500}>
                      <Iframe url="https://statuspage.freshping.io/18495-ElectricityWizard"
                              width="100%"
                              id="myId"
                              frameBorder = "0"
                              allowFullScreen = "1"
                              className="myClassname"
                              scrolling = "0"
                              overflow = "hidden"
                              display="initial"/>
                    </ResponsiveContainer>
                        {/* <div className="gx-card">
                            <div className="gx-card-body">
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                  
                                </Col>
                            </div>                            
                        </div>                         */}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispath => ({
    
});

const WrappedAdvancedSearch = Form.create()(SystemStatus);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);