import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, Tag} from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getDistributorList, updateDistributor, addDistributor, deleteDistributor } from "appRedux/actions/admin/distributors";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
let retailerState = "";

//const title = () => 'Here is title';
const showHeader = true;
//const footer = () => 'Here is footer';
//const scroll = {y: 500};
const pagination = { position: 'bottom' };

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const EditableContext = React.createContext();

class EditableCell extends React.Component {

  getInput = () => {
    const {
      dataIndex,
      record,
    } = this.props;

    function onChange(checked) {
    }

    function handleChange(value) {
      retailerState = value.toString().split(',').join('|');
    }

    if (this.props.inputType === 'statusSelection') {
      return <Switch disabled={false} defaultChecked={record.isActive == 1 ? true : false} onChange={onChange} />;
    }
    else if (this.props.inputType === 'stateSelection') {
      return <Select
        style={{ width: '250px' }}
        placeholder="Please select State"
        onChange={handleChange}
      >
        <Option key="VIC">VIC</Option>
        <Option key="NSW">NSW</Option>
        <Option key="ACT">ACT</Option>
        <Option key="QLD">QLD</Option>
        <Option key="SA">SA</Option>
        <Option key="WA">WA</Option>
      </Select>;
    }
    return <Input style={{minWidth:'150px'}} />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    function getInitialValue(dataIndex) {
      if(dataIndex == "isActive")
      {
        return record[dataIndex] == 1 ? true : false;
      }
      else if(dataIndex == "state")
      {
        retailerState = record[dataIndex];
        return record[dataIndex].split("|");
      }
      else
      {
        return record[dataIndex];
      }
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0, width: '100%', display:'block',  }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: getInitialValue(dataIndex),
            })(this.getInput())}
          </Form.Item>
        ) : (
            children
          )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class ManageDistributors extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editingKey: '',
      isAdding: false,
      isSearching: false,
      recordsEdited: false,
      expand: false,
      dataSource: [],
      addingNewRecord: false,
      distributorRecordIdToDelete: null,
      showAddNewDistributorPanel: false,
      showWarning: false,
      warningMessage: "",
      showSuccess: false,
      successMessage: "",
      bordered: true,
      loading: true,
      pagination,
      size: 'middle',
      title: undefined,
      showHeader,
      footer: undefined,
      rowSelection: {},
    };
    this.columns = [
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        width: 100,
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <center>
                <EditableContext.Consumer>
                  {form => (
                    <a
                      onClick={() => this.save(form, record.key)}

                    >
                      <i className="icon icon-check-circle-o" />
                    </a>
                  )}
                </EditableContext.Consumer>
                <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                  <a style={{ marginLeft: 10 }}><i className="icon icon-close-circle" /></a>
                </Popconfirm>
              </center>
            </span>
          ) : (
              <span>
                <center>
                  <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
                    <i className="icon icon-edit" />
                  </a>
                  <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.confirmDelete(record.id)}>
                    <i className="icon icon-trash" />
                  </a>
                </center>
              </span>
            );
        },
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 80,
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Updated Date',
        dataIndex: 'updatedDate',
        key: 'updatedDate',
        align: 'center',
        width: 220,
        sorter: (a, b) => a.updatedDate - b.updatedDate,
        render: (text, record) => {
          return <Tag color={"volcano"} key={text}>
                  {text.toUpperCase()}
                </Tag>
        }
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: 150,
        editable: true,
        sorter: (a, b) => { return a.name.localeCompare(b.name) },
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        align: 'center',
        width: 100,
        editable: true,
        sorter: (a, b) => { return a.state.localeCompare(b.state) },
        render: (text, record) => {

          return <Select
            style={{ width: '250px' }}
            placeholder="Please select State"
            defaultValue={text}
            disabled
          >
            <Option key="VIC">VIC</Option>
            <Option key="NSW">NSW</Option>
            <Option key="ACT">ACT</Option>
            <Option key="QLD">QLD</Option>
            <Option key="SA">SA</Option>
            <Option key="WA">WA</Option>
          </Select>
        },
      },
      {
        title: 'Contact Number',
        dataIndex: 'contactNumber',
        key: 'contactNumber',
        width: 150,
        editable: true,
        sorter: (a, b) => { return a.contactNumber.localeCompare(b.contactNumber) },
      },
    ];

  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.dataSource];
      const index = newData.findIndex(item => key === item.key);

      if (index > -1) {
        const item = newData[index];

        const valuesToUpdate = {
          id: item.id,
          distributorName: row.name,
          state: row.state,
          contactNumber: row.contactNumber,
        }
     
        this.setState({ loading: true });
        this.props.updateDistributor(valuesToUpdate);

        retailerState = "";

        this.setState({
          editingKey: '',
          recordsEdited: true
        });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  confirmDelete = (key) => {
    this.setState({ distributorRecordIdToDelete: key });
    this.setState({ showWarning: true });

  };

  onCancelDelete = key => {
    this.setState({ distributorRecordIdToDelete: null });
    this.setState({ showWarning: false });
  };

  handleDelete = () => {
    const deleteInfo = {
      id: this.state.distributorRecordIdToDelete,
    }
    this.props.deleteDistributor(deleteInfo);
    this.setState({ showWarning: false });
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showAddNewDistributorPanel: false,
    });
    this.handleReset();
  };

  handleAdd = () => {
    this.setState({
      showAddNewDistributorPanel: true,
    });
  };

  handleAddDistributorDetails = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      const distributoDetails = {
        distributorName : values.txtDistributorNameAdd,
        state : values.drpStateToAdd,
        contactNumber : values.txtContactNumberAdd,
      }

      this.setState({ isAdding: true });
      this.props.addDistributor(distributoDetails);
    });
  };

  onAddCancel = () => {
    this.setState({
      showAddNewDistributorPanel: false,
    });
    this.handleReset();
  };

  componentDidMount() {
    this.props.getDistributorList();
  }

  handleSearch = (e) => {
    // e.preventDefault();
    // this.props.form.validateFields((err, values) => {

    //   const filtersForSearch = {
    //     email: values.txtEmail,
    //     name: values.txtName,
    //     accessLevel: values.drpAccessLevel,
    //   }
    //   this.setState({ isSearching: true });
    //   this.props.getUsers(filtersForSearch);
    // });
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.props.getDistributorList();
  };

  checkColoumnType = (field) => {
    switch (field) {
      case 'isActive': return "statusSelection";
      case 'state': return "stateSelection";
      default: return "text";
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.distributorList)) {
      this.setState({ loading: true });
      this.state.dataSource = [];
      let count = 1;
      nextProps.distributorList.map((distributor, key) => {
        let record = {
          key: count.toString(),
          id: distributor.id,
          updatedDate: moment(distributor.updatedDate).format('YYYY-MM-DD h:mm:ss A'),
          name: !isEmpty(distributor.distributorName) ? distributor.distributorName : "-",
          state: !isEmpty(distributor.state) ? distributor.state : "-",
          contactNumber: !isEmpty(distributor.contactNumber) ? distributor.contactNumber : "-",
        };
        this.state.dataSource.push(record);
        count++;
      });
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }
    else {
      this.setState({ loading: true });
      this.state.dataSource = [];
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }

    if (!isEmpty(nextProps.recordUpdateStatus)) {
      if (nextProps.recordUpdateStatus.status == "Success") {
        this.setState({
          successMessage: nextProps.recordUpdateStatus.message,
          showSuccess: true,
        });
        this.props.getDistributorList();
        this.setState({ 
          isAdding: false,
          showAddNewDistributorPanel: false,
        });
      }
    }
  }


  render() {

    let { dataSource } = this.state;

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: this.checkColoumnType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-main-content">
        <ContainerHeader title="Manage Distributors" />
        <Row>
          {/* <Col span={24}>
            <Spin spinning={this.state.isSearching}>
              <Card className="gx-card" title="Search Retailer">
                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleSearch}
                >
                  <Row>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" htmlType="submit">Search</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                          Clear
                    </Button>
                      </center>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Spin>
          </Col> */}
          {this.state.showAddNewDistributorPanel && <Col span={24}>
          <Spin spinning={this.state.isAdding}>
            <Card className="gx-card" title="Add New Distributor Details">
              <Form
                className="ant-advanced-search-form"
                onSubmit={this.handleAddDistributorDetails}
              >
                <Row>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Distributor Name" >
                        {getFieldDecorator('txtDistributorNameAdd', {
                          rules: [{ required: true, message: 'Please enter Distributor Name!' }],
                        })(
                          <Input style={{ width: '100%' }} placeholder="Distributor Name" />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="State">
                        {getFieldDecorator('drpStateToAdd', {
                          rules: [{ required: true, message: 'Please select a State!' }],
                        })(
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select a State"
                          >
                            <Option value="VIC">VIC</Option>
                            <Option value="NSW">NSW</Option>
                            <Option value="QLD">QLD</Option>
                            <Option value="ACT">ACT</Option>
                            <Option value="SA">SA</Option>
                            <Option value="WA">WA</Option>
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Contact Number" >
                        {getFieldDecorator('txtContactNumberAdd', {
                          rules: [{ required: true, message: 'Please enter Contact Number!' }],
                        })(
                          <Input style={{ width: '100%' }} placeholder="Contact Number" />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                </Row>
                <Row>
                <Col span={24} className="gx-text-right">
                    <center>
                      <Button type="primary" htmlType="submit">Add Distributor Details</Button>
                      <Button style={{ marginLeft: 8 }} onClick={this.onAddCancel}>
                        Cancel
                    </Button>
                    </center>
                  </Col>
                </Row>
              </Form>
            </Card>
            </Spin>
          </Col>
          }
          <Col span={24}>
            <Card className="gx-card" title="Distributor Details">
              <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add New Distributor
              </Button>
              <EditableContext.Provider value={this.props.form}>
                <Table
                  components={components}
                  rowClassName="editable-row"
                  className="gx-table-responsive"
                  {...this.state}
                  columns={columns}
                  dataSource={dataSource} />
              </EditableContext.Provider>
            </Card>
          </Col>
        </Row>
        <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
        <SweetAlert show={this.state.showWarning}
          warning
          showCancel
          confirmBtnText="Yes Delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.handleDelete}
          onCancel={this.onCancelDelete}
        ></SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  recordUpdateStatus: state.users.recordUpdateStatus,
  distributorList: state.distributors.distributorList,
});

const mapDispatchToProps = dispath => ({
  getDistributorList: (data) => dispath(getDistributorList(data)),
  updateDistributor: (data) => dispath(updateDistributor(data)),
  addDistributor: (data) => dispath(addDistributor(data)),
  deleteDistributor: (data) => dispath(deleteDistributor(data)),
});

const WrappedAdvancedSearch = Form.create()(ManageDistributors);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);