import React, { useEffect } from 'react'
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Card, Table, Col, Button, Popconfirm, Tag, Icon, } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import { useState } from 'react';

const EditableContext = React.createContext();
const moment = extendMoment(Moment);

const BroadbandModemTable = (props) => {
    const editingKey = null
    const [dataSource, setDataSource] = useState([])
    const [isEditing, setIsEditing] = useState(null)
    const [showDelete, setShowDelete] = useState(false)
    const [selectedId, setSelectedId] = useState(null)

    useEffect(() => {
        if (props.data && props.data.result && props.data.result.length > 0) {

            setDataSource(props.data.result)

        }else {
            setDataSource([])
        }
    }, [props.data])

    const confirmDelete = () => {
        setShowDelete(false)
        props.deleteRecord(selectedId)
    }

    const checkColoumnType = (field) => {
        switch (field) {
            case 'isActive': return "statusSelection";
            case 'state': return "stateSelection";
            default: return "text";
        }
    }
    const columnsList = [
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 150,
            render: (text, record) => {
                const editable = setIsEditing(record)
                return editable ? (
                    <span>
                        <center>
                            <EditableContext.Consumer>
                                {form => (
                                    <a
                                        onClick={() => this.save(form, record.key)}

                                    >
                                        <i className="icon icon-check-circle-o" />
                                    </a>
                                )}
                            </EditableContext.Consumer>
                            <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                                <a style={{ marginLeft: 10 }}><i className="icon icon-close-circle" /></a>
                            </Popconfirm>
                        </center>
                    </span>
                ) : (
                    <span>
                        <center>
                            {/* <a onClick={() => this.viewRecord(record)}>
                                <i className="icon icon-search-new" />
                            </a> */}
                            <a style={{ marginLeft: 10 }} onClick={() => props.navigateToUpdate(record)}>
                                <i className="icon icon-edit" />
                            </a>
                            {/* <a style={{ marginLeft: 10, color: "#52c41a" }} disabled={editingKey !== ''} onClick={() => this.duplicateRecord(record)}>
                                <i className="icon icon-copy" />
                            </a> */}
                            <a
                                style={{ marginLeft: 10, color: "#f5222d" }}

                                onClick={() => {
                                    setSelectedId(record.id)
                                    setShowDelete(true)
                                }}>
                                <i className="icon icon-close-circle" />
                            </a>

                        </center>
                    </span>
                );
            },
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 150,
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Created Date',
            dataIndex: 'created',
            key: 'created',
            align: 'center',
            width: 150,
            sorter: (a, b) => moment(a.created, "YYYY-MM-DDTHH:mm:ss.SSSZ") - moment(b.created, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
            render: (text, record) => {
                return <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"geekblue"} key={text}>
                    {moment(text).format('YYYY-MM-DD h:mm:ss A')}
                </Tag>
            }
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.state.localeCompare(b.state) },
        },
        {
            title: 'Retailer',
            dataIndex: 'retailer',
            key: 'retailer',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.retailer.localeCompare(b.retailer) },
        },
        {
            title: 'RES/SME',
            dataIndex: 'res_sme',
            key: 'res_sme',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.res_sme.localeCompare(b.res_sme) },
        },
        {
            title: 'Product Name',
            dataIndex: 'product_name',
            key: 'product_name',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.product_name.localeCompare(b.product_name) },
        },
        {
            title: 'Contract Term',
            dataIndex: 'contract_term',
            key: 'contract_term',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.contract_term.localeCompare(b.contract_term) },
        },
        {
            title: 'Technology Type',
            dataIndex: 'technology_type',
            key: 'technology_type',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.technology_type.localeCompare(b.technology_type) },
        },
        {
            title: 'Modem Type',
            dataIndex: 'modem_type',
            key: 'modem_type',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.modem_type.localeCompare(b.modem_type) },
        },
        {
            title: 'Modem Name',
            dataIndex: 'modem_name',
            key: 'modem_name',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.modem_name.localeCompare(b.modem_name) },
        },

        {
            title: 'Modem Cost',
            dataIndex: 'modem_cost',
            key: 'modem_cost',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.modem_cost.localeCompare(b.modem_cost) },
        },
        {
            title: 'Modem Delivery Fee',
            dataIndex: 'modem_delivery_fee',
            key: 'modem_delivery_fee',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.modem_delivery_fee.localeCompare(b.modem_delivery_fee) },
        },
        {
            title: 'Modem Description',
            dataIndex: 'modem_description',
            key: 'modem_description',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.modem_description.localeCompare(b.modem_description) },
        }, {
            title: 'Modem Technology Support',
            dataIndex: 'modem_technology_support',
            key: 'modem_technology_support',
            align: 'center',
            width: 350,
            editable: true,
            sorter: (a, b) => { return a.modem_technology_support.localeCompare(b.modem_technology_support) },
        },
        {
            title: 'Modem Technology Support Helper Notes',
            dataIndex: 'modem_technology_support_helper_notes',
            key: 'modem_technology_support_helper_notes',
            align: 'center',
            width: 550,
            editable: true,
            sorter: (a, b) => { return a.modem_technology_support_helper_notes.localeCompare(b.modem_technology_support_helper_notes) },
        },


    ];

    const columns = columnsList.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                inputType: checkColoumnType(col.dataIndex),
                dataIndex: col.dataIndex,
                title: col.title,
                editing: setIsEditing(record),
            }),
        };
    });

    return (
        <Col span={24}>
            <Card className="gx-card" title="Add New Broadband Modem Option">

                <Button onClick={props.navigate} type="primary" className="gx-btn-cyan" style={{ marginBottom: 16 }}>
                    <Icon type="plus" />  Add New Broadband Modem Option
                </Button>

                <Button onClick={props.handleExportToCSV} type="primary" style={{ marginBottom: 16, marginRight: 0, float: "right" }}>
                    <Icon type="download" /> Export Displayed Results
                </Button>


                <EditableContext.Provider value={props.form}>
                    <Table
                        rowClassName="editable-row"
                        className="gx-table-responsive"
                        pagination={{ pageSize: 10 }}
                        columns={columns}
                        dataSource={dataSource} />
                </EditableContext.Provider>

                <SweetAlert show={showDelete}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={`Are you sure you want delete record ID ${selectedId}`}
                    onConfirm={confirmDelete}
                    onCancel={() => setShowDelete(false)}
                />

            </Card>
        </Col >
    )
}

export default BroadbandModemTable