import { unitType } from "../static/formSupport";

export const getUnitValue = (value) => {

    // check unit name availability
    if (value) {

        // convert unit name to camel case
        const camelCasedValue = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

        // filter unit name from unit type array
        const unit = unitType.filter((unit) => {
            return unit.label === camelCasedValue
        })

        // return the filtered value
        return unit[0].value

    } else {

        return ""
    }

}