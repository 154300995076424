import React from "react";
import { Route, Switch } from "react-router-dom";
//import ScriptTool from "./ScriptTool";
import OriginNMIMIRNTool from "./OriginNMIMIRNTool";
import BPIDGeneratorTool from "./BPIDGeneratorTool";
import InternalMIRNTool from "./InternalMIRNTool";
import AssignCompareBroadbandLeads from "./AssignCompareBroadbandLeads";
import AddToDNCRegister from "./AddToDNCRegister";
import BroadbandComparison from "./BroadbandComparison/BroadbandComparison";
import BroadbandResult from "./BroadbandResult/BroadbandResult";

const Tools = ({ match }) => (
    <Switch>
        <Route path={`${match.url}/originNMIMIRNTool`} component={OriginNMIMIRNTool} />
        <Route path={`${match.url}/internalNMIMIRNTool`} component={InternalMIRNTool} />
        {/* <Route path={`${match.url}/bpidGeneratorTool`} component={BPIDGeneratorTool}/> */}
        <Route path={`${match.url}/addToDNCRegister`} component={AddToDNCRegister} />
        <Route path={`${match.url}/assignCompareBroadbandLeads`} component={AssignCompareBroadbandLeads} />
        <Route path={`${match.url}/broadbandComparison`} component={BroadbandComparison} />
        <Route path={`${match.url}/broadband/result/:id`} component={BroadbandResult} />
    </Switch>
);

export default Tools;
