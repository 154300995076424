import React from "react";
import { connect } from "react-redux";
import { Breadcrumb, Divider, Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, InputNumber, DatePicker, Tag, Collapse, Upload, Icon } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getTariffRecords, searchTariffRecords, updateTariffSingleRecord, createTariffRecord, deleteTariffRecord, uploadTariffRecords, wipeAndUploadTariffRecords } from "appRedux/actions/admin/tariff";
import { getModuleAccessRecords, searchModuleAccessRecords, updateModuleAccessSingleRecord, createModuleAccessRecord, deleteModuleAccessRecord, clearError } from "appRedux/actions/admin/moduleAccess";
import { getAccessLevels } from "appRedux/actions/admin/accessLevels";

// import "./index.css";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import exportToCSV from 'util/exportToCSV'
import swal from 'sweetalert2';

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const { TextArea } = Input;
let retailerState = "";

//const title = () => 'Here is title';
const showHeader = true;
//const footer = () => 'Here is footer';
//const scroll = {y: 500};
const pagination = { position: 'bottom' };

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const EditableContext = React.createContext();

class EditableCell extends React.Component {

    getInput = () => {
        const {
            dataIndex,
            record,
        } = this.props;

        function onChange(checked) {
        }

        function handleChange(value) {
            retailerState = value.toString().split(',').join('|');
        }

        if (this.props.inputType === 'statusSelection') {
            return <Switch disabled={false} defaultChecked={record.isActive == 1 ? true : false} onChange={onChange} />;
        }
        else if (this.props.inputType === 'stateSelection') {
            return <Select
                style={{ width: '250px' }}
                placeholder="Please select State"
                onChange={handleChange}
            >
                <Option key="VIC">VIC</Option>
                <Option key="NSW">NSW</Option>
                <Option key="ACT">ACT</Option>
                <Option key="QLD">QLD</Option>
                <Option key="SA">SA</Option>
                <Option key="WA">WA</Option>
            </Select>;
        }
        return <Input style={{ minWidth: '150px' }} />;
    };

    renderCell = ({ getFieldDecorator }) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;

        function getInitialValue(dataIndex) {
            if (dataIndex == "isActive") {
                return record[dataIndex] == 1 ? true : false;
            }
            else if (dataIndex == "state") {
                retailerState = record[dataIndex];
                return record[dataIndex].split("|");
            }
            else {
                return record[dataIndex];
            }
        }

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item style={{ margin: 0, width: '100%' }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ],
                            initialValue: getInitialValue(dataIndex),
                        })(this.getInput())}
                    </Form.Item>
                ) : (
                        children
                    )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

class ManageModules extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editingKey: '',
            recordtoEdit: null,
            recordtoDelete: null,
            tariffTypeList: [],
            formFunction: null,
            isAddingUpdating: false,
            isSearching: false,
            recordsEdited: false,
            expand: false,
            dataSource: [],
            addingNewRecord: false,
            showAddEditPanel: false,
            showSearchPanel: true,
            showWarning: false,
            warningMessage: "",
            showSuccess: false,
            successMessage: "",
            showError: false,
            errorMessage: "",
            showWaiting: false,
            showWaitingOkButton: false,
            bordered: true,
            loading: false,
            pagination,
            size: 'middle',
            title: undefined,
            showHeader,
            footer: undefined,
            rowSelection: {},
            singleRecordDetails: {},
            fileList: [],
            accessLevelChildren:[],
            fieldState:{
                "id": false,
                "createdDate": false,
                "updatedDate": false,
                "module": false,
                "moduleType": false,
                "moduleDesc": false,
                "accessLevelIDs": false,
                "status": false,
            }
        };
        this.columns = [
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 150,
                render: (text, record) => {
                    const { editingKey } = this.state;
                    const editable = this.isEditing(record);
                    return editable ? (
                        <span>
                            <center>
                                <EditableContext.Consumer>
                                    {form => (
                                        <a
                                            onClick={() => this.save(form, record.key)}

                                        >
                                            <i className="icon icon-check-circle-o" />
                                        </a>
                                    )}
                                </EditableContext.Consumer>
                                <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                                    <a style={{ marginLeft: 10 }}><i className="icon icon-close-circle" /></a>
                                </Popconfirm>
                            </center>
                        </span>
                    ) : (
                            <span>
                                <center>
                                    <a onClick={() => this.viewRecord(record)}>
                                        <i className="icon icon-search-new" />
                                    </a>
                                    <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.editRecord(record)}>
                                        <i className="icon icon-edit" />
                                    </a>
                                    <a style={{ marginLeft: 10, color:"#52c41a"  }} disabled={editingKey !== ''} onClick={() => this.duplicateRecord(record)}>
                                        <i className="icon icon-copy" />
                                    </a>
                                    <a style={{ marginLeft: 10, color:"#f5222d" }} disabled={editingKey !== ''} onClick={() => this.confirmDeleteRecord(record)}>
                                        <i className="icon icon-close-circle" />
                                    </a>
                                    {/* <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.confirmDelete(record.id)}>
                    <i className="icon icon-trash" />
                  </a> */}
                                </center>
                            </span>
                        );
                },
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 150,
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: 'Created Date',
                dataIndex: 'createdDate',
                key: 'createdDate',
                align: 'center',
                width: 150,
                sorter: (a, b) => moment(a.createdDate, "YYYY-MM-DDTHH:mm:ss.SSSZ") - moment(b.createdDate, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
                render: (text, record) => {
                    return <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"geekblue"} key={text}>
                        {moment(text).format('YYYY-MM-DD h:mm:ss A')}
                    </Tag>
                }
            },
            {
                title: 'Updated Date',
                dataIndex: 'updatedDate',
                key: 'updatedDate',
                align: 'center',
                width: 150,
                sorter: (a, b) => moment(a.updatedDate, "YYYY-MM-DDTHH:mm:ss.SSSZ") - moment(b.updatedDate, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
                render: (text, record) => {
                    return <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"geekblue"} key={text}>
                        {moment(text).format('YYYY-MM-DD h:mm:ss A')}
                    </Tag>
                }
            },
            {
                title: 'Module',
                dataIndex: 'module',
                key: 'module',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.module.localeCompare(b.module) },
            },
            {
                title: 'Module Type',
                dataIndex: 'moduleType',
                key: 'moduleType',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.moduleType.localeCompare(b.moduleType) },
            },
            {
                title: 'Access Levels',
                dataIndex: 'accessLevelIDs',
                key: 'accessLevelIDs',
                align: 'center',
                width: 150,
                editable: false,
                // sorter: (a, b) => { return a.accessLevelIDs.localeCompare(b.accessLevelIDs) },
                render: (text, record) => {

                    console.log(record.id,text,this.getAccessLevelArray(text))
                    console.log("Access List",this.state.accessLevelChildren)



                    var compToDisplay = <Select
                                            disabled={true}
                                            mode="multiple"
                                            style={{width: '100%'}}
                                            placeholder="Please select"
                                            value={this.getAccessLevelArray(text)}
                                        >
                                            {this.state.accessLevelChildren}
                                        </Select>

                    return compToDisplay
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.status.localeCompare(b.status) },
                render: (text, record) => {

                    var valueToShow = "";
                    var colorToShow = "";

                    switch(text)
                    {
                        case 1 : valueToShow = "Active"; colorToShow = "green"; break;
                        case 2 : valueToShow = "Testing"; colorToShow = "orange"; break;
                        case 3 : valueToShow = "Developing"; colorToShow = "red"; break;
                    }

                    return <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={colorToShow} key={text}>
                        {valueToShow}
                    </Tag>
                }
            },
        ];

    }

    isEditing = record => record.key === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };
    
    disableAllFields = () => {
        
        this.setState({
            fieldState:{
                "id": true,
                "createdDate": true,
                "updatedDate": true,
                "module": true,
                "moduleType": true,
                "moduleDesc": true,
                "accessLevelIDs": true,
                "status": true,
            }
        })
    };

    getAccessLevelArray = (data) => {
        
        data = data.substring(0, data.length - 1);
        data = data.substring(1);
        const currentAccessLevels = data.split("|");

        return currentAccessLevels;
    };

    enableAllFields = () => {
        
        this.setState({
            fieldState:{
                "id": false,
                "createdDate": false,
                "updatedDate": false,
                "module": false,
                "moduleType": false,
                "moduleDesc": false,
                "accessLevelIDs": false,
                "status": false,
            }
        })
    };

    // save(form, key) {
    //     form.validateFields((error, row) => {
            
    //     });
    // }

    next() {
        const currentStep = this.state.currentStep + 1;
        this.setState({ currentStep });
    }

    prev() {
        const currentStep = this.state.currentStep - 1;
        this.setState({ currentStep });
    }

    duplicateRecord(record) {

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    }

    editRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.enableAllFields();

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Update",
            fieldState:{
                ...({createdDate:true, updatedDate:true})
            }
        });
    }

    viewRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "View",
        });
        this.disableAllFields();
    }

    confirmDeleteRecord = (record) => {
        this.setState({ recordtoDelete: record.id });
        this.setState({
            showWarning: true,
            warningMessage: `Are you sure you want delete record ID ${record.id}?`,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    onCancelDelete = () => {
        this.setState({ recordtoDelete: null });
        this.setState({
            showWarning: false,
            warningMessage: ``,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    handleDelete = () => {
        this.state.dataSource = [];

        this.props.deleteTariffRecord(this.state.recordtoDelete);
        console.log("Delete Record");
    };

    onConfirm = () => {
        this.setState({
            showSuccess: false,
            showError: false,
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleAdd = () => {
        this.handleReset();
        this.enableAllFields();
        this.setState({
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    };

    removeSearchValuesFromPayload = (values) => {

        delete values.txtModuleTypeSearch;
        delete values.txtModuleStatusSearch;

        return values;
    };

    handleAddRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if(!err)
            {
                values.accessLevelIDs = this.getAccessLevelArrayToString(values.accessLevelIDs);
                values = this.removeSearchValuesFromPayload(values);

                this.setState({ 
                    isAddingUpdating: true,
                    showSearchPanel: false,
                });
                this.state.dataSource = [];
                this.props.createModuleAccessRecord(values);
                console.log("Add Data");
            }

            
        });
    };

    handleUpdateRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log(values)
            delete values.createdDate;
            delete values.updatedDate;

            values.id = this.state.singleRecordDetails.id;

            values.accessLevelIDs = this.getAccessLevelArrayToString(values.accessLevelIDs);
            values = this.removeSearchValuesFromPayload(values);

            this.setState({ 
                isAddingUpdating: true,
                showSearchPanel: false,
            });
            this.state.dataSource = [];
            this.props.updateModuleAccessSingleRecord(values);
            console.log("Update Data");
        });
    };

    getAccessLevelArrayToString = (data) => {
        
        var string = "|";

        for (let i = 0; i < data.length; i++) {
            string = string + data[i] + "|";
        }

        return string;
    };

    onAddUpdateCancel = () => {
        this.setState({
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleClearSearch = () => {
        this.handleReset();
    };

    componentDidMount() {
        this.props.getAccessLevels();
        this.props.getModuleAccessRecords();
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
        console.log(values);

            const filtersForSearch = {
                ...(values.txtModuleTypeSearch != "-1" && {moduleType: values.txtModuleTypeSearch}),
                ...(values.txtModuleStatusSearch != "-1" && {status: values.txtModuleStatusSearch}),

            }
            this.setState({ isSearching: true });

            if(!isEmpty(filtersForSearch))
                this.props.searchModuleAccessRecords(filtersForSearch);
            else
            {
                this.props.getModuleAccessRecords();
                this.setState({ isSearching: false });
            }
        });
    };

    handleReset = () => {
        this.props.form.resetFields();

        this.setState({ 
            singleRecordDetails: null,
            showSearchPanel: true,
            showAddEditPanel: false,
        });
        this.setState({ recordtoEdit: null });

        this.props.getModuleAccessRecords();
    };

    checkColoumnType = (field) => {
        switch (field) {
            case 'isActive': return "statusSelection";
            case 'state': return "stateSelection";
            default: return "text";
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!isEmpty(nextProps.moduleRecords)) {
            this.setState({ loading: true });

            this.state.dataSource = nextProps.moduleRecords;

            this.setState({ 
                loading: false,
                isSearching : false });
        }
        else {
            this.setState({ loading: true });

            this.state.dataSource = [];

            this.setState({ 
                loading: false,
                isSearching: false});
        }

        if (!isEmpty(nextProps.accessLevels)) {

            const children = [];

            for (let i = 0; i < Object.values(nextProps.accessLevels).length; i++) {
                        
                var currentAccessLevel = nextProps.accessLevels[i];
                
                children.push(<Option key={currentAccessLevel.id}>{currentAccessLevel.name}</Option>);
            }

            this.state.accessLevelChildren = children;
        }

        if (!isEmpty(nextProps.recordUpdateStatus)) {
            if (nextProps.recordUpdateStatus.status == "Success") {
                this.setState({
                    successMessage: nextProps.recordUpdateStatus.message,
                    showSuccess: true,
                    showWarning: false,
                    warningMessage: "",
                });
                this.setState({
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                swal.close();
                console.log("New Update Request");
                this.props.getModuleAccessRecords();
            }
        }

        if (!isEmpty(nextProps.error)) {
            if (nextProps.error != "") {
                this.setState({
                    errorMessage: nextProps.error,
                    showError: true,
                    successMessage: "",
                    showSuccess: false,
                });
                this.setState({
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                console.log("Error");
                this.props.clearError();
                this.props.getModuleAccessRecords();
            }
        }
    }


    render() {

        let { dataSource } = this.state;
        const { fileList } = this.state;

        const components = {
            body: {
                cell: EditableCell,
            },
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: this.checkColoumnType(col.dataIndex),
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-main-content">
                <ContainerHeader title="Manage Modules" />
                <Row>
                    {this.state.showSearchPanel && <Col span={24}>
                        <Spin spinning={this.state.isSearching}>
                            <Card className="gx-card" title={`Search Module Records`} style={{ maxHeight: "550px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                                >
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Module Type" >
                                                    {getFieldDecorator('txtModuleTypeSearch', {
                                                        initialValue: "-1",
                                                        })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Module Types"
                                                        >
                                                            <Option value="-1">All Modules Types</Option>
                                                            <Option value="Module">Module</Option>
                                                            <Option value="Component">Component</Option>
                                                            <Option value="Page">Page</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Module Status" >
                                                    {getFieldDecorator('txtModuleStatusSearch', {
                                                        initialValue: "-1",
                                                        })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Module Status"
                                                        >
                                                            <Option value="-1">All Modules Status</Option>
                                                            <Option value="1">Active</Option>
                                                            <Option value="2">Testing</Option>
                                                            <Option value="3">Developing</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            <Button type="primary" onClick={this.handleSearch}>
                                                Search
                      </Button>
                                            <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                                                Clear
                      </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    {this.state.showAddEditPanel && <Col span={24}>
                        <Spin spinning={this.state.isAddingUpdating}>
                            <Card className="gx-card" title={`${this.state.formFunction} Module Details`} style={{ maxHeight: "650px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                                >
                                    <Collapse accordion defaultActiveKey={['1']}>
                                        <Panel header="Basic Details" key="1">
                                            <Row>
                                                {this.state.formFunction != "Add" &&
                                                    <Col lg={8} md={8} sm={12} xs={24}>
                                                        <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Created Date" >
                                                            {getFieldDecorator('createdDate', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.createdDate,"YYYY-MM-DDTHH:mm:ss.SSSZ") : moment(),
                                                            rules: [{ required: true, message: 'Please select Created Date!' }],
                                                            })(
                                                            <DatePicker disabled={this.state.fieldState.createdDate} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'}/>
                                                            )}
                                                        </FormItem>
                                                        </div>
                                                    </Col>
                                                }
                                                {this.state.formFunction != "Add" &&
                                                    <Col lg={8} md={8} sm={12} xs={24}>
                                                        <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Updated Date" >
                                                            {getFieldDecorator('updatedDate', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.updatedDate,"YYYY-MM-DDTHH:mm:ss.SSSZ") : moment(),
                                                            rules: [{ required: true, message: 'Please select Updated Date!' }],
                                                            })(
                                                            <DatePicker disabled={this.state.fieldState.updatedDate} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'}/>
                                                            )}
                                                        </FormItem>
                                                        </div>
                                                    </Col>
                                                }
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Module Name" >
                                                            {getFieldDecorator('module', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.module : "",
                                                            rules: [{ required: true, message: 'Please enter module name' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.module} style={{ width: '100%' }} placeholder="Enter Module Name" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Module Type" >
                                                            {getFieldDecorator('moduleType', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.moduleType : undefined,
                                                            rules: [{ required: true, message: 'Please enter module description!' }],
                                                            })(
                                                                <Select
                                                                disabled={this.state.fieldState.moduleType}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Module">Module</Option>
                                                                    <Option key="Component">Component</Option>
                                                                    <Option key="Page">Page</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Module Description" >
                                                            {getFieldDecorator('moduleDesc', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.moduleDesc : "",
                                                            rules: [{ required: true, message: 'Please enter module description' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.moduleDesc} style={{ width: '100%' }} placeholder="Enter Module Description" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Access Levels" >
                                                            {getFieldDecorator('accessLevelIDs', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? this.getAccessLevelArray(this.state.singleRecordDetails.accessLevelIDs) : undefined,
                                                            rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.accessLevelIDs}
                                                                    mode="multiple"
                                                                    style={{width: '100%'}}
                                                                    placeholder="Please select"
                                                                >
                                                                {this.state.accessLevelChildren}
                                                            </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Status" >
                                                            {getFieldDecorator('status', {
                                                            initialValue: !isEmpty(this.state.singleRecordDetails) ? (this.state.singleRecordDetails.status).toString() : undefined,
                                                            rules: [{ required: true, message: 'Please enter module description!' }],
                                                            })(
                                                                <Select
                                                                disabled={this.state.fieldState.status}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Status"
                                                                >
                                                                    <Option key="1">Active</Option>
                                                                    <Option key="2">Testing</Option>
                                                                    <Option key="3">Developing</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Panel>
                                    </Collapse>

                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            {this.state.formFunction == "Add" &&
                                                <Button type="primary" onClick={this.handleAddRecord}>
                                                    Add
                        </Button>
                                            }
                                            {this.state.formFunction == "Update" &&
                                                <Button type="primary" onClick={this.handleUpdateRecord}>
                                                    Update
                        </Button>
                                            }
                                            <Button style={{ marginLeft: 8 }} onClick={this.onAddUpdateCancel}>
                                                Cancel
                      </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    <Col span={24}>
                        <Card className="gx-card" title="Module Details">
                            <Button onClick={this.handleAdd} type="primary" className="gx-btn-cyan" style={{ marginBottom: 16 }}>
                                <Icon type="plus"/>  Add New Module Details
                            </Button>
                            <EditableContext.Provider value={this.props.form}>
                                <Table
                                    components={components}
                                    rowClassName="editable-row"
                                    className="gx-table-responsive"
                                    {...this.state}
                                    pagination={{ pageSize: 10 }}
                                    columns={columns}
                                    dataSource={dataSource} />
                            </EditableContext.Provider>
                        </Card>
                    </Col>


                </Row>
                <SweetAlert show= {this.state.showWaiting}
                    title= {'Please wait..'}
                    buttons= {false}
                />
                <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showError} error title={this.state.errorMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showWarning}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={this.state.warningMessage}
                    onConfirm={this.handleDelete}
                    onCancel={this.onCancelDelete}
                ></SweetAlert>
            </div>
        );
    }
}

const mapStateToProps = state => ({

    accessLevels: state.accesslevels.accessLevelList,

    moduleRecords: state.moduleAccess.resultList,
    recordUpdateStatus: state.moduleAccess.recordUpdateStatus,
    error: state.moduleAccess.error,
});

const mapDispatchToProps = dispath => ({

    getAccessLevels: () => dispath(getAccessLevels()),

    getModuleAccessRecords: () => dispath(getModuleAccessRecords()),
    searchModuleAccessRecords: (data) => dispath(searchModuleAccessRecords(data)),
    updateModuleAccessSingleRecord: (data) => dispath(updateModuleAccessSingleRecord(data)),
    createModuleAccessRecord: (data) => dispath(createModuleAccessRecord(data)),
    deleteModuleAccessRecord: (data) => dispath(deleteModuleAccessRecord(data)),


    getTariffRecords: () => dispath(getTariffRecords()),
    searchTariffRecords: (data) => dispath(searchTariffRecords(data)),
    updateTariffSingleRecord: (data) => dispath(updateTariffSingleRecord(data)),
    createTariffRecord: (data) => dispath(createTariffRecord(data)),
    deleteTariffRecord: (data) => dispath(deleteTariffRecord(data)),
    uploadTariffRecords: (data) => dispath(uploadTariffRecords(data)),
    wipeAndUploadTariffRecords: (data) => dispath(wipeAndUploadTariffRecords(data)),

    clearError: () => dispath(clearError()),

});

const WrappedAdvancedSearch = Form.create()(ManageModules);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);