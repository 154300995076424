import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Tag, Input, Row, Button, Select, InputNumber, Popconfirm, Switch, Modal, DatePicker } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getUsers } from "appRedux/actions/admin/users";
import { getProcessSaleLogs, clearErrors } from "appRedux/actions/sales/processSales";
import { getRetailerList } from "appRedux/actions/admin/retailers";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import Widget from "components/Widget/index";

import exportFromJSON from 'export-from-json'

const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const RangePicker = DatePicker.RangePicker;

const showHeader = true;
const pagination = { position: 'bottom' };

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class ProcessSaleLogs extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isSearching: false,
      expand: false,
      dataSource: [],
      dataSourceOrg: [],
      bordered: true,
      loading: false,
      pagination,
      size: 'middle',
      title: undefined,
      showHeader,
      footer: undefined,
      rowSelection: {},
      startDate: null,
      endDate: null,
      recordCount: 0,
      showError: false,
      errorMessage: "",
    };

    this.columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        align: "center",
        width: 50,
        //defaultSortOrder: 'descend',
        sorter: (a, b) => a.id - b.id,
        render: (text, record) => {
          return <span style={{ fontSize: "12px" }}>{text}</span>;
        }
      },
      {
        title: "Created Date",
        dataIndex: "createdDate",
        key: "createdDate",
        align: "center",
        width: 200,
        //defaultSortOrder: 'descend',
        sorter: (a, b) =>
          moment(a.createdDate, "YYYY-MM-DD h:mm:ss A") -
          moment(b.createdDate, "YYYY-MM-DD h:mm:ss A"),
        render: (text, record) => {
          return (
            <Tag
              style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }}
              color={"geekblue"}
              key={text}
            >
              {text.toUpperCase()}
            </Tag>
          );
        }
      },
      {
        title: "User",
        dataIndex: "user",
        key: "user",
        align: "center",
        width: 100,
        render: (text, record) => {
          return <span style={{ fontSize: "12px" }}>{text}</span>;
        }
      },
      {
        title: "Lead ID",
        dataIndex: "leadId",
        key: "leadId",
        align: "center",
        width: 100,
        sorter: (a, b) => a.id - b.id,
        render: (text, record) => {
          let tempLeadID = text;

          try{
            if (text.includes("EW0")) {
              tempLeadID = tempLeadID.replace("EW0", "");
              tempLeadID = tempLeadID.replace(/\_.*/g, "$'");
            }
          } catch (err) { }          

          var url = `https://lm.prod.velocify.com/Web/LeadAddEdit.aspx?LeadId=${tempLeadID}`;
          return (
            <a href={url} target="_blank">
              {tempLeadID}
            </a>
          );
        }
      },
      {
        title: "Correlation ID",
        dataIndex: "correlationId",
        key: "correlationId",
        align: "center",
        width: 230,
        render: (text, record) => {
          return <span style={{ fontSize: "12px" }}>{text}</span>;
        }
      },
      {
        title: "Batch No",
        dataIndex: "batchRefNo",
        key: "batchRefNo",
        align: "center",
        width: 100,
        sorter: (a, b) => a.id - b.id,
        render: (text, record) => {
          return <span style={{ fontSize: "12px" }}>{text}</span>;
        }
      },
      {
        title: "Sale Type",
        dataIndex: "saleType",
        key: "saleType",
        align: "center",
        width: 100,
        render: (text, record) => {
          return <span style={{ fontSize: "12px" }}>{text}</span>;
        }
      },
      {
        title: "Information Sent",
        dataIndex: "payloadData",
        key: "payloadData",
        align: "center",
        width: 100,
        editable: false,
        render: (text, record) => {
          var content = "";
          if (!isEmpty(record.payloadData) && record.payloadData != "Error") {
            content = (
              <Button
                type="primary"
                style={{ marginLeft: 8 }}
                onClick={() => this.handleShowPayLoadData(record.id)}
              >
                View
              </Button>
            );
          } else {
            content = (
              <Button
                type="danger"
                style={{ marginLeft: 8 }}
                onClick={() => this.handleShowPayLoadData(record.id)}
              >
                View
              </Button>
            );
          }

          return content;
        }
      },
      {
        title: "Processing Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 100,
        render: (text, record) => {
          return <span style={{ fontSize: "12px" }}>{text}</span>;
        }
      },
      {
        title: "Processing Result",
        dataIndex: "responseData",
        key: "responseData",
        align: "center",
        width: 100,
        editable: false,
        render: (text, record) => {
          var content = "";
          if (!isEmpty(record.responseData) && record.responseData != "Error") {
            content = (
              <Button
                type="primary"
                style={{ marginLeft: 8 }}
                onClick={() => this.handleShowResponseData(record.id)}
              >
                View
              </Button>
            );
          } else {
            content = (
              <Button
                type="danger"
                style={{ marginLeft: 8 }}
                onClick={() => this.handleShowResponseData(record.id)}
              >
                View
              </Button>
            );
          }

          return content;
        }
      },
      {
        title: "Sale Updated Date",
        dataIndex: "updatedDate",
        key: "updatedDate",
        align: "center",
        width: 200,
        sorter: (a, b) =>
          moment(a.updatedDate, "YYYY-MM-DD hh:mm:ss A") -
          moment(b.updatedDate, "YYYY-MM-DD hh:mm:ss A"),
        render: (text, record) => {
          return (
            <Tag
              style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }}
              color={"volcano"}
              key={text}
            >
              {text.toUpperCase()}
            </Tag>
          );
        }
      },
      {
        title: "Order Status",
        dataIndex: "orderStatus",
        key: "orderStatus",
        align: "center",
        width: 100,
        render: (text, record) => {
          return <span style={{ fontSize: "12px" }}>{text}</span>;
        }
      },
      {
        title: "Order Result",
        dataIndex: "orderDetails",
        key: "orderDetails",
        align: "center",
        width: 100,
        editable: false,
        render: (text, record) => {
          var content = "";
          if (!isEmpty(text) && text != "-") {
            if (
              !isEmpty(record.orderDetails) &&
              record.orderDetails != "Error"
            ) {
              content = (
                <Button
                  type="primary"
                  style={{ marginLeft: 8 }}
                  onClick={() => this.handleShowOrderDetails(record.id)}
                >
                  View
                </Button>
              );
            } else {
              content = (
                <Button
                  type="danger"
                  style={{ marginLeft: 8 }}
                  onClick={() => this.handleShowOrderDetails(record.id)}
                >
                  View
                </Button>
              );
            }
          }

          return content;
        }
      },
      {
        title: "Order Updated Date",
        dataIndex: "orderUpdatedDate",
        key: "orderUpdatedDate",
        align: "center",
        width: 200,
        sorter: (a, b) =>
          moment(a.orderUpdatedDate, "YYYY-MM-DD hh:mm:ss A") -
          moment(b.orderUpdatedDate, "YYYY-MM-DD hh:mm:ss A"),
        render: (text, record) => {
          return (
            !isEmpty(text) &&
            text != "-" && (
              <Tag
                style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }}
                color={"volcano"}
                key={text}
              >
                {text.toUpperCase()}
              </Tag>
            )
          );
        }
      }
    ];

  }

  handleShowPayLoadData = (recordID) => {

    var recordData = this.state.dataSource.find(data => data.id == recordID);

    Modal.info({
      title: 'Payload Data',
      width: '350',
      centered: true,
      maskClosable: true,
      content: (
        <div>
          <div style={{ overflow: 'auto', maxHeight: 450, minWidth: 600, maxWidth: 600 }}><pre style={{whiteSpace:"pre-wrap"}}>{recordData.payloadData}</pre></div>
        </div>
      ),
      onOk() {
      },
    });
  };

  handleShowResponseData = (recordID) => {

    var recordData = this.state.dataSource.find(data => data.id == recordID);

    Modal.info({
      title: 'Response Data',
      width: '350',
      centered: true,
      maskClosable: true,
      content: (
        <div>
          <div style={{ overflow: 'auto', maxHeight: 450, minWidth: 600, maxWidth: 600 }}><pre style={{whiteSpace:"pre-wrap"}}>{recordData.responseData}</pre></div>
        </div>
      ),
      onOk() {
      },
    });
  };

  handleShowOrderDetails = (recordID) => {

    var recordData = this.state.dataSource.find(data => data.id == recordID);

    Modal.info({
      title: 'Response Data',
      width: '350',
      centered: true,
      maskClosable: true,
      content: (
        <div>
          <div style={{ overflow: 'auto', maxHeight: 450, minWidth: 600, maxWidth: 600 }}><pre style={{whiteSpace:"pre-wrap"}}>{recordData.orderDetails}</pre></div>
        </div>
      ),
      onOk() {
      },
    });
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
    });
    this.handleReset();
  };

  onErrorMessageConfirm = () => {
    this.setState({
        errorMessage: "",
        showError: false,
        isSearching: false,
        loading: false,
    });
    this.props.clearErrors();
};

  componentDidMount() {
    this.props.getUsers();
    this.setState({ loading: true, isSearching: true  });
    this.props.getProcessSaleLogs();
    this.props.getRetailerList();
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      const filtersForSearch = {
        correlationId: values.txtCorrelationID,
        userId: values.drpUser, //Currently passing User Name
        retailer: values.drpRetailer != "-1" ? values.drpRetailer : "",
        leadId: values.txtLeadID,
        status: values.txtStatus,
        batchRefNo: values.txtBatchRefNo,
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        transactionType: values.drpRetailer != "-1" ? values.drpSaleType : "",
      }

      this.setState({ isSearching: true });
      this.setState({ loading: true });
      this.props.getProcessSaleLogs(filtersForSearch);
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.props.getUsers();
    this.props.getProcessSaleLogs();
    this.props.getRetailerList();
  };

  handleExportToExcel = (e) => {
    const data = this.state.dataSourceOrg;

    const fileName = 'API Sale Submission Logs - ' + moment().format("DDMMYYYY_HHmmss")
    const exportType = 'csv'
    
    exportFromJSON({ data, fileName, exportType })
  };

  handleDateRangeChange = (dates, dateStrings) => {
    this.setState({
      startDate: moment(dateStrings[0],"YYYY/MM/DD").format("YYYY-MM-DD"),
      endDate: moment(dateStrings[1],"YYYY/MM/DD").format("YYYY-MM-DD"),
    })
  };


  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.processSaleLogs)) {
      this.setState({ loading: true });
      this.state.dataSource = [];
      this.state.dataSourceOrg = [];
      let count = 1;
      nextProps.processSaleLogs.map((saleLog, key) => {

        var payloadDataFinal = "";

        try{
          payloadDataFinal = JSON.stringify(JSON.parse(saleLog.payloadData), null, 4);
        }
        catch(err){
          payloadDataFinal = "Error";
        }

        var responseDataFinal = "";

        try{
          responseDataFinal = JSON.stringify(JSON.parse(saleLog.responseData), null, 4);
        }
        catch(err){
          responseDataFinal = "Error";
        }

        var orderDetailsDataFinal = "";

        try{
          if(!isEmpty(saleLog.returnPayload))
            orderDetailsDataFinal = JSON.stringify(JSON.parse(saleLog.returnPayload), null, 4);
          else
          orderDetailsDataFinal = "-";
        }
        catch(err){
          orderDetailsDataFinal = "Error";
        }


        let record = {
          key: count.toString(),
          id: count.toString(),
          createdDate: moment(saleLog.createdDate).format('YYYY-MM-DD h:mm:ss A'),
          updatedDate: moment(saleLog.updateDate).format('YYYY-MM-DD h:mm:ss A'),
          orderUpdatedDate: !isEmpty(saleLog.orderUpdateDate) ? moment(saleLog.orderUpdateDate).format('YYYY-MM-DD h:mm:ss A') : "-",
          leadId: !isEmpty(saleLog.leadId) ? saleLog.leadId : "-",
          status: !isEmpty(saleLog.status) ? saleLog.status : "-",
          orderStatus: !isEmpty(saleLog.orderStatus) ? saleLog.orderStatus : "-",
          user: !isEmpty(saleLog.userId) ? saleLog.userId : "-",
          saleType: !isEmpty(saleLog.transactionType) ? saleLog.transactionType : "-",
          correlationId: !isEmpty(saleLog.correlationId) ? saleLog.correlationId : "-",
          batchRefNo: !isEmpty(saleLog.batchRefNo) ? saleLog.batchRefNo : "-",
          payloadData: payloadDataFinal,
          responseData: responseDataFinal,
          orderDetails:  orderDetailsDataFinal,
        };

        var tempObj = {
          "ID" : count.toString(),
          "Lead ID" : !isEmpty(saleLog.leadId) ? saleLog.leadId : "-",
          "DateTime Sale Sent" : moment(saleLog.createdDate).format('YYYY-MM-DD h:mm:ss A'),
          "DateTime Sale Update" : moment(saleLog.updateDate).format('YYYY-MM-DD h:mm:ss A'),
          "DateTime Order Update" : !isEmpty(saleLog.orderUpdateDate) ? moment(saleLog.orderUpdateDate).format('YYYY-MM-DD h:mm:ss A') : "-",
          "Batch ID" : !isEmpty(saleLog.batchRefNo) ? saleLog.batchRefNo : "-",
          "Correlation ID" : !isEmpty(saleLog.correlationId) ? saleLog.correlationId : "-",
          "Sale Type" : !isEmpty(saleLog.transactionType) ? saleLog.transactionType : "-",
          "Sale Sent By" : !isEmpty(saleLog.userId) ? saleLog.userId : "-",
          "Sale Status" : !isEmpty(saleLog.status) ? saleLog.status : "-",
          "Sale Response" : saleLog.responseData,
          "Order Status" : !isEmpty(saleLog.orderStatus) ? saleLog.orderStatus : "-",
          "Order Errors" : !isEmpty(saleLog.orderErrors) ? saleLog.orderErrors : "-",
        }
        this.state.dataSourceOrg.push(tempObj);
        this.state.dataSource.push(record);
        count++;
      });
      this.setState({ 
        loading: false,
        isSearching: false,
        recordCount: (count-1),
       });
      this.setState({ isSearching: false });
    }

    else {
      // this.setState({ loading: true });
      // this.state.dataSource = [];
      // this.setState({ 
      //   loading: false,        
      //   isSearching: false,
      //   recordCount: 0,
      //  });
    }

    if (!isEmpty(nextProps.error)) {
      if (isEmpty(this.state.errorMessage) && this.state.showError == false) {
          this.setState({
              errorMessage: nextProps.error,
              showError: true,
          });
      }
    }
  }


  render() {

    let { dataSource } = this.state;

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });

    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-main-content">
        <ContainerHeader title="Process Sale Logs" />
        <Row>
          <Col span={24}>
            
              <Card className="gx-card" title="Search Sale Log">
              <Spin spinning={this.state.isSearching}>
                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleSearch}
                >
                  <Row>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Log Date Range">
                          {getFieldDecorator('drpDateRange', {
                            rules: [{ required: false, message: 'Please select a Date Range' }],
                          })(
                            <RangePicker className="gx-w-100"
                              ranges={{ Today: [moment(), moment()], 'This Month': [moment(), moment().endOf('month')] }}
                              format="YYYY/MM/DD"
                              defaultValue={[moment(), moment()]}
                              onChange={this.handleDateRangeChange}
                            />
                          )}
                        </FormItem>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Lead ID" >
                          {getFieldDecorator('txtLeadID', {
                            rules: [{ required: false, message: 'Enter Lead ID!' }],
                          })(
                            <Input

                              style={{ width: '100%' }} placeholder="Enter Lead ID" />
                          )}
                        </FormItem>
                      </div>
                    </Col>

                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Batch Ref No" >
                          {getFieldDecorator('txtBatchRefNo', {
                            rules: [{ required: false, message: 'Enter Batch Ref No!' }],
                          })(
                            <Input

                              style={{ width: '100%' }} placeholder="Enter Batch Ref No" />
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Correlation ID" >
                          {getFieldDecorator('txtCorrelationID', {
                            rules: [{ required: false, message: 'Enter Correlation ID!' }],
                          })(
                            <Input

                              style={{ width: '100%' }} placeholder="Enter Correlation ID" />
                          )}
                        </FormItem>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Retailer">
                          {getFieldDecorator('drpRetailer', {
                            rules: [{ required: false, message: 'Please select Retailer!' }],
                          })(
                            <Select

                              showSearch
                              style={{ width: '100%' }}
                              placeholder="Select Retailer"
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                              <Option value="-1">Select</Option>
                              {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                return <option key={key} value={retailer.id}>{retailer.retailerName}</option>;
                              })}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="User">
                          {getFieldDecorator('drpUser', {
                            rules: [{ required: false, message: 'Please select Retailer!' }],
                          })(
                            <Select

                              showSearch
                              style={{ width: '100%' }}
                              placeholder="Select User"
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                              <Option value="-1">Select</Option>
                              {!isEmpty(this.props.userList) && this.props.userList.map((user, key) => {
                                return <option key={key} value={user.name}>{user.name}</option>;
                              })}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    </Row>
                    <Row>                   
                      <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                          <FormItem {...formItemLayout} label="Status" >
                            {getFieldDecorator('txtStatus', {
                              rules: [{ required: false, message: 'Enter Status!' }],
                            })(
                              <Input

                                style={{ width: '100%' }} placeholder="Enter Status" />
                            )}
                          </FormItem>
                        </div>
                      </Col>
                      <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Sale Type">
                          {getFieldDecorator('drpSaleType', {
                            rules: [{ required: false, message: 'Please select Sale Type!' }],
                          })(
                            <Select

                              showSearch
                              style={{ width: '100%' }}
                              placeholder="Select Sale Type"
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                              <Option value="-1">Select</Option>
                              <Option value="Sale">Sale</Option>
                              <Option value="SDFI">SDFI</Option>
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>                    
                    </Row> 
                    <Row>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" htmlType="submit">Search</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                          Clear
                    </Button>
                      </center>
                    </Col>
                  </Row>
                </Form>
                </Spin>
              </Card>
            
          </Col>
          {this.state.loading && <Col md={24}>
              <Widget styleName="gx-card-full gx-text-center gx-pt-4 gx-pb-3 gx-px-3">
                <div className="gx-separator gx-bg-primary"/>
                <h2 className="gx-mb-4 gx-text-primary">Loading Data...</h2>
                <Spin spinning={this.state.loading}></Spin>
              </Widget>
            
          </Col>}
          {this.state.loading == false && <Col span={24}>
            <Card className="gx-card" title={`Process Sale Log Details (${this.state.recordCount})`}>
              <Button onClick={this.handleExportToExcel} type="primary" style={{ marginBottom: 16 }}>
                Export Results
              </Button>
              <Table
                rowClassName="editable-row"
                className="gx-table-responsive"
                {...this.state}
                columns={columns}
                dataSource={dataSource} />
            </Card>
          </Col>}
        </Row>
        <SweetAlert show={this.state.showError} error title={this.state.errorMessage}
            onConfirm={this.onErrorMessageConfirm}>
        </SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userList: state.users.userList,
  processSaleLogs: state.processSales.processSaleLogs,
  retailerList: state.retailers.retailerList,

  error: state.processSales.error
});

const mapDispatchToProps = dispath => ({
  getProcessSaleLogs: (data) => dispath(getProcessSaleLogs(data)),
  getUsers: (data) => dispath(getUsers(data)),
  getRetailerList: (data) => dispath(getRetailerList(data)),
  clearErrors: () => dispath(clearErrors()),
});

const WrappedAdvancedSearch = Form.create()(ProcessSaleLogs);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);