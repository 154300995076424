import React from 'react'
import { connect } from "react-redux";
import { Spin, Card, Col, Form, Input, Row, Button, Steps, Select } from "antd";
import ContainerHeader from "components/ContainerHeader/index";
import { isEmpty } from 'lodash';
import AddressFinder from './AddressFinder';
import AddressSummary from './AddressSummary';
import BroadbandQuestions from './BroadbandQuestions';
import { getSingleBBLeadDetails, createSingleLeadDetail, clearErrors, clearAll, } from "appRedux/actions/sales/leads";
import SweetAlert from "react-bootstrap-sweetalert";
import { resetAddress, validateAddress } from '../../../appRedux/actions/tools/address';
import { clearProducts, getProductDetails } from '../../../appRedux/actions/sales/products';
import { setLeadDetailsReset } from '../../../appRedux/actions/sales/leads';

import styles from './styles.module.less'
const { Step } = Steps;
const FormItem = Form.Item;
const { Option } = Select;


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};


const company = [
    {
        value: 'BC',
        label: 'Broadband.Compare'
    },
    {
        value: 'EW',
        label: 'Electricity Wizard'
    },
    {
        value: 'DR',
        label: 'Deal Reveal'
    },
    {
        value: 'HC',
        label: 'Health Compare'
    }
]
class BroadbandComparison extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isSearching: false,
            showSearchPanel: false,
            newLead: false,
            current: 0,
            leadDetails: {
                leadDetails: {},
                comparisonDetails: {}
            },
            errorMessage: "",
            showError: false,
            address: {},
            submitForm: false,
            loaded: false,
            newLeadCreated: false,
            createNewDealButtonDisabled: false,
            fetchingLeadDetails: false,
            creatingNewLead: false,
            addressFetching: false,
            productApiLoading: false,
            redirectNew: false,
            backToAddress: false
        }
    }

    componentWillReceiveProps(nextProps) {

        // navigate to address search when successful deal create 
        if (!isEmpty(nextProps.singleLeadDetails) && nextProps.status === 'success' && this.state.current === 0) {

            this.setState({
                leadDetails: { leadDetails: { ...nextProps.singleLeadDetails }, ...this.state.leadDetail },
                current: !this.state.newLead && nextProps.redirectToCreate ? 0 : 1,
                newLeadCreated: true,
                createNewDealButtonDisabled: false,
                redirectNew: nextProps.redirectToCreate ? nextProps.redirectToCreate : false
            })
        }

        // set leads loading message show
        if (nextProps.status === 'loading' && this.state.current === 0) {

            this.setState({ fetchingLeadDetails: true })


            if (nextProps.redirectToCreate && !isEmpty(nextProps.recordUpdateStatus) && nextProps.recordUpdateStatus !== 'loading') {


                setTimeout(() => {
                    this.setState({ current: 1 })
                }, 700);


            }

        } else {

            this.setState({ fetchingLeadDetails: false })

        }

        // show create new lead message

        if (!isEmpty(nextProps.recordUpdateStatus) && nextProps.recordUpdateStatus === 'loading') {

            this.setState({ creatingNewLead: true })

        } else {



            this.setState({ creatingNewLead: false })

        }

        // request the newly created deal when deal creation success

        if (!isEmpty(nextProps.recordUpdateStatus) && nextProps.recordUpdateStatus !== 'loading' && nextProps.status === 'idl' && !this.state.showError) {


            this.props.getSingleBBLeadDetails(nextProps.recordUpdateStatus.ImportResults.ImportResult._attributes.leadId, this.props.userDetails.email);

        }

        // show error notification when error occur 
        if (!isEmpty(nextProps.error)) {
            if (nextProps.error != "") {

                this.setState({
                    errorMessage: nextProps.error,
                    showError: true
                });

                this.props.clearErrors();
            }
        }

        // set address details when successfully address fetched 
        if (nextProps.address && nextProps.address.validatedAddress) {

            this.setState({ address: { ...nextProps.address.validatedAddress } })

        }

        // show Address Fetching Message
        if (nextProps.address && nextProps.addressApiStatus === 'loading') {

            this.setState({ addressFetching: true })

        } else {

            this.setState({ addressFetching: false })

        }

        // set product api loading
        if (nextProps.products && nextProps.products.status === 'loading') {

            this.setState({ productApiLoading: true })

        } else {

            this.setState({ productApiLoading: false })

        }
        // navigate to result page when comparison success
        if (nextProps.products && nextProps.products.status === 'success' && nextProps.products.productsDetails && nextProps.products.productsDetails.uuid
        ) {

            this.props.history.push(`/tools/broadband/result/${nextProps.products.productsDetails.uuid}`);
        }

    }


    onConfirm = () => {
        this.setState({
            showError: false
        });
    };

    next = () => {
        this.setState({ current: this.state.current + 1 });
    };

    prev = () => {
        this.setState({ current: this.state.current - 1 });
    };


    handleSearchLeadIdOnKeyDown = () => {

    }

    handleSearchLeadId = async (e) => {

        this.setState({ showError: false });

        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            this.props.getSingleBBLeadDetails(values.tetxLeadIdToSearch, this.props.userDetails.email);
        });
    }

    handleCreateLead = async (e) => {


        this.setState({
            createNewDealButtonDisabled: true,
            showError: false
        })
        e.preventDefault();

        this.props.form.validateFields((err, values) => {

            if (!err) {

                const nameArr = values.Name.split(' ');

                let obj = {
                    FirstName: { _text: nameArr[0] },
                    Surname: { _text: !isEmpty(nameArr[1]) ? nameArr[1] : `` },
                    MobileNumber: { _text: values.MobileNumber },
                    Email_x0028_M_x0029_: { _text: values.Email_x0028_M_x0029_ },
                    CompanyIndustry: { _text: values.CompanyIndustry },
                    AgentEmail: { _text: this.props.userDetails.email },
                    LeadCallType: { _text: values.LeadCallType },
                }

                let data = {
                    leadDetails: JSON.stringify(obj)
                }

                this.props.createSingleLeadDetail(data);

            } else {

                this.setState({ createNewDealButtonDisabled: false })

            }

        });
    }

    handlePreviousStepNavigation = () => {

        this.setState({ current: this.state.current - 1 })

        if (this.state.current - 1 === 1) {

            this.setState({ backToAddress: true })
        }
    }

    handleClearSearch = () => {

    }

    setNewLead = () => {

        this.props.resetLeadDetails();

        this.props.clearProducts();

        this.props.resetAddress()


        this.setState({
            newLead: true
        })
    }

    setPreLead = () => {
        this.setState({
            newLead: false
        })
    }

    handleOnComplete = (data, id, status, leadObj) => {

        const tempLeadDetails = {
            ...this.state.leadDetails,

        }


        if (id) {

            tempLeadDetails[id] = {
                ...data
            }

            tempLeadDetails.leadDetails = {
                ...tempLeadDetails.leadDetails,
                ...leadObj
            }

        } else {


            tempLeadDetails.leadDetails = {
                ...tempLeadDetails.leadDetails,
                ...data,
                ...leadObj
            }

            this.setState({ leadDetails: { ...tempLeadDetails } })

            this.setState({ current: this.state.current + 1 })

        }



        if (status === 'done') {

            this.props.getProductDetails(tempLeadDetails);


        }

    }

    componentDidUpdate() {

        if (!this.state.loaded) {

            this.setState({ loaded: true });

            this.props.resetLeadDetails();

            this.props.clearProducts();

            this.props.resetAddress()

        }

    }


    render() {
        const { getFieldDecorator } = this.props.form
        const { current, newLead, isSearching, redirectNew, leadDetails } = this.state

        return (
            <div className="gx-main-content">
                <ContainerHeader title={`Compare Broadband`} />
                <>


                    <Steps current={current}>
                        <Step key={0}>
                        </Step>
                        <Step key={1}>
                        </Step>
                        <Step key={2}>
                        </Step>
                        <Step key={3}>
                        </Step>
                    </Steps>
                    <div className="steps-content" style={{ marginTop: '10px' }}>


                        {
                            leadDetails && leadDetails.leadDetails && leadDetails.leadDetails.Id && leadDetails.leadDetails.Id._text && (
                                <div className={styles.LeadIDcontainer}>
                                    <h3>Lead ID <strong><a href={`https://lm.prod.velocify.com/Web/LeadAddEdit.aspx?LeadId=${leadDetails.leadDetails.Id._text}`} target="_blank">{leadDetails.leadDetails.Id._text}</a></strong></h3>
                                </div>
                            )
                        }


                        {current === 0 &&
                            <Row>
                                <Col span={24}>
                                    <Spin spinning={isSearching}>

                                        {!newLead && !redirectNew &&

                                            <Card className="gx-card" title={`Search Lead`} style={{ maxHeight: "650px", overflow: "hidden" }}>

                                                <Form
                                                    className="ant-advanced-search-form"
                                                    style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }}
                                                    onSubmit={this.handleSearchLeadId}
                                                >
                                                    <Row>
                                                        <Col lg={24} md={24} sm={24} xs={24}>
                                                            <center>
                                                                <h3>Enter  Lead ID to Search</h3>
                                                                <div className="gx-form-row0">
                                                                    {/* <Input style={{ width: '50%' }} value="2026563" id="tetxLeadIdToSearch" name="tetxLeadIdToSearch" placeholder="Enter Velocify Lead ID" /> */}
                                                                    <FormItem {...formItemLayout2} >
                                                                        {getFieldDecorator('tetxLeadIdToSearch', {
                                                                            initialValue: "",
                                                                            rules: [{ required: true, message: 'Enter Lead ID!' }],
                                                                        })(
                                                                            <Input style={{ width: '50%', height: "50px", fontSize: "25px", textAlign: "center" }} placeholder="Enter Velocify Lead ID" />
                                                                        )}
                                                                    </FormItem>
                                                                </div>
                                                            </center>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col span={24} className="gx-text-right">
                                                            <center>
                                                                <Button type="link" onClick={this.setNewLead}>
                                                                    Create New Lead
                                                                </Button>
                                                            </center>

                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col span={24} className="gx-text-right">
                                                            <center>
                                                                <Button type="primary" htmlType="submit" loading={this.state.fetchingLeadDetails} disabled={this.state.fetchingLeadDetails}>
                                                                    {this.state.fetchingLeadDetails ? 'Searching Lead' : 'Search'}
                                                                </Button>
                                                                <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                                                                    Clear
                                                                </Button>
                                                            </center>
                                                        </Col>
                                                    </Row>
                                                </Form>

                                            </Card>
                                        }

                                        {(newLead || redirectNew) &&

                                            <Card className="gx-card" title={`Create New Lead`} style={{ maxHeight: "550px", overflow: "hidden" }}>

                                                <Form
                                                    className="ant-advanced-search-form"
                                                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}
                                                    onSubmit={this.handleCreateLead}
                                                >
                                                    <Row>
                                                        <Col sm={18} xs={24} offset={6}>
                                                            <div className="gx-form-row0">
                                                                <FormItem {...formItemLayout} label="First and Last Name" >
                                                                    {getFieldDecorator('Name', {
                                                                        initialValue: (redirectNew && !isEmpty(leadDetails.leadDetails.FirstName)) ? `${leadDetails.leadDetails.FirstName._text}${!isEmpty(leadDetails.leadDetails.Surname) ? ` ${leadDetails.leadDetails.Surname._text}` : ``}` : '',
                                                                        rules: [{ required: true, message: 'Please enter your First and Last Name' }],
                                                                    })(
                                                                        <Input style={{ width: '100%' }} disabled={redirectNew} />
                                                                    )}
                                                                </FormItem>
                                                            </div>
                                                        </Col>

                                                        <Col sm={18} xs={24} offset={6}>
                                                            <div className="gx-form-row0">
                                                                <FormItem {...formItemLayout} label="Mobile Number" >
                                                                    {getFieldDecorator('MobileNumber', {
                                                                        initialValue: (redirectNew && !isEmpty(leadDetails.leadDetails.MobileNumber)) ? leadDetails.leadDetails.MobileNumber._text : '',
                                                                        rules: [
                                                                            { minLength: 10, message: 'Mobile Number require 10 digits inlcluding 0' },
                                                                            { maxLength: 10, message: 'Mobile Number can not exceed 10 digits inlcluding 0' },
                                                                            { required: true, message: 'Please enter your Mobile Number' }
                                                                        ],
                                                                    })(
                                                                        <Input type="number" maxlength="10" style={{ width: '100%' }} disabled={redirectNew} />
                                                                    )}
                                                                </FormItem>
                                                            </div>
                                                        </Col>
                                                        <Col sm={18} xs={24} offset={6}>
                                                            <div className="gx-form-row0">
                                                                <FormItem {...formItemLayout} label="Email" >
                                                                    {getFieldDecorator('Email_x0028_M_x0029_', {
                                                                        initialValue: (redirectNew && !isEmpty(leadDetails.leadDetails.EmailAddress)) ? leadDetails.leadDetails.EmailAddress._text : '',
                                                                        rules: [{ required: false, message: 'Please enter your Email' }],
                                                                    })(
                                                                        <Input type="email" style={{ width: '100%' }} disabled={redirectNew && !isEmpty(leadDetails.leadDetails.EmailAddress)} />
                                                                    )}
                                                                </FormItem>
                                                            </div>
                                                        </Col>

                                                        <Col sm={18} xs={24} offset={6}>

                                                            <div className="gx-form-row0">

                                                                <Form.Item    {...formItemLayout} label="Company">

                                                                    {getFieldDecorator('CompanyIndustry', {
                                                                        initialValue: (redirectNew && !isEmpty(leadDetails.leadDetails.CompanyIndustry)) ? leadDetails.leadDetails.CompanyIndustry._text : '',
                                                                        rules: [{ required: true, message: 'Select Company' }],
                                                                    })(
                                                                        <Select
                                                                            disabled={redirectNew && !isEmpty(leadDetails.leadDetails.CompanyIndustry)}
                                                                            showSearch
                                                                            name={"CompanyIndustry"}
                                                                            id={"CompanyIndustry"}
                                                                            placeholder="Select Company"
                                                                            allowClear
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            {
                                                                                company && company.map((companyItem, index) => {
                                                                                    return <Option value={companyItem.value} key={index}>{companyItem.label}</Option>
                                                                                })
                                                                            }


                                                                        </Select>
                                                                    )}

                                                                </Form.Item>

                                                            </div>

                                                        </Col>

                                                        <Col sm={18} xs={24} offset={6}>

                                                            <div className="gx-form-row0">

                                                                <Form.Item    {...formItemLayout} label="Lead Call Type">

                                                                    {getFieldDecorator('LeadCallType', {
                                                                        initialValue:  (redirectNew && leadDetails.leadDetails.LeadCallType) ? leadDetails.leadDetails.LeadCallType._text : '',
                                                                        rules: [{ required: true, message: 'Select Lead Call Type' }],
                                                                    })(
                                                                        <Select
                                                                            showSearch
                                                                            name={"LeadCallType"}
                                                                            id={"LeadCallType"}
                                                                            placeholder="Select Lead Call Type"
                                                                            allowClear
                                                                            filterOption={(input, option) =>
                                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                            }
                                                                        >
                                                                            <Option value={"Broadband Inbound"} key={0}>Broadband Inbound</Option>
                                                                            <Option value={"Cross-sell"} key={1}>Cross-sell</Option>


                                                                        </Select>
                                                                    )}

                                                                </Form.Item>

                                                            </div>

                                                        </Col>

                                                    </Row>

                                                    <Row>

                                                        <Col span={24} className="gx-text-right">

                                                            <center>
                                                                <Button type="link" onClick={this.setPreLead}>
                                                                    Fetch Previous Lead
                                                                </Button>
                                                            </center>

                                                        </Col>
                                                    </Row>

                                                    <Row>

                                                        <Col span={24} className="gx-text-right">

                                                            <center>

                                                                <Button
                                                                    type="primary"
                                                                    htmlType="submit"
                                                                    disabled={this.state.createNewDealButtonDisabled}
                                                                    loading={this.state.creatingNewLead || this.state.fetchingLeadDetails}
                                                                >
                                                                    {!this.state.creatingNewLead && !this.state.fetchingLeadDetails && 'Create'}

                                                                    {this.state.creatingNewLead && 'Creating a new lead...'}

                                                                    {this.state.fetchingLeadDetails && 'Fetching Lead Details...'}

                                                                </Button>

                                                                <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                                                                    Clear
                                                                </Button>

                                                            </center>

                                                        </Col>

                                                    </Row>

                                                </Form>

                                            </Card>
                                        }
                                    </Spin>
                                </Col>
                            </Row>
                        }

                        {current === 1 &&
                            <Row>
                                <AddressFinder onComplete={this.handleOnComplete} disabledAction={this.state.addressFetching} data={this.state.leadDetails} addressSearchedAlready={this.state.backToAddress} />
                            </Row>
                        }

                        {/* {current === 2 &&
                            <Row>
                                <ConfirmAddress addressList={["U 2, 65, MARKET RD , WERRIBEE 3030, VIC - (Residential)", "U 2, 70, MARKET RD , WERRIBEE 3030, VIC - (Residential)"]} />
                            </Row>
                        }
     */}
                        {current === 2 &&
                            <Row>
                                <AddressSummary
                                    onComplete={this.handleOnComplete}
                                    address={this.state.address}
                                    goPrevious={this.handlePreviousStepNavigation} />
                            </Row>
                        }

                        {current === 3 &&
                            <Row>
                                <BroadbandQuestions
                                    onComplete={this.handleOnComplete}
                                    address={this.state.address}
                                    goPrevious={this.handlePreviousStepNavigation}
                                    apiLoading={this.state.productApiLoading}
                                    data={this.state.leadDetails}
                                />

                            </Row>
                        }
                    </div>
                </>
                <SweetAlert show={this.state.showError} error title={this.state.errorMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
            </div >
        )
    }

}

const mapStateToProps = state => ({
    singleLeadDetails: state.leads.leadDetails,
    redirectToCreate: state.leads.redirect,
    recordUpdateStatus: state.leads.recordUpdateStatus,
    status: state.leads.status,
    error: state.leads.error,
    address: state.address,
    addressApiStatus: state.address.status,
    products: state.products,
    userDetails: state.auth.userDetails,
});

const mapDispatchToProps = dispath => ({
    getSingleBBLeadDetails: (id, ageentEmail) => dispath(getSingleBBLeadDetails(id, ageentEmail)),
    createSingleLeadDetail: (data) => dispath(createSingleLeadDetail(data)),
    clearErrors: () => dispath(clearErrors()),
    clearAll: () => dispath(clearAll()),
    getProductDetails: (data) => dispath(getProductDetails(data)),
    clearProducts: () => dispath(clearProducts()),
    resetAddress: () => dispath(resetAddress()),
    resetLeadDetails: () => dispath(setLeadDetailsReset())
});

const WrappedAdvancedSearch = Form.create()(BroadbandComparison);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);