import React, { useState } from 'react'
import HtmlParser from 'react-html-parser';

import { Modal, Col, Row, Button, Tag, Alert, Radio, Divider, Tabs } from "antd";
import styles from './BroadbandResult.module.less';

import classnames from 'classnames';

import { FaExternalLinkAlt, FaWifi } from 'react-icons/fa';
import { FcDownload, FcUpload } from 'react-icons/fc';

import FeatureList from './FeatureList';
import SummaryCard from './SummaryCard';
import { useEffect } from 'react';
import ModemOptions from './ModemOptions';
import fixUnicodeChars from '../../../util/fixUnicodeChars';

import isEmpty from "util/isEmpty";

const { TabPane } = Tabs;

const ResultCard = (props) => {

    const { type, headerTitleStyle, data, index, showSingUpModel, recommendedPlanCount, setSelectedPlan, techType } = props;

    const [more, setMore] = useState(false)

    const [planFullPrice, setPlanFullPrice] = useState('0.00')

    const [hasDiscount, setHasDiscount] = useState(false)

    const [planDiscountedPrice, setPlanDiscountedPrice] = useState('0.00')

    const [planDiscountedMonthlyPrice, setPlanDiscountedMonthlyPrice] = useState('0.00')

    const [selectedModem, setSelectedModem] = useState(null)

    const [selectedModemLink, setSelectedModemLink] = useState(null)

    const [modemCost, setModemCost] = useState(0)

    const [retailerHighlights, setRetailerHighlights] = useState([])

    const [broadbandRetailerFees, setBroadbandRetailerFee] = useState([])


    const tagStringTagsArray = (string) => {

        const tags = []

        string.split("|").map((option) => {
            tags.push({ label: option, color: { color: '#2db7f5' } })
        })

        return tags

    }

    const featuresListToArray = () => {

        let descriptionList = data.planDetails.product_description.split("|")

        descriptionList = descriptionList.sort(function (a, b) {
            return a.length - b.length
        })

        const structuredList = descriptionList.map((item, index) => {

            return { title: item }
        })

        return structuredList

    }

    const handlePackageURL = (url, modem) => {

        setSelectedModemLink(data.planDetails.offline_signup_link)

        const selectedModemCost = parseFloat(modem.modem_cost ? modem.modem_cost : 0)

        setModemCost(selectedModemCost)


    }

    const returnTwoDecimalFloat = (...args) => {


        let total = 0;

        args.forEach((value) => {

            const convertedFloatValue = parseFloat(value)

            total += convertedFloatValue

        })

        return total.toFixed(2)

    }

    useEffect(() => {

        if ((data.planDetails)) {

            let tempPlanFullPrice = !isEmpty(data.planDetails.discounted_monthly_charge) ? parseFloat(data.planDetails.discounted_monthly_charge) : parseFloat(data.planDetails.monthly_charge)

            let tempPlanDiscountedPrice = parseFloat(data.planDetails.monthly_charge)

            let tempPlanDiscountedMonthlyPrice = parseFloat(data.planDetails.monthly_charge)

            if (data.planDetails.home_calling_plan === 'Yes') {

                tempPlanFullPrice = tempPlanFullPrice + parseFloat(data.planDetails.home_calling_plan_cost)

                tempPlanDiscountedPrice = tempPlanDiscountedPrice + parseFloat(data.planDetails.home_calling_plan_cost)

                tempPlanDiscountedMonthlyPrice = tempPlanDiscountedMonthlyPrice + parseFloat(data.planDetails.home_calling_plan_cost)
            }

            if (!isEmpty(data.planDetails.discount)) {

                if (data.planDetails.discount_type === 'dollar') {
                    console.log('Has Discount - dollar')

                    tempPlanDiscountedPrice = tempPlanDiscountedPrice - (parseFloat(data.planDetails.discount))

                    tempPlanDiscountedMonthlyPrice = tempPlanDiscountedMonthlyPrice - (parseFloat(data.planDetails.discount))

                }

                if (data.planDetails.discount_type === 'percentage') {
                    console.log('Has Discount - percentage')

                    tempPlanDiscountedPrice = tempPlanDiscountedPrice - ((parseFloat(data.planDetails.discount) / 100) * tempPlanDiscountedPrice)

                    tempPlanDiscountedMonthlyPrice = tempPlanDiscountedMonthlyPrice - ((parseFloat(data.planDetails.discount) / 100) * tempPlanDiscountedMonthlyPrice)
                }

                setHasDiscount(true)
            }

            if (data.broadbandData.retailerModems.length === 1) {

                tempPlanFullPrice = parseFloat(tempPlanFullPrice) + parseFloat(data.broadbandData.retailerModems[0].modem_cost) + parseFloat(data.broadbandData.retailerModems[0].modem_delivery_fee)

                tempPlanDiscountedPrice = parseFloat(tempPlanDiscountedPrice) + parseFloat(data.broadbandData.retailerModems[0].modem_cost) + parseFloat(data.broadbandData.retailerModems[0].modem_delivery_fee)
            }

            setPlanFullPrice(tempPlanFullPrice.toFixed(2))

            setPlanDiscountedPrice(tempPlanDiscountedPrice.toFixed(2))

            setPlanDiscountedMonthlyPrice(tempPlanDiscountedMonthlyPrice.toFixed(2))

        }

        setSelectedModem(`plan_${data.planDetails.id}_modem_${data.broadbandData.retailerModems[0].id}`)

    }, [])


    useEffect(() => {

        if (data.retailerDetails && data.retailerDetails.retailerHghlights) {

            const rawFeaturesList = data.retailerDetails.retailerHghlights.split("|")

            const modifiedFeatureList = []

            rawFeaturesList.forEach(feature => {

                modifiedFeatureList.push({ title: feature })
            });

            setRetailerHighlights(modifiedFeatureList)

        } else {
            setRetailerHighlights([])
        }

        if (data.broadbandData && data.broadbandData.retailerFees) {

            setBroadbandRetailerFee(data.broadbandData.retailerFees[0])
        }


    }, [])


    const navigateToSignUp = async () => {

        console.log(selectedModemLink);

        showSingUpModel()

        setSelectedPlan(data)

        window.open(selectedModemLink)

    }

    const size = 'large'
    return (
        <Row>
            <Col span={24}>
                <div className={
                    classnames(
                        styles.cardContainer,
                        recommendedPlanCount && recommendedPlanCount > index ? styles.recommendedCard : ''
                    )}
                >
                    <div className={classnames(
                        styles.cardHeader,
                        styles[type || 'success'],
                        styles[headerTitleStyle || 'light']
                    )}>
                        <h3 className={classnames(
                            styles[headerTitleStyle || 'light'],
                            styles.headerTitle
                        )}>

                            {`#${index + 1}. ${data.planDetails.product_name}`} <span className={styles.company}> By{` ${data.retailerDetails.retailerName}  -  ${data.planDetails.contract_term}`} </span>

                        </h3>
                    </div>

                    {!isEmpty(data.planDetails.benefit_description) && <Col xs={24}>
                        <Alert
                            message="Great news!"
                            description={data.planDetails.benefit_description ? data.planDetails.benefit_description : ''}
                            type="info"
                            showIcon
                            style={{ marginTop: '1rem' }}
                        />
                    </Col>}


                    <div className={classnames(styles.cardContent)}>

                        <Row gutter={12}>

                            <Col sm={24}>

                                <Tabs defaultActiveKey="1">

                                    <TabPane tab="Offer Details" key="1">

                                        <Row>
                                            <Col lg={6} xs={24}>

                                                <div className={styles.companyLogoWrapper}>


                                                    <img src={isEmpty(data.planDetails.special_logo) ? data.retailerDetails.logoURL : data.planDetails.special_logo} alt='name' />


                                                </div>

                                                <div className={styles.linkWrapper} >

                                                    {
                                                        data.planDetails.critical_information_summary_link &&
                                                        <a href={data.planDetails.critical_information_summary_link} target="_blank">Critical Information Summary <FaExternalLinkAlt /> </a>

                                                    }

                                                    {
                                                        data.planDetails.nbn_key_fact_sheet_link &&
                                                        <a href={data.planDetails.nbn_key_fact_sheet_link} target="_blank">NBN Fact Sheet <FaExternalLinkAlt /> </a>
                                                    }

                                                </div>

                                                <div className={styles.tagWrapper}>

                                                    {
                                                        data.planDetails.plan_eligibility && tagStringTagsArray(data.planDetails.plan_eligibility).map((tag, index) => (

                                                            <Tag className={classnames(tag.type ? styles[tag.type] : styles.lightSuccess, styles.light)} key={index} {...tag.color}>{tag.label}</Tag>

                                                        ))
                                                    }

                                                </div>

                                            </Col>
                                            <Col lg={18} xs={24}>
                                                <Row>
                                                    <Col lg={24} xs={24}>
                                                        <FeatureList list={featuresListToArray()} />
                                                    </Col>
                                                    <Col lg={24} xs={24} style={{ marginTop: '10px' }}>
                                                        <Row gutter={10}>
                                                            <Col lg={6} xs={24}>
                                                                <SummaryCard
                                                                    title={`${data.planDetails.typical_evening_download_speed} Mbps`}
                                                                    description={'Typical Evening speed'}
                                                                    icon={<FcDownload />}
                                                                    additionalText="Download"
                                                                    tooltip={data.planDetails.typical_evening_download_speed_description}
                                                                />
                                                            </Col>
                                                            <Col lg={6} xs={24}>
                                                                <SummaryCard
                                                                    title={`${data.planDetails.upload_speed} Mbps`}
                                                                    description={'Upload speed'}
                                                                    additionalText="Upload"
                                                                    icon={<FcUpload />}
                                                                />
                                                            </Col>
                                                            <Col lg={12} xs={24}>

                                                                {hasDiscount && data.planDetails.contract_term === 'Month to Month' &&
                                                                    <SummaryCard
                                                                        description={<><p>Est. Minimum 1st Month Cost# incl. Discounts*</p>${returnTwoDecimalFloat(planDiscountedMonthlyPrice)}/Mnth for {data.planDetails.discount_period_months} mnths, then ${returnTwoDecimalFloat(!isEmpty(data.planDetails.discounted_monthly_charge) ? parseFloat(data.planDetails.discounted_monthly_charge) : parseFloat(data.planDetails.monthly_charge), data.planDetails.home_calling_plan === 'Yes' ? data.planDetails.home_calling_plan_cost : 0)}/Mnth</>}
                                                                        icon={<FaWifi />}
                                                                        type={"package"}
                                                                        discountPrice={`$${returnTwoDecimalFloat(planDiscountedPrice, modemCost)}`}
                                                                        actualPrice={`$${returnTwoDecimalFloat(planFullPrice, modemCost)}`}
                                                                        variant="success"
                                                                        textStyle="light"
                                                                    />
                                                                }

                                                                {!hasDiscount && data.planDetails.contract_term === 'Month to Month' &&
                                                                    <SummaryCard
                                                                        description={<><p>Est. Minimum 1st Month Cost# incl. Discounts*</p></>}
                                                                        icon={<FaWifi />}
                                                                        type={"package"}
                                                                        discountPrice={``}
                                                                        actualPrice={`$${returnTwoDecimalFloat(planFullPrice, modemCost)}`}
                                                                        variant="success"
                                                                        textStyle="light"
                                                                    />
                                                                }

                                                            </Col>
                                                        </Row>

                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col lg={24} xs={24}>
                                                <p>#This cost will include any modem costs, modem delivery costs and other NBN Development charges if applicable. This is an estimate and correct value can be determined during the actual signup on retailer website.</p>
                                                <p>*All discount applicable for the plan. Discounts may have certain eligibility criteria.</p>
                                            </Col>

                                        </Row>

                                    </TabPane>

                                    <TabPane tab="Retailer Details" key="2">

                                        <div style={{ margin: '1rem' }}>

                                            <h3>
                                                Why{data.retailerDetails.retailerName}?
                                            </h3>

                                            <p>
                                                {data.retailerDetails.whyChoose}
                                            </p>

                                            <FeatureList list={retailerHighlights} title={"Retailer Highlights"} />

                                        </div>


                                    </TabPane>

                                    <TabPane tab="Fees and Charges" key="3">

                                        <div style={{ margin: '1rem' }}>

                                            {
                                                broadbandRetailerFees.new_development_fee && broadbandRetailerFees.new_development_fee !== 'N/A' && <p>
                                                    New Development Fee: <strong>${broadbandRetailerFees.new_development_fee}</strong> {broadbandRetailerFees.new_development_fee_description && `(${broadbandRetailerFees.new_development_fee_description})`}
                                                </p>
                                            }


                                            {
                                                broadbandRetailerFees.relocation_fee && broadbandRetailerFees.relocation_fee !== 'N/A' && <p>
                                                    Relocation Fee: <strong>${broadbandRetailerFees.relocation_fee}</strong> {broadbandRetailerFees.relocation_fee_description && `(${broadbandRetailerFees.relocation_fee_description})`}
                                                </p>
                                            }


                                            {
                                                broadbandRetailerFees.late_payment_fee && broadbandRetailerFees.late_payment_fee !== 'N/A' && <p>
                                                    Late Payment Fee: <strong>${broadbandRetailerFees.late_payment_fee}</strong> {broadbandRetailerFees.late_payment_fee_description && `(${broadbandRetailerFees.late_payment_fee_description})`}
                                                </p>
                                            }

                                            {
                                                broadbandRetailerFees.paper_bill_fee && broadbandRetailerFees.paper_bill_fee !== 'N/A' && <p>
                                                    Paper Bill Fee: <strong>${broadbandRetailerFees.paper_bill_fee}</strong> {broadbandRetailerFees.paper_bill_fee_description && `(${broadbandRetailerFees.paper_bill_fee_description})`}
                                                </p>
                                            }


                                            {
                                                broadbandRetailerFees.late_payment_fee && broadbandRetailerFees.late_payment_fee !== 'N/A' && <p>
                                                    Non-direct Debit Fee: <strong>${broadbandRetailerFees.late_payment_fee}</strong> {broadbandRetailerFees.non_direct_debit_fee_description && `(${broadbandRetailerFees.non_direct_debit_fee_description})`}
                                                </p>
                                            }


                                            {
                                                broadbandRetailerFees.failed_direct_debit_fee && broadbandRetailerFees.failed_direct_debit_fee !== 'N/A' && <p>
                                                    Failed Direct Debit Fee: <strong>${broadbandRetailerFees.failed_direct_debit_fee}</strong> {broadbandRetailerFees.failed_direct_debit_fee_description && `(${broadbandRetailerFees.failed_direct_debit_fee_description})`}
                                                </p>
                                            }


                                            {
                                                broadbandRetailerFees.speed_change_fee && broadbandRetailerFees.speed_change_fee !== 'N/A' && <p>
                                                    Speed Change Fee:<strong>${broadbandRetailerFees.speed_change_fee}</strong> {broadbandRetailerFees.speed_change_fee_description && `(${broadbandRetailerFees.speed_change_fee_description})`}
                                                </p>
                                            }

                                        </div>

                                    </TabPane>

                                    <TabPane tab="Important Info" key="4">

                                        <div style={{ margin: '1rem' }}>

                                            {data.planDetails.terms && <div>

                                                <h3>

                                                    Terms and Conditions

                                                </h3>

                                                <p>
                                                    {HtmlParser(fixUnicodeChars(data.planDetails.terms))}
                                                </p>

                                            </div>
                                            }


                                            {data.planDetails.important_info && <div>

                                                <p>
                                                    {HtmlParser(fixUnicodeChars(data.planDetails.important_info))}

                                                </p>

                                            </div>}

                                            {
                                                data.retailerDetails.termsAndConditionsLin || data.retailerDetails.privacyPolicyLink
                                                || data.retailerDetails.creditReportingLink || data.retailerDetails.policiesLink && (
                                                    <h3>

                                                        Important Links

                                                    </h3>
                                                )
                                            }

                                            <div>

                                                {data.retailerDetails.termsAndConditionsLink && <p>
                                                    <a href={data.retailerDetails.termsAndConditionsLink} target="_blank" className="primary">Terms and Conditions</a>
                                                </p>}

                                                {data.retailerDetails.privacyPolicyLink && <p>
                                                    <a href={data.retailerDetails.privacyPolicyLink} target="_blank" className="primary">Privacy policy</a>
                                                </p>
                                                }

                                                {data.retailerDetails.creditReportingLink && <p>
                                                    <a href={data.retailerDetails.creditReportingLink} target="_blank" className="primary">Credit Reporting</a>
                                                </p>}

                                                {data.retailerDetails.policiesLink && <p>
                                                    <a href={data.retailerDetails.policiesLink} target="_blank" className="primary">List of all Policies</a>
                                                </p>
                                                }

                                            </div>

                                        </div>

                                    </TabPane>

                                </Tabs>

                            </Col>

                        </Row>

                        <Row>

                            <Col sm={24} style={{ marginBottom: '1rem' }}>

                                <Divider />

                            </Col>

                            <Col sm={24} style={{ marginBottom: '1rem' }}>

                                <ModemOptions data={data} setPackageUrl={handlePackageURL} techType={techType}/>

                            </Col>

                        </Row>

                        <Col sm={24} style={{ marginBottom: '1rem' }}>

                            {
                                <Button
                                    disabled={!selectedModemLink}
                                    className={classnames(styles['btn-success'], styles.fullWidth, styles.marginTop)}
                                    size={size}
                                    onClick={navigateToSignUp}
                                >
                                    Signup With {data.retailerDetails.retailerName}
                                </Button>
                            }
                            {
                                !selectedModemLink && <small>
                                    Please Select a Modem for Signup
                                </small>
                            }


                        </Col>



                    </div>
                </div>
            </Col>
        </Row >
    )
}

export default ResultCard