import React, { Component } from 'react'
import { Button, Card, Col, Form, Input, InputNumber, Radio, Row, Select, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import { connect } from 'react-redux'
import isEmpty from "util/isEmpty";

import { getRetailerList } from "appRedux/actions/admin/retailers";
import { createBroadbandFeePlan, getBroadbandFeeById, setCreateStatusToDefault, updateBroadbandFeePlan } from '../../../../appRedux/actions/admin/broadbandFees';

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const { Option } = Select;


class NewBroadbandFees extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editMode: false,
            selectedPlan: {}
        }
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id) {

            console.log("triggerd",id);
            this.props.getBroadbandFeeById(id)

            this.setState({ editMode: true })

        } else {

            this.setState({ editMode: false })

        }

        this.props.getRetailerList();
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {

            if (this.state.editMode) {

                this.props.updateBroadbandFeePlan({ ...values, id: this.state.selectedPlan.id })

            } else {

                this.props.createBroadbandFeePlan({ ...values })

            }

        })

    }

    componentWillReceiveProps(nextProps) {

        if (this.state.editMode) {

            console.log(nextProps.selectedFeePlan);
            this.setState({ selectedPlan: nextProps.selectedFeePlan })
        }
        // Check the broadband Create status and  if it is success redirect to broadband fee index page
        if (nextProps.createStatus === 'success') {

            this.props.setCreateStatusToDefault()

            message.success('New Broadband Fee Record Added!');

            setTimeout(() => {
                this.props.history.replace('/admin/manage/broadbandFees')
            }, 1000);

        }
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const { editMode, selectedPlan } = this.state
        return (
            <Card className="gx-card" title={`${editMode ? 'Edit Broadband Fees' : 'Broadband Fees'}`} style={{ maxHeight: "100%", overflow: "hidden" }}>
                <Form
                    name="modemOptionForm"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                >
                    <Row>
                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="Retailer" hasFeedback>
                                {getFieldDecorator('retailer', {
                                    rules: [{ required: true, message: 'Please select Retailer' }],
                                    initialValue: editMode ? selectedPlan.retailer : ''
                                })(
                                    <Select placeholder="Please select a Retailer">
                                        {/* <Option value="-1">All Retailers</Option> */}
                                        {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                            return <option key={key} value={retailer.retailerFriendlyName}>{retailer.retailerName}</option>;
                                        })}
                                    </Select>,
                                )}
                            </Form.Item>

                        </Col>

                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="Product Name" hasFeedback>
                                {getFieldDecorator('product_name', {
                                    rules: [{ required: true, message: 'Please select Product Name' }],
                                    initialValue: editMode ? selectedPlan.product_name : ''
                                })(
                                    <Input placeholder='Please Enter Product Name' />
                                )}
                            </Form.Item>

                        </Col>

                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="States" hasFeedback mode="multiple">
                                {getFieldDecorator('state', {
                                    rules: [{ required: true, message: 'Please select States' }],
                                    initialValue: editMode ? selectedPlan.state : ''
                                })(
                                    <Select placeholder="Please select States">
                                        <Option value="VIC">Victoria</Option>
                                        <Option value="NSW">New South Wales</Option>
                                        <Option value="ACT">Australian Capital Territory</Option>
                                        <Option value="QLD">Queensland</Option>
                                        <Option value="SA">South Australia</Option>
                                        <Option value="WA">Western Australia</Option>
                                        <Option value="NT">Northern Territory</Option>
                                        <Option value="TAS">Tasmania</Option>
                                    </Select>,
                                )}
                            </Form.Item>

                        </Col>


                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="Res/sme" hasFeedback mode="multiple">
                                {getFieldDecorator('res_sme', {
                                    rules: [{ required: true, message: 'Please select States' }],
                                    initialValue: editMode ? selectedPlan.res_sme : ''
                                })(
                                    <Radio.Group>
                                        <Radio value="RES">RES</Radio>
                                        <Radio value="SME">SME</Radio>
                                    </Radio.Group>,
                                )}
                            </Form.Item>

                        </Col>



                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="New Development Fee">
                                {getFieldDecorator('new_development_fee', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter New Development Fee',
                                        },
                                    ],
                                    initialValue: editMode ? selectedPlan.new_development_fee : ''
                                })(<Input addonBefore="$" />)}

                            </Form.Item>

                        </Col>

                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="Non Direct Debit Fee">
                                {getFieldDecorator('non_direct_debit_fee', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Non Direct Debit Fee',
                                        },
                                    ],
                                    initialValue: editMode ? selectedPlan.non_direct_debit_fee : ''
                                })(<Input addonBefore="$" />)}

                            </Form.Item>


                        </Col>

                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="Paper Bill Fee">
                                {getFieldDecorator('paper_bill_fee', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Paper Bill Fee',
                                        },
                                    ],
                                    initialValue: editMode ? selectedPlan.paper_bill_fee : ''
                                })(<Input addonBefore="$" />)}

                            </Form.Item>

                        </Col>

                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="Failed Direct Debit Fee">
                                {getFieldDecorator('failed_direct_debit_fee', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Failed Direct Debit Fee',
                                        },
                                    ],
                                    initialValue: editMode ? selectedPlan.failed_direct_debit_fee : ''
                                })(<Input addonBefore="$" />)}

                            </Form.Item>

                        </Col>


                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="Late Payment Fee">
                                {getFieldDecorator('late_payment_fee', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Late Payment Fee',
                                        },
                                    ],
                                    initialValue: editMode ? selectedPlan.late_payment_fee : ''
                                })(<Input addonBefore="$" />)}

                            </Form.Item>

                        </Col>

                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="Relocation Fee">
                                {getFieldDecorator('relocation_fee', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Relocation Fee',
                                        },
                                    ],
                                    initialValue: editMode ? selectedPlan.relocation_fee : ''
                                })(<Input addonBefore="$" />)}

                            </Form.Item>

                        </Col>

                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="Speed Change Fee">
                                {getFieldDecorator('speed_change_fee', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Speed Change Fee',
                                        },
                                    ],
                                    initialValue: editMode ? selectedPlan.speed_change_fee : ''
                                })(<Input addonBefore="$" />)}

                            </Form.Item>

                        </Col>


                        <Col sm={12} xs={24}>

                            <Form.Item {...formItemLayout2} label="Late Payment Fee Description" hasFeedback mode="multiple">
                                {getFieldDecorator('late_payment_fee_description', {
                                    rules: [{ required: true, message: 'Please Enter Late Payment Fee Description' }],
                                    initialValue: editMode ? selectedPlan.late_payment_fee_description : ''
                                })(
                                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                )}
                            </Form.Item>

                        </Col>



                        <Col sm={24} xs={24}>

                            <Form.Item {...formItemLayout2} label="New Development Fee Description">
                                {getFieldDecorator('new_development_fee_description', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter New Development Fee Description',
                                        },
                                    ],
                                    initialValue: editMode ? selectedPlan.new_development_fee_description : ''
                                })(<TextArea autoSize={{ minRows: 3, maxRows: 5 }} />)}

                            </Form.Item>

                        </Col>

                        <Col sm={24} xs={24}>

                            <Form.Item {...formItemLayout2} label="Non Direct Debit Fee Description">
                                {getFieldDecorator('non_direct_debit_fee_description', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Non Direct Debit Fee Description',
                                        },
                                    ],
                                    initialValue: editMode ? selectedPlan.non_direct_debit_fee_description : ''
                                })(<TextArea autoSize={{ minRows: 3, maxRows: 5 }} />)}

                            </Form.Item>

                        </Col>

                        <Col sm={24} xs={24}>

                            <Form.Item {...formItemLayout2} label="Paper Bill Fee Description">
                                {getFieldDecorator('paper_bill_fee_description', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Paper Bill Fee Description',
                                        },
                                    ],
                                    initialValue: editMode ? selectedPlan.paper_bill_fee_description : ''
                                })(<TextArea autoSize={{ minRows: 3, maxRows: 5 }} />)}

                            </Form.Item>

                        </Col>

                        <Col sm={24} xs={24}>

                            <Form.Item {...formItemLayout2} label="Relocation Fee Description">
                                {getFieldDecorator('relocation_fee_description', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Relocation Fee Description',
                                        },
                                    ],
                                    initialValue: editMode ? selectedPlan.relocation_fee_description : ''
                                })(<TextArea autoSize={{ minRows: 3, maxRows: 5 }} />)}

                            </Form.Item>

                        </Col>

                        <Col sm={24} xs={24}>

                            <Form.Item {...formItemLayout2} label="Speed Change Fee Description">
                                {getFieldDecorator('speed_change_fee_description', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Speed Change Fee Description',
                                        },
                                    ],
                                    initialValue: editMode ? selectedPlan.speed_change_fee_description : ''
                                })(<TextArea autoSize={{ minRows: 3, maxRows: 5 }} />)}

                            </Form.Item>

                        </Col>

                        <Col sm={24} xs={24}>

                            <Form.Item {...formItemLayout2} label="Failed Direct Debit Fee Description">
                                {getFieldDecorator('failed_direct_debit_fee_description', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Failed Direct Debit Fee Description',
                                        },
                                    ],
                                    initialValue: editMode ? selectedPlan.failed_direct_debit_fee_description : ''
                                })(<TextArea autoSize={{ minRows: 3, maxRows: 5 }} />)}

                            </Form.Item>

                        </Col>

                        <Col span={24} className="gx-text-right">

                            <center>

                                <div className='contentCenter'>

                                    <Button type="primary" htmlType="submit">
                                        {editMode ? 'Update' : 'Save'}
                                    </Button>

                                </div>

                            </center>

                        </Col>

                    </Row>

                </Form>

            </Card>
        )
    }
}

const mapStateToProps = (state) => ({
    retailerList: state.retailers.retailerList,
    createStatus: state.broadbandFees.createNewStatus,
    selectedFeePlan: state.broadbandFees.selectedPlan
})

const mapDispatchToProps = dispath => ({
    getRetailerList: (data) => dispath(getRetailerList(data)),
    createBroadbandFeePlan: (data) => dispath(createBroadbandFeePlan(data)),
    setCreateStatusToDefault: () => dispath(setCreateStatusToDefault()),
    updateBroadbandFeePlan: (data) => dispath(updateBroadbandFeePlan(data)),
    getBroadbandFeeById: (id) => dispath(getBroadbandFeeById(id)),
})

const WrappedNewBroadbandFees = Form.create()(NewBroadbandFees);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedNewBroadbandFees);