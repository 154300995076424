import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Tag, Input, Row, Button, Select, InputNumber, Popconfirm, Switch, Modal, DatePicker } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getUsers } from "appRedux/actions/admin/users";
import { getSalesInProgress, searchSalesInProgress, deleteSaleInProgress, clearErrors } from "appRedux/actions/sales/processSales";
import { getRetailerList } from "appRedux/actions/admin/retailers";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import swal from 'sweetalert2';

import Widget from "components/Widget/index";

import exportFromJSON from 'export-from-json'

const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const RangePicker = DatePicker.RangePicker;

const showHeader = true;
const pagination = { position: 'bottom' };

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

class SalesInProgress extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isSearching: false,
            expand: false,
            dataSource: [],
            dataSourceOrg: [],
            bordered: true,
            loading: false,
            pagination,
            size: 'middle',
            title: undefined,
            showHeader,
            footer: undefined,
            rowSelection: {},
            startDate: null,
            endDate: null,
            recordCount: 0,
            showError: false,
            showWarning: false,
            showSuccess: false,
            errorMessage: "",
            showSearchPanel: true,
        };

        this.columns = [
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 150,
                render: (text, record) => {
                    console.log(record)
                    return (
                        <span>
                            <center>
                                <a style={{ marginLeft: 10, color: "#f5222d" }} onClick={() => this.confirmDeleteRecord(record)}>
                                    <i className="icon icon-close-circle" />
                                </a>
                            </center>
                        </span>
                    );
                },
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 50,
                //defaultSortOrder: 'descend',
                sorter: (a, b) => a.id - b.id,
                render: (text, record) => {
                    return <span style={{ fontSize: "12px" }}>{text}</span>
                },
            },
            {
                title: 'Created Date/Time',
                dataIndex: 'createdTime',
                key: 'createdTime',
                align: 'center',
                width: 200,
                //defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.createdTime, "YYYY-MM-DD h:mm:ss A") - moment(b.createdTime, "YYYY-MM-DD h:mm:ss A"),
                render: (text, record) => {
                    return <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"geekblue"} key={text}>
                        {text.toUpperCase()}
                    </Tag>
                }
            },
            {
                title: 'Updated Date/Time',
                dataIndex: 'updatedTime',
                key: 'updatedTime',
                align: 'center',
                width: 200,
                sorter: (a, b) => a.updatedTime - b.updatedTime,
                render: (text, record) => {
                    return !isEmpty(text) && text != "-" && <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"volcano"} key={text}>
                        {text.toUpperCase()}
                    </Tag>
                }
            },
            {
                title: 'Correlation ID',
                dataIndex: 'correlationId',
                key: 'correlationId',
                align: 'center',
                width: 230,
                render: (text, record) => {
                    return <span style={{ fontSize: "12px" }}>{text}</span>
                },
            },
            {
                title: 'Lead ID',
                dataIndex: 'leadId',
                key: 'leadId',
                align: 'center',
                width: 100,
                sorter: (a, b) => a.id - b.id,
                render: (text, record) => {

                let tempLeadID = text;

                try{
                    if (text.includes("EW0")) {
                    tempLeadID = tempLeadID.replace("EW0", "");
                    tempLeadID = tempLeadID.replace(/\_.*/g, "$'");
                    }
                } catch (err) { }
                
                    var url = `https://lm.prod.velocify.com/Web/LeadAddEdit.aspx?LeadId=${tempLeadID}`;
                    return <a href={url} target="_blank">{tempLeadID}</a>
                },
            },
            {
                title: 'Retailer',
                dataIndex: 'retailerId',
                key: 'retailerId',
                align: 'center',
                width: 100,
                sorter: (a, b) => a.retailerId - b.retailerId,
                render: (text, record) => {
                    return <span style={{ fontSize: "12px" }}>{text}</span>
                },
            },
            {
                title: 'Electricity Status',
                dataIndex: 'elecStatus',
                key: 'elecStatus',
                align: 'center',
                width: 100,
                render: (text, record) => {
                    return <span style={{ fontSize: "12px" }}>{text}</span>
                },
            },
            {
                title: 'Gas Status',
                dataIndex: 'gasStatus',
                key: 'gasStatus',
                align: 'center',
                width: 100,
                render: (text, record) => {
                    return <span style={{ fontSize: "12px" }}>{text}</span>
                },
            },
            {
                title: 'Last Status Check Date/Time',
                dataIndex: 'lastStatusCheck',
                key: 'lastStatusCheck',
                align: 'center',
                width: 200,
                sorter: (a, b) => a.lastStatusCheck - b.lastStatusCheck,
                render: (text, record) => {
                    return !isEmpty(text) && text != "-" && <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"volcano"} key={text}>
                        {text.toUpperCase()}
                    </Tag>
                }
            },
        ];

    }

    confirmDeleteRecord = (record) => {
        this.setState({ recordtoDelete: record.id });
        this.setState({
            showWarning: true,
            warningMessage: `Are you sure you want delete record ID ${record.id}?`,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    onCancelDelete = () => {
        this.setState({ recordtoDelete: null });
        this.setState({
            showWarning: false,
            warningMessage: ``,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    handleDelete = () => {
        this.state.dataSource = [];
        this.props.deleteSaleInProgress(this.state.recordtoDelete);
        console.log("Delete Record");
    };

    handleShowPayLoadData = (recordID) => {

        var recordData = this.state.dataSource.find(data => data.id == recordID);

        Modal.info({
            title: 'Payload Data',
            width: '350',
            centered: true,
            maskClosable: true,
            content: (
                <div>
                    <div style={{ overflow: 'auto', maxHeight: 450, minWidth: 600, maxWidth: 600 }}><pre style={{ whiteSpace: "pre-wrap" }}>{recordData.payloadData}</pre></div>
                </div>
            ),
            onOk() {
            },
        });
    };

    handleShowResponseData = (recordID) => {

        var recordData = this.state.dataSource.find(data => data.id == recordID);

        Modal.info({
            title: 'Response Data',
            width: '350',
            centered: true,
            maskClosable: true,
            content: (
                <div>
                    <div style={{ overflow: 'auto', maxHeight: 450, minWidth: 600, maxWidth: 600 }}><pre style={{ whiteSpace: "pre-wrap" }}>{recordData.responseData}</pre></div>
                </div>
            ),
            onOk() {
            },
        });
    };

    handleShowOrderDetails = (recordID) => {

        var recordData = this.state.dataSource.find(data => data.id == recordID);

        Modal.info({
            title: 'Response Data',
            width: '350',
            centered: true,
            maskClosable: true,
            content: (
                <div>
                    <div style={{ overflow: 'auto', maxHeight: 450, minWidth: 600, maxWidth: 600 }}><pre style={{ whiteSpace: "pre-wrap" }}>{recordData.orderDetails}</pre></div>
                </div>
            ),
            onOk() {
            },
        });
    };

    onConfirm = () => {
        this.setState({
            showSuccess: false,
        });
        this.handleReset();
    };

    onErrorMessageConfirm = () => {
        this.setState({
            errorMessage: "",
            showError: false,
            isSearching: false,
            loading: false,
        });
        this.props.clearErrors();
    };

    componentDidMount() {
        this.props.getUsers();
        this.props.getRetailerList();

        this.setState({ loading: true, isSearching: true });

        this.props.getSalesInProgress();
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log(values);

            const filtersForSearch = {
                ...(values.txtLeadID != "-1" && { leadId: values.txtLeadID }),
                ...(values.drpRetailer != "-1" && { retailerId: values.drpRetailer }),
                // ...(values.drpElecStatus != "-1" && { elecStatus: values.drpElecStatus }),
                // ...(values.drpGasStatus != "-1" && { gasStatus: values.drpGasStatus }),
            }
            this.setState({ isSearching: true });
            // this.setState({ loading: true });
            if (!isEmpty(filtersForSearch))
                this.props.searchSalesInProgress(filtersForSearch);
            else {
                this.props.getSalesInProgress();
                this.setState({ isSearching: false });
            }
        });
    };

    handleReset = () => {
        this.props.form.resetFields();

        this.props.getUsers();
        this.props.getRetailerList();

        this.props.getSalesInProgress();
    };

    handleExportToExcel = (e) => {
        const data = this.state.dataSourceOrg;

        const fileName = 'Sales In Progress Report - ' + moment().format("DDMMYYYY_HHmmss")
        const exportType = 'csv'

        exportFromJSON({ data, fileName, exportType })
    };

    handleDateRangeChange = (dates, dateStrings) => {
        this.setState({
            startDate: moment(dateStrings[0], "YYYY/MM/DD").format("YYYY-MM-DD"),
            endDate: moment(dateStrings[1], "YYYY/MM/DD").format("YYYY-MM-DD"),
        })
    };


    componentWillReceiveProps(nextProps) {
        if (!isEmpty(nextProps.salesInProgress) && !isEmpty(nextProps.retailerList)) {
            this.setState({ loading: true });
            this.state.dataSource = [];
            this.state.dataSourceOrg = [];
            let count = 1;
            nextProps.salesInProgress.map((saleLog, key) => {

                let record = {
                    key: count.toString(),
                    id: saleLog.id,
                    createdTime: moment(saleLog.createdTime).format('YYYY-MM-DD h:mm:ss A'),
                    updatedTime: moment(saleLog.updatedTime).format('YYYY-MM-DD h:mm:ss A'),
                    correlationId: !isEmpty(saleLog.correlationId) ? saleLog.correlationId : "-",
                    leadId: !isEmpty(saleLog.leadId) ? saleLog.leadId : "-",
                    retailerId: !isEmpty(saleLog.retailerId) ? nextProps.retailerList.find(data => data.id == saleLog.retailerId).retailerName : "-",
                    elecStatus: !isEmpty(saleLog.elecStatus) ? saleLog.elecStatus : "-",
                    gasStatus: !isEmpty(saleLog.gasStatus) ? saleLog.gasStatus : "-",
                    lastStatusCheck: moment(saleLog.lastStatusCheck).format('YYYY-MM-DD h:mm:ss A'),
                };

                var tempObj = {
                    "ID": saleLog.id,
                    "Lead ID": !isEmpty(saleLog.leadId) ? saleLog.leadId : "-",
                    "Created Date Time": moment(saleLog.createdTime).format('YYYY-MM-DD h:mm:ss A'),
                    "Updated Date Time": moment(saleLog.updatedTime).format('YYYY-MM-DD h:mm:ss A'),
                    "Correlation ID": !isEmpty(saleLog.correlationId) ? saleLog.correlationId : "-",
                    "Lead ID": !isEmpty(saleLog.leadId) ? saleLog.leadId : "-",
                    "Retailer": !isEmpty(saleLog.retailerId) ? nextProps.retailerList.find(data => data.id == saleLog.retailerId).retailerName : "-",
                    "Electricity Status": !isEmpty(saleLog.elecStatus) ? saleLog.elecStatus : "-",
                    "Gas Status": !isEmpty(saleLog.gasStatus) ? saleLog.gasStatus : "-",
                    "DateTime Sale Update": moment(saleLog.updatedTime).format('YYYY-MM-DD h:mm:ss A'),
                }
                this.state.dataSourceOrg.push(tempObj);
                this.state.dataSource.push(record);
                count++;
            });
            this.setState({
                loading: false,
                isSearching: false,
                recordCount: (count - 1),
            });
            this.setState({ isSearching: false });
        } else {
            // this.setState({ loading: true });
            // this.state.dataSource = [];
            // this.setState({ 
            //   loading: false,        
            //   isSearching: false,
            //   recordCount: 0,
            //  });
        }

        // If Search Results Empty, Override DataSource - VSrimal - 04/05/2023
        if (isEmpty(nextProps.salesInProgress)) {
            this.setState({
                dataSource: [],
                dataSourceOrg: [],
                loading: false,
                isSearching: false,
                recordCount: 0,
            });
        }


        if (!isEmpty(nextProps.recordUpdateStatus)) {
            if (nextProps.recordUpdateStatus.status == "Success") {
                this.setState({
                    successMessage: nextProps.recordUpdateStatus.message,
                    showSuccess: true,
                    showWarning: false,
                    warningMessage: "",
                });
                this.setState({
                    showSearchPanel: true,
                });
                swal.close();
                console.log("New Update Request");
                this.props.getSalesInProgress();
            }
        }

        if (!isEmpty(nextProps.error)) {
            if (isEmpty(this.state.errorMessage) && this.state.showError == false) {
                this.setState({
                    errorMessage: nextProps.error,
                    showError: true,
                });
            }
        }
    }


    render() {

        let { dataSource } = this.state;

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                }),
            };
        });

        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-main-content">
                <ContainerHeader title="Sales In Progress" />
                <Row>

                    {/* New Search - VSrimal - 04/05/2023 */}
                    {this.state.showSearchPanel && <Col span={24}>
                        <Spin spinning={this.state.isSearching}>
                            <Card className="gx-card" title={`Search In-Progress Records`} style={{ maxHeight: "550px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                                >
                                    <Row>
                                        <Col sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Lead ID" >
                                                    {getFieldDecorator('txtLeadID', {
                                                        rules: [{ required: false, message: 'Enter Lead ID!' }],
                                                    })(
                                                        <Input style={{ width: '100%' }} placeholder="Enter Lead ID" />
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Retailer" >
                                                    {getFieldDecorator('drpRetailer', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select a Retailer"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Select.Option value="-1">All Retailers</Select.Option>
                                                            {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                                                return <Select.Option key={key} value={retailer.id}>{retailer.systemName}</Select.Option>;
                                                            })}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Electricity Status" >
                                                    {getFieldDecorator('drpElecStatus', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Status"
                                                        >
                                                            <Select.Option value="-1">All Status</Select.Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Gas Status" >
                                                    {getFieldDecorator('drpGasStatus', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Status"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Select.Option value="-1">All Status</Select.Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            <Button type="primary" onClick={this.handleSearch}>
                                                Search
                                            </Button>
                                            <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                                                Clear
                                            </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }
                    {this.state.loading && <Col md={24}>
                        <Widget styleName="gx-card-full gx-text-center gx-pt-4 gx-pb-3 gx-px-3">
                            <div className="gx-separator gx-bg-primary" />
                            <h2 className="gx-mb-4 gx-text-primary">Loading Data...</h2>
                            <Spin spinning={this.state.loading}></Spin>
                        </Widget>

                    </Col>}
                    {this.state.loading == false && <Col span={24}>
                        <Card className="gx-card" title={`Sales In Progress Details (${this.state.recordCount})`}>
                            <Button onClick={this.handleExportToExcel} type="primary" style={{ marginBottom: 16 }}>
                                Export Results
                            </Button>
                            <Table
                                rowClassName="editable-row"
                                className="gx-table-responsive"
                                {...this.state}
                                columns={columns}
                                dataSource={dataSource} />
                        </Card>
                    </Col>}
                </Row>
                <SweetAlert show={this.state.showError} error title={this.state.errorMessage}
                    onConfirm={this.onErrorMessageConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showWarning}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={this.state.warningMessage}
                    onConfirm={this.handleDelete}
                    onCancel={this.onCancelDelete}
                ></SweetAlert>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userList: state.users.userList,
    retailerList: state.retailers.retailerList,
    recordUpdateStatus: state.processSales.recordUpdateStatus,

    salesInProgress: state.processSales.salesInProgress,

    processSaleLogs: state.processSales.processSaleLogs,

    error: state.processSales.error
});

const mapDispatchToProps = dispath => ({
    getSalesInProgress: (data) => dispath(getSalesInProgress(data)),
    searchSalesInProgress: (data) => dispath(searchSalesInProgress(data)),
    deleteSaleInProgress: (data) => dispath(deleteSaleInProgress(data)),
    getUsers: (data) => dispath(getUsers(data)),
    getRetailerList: (data) => dispath(getRetailerList(data)),
    clearErrors: () => dispath(clearErrors()),
});

const WrappedAdvancedSearch = Form.create()(SalesInProgress);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);