import { states } from "../static/formSupport"

export const getStateTitle = (value) => {

    // check street Type availability
    if (value) {

        // filter state
        const filteredState = states.filter((stateItem) => {
            return stateItem.value === value
        })

        // return the filtered Street value
        return filteredState[0].label

    } else {

        return ""
    }

}