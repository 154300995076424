import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getDistributorTranslationList, updateDistributorTranslationList, addDistributorTranslationList, deleteDistributorTranslationList } from "appRedux/actions/admin/distributorTranslations";

import { getDistributorList } from "appRedux/actions/admin/distributors";
import { getRetailerList } from "appRedux/actions/admin/retailers";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
let retailerState = "";

//const title = () => 'Here is title';
const showHeader = true;
//const footer = () => 'Here is footer';
//const scroll = {y: 500};
const pagination = { position: 'bottom' };

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const EditableContext = React.createContext();

class EditableCell extends React.Component {

  getInput = () => {
    const {
      dataIndex,
      record,
    } = this.props;

    function onChange(checked) {
    }

    function handleChange(value) {
      retailerState = value.toString().split(',').join('|');
    }

    if (this.props.inputType === 'statusSelection') {
      return <Switch disabled={false} defaultChecked={record.isActive == 1 ? true : false} onChange={onChange} />;
    }
    else if (this.props.inputType === 'ewDbSelection') {
      return <Select
        style={{ width: '250px' }}
        placeholder="Please select Distributor"
        onChange={handleChange}
        defaultValue={record.ewDbId}
      >
        <Option value="-1">Select</Option>
        {!isEmpty(this.props.distributorList) && this.props.distributorList.map((distributor, key) => {
            return <option key={key} value={distributor.id}>{distributor.distributorName}</option>;
        })}
      </Select>;
    }
    else if (this.props.inputType === 'retailerSelection') {
      return <Select
        style={{ width: '250px' }}
        placeholder="Please select Retailer"
        onChange={handleChange}
        defaultValue={record.retailerId}
      >
        <Option value="-1">Select</Option>
        {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
            return <option key={key} value={retailer.id}>{retailer.retailerName}</option>;
        })}
      </Select>;
    }
    return <Input style={{minWidth:'150px'}} />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    function getInitialValue(dataIndex) {
      if(dataIndex == "isActive")
      {
        return record[dataIndex] == 1 ? true : false;
      }
      else if(dataIndex == "state")
      {
        retailerState = record[dataIndex];
        return record[dataIndex].split("|");
      }
      else
      {
        return record[dataIndex];
      }
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0, width: '100%', display:'block',  }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: getInitialValue(dataIndex),
            })(this.getInput())}
          </Form.Item>
        ) : (
            children
          )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class DistributorTranslation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editingKey: '',
      isAdding: false,
      isSearching: false,
      recordsEdited: false,
      expand: false,
      dataSource: [],
      addingNewRecord: false,
      distributorRecordIdToDelete: null,
      showAddNewDistributorTranslationPanel: false,
      showWarning: false,
      warningMessage: "",
      showSuccess: false,
      successMessage: "",
      bordered: true,
      loading: true,
      pagination,
      size: 'middle',
      title: undefined,
      showHeader,
      footer: undefined,
      rowSelection: {},
    };
    this.columns = [
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        width: 100,
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <center>
                <EditableContext.Consumer>
                  {form => (
                    <a
                      onClick={() => this.save(form, record.key)}

                    >
                      <i className="icon icon-check-circle-o" />
                    </a>
                  )}
                </EditableContext.Consumer>
                <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                  <a style={{ marginLeft: 10 }}><i className="icon icon-close-circle" /></a>
                </Popconfirm>
              </center>
            </span>
          ) : (
              <span>
                <center>
                  <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
                    <i className="icon icon-edit" />
                  </a>
                  <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.confirmDelete(record.id)}>
                    <i className="icon icon-trash" />
                  </a>
                </center>
              </span>
            );
        },
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 80,
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Created Date',
        dataIndex: 'createdDate',
        key: 'createdDate',
        align: 'center',
        width: 220,
        sorter: (a, b) => a.createdDate - b.createdDate,
      },
      {
        title: 'Retailer',
        dataIndex: 'retailerId',
        key: 'retailerId',
        align: 'center',
        width: 150,
        editable: true,
        sorter: (a, b) => a.retailerId - b.retailerId,
        render: (text, record) => {

          return <Select
            style={{ width: '250px' }}
            placeholder="Please select Distributor"
            defaultValue={text}
            disabled
          >
            <Option value="-1">Select</Option>
            {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                return <option key={key} value={retailer.id}>{retailer.retailerName}</option>;
            })}
          </Select>
        },
      },
      {
        title: 'Retailer Distributor',
        dataIndex: 'retailerDb',
        key: 'retailerDb',
        align: 'center',
        width: 150,
        editable: true,
        sorter: (a, b) => { return a.retailerDb.localeCompare(b.retailerDb) },
      },
      {
        title: 'EW Distributor',
        dataIndex: 'ewDbId',
        key: 'ewDbId',
        align: 'center',
        width: 100,
        editable: true,
        sorter: (a, b) => { return a.ewDbId.localeCompare(b.ewDbId) },
        render: (text, record) => {

          return <Select
            style={{ width: '250px' }}
            placeholder="Please select Distributor"
            defaultValue={text}
            disabled
          >
            <Option value="-1">Select</Option>
            {!isEmpty(this.props.distributorList) && this.props.distributorList.map((distributor, key) => {
                return <option key={key} value={distributor.id}>{distributor.distributorName}</option>;
            })}
          </Select>
        },
      },
      {
        title: 'Status',
        dataIndex: 'isActive',
        key: 'isActive',
        align: 'center',
        width: 150,
        editable: true,
        sorter: (a, b) => { return a.isActive.localeCompare(b.isActive) },
        render: (text, record) => {
          return <Switch disabled={true} checked={text == 1 ? true : false} onChange={{}} />
        }
      },
    ];

  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.dataSource];
      const index = newData.findIndex(item => key === item.key);

      if (index > -1) {
        const item = newData[index];

        const valuesToUpdate = {
          id: item.id,
          retailerId: row.retailerId,
          retailerDb: row.retailerDb,
          ewDbId: row.ewDbId,
          isActive: row.isActive,
        }
     
        this.setState({ loading: true });
        this.props.updateDistributorTranslationList(valuesToUpdate);

        retailerState = "";

        this.setState({
          editingKey: '',
          recordsEdited: true
        });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  confirmDelete = (key) => {
    this.setState({ distributorRecordIdToDelete: key });
    this.setState({ showWarning: true });

  };

  onCancelDelete = key => {
    this.setState({ distributorRecordIdToDelete: null });
    this.setState({ showWarning: false });
  };

  handleDelete = () => {
    const deleteInfo = {
      id: this.state.distributorRecordIdToDelete,
    }
    this.props.deleteDistributorTranslationList(deleteInfo);
    this.setState({ showWarning: false });
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showAddNewDistributorTranslationPanel: false,
    });
    this.handleReset();
  };

  handleAdd = () => {
    this.setState({
      showAddNewDistributorTranslationPanel: true,
    });
  };

  handleAddDistributorTranslationDetails = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      const distributorTranslationDetails = {
        retailerId : values.drpRetailerAdd,
        retailerDb : values.txtRetailerDistributorNameAdd,
        ewDbId : values.drpDistributorAdd,
      }

      this.setState({ isAdding: true });
      this.props.addDistributorTranslationList(distributorTranslationDetails);
    });
  };

  onAddCancel = () => {
    this.setState({
      showAddNewDistributorTranslationPanel: false,
    });
    this.handleReset();
  };

  componentDidMount() {
    this.props.getDistributorList();
    this.props.getRetailerList();
    this.props.getDistributorTranslationList();
  }

  handleSearch = (e) => {
    // e.preventDefault();
    // this.props.form.validateFields((err, values) => {

    //   const filtersForSearch = {
    //     email: values.txtEmail,
    //     name: values.txtName,
    //     accessLevel: values.drpAccessLevel,
    //   }
    //   this.setState({ isSearching: true });
    //   this.props.getUsers(filtersForSearch);
    // });
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.props.getDistributorTranslationList();
  };

  checkColoumnType = (field) => {
    switch (field) {
      case 'retailerId': return "retailerSelection";
      case 'ewDbId': return "ewDbSelection";
      case 'isActive': return "statusSelection";
      default: return "text";
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.distributorTranslationList)) {
      this.setState({ loading: true });
      this.state.dataSource = [];
      let count = 1;
      nextProps.distributorTranslationList.map((distributorTranslation, key) => {
        let record = {
          key: count.toString(),
          id: distributorTranslation.id,
          createdDate: moment(distributorTranslation.createdDate).format('YYYY-MM-DD h:mm:ss A'),
          retailerId: !isEmpty(distributorTranslation.retailerId) ? distributorTranslation.retailerId : "-",
          retailerDb: !isEmpty(distributorTranslation.retailerDb) ? distributorTranslation.retailerDb : "-",
          ewDbId: !isEmpty(distributorTranslation.ewDbId) ? distributorTranslation.ewDbId : "-",
          isActive: distributorTranslation.isActive,
        };
        this.state.dataSource.push(record);
        count++;
      });
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }
    else {
      this.setState({ loading: true });
      this.state.dataSource = [];
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }

    if (!isEmpty(nextProps.recordUpdateStatus)) {
      if (nextProps.recordUpdateStatus.status == "Success") {
        this.setState({
          successMessage: nextProps.recordUpdateStatus.message,
          showSuccess: true,
        });
        this.props.getDistributorTranslationList();
        this.setState({ 
          isAdding: false,
          showAddNewDistributorTranslationPanel: false,
        });
      }
    }
  }


  render() {

    let { dataSource } = this.state;

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          retailerList: this.props.retailerList,
          distributorList: this.props.distributorList,
          inputType: this.checkColoumnType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-main-content">
        <ContainerHeader title="Manage Distributor Translations" />
        <Row>
          {/* <Col span={24}>
            <Spin spinning={this.state.isSearching}>
              <Card className="gx-card" title="Search Retailer">
                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleSearch}
                >
                  <Row>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" htmlType="submit">Search</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                          Clear
                    </Button>
                      </center>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Spin>
          </Col> */}
          {this.state.showAddNewDistributorTranslationPanel && <Col span={24}>
          <Spin spinning={this.state.isAdding}>
            <Card className="gx-card" title="Add New Distributor Translation Details">
              <Form
                className="ant-advanced-search-form"
                onSubmit={this.handleAddDistributorTranslationDetails}
              >
                <Row>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Retailer">
                        {getFieldDecorator('drpRetailerAdd', {
                          rules: [{ required: true, message: 'Please select a Retailer!' }],
                        })(
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select a Retailer"
                          >
                            <Option value="-1">Select</Option>
                            {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                return <option key={key} value={retailer.id}>{retailer.retailerName}</option>;
                            })}
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col>                  
                  <Col lg={12} md={12} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Retailer Distributor Name" >
                        {getFieldDecorator('txtRetailerDistributorNameAdd', {
                          rules: [{ required: true, message: 'Please enter Retailer Distributor Name!' }],
                        })(
                          <Input style={{ width: '100%' }} placeholder="Retailer Distributor Name" />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  </Row>
                  <Row>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="EW Distributor Map">
                        {getFieldDecorator('drpDistributorAdd', {
                          rules: [{ required: true, message: 'Please select a EW Distributor to Map!' }],
                        })(
                          <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="Select a EW Distributor"
                          >
                            <Option value="-1">Select</Option>
                            {!isEmpty(this.props.distributorList) && this.props.distributorList.map((distributor, key) => {
                                return <option key={key} value={distributor.id}>{distributor.distributorName}</option>;
                            })}
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col>  
                </Row>
                <Row>
                <Col span={24} className="gx-text-right">
                    <center>
                      <Button type="primary" htmlType="submit">Add Distributor Translation Details</Button>
                      <Button style={{ marginLeft: 8 }} onClick={this.onAddCancel}>
                        Cancel
                    </Button>
                    </center>
                  </Col>
                </Row>
              </Form>
            </Card>
            </Spin>
          </Col>
          }
          <Col span={24}>
            <Card className="gx-card" title="Distributor Translation Details">
              <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add New Distributor Translation
              </Button>
              <EditableContext.Provider value={this.props.form}>
                <Table
                  components={components}
                  rowClassName="editable-row"
                  className="gx-table-responsive"
                  {...this.state}
                  columns={columns}
                  dataSource={dataSource} />
              </EditableContext.Provider>
            </Card>
          </Col>
        </Row>
        <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
        <SweetAlert show={this.state.showWarning}
          warning
          showCancel
          confirmBtnText="Yes Delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.handleDelete}
          onCancel={this.onCancelDelete}
        ></SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  recordUpdateStatus: state.users.recordUpdateStatus,
  distributorTranslationList: state.distributorTranslations.distributorTranslationList,
  retailerList: state.retailers.retailerList,
  distributorList: state.distributors.distributorList,
});

const mapDispatchToProps = dispath => ({
  getDistributorTranslationList: (data) => dispath(getDistributorTranslationList(data)),
  updateDistributorTranslationList: (data) => dispath(updateDistributorTranslationList(data)),
  addDistributorTranslationList: (data) => dispath(addDistributorTranslationList(data)),
  deleteDistributorTranslationList: (data) => dispath(deleteDistributorTranslationList(data)),
  
  getDistributorList: (data) => dispath(getDistributorList(data)),
  getRetailerList: (data) => dispath(getRetailerList(data)),
});

const WrappedAdvancedSearch = Form.create()(DistributorTranslation);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);