const initialState = {
    broadbandModem: null,
    error: null,
    selectedPlan: {},
    status: 'idl',
    manageApiStatus: 'idl',
    deleteApiStatus: 'idl'
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'SAVE_BROADBAND_MODEM':
            return {
                ...state,
                broadbandModem: action.modemDetails,
                status: 'success'
            };

        case 'CREATE_BROADBAND_MODEM':
            return {
                manageApiStatus: 'success'
            };

        case 'REST_BROADBAND_MODEM_MANAGE_STATUS':
            return {
                ...state,
                createNewStatus: 'idl',
                deleteApiStatus: 'idl'
            };

        case 'REST_BROADBAND_MODEM':
            return {
                ...state,
                broadbandModem: null,
                status: 'idl',
                createNewStatus: 'idl',
                deleteApiStatus: 'idl'
            };

        case 'SET_SELECTED_BROADBAND_MODEM':
            return {
                ...state,
                selectedPlan: action.modemOption,
            };

        case 'UPDATE_BROADBAND_MODEM':
            return {
                ...state,
                manageApiStatus: 'success'
            };

        case 'DELETE_BROADBAND_MODEM':
            return {
                ...state,
                deleteApiStatus: 'success'
            };

        case 'ERROR':
            return {
                ...state,
                error: action.error,
                status: 'idl'
            };
        default:
            return state;
    }
}