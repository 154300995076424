import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';

export const saveRecords = resultList => ({
    type: 'SAVE_DMO_VDORECORDS',
    resultList,
});

export const saveRecordUpdateStatus = recordUpdateStatus => ({
    type: 'SAVE_RECORD_UPDATE_STATUS',
    recordUpdateStatus,
});

export const error = error => ({
    type: 'ERROR',
    error,
});

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const getDMOVDORecords = () => dispath =>{

    axios.get(apidetails.API_URL + 'dmovdo/')
    .then(res => {
        dispath(saveRecords(res.data.result));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const searchDMOVDORecords = (data) => dispath =>{
    axios.post(apidetails.API_URL + 'dmovdo/search', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecords(res.data.result));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const updateDMOVDOSingleRecord = (data) => dispath =>{

    axios.put(apidetails.API_URL + 'dmovdo/', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const createDMOVDORecord = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'dmovdo/', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const uploadDMOVDORecords = (data) => dispath =>{

    config.headers = {
        'Content-Type': 'multipart/form-data',
        'timeout': 600000
    }

    axios.post(apidetails.API_URL + 'dmovdo/upload', data, config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const wipeAndUploadDMOVDORecords = (data) => dispath =>{

    config.headers = {
        'Content-Type': 'multipart/form-data',
        'timeout': 600000
    }

    axios.post(apidetails.API_URL + 'dmovdo/wipeUpload', data, config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const deleteDMOVDORecord = (idToDelete) => dispath =>{

    axios.delete(apidetails.API_URL + `dmovdo/${idToDelete}`, config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const clearError = () => dispath =>{
    dispath(error(''));
}