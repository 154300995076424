const initialState = {
  csvData: {},
  csvDataStatus: null,
  error: null,
}

export default function (state = initialState, action){
  switch(action.type)
  {
      case 'SAVE_CSV_DATA':
          return{
              ...state,
              csvData: action.csvData
          };
      case 'SAVE_CSV_DATA_UPLOAD_STATUS':
          return{
              ...state,
              csvDataStatus: action.csvDataStatus
          };
      case 'ERROR':
          return{
              ...state,
              error: action.error
          };
      default:
          return state;
  }
}
