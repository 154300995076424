const initialState = {
    userList: {},
    toolsUserList: {},
    recordUpdateStatus: null,
    error: null,
}

export default function (state = initialState, action){
    switch(action.type)
    {
        case 'SAVE_USER_LIST':
            return{
                ...state,
                userList: action.userList
            };
        case 'SAVE_TOOLS_USER_LIST':
            return{
                ...state,
                toolsUserList: action.toolsUserList
            };
        case 'SAVE_VELOCIFY_USER_LIST':
            return{
                ...state,
                velocifyUserList: action.velocifyUserList
            };
        case 'SAVE_RECORD_UPDATE_STATUS':
            return{
                ...state,
                recordUpdateStatus: action.recordUpdateStatus
            };
        case 'ERROR':
            return{
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}