import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Tag, Input, Row, Button, Select, DatePicker, Descriptions, Badge } from "antd";

import AmCharts from "@amcharts/amcharts3-react";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";
import IconWithTextCard from "components/Metrics/IconWithTextCard";

import htmlToImage from 'html-to-image';
import download from 'downloadjs';

import html2canvas from 'html2canvas';

import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { getSalesDistribution } from "appRedux/actions/sales/salesInformation";

import isEmpty from "util/isEmpty";

const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const RangePicker = DatePicker.RangePicker;

const { Column, ColumnGroup } = Table;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const detailedRetailerSummaryColoumns = [
  {
    title: 'Retailer',
    dataIndex: 'retailer',
    key: 'retailer',
    render: (text, row, index) => {
      const obj = {
        children: text,
        props: {},
      };

      if(row["count"]==1)
      {
        obj.props.rowSpan = 8;
      }
      else 
      {
        obj.props.rowSpan = 0;
      }     

      return obj;
    },
  },
  {
    title: 'Fuel Type',
    dataIndex: 'fuelType',
    key: 'fuelType',
    render: (text, row, index) => {
      const obj = {
        children: text,
        props: {},
      };

      if(row["count"] == 1 || row["count"] == 5)
      {
        obj.props.rowSpan = 4;
      }
      else 
      {
        obj.props.rowSpan = 0;
      }  

      return obj;
    },
  },
  {
    title: 'Fuel Type Count',
    dataIndex: 'fuelTypeCount',
    key: 'fuelTypeCount',
    render: (text, row, index) => {
      const obj = {
        children: text,
        props: {},
      };

      if(row["count"] == 1 || row["count"] == 5)
      {
        obj.props.rowSpan = 4;
      }
      else 
      {
        obj.props.rowSpan = 0;
      }  

      return obj;
    },
  },
  {
    title: 'Customer Type',
    dataIndex: 'customerType',
    key: 'customerType',
    render: (text, row, index) => {
      const obj = {
        children: text,
        props: {},
      };

      if(row["count"] == 1 || row["count"] == 3 || row["count"] == 5  || row["count"] == 7)
      {
        obj.props.rowSpan = 2;
      }
      else 
      {
        obj.props.rowSpan = 0;
      }  

      return obj;
    },
  },
  {
    title: 'Customer Type Count',
    dataIndex: 'customerTypeCount',
    key: 'customerTypeCount',
    render: (text, row, index) => {
      const obj = {
        children: text,
        props: {},
      };

      if(row["count"] == 1 || row["count"] == 3 || row["count"] == 5  || row["count"] == 7)
      {
        obj.props.rowSpan = 2;
      }
      else 
      {
        obj.props.rowSpan = 0;
      }  

      return obj;
    },
  },
  {
    title: 'Retention/Acquisition',
    dataIndex: 'retAcq',
    key: 'retAcq',
    render: (text, row, index) => {
      const obj = {
        children: text,
        props: {},
      };

      obj.props.rowSpan = 1;

      return obj;
    },
  },
  {
    title: 'Retention/Acquisition Count',
    dataIndex: 'retAcqCount',
    key: 'retAcqCount',
    render: (text, row, index) => {
      const obj = {
        children: text,
        props: {},
      };

      obj.props.rowSpan = 1;

      return obj;
    },
  },
];

class SalesDistribution extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isFetchingData: false,
      salesPerHourData: [],
      salesDistributionStats: [],
      retailerSummaryData:[],
      retailerSummaryChartConfig:null,
      detailedRetailerSummaryData:[],
      salesSummaryData: {
        totalSales: 0,
        totalElec: 0,
        totalGas: 0,
      },
      isSearching: false,
      showWarning: false,
      warningMessage: "",
      showSuccess: false,
      successMessage: "",
      startDate: null,
      endDate: null,
    };
  }

  componentDidMount() {

    this.setState({
      isFetchingData: true,
    })

    this.props.getSalesDistribution();
  }

  handleDateRangeChange = (dates, dateStrings) => {
    this.setState({
      startDate: dateStrings[0],
      endDate: dateStrings[1],
    })
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      const filtersForSearch = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
      }
      this.setState({
        isFetchingData: true,
        isSearching: true,
      });
      this.props.getSalesDistribution(filtersForSearch);
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
  };

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.salesDistributionStats)) {
      this.setState({
        salesDistributionStats: nextProps.salesDistributionStats,
      });

      this.state.retailerSummaryData = [];
      this.state.detailedRetailerSummaryData = [];

      //SALES DISTRIBUTION SUMMARY

      //Elec Record
      
      var elecRetailerSummaryData = {};

      elecRetailerSummaryData["campaignMix"] = "ELEC";
      elecRetailerSummaryData["totalSales"] = nextProps.salesDistributionStats.elecTotal;

      !isEmpty(nextProps.salesDistributionStats) && nextProps.salesDistributionStats.retailerDetails.map((retailer, key) => {
        
        elecRetailerSummaryData[(retailer.name).replace(/ /g,'').toLowerCase()] = retailer.elec.totalSales;

      });

      this.state.retailerSummaryData.push(elecRetailerSummaryData);

      //Gas Record
      
      var gasRetailerSummaryData = {};

      gasRetailerSummaryData["campaignMix"] = "GAS";
      gasRetailerSummaryData["totalSales"] = nextProps.salesDistributionStats.gasTotal;

      !isEmpty(nextProps.salesDistributionStats) && nextProps.salesDistributionStats.retailerDetails.map((retailer, key) => {
        
        gasRetailerSummaryData[(retailer.name).replace(/ /g,'').toLowerCase()] = retailer.gas.totalSales;

      });
      
      this.state.retailerSummaryData.push(gasRetailerSummaryData);

      //Elec+Gas Record

      var elecGasRetailerSummaryData = {};

      elecGasRetailerSummaryData["campaignMix"] = "ELEC & GAS";
      elecGasRetailerSummaryData["totalSales"] = nextProps.salesDistributionStats.totalSales;

      !isEmpty(nextProps.salesDistributionStats) && nextProps.salesDistributionStats.retailerDetails.map((retailer, key) => {
        
        elecGasRetailerSummaryData[(retailer.name).replace(/ /g,'').toLowerCase()] = retailer.totalSales;

      });
      
      this.state.retailerSummaryData.push(elecGasRetailerSummaryData);

      // % of total Sales

      var percentageOfTotalSalesRetailerSummaryData = {};

      percentageOfTotalSalesRetailerSummaryData["campaignMix"] = "% of Total Sales";
      percentageOfTotalSalesRetailerSummaryData["totalSales"] = (((nextProps.salesDistributionStats.totalSales)/nextProps.salesDistributionStats.totalSales)*100).toFixed(1) + "%";

      !isEmpty(nextProps.salesDistributionStats) && nextProps.salesDistributionStats.retailerDetails.map((retailer, key) => {
        
        percentageOfTotalSalesRetailerSummaryData[(retailer.name).replace(/ /g,'').toLowerCase()] = ((retailer.totalSales / nextProps.salesDistributionStats.totalSales)*100).toFixed(1) + "%";

      });
      
      this.state.retailerSummaryData.push(percentageOfTotalSalesRetailerSummaryData);


      //DETAILED SALES DISTRIBUTION SUMMARY          

      !isEmpty(nextProps.salesDistributionStats) && nextProps.salesDistributionStats.retailerDetails.map((retailer, key) => {
        
        var count = 1;

        while(count <= 8)
        {

          var detailedRetailerSummary = {};  

          detailedRetailerSummary["count"] =  count;
          detailedRetailerSummary["retailer"] =  retailer.name;

          if(count <= 4)
          {
            detailedRetailerSummary["fuelType"] =  "ELEC";
            detailedRetailerSummary["fuelTypeCount"] =  retailer.elec.totalSales;
          }
          else
          {
            detailedRetailerSummary["fuelType"] =  "GAS";
            detailedRetailerSummary["fuelTypeCount"] =  retailer.gas.totalSales;
          }

          if(count <= 2)
          {
            detailedRetailerSummary["customerType"] =  "RESIDENTIAL";
            detailedRetailerSummary["customerTypeCount"] =  retailer.elec.residential.totalSales;
          }
          else if(count <= 4)
          {
            detailedRetailerSummary["customerType"] =  "BUSINESS";
            detailedRetailerSummary["customerTypeCount"] =  retailer.elec.business.totalSales;
          }
          else if(count <= 6)
          {
            detailedRetailerSummary["customerType"] =  "RESIDENTIAL";
            detailedRetailerSummary["customerTypeCount"] =  retailer.gas.residential.totalSales;
          }
          else
          {
            detailedRetailerSummary["customerType"] =  "BUSINESS";
            detailedRetailerSummary["customerTypeCount"] =  retailer.gas.business.totalSales;
          }

          if(count == 1)
          {
            detailedRetailerSummary["retAcq"] =  "RETENTION";
            detailedRetailerSummary["retAcqCount"] =  retailer.elec.residential.retentionSales;
          }
          else if(count == 2)
          {
            detailedRetailerSummary["retAcq"] =  "ACQUISITION";
            detailedRetailerSummary["retAcqCount"] =  retailer.elec.residential.acquisitionSales;
          }
          else if(count == 3)
          {
            detailedRetailerSummary["retAcq"] =  "RETENTION";
            detailedRetailerSummary["retAcqCount"] =  retailer.elec.business.retentionSales;
          }
          else if(count == 4)
          {
            detailedRetailerSummary["retAcq"] =  "ACQUISITION";
            detailedRetailerSummary["retAcqCount"] =  retailer.elec.business.acquisitionSales;
          }
          else if(count == 5)
          {
            detailedRetailerSummary["retAcq"] =  "RETENTION";
            detailedRetailerSummary["retAcqCount"] =  retailer.gas.residential.retentionSales;
          }
          else if(count == 6)
          {
            detailedRetailerSummary["retAcq"] =  "ACQUISITION";
            detailedRetailerSummary["retAcqCount"] =  retailer.gas.residential.acquisitionSales;
          }
          else if(count == 7)
          {
            detailedRetailerSummary["retAcq"] =  "RETENTION";
            detailedRetailerSummary["retAcqCount"] =  retailer.gas.business.retentionSales;
          }
          else if(count == 8)
          {
            detailedRetailerSummary["retAcq"] =  "ACQUISITION";
            detailedRetailerSummary["retAcqCount"] =  retailer.gas.business.acquisitionSales;
          }

          this.state.detailedRetailerSummaryData.push(detailedRetailerSummary);

          count = count + 1;
        }

      });

      var retailerListWithSalesStats = [];

      !isEmpty(nextProps.salesDistributionStats) && nextProps.salesDistributionStats.retailerDetails.map((retailer, key) => {

        var retailerData = {
          name: retailer.name,
          sales: retailer.totalSales,
        }

        retailerListWithSalesStats.push(retailerData);

      });

      this.state.retailerSummaryChartConfig = {
        "type": "pie",
        "theme": "light",
        "dataProvider": retailerListWithSalesStats,
        "valueField": "sales",
        "titleField": "name",
        "balloon": {
          "fixedPosition": false
        },
        "export": {
          "enabled": false
        }
      };

      
      // htmlToImage.toPng(document.getElementById('salesDistributionData'))
      // .then(function (dataUrl) {
      //   download(dataUrl, 'my-node.png');
      // });

      // const input = document.getElementById('salesDistributionData');
      // html2canvas(input)
      //   .then((canvas) => {
      //     const imgData = canvas.toDataURL('image/png');
      //     download(imgData, 'my-node.png');
      //   })
      // ;


    }

    if (!isEmpty(nextProps.fetchStatus)) {
      if (nextProps.fetchStatus == "Fetching") {
        this.setState({
          isFetchingData: true,
          isSearching: true,
        });
      }
    }
    else {
      this.setState({
        isFetchingData: false,
        isSearching: false,
      });
    }
  }

  render() {


    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-main-content" id="salesDistributionData">
        <ContainerHeader title="Sales Distribution" />
        <Row>
          <Col span={24}>
            <Spin spinning={this.state.isSearching}>
              <Card className="gx-card" title="Search Sales Distribution">
                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleSearch}
                >
                  <Row>
                    <Col lg={18} md={18} sm={18} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Sales Date Range">
                          {getFieldDecorator('drpDateRange', {
                            rules: [{ required: false, message: 'Please select a Date Range' }],
                          })(
                            <RangePicker className="gx-w-100"
                              ranges={{ Today: [moment(), moment()], 'This Month': [moment(), moment().endOf('month')] }}
                              format="YYYY/MM/DD"
                              defaultValue={[moment(), moment()]}
                              onChange={this.handleDateRangeChange}
                            />
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" htmlType="submit">Search</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                          Clear
                    </Button>
                      </center>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Spin>
          </Col>
          <Col span={24}>
            <Card className="gx-card" title="Sales Distribution Summary">
              <Table loading={this.state.isFetchingData} dataSource={this.state.retailerSummaryData}>
                <Column width="150px" title="Campaign Mix" dataIndex="campaignMix" key="campaignMix" />
                <Column align="center" title="Total Sales" dataIndex="totalSales" key="totalSales" />
                  {!isEmpty(this.state.salesDistributionStats) && this.state.salesDistributionStats.retailerDetails.map((retailer, key) => {
                      return <Column align="center" title={retailer.name} dataIndex={(retailer.name).replace(/ /g,'').toLowerCase()} key={(retailer.name).replace(/ /g,'').toLowerCase()} />;
                  })}
              </Table>
            </Card>
          </Col>
          <Col span={24}>
            <Card className="gx-card" title="Detailed Sales Distribution Summary">
              <Table size={"middle"} pagination={{ pageSize: 16 }}  columns={detailedRetailerSummaryColoumns} loading={this.state.isFetchingData} dataSource={this.state.detailedRetailerSummaryData}/>
            </Card>
          </Col>
          <Col span={24}>
           <Spin spinning={this.state.isSearching}>
              <Card className="gx-card" title="Sales Distribution Summary Chart">                
                  {!isEmpty(this.state.retailerSummaryChartConfig) && <AmCharts.React style={{width: "100%", height: "500px"}} options={this.state.retailerSummaryChartConfig}/>}
              </Card>
            </Spin>
          </Col>  
          <Col span={24}>
                <img id="screenshot"/>
          </Col>         
        </Row>
        <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
        <SweetAlert show={this.state.showWarning}
          warning
          showCancel
          confirmBtnText="Yes Delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.handleDelete}
          onCancel={this.onCancelDelete}
        ></SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  salesDistributionStats: state.salesinformation.salesDistributionStats,
  fetchStatus: state.salesinformation.fetchStatus,
});

const mapDispatchToProps = dispath => ({
  getSalesDistribution: (data) => dispath(getSalesDistribution(data)),
});

const WrappedAdvancedSearch = Form.create()(SalesDistribution);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);