import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin, Avatar, DatePicker, Form, Select, Row, Col, Button } from "antd";
import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { getAgentSummary } from "appRedux/actions/sales/salesInformation";
import { getAllVelocifyUsers, getToolsUsers } from "appRedux/actions/admin/users";

import swal from 'sweetalert2';

const moment = extendMoment(Moment);
var momentTimezone = require('moment-timezone');
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const Option = Select.Option;
const FormItem = Form.Item;

const dateFormat = 'DD-MM-YYYY';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class ProfileHeader extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isFetchingData: false,
      isSearchingVelocifyUsers: false,
      lastUpdateTime: null,
      dateRangeFetch: false,
      dataFetchType: "today",
      dateRangeValues: [moment(), moment().add(1, "days")]
    };

  }

  componentDidMount() {

    const { userDetails, accessLevelDetails } = this.props;



    if (!isEmpty(accessLevelDetails) && accessLevelDetails[0].id != 12) {
      this.props.getToolsUsers();
      this.setState({
        lastUpdateTime: moment().format("DD-MM-YYYY hh:mm:ss A"),
        isSearchingVelocifyUsers: true,
      })
    }
    else {
      this.setState({
        lastUpdateTime: moment().format("DD-MM-YYYY hh:mm:ss A")
      })
    }

  }

  componentWillReceiveProps(nextProps) {

    if (!isEmpty(nextProps.singleAgentSummaryData)) {
      this.setState({
        lastUpdateTime: moment().format("DD-MM-YYYY hh:mm:ss A")
      })
    }

    if (!isEmpty(nextProps.toolsUserList) && this.state.isSearchingVelocifyUsers == true) {
      this.setState({
        isSearchingVelocifyUsers: false
      });
    }

    if (!isEmpty(nextProps.fetchStatus)) {
      if (nextProps.fetchStatus == "Fetching") {
        this.setState({
          isFetchingData: true,
        });
      }
    }
    else {
      this.setState({
        isFetchingData: false,
      });
    }

  }

  handleGetSummary = (e) => {
    const { userDetails, accessLevelDetails } = this.props;

    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      //console.log(values)
      if (!err) {
        if (!isEmpty(values)) {
          let fromDate = values.rangeSummary[0].format("YYYY-MM-DD");
          let toDate = values.rangeSummary[1].format("YYYY-MM-DD");

          if (!isEmpty(accessLevelDetails) && accessLevelDetails[0].id != 12) {
            this.props.getAgentSummary(values.drpUser, fromDate, toDate);
          } else {

            if(values.rangeSummary[1].diff(values.rangeSummary[0], 'days') > 31){
              swal.fire({
                  title: "Date Range Limit",
                  text: `You have selected ${values.rangeSummary[1].diff(values.rangeSummary[0], 'days')} days. Only a maximum of 31 days is allowed.`,
                  showConfirmButton: true,
              });
              toDate = moment(fromDate, "YYYY-MM-DD").add(29, 'days').format("YYYY-MM-DD")
            }

            this.props.getAgentSummary(userDetails.name, fromDate, toDate);
            this.setState({
              dateRangeFetch: true,
              dataFetchType: "custom",
              dateRangeValues: [moment(fromDate), moment(toDate)]
            })
          }


        }


      }

      // setTimeout(() => {

      //   if (!isEmpty(filtersForSearch))
      //     this.props.getAgentSummary(filtersForSearch);

      // }, 1000);
    });
  };

  handleGetReaaltimeSummary = (e) => {
    const { userDetails, accessLevelDetails } = this.props;

    e.preventDefault();
    this.props.getAgentSummary(userDetails.name, moment().format("YYYY-MM-DD"), moment().add(1, "days").format("YYYY-MM-DD"));
    this.setState({
      dateRangeFetch: false,
      dataFetchType: "today",
      dateRangeValues: [moment(), moment().add(1, "days")]
    })
  };

  handleGetThisWeekSummary = (e) => {
    const { userDetails, accessLevelDetails } = this.props;

    e.preventDefault();
    this.props.getAgentSummary(userDetails.name, moment().day(1).format("YYYY-MM-DD"), moment().day(6).format("YYYY-MM-DD"));
    this.setState({
      dateRangeFetch: true,
      dataFetchType: "thisWeek",
      dateRangeValues: [moment().day(1), moment().day(6)]
    })
  };

  render() {

    const { userDetails, accessLevelDetails, toolsUserList } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (



      <div className="gx-profile-banner">
        <div className="gx-profile-container">
          {!isEmpty(accessLevelDetails) && accessLevelDetails[0].id != 12 && !isEmpty(toolsUserList) &&
            <Spin spinning={this.state.isSearchingVelocifyUsers}>
              <Row>
                <Col md={8}>
                  <FormItem {...formItemLayout} label="" >
                    {getFieldDecorator('drpUser', {
                      defaultValue: userDetails.name,
                      rules: [{ required: true, message: 'Please select a User!' }],
                    })(
                      <Select
                        showSearch
                        style={{ width: '350px' }}
                        placeholder="Select Velocify User"
                        optionFilterProp="children"
                        size={`large`}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {!isEmpty(toolsUserList) && toolsUserList.map((user, key) => {
                          return <option key={key} value={user.name}>{user.name}</option>;
                        })}
                      </Select>
                    )}
                  </FormItem>

                </Col>
                <Col md={8}>
                  <FormItem {...formItemLayout} label="" >
                    {getFieldDecorator('rangeSummary', {
                      initialValue: this.state.dateRangeValues,
                      value: this.state.dateRangeValues,
                      rules: [{ required: true, message: 'Please select range for the summary' }],
                    })(
                      <RangePicker style={{ width: '350px' }} className="" size={`large`}/>
                    )}
                  </FormItem>

                </Col>
                <Col md={8}>
                  <Button type="primary" size={`large`} loading={this.state.isFetchingData} onClick={(e) => this.handleGetSummary(e)}>
                    {this.state.isFetchingData ? <>{" "}Fetching Summary</> : <>Get Summary</>}
                  </Button>

                </Col>
              </Row>

            </Spin>
          }
          {!isEmpty(accessLevelDetails) && accessLevelDetails[0].id == 12 &&
            <Spin spinning={this.state.isSearchingVelocifyUsers}>
              <Row>
                <Col md={10} className="gx-col-full gx-mt-3">
                  <Row>
                    <Col md={12} >
                      <FormItem {...formItemLayout} label="" >
                        {getFieldDecorator('rangeSummary', {
                          initialValue: this.state.dateRangeValues,
                          value: this.state.dateRangeValues,
                          rules: [{ required: true, message: 'Please select range for the summary' }],
                        })(
                          <RangePicker defaultValue={this.state.dateRangeValues} style={{ width: '350px' }} size={`large`} />
                        )}
                      </FormItem>
                    </Col>
                    <Col md={12} style={{paddingLeft: "50px"}}>
                      <Button type="primary" size={`large`} loading={this.state.isFetchingData} onClick={(e) => this.handleGetSummary(e)}>
                        {this.state.isFetchingData ? <>{" "}Fetching Summary</> : <>Get Summary</>}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <Button type="primary" size={`large`} loading={this.state.isFetchingData} onClick={(e) => this.handleGetReaaltimeSummary(e)}>
                        {this.state.isFetchingData ? <>{" "}Fetching Summary</> : <>Get Today's Summary</>}
                      </Button>
                      <Button type="primary" size={`large`} loading={this.state.isFetchingData} onClick={(e) => this.handleGetThisWeekSummary(e)}>
                        {this.state.isFetchingData ? <>{" "}Fetching Summary</> : <>Get This Week's Summary</>}
                      </Button>
                    </Col>
                  </Row>



                </Col>
                <Col md={14} className="gx-col-full gx-mt-3 gx-text-right">
                  {(!isEmpty(accessLevelDetails) && accessLevelDetails[0].id == 12 && this.state.dateRangeFetch === false && this.state.dataFetchType === "today") && <div className="gx-profile-banner-top-right gx-text-right">
                    <span className="h2 gx-fs-xxl gx-font-weight-medium gx-mb-4">My Sales Summary as at {this.state.lastUpdateTime}</span>
                  </div>}
                  {(!isEmpty(accessLevelDetails) && accessLevelDetails[0].id == 12 && this.state.dateRangeFetch === true && this.state.dataFetchType === "custom") && <div className="gx-profile-banner-top-right gx-text-right">
                    <span className="h2 gx-fs-xxl gx-font-weight-medium gx-mb-4">My Sales Summary from {this.state.dateRangeValues[0].format("DD-MM-YYYY")} to {this.state.dateRangeValues[1].format("DD-MM-YYYY")}</span>
                  </div>}
                  {(!isEmpty(accessLevelDetails) && accessLevelDetails[0].id == 12 && this.state.dateRangeFetch === true && this.state.dataFetchType === "thisWeek") && <div className="gx-profile-banner-top-right gx-text-right">
                    <span className="h2 gx-fs-xxl gx-font-weight-medium gx-mb-4">My Sales Summary for this week</span>
                  </div>}
                </Col>
              </Row>



            </Spin>}
          {/* <div className="gx-profile-banner-bottom">
          <div className="gx-tab-list">
            <ul className="gx-navbar-nav">
              <li>
                <span className="gx-link">Timeline</span>
              </li>
              <li>
                <span className="gx-link">About</span>
              </li>
              <li>
                <span className="gx-link">Photos</span>
              </li>
              <li>
                <span className="gx-link">Friends <span className="gx-fs-xs">287</span></span>
              </li>
              <li>
                <span className="gx-link">More</span>
              </li>
            </ul>
          </div>
          <span className="gx-link gx-profile-setting">
            <i className="icon icon-setting gx-fs-lg gx-mr-2 gx-mr-sm-3 gx-d-inline-flex gx-vertical-align-middle"/>
            <span className="gx-d-inline-flex gx-vertical-align-middle gx-ml-1 gx-ml-sm-0">Setting</span>
          </span>
        </div> */}
        </div>
      </div>


    )
  }

}

// const mapStateToProps = ({ auth }) => {
//   const { userDetails } = auth;
//   return { userDetails }
// };

// export default connect(mapStateToProps, {})(ProfileHeader);

const mapStateToProps = state => ({
  singleAgentSummaryData: state.salesinformation.singleAgentSummaryData,
  userDetails: state.auth.userDetails,
  accessLevelDetails: state.auth.accessLevelDetails,

  fetchStatus: state.salesinformation.fetchStatus,

  velocifyUserList: state.users.velocifyUserList,
  toolsUserList: state.users.toolsUserList,
});

const mapDispatchToProps = dispath => ({
  getAgentSummary: (agentName, fromDate, toDate) => dispath(getAgentSummary(agentName, fromDate, toDate)),

  getAllVelocifyUsers: (data) => dispath(getAllVelocifyUsers(data)),
  getToolsUsers: (data) => dispath(getToolsUsers(data)),
});

const WrappedAdvancedSearch = Form.create()(ProfileHeader);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);

// const ProfileHeader = () => {
//   return (

//   )
// }

// export default ProfileHeader;
