import React, { Component } from "react";
import { connect } from "react-redux";
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch } from "react-router-dom";
import { LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import { setInitUrl } from "appRedux/actions/Auth";
import setAuthToken from '../../util/setAuthToken';
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from "appRedux/actions/Setting";

import isEmpty from "util/isEmpty";

import {
  auth,
} from "../../firebase/firebase";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";

import {setWithExpiry, removeKey, getWithExpiry} from '../../util/customSessionManager';
import {userSignOut} from "appRedux/actions/Auth";

const RestrictedRoute = ({ component: Component, authUser, localStorageUser , ...rest }) =>
  <Route
    {...rest}
    render={props =>
      localStorageUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location }
          }}
        />}
  />;




class App extends Component {

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  setNewtoken = () => {
    if (auth != null) {
      try {
        auth.currentUser.getIdToken(true)
          .then(function (result) {
            //localStorage.setItem('tokenID', result);
            setWithExpiry('tokenID', result);
            setAuthToken(result);
          }).catch(function (error) {
          });
      }
      catch (e) {
      }
    }
  };

  componentWillMount() {

    //this.props.userSignOut()

    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);

    if (params.has("theme")) {
      this.props.setThemeType(params.get('theme'));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get('nav-style'));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get('layout-type'));
    }

    this.refreshTokenID = setInterval(this.setNewtoken.bind(this), 2700000);
    //this.refreshTokenID = setInterval(this.setNewtoken.bind(this), 60000); 

  }



  render() {
    const { match, location, layoutType, navStyle, locale, authUser, initURL, tokenID, modulesAccess, accessLevelDetails } = this.props;

    if (getWithExpiry('tokenID') === null) {
      //console.log("Token ID is null");
      setAuthToken(false);
    }
    else {
      //console.log("1 - " + tokenID);
      setAuthToken(getWithExpiry('tokenID'));
    }

    const localStorageUser = getWithExpiry('user_id')

    if (location.pathname === '/') {
      if (localStorageUser === null) {
        return (<Redirect to={'/signin'} />);
      } else if ((initURL === '' || initURL === '/' || initURL === '/signin')) {
        return (<Redirect to={'/home/dashboard'} />);
      } else {
        return (<Redirect to={initURL} />);
      }
    }


    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>

          <Switch>
            <Route exact path='/signin' component={SignIn} />
            <RestrictedRoute path={`${match.url}`} authUser={authUser} localStorageUser={localStorageUser}
              component={MainApp} />
          </Switch>
        </IntlProvider>
      </LocaleProvider>
    )
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale, navStyle, layoutType } = settings;
  const { authUser, initURL, tokenID, modulesAccess, accessLevelDetails } = auth;
  return { locale, navStyle, layoutType, authUser, initURL, tokenID, modulesAccess, accessLevelDetails }
};
export default connect(mapStateToProps, { setInitUrl, setThemeType, onNavStyleChange, onLayoutTypeChange, userSignOut })(App);
