import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Spin, Card, Col, Form, Row, Button, Select, message } from "antd";
import NewBroadbandFees from './NewBroadbandFees';

import { searchDMOVDORecords, clearError } from "appRedux/actions/admin/dmovdo";
import { getRetailerList } from "appRedux/actions/admin/retailers";
import { getDistributorList } from "appRedux/actions/admin/distributors";
import { getUniqueTariffs } from "appRedux/actions/admin/tariff";
import isEmpty from "util/isEmpty";
import BroadbandTable from './BroadbandTable';
import { deleteBroadbandFeeRecord, getAllBroadbandDetails, searchBroadbandFeesRecords, setCreateStatusToDefault, setSelectedPlan } from '../../../../appRedux/actions/admin/broadbandFees';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const formItemLayout2 = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    style: { textAlign: "left" },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};
const { Option } = Select;
const FormItem = Form.Item;

const pagination = { position: 'bottom' };

export class BroadbandFees extends Component {

  constructor(props) {
    super(props)

    this.state = {
      newForm: false,
      isSearching: false,
      pagination,
      dataSource: [],

    }

  }

  componentDidMount() {
    this.props.getAllBroadbandDetails()
    this.props.getRetailerList();
    this.props.getUniqueTariffs();
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.deleteStatus === 'success') {

      message.success('Record Deleted Successfully');

      this.props.setCreateStatusToDefault()

      this.props.getAllBroadbandDetails()

    }
  }

  navigateToAddNewBroadbandFeePage = () => {
    this.props.history.replace('/admin/manage/broadbandFee/new')
  }

  navigateToUpdate = (data) => {
    this.props.setFeesPlan(data)
    this.props.history.replace(`/admin/manage/broadbandFee/${data.id}`)
  }

  deleteBroadbandFeeRecord = (id) => {

    this.props.deleteBroadbandFeeRecord(id)

  }

  handleSearch = (e) => {

    e.preventDefault();

    this.props.form.validateFields((err, values) => {

      const filtersForSearch = {
        ...(values.txtRetailerNameSearch != "-1" && { retailer: values.txtRetailerNameSearch }),
        ...(values.txtStateSearch != "-1" && { state: values.txtStateSearch }),
        ...(values.txtResSmeSearch != "-1" && { res_sme: values.txtResSmeSearch }),

      }

      this.setState({ isSearching: true });

      if (!isEmpty(filtersForSearch)) {

        this.props.searchBroadbandFeesRecords(filtersForSearch);

      }
      else {

        this.props.getAllBroadbandDetails();

        this.setState({ isSearching: false });

      }

    });

  };

  handleClearSearch = () => {

    this.props.form.resetFields();

    this.props.getAllBroadbandDetails();
  }

  componentWillReceiveProps(nextProps) {


    if (!isEmpty(nextProps.broadbandFees && nextProps.broadbandFees.result)) {

      this.state.dataSource = nextProps.broadbandFees;

      this.setState({ isSearching: false });
    }
    else {

      this.state.dataSource = [];

      this.setState({ isSearching: false });
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <Col span={24}>

        {
          this.state.newForm && <NewBroadbandFees />
        }

        {!this.state.newForm && <Col span={24}>
          <Spin spinning={this.state.isSearching}>
            <Card className="gx-card" title={`Search Broadband Fees`} style={{ maxHeight: "550px", overflow: "hidden" }}>
              <Form
                className="ant-advanced-search-form"
                style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
              >
                <Row>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Retailer" >
                        {getFieldDecorator('txtRetailerNameSearch', {
                          initialValue: "-1",
                        })(
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a Retailer"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          >
                            <Option value="-1">All Retailers</Option>
                            {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                              return <option key={key} value={retailer.retailerFriendlyName}>{retailer.retailerName}</option>;
                            })}
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="State" >
                        {getFieldDecorator('txtStateSearch', {
                          initialValue: "-1",
                        })(
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Select State"
                          >
                            <Option value="-1">All States</Option>
                            <Option value="VIC">Victoria</Option>
                            <Option value="NSW">New South Wales</Option>
                            <Option value="ACT">Australian Capital Territory</Option>
                            <Option value="QLD">Queensland</Option>
                            <Option value="SA">South Australia</Option>
                            <Option value="WA">Western Australia</Option>
                            <Option value="NT">Northern Territory</Option>
                            <Option value="TAS">Tasmania</Option>
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="RES/SME" >
                        {getFieldDecorator('txtResSmeSearch', {
                          initialValue: "-1",
                        })(
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Select Options"
                          >
                            <Option value="-1">All Options</Option>
                            <Option value="RES">RES</Option>
                            <Option value="SME">SME</Option>
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  {/* <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Distributor" >
                        {getFieldDecorator('txtDistributorSearch', {
                          initialValue: "-1",
                        })(
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a Distributor"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          >
                            <Option value="-1">All Distributors</Option>
                            {!isEmpty(this.props.distributorList) && this.props.distributorList.map((distributor, key) => {
                              return <option key={key} value={distributor.distributorName}>{distributor.distributorName}</option>;
                            })}
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Version" >
                        {getFieldDecorator('txtVersionSearch', {
                          initialValue: "-1",
                        })(
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Select Version"
                          >
                            <Option value="-1">All Versions</Option>
                            <Option value="All">All</Option>
                            <Option value="1">1</Option>
                            <Option value="4">4</Option>
                            <Option value="5">5</Option>
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col> */}
                </Row>
              </Form>
              <Row>
                <Col span={24} className="gx-text-right">
                  <center>
                    <Button type="primary" onClick={this.handleSearch} loading={this.state.isSearching}>
                      Search
                    </Button>
                    <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                      Clear
                    </Button>
                  </center>
                </Col>
              </Row>
            </Card>
          </Spin>
        </Col>
        }

        <BroadbandTable
          data={this.state.dataSource}
          navigate={this.navigateToAddNewBroadbandFeePage}
          navigateToUpdate={(data) => this.navigateToUpdate(data)}
          deleteRecord={this.deleteBroadbandFeeRecord}
        />
      </Col>
    )
  }
}

const mapStateToProps = (state) => ({
  recordUpdateStatus: state.dmoVdo.recordUpdateStatus,
  error: state.dmoVdo.error,
  retailerList: state.retailers.retailerList,
  tariffList: state.tariff.tariffList,
  broadbandFees: state.broadbandFees.broadbandFees,
  deleteStatus: state.broadbandFees.deleteStatus,
})

const mapDispatchToProps = dispath => ({
  getRetailerList: (data) => dispath(getRetailerList(data)),
  getUniqueTariffs: () => dispath(getUniqueTariffs()),
  searchBroadbandFeesRecords: (data) => dispath(searchBroadbandFeesRecords(data)),
  getAllBroadbandDetails: () => dispath(getAllBroadbandDetails()),
  setFeesPlan: (data) => dispath(setSelectedPlan(data)),
  deleteBroadbandFeeRecord: (id) => dispath(deleteBroadbandFeeRecord(id)),
  setCreateStatusToDefault: () => dispath(setCreateStatusToDefault()),
  clearError: () => dispath(clearError()),
})

const WrappedBroadbandGFees = Form.create()(BroadbandFees);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedBroadbandGFees)