const initialState = {
    diallerPackList: {},
    diallerPackData :{},
    diallerPackGenerationStatus :null,
    washersAvailable :null,
    recordUpdateStatus: null,
    error: null,
}

export default function (state = initialState, action){
    switch(action.type)
    {
        case 'SAVE_DIALLER_PACK_LIST':
            return{
                ...state,
                diallerPackList: action.diallerPackList
            };
        case 'SAVE_DIALLER_PACK_GENERTION_STATUS':
            return{
                ...state,
                diallerPackGenerationStatus: action.diallerPackGenerationStatus
            };
        case 'SAVE_WASHERS_AVAILABLE_COUNT':
            return{
                ...state,
                washersAvailable: action.washersAvailable
            };
        case 'SAVE_RECORD_UPDATE_STATUS':
            return {
                ...state,
                recordUpdateStatus: action.recordUpdateStatus
            };
        case 'ERROR':
            return{
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}