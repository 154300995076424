import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';

export const saveRecords = resultList => ({
    type: 'SAVE_MODULE_ACCESS_RECORDS',
    resultList,
});

export const saveTariffList = resultList => ({
    type: 'SAVE_TARIFF_LIST',
    resultList,
});

export const saveRecordUpdateStatus = recordUpdateStatus => ({
    type: 'SAVE_RECORD_UPDATE_STATUS',
    recordUpdateStatus,
});

export const error = error => ({
    type: 'ERROR',
    error,
});

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const getModuleAccessRecords = () => dispath =>{

    axios.get(apidetails.API_URL + 'moduleAccess/')
    .then(res => {
        dispath(saveRecords(res.data.modules));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const searchModuleAccessRecords = (data) => dispath =>{
    axios.post(apidetails.API_URL + 'moduleAccess/searchNew', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecords(res.data.result));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const updateModuleAccessSingleRecord = (data) => dispath =>{

    axios.put(apidetails.API_URL + 'moduleAccess/', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const createModuleAccessRecord = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'moduleAccess/', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const deleteModuleAccessRecord = (idToDelete) => dispath =>{

    axios.delete(apidetails.API_URL + `moduleAccess/${idToDelete}`, config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const clearError = () => dispath =>{
    dispath(error(''));
}