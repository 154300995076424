import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';

export const saveUserList = userList => ({
    type: 'SAVE_USER_LIST',
    userList,
});

export const saveToolsUserList = toolsUserList => ({
    type: 'SAVE_TOOLS_USER_LIST',
    toolsUserList,
});

export const saveVelocifyUserList = velocifyUserList => ({
    type: 'SAVE_VELOCIFY_USER_LIST',
    velocifyUserList,
});


export const saveRecordUpdateStatus = recordUpdateStatus => ({
    type: 'SAVE_RECORD_UPDATE_STATUS',
    recordUpdateStatus,
});

export const error = error => ({
    type: 'ERROR',
    error,
});

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}


export const getUsers = (data) => dispath =>{

    if(data !== undefined)
    {
        axios.post(apidetails.API_URL + 'users/search', qs.stringify(data), config)
        .then(res => {
            dispath(saveUserList(res.data.users));
        })
        .catch(err => {
            dispath(error(err.message || 'ERROR'));
        });
    }
    else
    {
        axios.get(apidetails.API_URL + 'users/')
        .then(res => {
            dispath(saveUserList(res.data.users));
        })
        .catch(err => {
            dispath(error(err.message || 'ERROR'));
        });
    }    
}

export const updateUserAccessLevel = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'users/updateAccessLevel', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.message || 'ERROR'));
    });  
}


export const deleteUser = (data) => dispath =>{

    // axios.post(apidetails.API_URL + 'elecPostcodeDistributors/delete', qs.stringify(data), config)
    // .then(res => {
    //     dispath(saveRecordUpdateStatus(res.data));
    //     dispath(saveRecordUpdateStatus(null));
    // })
    // .catch(err => {
    //     dispath(error(err.message || 'ERROR'));
    // });  
}

export const getToolsUsers = (data) => dispath =>{

    dispath(saveToolsUserList({}));
    if(data !== undefined)
    {
        axios.post(apidetails.API_URL + 'users/searchToolsUsers', qs.stringify(data), config)
        .then(res => {
            dispath(saveToolsUserList(res.data.result));
        })
        .catch(err => {
            dispath(error(err.response.data.message || 'ERROR'));
            dispath(error(''));
        });
    }
    else
    {
        axios.get(apidetails.API_URL + 'users/allToolsUsers')
        .then(res => {
            dispath(saveToolsUserList(res.data.result));
        })
        .catch(err => {
            dispath(error(err.response.data.message || 'ERROR'));
            dispath(error(''));
        });
    }    
    dispath(saveToolsUserList({}));
}

export const getAllVelocifyUsers = (data) => dispath =>{

    axios.get(apidetails.API_URL + 'users/allVelocifyUsers')
    .then(res => {
        dispath(saveVelocifyUserList(res.data.result));
    })
    .catch(err => {
        dispath(error(err.message || 'ERROR'));
    }); 
}

export const createNewToolsUser = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'users/createToolsUser', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
        dispath(error(''));
    });
}