import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';
import isEmpty from "util/isEmpty"



export const saveBroadbandFees = feesDetails => ({
    type: 'SAVE_BROADBAND_FEES',
    feesDetails,
});

export const newBroadbandFees = feePlan => ({
    type: 'NEW_BROADBAND_FEES',
    feePlan,
});

const updateBroadbandFee = feePlan => ({
    type: 'UPDATE_BROADBAND_FEES',
    feePlan,
})

const saveSelectedPlanOnState = feePlan => ({
    type: 'SET_SELECTED_BROADBAND_FEES',
    feePlan,
})

const deleteBroadbandFeePlan = () => ({
    type: 'DELETE_BROADBAND_FEES',
})

export const error = error => ({
    type: 'ERROR',
    error,
});

const setToDefaultStatus = () => ({
    type: 'REST_BROADBAND_FEES_CREATE_STATUS',
})


export const resetBroadbandFees = () => ({
    type: 'REST_BROADBAND_FEES',
});



const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const clearErrors = (data) => dispath => {

    dispath(error(null));
}

export const clearAll = () => dispath => {

    dispath(resetBroadbandFees());
    dispath(error(null));

}

export const setCreateStatusToDefault = () => dispath => {

    dispath(setToDefaultStatus());
}

export const getAllBroadbandDetails = (planDetails) => dispath => {

    axios.get(`${apidetails.API_URL}broadbandFees`)
        .then(res => {
            dispath(saveBroadbandFees(res.data))
        })
        .catch(err => {
            dispath(error(!isEmpty(err.response.data.message) ? err.response.data.message : 'ERROR'));
        });

}

// Create New Broadband Fee Record 
export const createBroadbandFeePlan = (data) => dispath => {

    axios.post(`${apidetails.API_URL}broadbandFees`, qs.stringify(data), config)
        .then(res => {
            dispath(newBroadbandFees(res.data.result));
        })
        .catch(err => {
            dispath(error(err.response.data.message || 'ERROR'));
        });
}

// Update existing Broadband Fee Record 
export const updateBroadbandFeePlan = (data) => dispath => {

    axios.put(`${apidetails.API_URL}broadbandFees`, qs.stringify(data), config)
        .then(res => {
            dispath(updateBroadbandFee(res.data.result));
        })
        .catch(err => {
            dispath(error(err.response.data.message || 'ERROR'));
        });
}

export const setSelectedPlan = (data) => dispath => {
    dispath(saveSelectedPlanOnState(data))
}

export const deleteBroadbandFeeRecord = (id) => dispath => {
    axios.delete(`${apidetails.API_URL}broadbandFees/${id}`)
        .then(res => {
            dispath(deleteBroadbandFeePlan());
        })
        .catch(err => {
            dispath(error(err.response.data.message || 'ERROR'));
        });
}

export const searchBroadbandFeesRecords = (data) => dispath => {

    axios.post(apidetails.API_URL + `broadbandFees/` + 'search', qs.stringify(data), config)
        .then(res => {

            dispath(saveBroadbandFees(res.data));

        })
        .catch(err => {

            dispath(error(err.response.data.message || 'ERROR'));

        });
}

export const getBroadbandFeeById = (id) => dispath => {

    axios.get(apidetails.API_URL + `broadbandFees/${id}`,)
        .then(res => {

            dispath(saveSelectedPlanOnState(res.data.result[0]));

        })
        .catch(err => {

            dispath(error(err.response.data.message || 'ERROR'));

        });
}