const initialState = {
  statusList: {},
  recordUpdateStatus: null,
  error: null,
}

export default function (state = initialState, action){
  switch(action.type)
  {
      case 'SAVE_STATUS_LIST':
          return{
              ...state,
              statusList: action.statusList
          };
      case 'SAVE_RECORD_UPDATE_STATUS':
          return {
              ...state,
              recordUpdateStatus: action.recordUpdateStatus
          };
      case 'ERROR':
          return{
              ...state,
              error: action.error
          };
      default:
          return state;
  }
}