import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';
import isEmpty from "util/isEmpty";

export const resetAlintaToken = alintaTokenResponse => ({
  type: 'RESET_ALINTA_TOKEN',
  alintaTokenResponse,
});

export const saveSalesToProcess = salesToProcess => ({
  type: 'SAVE_SALES_TO_PROCESS',
  salesToProcess,
});

export const saveOrderUpdates = orderUpdates => ({
  type: 'SAVE_ORDER_UPDATES',
  orderUpdates,
});

export const saveProcessSaleLogs = processSaleLogs => ({
  type: 'SAVE_PROCESS_LOGS',
  processSaleLogs,
});

export const saveSalesInProgress = salesInProgress => ({
  type: 'SAVE_SALES_INPROGRESS',
  salesInProgress,
});

export const saveProcessSaleBatchDetails = processSaleBatchDetails => ({
  type: 'SAVE_PROCESS_SALE_BATCH_DETAILS',
  processSaleBatchDetails,
});

export const saveRetailerCallbackLogs = retailerCallbackLogs => ({
  type: 'SAVE_RETAILER_CALLBACK_LOGS',
  retailerCallbackLogs,
});

export const saveRecordUpdateStatus = recordUpdateStatus => ({
  type: 'SAVE_RECORD_UPDATE_STATUS',
  recordUpdateStatus,
});

export const error = error => ({
  type: 'ERROR',
  error,
});

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

export const clearErrors = (data) => dispath => {

  dispath(error(null));
}

export const resetAlintaTokenFn = () => dispath => {
  axios.get(apidetails.API_URL + 'alintaToken')
    .then(res => {
        let message = res.data.message
        dispath(resetAlintaToken(message));
    })
    .catch(err => {
        // dispath(error(err.message || 'ERROR'));
        console.log("Error Alinta Token")
    });
}

export const getSalesToProcess = (data) => dispath => {

  dispath(saveSalesToProcess(null));
  if (data !== undefined) {
    axios.post(apidetails.API_URL + 'processSales/salesToProcess', qs.stringify(data), config)
      .then(res => {
        if (res.data.recordCount != 0)
        {
          dispath(saveSalesToProcess(res.data.saleRecords));
          dispath(saveSalesToProcess(null));
        }
        else
        {
          dispath(saveSalesToProcess([]));
          dispath(saveSalesToProcess(null));
        } 
      })
      .catch(err => {
        console.log(err);
        //dispath(error(err.response.data.message || 'ERROR'));
      });
  }
  else {
    dispath(error("No Payload Found" || 'ERROR'));
  }
}

export const clearSales = (data) => dispath => {

  dispath(saveSalesToProcess(null));
}

export const processSale = (data) => dispath => {

  dispath(saveRecordUpdateStatus(null));
  setTimeout(function () {
  axios.post(apidetails.API_URL + 'processSales/processSale', qs.stringify(data), config)
    .then(res => {
      dispath(saveRecordUpdateStatus(res.data));
      dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
      console.log(err);
      //dispath(error(err.response.data.message || 'ERROR'));
    });
  }, 2000);
}

export const getOrderUpdates = (data, retailer) => dispath => {

  dispath(saveOrderUpdates(null));

  let orderUpdateTimeout = 45000;

  const updatesConfig = [
    {
      retailerKey: "Lumo",
      token: "ZXdfY21zX2x1bW9fMTp2X1ZFLXJ6d041Vz8yKzU0",
      endpoint: "retailerEndPoints/lumo",
      timeout: 15000,
      orderUpdateTimeout: 45000
    },
    {
      retailerKey: "Origin",
      token: "ZXdfY21zX29yaWdpbl8yOmFKSmJITHctOTlnOXo3JDI",
      endpoint: "retailerEndPoints/origin/v3",
      timeout: 15000,
      orderUpdateTimeout: 45000
    },
    {
      retailerKey: "Alinta Energy",
      token: "ZXdfY21zX2FsaW50YV8xOiQ1QkRFJjFCOHZmUQ",
      endpoint: "retailerEndPoints/alinta",
      timeout: 25000,
      orderUpdateTimeout: 60000
    }
  ];

   try {
     if (!isEmpty(updatesConfig.find(x => x.retailerKey === retailer))) {
       orderUpdateTimeout = updatesConfig.find(x => x.retailerKey === retailer).orderUpdateTimeout;
     }
   } catch (err) {
     console.log(err);
   }

  if(!isEmpty(updatesConfig.find(x => x.retailerKey === retailer))){
    setTimeout(function() {
      //'Authorization': 'Basic ZXdfY21zX2x1bW9fdGVzdF8xOlBwQHRlc3Q4NTA='
      let specialConfigLumo = {
        headers: {
          Authorization: `Basic ${
            updatesConfig.find(x => x.retailerKey === retailer).token
          }`
        }
      };

      axios
        .post(
          apidetails.API_URL +
            `${updatesConfig.find(x => x.retailerKey === retailer).endpoint}`,
          null,
          specialConfigLumo
        )
        .then(res => {})
        .catch(err => {
          console.log(err);
        });
    }, updatesConfig.find(x => x.retailerKey === retailer).timeout);  
  }   

  setTimeout(function() {
    axios
      .post(
        apidetails.API_URL + "processSales/getOrderUpdates",
        qs.stringify(data),
        config
      )
      .then(res => {
        dispath(saveOrderUpdates(res.data.results));
        dispath(saveOrderUpdates(null));
      })
      .catch(err => {
        console.log(err);
        //dispath(error(err.response.data.message || 'ERROR'));
      });
  }, orderUpdateTimeout);


}

export const getProcessSaleLogs = (data) => dispath => {

  dispath(saveProcessSaleLogs(null));
  //dispath(error(null));

  axios.post(apidetails.API_URL + 'processSales/getProcessSaleLogs', qs.stringify(data), config)
  .then(res => {
    dispath(saveProcessSaleLogs(res.data.results));
  })
  .catch(err => {
    console.log(err.response.data.message);
    dispath(error(err.response.data.message || 'ERROR'));
    //dispath(error(null));
  });

}

export const getSalesInProgress = (data) => dispath => {

  dispath(saveSalesInProgress(null));
  //dispath(error(null));

  axios.get(apidetails.API_URL + 'processSales/getSalesInProgress', config)
  .then(res => {
    dispath(saveSalesInProgress(res.data.results));
  })
  .catch(err => {
    console.log(err.response.data.message);
    dispath(error(err.response.data.message || 'ERROR'));
    //dispath(error(null));
  });

}

// New Search Option - Vsrimal - 04/05/2023
export const searchSalesInProgress = (data) => dispath =>{
  axios.post(apidetails.API_URL + 'processSales/searchSalesInProgress', qs.stringify(data), config)
  .then(res => {
      dispath(saveSalesInProgress(res.data.results));
  })
  .catch(err => {
      console.log(err.response.data.message);
      dispath(error(err.response.data.message || 'ERROR'));
  });  
}

// New Delete Option - Vsrimal - 04/05/2023
export const deleteSaleInProgress = (idToDelete) => dispath =>{

  axios.delete(apidetails.API_URL + `processSales/deleteSalesInProgress/${idToDelete}`, config)
  .then(res => {
      dispath(saveRecordUpdateStatus(res.data));
      dispath(saveRecordUpdateStatus(null));
  })
  .catch(err => {
      dispath(error(err.response.data.message || 'ERROR'));
  });  
}

export const getRetailerSaleCallbackLogs = (data) => dispath => {

  axios.post(apidetails.API_URL + 'processSales/getRetailerSaleCallbackLogs', qs.stringify(data), config)
  .then(res => {
    dispath(saveRetailerCallbackLogs(res.data.results));
  })
  .catch(err => {
    console.log(err);
    //dispath(error(err.response.data.message || 'ERROR'));
  });
  
}

export const getProcessSaleBatchDetails = (data) => dispath => {

  axios.post(apidetails.API_URL + 'processSales/getProcessSaleBatchDetails', qs.stringify(data), config)
  .then(res => {
    dispath(saveProcessSaleBatchDetails(res.data.results));
  })
  .catch(err => {
    console.log(err);
    //dispath(error(err.response.data.message || 'ERROR'));
  });
  
}