import React from "react";
import {Route, Switch} from "react-router-dom";
import ManageLeads from "./ManageLeads";
import ManageInternalDNC from "./ManageInternalDNC";
import ManageDataPacks from "./ManageDataPacks";

const PostCodeMapping = ({match}) => (
  <Switch>
    <Route path={`${match.url}/manageLeads`} component={ManageLeads}/>
    <Route path={`${match.url}/manageInternalDNC`} component={ManageInternalDNC}/>
    <Route path={`${match.url}/manageDataPacks`} component={ManageDataPacks}/>
  </Switch>
);

export default PostCodeMapping;
