import axios from 'axios';
import {setWithExpiry, removeKey} from '../util/customSessionManager';

const setAuthToken = token => {
    if (token) {
        //localStorage.setItem('tokenID', token);
        setWithExpiry('tokenID', token);
        axios.defaults.headers.common['tokenID'] = token;
        axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    else {
        delete axios.defaults.headers.common['tokenID'];
        delete axios.defaults.headers.common['Content-Type'];
    }
}

export default setAuthToken;