import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getRetailerFileHeaderList, updateRetailerFileHeader, addRetailerFileHeader, deleteRetailerFileHeader } from "appRedux/actions/admin/retailerFileMapping";
import { getRetailerList } from "appRedux/actions/admin/retailers";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);


//const title = () => 'Here is title';
const showHeader = true;
//const footer = () => 'Here is footer';
//const scroll = {y: 500};
const pagination = { position: 'bottom' };

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const EditableContext = React.createContext();

class EditableCell extends React.Component {

  getInput = () => {
    const {
      dataIndex,
      record,
    } = this.props;

    function onChange(checked) {
    }

    if (this.props.inputType === 'statusSelection') {
      return <Switch disabled={false} defaultChecked={record.isActive == 1 ? true : false} onChange={onChange} />;
    }
    else if (this.props.inputType === 'fuelTypeSelection') {
      return <Select
        style={{ width: '250px' }}
        placeholder="Please select Fuel Type"
      >
        <Option key="ELEC">ELEC</Option>
        <Option key="GAS">GAS</Option>
      </Select>;
    }
    else if (this.props.inputType === 'retailerSelection') {
      return  <Select
                showSearch
                style={{ width: '100%', minWidth: 300 }}
                placeholder="Select Retailer"
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value="-1">Select</Option>
                {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                    return <option key={key} value={retailer.id}>{retailer.retailerName}</option>;
                })}
              </Select>;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    function getInitialValue(dataIndex) {
      if(dataIndex == "logo")
      {
        return "-"
      }
      else if(dataIndex == "isActive")
      {
        console.log(record[dataIndex]);
        return record[dataIndex] == 1 ? true : false;
      }
      else
      {
        return record[dataIndex];
      }
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0, width: '100%' , display:'block', }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: getInitialValue(dataIndex),
            })(this.getInput())}
          </Form.Item>
        ) : (
            children
          )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class MapPostcodesRetailer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editingKey: '',
      isAdding: false,
      isSearching: false,
      recordsEdited: false,
      expand: false,
      dataSource: [],
      addingNewRecord: false,
      fieldMappingRecordIdToDelete: null,
      postcodeToDelete: null,
      showAddNewFileMappingPanel: false,
      showWarning: false,
      warningMessage: "",
      showSuccess: false,
      successMessage: "",
      bordered: true,
      loading: true,
      pagination,
      size: 'middle',
      title: undefined,
      showHeader,
      footer: undefined,
      rowSelection: {},
    };
    this.columns = [   
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        width: 100,
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <center>
                <EditableContext.Consumer>
                  {form => (
                    <a
                      onClick={() => this.save(form, record.key)}

                    >
                      <i className="icon icon-check-circle-o" />
                    </a>
                  )}
                </EditableContext.Consumer>
                <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                  <a style={{ marginLeft: 10 }}><i className="icon icon-close-circle" /></a>
                </Popconfirm>
              </center>
            </span>
          ) : (
              <span>
                <center>
                  <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
                    <i className="icon icon-edit" />
                  </a>
                  <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.confirmDelete(record.id)}>
                    <i className="icon icon-trash" />
                  </a>
                </center>
              </span>
            );
        },
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 220,
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: '',
        dataIndex: 'logo',
        key: 'logo',
        align: 'center',
        width: 150,
        sorter: (a, b) => { return a.logo.localeCompare(b.logo) },
        render: (text, record) => {
          return <span><img style={{ maxWidth: 70, padding: 5,}} src={text} /></span>
        },
      },
      {
        title: 'Created Date',
        dataIndex: 'createdDate',
        key: 'createdDate',
        align: 'center',
        width: 220,
        sorter: (a, b) => a.createdDate - b.createdDate,
      },
      {
        title: 'Fuel Type',
        dataIndex: 'fuelType',
        key: 'fuelType',
        align: 'center',
        width: 150,
        editable: true,
        sorter: (a, b) => { return a.fuelType.localeCompare(b.fuelType) },
      },
      {
        title: 'Coloumn To Update',
        dataIndex: 'coloumnToUpdate',
        key: 'coloumnToUpdate',
        align: 'center',
        width: 100,
        editable: true,
        sorter: (a, b) => { return a.coloumnToUpdate.localeCompare(b.coloumnToUpdate) },
      },
      {
        title: 'Coloumn Db',
        dataIndex: 'coloumnDb',
        key: 'coloumnDb',
        width: 150,
        editable: true,
        sorter: (a, b) => { return a.coloumnDb.localeCompare(b.coloumnDb) },
      },
      {
        title: 'Coloumn State',
        dataIndex: 'coloumnState',
        key: 'coloumnState',
        width: 150,
        editable: true,
        sorter: (a, b) => { return a.coloumnDb.localeCompare(b.coloumnDb) },
      },
      {
        title: 'Coloumn Postcode',
        dataIndex: 'coloumnPostcode',
        key: 'coloumnPostcode',
        width: 150,
        editable: true,
        sorter: (a, b) => { return a.coloumnPostcode.localeCompare(b.coloumnPostcode) },
      },
      {
        title: 'Coloumn Suburb',
        dataIndex: 'coloumnSuburb',
        key: 'coloumnSuburb',
        width: 150,
        editable: true,
        sorter: (a, b) => { return a.coloumnSuburb.localeCompare(b.coloumnSuburb) },
      },
      {
        title: 'Coloumn Climate Zone',
        dataIndex: 'coloumnClimateZone',
        key: 'coloumnClimateZone',
        width: 150,
        editable: true,
        sorter: (a, b) => { return a.coloumnClimateZone.localeCompare(b.coloumnClimateZone) },
      },
      {
        title: 'Is Active',
        dataIndex: 'isActive',
        key: 'isActive',
        align: 'center',
        width: 150,
        editable: true,
        sorter: (a, b) => { return a.isActive.localeCompare(b.isActive) },
        render: (text, record) => {
          return <Switch disabled={true} checked={text == 1 ? true : false} onChange={{}} />
        },
      },
    ];

  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.dataSource];
      const index = newData.findIndex(item => key === item.key);

      if (index > -1) {
        const item = newData[index];

        const valuesToUpdate = {
          id: item.id,
          fuelType: row.fuelType,
          coloumnToUpdate: row.coloumnToUpdate,
          coloumnDb: row.coloumnDb,
          coloumnState: row.coloumnState,
          coloumnPostcode: row.coloumnPostcode,
          coloumnSuburb: row.coloumnSuburb,
          coloumnClimateZone: row.coloumnClimateZone,
          isActive: row.isActive,
        }

        this.setState({ loading: true });
        this.props.updateRetailerFileHeader(valuesToUpdate);

        this.setState({
          editingKey: '',
          recordsEdited: true
        });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  confirmDelete = (key) => {
    this.setState({ fieldMappingRecordIdToDelete: key });
    this.setState({ showWarning: true });

  };

  onCancelDelete = key => {
    this.setState({ fieldMappingRecordIdToDelete: null });
    this.setState({ showWarning: false });
  };

  handleDelete = () => {
    const deleteInfo = {
      id: this.state.fieldMappingRecordIdToDelete,
    }
    this.props.deleteRetailerFileHeader(deleteInfo);
    this.setState({ showWarning: false });
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showAddNewPostcodePanel: false,
    });
    this.handleReset();
  };

  componentDidMount() {
    this.props.getRetailerList();
    this.props.getRetailerFileHeaderList();
  }

  handleSearch = (e) => {
    // e.preventDefault();
    // this.props.form.validateFields((err, values) => {

    //   const filtersForSearch = {
    //     email: values.txtEmail,
    //     name: values.txtName,
    //     accessLevel: values.drpAccessLevel,
    //   }
    //   this.setState({ isSearching: true });
    //   this.props.getUsers(filtersForSearch);
    // });
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.props.getRetailerList();
    this.props.getRetailerFileHeaderList();
  };

  handleAdd = () => {
    this.setState({
      showAddNewFileMappingPanel: true,
    });
  };

  //onAddCancel

  onAddCancel = () => {
    this.handleReset();
    this.setState({
      showAddNewFileMappingPanel: false,
    });
  };


  handleAddFileMappingDetails = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      const fileMappingDetails = {
        retailerId : values.drpRetailerAdd,
        fuelType : values.drpFuelTypeAdd,
        coloumnToUpdate : values.txtColoumnToUpdateAdd,
        coloumnDb : values.txtColoumnDbAdd,
        coloumnState : values.txtColoumnStateAdd,
        coloumnPostcode : values.txtColoumnPostcodeAdd,
        coloumnSuburb : values.txtColoumnSuburbAdd,
        coloumnClimateZone : values.txtColoumnClimateZoneAdd,
      }

      this.setState({ isAdding: true });
      this.props.addRetailerFileHeader(fileMappingDetails);
    });
  };

  checkColoumnType = (field) => {
    switch (field) {
      case 'isActive': return "statusSelection";
      case 'fuelType': return "fuelTypeSelection";
      default: return "text";
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.retailerFileHeaderList) && !isEmpty(this.props.retailerList)) {
      this.setState({ loading: true });
      this.state.dataSource = [];
      let count = 1;
      nextProps.retailerFileHeaderList.map((retailerFileHeader, key) => {
        let record = {
          key: count.toString(),
          id: retailerFileHeader.id,
          logo : !isEmpty(this.props.retailerList) ? this.props.retailerList.find(data => data.id == retailerFileHeader.retailerId).logoURL : "-",
          createdDate: moment(retailerFileHeader.createdDate).format('YYYY-MM-DD h:mm:ss A'),
          fuelType: !isEmpty(retailerFileHeader.fuelType) ? retailerFileHeader.fuelType : "-",
          coloumnToUpdate: !isEmpty(retailerFileHeader.coloumnToUpdate) ? retailerFileHeader.coloumnToUpdate : "-",
          coloumnDb: !isEmpty(retailerFileHeader.coloumnDb) ? retailerFileHeader.coloumnDb : "-",
          coloumnState: !isEmpty(retailerFileHeader.coloumnState) ? retailerFileHeader.coloumnState : "-",
          coloumnPostcode: !isEmpty(retailerFileHeader.coloumnPostcode) ? retailerFileHeader.coloumnPostcode : "-",
          coloumnSuburb: !isEmpty(retailerFileHeader.coloumnSuburb) ? retailerFileHeader.coloumnSuburb : "-",
          coloumnClimateZone: !isEmpty(retailerFileHeader.coloumnClimateZone) ? retailerFileHeader.coloumnClimateZone : "-",
          isActive: retailerFileHeader.isActive,
        };
        this.state.dataSource.push(record);
        count++;
      });
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }
    else {
      this.setState({ loading: true });
      this.state.dataSource = [];
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }

    if (!isEmpty(nextProps.recordUpdateStatus)) {
      if (nextProps.recordUpdateStatus.status == "Success") {
        this.setState({
          successMessage: nextProps.recordUpdateStatus.message,
          showSuccess: true,
        });
        this.props.getRetailerList();
        this.props.getRetailerFileHeaderList();
        this.setState({
          isAdding: false,
          showAddNewFileMappingPanel: false,
        });
      }
    }
  }


  render() {

    let { dataSource } = this.state;

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          retailerList : this.props.retailerList,
          inputType: this.checkColoumnType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-main-content">
        <ContainerHeader title="Retailer Postcode File Mapping" />
        <Row>
          {/* <Col span={24}>
            <Spin spinning={this.state.isSearching}>
              <Card className="gx-card" title="Search Retailer">
                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleSearch}
                >
                  <Row>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" htmlType="submit">Search</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                          Clear
                    </Button>
                      </center>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Spin>
          </Col> */}
          {this.state.showAddNewFileMappingPanel && <Col span={24}>
          <Spin spinning={this.state.isAdding}>
            <Card className="gx-card" title="Add New File Mapping">
              <Form
                className="ant-advanced-search-form"
                onSubmit={this.handleAddFileMappingDetails}
              >
                <Row>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Retailer">
                        {getFieldDecorator('drpRetailerAdd', {
                          rules: [{ required: true, message: 'Please select a Retailer!' }],
                        })(
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a Retailer"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          >
                             <Option value="-1">Select</Option>
                            {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                return <option key={key} value={retailer.id}>{retailer.retailerName}</option>;
                            })}
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Fuel Type">
                        {getFieldDecorator('drpFuelTypeAdd', {
                          rules: [{ required: true, message: 'Please select a Fuel Type!' }],
                        })(
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a Fuel Type"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          >
                             <Option value="-1">Select</Option>
                             <Option value="ELEC">ELEC</Option>
                             <Option value="GAS">GAS</Option>
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col>   
                  </Row>
                  <Row>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Coloumn To Update" >
                        {getFieldDecorator('txtColoumnToUpdateAdd', {
                          rules: [{ required: true, message: 'Please enter Coloumn to Update!' }],
                        })(
                          <Input style={{ width: '100%' }} placeholder="Coloumn Name to Update" />
                        )}
                      </FormItem>
                    </div>
                  </Col>                  
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Distributor Coloumn" >
                        {getFieldDecorator('txtColoumnDbAdd', {
                          rules: [{ required: true, message: 'Please enter coloumn name of Distributor!' }],
                        })(
                          <Input style={{ width: '100%' }} placeholder="Distributor Coloumn Name" />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="State Coloumn" >
                        {getFieldDecorator('txtColoumnStateAdd', {
                          rules: [{ required: false, message: 'Please enter Coloumn name of State!' }],
                        })(
                          <Input style={{ width: '100%' }} placeholder="State Coloumn Name" />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Postcode Coloumn" >
                        {getFieldDecorator('txtColoumnPostcodeAdd', {
                          rules: [{ required: false, message: 'Please enter Coloumn name of Postcode!' }],
                        })(
                          <Input style={{ width: '100%' }} placeholder="Postcode coloumn Name" />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Suburb Coloumn" >
                        {getFieldDecorator('txtColoumnSuburbAdd', {
                          rules: [{ required: false, message: 'Please enter Coloumn name of Suburb!' }],
                        })(
                          <Input style={{ width: '100%' }} placeholder="Suburb Coloumn Name" />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Climate Zone Coloumn" >
                        {getFieldDecorator('txtColoumnClimateZoneAdd', {
                          rules: [{ required: false, message: 'Please enter Coloumn name of Climate Zone!' }],
                        })(
                          <Input style={{ width: '100%' }} placeholder="Cimate Zone Coloumn Name" />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                </Row>
                <Row>
                  
                <Col span={24} className="gx-text-right">
                    <center>
                      <Button type="primary" htmlType="submit">Add File Mapping Details</Button>
                      <Button style={{ marginLeft: 8 }} onClick={this.onAddCancel}>
                        Cancel
                    </Button>
                    </center>
                  </Col>
                </Row>
              </Form>
            </Card>
            </Spin>
          </Col>
          }
          <Col span={24}>
            <Card className="gx-card" title="File Mapping Details">
              <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add New File Mapping
              </Button>
              <EditableContext.Provider value={this.props.form}>
                <Table
                  components={components}
                  rowClassName="editable-row"
                  className="gx-table-responsive"
                  {...this.state}
                  columns={columns}
                  dataSource={dataSource} />
              </EditableContext.Provider>
            </Card>
          </Col>
        </Row>
        <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
        <SweetAlert show={this.state.showWarning}
          warning
          showCancel
          confirmBtnText="Yes Delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.handleDelete}
          onCancel={this.onCancelDelete}
        ></SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retailerFileHeaderList: state.retailerFileMapping.retailerFileHeaderList,
  retailerList: state.retailers.retailerList,
  recordUpdateStatus: state.users.recordUpdateStatus,
});

const mapDispatchToProps = dispath => ({
  getRetailerFileHeaderList: (data) => dispath(getRetailerFileHeaderList(data)),
  updateRetailerFileHeader: (data) => dispath(updateRetailerFileHeader(data)),
  addRetailerFileHeader: (data) => dispath(addRetailerFileHeader(data)),
  deleteRetailerFileHeader: (data) => dispath(deleteRetailerFileHeader(data)),
  getRetailerList: (data) => dispath(getRetailerList(data)),
});

const WrappedAdvancedSearch = Form.create()(MapPostcodesRetailer);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);