const initialState = {
    retailerList: {},
    recordUpdateStatus: null,
    error: null,
}

export default function (state = initialState, action){
    switch(action.type)
    {
        case 'SAVE_RETAILER_LIST':
            return{
                ...state,
                retailerList: action.retailerList
            };
        case 'SAVE_RECORD_UPDATE_STATUS':
            return {
                ...state,
                recordUpdateStatus: action.recordUpdateStatus
            };
        case 'ERROR':
            return{
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}