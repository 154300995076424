import exportFromJSON from 'export-from-json'

const exportToCSV = (filename, datasource) => {
    const data = datasource;
    
    const fileName = filename;
    const exportType = 'csv'
    
    exportFromJSON({ data, fileName, exportType })
  }
  
  export default exportToCSV;