import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "util/isEmpty";
import { Form, Row, Col } from "antd";
import Widget from "components/Widget";
import { FaBeer, FaTruck, FaAmbulance, FaTruckLoading, FaSimCard, FaGrinStars } from 'react-icons/fa';
import { GoFlame } from 'react-icons/go';
import { TiFlash } from 'react-icons/ti';
import { FcBullish, FcProcess, FcInTransit, FcGraduationCap, FcFlashOn, FcHeadset, FcDepartment, FcHome, FcWiFiLogo, FcHighPriority, FcApproval, FcPlus } from 'react-icons/fc';
import { Bar, BarChart, CartesianGrid, Legend, Line, LabelList, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { GiClockwiseRotation, GiWifiRouter, GiHealthNormal } from 'react-icons/gi';
import { BsWifi } from 'react-icons/bs'
import { RiHomeWifiLine } from 'react-icons/ri'

import Chart from "react-google-charts";
import AustraliaMap from 'react-australia-map';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_australiaLow from "@amcharts/amcharts4-geodata/australiaLow";

import FixupList from "./fixupSales";
import RecentSales from "./recentSales";
import CallStats from "./callStats";

class SaleStats extends Component {

    render() {

        let callData = this.props.callStats;
        let agentCallRank = !isEmpty(callData) ? callData.rank : 0;
        let totalCalls = !isEmpty(callData) ? callData.totalCalls : 0;

        let salesData = this.props.salesData;

        let elecRetailerStats = [];
        let elecDistributorStats = [];
        let elecProductStats = [];

        var gasRetailerStats = [];
        var gasDistributorStats = [];
        let gasProductStats = [];

        salesData.allSales.map((sale, key) => {

            let codeArea = ``;

            try{


                if (sale.FuelType["_text"] !== "Gas") {
                    codeArea = `elecRetailerName`;
                    let elecRetailerName = sale.NewElectricityRetailer["_text"].toUpperCase();
                    if (!isEmpty(elecRetailerName) && (elecRetailerName != "NA") && !(elecRetailerStats.find(data => data.name == elecRetailerName))) {
                        let elecRetailerDetails = {
                            name: elecRetailerName,
                            elec: Object.values(salesData.allSales.filter(({ NMICode, NewElectricityRetailer }) => !isEmpty(NMICode["_text"]) && NMICode["_text"] != 0 && NewElectricityRetailer["_text"].toUpperCase() === elecRetailerName)).length,
                        };

                        elecRetailerStats.push(elecRetailerDetails);
                    }

                    codeArea = `elecProductName`;
                    let elecProductName = sale.ElectricityProduct["_text"].toUpperCase();
                    if (!isEmpty(elecProductName) && (elecProductName != "NA") && !(elecProductStats.find(data => data.name == elecProductName))) {
                        let elecProductDetails = {
                            name: elecProductName,
                            elec: Object.values(salesData.allSales.filter(({ NMICode, ElectricityProduct }) => !isEmpty(NMICode["_text"]) && NMICode["_text"] != 0 && ElectricityProduct["_text"].toUpperCase() === elecProductName)).length,
                        };

                        elecProductStats.push(elecProductDetails);
                    }

                    codeArea = `elecProductName`;
                    let elecDistributorName = sale.ElectricityDistributor["_text"].toUpperCase();
                    if (!isEmpty(elecDistributorName) && (elecDistributorName != "NA") && !(elecDistributorStats.find(data => data.name == elecDistributorName))) {
                        let elecDistributorDetails = {
                            name: elecDistributorName,
                            elec: Object.values(salesData.allSales.filter(({ NMICode, ElectricityDistributor }) => !isEmpty(NMICode["_text"]) && NMICode["_text"] != 0 && ElectricityDistributor["_text"].toUpperCase() === elecDistributorName)).length,
                        };

                        elecDistributorStats.push(elecDistributorDetails);
                    }
                }
                
                if (sale.FuelType["_text"] !== "Elec") {
                    codeArea = `elecProductName`;
                    let gasRetailerName = sale.NewGasRetailer["_text"].toUpperCase();
                    if (!isEmpty(gasRetailerName) && (gasRetailerName != "NA") && !(gasRetailerStats.find(data => data.name == gasRetailerName))) {
                        let gasRetailerDetails = {
                            name: gasRetailerName,
                            gas: Object.values(salesData.allSales.filter(({ MIRNNumber, NewGasRetailer }) => !isEmpty(MIRNNumber["_text"]) && MIRNNumber["_text"] != 0 && NewGasRetailer["_text"].toUpperCase() === gasRetailerName)).length,
                        };

                        gasRetailerStats.push(gasRetailerDetails);
                    }

                    codeArea = `elecProductName`;
                    let gasProductName = sale.GasProduct["_text"].toUpperCase();
                    if (!isEmpty(gasProductName) && (gasProductName != "NA") && !(gasProductStats.find(data => data.name == gasProductName))) {
                        let gasProductDetails = {
                            name: gasProductName,
                            gas: Object.values(salesData.allSales.filter(({ MIRNNumber, GasProduct }) => !isEmpty(MIRNNumber["_text"]) && MIRNNumber["_text"] != 0 && GasProduct["_text"].toUpperCase() === gasProductName)).length,
                        };

                        gasProductStats.push(gasProductDetails);
                    }

                    codeArea = `elecProductName`;
                    let gasDistributorName = sale.GasDistributor["_text"].toUpperCase();
                    if (!isEmpty(gasDistributorName) && (gasDistributorName != "NA") && !(gasDistributorStats.find(data => data.name == gasDistributorName))) {
                        let gasDistributorDetails = {
                            name: gasDistributorName,
                            gas: Object.values(salesData.allSales.filter(({ MIRNNumber, GasDistributor }) => !isEmpty(MIRNNumber["_text"]) && MIRNNumber["_text"] != 0 && GasDistributor["_text"].toUpperCase() === gasDistributorName)).length,
                        };

                        gasDistributorStats.push(gasDistributorDetails);
                    }
                }                
                 
            } catch (err) {
                console.log(
                  `Error getting details for lead id ${sale.Id["_text"]} in code area ${codeArea}`
                );
            }            

        });

        let agentRank = !isEmpty(salesData.rankData) ? salesData.rankData.rank : 0;

        let totalElec = Object.values(salesData.allSales.filter(({ NMICode }) => !isEmpty(NMICode["_text"]) && NMICode["_text"] != 0)).length
        let totalGas = Object.values(salesData.allSales.filter(({ MIRNNumber }) => !isEmpty(MIRNNumber["_text"]) && MIRNNumber["_text"] != 0)).length

        let totalRetention = Object.values(salesData.allSales.filter(({ NMIAcqRet, NMICode }) => !isEmpty(NMICode["_text"]) && NMICode["_text"] != 0 && !isEmpty(NMIAcqRet["_text"]) && NMIAcqRet["_text"] == "Retention")).length + Object.values(salesData.allSales.filter(({ NMIAcqRet, MIRNNumber }) => !isEmpty(MIRNNumber["_text"]) && MIRNNumber["_text"] != 0 && !isEmpty(NMIAcqRet["_text"]) && NMIAcqRet["_text"] == "Retention")).length
        let totalAcquistion = Object.values(salesData.allSales.filter(({ NMIAcqRet, NMICode }) => !isEmpty(NMICode["_text"]) && NMICode["_text"] != 0 && !isEmpty(NMIAcqRet["_text"]) && NMIAcqRet["_text"] == "Acquisition")).length + Object.values(salesData.allSales.filter(({ NMIAcqRet, MIRNNumber }) => !isEmpty(MIRNNumber["_text"]) && MIRNNumber["_text"] != 0 && !isEmpty(NMIAcqRet["_text"]) && NMIAcqRet["_text"] == "Acquisition")).length

        let totalFuels = totalElec + totalGas
        let totalMoveIns = Object.values(salesData.allSales.filter((data) => !isEmpty(data.NMICode["_text"]) && data.NMICode["_text"] != 0 && data["Move-inorTransfer"]["_text"] != "Transfer")).length + Object.values(salesData.allSales.filter((data) => !isEmpty(data.MIRNNumber["_text"]) && data.MIRNNumber["_text"] != 0 && data["Move-inorTransfer"]["_text"] != "Transfer")).length;
        let totalTransfers = Object.values(salesData.allSales.filter((data) => !isEmpty(data.NMICode["_text"]) && data.NMICode["_text"] != 0 && data["Move-inorTransfer"]["_text"] == "Transfer")).length + Object.values(salesData.allSales.filter((data) => !isEmpty(data.MIRNNumber["_text"]) && data.MIRNNumber["_text"] != 0 && data["Move-inorTransfer"]["_text"] == "Transfer")).length;

        let totalResidential = Object.values(salesData.allSales.filter((data) => !isEmpty(data.NMICode["_text"]) && data.NMICode["_text"] != 0 && data.BusinessorResidential["_text"] == "Residential")).length + Object.values(salesData.allSales.filter((data) => !isEmpty(data.MIRNNumber["_text"]) && data.MIRNNumber["_text"] != 0 && data.BusinessorResidential["_text"] == "Residential")).length;;
        let totalBusiness = Object.values(salesData.allSales.filter((data) => !isEmpty(data.NMICode["_text"]) && data.NMICode["_text"] != 0 && data.BusinessorResidential["_text"] == "Business")).length + Object.values(salesData.allSales.filter((data) => !isEmpty(data.MIRNNumber["_text"]) && data.MIRNNumber["_text"] != 0 && data.BusinessorResidential["_text"] == "Business")).length;;

        let totalFixUpCount = Object.values(salesData.fixups).length

        let broadbandData = this.props.broadbandStats;
        let broadbandSalesData = this.props.broadbandSalesStats;
        let mobileSIMSalesData = this.props.mobileSIMSalesStats;
        let healthLeadData = this.props.healthLeadStats;
        let healthSalesData = this.props.healthSalesStats;
        let healthSalesCommissionData = this.props.healthSalesCommissionStats;

        let healthSalesCommission = 0

        for (var hsc = 0; hsc < Object.values(healthSalesCommissionData).length; hsc++) {
            healthSalesCommission = healthSalesCommission + (!isEmpty(healthSalesCommissionData[hsc].GrossAnnualPremium) ? ((parseFloat(healthSalesCommissionData[hsc].GrossAnnualPremium._text) * 1)/100) : 0);
        }

        let totalBroadbandLeads = Object.values(broadbandData).length
        let totalBroadbandSales = Object.values(broadbandSalesData).length
        let totalMobileSIMSales = Object.values(mobileSIMSalesData).length
        let totalHealthLeads = Object.values(healthLeadData).length
        let totalHealthSales = Object.values(healthSalesData).length

        totalFuels = totalFuels + totalBroadbandSales + totalHealthSales;


        return (

            <>
                <Row>
                    <Col md={5} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <FcGraduationCap size={45} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{agentRank}</h1>
                                    <p className="gx-text-grey gx-mb-0">Sales Rank</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                    <Col md={14} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <TiFlash color={"#1493C3"} size={45} /><GoFlame className="gx-mr-3 gx-ml-3" color={"#E59D2D"} size={45} /><GiWifiRouter className="gx-mr-3 gx-ml-3" color={"#26d16d"} size={45} /><GiHealthNormal className="gx-mr-3 gx-ml-3" color={"#d12626"} size={45} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalFuels}</h1>
                                    <p className="gx-text-grey gx-mb-0">Elec + Gas + Broadband + Health</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                    <Col md={5} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <FaGrinStars size={45} color={"#FED83E"} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">${healthSalesCommission.toFixed(2)}</h1>
                                    <p className="gx-text-grey gx-mb-0">Health Commissions</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <TiFlash size={45} color={"#1493C3"} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalElec}</h1>
                                    <p className="gx-text-grey gx-mb-0">Electricity</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                    <Col md={4} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <GoFlame size={45} color={"#E59D2D"} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalGas}</h1>
                                    <p className="gx-text-grey gx-mb-0">Gas</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                    <Col md={4} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <GiWifiRouter size={45} color={"#26d16d"} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalBroadbandSales}</h1>
                                    <p className="gx-text-grey gx-mb-0">Broadband</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                    <Col md={4} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <FaSimCard size={45} color={"#7c23cf"} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalMobileSIMSales}</h1>
                                    <p className="gx-text-grey gx-mb-0">Mobile</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                    <Col md={4} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <GiHealthNormal size={45} color={"#d12626"} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalHealthSales}</h1>
                                    <p className="gx-text-grey gx-mb-0">Health</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>                    
                    <Col md={4} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <FcHighPriority size={45} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalFixUpCount}</h1>
                                    <p className="gx-text-grey gx-mb-0">Fixups</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <FcHome size={45} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalResidential}</h1>
                                    <p className="gx-text-grey gx-mb-0">Residential</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                    <Col md={6} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <FcDepartment size={45} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalBusiness}</h1>
                                    <p className="gx-text-grey gx-mb-0">Business</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                    <Col md={6} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <FcApproval size={45} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalRetention}</h1>
                                    <p className="gx-text-grey gx-mb-0">Retention</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                    <Col md={6} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <FcPlus size={45} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalAcquistion}</h1>
                                    <p className="gx-text-grey gx-mb-0">Acquisition</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                    </Row>
                    <Row>
                    <Col md={6} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <FcInTransit size={45} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalMoveIns}</h1>
                                    <p className="gx-text-grey gx-mb-0">Move-ins (Fuels)</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                    <Col md={6} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <FcProcess size={45} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalTransfers}</h1>
                                    <p className="gx-text-grey gx-mb-0">Transfers (Fuels)</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                    <Col md={6} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <FcWiFiLogo size={45} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalBroadbandLeads}</h1>
                                    <p className="gx-text-grey gx-mb-0">Broadband Leads</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                    <Col md={6} xs={24} className="gx-col-full">
                        <Widget>
                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                <div className="gx-mr-lg-4 gx-mr-3">
                                    <FaAmbulance color={"#d12626"} size={45} />
                                </div>
                                <div className="gx-media-body">
                                    <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalHealthLeads}</h1>
                                    <p className="gx-text-grey gx-mb-0">Health Leads</p>
                                </div>
                            </div>
                        </Widget>
                    </Col>
                </Row>
                {!isEmpty(this.props.salesData.fixups) && <Row>
                    <Col md={24} xs={24} className="gx-col-full">
                        <FixupList fixupSales={this.props.salesData.fixups} type="elecGas"/>
                    </Col>
                </Row>}
                {!isEmpty(this.props.salesData.allSales) && <Row>
                    <Col md={24} xs={24} className="gx-col-full">
                        <RecentSales allSales={this.props.salesData.allSales} type="elecGas" />
                    </Col>
                </Row>}
                {!isEmpty(this.props.salesData.healthSales) && <Row>
                    <Col md={24} xs={24} className="gx-col-full">
                        <RecentSales allSales={this.props.salesData.healthSales} type="health" />
                    </Col>
                </Row>}
                {!isEmpty(this.props.salesData.healthSalesCommissions) && <Row>
                    <Col md={24} xs={24} className="gx-col-full">
                        <RecentSales allSales={this.props.salesData.healthSalesCommissions} type="healthSaleCommissions" />
                    </Col>
                </Row>}
                {!isEmpty(this.props.salesData.broadbandSales) && <Row>
                    <Col md={24} xs={24} className="gx-col-full">
                        <RecentSales allSales={this.props.salesData.broadbandSales} type="broadband" />
                    </Col>
                </Row>}
                {!isEmpty(this.props.salesData.MobileSIMSales) && <Row>
                    <Col md={24} xs={24} className="gx-col-full">
                        <RecentSales allSales={this.props.salesData.MobileSIMSales} type="mobileSIM" />
                    </Col>
                </Row>}

                {!isEmpty(this.props.callStats) && <CallStats callStats={this.props.callStats} />}

                <Row>
                    <Col md={12} xs={24} className="gx-col-full">
                        <Widget title="Electricity fuels by Retailer" >
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={elecRetailerStats}
                                    margin={{ top: 50, right: 0, left: -15, bottom: 0 }}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <CartesianGrid strokeDasharray="1 1" />
                                    <Tooltip cursor={{ fill: 'transparent' }} />
                                    <Legend />
                                    <Bar name={'Electricity'} dataKey="elec" fill="#1493C3">
                                        <LabelList name={'Retailers'} datakey="elec" position="top" offset="10" style={{ fill: "#000000", padding: "10px" }} />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Widget>
                    </Col>
                    <Col md={12} xs={24} className="gx-col-full">
                        <Widget title="Electricity fuels by Product" >
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={elecProductStats}
                                    margin={{ top: 50, right: 0, left: -15, bottom: 0 }}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <CartesianGrid strokeDasharray="1 1" />
                                    <Tooltip cursor={{ fill: 'transparent' }} />
                                    <Legend />
                                    <Bar name={'Electricity'} dataKey="elec" fill="#1493C3">
                                        <LabelList name={'Products'} datakey="elec" position="top" offset="10" style={{ fill: "#000000", padding: "10px" }} />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Widget>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} xs={24} className="gx-col-full">
                        <Widget title="Gas fuels by Retailer">
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={gasRetailerStats}
                                    margin={{ top: 50, right: 0, left: -15, bottom: 0 }}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <CartesianGrid strokeDasharray="1 1" />
                                    <Tooltip cursor={{ fill: 'transparent' }} />
                                    <Legend />
                                    <Bar name={'Gas'} dataKey="gas" fill="#E59D2D">
                                        <LabelList name={'Retailers'} datakey="gas" position="top" offset="10" style={{ fill: "#000000", padding: "10px" }} />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Widget>
                    </Col>
                    <Col md={12} xs={24} className="gx-col-full">
                        <Widget title="Gas fuels by Product">
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={gasProductStats}
                                    margin={{ top: 50, right: 0, left: -15, bottom: 0 }}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <CartesianGrid strokeDasharray="1 1" />
                                    <Tooltip cursor={{ fill: 'transparent' }} />
                                    <Legend />
                                    <Bar name={'Gas'} dataKey="gas" fill="#E59D2D">
                                        <LabelList name={'Products'} datakey="gas" position="top" offset="10" style={{ fill: "#000000", padding: "10px" }} />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Widget>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} xs={24} className="gx-col-full">
                        <Widget title="Electricity fuels by Distributor" >
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={elecDistributorStats}
                                    margin={{ top: 50, right: 0, left: -15, bottom: 0 }}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <CartesianGrid strokeDasharray="1 1" />
                                    <Tooltip cursor={{ fill: 'transparent' }} />
                                    <Legend />
                                    <Bar name={'Electricity'} dataKey="elec" fill="#1493C3">
                                        <LabelList name={'Products'} datakey="elec" position="top" offset="10" style={{ fill: "#000000", padding: "10px" }} />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Widget>
                    </Col>
                    <Col md={12} xs={24} className="gx-col-full">
                        <Widget title="Gas fuels by Distributor">
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={gasDistributorStats}
                                    margin={{ top: 50, right: 0, left: -15, bottom: 0 }}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <CartesianGrid strokeDasharray="1 1" />
                                    <Tooltip cursor={{ fill: 'transparent' }} />
                                    <Legend />
                                    <Bar name={'Gas'} dataKey="gas" fill="#E59D2D">
                                        <LabelList name={'Products'} datakey="gas" position="top" offset="10" style={{ fill: "#000000", padding: "10px" }} />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Widget>
                    </Col>
                </Row>



            </>



        )
    }

}


const mapStateToProps = state => ({
});

const mapDispatchToProps = dispath => ({

});

const WrappedAdvancedSearch = Form.create()(SaleStats);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);