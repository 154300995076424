import React from "react";
import { connect } from "react-redux";
import { Breadcrumb, Divider, Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, InputNumber, DatePicker, Tag, Collapse, Upload, Icon, Tabs } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getRecords, searchRecords, updateSingleRecord, createRecord, deleteRecord, uploadRecords, wipeAndUploadRecords, clearError } from "appRedux/actions/admin/dncRegister";

import { getUsers } from "appRedux/actions/admin/users";
// import "./index.css";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import exportToCSV from 'util/exportToCSV'
import swal from 'sweetalert2';

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const { TextArea } = Input;
const { TabPane } = Tabs;

const moduleName = "DNC Register"
const moduleNameUPPER = "DNCREGISTER"

const showHeader = true;
const pagination = { position: 'bottom' };

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

class DNCRegister extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editingKey: '',
            recordtoEdit: null,
            recordtoDelete: null,
            formFunction: null,
            isAddingUpdating: false,
            isSearching: false,
            recordsEdited: false,
            expand: false,
            dataSource: [],
            addingNewRecord: false,
            showAddEditPanel: false,
            showSearchPanel: true,
            showWarning: false,
            warningMessage: "",
            showSuccess: false,
            successMessage: "",
            showError: false,
            errorMessage: "",
            showWaiting: false,
            showWaitingOkButton: false,
            bordered: true,
            loading: false,
            pagination,
            size: 'middle',
            title: undefined,
            showHeader,
            footer: undefined,
            rowSelection: {},
            singleRecordDetails: {},
            fileList: [],
            fieldState: {
                "id": false,
                "createdDate": false,
                "updatedDate": false,
                "customerTitle": false,
                "customerFirstName": false,
                "customerLastName": false,
                "phoneNumber": false,
                "emailAddress": false,
                "salesBroker": false,
                "leadId": false,
                "comment": false,
                "actionedBy": false,
            }
        };
        this.columns = [
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 150,
                render: (text, record) => {
                    const { editingKey } = this.state;
                    const editable = this.isEditing(record);
                    return (
                        <span>
                            <center>
                                <a onClick={() => this.viewRecord(record)}>
                                    <i className="icon icon-search-new" />
                                </a>
                                <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.editRecord(record)}>
                                    <i className="icon icon-edit" />
                                </a>
                                <a style={{ marginLeft: 10, color: "#52c41a" }} disabled={editingKey !== ''} onClick={() => this.duplicateRecord(record)}>
                                    <i className="icon icon-copy" />
                                </a>
                                <a style={{ marginLeft: 10, color: "#f5222d" }} disabled={editingKey !== ''} onClick={() => this.confirmDeleteRecord(record)}>
                                    <i className="icon icon-close-circle" />
                                </a>
                            </center>
                        </span>
                    );
                },
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 150,
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: 'Created Date',
                dataIndex: 'createdDate',
                key: 'createdDate',
                align: 'center',
                width: 150,
                // sorter: (a, b) => moment(a.createdDate, "YYYY-MM-DDTHH:mm:ss.SSSZ") - moment(b.createdDate, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
                render: (text, record) => {
                    return <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"geekblue"} key={text}>
                        {moment(text).format('YYYY-MM-DD h:mm:ss A')}
                    </Tag>
                }
            },
            {
                title: 'First name',
                dataIndex: 'customerFirstName',
                key: 'customerFirstName',
                align: 'center',
                width: 150,
                editable: true,
                // sorter: (a, b) => { return a.customerFirstName.localeCompare(b.customerFirstName) },
            },
            {
                title: 'Last name',
                dataIndex: 'customerLastName',
                key: 'customerLastName',
                align: 'center',
                width: 150,
                editable: true,
                // sorter: (a, b) => { return a.customerLastName.localeCompare(b.customerLastName) },
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber',
                align: 'center',
                width: 150,
                editable: true,
                // sorter: (a, b) => { return a.phoneNumber.localeCompare(b.phoneNumber) },
            },
            {
                title: 'Email Address',
                dataIndex: 'emailAddress',
                key: 'emailAddress',
                align: 'center',
                width: 150,
                editable: true,
                // sorter: (a, b) => { return a.emailAddress.localeCompare(b.emailAddress) },
            },
            {
                title: 'Sales Agent',
                dataIndex: 'salesBroker',
                key: 'salesBroker',
                align: 'center',
                width: 150,
                editable: true,
                // sorter: (a, b) => { return a.salesBroker.localeCompare(b.salesBroker) },
            },
            {
                title: 'Lead ID',
                dataIndex: 'leadId',
                key: 'leadId',
                align: 'center',
                width: 150,
                editable: true,
                render: (text, record) => {
                    
                    var customLink = `https://lm.prod.velocify.com/Web/LeadAddEdit.aspx?LeadId=${text}`;

                    return <a href={customLink} target={"_blank"}>
                        {text}
                    </a>
                }
            },
            {
                title: 'Action By',
                dataIndex: 'actionedBy',
                key: 'actionedBy',
                align: 'center',
                width: 150,
                editable: true,
                // sorter: (a, b) => { return a.actionedBy.localeCompare(b.actionedBy) },
            },
        ];

    }

    isEditing = record => record.key === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    handleImportFromCSV = (info) => {

        // this.setState({
        //     showWaiting: true,
        // })

        swal.fire({
            title: "Please Wait..",
            text: `System is currently uploading and processing your file - ${info.file.name}`,
            showConfirmButton: this.state.showWaitingOkButton,
        });

        const data = new FormData();

        data.append('file', info.file);

        this.props.uploadRecords(data);

        if (info.file.status !== 'uploading') {
            // console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            // console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            // console.log(`${info.file.name} file upload failed.`);
        }


    };

    handleWipeImportFromCSV = (info) => {

        swal.fire({
            title: "Please Wait..",
            text: `System is currently uploading and processing your file - ${info.file.name}`,
            showConfirmButton: this.state.showWaitingOkButton,
        });

        const data = new FormData();

        data.append('file', info.file);

        this.props.wipeAndUploadRecords(data);

        if (info.file.status !== 'uploading') {
            // console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            // console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            // console.log(`${info.file.name} file upload failed.`);
        }


    };

    handleExportToCSV = (e) => {

        var dataToExport = this.state.dataSource;

        dataToExport.map((record, key) => {
            record.created = moment(record.created, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("YYYY-MM-DD HH:mm:ss");
        });


        const fileName = `${moduleNameUPPER}_` + moment().format("DDMMYYYY_HHmmss");

        exportToCSV(fileName, dataToExport);
    };

    disableAllFields = () => {

        this.setState({
            fieldState: {
                "id": true,
                "createdDate": true,
                "updatedDate": true,
                "customerTitle": true,
                "customerFirstName": true,
                "customerLastName": true,
                "phoneNumber": true,
                "emailAddress": true,
                "salesBroker": true,
                "leadId": true,
                "comment": true,
                "actionedBy": true,
            }
        })
    };

    enableAllFields = () => {

        this.setState({
            fieldState: {
                "id": false,
                "createdDate": false,
                "updatedDate": false,
                "customerTitle": false,
                "customerFirstName": false,
                "customerLastName": false,
                "phoneNumber": false,
                "emailAddress": false,
                "salesBroker": false,
                "leadId": false,
                "comment": false,
                "actionedBy": false,
            }
        })
    };

    duplicateRecord(record) {

        this.enableAllFields();

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    }

    editRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.enableAllFields();

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Update",
            fieldState: {
                ...({ createdDate: true, updatedDate: true,  })
            }
        });
    }

    viewRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "View",
        });
        this.disableAllFields();
    }

    confirmDeleteRecord = (record) => {
        this.setState({ recordtoDelete: record.id });
        this.setState({
            showWarning: true,
            warningMessage: `Are you sure you want delete record ID ${record.id}?`,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    onCancelDelete = () => {
        this.setState({ recordtoDelete: null });
        this.setState({
            showWarning: false,
            warningMessage: ``,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    handleDelete = () => {
        this.state.dataSource = [];

        this.props.deleteRecord(this.state.recordtoDelete);
        // console.log("Delete Record");
    };

    onConfirm = () => {
        this.setState({
            showSuccess: false,
            showError: false,
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleAdd = () => {
        this.handleReset();
        this.enableAllFields();
        this.setState({
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    };

    removeSearchValuesFromPayload = (values) => {

        delete values.drpSalesAgent;
        delete values.drpActionBy;
        delete values.txtPhoneNumber;
        delete values.txtEmailAddress;
        delete values.txtLeadID;
        delete values.emailAddressButton;

        return values;
    };

    handleAddRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (!err) {
                values = this.removeSearchValuesFromPayload(values);

                delete values.createdDate;
                delete values.updatedDate;

                if(!isEmpty(values.emailAddress))
                {
                    var invalidEmailList = ['noemail@gmail.com',
                        'sample@noemail.com.au',
                        '123@gmail.com',
                        'unknown@gmail.com',
                        'no@email.com',
                        'noemail@com.au']

                    if(invalidEmailList.find(data => data.toUpperCase() == values.emailAddress.toUpperCase())){
                        this.setState({
                            errorMessage: "Invalid or fake email address! Please enter correct email address.",
                            showError: true,
                            successMessage: "",
                            showSuccess: false,
                            isAddingUpdating: false,
                            showAddEditPanel: true,
                        });

                        return;
                    }                    
                }

                this.setState({
                    isAddingUpdating: true,
                    showSearchPanel: false,
                });
                this.state.dataSource = [];
                this.props.createRecord(values);
            }


        });
    };

    handleUpdateRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (!err) {

                delete values.createdDate;
                delete values.updatedDate;

                values.id = this.state.singleRecordDetails.id;

                values = this.removeSearchValuesFromPayload(values);

                this.setState({
                    isAddingUpdating: true,
                    showSearchPanel: false,
                });
                this.state.dataSource = [];
                this.props.updateSingleRecord(values);

            }
        });
    };

    onAddUpdateCancel = () => {
        this.setState({
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleClearSearch = () => {
        this.handleReset();
    };

    componentDidMount() {
        this.props.getUsers();
        this.props.getRecords();
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            const filtersForSearch = {
                ...(!isEmpty(values.drpSalesAgent) && values.drpSalesAgent != "-1" && { salesBroker: values.drpSalesAgent }),
                ...(!isEmpty(values.drpActionBy) && values.drpActionBy != "-1" && { actionedBy: values.drpActionBy }),
                ...(!isEmpty(values.txtPhoneNumber) && { phoneNumber: values.txtPhoneNumber }),
                ...(!isEmpty(values.txtEmailAddress) && { emailAddress: values.txtEmailAddress }),
                ...(!isEmpty(values.txtLeadID) && { leadId: values.txtLeadID }),

            }
            this.setState({ isSearching: true });

            if (!isEmpty(filtersForSearch))
                this.props.searchRecords(filtersForSearch);
            else {
                this.props.getRecords();
                this.setState({ isSearching: false });
            }
        });
    };

    handleReset = () => {
        this.props.form.resetFields();

        this.setState({
            singleRecordDetails: null,
            showSearchPanel: true,
            showAddEditPanel: false,
        });
        this.setState({ recordtoEdit: null });

        this.props.getRecords();
    };

    componentWillReceiveProps(nextProps) {
        if (!isEmpty(nextProps.dncRegisterRecords)) {
            this.setState({ loading: true });

            this.state.dataSource = nextProps.dncRegisterRecords;

            this.setState({
                loading: false,
                isSearching: false
            });
        }
        else {
            this.setState({ loading: true });

            this.state.dataSource = [];

            this.setState({
                loading: false,
                isSearching: false
            });
        }

        if (!isEmpty(nextProps.recordUpdateStatus)) {
            if (nextProps.recordUpdateStatus.status == "Success") {
                this.setState({
                    successMessage: nextProps.recordUpdateStatus.message,
                    showSuccess: true,
                    showWarning: false,
                    warningMessage: "",
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                swal.close();
                
                this.props.getRecords();
            }
        }

        if (!isEmpty(nextProps.error)) {
            if (nextProps.error != "") {
                this.setState({
                    errorMessage: nextProps.error,
                    showError: true,
                    successMessage: "",
                    showSuccess: false,
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                
                this.props.clearError();
                this.props.getRecords();
            }
        }
    }


    render() {

        let { dataSource } = this.state;
        const { fileList } = this.state;

        const uploadProps = {
            name: 'file',
            accept: '.csv',
            multiple: false,
            showUploadList: false,
            onChange: (this.handleImportFromCSV),
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        const uploadPropsWipe = {
            name: 'file',
            accept: '.csv',
            multiple: false,
            showUploadList: false,
            onChange: (this.handleWipeImportFromCSV),
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-main-content">
                <ContainerHeader title={`Manage ${moduleName}`} />
                <Row>
                    {this.state.showSearchPanel && <Col span={24}>
                        <Spin spinning={this.state.isSearching}>
                            <Card className="gx-card" title={`Search ${moduleName} Records`} style={{ maxHeight: "550px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                                >
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Sales Agent">
                                                {getFieldDecorator('drpSalesAgent', {
                                                    rules: [{ required: false, message: 'Please select a Sales Agent!' }],
                                                })(
                                                    <Select

                                                    showSearch
                                                    style={{ width: '100%' }}
                                                    placeholder="Select a Sales Agent"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    >
                                                    <Option value="-1">Select</Option>
                                                    {!isEmpty(this.props.userList) && this.props.userList.map((user, key) => {
                                                        return <option key={key} value={user.name}>{user.name}</option>;
                                                    })}
                                                    </Select>
                                                )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Action By">
                                                {getFieldDecorator('drpActionBy', {
                                                    rules: [{ required: false, message: 'Please select a Sales Agent!' }],
                                                })(
                                                    <Select

                                                    showSearch
                                                    style={{ width: '100%' }}
                                                    placeholder="Select a Actioned By User"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    >
                                                    <Option value="-1">Select</Option>
                                                    {!isEmpty(this.props.userList) && this.props.userList.map((user, key) => {
                                                        return <option key={key} value={user.name}>{user.name}</option>;
                                                    })}
                                                    </Select>
                                                )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Phone Number" >
                                                {getFieldDecorator('txtPhoneNumber', {
                                                    rules: [{ required: false, message: 'Enter Phone Number!' }, {type: "string", min:10, message: 'Phone Number should be minimum of 10 numbers'}, {type: "string", max:10, message: 'Phone Number should be maximum of 10 numbers'}],
                                                })(
                                                    <Input type="number"

                                                    style={{ width: '100%' }} placeholder="Enter a Phone Number" />
                                                )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Email Address" >
                                                {getFieldDecorator('txtEmailAddress', {
                                                    rules: [{ required: false, message: 'Enter Email Address!' },{type: "email", message: 'Should be a valid email'}],
                                                })(
                                                    <Input
                                                    type="email"
                                                    style={{ width: '100%' }} placeholder="Enter a Email Address" />
                                                )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Lead ID" >
                                                {getFieldDecorator('txtLeadID', {
                                                    rules: [{ required: false, message: 'Enter Lead ID!' }],
                                                })(
                                                    <Input type="number"

                                                    style={{ width: '100%' }} placeholder="Enter a Lead ID" />
                                                )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            <Button type="primary" onClick={this.handleSearch}>
                                                Search
                      </Button>
                                            <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                                                Clear
                      </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    {this.state.showAddEditPanel && <Col span={24}>
                        <Spin spinning={this.state.isAddingUpdating}>
                            <Card className="gx-card" title={`${this.state.formFunction} ${moduleName} Details`} style={{ maxHeight: "650px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "500px", marginBottom: "20px" }}
                                >
                                     <Tabs type="card" defaultActiveKey={['1']}>
                                        <TabPane tab="Basic Details" key="1" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden"}}>
                                        <Row>
                                                {this.state.formFunction != "Add" &&
                                                    <Col lg={8} md={8} sm={12} xs={24}>
                                                        <div className="gx-form-row0">
                                                            <FormItem {...formItemLayout} label="Created Date" >
                                                                {getFieldDecorator('createdDate', {
                                                                    initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.createdDate, "YYYY-MM-DD HH:mm:ss") : moment(),
                                                                    rules: [{ required: false, message: 'Please select Created Date!' }],
                                                                })(
                                                                    <DatePicker disabled={this.state.fieldState.createdDate} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'} />
                                                                )}
                                                            </FormItem>
                                                        </div>
                                                    </Col>                                                    
                                                }
                                                {this.state.formFunction != "Add" &&
                                                    <Col lg={8} md={8} sm={12} xs={24}>
                                                        <div className="gx-form-row0">
                                                            <FormItem {...formItemLayout} label="Updated Date" >
                                                                {getFieldDecorator('updatedDate', {
                                                                    initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.updatedDate, "YYYY-MM-DD HH:mm:ss") : moment(),
                                                                    rules: [{ required: false, message: 'Please select Created Date!' }],
                                                                })(
                                                                    <DatePicker disabled={this.state.fieldState.updatedDate} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'} />
                                                                )}
                                                            </FormItem>
                                                        </div>
                                                    </Col>                                                    
                                                }
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Title" >
                                                            {getFieldDecorator('customerTitle', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.customerTitle : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.customerTitle} style={{ width: '100%' }} placeholder="Enter Customer Title" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="First Name" >
                                                            {getFieldDecorator('customerFirstName', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.customerFirstName : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.customerFirstName} style={{ width: '100%' }} placeholder="Enter Customer First name" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Last Name" >
                                                            {getFieldDecorator('customerLastName', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.customerLastName : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.customerLastName} style={{ width: '100%' }} placeholder="Enter Customer Last name" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Phone Number" >
                                                            {getFieldDecorator('phoneNumber', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.phoneNumber : "",
                                                                rules: [{ required: true, message: 'Phone Number is required' }, {type: "string", min:10, message: 'Phone Number should be minimum of 10 numbers'}, {type: "string", max:10, message: 'Phone Number should be maximum of 10 numbers'}],
                                                            })(
                                                                <Input type="number" disabled={this.state.fieldState.phoneNumber} style={{ width: '100%' }} placeholder="Enter Phone Number" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Email" >
                                                            {getFieldDecorator('emailAddress', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.emailAddress : "",
                                                                rules: [{ required: false, message: '' },{type: "email", message: 'Should be a valid email'}],
                                                            })(
                                                                <Input type="email" disabled={this.state.fieldState.emailAddress} style={{ width: '100%' }} placeholder="Enter Email" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Lead ID" >
                                                            {getFieldDecorator('leadId', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.leadId : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input type="number" type="number" disabled={this.state.fieldState.leadId} style={{ width: '100%' }} placeholder="Enter Lead ID" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={8} xs={24}>
                                                    <div className="gx-form-row0">
                                                    <FormItem {...formItemLayout} label="Comments" >
                                                        {getFieldDecorator('comment', {
                                                        initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.comment : "",
                                                        rules: [{ required: false, message: 'Please enter Comments!' }],
                                                        })(
                                                        <TextArea disabled={this.state.fieldState.comment} rows={4} style={{ width: '100%' }} placeholder="Please enter Comments" />
                                                        )}
                                                    </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Sales Agent" >
                                                            {getFieldDecorator('salesBroker', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.salesBroker : undefined,
                                                                rules: [{ required: true, message: 'Please select a Sales Agent!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.salesBroker}
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Sales Agent"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                >
                                                                    {!isEmpty(this.props.userList) && this.props.userList.map((user, key) => {
                                                                        return <option key={key} value={user.name}>{user.name}</option>;
                                                                    })}
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Actioned by" >
                                                            {getFieldDecorator('actionedBy', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.actionedBy : undefined,
                                                                rules: [{ required: true, message: 'Please select a Actioned By User!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.actionedBy}
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Actioned By User"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                >
                                                                    {!isEmpty(this.props.userList) && this.props.userList.map((user, key) => {
                                                                        return <option key={key} value={user.name}>{user.name}</option>;
                                                                    })}
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </Tabs>
                                    {/* <Collapse accordion defaultActiveKey={['1']}>
                                        <Panel header="Basic Details" key="1">
                                            
                                        </Panel>
                                    </Collapse> */}

                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            {this.state.formFunction == "Add" &&
                                                <Button type="primary" onClick={this.handleAddRecord}>
                                                    Add
                        </Button>
                                            }
                                            {this.state.formFunction == "Update" &&
                                                <Button type="primary" onClick={this.handleUpdateRecord}>
                                                    Update
                        </Button>
                                            }
                                            <Button style={{ marginLeft: 8 }} onClick={this.onAddUpdateCancel}>
                                                Cancel
                      </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    <Col span={24}>
                        <Card className="gx-card" title={`${moduleName} Details`}>
                            <Button onClick={this.handleAdd} type="primary" className="gx-btn-cyan" style={{ marginBottom: 16 }}>
                                <Icon type="plus" />  Add New {moduleName} Details
                            </Button>
                            <Button onClick={this.handleExportToCSV} type="primary" style={{ marginBottom: 16, marginRight: 0, float: "right" }}>
                                <Icon type="download" /> Export Displayed Results
                            </Button>
                            {/* <Upload {...uploadProps}>
                                <Button type="primary">
                                    <Icon type="upload" /> Add/Update by Uploading a CSV
                                </Button>
                            </Upload>
                            <Upload {...uploadPropsWipe} style={{ marginLeft: "10px" }}>
                                <Button type="danger">
                                    <Icon type="upload" /> Wipe and Add by Uploading a CSV
                                </Button>
                            </Upload> */}
                            <Table
                                className="gx-table-responsive"
                                {...this.state}
                                pagination={{ pageSize: 10 }}
                                columns={columns}
                                dataSource={dataSource} />
                        </Card>
                    </Col>


                </Row>
                <SweetAlert show={this.state.showWaiting}
                    title={'Please wait..'}
                    buttons={false}
                />
                <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showError} error title={this.state.errorMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showWarning}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={this.state.warningMessage}
                    onConfirm={this.handleDelete}
                    onCancel={this.onCancelDelete}
                ></SweetAlert>
            </div>
        );
    }
}

const mapStateToProps = state => ({

    userList: state.users.userList,

    dncRegisterRecords: state.dncRegister.resultList,
    recordUpdateStatus: state.dncRegister.recordUpdateStatus,
    error: state.dncRegister.error,
});

const mapDispatchToProps = dispath => ({

    getUsers: (data) => dispath(getUsers(data)),

    getRecords: () => dispath(getRecords()),
    searchRecords: (data) => dispath(searchRecords(data)),
    updateSingleRecord: (data) => dispath(updateSingleRecord(data)),
    createRecord: (data) => dispath(createRecord(data)),
    deleteRecord: (data) => dispath(deleteRecord(data)),
    uploadRecords: (data) => dispath(uploadRecords(data)),
    wipeAndUploadRecords: (data) => dispath(wipeAndUploadRecords(data)),

    clearError: () => dispath(clearError()),

});

const WrappedAdvancedSearch = Form.create()(DNCRegister);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);