const initialState = {
    postcodeDistributorList: {},
    postcodeDataUploadStatus: null,
    recordUpdateStatus: null,
    error: null,
}

export default function (state = initialState, action){
    switch(action.type)
    {
        case 'SAVE_POSTCODE_DISTRIBUTOR_LIST':
            return{
                ...state,
                postcodeDistributorList: action.postcodeDistributorList
            };
        case 'SAVE_POSTCODE_DATA_UPLOAD_STATUS':
            return{
                ...state,
                postcodeDataUploadStatus: action.postcodeDataUploadStatus
            };
        case 'SAVE_RECORD_UPDATE_STATUS':
            return{
                ...state,
                recordUpdateStatus: action.recordUpdateStatus
            };
        case 'ERROR':
            return{
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}