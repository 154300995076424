import React, { useEffect, useState } from 'react'
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Card, Table, Col, Button, Popconfirm, Tag, Icon, } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";

const EditableContext = React.createContext();
const moment = extendMoment(Moment);

const BroadbandTable = (props) => {
    const editingKey = null
    const [dataSource, setDataSource] = useState([])
    const [isEditing, setIsEditing] = useState(null)
    const [showDelete, setShowDelete] = useState(false)
    const [selectedId, setSelectedId] = useState(null)

    useEffect(() => {
        if (props.data && props.data.result && props.data.result.length > 0) {

            setDataSource(props.data.result)

        } else {
            setDataSource([])
        }
    }, [props.data])

    const checkColoumnType = (field) => {
        switch (field) {
            case 'isActive': return "statusSelection";
            case 'state': return "stateSelection";
            default: return "text";
        }
    }

    const confirmDelete = () => {
        setShowDelete(false)
        props.deleteRecord(selectedId)
    }
    const columnsList = [
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 150,
            render: (text, record) => {
                const editable = setIsEditing(record)
                return editable ? (
                    <span>
                        <center>
                            <EditableContext.Consumer>
                                {form => (
                                    <a
                                        onClick={() => this.save(form, record.key)}

                                    >
                                        <i className="icon icon-check-circle-o" />
                                    </a>
                                )}
                            </EditableContext.Consumer>
                            <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                                <a style={{ marginLeft: 10 }}><i className="icon icon-close-circle" /></a>
                            </Popconfirm>
                        </center>
                    </span>
                ) : (
                    <span>
                        <center>
                            {/* <a onClick={() => this.viewRecord(record)}>
                                <i className="icon icon-search-new" />
                            </a> */}
                            <a style={{ marginLeft: 10 }} onClick={() => props.navigateToUpdate(record)}>
                                <i className="icon icon-edit" />
                            </a>
                            {/* <a style={{ marginLeft: 10, color: "#52c41a" }} disabled={editingKey !== ''} onClick={() => this.duplicateRecord(record)}>
                                <i className="icon icon-copy" />
                            </a> */}
                            <a style={{ marginLeft: 10, color: "#f5222d" }} onClick={() => {
                                setSelectedId(record.id)
                                setShowDelete(true)
                            }}>
                                <i className="icon icon-close-circle" />
                            </a>

                        </center>
                    </span>
                );
            },
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 150,
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Created Date',
            dataIndex: 'created',
            key: 'created',
            align: 'center',
            width: 150,
            sorter: (a, b) => moment(a.created, "YYYY-MM-DDTHH:mm:ss.SSSZ") - moment(b.created, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
            render: (text, record) => {
                return <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"geekblue"} key={text}>
                    {moment(text).format('YYYY-MM-DD h:mm:ss A')}
                </Tag>
            }
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.state.localeCompare(b.state) },
        },
        {
            title: 'Retailer',
            dataIndex: 'retailer',
            key: 'retailer',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.retailer.localeCompare(b.retailer) },
        },
        {
            title: 'RES/SME',
            dataIndex: 'res_sme',
            key: 'res_sme',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.res_sme.localeCompare(b.res_sme) },
        },

        {
            title: 'Product Name',
            dataIndex: 'product_name',
            key: 'product_name',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.product_name.localeCompare(b.product_name) },
        },
        {
            title: 'New Development Fee',
            dataIndex: 'new_development_fee',
            key: 'new_development_fee',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.new_development_fee.localeCompare(b.new_development_fee) },
        },
        {
            title: 'Relocation Fee',
            dataIndex: 'relocation_fee',
            key: 'relocation_fee',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.relocation_fee.localeCompare(b.relocation_fee) },
        },
        // {
        //     title: 'Relocation Fee Description',
        //     dataIndex: 'relocation_fee_description',
        //     key: 'relocation_fee_description',
        //     align: 'center',
        //     width: 350,
        //     editable: true,
        //     sorter: (a, b) => { return a.relocation_fee_description.localeCompare(b.relocation_fee_description) },
        // },

        // Removed because of long content
        {
            title: 'Non Direct Debit Fee',
            dataIndex: 'non_direct_debit_fee',
            key: 'non_direct_debit_fee',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.non_direct_debit_fee.localeCompare(b.non_direct_debit_fee) },
        },
        {
            title: 'Non Direct Debit Fee Description',
            dataIndex: 'non_direct_debit_fee_description',
            key: 'non_direct_debit_fee_description',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.non_direct_debit_fee_description.localeCompare(b.non_direct_debit_fee_description) },
        },
        {
            title: 'Failed Direct Debit Fee',
            dataIndex: 'failed_direct_debit_fee',
            key: 'failed_direct_debit_fee',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.failed_direct_debit_fee.localeCompare(b.failed_direct_debit_fee) },
        },
        // {
        //     title: 'Failed Direct Debit Fee Description',
        //     dataIndex: 'failed_direct_debit_fee_description',
        //     key: 'failed_direct_debit_fee_description',
        //     align: 'center',
        //     width: 350,
        //     editable: true,
        //     sorter: (a, b) => { return a.failed_direct_debit_fee_description.localeCompare(b.failed_direct_debit_fee_description) },
        // },
        // Removed because of long content
        {
            title: 'Paper Bill Fee',
            dataIndex: 'paper_bill_fee',
            key: 'paper_bill_fee',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.paper_bill_fee.localeCompare(b.paper_bill_fee) },
        },
        {
            title: 'Paper Bill Fee Description',
            dataIndex: 'paper_bill_fee_description',
            key: 'paper_bill_fee_description',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.paper_bill_fee_description.localeCompare(b.paper_bill_fee_description) },
        },
        {
            title: 'Late Payment Fee',
            dataIndex: 'late_payment_fee',
            key: 'late_payment_fee',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.late_payment_fee.localeCompare(b.late_payment_fee) },
        },
        {
            title: 'Late Payment Fee Description',
            dataIndex: 'late_payment_fee_description',
            key: 'late_payment_fee_description',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.late_payment_fee_description.localeCompare(b.late_payment_fee_description) },
        },
        {
            title: 'Speed Change Fee',
            dataIndex: 'speed_change_fee',
            key: 'speed_change_fee',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.speed_change_fee.localeCompare(b.speed_change_fee) },
        },
        {
            title: 'Speed Change Fee Description',
            dataIndex: 'speed_change_fee_description',
            key: 'speed_change_fee_description',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.speed_change_fee_description.localeCompare(b.speed_change_fee_description) },
        },

    ];

    const columns = columnsList.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                inputType: checkColoumnType(col.dataIndex),
                dataIndex: col.dataIndex,
                title: col.title,
                editing: setIsEditing(record),
            }),
        };
    });
    return (
        <Col span={24}>
            <Card className="gx-card" title="Add New Broadband Fee">

                <Button onClick={props.navigate} type="primary" className="gx-btn-cyan" style={{ marginBottom: 16 }}>
                    <Icon type="plus" />  Add New Broadband Fee
                </Button>

                <Button onClick={props.handleExportToCSV} type="primary" style={{ marginBottom: 16, marginRight: 0, float: "right" }}>
                    <Icon type="download" /> Export Displayed Results
                </Button>

                <EditableContext.Provider value={props.form}>
                    <Table
                        //components={components}
                        rowClassName="editable-row"
                        className="gx-table-responsive"
                        pagination={{ pageSize: 10 }}
                        columns={columns}
                        dataSource={dataSource} />
                </EditableContext.Provider>
            </Card>
            <SweetAlert show={showDelete}
                warning
                showCancel
                confirmBtnText="Yes Delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={`Are you sure you want delete record ID ${selectedId}`}
                onConfirm={confirmDelete}
                onCancel={() => setShowDelete(false)}
            ></SweetAlert>
        </Col>
    )
}

export default BroadbandTable