const initialState = {
    broadbandPlans: null,
    error: null,
    status: 'idl',
    createNewStatus: 'idl',
    deleteStatus: 'idl',
    selectedPlan: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'SAVE_BROADBAND_PLANS':
            return {
                ...state,
                broadbandPlans: action.planDetails,
                status: 'success'
            };

        case 'NEW_BROADBAND_PLAN':
            return {
                ...state,
                createNewStatus: 'success'
            };

        case 'UPDATE_BROADBAND_PLAN':
            return {
                ...state,
                createNewStatus: 'success'
            }

        case 'REST_BROADBAND_PLANS':
            return {
                ...state,
                broadbandPlan: null,
                status: 'idl',
                createNewStatus: 'idl',
                deleteStatus: 'idl'
            };

        case 'SET_SELECTED_BROADBAND_PLANS':
            console.log(action);
            return {
                ...state,
                selectedPlan: action.planDetails,
            };

        case 'DELETE_BROADBAND_PLANS':
            return {
                ...state,
                deleteStatus: 'success'
            };

        case 'REST_BROADBAND_PLANS_CREATE_STATUS':
            return {
                ...state,
                createNewStatus: 'idl',
                deleteStatus: 'idl',
            };

        case 'ERROR':
            return {
                ...state,
                error: action.error,
                status: 'idl',
                deleteStatus: 'idl',
                createNewStatus: 'idl'
            };
        default:
            return state;
    }
}