import React from "react";
import {Route, Switch} from "react-router-dom";
import ProcessSales from "./ProcessSales";
import ProcessSaleLogs from "./ProcessSaleLogs";
import SalesInProgress from "./SalesInProgress";

const manageSales = ({match}) => (
  <Switch>    
    <Route path={`${match.url}/processSales`} component={ProcessSales}/>
    <Route path={`${match.url}/processSaleLogs`} component={ProcessSaleLogs}/>
    <Route path={`${match.url}/salesInProgress`} component={SalesInProgress}/>
  </Switch>
);

export default manageSales;
