import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';
import isEmpty from "util/isEmpty"



export const saveBroadbandModem = modemDetails => ({
    type: 'SAVE_BROADBAND_MODEM',
    modemDetails,
});

const createModemOptionRecord = modemDetails => ({
    type: 'CREATE_BROADBAND_MODEM',
    modemDetails,
});

export const error = error => ({
    type: 'ERROR',
    error,
});

export const resetBroadbandModem = () => ({
    type: 'REST_BROADBAND_MODEM',
});

const setToDefaultStatus = () => ({
    type: 'REST_BROADBAND_MODEM_MANAGE_STATUS',
})

const saveSelectedModemOption = modemOption => ({
    type: 'SET_SELECTED_BROADBAND_MODEM',
    modemOption,
})

const updateBroadbandModem = modemOption => ({
    type: 'UPDATE_BROADBAND_MODEM',
    modemOption,
})

const deleteBroadbandModemOption = () => ({
    type: 'DELETE_BROADBAND_MODEM',
})

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const clearErrors = (data) => dispath => {

    dispath(error(null));
}

export const clearAll = () => dispath => {

    dispath(resetBroadbandModem());
    dispath(error(null));

}


export const setManageBroadbandModemAPIStatusToDefault = () => dispath => {

    dispath(setToDefaultStatus());
}

export const getAllBroadbandModemOption = () => dispath => {

    axios.get(`${apidetails.API_URL}broadbandModems`)
        .then(res => {

            console.log(res);

            dispath(saveBroadbandModem(res.data));

        })
        .catch(err => {

            dispath(error(!isEmpty(err.response.data.message) ? err.response.data.message : 'ERROR'));
        });

}

// Create New Broadband Modem Option
export const createBroadbandModemOption = (data) => dispath => {

    axios.post(`${apidetails.API_URL}broadbandModems`, qs.stringify(data), config)
        .then(res => {

            dispath(createModemOptionRecord(res.data.result));

        })
        .catch(err => {

            dispath(error(err.response.data.message || 'ERROR'));

        });
}

export const setSelectedBroadbandOption = (data) => dispath => {
    dispath(saveSelectedModemOption(data))
}

export const updateBroadbandModemOption = (data) => dispath => {

    axios.put(`${apidetails.API_URL}broadbandModems`, qs.stringify(data), config)
        .then(res => {

            dispath(updateBroadbandModem(res.data.result));

        })
        .catch(err => {

            dispath(error(err.response.data.message || 'ERROR'));

        });
}

export const deleteBroadbandModemOptionRecord = (id) => dispath => {

    axios.delete(`${apidetails.API_URL}broadbandModems/${id}`)
        .then(res => {

            dispath(deleteBroadbandModemOption());

        })
        .catch(err => {

            dispath(error(err.response.data.message || 'ERROR'));

        });
}

export const searchBroadbandModemRecords = (data) => dispath => {

    axios.post(apidetails.API_URL + `broadbandModems/` + 'search', qs.stringify(data), config)
        .then(res => {

            dispath(saveBroadbandModem(res.data));

        })
        .catch(err => {

            dispath(error(err.response.data.message || 'ERROR'));

        });
}

export const getBroadbandModemRecordById = (id) => dispath => {

    axios.get(apidetails.API_URL + `broadbandModems/${id}`,)
        .then(res => {

            dispath(saveSelectedModemOption(res.data.result[0]));

        })
        .catch(err => {

            dispath(error(err.response.data.message || 'ERROR'));

        });
}