import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';
import refreshIDToken from 'util/refreshIDToken';
import {userSignOut} from "appRedux/actions/Auth";

export const saveRetailerList = retailerList => ({
    type: 'SAVE_RETAILER_LIST',
    retailerList,
});

export const saveRecordUpdateStatus = recordUpdateStatus => ({
    type: 'SAVE_RECORD_UPDATE_STATUS',
    recordUpdateStatus,
});

export const error = error => ({
    type: 'ERROR',
    error,
});

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const getRetailerList = (data) => dispath =>{

    if(data !== undefined)
    {
        // axios.post(apidetails.API_URL + 'elecPostcodeDistributors/search', qs.stringify(data), config)
        // .then(res => {
        //     dispath(savePostcodeDistributorList(res.data.electricityPostcodeDistributors));
        // })
        // .catch(err => {
        //     dispath(error(err.message || 'ERROR'));
        // });
    }
    else
    {
        axios.get(apidetails.API_URL + 'retailers/')
        .then(res => {
            dispath(saveRetailerList(res.data.retailers));
        })
        .catch(err => {
            dispath(error(err.response.data.message || 'ERROR'));
        });
    }    
}

export const updateRetailer = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'retailers/update', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const addRetailer = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'retailers/create', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message  || 'ERROR'));
    });  
}

export const deleteRetailer = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'retailers/delete', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message  || 'ERROR'));
    });  
}