const initialState = {
    validatedAddress: null,
    suburbList: [],
    error: null,
    status: 'idl'
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'SAVE_VALIDATE_ADDRESS':
            return {
                ...state,
                validatedAddress: action.addressDetails,
                status: 'success'
            };

        case 'REQUEST_TO_VALIDATE_ADDRESS':
            return {
                ...state,
                validatedAddress: null,
                status: 'loading'
            };

        case 'REST_ADDRESS':
            return {
                ...state,
                validatedAddress: null,
                status: 'idl',
                suburbList: [],
            };

        case 'GET_SUBURB_LIST_ADDRESS':
            return {
                ...state,
                suburbList: action.suburbList
            };
        case 'ERROR':
            return {
                ...state,
                error: action.error,
                status: 'idl'
            };
        default:
            return state;
    }
}