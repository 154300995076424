import { Tag } from 'antd'
import React from 'react'

import styles from './BroadbandResult.module.less'

const FiltersHeader = (props) => {

    const { leadDetails } = props

    console.log(leadDetails.comparisonDetails.broadbandDetails);

    const capitalize = (string) => {

        return string[0].toUpperCase() + string.slice(1);

    }

    return (
        <div className={styles.container}>

            <h3>
                Filters
            </h3>

            {
                leadDetails && leadDetails.comparisonDetails && leadDetails.comparisonDetails.broadbandDetails && <div>

                    {
                        leadDetails.comparisonDetails.broadbandDetails.importantFeature &&
                        <Tag className={styles.customTag} >Important feature: <strong> {capitalize(leadDetails.comparisonDetails.broadbandDetails.importantFeature)}</strong></Tag>
                    }

                    {
                        leadDetails.comparisonDetails.broadbandDetails.broadbandUsageLevel &&
                        <Tag className={styles.customTag} >Internet Usage: <strong> {capitalize(leadDetails.comparisonDetails.broadbandDetails.broadbandUsageLevel)}</strong></Tag>
                    }

                    {
                        leadDetails.comparisonDetails.broadbandDetails.internetUsers &&
                        <Tag className={styles.customTag}>Internet Users: <strong> {leadDetails.comparisonDetails.broadbandDetails.internetUsers}</strong></Tag>
                    }

                    {
                        leadDetails.leadDetails.contract_type &&
                        <Tag className={styles.customTag}>Contract Type: <strong> {capitalize(leadDetails.leadDetails.contract_type._text)}</strong></Tag>
                    }

                    {
                        leadDetails.leadDetails.download_speed &&
                        <Tag className={styles.customTag}>Download Speed: <strong> {capitalize(leadDetails.leadDetails.download_speed._text)}</strong></Tag>
                    }

                    {
                        leadDetails.leadDetails.bb_discount &&
                        <Tag className={styles.customTag}>Discount: <strong> {capitalize(leadDetails.leadDetails.bb_discount._text)}</strong></Tag>
                    }

                    {
                        leadDetails.leadDetails.activation_fee &&
                        <Tag className={styles.customTag}>Activation Fee: <strong> {capitalize(leadDetails.leadDetails.activation_fee._text)}</strong></Tag>
                    }

                    {
                        leadDetails.leadDetails.home_phone_plan &&
                        <Tag className={styles.customTag}>Home Phone Plan: <strong> {capitalize(leadDetails.leadDetails.home_phone_plan._text)}</strong></Tag>
                    }


                </div>
            }


        </div>
    )
}

export default FiltersHeader