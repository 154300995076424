const initialState = {
    leadDataList :null,
    leadDataUploadStatus : null,
    deleteLeadDataStatus : null,
    leadDataListDetails : null,
    leadDataForNewDataPack : null,
}

export default function (state = initialState, action){
    switch(action.type)
    {
        case 'SAVE_LEAD_DATA_DETAILS_LIST':
            return{
                ...state,
                leadDataList: action.leadDataList
            };
        case 'SAVE_LEAD_DATA_FOR_NEW_DATAPACK':
            return{
                ...state,
                leadDataForNewDataPack: action.leadDataForNewDataPack
            };
        case 'SAVE_LEAD_DATA_UPLOAD_STATUS':
            return{
                ...state,
                leadDataUploadStatus: action.leadDataUploadStatus
            };
        case 'DELETE_LEAD_DATA_STATUS':
            return{
                ...state,
                deleteLeadDataStatus: action.deleteLeadDataStatus
            };
        case 'SAVE_LEAD_DATA_LIST_DETAILS_LIST':
            return{
                ...state,
                leadDataListDetails: action.leadDataListDetails
            };
        default:
            return state;
    }
}