export const statesList = {
    NSW: {
        code: 'NSW',
        title: 'New South Wales'
    },
    NT: {
        code: 'NT',
        title: 'Northern Territory'
    },
    QLD: {
        code: 'QLD',
        title: 'Queensland'
    },
    SA: {
        code: 'SA',
        title: 'South Australia'
    },
    TAS: {
        code: 'TAS',
        title: 'Tasmania'
    },
    VIC: {
        code: 'VIC',
        title: 'Victoria'
    },
    WA: {
        code: 'WA',
        title: 'Western Australia'
    },
    ACT: {
        code: 'ACT',
        title: 'Australian Capital Territory'
    }

}


export default statesList