import React from "react";
import { connect } from "react-redux";
import { Breadcrumb, Divider, Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, InputNumber, DatePicker, Tag, Collapse, Upload, Icon, Alert } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getRecordsByMIRN, getRecordsByAddress, clearAll } from "appRedux/actions/tools/mirnSearch";

import addressMappingData from "util/addressMapping";
// import "./index.css";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import exportToCSV from 'util/exportToCSV'
import swal from 'sweetalert2';

import { geocodeByAddress } from 'react-google-places-autocomplete';

import { MdContentCopy } from 'react-icons/md';

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const { TextArea } = Input;

const pagination = { position: 'bottom' };

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

class InternalMIRNTool extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isSearching: false,
            isManualSearch: true,
            addressBreakdown: {},
            showSearchPanel: true,
            showWarning: false,
            warningMessage: "",
            showSuccess: false,
            successMessage: "",
            showError: false,
            errorMessage: "",
            showWaiting: false,
            showWaitingOkButton: false,
        };

    }



    handleClearSearch = () => {

        this.handleReset();

    };

    handleShowManualSearch = () => {

        this.setState({ isManualSearch: true })

    };

    handleHideManualSearch = () => {

        this.setState({ isManualSearch: false })

    };

    componentDidMount() {

    }


    handleSearchOnKeyDown = (e) => {

        const { value } = e.target;

        setTimeout(() => {
            this.props.clearAll();
            this.props.form.validateFields((err, values) => {

                if (!isEmpty(values.txtFullAddress) && values.txtFullAddress.length == 11 && !isNaN(values.txtFullAddress)) {
                    this.setState({
                        loading: true,
                        isSearching: true,
                    });
                    this.props.getRecordsByMIRN(values.txtFullAddress.substring(0, 10), values.txtFullAddress.substring(11, 10));
                }
                else if (!isEmpty(values.txtFullAddress) && values.txtFullAddress.length >= 20) {
                    this.setState({
                        loading: true,
                        isSearching: true,
                    });
                    geocodeByAddress(values.txtFullAddress)
                        .then(results => {

                            if (!isEmpty(results) && !isEmpty(results[0])) {

                                const place = results[0];

                                let streetName = place.address_components.find(data => data.types[0] === "route").short_name.toUpperCase()
                                let streetNameArray = streetName.split(" ");
                                let finalStreetName = streetName.replace(` ${streetNameArray[streetNameArray.length - 1]}`, "")

                                let addressBreakdown = {
                                    state: place.address_components.find(data => data.types[0] === "administrative_area_level_1").short_name.toUpperCase(),
                                    postcode: place.address_components.find(data => data.types[0] === "postal_code").long_name.toUpperCase(),
                                    suburb: place.address_components.find(data => data.types[0] === "locality").long_name.toUpperCase(),
                                    streetName: finalStreetName.toUpperCase(),
                                    streetType: streetNameArray[streetNameArray.length - 1].toUpperCase(),
                                    streetNumber: !isEmpty(place.address_components.find(data => data.types[0] === "street_number")) ? place.address_components.find(data => data.types[0] === "street_number").short_name.toUpperCase() : '',
                                    // unitType: !isEmpty(place.address_components.find(data => data.types[0] === "subpremise").long_name),
                                    unitNumber: !isEmpty(place.address_components.find(data => data.types[0] === "subpremise")) ? place.address_components.find(data => data.types[0] === "subpremise").short_name.toUpperCase() : '',
                                };

                                this.setState({
                                    addressBreakdown: addressBreakdown
                                })

                                const filtersForSearch = {
                                    ...(!isEmpty(addressBreakdown.unitNumber) && { FLAT_UNIT_NUMBER: addressBreakdown.unitNumber }),
                                    ...(!isEmpty(addressBreakdown.streetNumber) && { HOUSE_NUMBER: addressBreakdown.streetNumber }),
                                    ...(!isEmpty(addressBreakdown.streetName) && { STREET: addressBreakdown.streetName }),
                                    ...(!isEmpty(addressBreakdown.streetType) && { STREETTYPE1: addressBreakdown.streetType }),
                                    ...(!isEmpty(addressBreakdown.postcode) && { POST_CODE: addressBreakdown.postcode }),
                                    ...(!isEmpty(addressBreakdown.suburb) && { SUBURB: addressBreakdown.suburb }),
                                    ...(!isEmpty(addressBreakdown.state) && { STATE: addressBreakdown.state }),

                                }

                                if (!isEmpty(filtersForSearch))
                                    this.props.getRecordsByAddress(filtersForSearch);
                                else {
                                    this.setState({
                                        loading: false,
                                        isSearching: false,
                                    });
                                }
                            }
                        })
                        .catch(error => {
                            this.setState({
                                loading: false,
                                isSearching: false,
                            });
                            console.error(error)
                        });
                }
                else {
                    this.props.clearAll();
                }

            });
        }, 200);




    }

    handleSearch = (e) => {

        this.props.form.validateFields((err, values) => {

            if (!err) {
                if (!isEmpty(values.textMIRN) && values.textMIRN.length == 11) {
                    this.setState({
                        loading: true,
                        isSearching: true,
                    });
                    this.props.getRecordsByMIRN(values.textMIRN.substring(0, 10), values.textMIRN.substring(11, 10));
                }
                else {
                    const filtersForSearch = {
                        ...(!isEmpty(values.txtUnitNumber) && { FLAT_UNIT_NUMBER: values.txtUnitNumber.toUpperCase() }),
                        ...(!isEmpty(values.txtHouseNumber) && { HOUSE_NUMBER: values.txtHouseNumber.toUpperCase() }),
                        ...(!isEmpty(values.txtStreetName) && { STREET: values.txtStreetName.toUpperCase() }),
                        ...(!isEmpty(values.txtStreetType) && { STREETTYPE1: values.txtStreetType.toUpperCase() }),
                        ...(!isEmpty(values.txtPostcode) && { POST_CODE: values.txtPostcode.toUpperCase() }),
                        ...(!isEmpty(values.txtSuburb) && { SUBURB: values.txtSuburb.toUpperCase() }),
                        ...(!isEmpty(values.txtState) && values.txtState != "-1" && { STATE: values.txtState.toUpperCase() }),

                    }

                    if (!isEmpty(filtersForSearch))
                        this.props.getRecordsByAddress(filtersForSearch);
                }
            }

        });

    }


    handleReset = () => {

        this.props.form.resetFields();

        this.setState({
            showSearchPanel: true,
            // isManualSearch: false,
        });

        this.props.clearAll();

    };

    componentWillReceiveProps(nextProps) {

        if (!isEmpty(nextProps.mirnList)) {

            this.setState({
                loading: false,
                isSearching: false,
            });
        }
        else {
            this.setState({
                loading: false,
                isSearching: false,
            });
        }


        if (!isEmpty(nextProps.error)) {
            if (nextProps.error != "") {
                this.setState({
                    errorMessage: nextProps.error,
                    showError: true,
                    successMessage: "",
                    showSuccess: false,
                    showSearchPanel: true,
                });
                this.props.clearAll();
            }
        }
    }

    onConfirm = () => {
        this.setState({
            showSuccess: false,
            showError: false,
            showSearchPanel: true,
            isSearching: false,
        });
    };

    getFullAddress = (address) => {

        let tempFullAddress = "";

        tempFullAddress = tempFullAddress + (!isEmpty(address.FLAT_UNIT_TYPE) ? `${address.FLAT_UNIT_TYPE} ${address.FLAT_UNIT_NUMBER}, ` : ``);
        tempFullAddress = tempFullAddress + (!isEmpty(address.FLOOR_LEVEL_TYPE) ? `${address.FLOOR_LEVEL_TYPE} ${address.FLOOR_LEVEL_NUMBER}, ` : ``);
        tempFullAddress = tempFullAddress + (!isEmpty(address.BUILDING_PROPERTY_NAME) ? `${address.BUILDING_PROPERTY_NAME}, ` : ``);
        tempFullAddress = tempFullAddress + (!isEmpty(address.LOT_NUMBER) ? `LOT ${address.LOT_NUMBER}, ` : ``);
        tempFullAddress = tempFullAddress + (!isEmpty(address.HOUSE_NUMBER) ? `${address.HOUSE_NUMBER}` : ``);
        tempFullAddress = tempFullAddress + (!isEmpty(address.HOUSE_NUMBER_SUFFIX) ? `${address.HOUSE_NUMBER_SUFFIX}` : ``);
        tempFullAddress = tempFullAddress + (!isEmpty(address.STREET) ? `, ${address.STREET} ` : ``);
        tempFullAddress = tempFullAddress + (!isEmpty(address.STREETTYPE1) ? `${address.STREETTYPE1}` : ``);
        tempFullAddress = tempFullAddress + (!isEmpty(address.STREETSUFFIX1) ? ` ${address.STREETSUFFIX1}` : ``);
        tempFullAddress = tempFullAddress + (!isEmpty(address.SUBURB) ? `, ${address.SUBURB} ` : ``);
        tempFullAddress = tempFullAddress + (!isEmpty(address.STATE) ? `${address.STATE} ` : ``);
        tempFullAddress = tempFullAddress + (!isEmpty(address.POST_CODE) ? `${address.POST_CODE}` : ``);

        return tempFullAddress;

    };


    render() {

        const { getFieldDecorator } = this.props.form;
        const { addressBreakdown } = this.state

        const columns = [
            {
                title: 'MIRN (with Check Digit)',
                key: 'MIRN',
                render: (text, record) => (
                    <><span style={{ fontSize: 20 }} className="gx-link">{record.MIRN}{record.CHKDGT}</span><a><MdContentCopy onClick={() => navigator.clipboard.writeText(`${record.MIRN}${record.CHKDGT}`)} className="gx-ml-2" size={18} /></a></>
                )
            },
            {
                title: 'Meter Number',
                dataIndex: 'METER',
                key: 'METER',
                render: (text, record) => (
                    <><span style={{ fontSize: 20 }}>{record.METER}</span><a><MdContentCopy onClick={() => navigator.clipboard.writeText(`${record.METER}`)} className="gx-ml-2" size={18} /></a></>
                )
            },
            {
                title: 'Address',
                key: 'address',
                render: (text, record) => (
                    <><span style={{ fontSize: 20 }}>{this.getFullAddress(record)}</span><a><MdContentCopy onClick={() => navigator.clipboard.writeText(this.getFullAddress(record))} className="gx-ml-2" size={18} /></a></>
                )
            },
        ];

        return (
            <div className="gx-main-content">
                <Row>
                    <Col md={24}>
                        <Card className="gx-card" title={`Search address or MIRN here`} style={{ maxHeight: "550px", overflow: "hidden" }}>
                            <Form
                                className="ant-advanced-search-form"
                                style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                            >
                                {/* {!this.state.isManualSearch &&
                                    <>
                                        <Row>
                                            <Col lg={24} md={24} sm={24} xs={24}>
                                                <Alert
                                                    message="Search Tip 1"
                                                    description="Incase if you face difficulty searching via full address, please click on the `Search address manually` button to search by address breakdown."
                                                    type="info"
                                                />
                                                <Alert
                                                    className="gx-mt-1"
                                                    message="Search Tip 2"
                                                    description="If you are copy pasting an address make sure to delete the last character and re-type for the search to work."
                                                    type="info"
                                                />
                                            </Col>
                                            <Col lg={24} md={24} sm={24} xs={24} className="gx-mt-3">
                                                <FormItem {...formItemLayout2} >
                                                    {getFieldDecorator('txtFullAddress', {
                                                        rules: [{ required: false, message: '' }],
                                                    })(
                                                        <Input onKeyPress={e => this.handleSearchOnKeyDown(e)} onMouseLeave={e => (isEmpty(this.props.mirnList) || e.target.value.length < 1) && this.handleSearchOnKeyDown(e)} onChange={e => (e.target.value.length > 20 || e.target.value.length == 0) && this.handleSearchOnKeyDown(e)} style={{ width: '100%', height: "50px", fontSize: "25px" }} placeholder="Start typing address or enter MIRN" />
                                                    )}
                                                </FormItem>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col span={24} className="gx-text-right">
                                                <center>
                                                    <Button type="danger" onClick={this.handleShowManualSearch}>
                                                        Search address manually
                                                    </Button>
                                                </center>
                                            </Col>
                                        </Row>
                                    </>} */}
                                {this.state.isManualSearch &&
                                    <>
                                        <Row>
                                            <Col lg={8} md={8} sm={12} xs={24}>
                                                <div className="gx-form-row0">
                                                    <FormItem {...formItemLayout2} label="MIRN" >
                                                        {getFieldDecorator('textMIRN', {
                                                            rules: [{ required: false, message: '' },{ max: 11, message: 'MIRN Max length is 11 characters' },{ min: 11, message: 'MIRN Min length is 11 characters' }],
                                                        })(
                                                            <Input style={{ width: '100%' }} placeholder="Enter MIRN" />
                                                        )}
                                                    </FormItem>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={8} md={8} sm={12} xs={24}>
                                                <div className="gx-form-row0">
                                                    <FormItem {...formItemLayout2} label="Unit Number" >
                                                        {getFieldDecorator('txtUnitNumber', {
                                                            initialValue: addressBreakdown.unitNumber,
                                                            rules: [{ required: false, message: '' }],
                                                        })(
                                                            <Input style={{ width: '100%' }} placeholder="Enter Unit Number" />
                                                        )}
                                                    </FormItem>
                                                </div>
                                            </Col>
                                            <Col lg={8} md={8} sm={12} xs={24}>
                                                <div className="gx-form-row0">
                                                    <FormItem {...formItemLayout2} label="House Number" >
                                                        {getFieldDecorator('txtHouseNumber', {
                                                            initialValue: addressBreakdown.streetNumber,
                                                            rules: [{ required: false, message: 'Please enter Street Number' }],
                                                        })(
                                                            <Input style={{ width: '100%' }} placeholder="Enter House Number" />
                                                        )}
                                                    </FormItem>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={8} md={8} sm={12} xs={24}>
                                                <div className="gx-form-row0">
                                                    <FormItem {...formItemLayout2} label="Street Name" >
                                                        {getFieldDecorator('txtStreetName', {
                                                            initialValue: addressBreakdown.streetName,
                                                            rules: [{ required: false, message: 'Please enter Street Name' }],
                                                        })(
                                                            <Input style={{ width: '100%' }} placeholder="Enter Street Name" />
                                                        )}
                                                    </FormItem>
                                                </div>
                                            </Col>
                                            <Col lg={8} md={8} sm={12} xs={24}>
                                                <div className="gx-form-row0">
                                                    <FormItem {...formItemLayout2} label="Street Type">
                                                        {getFieldDecorator('txtStreetType', {
                                                            initialValue: addressBreakdown.streetType,
                                                            rules: [{ required: false, message: 'Please select a Street Type!' }],
                                                        })(
                                                            <Select
                                                                showSearch
                                                                style={{ width: "100%" }}
                                                                placeholder="Select a Street Type"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                            >
                                                                <Option value="-1">Select</Option>
                                                                {!isEmpty(addressMappingData) && addressMappingData.streetType.map((singleType, key) => {
                                                                    return <Select.Option value={singleType.StreetAbbrev}>{singleType.Description}</Select.Option>;
                                                                })}
                                                            </Select>
                                                        )}
                                                    </FormItem>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={8} md={8} sm={12} xs={24}>
                                                <div className="gx-form-row0">
                                                    <FormItem {...formItemLayout2} label="State">
                                                        {getFieldDecorator('txtState', {
                                                            initialValue: addressBreakdown.state,
                                                            rules: [{ required: false, message: 'Please select a State!' }],
                                                        })(
                                                            <Select
                                                                showSearch
                                                                style={{ width: "100%" }}
                                                                placeholder="Select a State"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                            >
                                                                <Option value="-1">Select</Option>
                                                                <Option value="VIC">VIC</Option>
                                                                <Option value="NSW">NSW</Option>
                                                                <Option value="QLD">QLD</Option>
                                                                <Option value="ACT">ACT</Option>
                                                                <Option value="SA">SA</Option>
                                                                <Option value="WA">WA</Option>
                                                            </Select>
                                                        )}
                                                    </FormItem>
                                                </div>
                                            </Col>
                                            <Col lg={8} md={8} sm={12} xs={24}>
                                                <div className="gx-form-row0">
                                                    <FormItem {...formItemLayout2} label="Postcode" >
                                                        {getFieldDecorator('txtPostcode', {
                                                            initialValue: addressBreakdown.postcode,
                                                            rules: [{ required: false, message: 'Please enter Postcode' }],
                                                        })(
                                                            <Input style={{ width: '100%' }} placeholder="Enter Postcode" />
                                                        )}
                                                    </FormItem>
                                                </div>
                                            </Col>
                                            <Col lg={8} md={8} sm={12} xs={24}>
                                                <div className="gx-form-row0">
                                                    <FormItem {...formItemLayout2} label="Suburb" >
                                                        {getFieldDecorator('txtSuburb', {
                                                            initialValue: addressBreakdown.suburb,
                                                            rules: [{ required: false, message: 'Please enter Suburb' }],
                                                        })(
                                                            <Input style={{ width: '100%' }} placeholder="Enter Suburb" />
                                                        )}
                                                    </FormItem>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>}
                            </Form>
                            {this.state.isManualSearch && <Row>
                                <Col md={24} className="gx-text-right">
                                    <center>
                                        <Button type="primary" onClick={this.handleSearch}>
                                            Search address
                                        </Button>
                                        <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                                            Clear
                                        </Button>
                                        {/* <Button onClick={this.handleHideManualSearch}>
                                            Back to full address search
                                        </Button> */}
                                    </center>
                                </Col>
                            </Row>}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    {this.state.showSearchPanel && <Col span={24}>
                        <Spin spinning={this.state.isSearching}>
                            <Card className="gx-card" title={`Search Results`}>
                                {!isEmpty(this.props.mirnList) && <Table className="gx-table-responsive" columns={columns} dataSource={this.props.mirnList} />}
                                {isEmpty(this.props.mirnList) && <h2>No Search Results</h2>}
                            </Card>
                        </Spin>
                    </Col>
                    }
                    {/* {this.state.showSearchPanel && isEmpty(this.props.mirnList) && <Col span={24}>
                        <Spin spinning={this.state.isSearching}>
                            <Card className="gx-card" title={`Search Results`}>
                                <h2>No Search Results</h2>
                            </Card>
                        </Spin>
                    </Col>
                    } */}
                </Row>
                <SweetAlert show={this.state.showWaiting}
                    title={'Please wait..'}
                    buttons={false}
                />
                <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showError} error title={this.state.errorMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showWarning}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={this.state.warningMessage}
                    onConfirm={this.handleDelete}
                    onCancel={this.onCancelDelete}
                ></SweetAlert>
            </div>
        );
    }
}

const mapStateToProps = state => ({

    error: state.mirnSearch.error,

    mirnList: state.mirnSearch.mirnList,

    userDetails: state.auth.userDetails,
});

const mapDispatchToProps = dispath => ({

    getRecordsByMIRN: (mirnNumber, checkDigit) => dispath(getRecordsByMIRN(mirnNumber, checkDigit)),
    getRecordsByAddress: (data) => dispath(getRecordsByAddress(data)),

    clearAll: () => dispath(clearAll()),

});

const WrappedAdvancedSearch = Form.create()(InternalMIRNTool);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);