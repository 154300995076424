export const addressLine1Decode = (line) => {

    // divide comma separated address line to a array
    const lineOneDetails = line.split(',')

    // if details array length is more than zero
    if (lineOneDetails.length > 0) {

        // line details array element one which include line type and line number convert to array
        const lineOneSectionOne = lineOneDetails[0].split(' ')


        // remove unit type from array
        lineOneDetails.shift()


        const remainingAddressDetails = [];

        // add remaining address details to a array and combine it to a one line
        remainingAddressDetails.push(lineOneSectionOne[1])

        if (lineOneDetails.length > 0) {

            remainingAddressDetails.push(lineOneDetails[0])

        }

        const number = remainingAddressDetails.join(',')

        // set the final object details as  type and  number number
        const lineExtendedDetails = {
            type: lineOneSectionOne[0],
            number: number
        }

        return lineExtendedDetails

    }

    return ""

}

export const addressLine2Decode = (line) => {


    if (line) {

        const line2Details = line.split(' ');

        const streetNumber = line2Details.shift();

        const streetType = line2Details.pop()

        const streetName = line2Details.join()

        return {
            streetNumber,
            streetType,
            streetName
        }

    } else {
        return ""
    }

}