import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "util/isEmpty";
import { Form, Row, Col } from "antd";
import Widget from "components/Widget";
import Aux from "util/Auxiliary";

import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { FcBullish } from 'react-icons/fc';

const moment = extendMoment(Moment);
var momentTimezone = require('moment-timezone');

const getStatus = (status) => {

    let statusColor = null;

    switch (status) {
        case "(Ops Status) Retailer Accepted": statusColor = "green"; break;
        case "(Ops Status) Retailer CAF Sent": statusColor = "blue"; break;
        case "(Ops Status) Ready to Process": statusColor = "grey"; break;
        case "(Ops Status) Pending Submission": statusColor = "orange"; break;
        case "(Sales Status) Fixup Sales Team": statusColor = "red"; break;
        default : statusColor = "yellow"; break;
    }


  return <Aux>
    <span className="gx-nonhover">
      <i className={`icon icon-circle gx-fs-sm gx-text-${statusColor}`}/>
    </span>
    <span className={`gx-badge gx-hover gx-mb-0 gx-text-white gx-badge-${statusColor}`}>
      {status}
    </span>
  </Aux>
};

const RecentSaleItem = ({ data, saleType }) => {

    return (
        <div key={"FixupSale" + data.Id["_text"]} className="gx-media gx-task-list-item gx-flex-nowrap">


            {/* <Avatar className="gx-mr-3 gx-size-36" src={avatar}/> */}
            <div className="gx-media-body gx-task-item-content">
                <div className="gx-task-item-content-left">
                    {saleType === "elecGas" && <h3 className="gx-text-truncate gx-task-item-title"><a href={`https://lm.prod.velocify.com/Web/LeadAddEdit.aspx?LeadId=${data.Id["_text"]}`} target="_blank"> {data.Id["_text"]} </a> - {data.LeadType["_text"]} ({data.FuelType["_text"]}) <span className="gx-text-grey gx-fs-sm gx-mb-0">({moment(data.SaleDate_x0026_Time["_text"], "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY hh:mm:ss A")})</span></h3>}
                    {(saleType == "broadband") && <h3 className="gx-text-truncate gx-task-item-title"><a href={`https://lm.prod.velocify.com/Web/LeadAddEdit.aspx?LeadId=${data.Id["_text"]}`} target="_blank"> {data.Id["_text"]} </a> - {data.LeadType["_text"]} (Broadband) - {data.NewBroadbandRetailer["_text"]} <span className="gx-text-grey gx-fs-sm gx-mb-0">({moment(data.SaleCompletionDate._text, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")})</span></h3>}
                    {(saleType == "mobileSIM") && <h3 className="gx-text-truncate gx-task-item-title"><a href={`https://lm.prod.velocify.com/Web/LeadAddEdit.aspx?LeadId=${data.Id["_text"]}`} target="_blank"> {data.Id["_text"]} </a> - {data.LeadType["_text"]} (Mobile SIMs) - {data.NewBroadbandRetailer["_text"]} ({data["NumberofSimSold_x0028_1-20_x0029_"]["_text"]} SIMs) <span className="gx-text-grey gx-fs-sm gx-mb-0">({moment(data["DateReferredtoBroadband.Compare"]["_text"], "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")})</span></h3>}
                    {(saleType == "health") && <h3 className="gx-text-truncate gx-task-item-title"><a href={`https://lm.prod.velocify.com/Web/LeadAddEdit.aspx?LeadId=${data.Id["_text"]}`} target="_blank"> {data.Id["_text"]} </a> - {data.LeadType["_text"]} <span className="gx-text-grey gx-fs-sm gx-mb-0">({moment(data.SaleCompletionDate["_text"], "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")})</span></h3>}
                    {(saleType == "healthSaleCommissions") && <h3 className="gx-text-truncate gx-task-item-title">
                        <a href={`https://lm.prod.velocify.com/Web/LeadAddEdit.aspx?LeadId=${data.Id["_text"]}`} target="_blank"> {data.Id["_text"]} </a> - {data.LeadType["_text"]} ({data.FirstName["_text"]} {data.Surname["_text"]}) [${!isEmpty(data.GrossAnnualPremium) ? ((parseFloat(data.GrossAnnualPremium._text) * 1)/100).toFixed(2) : 0}] <span className="gx-text-grey gx-fs-sm gx-mb-0">({moment(data.SaleCompletionDate["_text"], "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")})</span>
                    </h3>}
                    {/* <p key={data.Id["_text"]} className="gx-text-grey gx-fs-sm gx-mb-0">{data.LastAction["_text"]} - {data.LastActionNote["_text"]} </p> */}
                </div>
                <div className="h3 gx-task-item-content-right gx-pt-0">
                    {getStatus(data.Status["_text"])}
                </div>
            </div>
        </div>

    );
};

class RecentSales extends Component {



    render() {

        let salesData = this.props.allSales;
        let salesType = this.props.type;

        let saleTypeHeader = ''

        switch(salesType) {
            case "elecGas" : saleTypeHeader = "Recent Electricity and Gas Sales"; break;
            case "health" : saleTypeHeader = "Health Sales"; break;
            case "healthSaleCommissions" : saleTypeHeader = "Health Sale Commissions"; break;
            case "broadband" : saleTypeHeader = "Recent Broadband Sales"; break;
            case "mobileSIM" : saleTypeHeader = "Recent Mobile SIM Sales"; break;
        }

        try {
            salesData.sort((a, b) => ((moment(a.SaleDate_x0026_Time["_text"], "YYYY-MM-DDTHH:mm:ss")) < (moment(b.SaleDate_x0026_Time["_text"], "YYYY-MM-DDTHH:mm:ss"))) ? 1 : -1)
        }
        catch (err) { }

        return (
            <Widget title={
                <h2 className="h2 gx-text-capitalize gx-mb-0 gx-text-green">
                    <FcBullish size={18} /> {" "}{saleTypeHeader}<br/><p className="gx-text-grey gx-fs-sm gx-mb-0">(Hover over dot to see status)</p></h2>
            } styleName="gx-card-ticketlist"
            //       extra={<h5 className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
            //         See all tickets <i
            //         className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle"/>
            // </h5>}
            >
                {/* style={{height: "400px", overflowY: "scroll"}} */}
                <Row >
                {
                    salesData.map((sale, index) => <Col md={24} xs={24}><RecentSaleItem key={index} data={sale} saleType={salesType} /></Col>)
                }
                </Row>
                {/* <div className="gx-task-list-item gx-d-block gx-d-sm-none"><h5
          className="gx-text-primary gx-mb-0 gx-pointer">See all tickets <i
          className="icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle"/></h5>
        </div> */}
            </Widget>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispath => ({

});

const WrappedAdvancedSearch = Form.create()(RecentSales);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);
