import React from "react";
import {Route, Switch} from "react-router-dom";
import ViewPostCodeDistributorMap from "./ViewPostCodeDistributorMap";
import UploadRetailerPostcodeFile from "./UploadRetailerPostcodeFile";
import MapPostcodesRetailer from "./MapPostcodesRetailer";
import DistributorTranslation from "./DistributorTranslation";

const PostCodeMapping = ({match}) => (
  <Switch>
    <Route path={`${match.url}/viewPostCodeDistributorMap`} component={ViewPostCodeDistributorMap}/>
    <Route path={`${match.url}/uploadRetailerPostcodeFile`} component={UploadRetailerPostcodeFile}/>
    <Route path={`${match.url}/mapPostcodesRetailer`} component={MapPostcodesRetailer}/>
    <Route path={`${match.url}/distributorTranslation`} component={DistributorTranslation}/>
  </Switch>
);

export default PostCodeMapping;
