import React, { Component } from "react";
import { connect } from "react-redux";

import { Spin, Card, Table, Col, Form, Row, Select, DatePicker, Carousel, Skeleton } from "antd";
import { Bar, BarChart, CartesianGrid, Legend, Line, LabelList, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import About from "./profile/About/index";
import Biography from "./profile/Biography/index";
import Events from "./profile/Events/index";
import Contact from "./profile/Contact/index";

// import {friendList} from './data'
// import {photoList} from "../Wall/data";
// import Friends from "../../../components/profile/Friends/index";
// import Photos from "../../../components/profile/Photos/index";
import Auxiliary from "../../../util/Auxiliary";
import ProfileHeader from "./profileHeader";

import SaleStats from "./components/saleStats"
import Widget from "components/Widget";

import isEmpty from "util/isEmpty";

import { getAgentSummary } from "appRedux/actions/sales/salesInformation";


class AgentDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetchingData: false,
            startDate: null,
            endDate: null,
            agenSummarytData: [],
        };

    }

    componentDidMount() {

        const { userDetails, accessLevelDetails } = this.props;

        if (!isEmpty(userDetails) && !isEmpty(accessLevelDetails) && accessLevelDetails[0].id == 12) {
            this.props.getAgentSummary(this.props.userDetails.name);
            // this.intervalID = setInterval(this.props.getAgentSummary.bind(this, this.props.userDetails.name), 60000);
        }



    }

    componentWillUnmount() {

        const { userDetails } = this.props;

        if (userDetails.accessLevel == 12) {
            clearInterval(this.intervalID);
        }

    }

    componentWillReceiveProps(nextProps) {

        if (!isEmpty(nextProps.singleAgentSummaryData)) {
            this.setState({
                agenSummarytData: nextProps.singleAgentSummaryData,
            });
        }

        if (!isEmpty(nextProps.fetchStatus)) {
            if (nextProps.fetchStatus == "Fetching") {
                this.setState({
                    isFetchingData: true,
                    agenSummarytData: [],
                });
            }
        }
        else {
            this.setState({
                isFetchingData: false,
            });
        }

    }

    render() {

        const { userDetails, accessLevelDetails, toolsUserList } = this.props;

        const { isFetchingData, agenSummarytData } = this.state;

        return (
            <Auxiliary>
                <ProfileHeader />

                {!isFetchingData && isEmpty(agenSummarytData) && isEmpty(toolsUserList) && !isEmpty(accessLevelDetails) && accessLevelDetails[0].id != 12 && <div className="gx-profile-content">
                    <Row>
                        <Col md={24} sm={24} xs={24}>
                            <Widget>
                                <center><h2>Loading Users..</h2></center>
                            </Widget>
                        </Col>
                    </Row>
                </div>}

                {!isFetchingData && isEmpty(agenSummarytData) && !isEmpty(toolsUserList) && !isEmpty(accessLevelDetails) && accessLevelDetails[0].id != 12 && <div className="gx-profile-content">
                    <Row>
                        <Col md={24} sm={24} xs={24}>
                            <Widget>
                                <center><h2>Please select agent name and date to populate results</h2></center>
                            </Widget>
                        </Col>
                    </Row>
                </div>}

                {isFetchingData && <div className="gx-profile-content">
                    <Row>
                        <Col md={24} sm={24} xs={24}>
                            <Widget>
                                <center><Spin size="large" spinning={true}></Spin><h2>{isEmpty(agenSummarytData) ? <>Loading Data...<br /><small>If fetching data for a month please allow upto 2 minutes</small></> : "Refreshing Data..."}</h2></center>
                            </Widget>
                        </Col>
                        {isEmpty(agenSummarytData) && <Col md={24} sm={24} xs={24}>
                            <Row>
                                <Col md={6} sm={24} xs={24}>
                                    <Widget>
                                        <Skeleton active />
                                    </Widget>
                                </Col>
                                <Col md={6} sm={24} xs={24}>
                                    <Widget>
                                        <Skeleton active />
                                    </Widget>
                                </Col>
                                <Col md={6} sm={24} xs={24}>
                                    <Widget>
                                        <Skeleton active />
                                    </Widget>
                                </Col>
                                <Col md={6} sm={24} xs={24}>
                                    <Widget>
                                        <Skeleton active />
                                    </Widget>
                                </Col>
                            </Row>
                        </Col>}
                        {isEmpty(agenSummarytData) && <Col md={24} sm={24} xs={24}>
                            <Widget>
                                <Skeleton active />
                            </Widget>
                        </Col>}
                    </Row>
                </div>}

                {!isEmpty(agenSummarytData) && <div className="gx-profile-content">
                    <Row>
                        <Col md={24} sm={24} xs={24}>
                            <SaleStats salesData={agenSummarytData.saleData} callStats={agenSummarytData.callStats} broadbandStats={agenSummarytData.broadbandData} broadbandSalesStats={agenSummarytData.broadbandSalesData} mobileSIMSalesStats={agenSummarytData.mobileSIMSalesData} healthSalesStats={agenSummarytData.healthSalesData} healthSalesCommissionStats={agenSummarytData.healthSalesCommissionData} healthLeadStats={agenSummarytData.healthLeadData}/>
                        </Col>
                    </Row>
                </div>}
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => ({
    singleAgentSummaryData: state.salesinformation.singleAgentSummaryData,
    userDetails: state.auth.userDetails,
    accessLevelDetails: state.auth.accessLevelDetails,

    fetchStatus: state.salesinformation.fetchStatus,

    toolsUserList: state.users.toolsUserList,
});

const mapDispatchToProps = dispath => ({
    getAgentSummary: (data) => dispath(getAgentSummary(data)),
});

const WrappedAdvancedSearch = Form.create()(AgentDashboard);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);


