import React from "react";
import { connect } from "react-redux";
import { Spin, Button, Card, Col, Form, Icon, Input, Row, Upload, Tooltip, Select, Alert, Divider, DatePicker } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";
import { getUsers } from "appRedux/actions/admin/users";
import exportToCSV from 'util/exportToCSV'
import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import * as apidetails from 'util/api';


import { uploadCsvFile } from "appRedux/actions/admin/rateTool";
import { getRetailerList } from "appRedux/actions/admin/retailers";


let data = [];
const moment = extendMoment(Moment);
const FormItem = Form.Item;
const Dragger = Upload.Dragger;
const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const formItemLayout2 = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    style: { textAlign: "left" },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};


class RateToolUpdates extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      expand: false,
      fileList: [],
      showSuccess: false,
      successMessage: "",
      showError: false,
      errorMessage: "",
      uploading: false,
      showUploadPanel: false,
      selectedRetailer: "-1",
      selectedFileCategory: "-1",
      selectedFuelType: "-1",
    };

  }

  handleReset = (e) => {

    window.location.reload(true);

    // try{
    //   e.preventDefault();
    //   this.setState({
    //     selectedRetailer :"-1",
    //     selectedFileCategory :"-1",
    //     selectedFuelType : "-1",
    //     fileList: [],
    //   });
    // }
    // catch(err){

    // }

  }

  handleRetailerChange = (value) => {
    this.props.form.resetFields();
    this.setState({
      selectedRetailer: value,
      selectedFileCategory: "-1",
      selectedFuelType: "-1",
    });

    setTimeout(() => {
      this.showUploadPanel(value, this.state.selectedFileCategory, this.state.selectedFuelType);
    }, 200);
  }


  handleFileCategoryChange = (value) => {
    this.setState({ selectedFileCategory: value });
    setTimeout(() => {
      this.showUploadPanel(this.state.selectedRetailer, value, this.state.selectedFuelType);
    }, 200);
  }

  handleFuelTypeChange = (value) => {
    this.setState({
      selectedFuelType: value,
    });
    setTimeout(() => {
      this.showUploadPanel(this.state.selectedRetailer, this.state.selectedFileCategory, value);
    }, 200);
  }

  showUploadPanel = (retailer, fileCategory, fuelType) => {
    if (retailer != "-1" && fileCategory != "-1" && fuelType != "-1") {
      this.setState({ showUploadPanel: true });
    }
    else {
      this.setState({ showUploadPanel: false });
    }
  }


  uploadFile = (retrievedFileData) => {

    this.setState({
      uploading: true,
    });

    const uploadInfo = {
      retailerName: (this.props.retailerList.find(data => data.id == this.state.selectedRetailer)).systemName,
      fileCategoryType: this.state.selectedFileCategory,
      fuelType: this.state.selectedFuelType,
      userID: (this.props.userList.find(data => data.email.toLowerCase() == this.props.userDetails.email.toLowerCase())).id,
    }

    this.props.uploadCsvFile(uploadInfo, retrievedFileData.file);
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showError: false,
    });
    this.handleReset();
  };

  componentDidMount() {
    this.props.getRetailerList();
    this.props.getUsers();
  }

  handleExportToCSV = (dataArray) => {

    var dataToExport = dataArray;

    const fileName = `Test_` + moment().format("DDMMYYYY_HHmmss");

    exportToCSV(fileName, dataToExport);
  };

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.csvData) && this.state.uploading == true) {
      //alert('Data Received!')

      //this.handleExportToCSV(nextProps.csvData)
      window.open(`${apidetails.API_URL}rateToolExport/getExportFile/${nextProps.csvData}`);
      //fetch(`${apidetails.API_URL}rateToolExport/getExportFile/${nextProps.csvData}`)


      setTimeout(() => {
        this.setState({
          uploading: false,
        });
        this.handleReset();
      }, 2000);

    }

    if (!isEmpty(nextProps.error)) {
      this.setState({
        errorMessage: nextProps.error,
        showError: true,
        uploading: false,
      });
    }

  }

  render() {

    const { getFieldDecorator } = this.props.form;
    const { fileList } = this.state;

    const draggerProps = {
      name: 'file',
      multiple: false,
      onChange: (this.uploadFile),
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    return (
      <div className="gx-main-content">
        <ContainerHeader title="Rate tool Export" />
        <Row>

          <Col span={24}>
            <Spin spinning={this.state.uploading}>
              <Card className="gx-card rate-tool" title="Upload CSV file here to convert it to EW format">

                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleSearch}
                >
                  <Row>
                    <Col lg={8} md={8} sm={24} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout2} label="Retailer">
                          {getFieldDecorator('retailer', {
                            initialValue: this.state.selectedRetailer,
                            rules: [{ required: true, message: 'Please select a Retailer' }],
                            onChange: (e) => this.handleRetailerChange(e, 'retailer')
                          })(
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select a Retailer"
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                              <Option value="-1">Select a Retailer</Option>
                              {/* <Option value="9">Momentum Energy</Option> */}
                              {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                return retailer.rateToolAutomation == "Yes" ? <option key={key} value={retailer.id}>{retailer.retailerName}</option> : null;
                              })}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col lg={8} md={8} sm={24} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout2} label="File Category">
                          {getFieldDecorator('fileCategory', {
                            initialValue: this.state.selectedFileCategory,
                            rules: [{ required: true, message: 'Please select File Category' }],
                            onChange: (e) => this.handleFileCategoryChange(e, 'fileCategory')
                          })(
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select File Category"
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                              <Option value="-1">Select File Category</Option>
                              {/* <Option value="RATES">RATES</Option>
                              <Option value="BPID">BPID</Option>
                              <Option value="DMOVDO">DMO/VDO</Option> */}
                              {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {

                                let availableOptions = null;

                                if (this.state.selectedRetailer == retailer.id) {
                                  availableOptions = !isEmpty(retailer.rateToolAutomationSettings) && retailer.rateToolAutomationSettings.split("/").map((rateToolAutomationSetting, key) => {
                                    if (rateToolAutomationSetting == "RATES") {
                                      return <Option value="RATES">RATES</Option>
                                    }
                                    else if (rateToolAutomationSetting == "BPID") {
                                      return <Option value="BPID">BPID</Option>
                                    }
                                    else if (rateToolAutomationSetting == "DMOVDO") {
                                      return <Option value="DMOVDO">DMO/VDO</Option>
                                    }
                                  })
                                }

                                return this.state.selectedRetailer == retailer.id &&
                                  retailer.rateToolAutomation == "Yes" ? availableOptions : null;
                              })}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>


                    <Col lg={8} md={8} sm={24} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout2} label="Fuel Type">
                          {getFieldDecorator('fuelType', {
                            initialValue: this.state.selectedFuelType,
                            rules: [{ required: true, message: 'Please select Fuel Type' }],
                            onChange: (e) => this.handleFuelTypeChange(e, 'fuelType')
                          })(
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select Fuel Type"
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                              <Option value="-1">Select Fuel Type</Option>
                              {/* <Option value="ELECTRICTY">ELECTRICTY</Option>
                              <Option value="GAS">GAS</Option> */}
                              {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {

                                let availableOptions = null;

                                if (this.state.selectedRetailer == retailer.id) {
                                  availableOptions = !isEmpty(retailer.rateToolAutomationSettings) && retailer.rateToolAutomationSettings.split("/").map((rateToolAutomationSetting, key) => {
                                    if (rateToolAutomationSetting == "ELECTRICTY") {
                                      return <Option value="ELECTRICTY">ELECTRICTY</Option>
                                    }
                                    else if (rateToolAutomationSetting == "GAS") {
                                      return <Option value="GAS">GAS</Option>
                                    }
                                  })
                                }

                                return this.state.selectedRetailer == retailer.id &&
                                  retailer.rateToolAutomation == "Yes" ? availableOptions : null;
                              })}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>


                  </Row>
                  <hr />

                  <Row className="gx-text-right">

                    <Col span={6} push={9}  >
                      <Button type="primary" size={"large"} block onClick={this.handleReset}>
                        Reset
                      </Button>
                    </Col>

                  </Row>
                </Form>
              </Card>
              {this.state.showUploadPanel &&
                <React.Fragment>
                  <Alert message="Please make sure to upload only .csv files." type="warning" />
                  <Divider />
                </React.Fragment>
              }
              {this.state.showUploadPanel && <Card className="gx-card" title="Drag and Drop CSV File here">
                <Dragger {...draggerProps}>
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">Click or drag csv file to this area to upload</p>
                  <p className="ant-upload-hint">Support for single file uploads.</p>
                </Dragger>
              </Card>
              }

            </Spin>
          </Col>
        </Row>
        <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
        <SweetAlert show={this.state.showError} danger title={this.state.errorMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
      </div>
    );
  }

}

const mapStateToProps = state => ({
  postcodeDataUploadStatus: state.postcodemap.postcodeDataUploadStatus,
  error: state.postcodemap.error,
  retailerList: state.retailers.retailerList,

  userDetails: state.auth.userDetails,
  userList: state.users.userList,

  csvData: state.rateTool.csvData,
});

const mapDispatchToProps = dispath => ({
  uploadCsvFile: (info, file) => dispath(uploadCsvFile(info, file)),
  getRetailerList: (info, file) => dispath(getRetailerList(info, file)),
  getUsers: (data) => dispath(getUsers(data)),
});

const WrappedAdvancedSearch = Form.create({
  onValuesChange(_, values) {
  },
})(RateToolUpdates);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);
