const initialState = {
    salesToProcess: {},
    orderUpdates: {},
    processSaleLogs: {},
    salesInProgress: {},
    retailerCallbackLogs: {},
    processSaleBatchDetails: {},
    recordUpdateStatus: null,
    error: null,
    alintaTokenResponse: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'SAVE_SALES_TO_PROCESS':
            return {
                ...state,
                salesToProcess: action.salesToProcess
            };
        case 'SAVE_ORDER_UPDATES':
            return {
                ...state,
                orderUpdates: action.orderUpdates
            };
        case 'SAVE_RECORD_UPDATE_STATUS':
            return {
                ...state,
                recordUpdateStatus: action.recordUpdateStatus
            };
        case 'SAVE_PROCESS_LOGS':
            return {
                ...state,
                processSaleLogs: action.processSaleLogs
            };
        case 'SAVE_SALES_INPROGRESS':
            return {
                ...state,
                salesInProgress: action.salesInProgress
            };
        case 'SAVE_PROCESS_SALE_BATCH_DETAILS':
            return {
                ...state,
                processSaleBatchDetails: action.processSaleBatchDetails
            };
        case 'SAVE_RETAILER_CALLBACK_LOGS':
            return {
                ...state,
                retailerCallbackLogs: action.retailerCallbackLogs
            };
        case 'ERROR':
            return {
                ...state,
                error: action.error
            };
        case 'RESET_ALINTA_TOKEN':
            return {
                ...state,
                alintaTokenResponse: action.alintaTokenResponse
            };
        default:
            return state;
    }
}