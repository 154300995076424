import React from 'react'


import { Spin, Card, Col, Form, Input, Row, Select, Button, Radio } from "antd";
import TextArea from 'antd/lib/input/TextArea';



const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const { Option } = Select;


const GeneralDetails = (props) => {

    const handleSubmit = (e) => {

        e.preventDefault()
        
        props.next()

    }

    const { getFieldDecorator } = props.form

    return (
        <Col span={24}>

            <Card className="gx-card" title={`Broadband Plans General Details`} style={{ maxHeight: "640px", overflow: "hidden" }}>

                <Form
                    name="basic"
                    labelCol={{
                        xs: { span: 24 },
                        sm: { span: 4 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 20 },
                    }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <Row>
                        <Col sm={8} xs={24}>

                            <Form.Item {...formItemLayout2} label="Retailer" hasFeedback>
                                {getFieldDecorator('retailer', {
                                    rules: [{ required: true, message: 'Please select Retailer' }],
                                })(
                                    <Select placeholder="Please select a country">
                                        <Option value="china">More</Option>
                                        <Option value="usa">Tangerine</Option>
                                    </Select>,
                                )}
                            </Form.Item>

                        </Col>

                        <Col sm={8} xs={24}>

                            <Form.Item {...formItemLayout2} label="Product Name" hasFeedback>
                                {getFieldDecorator('productName', {
                                    rules: [{ required: true, message: 'Please select Product Name' }],
                                })(
                                    <Select placeholder="Please select Product Name">
                                        <Option value="product1">Product 1</Option>
                                        <Option value="product2">Product 2</Option>
                                    </Select>,
                                )}
                            </Form.Item>

                        </Col>

                        <Col sm={8} xs={24}>

                            <Form.Item {...formItemLayout2} label="States" hasFeedback mode="multiple">
                                {getFieldDecorator('states', {
                                    rules: [{ required: true, message: 'Please select States' }],
                                })(
                                    <Select placeholder="Please select States">
                                        <Option value="VIC">Victoria</Option>
                                        <Option value="NSW">New South Wales</Option>
                                    </Select>,
                                )}
                            </Form.Item>

                        </Col>


                        <Col sm={8} xs={24}>

                            <Form.Item {...formItemLayout2} label="Res/sme" hasFeedback mode="multiple">
                                {getFieldDecorator('res_sme', {
                                    rules: [{ required: true, message: 'Please select States' }],
                                })(
                                    <Radio.Group>
                                        <Radio value="a">RES</Radio>
                                        <Radio value="b">SME</Radio>
                                    </Radio.Group>,
                                )}
                            </Form.Item>

                        </Col>

                        <Col sm={8} xs={24}>

                            <Form.Item {...formItemLayout2} label="Timed Call Connection Fee">
                                {getFieldDecorator('timed_call_connection_fee', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Timed Call Connection Fee',
                                        },
                                    ],
                                })(<Input addonBefore="$" />)}

                            </Form.Item>

                        </Col>

                        <Col sm={8} xs={24}>

                            <Form.Item {...formItemLayout2} label="Standard Activation Fee">
                                {getFieldDecorator('standard_activation_fee', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Standard Activation Fee',
                                        },
                                    ],
                                })(<Input addonBefore="$" />)}

                            </Form.Item>

                        </Col>

                        <Col sm={8} xs={24}>

                            <Form.Item {...formItemLayout2} label="Upload Speed">
                                {getFieldDecorator('uploadSpeed', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Upload Speed',
                                        },
                                    ],
                                })(<Input addonBefore="Mbps" />)}

                            </Form.Item>

                        </Col>

                        <Col sm={8} xs={24}>

                            <Form.Item {...formItemLayout2} label="Download Speed">
                                {getFieldDecorator('downloadSpeed', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter Download Speed',
                                        },
                                    ],
                                })(<Input addonBefore="Mbps" />)}

                            </Form.Item>

                        </Col>

                        <Col sm={8} xs={24}>

                            <Form.Item {...formItemLayout2} label="Typical Evening Download Speed Description">
                                {getFieldDecorator('typical_evening_download_speed_description', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please EnterTypical Evening Download Speed Description',
                                        },
                                    ],
                                })(<TextArea autoSize={{ minRows: 3, maxRows: 5 }} />)}

                            </Form.Item>

                        </Col>

                        <Col sm={8} xs={24}>

                            <Form.Item {...formItemLayout2} label="upload Speed Description">
                                {getFieldDecorator('upload_speed_description', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please Enter upload Speed Description',
                                        },
                                    ],
                                })(<TextArea autoSize={{ minRows: 3, maxRows: 5 }} />)}

                            </Form.Item>

                        </Col>


                        <Col span={24} className="gx-text-right">

                            <center>

                                <div className='contentCenter'>

                                    <Button type="primary" htmlType="submit">
                                        Continue
                                    </Button>

                                </div>
                            </center>
                        </Col>

                    </Row>

                </Form>
            </Card>
        </Col>
    )
}

const WrappedGeneralDetails = Form.create()(GeneralDetails);

export default WrappedGeneralDetails