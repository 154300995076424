const initialState = {
    productsDetails: null,
    status: 'idl',
    error: null,
}

export default function (state = initialState, action) {
    console.log(action);
    switch (action.type) {

        case 'SAVE_PRODUCTS':
            return {
                ...state,
                productsDetails: action.productsDetails,
                status: 'success'
            };

        case 'RESET_PRODUCTS':
            return {
                ...state,
                productsDetails: null,
                status: 'idl'
            };

        case 'FAILED_RETRIEVE_PRODUCTS':
            return {
                ...state,
                productsDetails: null,
                status: 'failed'
            };

        case 'LOADING_PRODUCTS':
            return {
                ...state,
                productsDetails: null,
                status: 'loading'
            };
        case 'ERROR':
            return {
                ...state,
                error: action.error,
                status: 'failed'
            };
        default:
            return state;
    }
}