

import React, { Component } from 'react'
import { Button, Card, Col, Form, Input, InputNumber, Radio, Row, Select, Spin, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import { connect } from 'react-redux'
import NewBroadbandOption from './NewBroadbandOption';
import isEmpty from "util/isEmpty";
import ContainerHeader from "components/ContainerHeader/index";
import { clearError } from "appRedux/actions/admin/dmovdo";
import { getRetailerList } from "appRedux/actions/admin/retailers";
import { getUniqueTariffs } from "appRedux/actions/admin/tariff";
import BroadbandModemTable from './BroadbandModemTable';
import { deleteBroadbandModemOptionRecord, getAllBroadbandModemOption, searchBroadbandModemRecords, setManageBroadbandModemAPIStatusToDefault, setSelectedBroadbandOption } from '../../../../appRedux/actions/admin/broadbandModem';

const FormItem = Form.Item;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const pagination = { position: 'bottom' };

export class BroadbandModemOption extends Component {
  constructor(props) {
    super(props)

    this.state = {
      newForm: false,
      isSearching: false,
      pagination,
      dataSource: [],
      current: 0,
    }
  }

  componentDidMount() {
    this.props.getAllBroadbandModemOption()
    this.props.getRetailerList();
    this.props.getUniqueTariffs();
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.deleteStatus === 'success') {

      message.success('Record Deleted Successfully');

      this.props.setCreateStatusToDefault()

      this.props.getAllBroadbandModemOption()

    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {

      console.log(values);
    })
  }

  navigateToAddNewBroadbandFeePage = () => {
    this.props.history.replace('/admin/manage/broadbandModemOption/new')
  }

  navigateToUpdate = (data) => {

    this.props.setSelectedBroadbandOption(data)
    this.props.history.replace(`/admin/manage/broadbandModemOption/${data.id}`)
  }

  deleteBroadbandFeeRecord = (id) => {

    this.props.deleteBroadbandModemOptionRecord(id)

  }

  handleSearch = (e) => {

    e.preventDefault();

    this.props.form.validateFields((err, values) => {

      const filtersForSearch = {
        ...(values.txtRetailerNameSearch != "-1" && { retailer: values.txtRetailerNameSearch }),
        ...(values.txtStateSearch != "-1" && { state: values.txtStateSearch }),
        ...(values.txtResSmeSearch != "-1" && { res_sme: values.txtResSmeSearch }),
        ...(values.technology_type != "-1" && { technology_type: values.technology_type }),
        ...(values.modem_type != "-1" && { modem_type: values.modem_type }),

      }

      this.setState({ isSearching: true });

      if (!isEmpty(filtersForSearch)) {

        this.props.searchBroadbandModemRecords(filtersForSearch);

      }
      else {

        this.props.getAllBroadbandModemOption();

        this.setState({ isSearching: false });

      }

    });

  };

  handleClearSearch = () => {

    this.props.form.resetFields();

    this.props.getAllBroadbandModemOption();
  }

  componentWillReceiveProps(nextProps) {


    if (!isEmpty(nextProps.broadbandModem) && nextProps.broadbandModem.result && nextProps.broadbandModem.result.length > 0) {

      this.state.dataSource = nextProps.broadbandModem;

      this.setState({ isSearching: false });
    }
    else {

      this.setState({ dataSource: [] })

      this.setState({ isSearching: false });
    }
  }

  render() {

    const { getFieldDecorator } = this.props.form
    const { newForm, dataSource } = this.state
    return (
      <>
        {
          newForm && <NewBroadbandOption {...this.props} />
        }
        {
          !newForm && <div className='searchSection'>
            <ContainerHeader title="Manage Modem Option" />
            <Col span={24}>
              <Spin spinning={this.state.isSearching}>
                <Card className="gx-card" title={`Search Modem Options`} style={{ maxHeight: "550px", overflow: "hidden" }}>
                  <Form
                    className="ant-advanced-search-form"
                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                  >
                    <Row>
                      <Col lg={8} md={8} sm={8} xs={24}>
                        <div className="gx-form-row0">
                          <FormItem {...formItemLayout} label="Retailer" >
                            {getFieldDecorator('txtRetailerNameSearch', {
                              initialValue: "-1",
                            })(
                              <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a Retailer"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              >
                                <Option value="-1">All Retailers</Option>
                                {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                  return <option key={key} value={retailer.retailerFriendlyName}>{retailer.retailerName}</option>;
                                })}
                              </Select>
                            )}
                          </FormItem>
                        </div>
                      </Col>
                      <Col lg={8} md={8} sm={8} xs={24}>
                        <div className="gx-form-row0">
                          <FormItem {...formItemLayout} label="State" >
                            {getFieldDecorator('txtStateSearch', {
                              initialValue: "-1",
                            })(
                              <Select
                                style={{ width: '100%' }}
                                placeholder="Select State"
                              >
                                <Option value="-1">All States</Option>
                                <Option value="VIC">Victoria</Option>
                                <Option value="NSW">New South Wales</Option>
                                <Option value="ACT">Australian Capital Territory</Option>
                                <Option value="QLD">Queensland</Option>
                                <Option value="SA">South Australia</Option>
                                <Option value="WA">Western Australia</Option>
                                <Option value="NT">Northern Territory</Option>
                                <Option value="TAS">Tasmania</Option>
                              </Select>
                            )}
                          </FormItem>
                        </div>
                      </Col>

                      <Col lg={8} md={8} sm={8} xs={24}>
                        <div className="gx-form-row0">
                          <FormItem {...formItemLayout} label="RES/SME" >
                            {getFieldDecorator('txtResSmeSearch', {
                              initialValue: "-1",
                            })(
                              <Select
                                style={{ width: '100%' }}
                                placeholder="Select Options"
                              >
                                <Option value="-1">All Options</Option>
                                <Option value="RES">RES</Option>
                                <Option value="SME">SME</Option>
                              </Select>
                            )}
                          </FormItem>
                        </div>
                      </Col>

                    </Row>

                    <Row>
                      <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                          <Form.Item {...formItemLayout} label="Modem Type">
                            {getFieldDecorator('modem_type', { initialValue: "-1", })(
                              <Select placeholder="Modem Type">
                                <Option value="-1">All Modems</Option>
                                <Option value="BYO Modem">BYO Modem</Option>
                                <Option value="Google Modem">Google Modem</Option>
                              </Select>,
                            )}
                          </Form.Item>
                        </div>
                      </Col>

                      <Col lg={8} md={8} sm={12} xs={24}>

                        <Form.Item {...formItemLayout} label="Technology Type">
                          {getFieldDecorator('technology_type', { initialValue: "-1", })(
                            <Select placeholder="Please select Technology" >
                              <Option value="-1">All Technology</Option>
                              <Option value="FTTP">FTTP</Option>
                              <Option value="FTTC">FTTC</Option>
                              <Option value="FTTN">FTTN</Option>
                              <Option value="HFC">HFC</Option>
                              <Option value="FTTB">FTTB</Option>
                            </Select>,)}
                        </Form.Item>

                      </Col>

                    </Row>
                    <Row>
                    </Row>
                  </Form>
                  <Row>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" onClick={this.handleSearch} loading={this.state.isSearching}>
                          Search
                        </Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                          Clear
                        </Button>
                      </center>
                    </Col>
                  </Row>
                </Card>
              </Spin>
            </Col>
          </div>
        }

        <BroadbandModemTable
          data={dataSource}
          navigate={this.navigateToAddNewBroadbandFeePage}
          navigateToUpdate={(data) => this.navigateToUpdate(data)}
          deleteRecord={this.deleteBroadbandFeeRecord} />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  error: state.dmoVdo.error,
  retailerList: state.retailers.retailerList,
  tariffList: state.tariff.tariffList,
  broadbandModem: state.broadbandModem.broadbandModem,
  deleteStatus: state.broadbandModem.deleteApiStatus,
})

const mapDispatchToProps = dispath => ({
  getRetailerList: (data) => dispath(getRetailerList(data)),
  getUniqueTariffs: () => dispath(getUniqueTariffs()),
  searchBroadbandModemRecords: (data) => dispath(searchBroadbandModemRecords(data)),
  getAllBroadbandModemOption: () => dispath(getAllBroadbandModemOption()),
  clearError: () => dispath(clearError()),
  setSelectedBroadbandOption: (data) => dispath(setSelectedBroadbandOption(data)),
  deleteBroadbandModemOptionRecord: (id) => dispath(deleteBroadbandModemOptionRecord(id)),
  setCreateStatusToDefault: () => dispath(setManageBroadbandModemAPIStatusToDefault()),
})

const WrappedBroadbandModemOption = Form.create()(BroadbandModemOption);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedBroadbandModemOption)