import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';

export const saveRecords = resultList => ({
    type: 'SAVE_TARIFF_RECORDS',
    resultList,
});

export const saveTariffList = resultList => ({
    type: 'SAVE_TARIFF_LIST',
    resultList,
});

export const saveRecordUpdateStatus = recordUpdateStatus => ({
    type: 'SAVE_RECORD_UPDATE_STATUS',
    recordUpdateStatus,
});

export const error = error => ({
    type: 'ERROR',
    error,
});

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const getUniqueTariffs = () => dispath =>{

    axios.get(apidetails.API_URL + 'tariff/uniqueTariffs')
    .then(res => {
        dispath(saveTariffList(res.data.result));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const getTariffRecords = () => dispath =>{

    axios.get(apidetails.API_URL + 'tariff/')
    .then(res => {
        dispath(saveRecords(res.data.result));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const searchTariffRecords = (data) => dispath =>{
    axios.post(apidetails.API_URL + 'tariff/search', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecords(res.data.result));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const updateTariffSingleRecord = (data) => dispath =>{

    axios.put(apidetails.API_URL + 'tariff/', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const createTariffRecord = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'tariff/', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const uploadTariffRecords = (data) => dispath =>{

    config.headers = {
        'Content-Type': 'multipart/form-data',
        'timeout': 600000
    }

    axios.post(apidetails.API_URL + 'tariff/upload', data, config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.error || 'ERROR'));
    });  
}

export const wipeAndUploadTariffRecords = (data) => dispath =>{

    config.headers = {
        'Content-Type': 'multipart/form-data',
        'timeout': 600000
    }

    axios.post(apidetails.API_URL + 'tariff/wipeUpload', data, config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.error || 'ERROR'));
    });  
}

export const deleteTariffRecord = (idToDelete) => dispath =>{

    axios.delete(apidetails.API_URL + `tariff/${idToDelete}`, config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const clearError = () => dispath =>{
    dispath(error(''));
}