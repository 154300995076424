import React from "react";
import { connect } from "react-redux";
import { Breadcrumb, Divider, Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, InputNumber, DatePicker, Tag, Collapse, Upload, Icon } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { createRecord, clearError } from "appRedux/actions/admin/dncRegister";

import { getUsers } from "appRedux/actions/admin/users";
// import "./index.css";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import exportToCSV from 'util/exportToCSV'
import swal from 'sweetalert2';

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const { TextArea } = Input;

const moduleName = "DNC Register"
const moduleNameUPPER = "DNCREGISTER"

const showHeader = true;
const pagination = { position: 'bottom' };

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

class AddToDNCRegister extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editingKey: '',
            recordtoEdit: null,
            recordtoDelete: null,
            formFunction: null,
            isAddingUpdating: false,
            isSearching: false,
            recordsEdited: false,
            isAddEmail: false,
            expand: false,
            dataSource: [],
            addingNewRecord: false,
            showAddEditPanel: true,
            showSearchPanel: true,
            showWarning: false,
            warningMessage: "",
            showSuccess: false,
            successMessage: "",
            showError: false,
            errorMessage: "",
            showWaiting: false,
            showWaitingOkButton: false,
            bordered: true,
            loading: false,
            pagination,
            size: 'middle',
            title: undefined,
            showHeader,
            footer: undefined,
            rowSelection: {},
            singleRecordDetails: {},
            fileList: [],
            fieldState: {
                "customerTitle": false,
                "customerFirstName": false,
                "customerLastName": false,
                "phoneNumber": false,
                "emailAddress": false,
                "salesBroker": false,
                "leadId": false,
                "comment": false,
                "actionedBy": false,
            }
        };        

    }

    disableAllFields = () => {

        this.setState({
            fieldState: {
                "customerTitle": true,
                "customerFirstName": true,
                "customerLastName": true,
                "phoneNumber": true,
                "emailAddress": true,
                "salesBroker": true,
                "leadId": true,
                "comment": true,
                "actionedBy": true,
            }
        })
    };

    enableAllFields = () => {

        this.setState({
            fieldState: {
                "customerTitle": false,
                "customerFirstName": false,
                "customerLastName": false,
                "phoneNumber": false,
                "emailAddress": false,
                "salesBroker": false,
                "leadId": false,
                "comment": false,
                "actionedBy": false,
            }
        })
    };

    onConfirm = () => {
        this.setState({
            showSuccess: false,
            showError: false,
            showAddEditPanel: true,
        });
        this.handleReset();
    };

    removeSearchValuesFromPayload = (values) => {

        delete values.drpSalesAgent;
        delete values.drpActionBy;
        delete values.txtPhoneNumber;
        delete values.txtEmailAddress;
        delete values.txtLeadID;
        delete values.emailAddressButton;

        return values;
    };

    handleAddRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (!err) {

                values = this.removeSearchValuesFromPayload(values);

                delete values.createdDate;
                delete values.updatedDate;

                if(!isEmpty(values.emailAddress))
                {
                    var invalidEmailList = ['noemail@gmail.com',
                        'sample@noemail.com.au',
                        '123@gmail.com',
                        'unknown@gmail.com',
                        'no@email.com',
                        'noemail@com.au']

                    if(invalidEmailList.find(data => data.toUpperCase() == values.emailAddress.toUpperCase())){
                        this.setState({
                            errorMessage: "Invalid or fake email address! Please enter correct email address.",
                            showError: true,
                            successMessage: "",
                            showSuccess: false,
                            isAddingUpdating: false,
                            showAddEditPanel: true,
                        });

                        return;
                    }                    
                }

                this.setState({
                    isAddingUpdating: true,
                });
                this.state.dataSource = [];
                this.props.createRecord(values);
            }


        });
    };

    onAddUpdateCancel = () => {
        this.handleReset();
    };

    componentDidMount() {
        this.props.getUsers();
    }

    handleReset = () => {
        this.props.form.resetFields();

        this.setState({
            showAddEditPanel: true,
        });
    };

    handleAddEmail = (e) => {

        e.preventDefault();

        this.setState({
            isAddEmail: true,
        });
    };

    handleRemoveEmail = (e) => {

        e.preventDefault();

        this.setState({
            isAddEmail: false,
        });
    };

    componentWillReceiveProps(nextProps) {

        if (!isEmpty(nextProps.recordUpdateStatus)) {
            if (nextProps.recordUpdateStatus.status == "Success") {
                this.setState({
                    successMessage: nextProps.recordUpdateStatus.message,
                    showSuccess: true,
                    showWarning: false,
                    warningMessage: "",
                    isAddingUpdating: false,
                    showAddEditPanel: true,
                });
                swal.close();
            }
        }

        if (!isEmpty(nextProps.error)) {
            if (nextProps.error != "") {
                this.setState({
                    errorMessage: nextProps.error,
                    showError: true,
                    successMessage: "",
                    showSuccess: false,
                    isAddingUpdating: false,
                    showAddEditPanel: true,
                });
                
                this.props.clearError();
            }
        }
    }


    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-main-content">
                <ContainerHeader title={`New DNC Register Record`} />
                <Row>

                    {this.state.showAddEditPanel && <Col span={24}>
                        <Spin spinning={this.state.isAddingUpdating}>
                            <Card className="gx-card" title={`Add New ${moduleName} Details`} style={{ maxHeight: "850px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "600px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                                >
                                    <Collapse accordion defaultActiveKey={['1']}>
                                        <Panel header="Basic Details" key="1">
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Title" >
                                                            {getFieldDecorator('customerTitle', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.customerTitle : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.customerTitle} style={{ width: '100%' }} placeholder="Enter Customer Title" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="First Name" >
                                                            {getFieldDecorator('customerFirstName', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.customerFirstName : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.customerFirstName} style={{ width: '100%' }} placeholder="Enter Customer First name" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Last Name" >
                                                            {getFieldDecorator('customerLastName', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.customerLastName : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.customerLastName} style={{ width: '100%' }} placeholder="Enter Customer Last name" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Phone Number" >
                                                            {getFieldDecorator('phoneNumber', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.phoneNumber : "",
                                                                rules: [{ required: true, message: 'Phone Number is required' }, {type: "string", min:10, message: 'Phone Number should be minimum of 10 numbers'}, {type: "string", max:10, message: 'Phone Number should be maximum of 10 numbers'}],
                                                            })(
                                                                <Input type="number" maxlength="10" disabled={this.state.fieldState.phoneNumber} style={{ width: '100%' }} placeholder="Enter Phone Number" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Lead ID" >
                                                            {getFieldDecorator('leadId', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.leadId : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input type="number" disabled={this.state.fieldState.leadId} style={{ width: '100%' }} placeholder="Enter Lead ID" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {this.state.isAddEmail == false && <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Email" >
                                                            {getFieldDecorator('emailAddressButton', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.emailAddress : "",
                                                                rules: [{ required: false, message: '' },{type: "email", message: 'Should be a valid email'}],
                                                            })(
                                                                <>
                                                                    <Button type="primary" onClick={(e) => this.handleAddEmail(e)}>
                                                                            Add Email
                                                                    </Button><br/><span style={{color:"#e60000"}}>Only enter email when requested to remove emails with the Actual emails and not fake emails!</span>
                                                                </>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                    
                                                </Col>}
                                                {this.state.isAddEmail && <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Email" >
                                                            {getFieldDecorator('emailAddress', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.emailAddress : "",
                                                                rules: [{ required: false, message: '' },{type: "email", message: 'Should be a valid email'}],
                                                            })(
                                                                <Input type="email" disabled={this.state.fieldState.emailAddress} style={{ width: '100%' }} placeholder="Enter Email" />
                                                            )}
                                                            <Button type="danger" onClick={(e) => this.handleRemoveEmail(e)}>
                                                                    Remove Email
                                                            </Button>
                                                        </FormItem>
                                                    </div>
                                                </Col>}
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={8} xs={24}>
                                                    <div className="gx-form-row0">
                                                    <FormItem {...formItemLayout} label="Comments" >
                                                        {getFieldDecorator('comment', {
                                                        initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.comment : "",
                                                        rules: [{ required: false, message: 'Please enter Comments!' }],
                                                        })(
                                                        <TextArea disabled={this.state.fieldState.comment} rows={4} style={{ width: '100%' }} placeholder="Please enter Comments" />
                                                        )}
                                                    </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Sales Agent" >
                                                            {getFieldDecorator('salesBroker', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.salesBroker : this.props.userDetails.name,
                                                                rules: [{ required: true, message: 'Please select a Sales Agent!' }],
                                                            })(
                                                                <Select
                                                                    disabled={true}
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Sales Agent"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                >
                                                                    {!isEmpty(this.props.userList) && this.props.userList.map((user, key) => {
                                                                        return user.isActive == 1 && <option key={key} value={user.name}>{user.name}</option>;
                                                                    })}
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout} label="Actioned by" >
                                                            {getFieldDecorator('actionedBy', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.actionedBy : this.props.userDetails.name,
                                                                rules: [{ required: true, message: 'Please select a Actioned By User!' }],
                                                            })(
                                                                <Select
                                                                    disabled={true}
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Actioned By User"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                >
                                                                    {!isEmpty(this.props.userList) && this.props.userList.map((user, key) => {
                                                                        return user.isActive == 1 && <option key={key} value={user.name}>{user.name}</option>;
                                                                    })}
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Panel>
                                    </Collapse>

                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            
                                            <Button type="primary" onClick={this.handleAddRecord}>
                                                    Add
                                            </Button>

                                            <Button style={{ marginLeft: 8 }} onClick={this.onAddUpdateCancel}>
                                                Cancel
                                            </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }


                </Row>
                <SweetAlert show={this.state.showWaiting}
                    title={'Please wait..'}
                    buttons={false}
                />
                <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showError} error title={this.state.errorMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showWarning}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={this.state.warningMessage}
                    onConfirm={this.handleDelete}
                    onCancel={this.onCancelDelete}
                ></SweetAlert>
            </div>
        );
    }
}

const mapStateToProps = state => ({

    userList: state.users.userList,

    userDetails: state.auth.userDetails,

    recordUpdateStatus: state.dncRegister.recordUpdateStatus,
    error: state.dncRegister.error,
});

const mapDispatchToProps = dispath => ({

    getUsers: (data) => dispath(getUsers(data)),

    createRecord: (data) => dispath(createRecord(data)),

    clearError: () => dispath(clearError()),

});

const WrappedAdvancedSearch = Form.create()(AddToDNCRegister);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);