const initialState = {
    retailerFileHeaderList: {},    
    recordUpdateStatus: null,
    error: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'SAVE_RETAILER_FILE_HEADER_LIST':
            return {
                ...state,
                retailerFileHeaderList: action.retailerFileHeaderList
            };
        case 'SAVE_RECORD_UPDATE_STATUS':
            return {
                ...state,
                recordUpdateStatus: action.recordUpdateStatus
            };
        case 'ERROR':
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}