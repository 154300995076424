import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';

export const saveStatusList = statusList => ({
    type: 'SAVE_STATUS_LIST',
    statusList,
});

export const saveRecordUpdateStatus = recordUpdateStatus => ({
    type: 'SAVE_RECORD_UPDATE_STATUS',
    recordUpdateStatus,
});

export const error = error => ({
    type: 'ERROR',
    error,
});

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const getStatusList = (data) => dispath =>{

    if(data !== undefined)
    {
        axios.post(apidetails.API_URL + 'status/', qs.stringify(data), config)
        .then(res => {
            dispath(saveStatusList(res.data.statusList));
        })
        .catch(err => {
            dispath(error(err.message || 'ERROR'));
        });
    }
    else
    {
        axios.get(apidetails.API_URL + 'status/')
        .then(res => {
            dispath(saveStatusList(res.data.statusList));
        })
        .catch(err => {
            dispath(error(err.message || 'ERROR'));
        });
    }    
}

export const updateStatusList = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'status/update', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.message || 'ERROR'));
    });  
}
