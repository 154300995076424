import React from "react";
import { connect } from "react-redux";
import { Breadcrumb, Divider, Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, InputNumber, DatePicker, Tag, Collapse, Upload, Icon } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getSingleLeadDetails, assignLeadToAgent, clearAll } from "appRedux/actions/sales/leads";
// import "./index.css";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import exportToCSV from 'util/exportToCSV'
import swal from 'sweetalert2';

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const { TextArea } = Input;

const pagination = { position: 'bottom' };

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

class AssignCompareBroadbandLeads extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isSearching: false,
            showSearchPanel: true,
            showWarning: false,
            warningMessage: "",
            showSuccess: false,
            successMessage: "",
            showError: false,
            errorMessage: "",
            showWaiting: false,
            showWaitingOkButton: false,
            singleRecordDetails: {},
            disableAssignToMyselfButton: true,
        };

    }

    

    handleClearSearch = () => {

        this.handleReset();

    };

    componentDidMount() {


        
    }

    handleSearchLeadId = (e) => {

        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            console.log(values);

            if (!isEmpty(values.tetxLeadIdToSearch)) {
                this.setState({
                    loading: true,
                    isSearching: true,
                    singleRecordDetails: null,
                });
                this.props.getSingleLeadDetails(values.tetxLeadIdToSearch);
            }
        });

    };

    handleSearchLeadIdOnKeyDown = (e) => {

        //e.preventDefault();

        if (e.key === 'Enter') {

            this.props.form.validateFields((err, values) => {
                console.log(values);
    
                if (!isEmpty(values.tetxLeadIdToSearch)) {
                    this.setState({
                        loading: true,
                        isSearching: true,
                        singleRecordDetails: null,
                    });
                    this.props.getSingleLeadDetails(values.tetxLeadIdToSearch);
                }
            });
    
        }
    };

    handleAssignLead = (e) => {

        e.preventDefault();

        this.setState({ isSearching: true });

        setTimeout(() => {

            this.props.form.validateFields((err, values) => {

                const dataToSend = {
                    leadId: this.state.singleRecordDetails.Id["_text"],
                    agentEmail: this.props.userDetails.email,
                }
                

                this.props.assignLeadToAgent(dataToSend);

            });
        }, 1000);

    };

    handleReset = () => {

        this.props.form.resetFields();

        this.setState({
            singleRecordDetails: null,
            showSearchPanel: true,
            disableAssignToMyselfButton: false,
        });

    };

    componentWillReceiveProps(nextProps) {

        if (!isEmpty(nextProps.singleLeadDetails)) {

            if(isEmpty(this.state.singleRecordDetails))
            {

                var allowedLeadSourceArray = ["Deal Reveal - Res Leads","CompareBroadbandLead","DR- SME Request","DR- RESI Request"]

                if( allowedLeadSourceArray.find(data => data == nextProps.singleLeadDetails.LeadSource["_text"]) )
                {
                    this.setState({
                        loading: false,
                        isSearching: false,
                        singleRecordDetails: nextProps.singleLeadDetails,
                        disableAssignToMyselfButton: false,
                    });
                }
                else
                {
                    this.setState({
                        errorMessage: "Non Compare Broadband or Deal Reveal lead",
                        showError: true,
                        successMessage: "",
                        showSuccess: false,
                        showSearchPanel: true,
                        disableAssignToMyselfButton: true,
                    });
                    this.props.clearAll();
                }
                
            }            
        }   
        
        if (!isEmpty(nextProps.leadAssignmentToAgentMessage)) {
            this.setState({
                successMessage: nextProps.leadAssignmentToAgentMessage,
                showSuccess: true,
                showWarning: false,
                warningMessage: "",
                disableAssignToMyselfButton: true,
            });
            this.props.clearAll();
        }

        if (!isEmpty(nextProps.error)) {
            if (nextProps.error != "") {
                this.setState({
                    errorMessage: nextProps.error,
                    showError: true,
                    successMessage: "",
                    showSuccess: false,
                    showSearchPanel: true,
                    disableAssignToMyselfButton: true,
                });
                this.props.clearAll();
            }
        }
    }

    onConfirm = () => {
        this.setState({
            showSuccess: false,
            showError: false,
            showSearchPanel: true,
            isSearching: false,
        });
    };


    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-main-content">
                <ContainerHeader title={`Search and Assign Compare Broadband and Deal Reveal Leads`} />
                <Row>
                    <Col span={24}>
                    <Spin spinning={this.state.isSearching}>
                        <Card className="gx-card" title={`Search Compare Broadband and Deal Reveal Leads`} style={{ maxHeight: "550px", overflow: "hidden" }}>
                            <Form
                                className="ant-advanced-search-form"
                                style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                            >
                                <Row>
                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        <center>
                                            <h3>Enter Velocify Lead ID to Search</h3>
                                        <div className="gx-form-row0">
                                            {/* <Input style={{ width: '50%' }} value="2026563" id="tetxLeadIdToSearch" name="tetxLeadIdToSearch" placeholder="Enter Velocify Lead ID" /> */}
                                            <FormItem {...formItemLayout2} >
                                                {getFieldDecorator('tetxLeadIdToSearch', {
                                                    initialValue: "",
                                                })(
                                                    <Input onKeyDown={this.handleSearchLeadIdOnKeyDown} style={{ width: '50%', height: "50px", fontSize: "25px", textAlign:"center" }} placeholder="Enter Velocify Lead ID" />
                                                )}
                                            </FormItem>
                                        </div>
                                        </center>
                                    </Col>
                                </Row>
                            </Form>
                            <Row>
                                <Col span={24} className="gx-text-right">
                                    <center>
                                        <Button type="primary" onClick={this.handleSearchLeadId}>
                                            Search
                      </Button>
                                        <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                                            Clear
                      </Button>
                                    </center>
                                </Col>
                            </Row>
                        </Card>
                        </Spin>
                    </Col>
                </Row>
                <Row>
                    {this.state.showSearchPanel && !isEmpty(this.state.singleRecordDetails) && <Col span={24}>
                        <Spin spinning={this.state.isSearching}>
                            <Card className="gx-card" title={`Lead Information`} style={{ maxHeight: "550px", overflow: "hidden" }}>
                                {!isEmpty(this.state.singleRecordDetails) && <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                                >
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Lead Source" >
                                                    {getFieldDecorator('leadSource', {
                                                        initialValue: !isEmpty(this.state.singleRecordDetails.LeadSource) ? this.state.singleRecordDetails.LeadSource["_text"] : "",
                                                        rules: [{ required: false, message: '' }],
                                                    })(
                                                        <Input disabled={true} style={{ width: '100%' }} />
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="First Name" >
                                                    {getFieldDecorator('firstName', {
                                                        initialValue: !isEmpty(this.state.singleRecordDetails.FirstName) ? this.state.singleRecordDetails.FirstName["_text"] : "",
                                                        rules: [{ required: false, message: '' }],
                                                    })(
                                                        <Input disabled={true} style={{ width: '100%' }} />
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Last Name" >
                                                    {getFieldDecorator('lastName', {
                                                        initialValue: !isEmpty(this.state.singleRecordDetails.Surname) ? this.state.singleRecordDetails.Surname["_text"] : "",
                                                        rules: [{ required: false, message: '' }],
                                                    })(
                                                        <Input disabled={true} style={{ width: '100%' }} />
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>                                                       
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Mobile Number" >
                                                    {getFieldDecorator('mobileNumber', {
                                                        initialValue: !isEmpty(this.state.singleRecordDetails.MobileNumber) ? this.state.singleRecordDetails.MobileNumber["_text"] : "",
                                                        rules: [{ required: false, message: '' }],
                                                    })(
                                                        <Input disabled={true} style={{ width: '100%' }} />
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>                                       
                                    </Row>                              
                                </Form>}
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            <Button disabled={this.state.disableAssignToMyselfButton} type="primary" onClick={this.handleAssignLead}>
                                                Assign Lead to myself
                      </Button>
                                            {/* <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                                                Clear
                      </Button> */}
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }


                </Row>
                <SweetAlert show={this.state.showWaiting}
                    title={'Please wait..'}
                    buttons={false}
                />
                <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showError} error title={this.state.errorMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showWarning}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={this.state.warningMessage}
                    onConfirm={this.handleDelete}
                    onCancel={this.onCancelDelete}
                ></SweetAlert>
            </div>
        );
    }
}

const mapStateToProps = state => ({

    recordUpdateStatus: state.elecBPIDs.recordUpdateStatus,
    error: state.elecBPIDs.error,

    singleLeadDetails: state.leads.leadDetails,
    leadAssignmentToAgentMessage: state.leads.leadAssignmentToAgentMessage,

    userDetails: state.auth.userDetails,
});

const mapDispatchToProps = dispath => ({

    getSingleLeadDetails: (id) => dispath(getSingleLeadDetails(id)),
    assignLeadToAgent: (data) => dispath(assignLeadToAgent(data)),

    clearAll: () => dispath(clearAll()),

});

const WrappedAdvancedSearch = Form.create()(AssignCompareBroadbandLeads);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);