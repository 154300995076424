import { Button, Card, Tooltip } from 'antd'
import classNames from 'classnames';
import React from 'react'
import { FaExclamationCircle } from 'react-icons/fa';
import HtmlParser from 'react-html-parser';

import styles from './BroadbandResult.module.less';

const ModemOptionCard = (props) => {

    const { variant, title, description, image, price, deliveryCharge, selected, handleSelect, id, tooltip, url, data } = props;

    return (
        <Card className={classNames(styles.optionCard, variant ? styles[`card-${variant}`] : '', 'gx-card', selected === id && styles.selectedOptionCard)}>

            <div className={classNames(styles.optionCardImage)}>

                <img src={image} />
            </div>

            <h5 className={classNames(styles.optionCardPrice)} >
                ${parseFloat(price).toFixed(2)}
            </h5>

            <h5 className={classNames(styles.optionCardTitle)} >
                {HtmlParser(title)}
            </h5>

            <p>
                {description} {tooltip && <Tooltip placement="topRight" title={HtmlParser(tooltip)} className={styles.optionCardTooltipIcon}>
                    <FaExclamationCircle />
                </Tooltip>}
            </p>
            {(deliveryCharge && (parseFloat(deliveryCharge).toFixed(0) != 0 )) && <p style={{color:"red"}}>
                Delivery Charge: ${parseFloat(deliveryCharge).toFixed(2)}
            </p>}
            {
                url && <a href={url} target="_blank">View More Details</a>
            }

            <div className={classNames(styles.optionCardFooter)}>

                <div className={classNames(styles.optionCardButtonContainer)}>

                    <Button
                        className={classNames(styles['btn-success'], styles.fullWidth, styles.marginTop)}
                        size={'large'}
                        onClick={() => handleSelect(id, url, data)}
                    >
                        {selected === id ? "Selected" : "Choose"}
                    </Button>

                </div>

            </div>



        </Card>
    )
}

export default ModemOptionCard