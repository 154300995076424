export const streetType = [
  {
    StreetAbbrev: "ACCS",
    Description: "Access"
  },
  {
    StreetAbbrev: "ALLY",
    Description: "Alley"
  },
  {
    StreetAbbrev: "ALWY",
    Description: "Alleyway"
  },
  {
    StreetAbbrev: "AMBL",
    Description: "Amble"
  },
  {
    StreetAbbrev: "ANCG",
    Description: "Anchorage"
  },
  {
    StreetAbbrev: "APP",
    Description: "Approach"
  },
  {
    StreetAbbrev: "ARC",
    Description: "Arcade"
  },
  {
    StreetAbbrev: "ART",
    Description: "Artery"
  },
  {
    StreetAbbrev: "AVE",
    Description: "Avenue"
  },
  {
    StreetAbbrev: "BASN",
    Description: "Basin"
  },
  {
    StreetAbbrev: "BCH",
    Description: "Beach"
  },
  {
    StreetAbbrev: "BDGE",
    Description: "Bridge"
  },
  {
    StreetAbbrev: "BDWY",
    Description: "Broadway"
  },
  {
    StreetAbbrev: "BEND",
    Description: "Bend"
  },
  {
    StreetAbbrev: "BLK",
    Description: "Block"
  },
  {
    StreetAbbrev: "BRAE",
    Description: "Brae"
  },
  {
    StreetAbbrev: "BRCE",
    Description: "Brace"
  },
  {
    StreetAbbrev: "BRK",
    Description: "Break"
  },
  {
    StreetAbbrev: "BROW",
    Description: "Brow"
  },
  {
    StreetAbbrev: "BVD",
    Description: "Boulevard"
  },
  {
    StreetAbbrev: "BYPA",
    Description: "Bypass"
  },
  {
    StreetAbbrev: "BYWY",
    Description: "Byway"
  },
  {
    StreetAbbrev: "CAUS",
    Description: "Causeway"
  },
  {
    StreetAbbrev: "CCT",
    Description: "Circuit"
  },
  {
    StreetAbbrev: "CDS",
    Description: "Cul-De-Sac"
  },
  {
    StreetAbbrev: "CH",
    Description: "Chase"
  },
  {
    StreetAbbrev: "CIR",
    Description: "Circle"
  },
  {
    StreetAbbrev: "CL",
    Description: "Close"
  },
  {
    StreetAbbrev: "CLDE",
    Description: "Colonnade"
  },
  {
    StreetAbbrev: "CLT",
    Description: "Circlet"
  },
  {
    StreetAbbrev: "CMMN",
    Description: "Common"
  },
  {
    StreetAbbrev: "CNR",
    Description: "Corner"
  },
  {
    StreetAbbrev: "CNWY",
    Description: "Centreway"
  },
  {
    StreetAbbrev: "CON",
    Description: "Concourse"
  },
  {
    StreetAbbrev: "COVE",
    Description: "Cove"
  },
  {
    StreetAbbrev: "COWY",
    Description: "Crossway"
  },
  {
    StreetAbbrev: "CPS",
    Description: "Copse"
  },
  {
    StreetAbbrev: "CRCS",
    Description: "Circus"
  },
  {
    StreetAbbrev: "CRD",
    Description: "Crossroad"
  },
  {
    StreetAbbrev: "CRES",
    Description: "Crescent"
  },
  {
    StreetAbbrev: "CRSG",
    Description: "Crossing"
  },
  {
    StreetAbbrev: "CRSS",
    Description: "Cross"
  },
  {
    StreetAbbrev: "CRST",
    Description: "Crest"
  },
  {
    StreetAbbrev: "CSO",
    Description: "Corso"
  },
  {
    StreetAbbrev: "CT",
    Description: "Court"
  },
  {
    StreetAbbrev: "CTR",
    Description: "Centre"
  },
  {
    StreetAbbrev: "CTTG",
    Description: "Cutting"
  },
  {
    StreetAbbrev: "CTYD",
    Description: "Courtyard"
  },
  {
    StreetAbbrev: "CUWY",
    Description: "Cruiseway"
  },
  {
    StreetAbbrev: "DALE",
    Description: "Dale"
  },
  {
    StreetAbbrev: "DELL",
    Description: "Dell"
  },
  {
    StreetAbbrev: "DEVN",
    Description: "Deviation"
  },
  {
    StreetAbbrev: "DIP",
    Description: "Dip"
  },
  {
    StreetAbbrev: "DR",
    Description: "Drive"
  },
  {
    StreetAbbrev: "DRWY",
    Description: "Driveway"
  },
  {
    StreetAbbrev: "DSTR",
    Description: "Distributor"
  },
  {
    StreetAbbrev: "EDGE",
    Description: "Edge"
  },
  {
    StreetAbbrev: "ELB",
    Description: "Elbow"
  },
  {
    StreetAbbrev: "END",
    Description: "End"
  },
  {
    StreetAbbrev: "ENT",
    Description: "Entrance"
  },
  {
    StreetAbbrev: "ESP",
    Description: "Esplanade"
  },
  {
    StreetAbbrev: "EST",
    Description: "Estate"
  },
  {
    StreetAbbrev: "EXP",
    Description: "Expressway"
  },
  {
    StreetAbbrev: "EXTN",
    Description: "Extension"
  },
  {
    StreetAbbrev: "FAWY",
    Description: "Fairway"
  },
  {
    StreetAbbrev: "FITR",
    Description: "Firetrail"
  },
  {
    StreetAbbrev: "FLAT",
    Description: "Flat"
  },
  {
    StreetAbbrev: "FOLW",
    Description: "Follow"
  },
  {
    StreetAbbrev: "FORM",
    Description: "Formation"
  },
  {
    StreetAbbrev: "FRNT",
    Description: "Front"
  },
  {
    StreetAbbrev: "FRTG",
    Description: "Frontage"
  },
  {
    StreetAbbrev: "FSHR",
    Description: "Foreshore"
  },
  {
    StreetAbbrev: "FTRK",
    Description: "Fire Track"
  },
  {
    StreetAbbrev: "FTWY",
    Description: "Footway"
  },
  {
    StreetAbbrev: "FWY",
    Description: "Freeway"
  },
  {
    StreetAbbrev: "GAP",
    Description: "Gap"
  },
  {
    StreetAbbrev: "GDN",
    Description: "Garden"
  },
  {
    StreetAbbrev: "GDNS",
    Description: "Gardens"
  },
  {
    StreetAbbrev: "GLD",
    Description: "Glade"
  },
  {
    StreetAbbrev: "GLEN",
    Description: "Glen"
  },
  {
    StreetAbbrev: "GLY",
    Description: "Gully"
  },
  {
    StreetAbbrev: "GR",
    Description: "Grove"
  },
  {
    StreetAbbrev: "GRA",
    Description: "Grange"
  },
  {
    StreetAbbrev: "GRN",
    Description: "Green"
  },
  {
    StreetAbbrev: "GRND",
    Description: "Ground"
  },
  {
    StreetAbbrev: "GTE",
    Description: "Gate"
  },
  {
    StreetAbbrev: "GTES",
    Description: "Gates"
  },
  {
    StreetAbbrev: "HILL",
    Description: "Hill"
  },
  {
    StreetAbbrev: "HRD",
    Description: "Highroad"
  },
  {
    StreetAbbrev: "HTS",
    Description: "Heights"
  },
  {
    StreetAbbrev: "HWY",
    Description: "Highway"
  },
  {
    StreetAbbrev: "INTG",
    Description: "Interchange"
  },
  {
    StreetAbbrev: "INTN",
    Description: "Intersection"
  },
  {
    StreetAbbrev: "JNC",
    Description: "Junction"
  },
  {
    StreetAbbrev: "KEY",
    Description: "Key"
  },
  {
    StreetAbbrev: "LANE",
    Description: "Lane"
  },
  {
    StreetAbbrev: "LDG",
    Description: "Landing"
  },
  {
    StreetAbbrev: "LEES",
    Description: "Lees"
  },
  {
    StreetAbbrev: "LINE",
    Description: "Line"
  },
  {
    StreetAbbrev: "LINK",
    Description: "Link"
  },
  {
    StreetAbbrev: "LKT",
    Description: "Lookout"
  },
  {
    StreetAbbrev: "LNWY",
    Description: "Laneway"
  },
  {
    StreetAbbrev: "LOOP",
    Description: "Loop"
  },
  {
    StreetAbbrev: "LT",
    Description: "Little"
  },
  {
    StreetAbbrev: "LWR",
    Description: "Lower"
  },
  {
    StreetAbbrev: "MALL",
    Description: "Mall"
  },
  {
    StreetAbbrev: "MEW",
    Description: "Mew"
  },
  {
    StreetAbbrev: "MEWS",
    Description: "Mews"
  },
  {
    StreetAbbrev: "MNDR",
    Description: "Meander"
  },
  {
    StreetAbbrev: "MT",
    Description: "Mount"
  },
  {
    StreetAbbrev: "MWY",
    Description: "Motorway"
  },
  {
    StreetAbbrev: "NOOK",
    Description: "Nook"
  },
  {
    StreetAbbrev: "OTLK",
    Description: "Outlook"
  },
  {
    StreetAbbrev: "PARK",
    Description: "Park"
  },
  {
    StreetAbbrev: "PART",
    Description: "Part"
  },
  {
    StreetAbbrev: "PASS",
    Description: "Pass"
  },
  {
    StreetAbbrev: "PATH",
    Description: "Path"
  },
  {
    StreetAbbrev: "PDE",
    Description: "Parade"
  },
  {
    StreetAbbrev: "PHWY",
    Description: "Pathway"
  },
  {
    StreetAbbrev: "PIAZ",
    Description: "Piazza"
  },
  {
    StreetAbbrev: "PKLD",
    Description: "Parklands"
  },
  {
    StreetAbbrev: "PKT",
    Description: "Pocket"
  },
  {
    StreetAbbrev: "PKWY",
    Description: "Parkway"
  },
  {
    StreetAbbrev: "PL",
    Description: "Place"
  },
  {
    StreetAbbrev: "PLAT",
    Description: "Plateau"
  },
  {
    StreetAbbrev: "PLZA",
    Description: "Plaza"
  },
  {
    StreetAbbrev: "PNT",
    Description: "Point"
  },
  {
    StreetAbbrev: "PORT",
    Description: "Port"
  },
  {
    StreetAbbrev: "PROM",
    Description: "Promenade"
  },
  {
    StreetAbbrev: "PSGE",
    Description: "Passage"
  },
  {
    StreetAbbrev: "QDGL",
    Description: "Quadrangle"
  },
  {
    StreetAbbrev: "QDRT",
    Description: "Quadrant"
  },
  {
    StreetAbbrev: "QUAD",
    Description: "Quad"
  },
  {
    StreetAbbrev: "QY",
    Description: "Quay"
  },
  {
    StreetAbbrev: "QYS",
    Description: "Quays"
  },
  {
    StreetAbbrev: "RAMP",
    Description: "Ramp"
  },
  {
    StreetAbbrev: "RCH",
    Description: "Reach"
  },
  {
    StreetAbbrev: "RD",
    Description: "Road"
  },
  {
    StreetAbbrev: "RDGE",
    Description: "Ridge"
  },
  {
    StreetAbbrev: "RDS",
    Description: "Roads"
  },
  {
    StreetAbbrev: "RDSD",
    Description: "Roadside"
  },
  {
    StreetAbbrev: "RDWY",
    Description: "Roadway"
  },
  {
    StreetAbbrev: "RES",
    Description: "Reserve"
  },
  {
    StreetAbbrev: "REST",
    Description: "Rest"
  },
  {
    StreetAbbrev: "RGWY",
    Description: "Ridgeway"
  },
  {
    StreetAbbrev: "RIDE",
    Description: "Ride"
  },
  {
    StreetAbbrev: "RING",
    Description: "Ring"
  },
  {
    StreetAbbrev: "RISE",
    Description: "Rise"
  },
  {
    StreetAbbrev: "RMBL",
    Description: "Ramble"
  },
  {
    StreetAbbrev: "RND",
    Description: "Round"
  },
  {
    StreetAbbrev: "RNDE",
    Description: "Ronde"
  },
  {
    StreetAbbrev: "RNGE",
    Description: "Range"
  },
  {
    StreetAbbrev: "ROW",
    Description: "Row"
  },
  {
    StreetAbbrev: "ROWY",
    Description: "Right of way"
  },
  {
    StreetAbbrev: "RSBL",
    Description: "Rosebowl"
  },
  {
    StreetAbbrev: "BOWL",
    Description: "Bowl"
  },
  {
    StreetAbbrev: "RTE",
    Description: "Route"
  },
  {
    StreetAbbrev: "RTT",
    Description: "Retreat"
  },
  {
    StreetAbbrev: "RTY",
    Description: "Rotary"
  },
  {
    StreetAbbrev: "RUE",
    Description: "Rue"
  },
  {
    StreetAbbrev: "RUN",
    Description: "Run"
  },
  {
    StreetAbbrev: "RVR",
    Description: "River"
  },
  {
    StreetAbbrev: "RVRA",
    Description: "Riviera"
  },
  {
    StreetAbbrev: "RVWY",
    Description: "Riverway"
  },
  {
    StreetAbbrev: "SBWY",
    Description: "Subway"
  },
  {
    StreetAbbrev: "SDNG",
    Description: "Siding"
  },
  {
    StreetAbbrev: "SHWY",
    Description: "State highway"
  },
  {
    StreetAbbrev: "SLPE",
    Description: "Slope"
  },
  {
    StreetAbbrev: "SND",
    Description: "Sound"
  },
  {
    StreetAbbrev: "SPUR",
    Description: "Spur"
  },
  {
    StreetAbbrev: "SQ",
    Description: "Square"
  },
  {
    StreetAbbrev: "ST",
    Description: "Street"
  },
  {
    StreetAbbrev: "STPS",
    Description: "Steps"
  },
  {
    StreetAbbrev: "STRA",
    Description: "Strand"
  },
  {
    StreetAbbrev: "STRP",
    Description: "Strip"
  },
  {
    StreetAbbrev: "STRS",
    Description: "Stairs"
  },
  {
    StreetAbbrev: "SWY",
    Description: "Service way"
  },
  {
    StreetAbbrev: "TARN",
    Description: "Tarn"
  },
  {
    StreetAbbrev: "TCE",
    Description: "Terrace"
  },
  {
    StreetAbbrev: "THOR",
    Description: "Thoroughfare"
  },
  {
    StreetAbbrev: "TKWY",
    Description: "Trunkway"
  },
  {
    StreetAbbrev: "TLWY",
    Description: "Tollway"
  },
  {
    StreetAbbrev: "TOP",
    Description: "Top"
  },
  {
    StreetAbbrev: "TOR",
    Description: "Tor"
  },
  {
    StreetAbbrev: "TRI",
    Description: "Triangle"
  },
  {
    StreetAbbrev: "TRK",
    Description: "Track"
  },
  {
    StreetAbbrev: "TRL",
    Description: "Trail"
  },
  {
    StreetAbbrev: "TRLR",
    Description: "Trailer"
  },
  {
    StreetAbbrev: "TURN",
    Description: "Turn"
  },
  {
    StreetAbbrev: "TWRS",
    Description: "Towers"
  },
  {
    StreetAbbrev: "UPAS",
    Description: "Underpass"
  },
  {
    StreetAbbrev: "UPR",
    Description: "Upper"
  },
  {
    StreetAbbrev: "VALE",
    Description: "Vale"
  },
  {
    StreetAbbrev: "VDCT",
    Description: "Viaduct"
  },
  {
    StreetAbbrev: "VIEW",
    Description: "View"
  },
  {
    StreetAbbrev: "VLLS",
    Description: "Villas"
  },
  {
    StreetAbbrev: "VSTA",
    Description: "Vista"
  },
  {
    StreetAbbrev: "WADE",
    Description: "Wade"
  },
  {
    StreetAbbrev: "WALK",
    Description: "Walk"
  },
  {
    StreetAbbrev: "WAY",
    Description: "Way"
  },
  {
    StreetAbbrev: "WHRF",
    Description: "Wharf"
  },
  {
    StreetAbbrev: "WKWY",
    Description: "Walkway"
  },
  {
    StreetAbbrev: "WYND",
    Description: "Wynd"
  },
  {
    StreetAbbrev: "YARD",
    Description: "Yard"
  }
]

export default streetType

