import React from "react";
import { connect } from "react-redux";
import { Breadcrumb, Divider, Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, InputNumber, DatePicker, Tag, Collapse, Upload, Icon, Tabs } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getRecords, searchRecords, updateSingleRecord, createRecord, deleteRecord, uploadRecords, wipeAndUploadRecords, clearError } from "appRedux/actions/admin/elecRates";
import { getRetailerList } from "appRedux/actions/admin/retailers";
import { getDistributorList } from "appRedux/actions/admin/distributors";
import { getUniqueTariffs } from "appRedux/actions/admin/tariff";
// import "./index.css";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import exportToCSV from 'util/exportToCSV'
import swal from 'sweetalert2';

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const { TextArea } = Input;
const { TabPane } = Tabs;

const moduleName = "Electricity Rates"
const moduleNameUPPER = "ELECTRICITYRATES"

const showHeader = true;
const pagination = { position: 'bottom' };

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

class ManagePDFs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editingKey: '',
            recordtoEdit: null,
            recordtoDelete: null,
            formFunction: null,
            isAddingUpdating: false,
            isSearching: false,
            recordsEdited: false,
            expand: false,
            dataSource: [],
            addingNewRecord: false,
            showAddEditPanel: false,
            showSearchPanel: true,
            showWarning: false,
            warningMessage: "",
            showSuccess: false,
            successMessage: "",
            showError: false,
            errorMessage: "",
            showWaiting: false,
            showWaitingOkButton: false,
            bordered: true,
            loading: false,
            pagination,
            size: 'middle',
            title: undefined,
            showHeader,
            footer: undefined,
            rowSelection: {},
            singleRecordDetails: {},
            fileList: [],
            fieldState: {
                'id': false,
                'state': false,
                'distributor': false,
                'res_sme': false,
                'retailer': false,
                'tariff_type': false,
                'tariff_class': false,
                'rate_name': false,
                'rate_tier_period': false,
                'stp_period': false,
                'peak_tier_1': false,
                'peak_tier_2': false,
                'peak_tier_3': false,
                'peak_tier_4': false,
                'controlled_load_tier_1': false,
                'peak_rate_1': false,
                'peak_rate_2': false,
                'peak_rate_3': false,
                'peak_rate_4': false,
                'peak_rate_5': false,
                'shoulder_rate': false,
                'controlled_load_1_rate_1': false,
                'controlled_load_1_rate_2': false,
                'controlled_load_2_rate': false,
                'off_peak_rate': false,
                'climate_saver_rate': false,
                'single_capacity_charge': false,
                'summer_non_summer_charge': false,
                'stp': false,
                'status': false,
                'rate_start': false,
                'rate_expire': false,
                'gst_rates': false,
                'controlled_load_1_stp': false,
                'controlled_load_2_stp': false,
                'solar_meter_charge': false,
                'created': false,
                'updated': false,
            }
        };
        this.columns = [
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 150,
                render: (text, record) => {
                    const { editingKey } = this.state;
                    const editable = this.isEditing(record);
                    return (
                        <span>
                            <center>
                                <a onClick={() => this.viewRecord(record)}>
                                    <i className="icon icon-search-new" />
                                </a>
                                <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.editRecord(record)}>
                                    <i className="icon icon-edit" />
                                </a>
                                <a style={{ marginLeft: 10, color: "#52c41a" }} disabled={editingKey !== ''} onClick={() => this.duplicateRecord(record)}>
                                    <i className="icon icon-copy" />
                                </a>
                                <a style={{ marginLeft: 10, color: "#f5222d" }} disabled={editingKey !== ''} onClick={() => this.confirmDeleteRecord(record)}>
                                    <i className="icon icon-close-circle" />
                                </a>
                            </center>
                        </span>
                    );
                },
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 150,
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: 'Created Date',
                dataIndex: 'created',
                key: 'created',
                align: 'center',
                width: 150,
                sorter: (a, b) => moment(a.created, "YYYY-MM-DDTHH:mm:ss.SSSZ") - moment(b.created, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
                render: (text, record) => {
                    return <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"geekblue"} key={text}>
                        {moment(text).format('YYYY-MM-DD h:mm:ss A')}
                    </Tag>
                }
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.state.localeCompare(b.state) },
            },
            {
                title: 'Distributor',
                dataIndex: 'distributor',
                key: 'distributor',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.distributor.localeCompare(b.distributor) },
            },
            {
                title: 'Retailer',
                dataIndex: 'retailer',
                key: 'retailer',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.retailer.localeCompare(b.retailer) },
            },
            {
                title: 'Rate Name',
                dataIndex: 'rate_name',
                key: 'rate_name',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.rate_name.localeCompare(b.rate_name) },
            },
            {
                title: 'RES/SME',
                dataIndex: 'res_sme',
                key: 'res_sme',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.res_sme.localeCompare(b.res_sme) },
            },
            {
                title: 'Tariff Type',
                dataIndex: 'tariff_type',
                key: 'tariff_type',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.tariff_type.localeCompare(b.tariff_type) },
            },
            {
                title: 'Tariff Class',
                dataIndex: 'tariff_class',
                key: 'tariff_class',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.tariff_class.localeCompare(b.tariff_class) },
            },
        ];

    }

    isEditing = record => record.key === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    handleImportFromCSV = (info) => {

        // this.setState({
        //     showWaiting: true,
        // })

        swal.fire({
            title: "Please Wait..",
            text: `System is currently uploading and processing your file - ${info.file.name}`,
            showConfirmButton: this.state.showWaitingOkButton,
        });

        const data = new FormData();

        data.append('file', info.file);

        this.props.uploadRecords(data);

        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            console.log(`${info.file.name} file upload failed.`);
        }


    };

    handleWipeImportFromCSV = (info) => {

        swal.fire({
            title: "Please Wait..",
            text: `System is currently uploading and processing your file - ${info.file.name}`,
            showConfirmButton: this.state.showWaitingOkButton,
        });

        const data = new FormData();

        data.append('file', info.file);

        this.props.wipeAndUploadRecords(data);

        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            console.log(`${info.file.name} file upload failed.`);
        }


    };

    handleExportToCSV = (e) => {

        var dataToExport = this.state.dataSource;

        dataToExport.map((record, key) => {
            record.created = moment(record.created, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("YYYY-MM-DD HH:mm:ss");
        });


        const fileName = `${moduleNameUPPER}_` + moment().format("DDMMYYYY_HHmmss");

        exportToCSV(fileName, dataToExport);
    };

    disableAllFields = () => {

        this.setState({
            fieldState: {
                'id': true,
                'state': true,
                'distributor': true,
                'res_sme': true,
                'retailer': true,
                'tariff_type': true,
                'tariff_class': true,
                'rate_name': true,
                'rate_tier_period': true,
                'stp_period': true,
                'peak_tier_1': true,
                'peak_tier_2': true,
                'peak_tier_3': true,
                'peak_tier_4': true,
                'controlled_load_tier_1': true,
                'peak_rate_1': true,
                'peak_rate_2': true,
                'peak_rate_3': true,
                'peak_rate_4': true,
                'peak_rate_5': true,
                'shoulder_rate': true,
                'controlled_load_1_rate_1': true,
                'controlled_load_1_rate_2': true,
                'controlled_load_2_rate': true,
                'off_peak_rate': true,
                'climate_saver_rate': true,
                'single_capacity_charge': true,
                'summer_non_summer_charge': true,
                'stp': true,
                'status': true,
                'rate_start': true,
                'rate_expire': true,
                'gst_rates': true,
                'controlled_load_1_stp': true,
                'controlled_load_2_stp': true,
                'solar_meter_charge': true,
                'created': true,
                'updated': true,
            }
        })
    };

    enableAllFields = () => {

        this.setState({
            fieldState: {
                'id': false,
                'state': false,
                'distributor': false,
                'res_sme': false,
                'retailer': false,
                'tariff_type': false,
                'tariff_class': false,
                'rate_name': false,
                'rate_tier_period': false,
                'stp_period': false,
                'peak_tier_1': false,
                'peak_tier_2': false,
                'peak_tier_3': false,
                'peak_tier_4': false,
                'controlled_load_tier_1': false,
                'peak_rate_1': false,
                'peak_rate_2': false,
                'peak_rate_3': false,
                'peak_rate_4': false,
                'peak_rate_5': false,
                'shoulder_rate': false,
                'controlled_load_1_rate_1': false,
                'controlled_load_1_rate_2': false,
                'controlled_load_2_rate': false,
                'off_peak_rate': false,
                'climate_saver_rate': false,
                'single_capacity_charge': false,
                'summer_non_summer_charge': false,
                'stp': false,
                'status': false,
                'rate_start': false,
                'rate_expire': false,
                'gst_rates': false,
                'controlled_load_1_stp': false,
                'controlled_load_2_stp': false,
                'solar_meter_charge': false,
                'created': false,
                'updated': false,
            }
        })
    };

    duplicateRecord(record) {

        this.enableAllFields();

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    }

    editRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.enableAllFields();

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Update",
            fieldState: {
                ...({ created: true })
            }
        });
    }

    viewRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "View",
        });
        this.disableAllFields();
    }

    confirmDeleteRecord = (record) => {
        this.setState({ recordtoDelete: record.id });
        this.setState({
            showWarning: true,
            warningMessage: `Are you sure you want delete record ID ${record.id}?`,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    onCancelDelete = () => {
        this.setState({ recordtoDelete: null });
        this.setState({
            showWarning: false,
            warningMessage: ``,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    handleDelete = () => {
        this.state.dataSource = [];

        this.props.deleteRecord(this.state.recordtoDelete);
        console.log("Delete Record");
    };

    onConfirm = () => {
        this.setState({
            showSuccess: false,
            showError: false,
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleAdd = () => {
        this.handleReset();
        this.enableAllFields();
        this.setState({
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    };

    removeSearchValuesFromPayload = (values) => {

        delete values.txtStateSearch;
        delete values.txtResSmeSearch;
        delete values.txtRetailerSearch;

        return values;
    };

    handleAddRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (!err) {
                values = this.removeSearchValuesFromPayload(values);

                values.created = moment(values.created, "DD-MM-YYYY").format("DD/MM/YYYY");

                if (values.created == "Invalid date")
                    values.created = "0000-00-00";

                values.updated = moment(values.updated, "DD-MM-YYYY").format("DD/MM/YYYY");

                if (values.updated == "Invalid date")
                    values.updated = "0000-00-00";

                values.rate_start = moment(values.rate_start, "DD-MM-YYYY").format("DD/MM/YYYY");

                if (values.rate_start == "Invalid date")
                    values.rate_start = "0000-00-00";

                values.rate_expire = moment(values.rate_expire, "DD-MM-YYYY").format("DD/MM/YYYY");

                if (values.rate_expire == "Invalid date")
                    values.rate_expire = "0000-00-00";

                this.setState({
                    isAddingUpdating: true,
                    showSearchPanel: false,
                });
                this.state.dataSource = [];
                this.props.createRecord(values);
            }


        });
    };

    handleUpdateRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            delete values.created;

            values.id = this.state.singleRecordDetails.id;

            values.created = moment(values.created, "DD-MM-YYYY").format("DD/MM/YYYY");

            if (values.created == "Invalid date")
                values.created = "0000-00-00";

            values.updated = moment(values.updated, "DD-MM-YYYY").format("DD/MM/YYYY");

            if (values.updated == "Invalid date")
                values.updated = "0000-00-00";

            values.rate_start = moment(values.rate_start, "DD-MM-YYYY").format("DD/MM/YYYY");

            if (values.rate_start == "Invalid date")
                values.rate_start = "0000-00-00";

            values.rate_expire = moment(values.rate_expire, "DD-MM-YYYY").format("DD/MM/YYYY");

            if (values.rate_expire == "Invalid date")
                values.rate_expire = "0000-00-00";

            values = this.removeSearchValuesFromPayload(values);

            this.setState({
                isAddingUpdating: true,
                showSearchPanel: false,
            });
            this.state.dataSource = [];
            this.props.updateSingleRecord(values);
        });
    };

    onAddUpdateCancel = () => {
        this.setState({
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleClearSearch = () => {
        this.handleReset();
    };

    componentDidMount() {
        this.props.getRetailerList();
        this.props.getDistributorList();
        this.props.getUniqueTariffs();
        this.props.getRecords();
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log(values);

            const filtersForSearch = {
                ...(values.txtRetailerSearch != "-1" && { retailer: values.txtRetailerSearch }),
                ...(values.txtStateSearch != "-1" && { state: values.txtStateSearch }),
                ...(values.txtResSmeSearch != "-1" && { res_sme: values.txtResSmeSearch }),
                ...(values.txtDistributorSearch != "-1" && { distributor: values.txtDistributorSearch }),
                ...(values.txtTariffTypeSearch != "-1" && { tariff_type: values.txtTariffTypeSearch }),
            }
            this.setState({ isSearching: true });

            if (!isEmpty(filtersForSearch))
                this.props.searchRecords(filtersForSearch);
            else {
                this.props.getRecords();
                this.setState({ isSearching: false });
            }
        });
    };

    handleReset = () => {
        this.props.form.resetFields();

        this.setState({
            singleRecordDetails: null,
            showSearchPanel: true,
            showAddEditPanel: false,
        });
        this.setState({ recordtoEdit: null });

        this.props.getRetailerList();
        this.props.getDistributorList();
        this.props.getRecords();
    };

    componentWillReceiveProps(nextProps) {
        if (!isEmpty(nextProps.elecRateRecords)) {
            this.setState({ loading: true });

            this.state.dataSource = nextProps.elecRateRecords;

            this.setState({
                loading: false,
                isSearching: false
            });
        }
        else {
            this.setState({ loading: true });

            this.state.dataSource = [];

            this.setState({
                loading: false,
                isSearching: false
            });
        }

        if (!isEmpty(nextProps.recordUpdateStatus)) {
            if (nextProps.recordUpdateStatus.status == "Success") {
                this.setState({
                    successMessage: nextProps.recordUpdateStatus.message,
                    showSuccess: true,
                    showWarning: false,
                    warningMessage: "",
                });
                this.setState({
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                swal.close();
                console.log("New Update Request");
                this.props.getRecords();
            }
        }

        if (!isEmpty(nextProps.error)) {
            if (nextProps.error != "") {
                this.setState({
                    errorMessage: nextProps.error,
                    showError: true,
                    successMessage: "",
                    showSuccess: false,
                });
                this.setState({
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                console.log("Error");
                this.props.clearError();
                this.props.getRecords();
            }
        }
    }


    render() {

        let { dataSource } = this.state;
        const { fileList } = this.state;

        const uploadProps = {
            name: 'file',
            accept: '.csv',
            multiple: false,
            showUploadList: false,
            onChange: (this.handleImportFromCSV),
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        const uploadPropsWipe = {
            name: 'file',
            accept: '.csv',
            multiple: false,
            showUploadList: false,
            onChange: (this.handleWipeImportFromCSV),
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-main-content">
                <ContainerHeader title={`Manage ${moduleName}`} />
                <Row>
                    {this.state.showSearchPanel && <Col span={24}>
                        <Spin spinning={this.state.isSearching}>
                            <Card className="gx-card" title={`Search ${moduleName} Records`} style={{ maxHeight: "550px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                                >
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="State" >
                                                    {getFieldDecorator('txtStateSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select State"
                                                        >
                                                            <Option value="-1">All States</Option>
                                                            <Option value="Victoria">Victoria</Option>
                                                            <Option value="New South Wales">New South Wales</Option>
                                                            <Option value="Australian Capital Territory">Australian Capital Territory</Option>
                                                            <Option value="Queensland">Queensland</Option>
                                                            <Option value="South Australia">South Australia</Option>
                                                            <Option value="Western Australia">Western Australia</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="RES/SME" >
                                                    {getFieldDecorator('txtResSmeSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Options"
                                                        >
                                                            <Option value="-1">All Options</Option>
                                                            <Option value="RES">RES</Option>
                                                            <Option value="SME">SME</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Retailer" >
                                                    {getFieldDecorator('txtRetailerSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select a Retailer"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option value="-1">All Retailers</Option>
                                                            {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                                                return <option key={key} value={retailer.systemName}>{retailer.systemName}</option>;
                                                            })}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Distributor" >
                                                    {getFieldDecorator('txtDistributorSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select a Distributor"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option value="-1">All Distributors</Option>
                                                            {!isEmpty(this.props.distributorList) && this.props.distributorList.map((distributor, key) => {
                                                                return <option key={key} value={distributor.distributorName}>{distributor.distributorName}</option>;
                                                            })}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Tariff Type" >
                                                    {getFieldDecorator('txtTariffTypeSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select a Tariff Type"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            <Option value="-1">All Tariff Types</Option>
                                                            {!isEmpty(this.props.tariffList) && this.props.tariffList.map((tariff, key) => {
                                                                return <option key={key} value={tariff.pricing_group}>{tariff.pricing_group}</option>;
                                                            })}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            <Button type="primary" onClick={this.handleSearch}>
                                                Search
                      </Button>
                                            <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                                                Clear
                      </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    {this.state.showAddEditPanel && <Col span={24}>
                        <Spin spinning={this.state.isAddingUpdating}>
                            <Card className="gx-card" title={`${this.state.formFunction} ${moduleName} Details`} style={{ maxHeight: "650px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "500px", marginBottom: "20px" }}
                                >
                                    <Tabs type="card" defaultActiveKey={['1']}>
                                        <TabPane tab="Basic Details" key="1" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
                                            {this.state.formFunction != "Add" &&
                                                <Row>
                                                    <Col lg={8} md={8} sm={12} xs={24}>
                                                        <div className="gx-form-row0">
                                                            <FormItem {...formItemLayout2} label="Created Date" >
                                                                {getFieldDecorator('created', {
                                                                    initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.created, "YYYY-MM-DD HH:mm:ss") : moment(),
                                                                    rules: [{ required: true, message: 'Please select Created Date!' }],
                                                                })(
                                                                    <DatePicker disabled={this.state.fieldState.created} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'} />
                                                                )}
                                                            </FormItem>
                                                        </div>
                                                    </Col>
                                                    <Col lg={8} md={8} sm={12} xs={24}>
                                                        <div className="gx-form-row0">
                                                            <FormItem {...formItemLayout2} label="Created Date" >
                                                                {getFieldDecorator('created', {
                                                                    initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.created, "YYYY-MM-DD HH:mm:ss") : moment(),
                                                                    rules: [{ required: true, message: 'Please select Created Date!' }],
                                                                })(
                                                                    <DatePicker disabled={this.state.fieldState.created} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'} />
                                                                )}
                                                            </FormItem>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Rate Start Date" >
                                                            {getFieldDecorator('rate_start', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.rate_start, "YYYY-MM-DD HH:mm:ss") : moment(),
                                                                rules: [{ required: false, message: 'Please select Rate Start Date!' }],
                                                            })(
                                                                <DatePicker disabled={this.state.fieldState.rate_start} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'} />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Rate Expiry Date" >
                                                            {getFieldDecorator('rate_expire', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.rate_expire, "YYYY-MM-DD HH:mm:ss") : moment(),
                                                                rules: [{ required: false, message: 'Please select Rate Expiry Date!' }],
                                                            })(
                                                                <DatePicker disabled={this.state.fieldState.rate_expire} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'} />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Status" >
                                                            {getFieldDecorator('status', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.status : undefined,
                                                                rules: [{ required: true, message: 'Please select Option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.status}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Active">Active</Option>
                                                                    <Option key="Inactive">Inactive</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="GST Rates" >
                                                            {getFieldDecorator('gst_rates', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.gst_rates : undefined,
                                                                rules: [{ required: true, message: 'Please select Option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.gst_rates}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Retailer" >
                                                            {getFieldDecorator('retailer', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.retailer : undefined,
                                                                rules: [{ required: true, message: 'Please select a Retailer!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.retailer}
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Retailer"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                >
                                                                    {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                                                        return <option key={key} value={retailer.systemName}>{retailer.systemName}</option>;
                                                                    })}
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="State" >
                                                            {getFieldDecorator('state', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.state : undefined,
                                                                rules: [{ required: true, message: 'Please select a State!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.state}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select State"
                                                                >
                                                                    <Option value="Victoria">Victoria</Option>
                                                                    <Option value="New South Wales">New South Wales</Option>
                                                                    <Option value="Australian Capital Territory">Australian Capital Territory</Option>
                                                                    <Option value="Queensland">Queensland</Option>
                                                                    <Option value="South Australia">South Australia</Option>
                                                                    <Option value="Western Australia">Western Australia</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="RES/SME" >
                                                            {getFieldDecorator('res_sme', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.res_sme : undefined,
                                                                rules: [{ required: true, message: 'Please select either RES or SME!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.res_sme}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="RES">RES</Option>
                                                                    <Option key="SME">SME</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Distributor" >
                                                            {getFieldDecorator('distributor', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.distributor : "",
                                                                rules: [{ required: true, message: '' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.distributor}
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Distributor"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                >
                                                                    {!isEmpty(this.props.distributorList) && this.props.distributorList.map((distributor, key) => {
                                                                        return <option key={key} value={distributor.distributorName}>{distributor.distributorName}</option>;
                                                                    })}
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Tariff Type" >
                                                            {getFieldDecorator('tariff_type', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.tariff_type : "",
                                                                rules: [{ required: true, message: '' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.tariff_type}
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Tariff Type"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                >
                                                                    {!isEmpty(this.props.tariffList) && this.props.tariffList.map((tariff, key) => {
                                                                        return <option key={key} value={tariff.pricing_group}>{tariff.pricing_group}</option>;
                                                                    })}
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Tariff Class" >
                                                            {getFieldDecorator('tariff_class', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.tariff_class : "",
                                                                rules: [{ required: true, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.tariff_class} style={{ width: '100%' }} placeholder="Enter Tariff Class" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tab="Basic Rate Details" key="2" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Rate Name" >
                                                            {getFieldDecorator('rate_name', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.rate_name : "",
                                                                rules: [{ required: true, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.rate_name} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Rate Tier Period" >
                                                            {getFieldDecorator('rate_tier_period', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.rate_tier_period : "",
                                                                rules: [{ required: true, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.rate_tier_period} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="STP Period" >
                                                            {getFieldDecorator('stp_period', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.stp_period : "",
                                                                rules: [{ required: true, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.stp_period} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Daily Supply Charge" >
                                                            {getFieldDecorator('stp', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.stp : "",
                                                                rules: [{ required: true, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.stp} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Controlled Load 1 STP" >
                                                            {getFieldDecorator('controlled_load_1_stp', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.controlled_load_1_stp : "",
                                                                rules: [{ required: true, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.controlled_load_1_stp} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Controlled Load 2 STP" >
                                                            {getFieldDecorator('controlled_load_2_stp', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.controlled_load_2_stp : "",
                                                                rules: [{ required: true, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.controlled_load_2_stp} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Solar Meter Charge" >
                                                            {getFieldDecorator('solar_meter_charge', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.solar_meter_charge : "",
                                                                rules: [{ required: true, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.solar_meter_charge} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tab="Peak Details" key="3" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Peak Rate 1" >
                                                            {getFieldDecorator('peak_rate_1', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.peak_rate_1 : "",
                                                                rules: [{ required: true, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.peak_rate_1} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Peak Tier 1" >
                                                            {getFieldDecorator('peak_tier_1', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.peak_tier_1 : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.peak_tier_1} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Peak Rate 2" >
                                                            {getFieldDecorator('peak_rate_2', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.peak_rate_2 : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.peak_rate_2} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Peak Tier 2" >
                                                            {getFieldDecorator('peak_tier_2', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.peak_tier_2 : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.peak_tier_2} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Peak Rate 3" >
                                                            {getFieldDecorator('peak_rate_3', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.peak_rate_3 : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.peak_rate_3} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Peak Tier 3" >
                                                            {getFieldDecorator('peak_tier_3', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.peak_tier_3 : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.peak_tier_3} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Peak Rate 4" >
                                                            {getFieldDecorator('peak_rate_4', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.peak_rate_4 : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.peak_rate_4} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Peak Tier 4" >
                                                            {getFieldDecorator('peak_tier_4', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.peak_tier_4 : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.peak_tier_4} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Peak Rate 5" >
                                                            {getFieldDecorator('peak_rate_5', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.peak_rate_5 : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.peak_rate_5} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tab="Controlled Load Details" key="4" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Controlled Load 1 Rate 1" >
                                                            {getFieldDecorator('controlled_load_1_rate_1', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.controlled_load_1_rate_1 : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.controlled_load_1_rate_1} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Controlled Load 1 Rate 2" >
                                                            {getFieldDecorator('controlled_load_1_rate_2', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.controlled_load_1_rate_2 : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.controlled_load_1_rate_2} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Controlled Load Tier 1" >
                                                            {getFieldDecorator('controlled_load_tier_1', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.controlled_load_tier_1 : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.controlled_load_tier_1} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Controlled Load 2 Rate" >
                                                            {getFieldDecorator('controlled_load_2_rate', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.controlled_load_2_rate : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.controlled_load_2_rate} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tab="Offpeak and Shoulder Details" key="5" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Offpeak Rate" >
                                                            {getFieldDecorator('off_peak_rate', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.off_peak_rate : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.off_peak_rate} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Shoulder Rate" >
                                                            {getFieldDecorator('shoulder_rate', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.shoulder_rate : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.shoulder_rate} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tab="Other Charge Details" key="6" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Climate Saver Rate" >
                                                            {getFieldDecorator('climate_saver_rate', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.climate_saver_rate : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.climate_saver_rate} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Single Capacity Charge" >
                                                            {getFieldDecorator('single_capacity_charge', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.single_capacity_charge : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.single_capacity_charge} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Summer Non Summer Charge" >
                                                            {getFieldDecorator('summer_non_summer_charge', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.summer_non_summer_charge : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.summer_non_summer_charge} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </Tabs>
                                    {/* <Collapse accordion defaultActiveKey={['1']}>
                                        <Panel header="Basic Details" key="1">
                                            
                                        </Panel>
                                        <Panel header="Basic Rate Details" key="2">
                                            
                                        </Panel>
                                        <Panel header="Peak Details" key="3">
                                            
                                        </Panel>
                                        <Panel header="Controlled Load Details" key="4">
                                            
                                        </Panel>
                                        <Panel header="Offpeak and Shoulder Details" key="5">
                                            
                                        </Panel>
                                        <Panel header="Other Charge Details" key="6">
                                            
                                        </Panel>
                                    </Collapse> */}

                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            {this.state.formFunction == "Add" &&
                                                <Button type="primary" onClick={this.handleAddRecord}>
                                                    Add
                        </Button>
                                            }
                                            {this.state.formFunction == "Update" &&
                                                <Button type="primary" onClick={this.handleUpdateRecord}>
                                                    Update
                        </Button>
                                            }
                                            <Button style={{ marginLeft: 8 }} onClick={this.onAddUpdateCancel}>
                                                Cancel
                      </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    <Col span={24}>
                        <Card className="gx-card" title={`${moduleName} Details`}>
                            <Button onClick={this.handleAdd} type="primary" className="gx-btn-cyan" style={{ marginBottom: 16 }}>
                                <Icon type="plus" />  Add New {moduleName} Details
                            </Button>
                            <Button onClick={this.handleExportToCSV} type="primary" style={{ marginBottom: 16, marginRight: 0, float: "right" }}>
                                <Icon type="download" /> Export Displayed Results
                            </Button>
                            <Upload {...uploadProps}>
                                <Button type="primary">
                                    <Icon type="upload" /> Add/Update by Uploading a CSV
                                </Button>
                            </Upload>
                            <Upload {...uploadPropsWipe}>
                                <Button type="danger" style={{ marginLeft: "10px" }}>
                                    <Icon type="upload" /> Wipe and Add by Uploading a CSV
                                </Button>
                            </Upload>
                            <Table
                                className="gx-table-responsive"
                                {...this.state}
                                pagination={{ pageSize: 10 }}
                                columns={columns}
                                dataSource={dataSource} />
                        </Card>
                    </Col>


                </Row>
                <SweetAlert show={this.state.showWaiting}
                    title={'Please wait..'}
                    buttons={false}
                />
                <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showError} error title={this.state.errorMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showWarning}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={this.state.warningMessage}
                    onConfirm={this.handleDelete}
                    onCancel={this.onCancelDelete}
                ></SweetAlert>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    retailerList: state.retailers.retailerList,
    distributorList: state.distributors.distributorList,
    tariffList: state.tariff.tariffList,

    elecRateRecords: state.elecRates.resultList,
    recordUpdateStatus: state.elecRates.recordUpdateStatus,
    error: state.elecRates.error,
});

const mapDispatchToProps = dispath => ({

    getRetailerList: (data) => dispath(getRetailerList(data)),
    getDistributorList: (data) => dispath(getDistributorList(data)),
    getUniqueTariffs: (data) => dispath(getUniqueTariffs(data)),

    getRecords: () => dispath(getRecords()),
    searchRecords: (data) => dispath(searchRecords(data)),
    updateSingleRecord: (data) => dispath(updateSingleRecord(data)),
    createRecord: (data) => dispath(createRecord(data)),
    deleteRecord: (data) => dispath(deleteRecord(data)),
    uploadRecords: (data) => dispath(uploadRecords(data)),
    wipeAndUploadRecords: (data) => dispath(wipeAndUploadRecords(data)),

    clearError: () => dispath(clearError()),

});

const WrappedAdvancedSearch = Form.create()(ManagePDFs);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);