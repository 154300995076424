import React from "react";
import { connect } from "react-redux";
import { Breadcrumb, Divider, Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, InputNumber, DatePicker, Tag, Collapse, Upload, Icon, Tabs } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getRecords, searchRecords, updateSingleRecord, createRecord, deleteRecord, uploadRecords, wipeAndUploadRecords, clearError } from "appRedux/actions/admin/plans";
import { getRetailerList } from "appRedux/actions/admin/retailers";
// import "./index.css";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import exportToCSV from 'util/exportToCSV'
import swal from 'sweetalert2';

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const { TextArea } = Input;
const { TabPane } = Tabs;

const moduleName = "Plan"
const moduleNameUPPER = "PLANS"

const showHeader = true;
const pagination = { position: 'bottom' };

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

class ManageConsumptions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editingKey: '',
            recordtoEdit: null,
            recordtoDelete: null,
            formFunction: null,
            isAddingUpdating: false,
            isSearching: false,
            recordsEdited: false,
            expand: false,
            dataSource: [],
            addingNewRecord: false,
            showAddEditPanel: false,
            showSearchPanel: true,
            showWarning: false,
            warningMessage: "",
            showSuccess: false,
            successMessage: "",
            showError: false,
            errorMessage: "",
            showWaiting: false,
            showWaitingOkButton: false,
            bordered: true,
            loading: false,
            pagination,
            size: 'middle',
            title: undefined,
            showHeader,
            footer: undefined,
            rowSelection: {},
            singleRecordDetails: {},
            fileList: [],
            fieldState: {
                'id': false,
                'state': false,
                'retailer': false,
                'res_sme': false,
                'package': false,
                'product_name': false,
                'rate_name': false,
                'acq_ret': false,
                'pdf': false,
                'discount_bonuses_description': false,
                'discount_guaranteed_elec': false,
                'discount_guaranteed_gas': false,
                'discount_guaranteed_description': false,
                'discount_direct_debit_elec': false,
                'discount_direct_debit_gas': false,
                'discount_direct_debit_description': false,
                'discount_pay_on_time_elec': false,
                'discount_pay_on_time_gas': false,
                'discount_pay_on_time_description': false,
                'discount_credit_elec': false,
                'discount_credit_gas': false,
                'discount_credit_description': false,
                'discount_dual_fuel_elec': false,
                'discount_dual_fuel_gas': false,
                'discount_dual_fuel_description': false,
                'discount_prepay_elec': false,
                'discount_bonus_sumo': false,
                'discount_applies': false,
                'discount_applies_gas': false,
                'plan_eligibility': false,
                'special_offer': false,
                'product_summary': false,
                'signup_summary': false,
                'terms': false,
                'benefit1_title': false,
                'benefit1_description': false,
                'benefit2_title': false,
                'benefit2_description': false,
                'benefit3_title': false,
                'benefit3_description': false,
                'benefit4_title': false,
                'benefit4_description': false,
                'unique_benefit': false,
                'short_description': false,
                'full_description': false,
                'contract_length': false,
                'exit_fee': false,
                'conditional_discount': false,
                'bill_smoothing': false,
                'bill_smoothing_details': false,
                'online_account_management': false,
                'online_account_management_details': false,
                'energy_monitoring_tools': false,
                'energy_monitoring_tools_details': false,
                'membership_reward_programs': false,
                'membership_reward_programs_details': false,
                'renewable_energy': false,
                'renewable_energy_details': false,
                'rate_freeze': false,
                'rate_freeze_details': false,
                'pay_on_time': false,
                'no_contract_plan': false,
                'no_contract_plan_details': false,
                'solar_specific_plan': false,
                'total_bill_discount': false,
                'paper_billing': false,
                'e_bill': false,
                'billing_period': false,
                'late_payment_fee': false,
                'dishonoured_payment_fee': false,
                'card_payment_fee': false,
                'other_fees': false,
                'solar_metering_charge': false,
                'direct_debit': false,
                'bpay': false,
                'credit_card': false,
                'easipay': false,
                'online': false,
                'centrepay': false,
                'cash': false,
                'cheque': false,
                'post_billpay': false,
                'pay_by_phone': false,
                'amex': false,
                'new_connection': false,
                'plan_usage': false,
                'product_code_elec': false,
                'product_code_gas': false,
                'campaign_code_elec': false,
                'campaign_code_gas': false,
                'elec_product': false,
                'gas_product': false,
                'offshore_lead_generation': false,
                'version': false,
                'sd': false,
                'status': false,
                'plan_start': false,
                'plan_expiry': false,
                'solar_boost_fit': false,
                'solar_boost_cap': false,
                'solar_inverter_limit': false,
                'patch_plan': false,
                'cms_tools_version': false,
                'discount_type': false,
                'life_support': false,
                'payment_methods': false,
                'created': false,
                'updated': false,
            }
        };
        this.columns = [
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: 150,
                render: (text, record) => {
                    const { editingKey } = this.state;
                    const editable = this.isEditing(record);
                    return (
                        <span>
                            <center>
                                <a onClick={() => this.viewRecord(record)}>
                                    <i className="icon icon-search-new" />
                                </a>
                                <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.editRecord(record)}>
                                    <i className="icon icon-edit" />
                                </a>
                                <a style={{ marginLeft: 10, color: "#52c41a" }} disabled={editingKey !== ''} onClick={() => this.duplicateRecord(record)}>
                                    <i className="icon icon-copy" />
                                </a>
                                <a style={{ marginLeft: 10, color: "#f5222d" }} disabled={editingKey !== ''} onClick={() => this.confirmDeleteRecord(record)}>
                                    <i className="icon icon-close-circle" />
                                </a>
                            </center>
                        </span>
                    );
                },
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                width: 150,
                sorter: (a, b) => a.id - b.id,
            },
            {
                title: 'Created Date',
                dataIndex: 'created',
                key: 'created',
                align: 'center',
                width: 150,
                sorter: (a, b) => moment(a.created, "YYYY-MM-DDTHH:mm:ss.SSSZ") - moment(b.created, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
                render: (text, record) => {
                    return <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"geekblue"} key={text}>
                        {moment(text).format('YYYY-MM-DD h:mm:ss A')}
                    </Tag>
                }
            },
            {
                title: 'Updated Date',
                dataIndex: 'updated',
                key: 'updated',
                align: 'center',
                width: 150,
                sorter: (a, b) => moment(a.updated, "YYYY-MM-DDTHH:mm:ss.SSSZ") - moment(b.updated, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
                render: (text, record) => {
                    return <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"geekblue"} key={text}>
                        {moment(text).format('YYYY-MM-DD h:mm:ss A')}
                    </Tag>
                }
            },
            {
                title: 'State',
                dataIndex: 'state',
                key: 'state',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.state.localeCompare(b.state) },
            },
            {
                title: 'Retailer',
                dataIndex: 'retailer',
                key: 'retailer',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.retailer.localeCompare(b.retailer) },
            },
            {
                title: 'RES/SME',
                dataIndex: 'res_sme',
                key: 'res_sme',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.res_sme.localeCompare(b.res_sme) },
            },
            {
                title: 'Package',
                dataIndex: 'package',
                key: 'package',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.package.localeCompare(b.package) },
            },
            {
                title: 'Product Name',
                dataIndex: 'product_name',
                key: 'product_name',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.product_name.localeCompare(b.product_name) },
            },
            {
                title: 'Rate Name',
                dataIndex: 'rate_name',
                key: 'rate_name',
                align: 'center',
                width: 150,
                editable: true,
                sorter: (a, b) => { return a.rate_name.localeCompare(b.rate_name) },
            },
        ];

    }

    isEditing = record => record.key === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    handleImportFromCSV = (info) => {

        // this.setState({
        //     showWaiting: true,
        // })

        swal.fire({
            title: "Please Wait..",
            text: `System is currently uploading and processing your file - ${info.file.name}`,
            showConfirmButton: this.state.showWaitingOkButton,
        });

        const data = new FormData();

        data.append('file', info.file);

        this.props.uploadRecords(data);

        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            console.log(`${info.file.name} file upload failed.`);
        }


    };

    handleWipeImportFromCSV = (info) => {

        swal.fire({
            title: "Please Wait..",
            text: `System is currently uploading and processing your file - ${info.file.name}`,
            showConfirmButton: this.state.showWaitingOkButton,
        });

        const data = new FormData();

        data.append('file', info.file);

        this.props.wipeAndUploadRecords(data);

        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            console.log(`${info.file.name} file upload failed.`);
        }


    };

    handleExportToCSV = (e) => {

        var dataToExport = this.state.dataSource;

        dataToExport.map((record, key) => {
            record.created = moment(record.created, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("YYYY-MM-DD HH:mm:ss");
        });


        const fileName = `${moduleNameUPPER}_` + moment().format("DDMMYYYY_HHmmss");

        exportToCSV(fileName, dataToExport);
    };

    disableAllFields = () => {

        this.setState({
            fieldState: {
                'id': true,
                'state': true,
                'retailer': true,
                'res_sme': true,
                'package': true,
                'product_name': true,
                'rate_name': true,
                'acq_ret': true,
                'pdf': true,
                'discount_bonuses_description': true,
                'discount_guaranteed_elec': true,
                'discount_guaranteed_gas': true,
                'discount_guaranteed_description': true,
                'discount_direct_debit_elec': true,
                'discount_direct_debit_gas': true,
                'discount_direct_debit_description': true,
                'discount_pay_on_time_elec': true,
                'discount_pay_on_time_gas': true,
                'discount_pay_on_time_description': true,
                'discount_credit_elec': true,
                'discount_credit_gas': true,
                'discount_credit_description': true,
                'discount_dual_fuel_elec': true,
                'discount_dual_fuel_gas': true,
                'discount_dual_fuel_description': true,
                'discount_prepay_elec': true,
                'discount_bonus_sumo': true,
                'discount_applies': true,
                'discount_applies_gas': true,
                'plan_eligibility': true,
                'special_offer': true,
                'product_summary': true,
                'signup_summary': true,
                'terms': true,
                'benefit1_title': true,
                'benefit1_description': true,
                'benefit2_title': true,
                'benefit2_description': true,
                'benefit3_title': true,
                'benefit3_description': true,
                'benefit4_title': true,
                'benefit4_description': true,
                'unique_benefit': true,
                'short_description': true,
                'full_description': true,
                'contract_length': true,
                'exit_fee': true,
                'conditional_discount': true,
                'bill_smoothing': true,
                'bill_smoothing_details': true,
                'online_account_management': true,
                'online_account_management_details': true,
                'energy_monitoring_tools': true,
                'energy_monitoring_tools_details': true,
                'membership_reward_programs': true,
                'membership_reward_programs_details': true,
                'renewable_energy': true,
                'renewable_energy_details': true,
                'rate_freeze': true,
                'rate_freeze_details': true,
                'pay_on_time': true,
                'no_contract_plan': true,
                'no_contract_plan_details': true,
                'solar_specific_plan': true,
                'total_bill_discount': true,
                'paper_billing': true,
                'e_bill': true,
                'billing_period': true,
                'late_payment_fee': true,
                'dishonoured_payment_fee': true,
                'card_payment_fee': true,
                'other_fees': true,
                'solar_metering_charge': true,
                'direct_debit': true,
                'bpay': true,
                'credit_card': true,
                'easipay': true,
                'online': true,
                'centrepay': true,
                'cash': true,
                'cheque': true,
                'post_billpay': true,
                'pay_by_phone': true,
                'amex': true,
                'new_connection': true,
                'plan_usage': true,
                'product_code_elec': true,
                'product_code_gas': true,
                'campaign_code_elec': true,
                'campaign_code_gas': true,
                'elec_product': true,
                'gas_product': true,
                'offshore_lead_generation': true,
                'version': true,
                'sd': true,
                'status': true,
                'plan_start': true,
                'plan_expiry': true,
                'solar_boost_fit': true,
                'solar_boost_cap': true,
                'solar_inverter_limit': true,
                'patch_plan': true,
                'cms_tools_version': true,
                'discount_type': true,
                'life_support': true,
                'payment_methods': true,
                'created': true,
                'updated': true,
            }
        })
    };

    enableAllFields = () => {

        this.setState({
            fieldState: {
                'id': false,
                'state': false,
                'retailer': false,
                'res_sme': false,
                'package': false,
                'product_name': false,
                'rate_name': false,
                'acq_ret': false,
                'pdf': false,
                'discount_bonuses_description': false,
                'discount_guaranteed_elec': false,
                'discount_guaranteed_gas': false,
                'discount_guaranteed_description': false,
                'discount_direct_debit_elec': false,
                'discount_direct_debit_gas': false,
                'discount_direct_debit_description': false,
                'discount_pay_on_time_elec': false,
                'discount_pay_on_time_gas': false,
                'discount_pay_on_time_description': false,
                'discount_credit_elec': false,
                'discount_credit_gas': false,
                'discount_credit_description': false,
                'discount_dual_fuel_elec': false,
                'discount_dual_fuel_gas': false,
                'discount_dual_fuel_description': false,
                'discount_prepay_elec': false,
                'discount_bonus_sumo': false,
                'discount_applies': false,
                'discount_applies_gas': false,
                'plan_eligibility': false,
                'special_offer': false,
                'product_summary': false,
                'signup_summary': false,
                'terms': false,
                'benefit1_title': false,
                'benefit1_description': false,
                'benefit2_title': false,
                'benefit2_description': false,
                'benefit3_title': false,
                'benefit3_description': false,
                'benefit4_title': false,
                'benefit4_description': false,
                'unique_benefit': false,
                'short_description': false,
                'full_description': false,
                'contract_length': false,
                'exit_fee': false,
                'conditional_discount': false,
                'bill_smoothing': false,
                'bill_smoothing_details': false,
                'online_account_management': false,
                'online_account_management_details': false,
                'energy_monitoring_tools': false,
                'energy_monitoring_tools_details': false,
                'membership_reward_programs': false,
                'membership_reward_programs_details': false,
                'renewable_energy': false,
                'renewable_energy_details': false,
                'rate_freeze': false,
                'rate_freeze_details': false,
                'pay_on_time': false,
                'no_contract_plan': false,
                'no_contract_plan_details': false,
                'solar_specific_plan': false,
                'total_bill_discount': false,
                'paper_billing': false,
                'e_bill': false,
                'billing_period': false,
                'late_payment_fee': false,
                'dishonoured_payment_fee': false,
                'card_payment_fee': false,
                'other_fees': false,
                'solar_metering_charge': false,
                'direct_debit': false,
                'bpay': false,
                'credit_card': false,
                'easipay': false,
                'online': false,
                'centrepay': false,
                'cash': false,
                'cheque': false,
                'post_billpay': false,
                'pay_by_phone': false,
                'amex': false,
                'new_connection': false,
                'plan_usage': false,
                'product_code_elec': false,
                'product_code_gas': false,
                'campaign_code_elec': false,
                'campaign_code_gas': false,
                'elec_product': false,
                'gas_product': false,
                'offshore_lead_generation': false,
                'version': false,
                'sd': false,
                'status': false,
                'plan_start': false,
                'plan_expiry': false,
                'solar_boost_fit': false,
                'solar_boost_cap': false,
                'solar_inverter_limit': false,
                'patch_plan': false,
                'cms_tools_version': false,
                'discount_type': false,
                'life_support': false,
                'payment_methods': false,
                'created': false,
                'updated': false,
            }
        })
    };

    duplicateRecord(record) {

        this.enableAllFields();

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    }

    editRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.enableAllFields();

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Update",
            fieldState: {
                ...({ created: true })
            }
        });
    }

    viewRecord(record) {
        this.setState({ recordtoEdit: record.key });

        this.setState({
            singleRecordDetails: record,
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "View",
        });
        this.disableAllFields();
    }

    confirmDeleteRecord = (record) => {
        this.setState({ recordtoDelete: record.id });
        this.setState({
            showWarning: true,
            warningMessage: `Are you sure you want delete record ID ${record.id}?`,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    onCancelDelete = () => {
        this.setState({ recordtoDelete: null });
        this.setState({
            showWarning: false,
            warningMessage: ``,
            showAddEditPanel: false,
            showSearchPanel: true,
            formFunction: "",
        });
    };

    handleDelete = () => {
        this.state.dataSource = [];

        this.props.deleteRecord(this.state.recordtoDelete);
        console.log("Delete Record");
    };

    onConfirm = () => {
        this.setState({
            showSuccess: false,
            showError: false,
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleAdd = () => {
        this.handleReset();
        this.enableAllFields();
        this.setState({
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
        });
    };

    removeSearchValuesFromPayload = (values) => {

        delete values.txtStateSearch;
        delete values.txtResSmeSearch;
        delete values.txtStatusSearch;
        delete values.txtPackageSearch;
        delete values.txtRetailerSearch;

        return values;
    };

    handleAddRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            if (!err) {
                values = this.removeSearchValuesFromPayload(values);

                values.created = moment(values.created, "DD-MM-YYYY").format("DD/MM/YYYY");

                if (values.created == "Invalid date")
                    values.created = "0000-00-00";

                values.updated = moment(values.updated, "DD-MM-YYYY").format("DD/MM/YYYY");

                if (values.updated == "Invalid date")
                    values.updated = "0000-00-00";

                values.plan_start = moment(values.plan_start, "DD-MM-YYYY").format("DD/MM/YYYY");

                if (values.plan_start == "Invalid date")
                    values.plan_start = "0000-00-00";

                values.plan_expiry = moment(values.plan_expiry, "DD-MM-YYYY").format("DD/MM/YYYY");

                if (values.plan_expiry == "Invalid date")
                    values.plan_expiry = "0000-00-00";

                this.setState({
                    isAddingUpdating: true,
                    showSearchPanel: false,
                });
                this.state.dataSource = [];
                this.props.createRecord(values);
            }


        });
    };

    handleUpdateRecord = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            delete values.created;
            console.log(this.state.singleRecordDetails)

            values.id = this.state.singleRecordDetails.id;
            console.log(values)

            values.created = moment(values.created, "DD-MM-YYYY").format("DD/MM/YYYY");

            if (values.created == "Invalid date")
                values.created = "0000-00-00";

            values.updated = moment(values.updated, "DD-MM-YYYY").format("DD/MM/YYYY");

            if (values.updated == "Invalid date")
                values.updated = "0000-00-00";

            values.plan_start = moment(values.plan_start, "DD-MM-YYYY").format("DD/MM/YYYY");

            if (values.plan_start == "Invalid date")
                values.plan_start = "0000-00-00";

            values.plan_expiry = moment(values.plan_expiry, "DD-MM-YYYY").format("DD/MM/YYYY");

            if (values.plan_expiry == "Invalid date")
                values.plan_expiry = "0000-00-00";

            values = this.removeSearchValuesFromPayload(values);

            this.setState({
                isAddingUpdating: true,
                showSearchPanel: false,
            });
            this.state.dataSource = [];
            this.props.updateSingleRecord(values);
        });
    };

    onAddUpdateCancel = () => {
        this.setState({
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

    handleClearSearch = () => {
        this.handleReset();
    };

    componentDidMount() {
        this.props.getRetailerList();
        this.props.getRecords();
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log(values);

            const filtersForSearch = {
                ...(values.txtStateSearch != "-1" && { state: values.txtStateSearch }),
                ...(values.txtResSmeSearch != "-1" && { res_sme: values.txtResSmeSearch }),
                ...(values.txtRetailerSearch != "-1" && { retailer: values.txtRetailerSearch }),
                ...(values.txtStatusSearch != "-1" && { status: values.txtStatusSearch }),
                ...(values.txtPackageSearch != "-1" && { package: values.txtPackageSearch }),
            }
            this.setState({ isSearching: true });

            if (!isEmpty(filtersForSearch))
                this.props.searchRecords(filtersForSearch);
            else {
                this.props.getRecords();
                this.setState({ isSearching: false });
            }
        });
    };

    handleReset = () => {
        this.props.form.resetFields();

        this.setState({
            singleRecordDetails: null,
            showSearchPanel: true,
            showAddEditPanel: false,
        });
        this.setState({ recordtoEdit: null });

        this.props.getRecords();
    };

    componentWillReceiveProps(nextProps) {
        if (!isEmpty(nextProps.planRecords)) {
            this.setState({ loading: true });

            this.state.dataSource = nextProps.planRecords;

            this.setState({
                loading: false,
                isSearching: false
            });
        }
        else {
            this.setState({ loading: true });

            this.state.dataSource = [];

            this.setState({
                loading: false,
                isSearching: false
            });
        }

        if (!isEmpty(nextProps.recordUpdateStatus)) {
            if (nextProps.recordUpdateStatus.status == "Success") {
                this.setState({
                    successMessage: nextProps.recordUpdateStatus.message,
                    showSuccess: true,
                    showWarning: false,
                    warningMessage: "",
                });
                this.setState({
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                swal.close();
                console.log("New Update Request");
                this.props.getRecords();
            }
        }

        if (!isEmpty(nextProps.error)) {
            if (nextProps.error != "") {
                this.setState({
                    errorMessage: nextProps.error,
                    showError: true,
                    successMessage: "",
                    showSuccess: false,
                });
                this.setState({
                    isAddingUpdating: false,
                    showAddEditPanel: false,
                    showSearchPanel: true,
                });
                console.log("Error");
                this.props.clearError();
                this.props.getRecords();
            }
        }
    }


    render() {

        let { dataSource } = this.state;
        const { fileList } = this.state;

        const uploadProps = {
            name: 'file',
            accept: '.csv',
            multiple: false,
            showUploadList: false,
            onChange: (this.handleImportFromCSV),
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        const uploadPropsWipe = {
            name: 'file',
            accept: '.csv',
            multiple: false,
            showUploadList: false,
            onChange: (this.handleWipeImportFromCSV),
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-main-content">
                <ContainerHeader title={`Manage ${moduleName}`} />
                <Row>
                    {this.state.showSearchPanel && <Col span={24}>
                        <Spin spinning={this.state.isSearching}>
                            <Card className="gx-card" title={`Search ${moduleName} Records`} style={{ maxHeight: "550px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                                >
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="State" >
                                                    {getFieldDecorator('txtStateSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select State"
                                                        >
                                                            <Option value="-1">All States</Option>
                                                            <Option value="Victoria">Victoria</Option>
                                                            <Option value="New South Wales">New South Wales</Option>
                                                            <Option value="Australian Capital Territory">Australian Capital Territory</Option>
                                                            <Option value="Queensland">Queensland</Option>
                                                            <Option value="South Australia">South Australia</Option>
                                                            <Option value="Western Australia">Western Australia</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="RES/SME" >
                                                    {getFieldDecorator('txtResSmeSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Options"
                                                        >
                                                            <Option value="-1">All Options</Option>
                                                            <Option value="RES">RES</Option>
                                                            <Option value="SME">SME</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Retailer" >
                                                    {getFieldDecorator('txtRetailerSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Retailer"
                                                        >
                                                            <Option value="-1">All Retailers</Option>
                                                            {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                                                return <option key={key} value={retailer.systemName}>{retailer.retailerName}</option>;
                                                            })}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Status" >
                                                    {getFieldDecorator('txtStatusSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Options"
                                                        >
                                                            <Option value="-1">All Options</Option>
                                                            <Option key="Active">Active</Option>
                                                            <Option key="Inactive">Inactive</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                        <Col lg={8} md={8} sm={12} xs={24}>
                                            <div className="gx-form-row0">
                                                <FormItem {...formItemLayout} label="Package" >
                                                    {getFieldDecorator('txtPackageSearch', {
                                                        initialValue: "-1",
                                                    })(
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Options"
                                                        >
                                                            <Option value="-1">All Options</Option>
                                                            <Option key="Dual">Dual</Option>
                                                            <Option key="Elec">Elec</Option>
                                                            <Option key="Gas">Gas</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            <Button type="primary" onClick={this.handleSearch}>
                                                Search
                      </Button>
                                            <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                                                Clear
                      </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    {this.state.showAddEditPanel && <Col span={24}>
                        <Spin spinning={this.state.isAddingUpdating}>
                            <Card className="gx-card" title={`${this.state.formFunction} ${moduleName} Details`} style={{ maxHeight: "650px", overflow: "hidden" }}>
                                <Form
                                    className="ant-advanced-search-form"
                                    style={{ maxHeight: "500px", marginBottom: "20px" }}
                                >
                                    <Tabs type="card" defaultActiveKey={['1']}>
                                        <TabPane tab="Basic Details" key="1" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden"}}>
                                            {this.state.formFunction != "Add" &&
                                                <Row>
                                                    <Col lg={8} md={8} sm={12} xs={24}>
                                                        <div className="gx-form-row0">
                                                            <FormItem {...formItemLayout2} label="Created Date" >
                                                                {getFieldDecorator('created', {
                                                                    initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.created, "YYYY-MM-DD HH:mm:ss") : moment(),
                                                                    rules: [{ required: true, message: 'Please select Created Date!' }],
                                                                })(
                                                                    <DatePicker disabled={this.state.fieldState.created} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'} />
                                                                )}
                                                            </FormItem>
                                                        </div>
                                                    </Col>
                                                    <Col lg={8} md={8} sm={12} xs={24}>
                                                        <div className="gx-form-row0">
                                                            <FormItem {...formItemLayout2} label="Created Date" >
                                                                {getFieldDecorator('created', {
                                                                    initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.created, "YYYY-MM-DD HH:mm:ss") : moment(),
                                                                    rules: [{ required: true, message: 'Please select Created Date!' }],
                                                                })(
                                                                    <DatePicker disabled={this.state.fieldState.created} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'} />
                                                                )}
                                                            </FormItem>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Version" >
                                                            {getFieldDecorator('version', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.version : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.version} style={{ width: '100%' }} placeholder="Enter Version" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="CMS Tool Version" >
                                                            {getFieldDecorator('cms_tools_version', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.cms_tools_version : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.cms_tools_version} style={{ width: '100%' }} placeholder="Enter CMS Tool Version" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Patch Plan" >
                                                            {getFieldDecorator('patch_plan', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.patch_plan : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.patch_plan} style={{ width: '100%' }} placeholder="Enter Patch Plan Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Life Support" >
                                                            {getFieldDecorator('life_support', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.life_support : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.life_support}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="SD" >
                                                            {getFieldDecorator('sd', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.sd : undefined,
                                                                rules: [{ required: true, message: 'Please select either RES or SME!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.sd}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Y">Yes</Option>
                                                                    <Option key="N">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Status" >
                                                            {getFieldDecorator('status', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.status : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.status}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Active">Active</Option>
                                                                    <Option key="Inactive">Inactive</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Plan Start" >
                                                            {getFieldDecorator('plan_start', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.plan_start, "YYYY-MM-DD HH:mm:ss") : moment(),
                                                                rules: [{ required: false, message: 'Please select Plan Start Date!' }],
                                                            })(
                                                                <DatePicker disabled={this.state.fieldState.plan_start} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'} />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Plan Expiry" >
                                                            {getFieldDecorator('plan_expiry', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? moment(this.state.singleRecordDetails.plan_expiry, "YYYY-MM-DD HH:mm:ss") : moment(),
                                                                rules: [{ required: false, message: 'Please select Plan Expiry Date!' }],
                                                            })(
                                                                <DatePicker disabled={this.state.fieldState.plan_expiry} className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'} />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="State" >
                                                            {getFieldDecorator('state', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.state : undefined,
                                                                rules: [{ required: true, message: 'Please select a State!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.state}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select State"
                                                                >
                                                                    <Option value="Victoria">Victoria</Option>
                                                                    <Option value="New South Wales">New South Wales</Option>
                                                                    <Option value="Australian Capital Territory">Australian Capital Territory</Option>
                                                                    <Option value="Queensland">Queensland</Option>
                                                                    <Option value="South Australia">South Australia</Option>
                                                                    <Option value="Western Australia">Western Australia</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Retailer" >
                                                            {getFieldDecorator('retailer', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.retailer : undefined,
                                                                rules: [{ required: true, message: 'Please select a State!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.retailer}
                                                                    showSearch
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select a Retailer"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                >
                                                                    <Option value="-1">All Retailers</Option>
                                                                    {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                                                        return <option key={key} value={retailer.systemName}>{retailer.systemName}</option>;
                                                                    })}
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="RES/SME" >
                                                            {getFieldDecorator('res_sme', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.res_sme : undefined,
                                                                rules: [{ required: true, message: 'Please select either RES or SME!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.res_sme}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="RES">RES</Option>
                                                                    <Option key="SME">SME</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Package" >
                                                            {getFieldDecorator('package', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.package : undefined,
                                                                rules: [{ required: true, message: 'Please select either Dual, Elec or Gas!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.package}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Dual">Dual</Option>
                                                                    <Option key="Elec">Elec</Option>
                                                                    <Option key="Gas">Gas</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tab="Plan Details" key="2" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden"}}>
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Product Name" >
                                                            {getFieldDecorator('product_name', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.product_name : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.product_name} style={{ width: '100%' }} placeholder="Enter Product Name" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Rate Name" >
                                                            {getFieldDecorator('rate_name', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.rate_name : "",
                                                                rules: [{ required: true, message: 'Please enter a rate name' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.rate_name} style={{ width: '100%' }} placeholder="Enter Product Name" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>                                                
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Acq/Ret" >
                                                            {getFieldDecorator('acq_ret', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.acq_ret : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.acq_ret} style={{ width: '100%' }} placeholder="Enter Acq/Ret" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="PDF" >
                                                            {getFieldDecorator('pdf', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.pdf : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.pdf} style={{ width: '100%' }} placeholder="Enter PDF" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Plan Eligibility" >
                                                            {getFieldDecorator('plan_eligibility', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.plan_eligibility : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.plan_eligibility} style={{ width: '100%' }} placeholder="Enter Plan Eligibility" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Special Offer" >
                                                            {getFieldDecorator('special_offer', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.special_offer : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.special_offer} style={{ width: '100%' }} placeholder="Enter Special Offer" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Product Summary" >
                                                            {getFieldDecorator('product_summary', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.product_summary : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <TextArea disabled={this.state.fieldState.product_summary} rows={4} style={{ width: '100%' }} placeholder="Enter Product Summary" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Signup Summary" >
                                                            {getFieldDecorator('signup_summary', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.signup_summary : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <TextArea disabled={this.state.fieldState.signup_summary} rows={4} style={{ width: '100%' }} placeholder="Enter Signup Summary" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={24} md={24} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Terms" >
                                                            {getFieldDecorator('terms', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.terms : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <TextArea disabled={this.state.fieldState.terms} rows={4} style={{ width: '100%' }} placeholder="Enter Terms" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Short Description" >
                                                            {getFieldDecorator('short_description', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.short_description : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.short_description} style={{ width: '100%' }} placeholder="Enter Short Description" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Full Description" >
                                                            {getFieldDecorator('full_description', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.full_description : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <TextArea disabled={this.state.fieldState.full_description} rows={4} style={{ width: '100%' }} placeholder="Enter Full Description" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Contract Length" >
                                                            {getFieldDecorator('contract_length', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.contract_length : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.contract_length} style={{ width: '100%' }} placeholder="Enter Contract Length" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Exit Fee" >
                                                            {getFieldDecorator('exit_fee', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.exit_fee : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.exit_fee} style={{ width: '100%' }} placeholder="Enter Exit Fee" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Solar Specific Plan" >
                                                            {getFieldDecorator('solar_specific_plan', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.solar_specific_plan : undefined,
                                                                rules: [{ required: false, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.solar_specific_plan}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Not Solar">Not Solar</Option>
                                                                    <Option key="Solar Only">Solar Only</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="New Connection" >
                                                            {getFieldDecorator('new_connection', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.new_connection : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.new_connection}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Offshore Lead Genertion" >
                                                            {getFieldDecorator('offshore_lead_generation', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.offshore_lead_generation : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.offshore_lead_generation}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tab="Discount Details" key="3" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}>
                                            <Row>
                                                <Col lg={24} md={24} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Type" >
                                                            {getFieldDecorator('discount_type', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_type : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_type} style={{ width: '100%' }} placeholder="Enter Discount Type" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={24} md={24} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Bonus Description" >
                                                            {getFieldDecorator('discount_bonuses_description', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_bonuses_description : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_bonuses_description} style={{ width: '100%' }} placeholder="Enter Discount Bonus Description" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Guranteed Electricity" >
                                                            {getFieldDecorator('discount_guaranteed_elec', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_guaranteed_elec : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_guaranteed_elec} style={{ width: '100%' }} placeholder="Enter Discount Guranteed Elec" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Guranteed Gas" >
                                                            {getFieldDecorator('discount_guaranteed_gas', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_guaranteed_gas : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_guaranteed_gas} style={{ width: '100%' }} placeholder="Enter Discount Guranteed Gas" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Guranteed Description" >
                                                            {getFieldDecorator('discount_guaranteed_description', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_guaranteed_description : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_guaranteed_description} style={{ width: '100%' }} placeholder="Enter Discount Guranteed Description" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Direct Debit Electricity" >
                                                            {getFieldDecorator('discount_direct_debit_elec', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_direct_debit_elec : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_direct_debit_elec} style={{ width: '100%' }} placeholder="Enter Discount Direct Debit Elec" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Direct Debit Gas" >
                                                            {getFieldDecorator('discount_direct_debit_gas', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_direct_debit_gas : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_direct_debit_gas} style={{ width: '100%' }} placeholder="Enter Discount Direct Debit Gas" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Direct Debit Description" >
                                                            {getFieldDecorator('discount_direct_debit_description', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_direct_debit_description : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_direct_debit_description} style={{ width: '100%' }} placeholder="Enter Discount Direct Debit Description" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Pay on Time Electricity" >
                                                            {getFieldDecorator('discount_pay_on_time_elec', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_pay_on_time_elec : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_pay_on_time_elec} style={{ width: '100%' }} placeholder="Enter Discount Pay on Time Elec" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Pay on Time Gas" >
                                                            {getFieldDecorator('discount_pay_on_time_gas', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_pay_on_time_gas : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_pay_on_time_gas} style={{ width: '100%' }} placeholder="Enter Discount Pay on Time Gas" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Pay on Time Description" >
                                                            {getFieldDecorator('discount_pay_on_time_description', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_pay_on_time_description : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_pay_on_time_description} style={{ width: '100%' }} placeholder="Enter Discount Pay on Time Description" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Credit Electricity" >
                                                            {getFieldDecorator('discount_credit_elec', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_credit_elec : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_credit_elec} style={{ width: '100%' }} placeholder="Enter Discount Credit Elec" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Credit Gas" >
                                                            {getFieldDecorator('discount_credit_gas', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_credit_gas : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_credit_gas} style={{ width: '100%' }} placeholder="Enter Discount Credit Gas" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Credit Description" >
                                                            {getFieldDecorator('discount_credit_description', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_credit_description : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_credit_description} style={{ width: '100%' }} placeholder="Enter Discount Credit Description" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Duel Fuel Electricity" >
                                                            {getFieldDecorator('discount_dual_fuel_elec', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_dual_fuel_elec : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_dual_fuel_elec} style={{ width: '100%' }} placeholder="Enter Discount Duel Fuel Elec" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Duel Fuel Gas" >
                                                            {getFieldDecorator('discount_dual_fuel_gas', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_dual_fuel_gas : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_dual_fuel_gas} style={{ width: '100%' }} placeholder="Enter Discount Duel Fuel Gas" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Duel Fuel Description" >
                                                            {getFieldDecorator('discount_dual_fuel_description', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_dual_fuel_description : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_dual_fuel_description} style={{ width: '100%' }} placeholder="Enter Discount Duel Fuel Description" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Pre-Pay Electricity" >
                                                            {getFieldDecorator('discount_prepay_elec', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_prepay_elec : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_prepay_elec} style={{ width: '100%' }} placeholder="Enter Discount Pre-Pay Elec" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Bonus Sumo" >
                                                            {getFieldDecorator('discount_bonus_sumo', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_bonus_sumo : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_bonus_sumo} style={{ width: '100%' }} placeholder="Enter Discount Bonus Sumo" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Applies" >
                                                            {getFieldDecorator('discount_applies', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_applies : undefined,
                                                                rules: [{ required: false, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.discount_applies}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Usage + STP + GST">Usage + STP + GST</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Discount Applies Gas" >
                                                            {getFieldDecorator('discount_applies_gas', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.discount_applies_gas : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.discount_applies_gas} style={{ width: '100%' }} placeholder="Enter Discount Applies Gas" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tab="Benefit Details" key="4" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden"}}>
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Benefit 1 Title" >
                                                            {getFieldDecorator('benefit1_title', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.benefit1_title : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.benefit1_title} style={{ width: '100%' }} placeholder="Enter Benefit Title" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Benefit 1 Description" >
                                                            {getFieldDecorator('benefit1_description', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.benefit1_description : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <TextArea disabled={this.state.fieldState.benefit1_description} rows={4} style={{ width: '100%' }} placeholder="Enter Benefit Description" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Benefit 2 Title" >
                                                            {getFieldDecorator('benefit2_title', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.benefit2_title : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.benefit2_title} style={{ width: '100%' }} placeholder="Enter Benefit Title" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Benefit 2 Description" >
                                                            {getFieldDecorator('benefit2_description', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.benefit2_description : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <TextArea disabled={this.state.fieldState.benefit2_description} rows={4} style={{ width: '100%' }} placeholder="Enter Benefit Description" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Benefit 3 Title" >
                                                            {getFieldDecorator('benefit3_title', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.benefit3_title : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.benefit3_title} style={{ width: '100%' }} placeholder="Enter Benefit Title" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Benefit 3 Description" >
                                                            {getFieldDecorator('benefit3_description', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.benefit3_description : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <TextArea disabled={this.state.fieldState.benefit3_description} rows={4} style={{ width: '100%' }} placeholder="Enter Benefit Description" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Benefit 4 Title" >
                                                            {getFieldDecorator('benefit4_title', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.benefit4_title : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.benefit4_title} style={{ width: '100%' }} placeholder="Enter Benefit Title" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Benefit 4 Description" >
                                                            {getFieldDecorator('benefit4_description', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.benefit4_description : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <TextArea disabled={this.state.fieldState.benefit4_description} rows={4} style={{ width: '100%' }} placeholder="Enter Benefit Description" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Unique Benefit" >
                                                            {getFieldDecorator('unique_benefit', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.unique_benefit : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.unique_benefit} style={{ width: '100%' }} placeholder="Enter Unique Benefit" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tab="Plan Features" key="5" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden"}}>
                                            <Row>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Conditional discount" >
                                                            {getFieldDecorator('conditional_discount', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.conditional_discount : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.conditional_discount}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Bill Smoothing" >
                                                            {getFieldDecorator('bill_smoothing', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.bill_smoothing : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.bill_smoothing}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={16} md={16} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Bill Smoothing Description" >
                                                            {getFieldDecorator('bill_smoothing_details', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.bill_smoothing_details : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <TextArea disabled={this.state.fieldState.bill_smoothing_details} rows={4} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Online Account Management" >
                                                            {getFieldDecorator('online_account_management', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.online_account_management : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.online_account_management}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={16} md={16} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Online Account Management Description" >
                                                            {getFieldDecorator('online_account_management_details', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.online_account_management_details : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <TextArea disabled={this.state.fieldState.online_account_management_details} rows={4} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Energy Monitoring Tools" >
                                                            {getFieldDecorator('energy_monitoring_tools', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.energy_monitoring_tools : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.energy_monitoring_tools}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={16} md={16} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Energy Monitoring Tools Description" >
                                                            {getFieldDecorator('energy_monitoring_tools_details', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.energy_monitoring_tools_details : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <TextArea disabled={this.state.fieldState.energy_monitoring_tools_details} rows={4} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Reward Programs" >
                                                            {getFieldDecorator('membership_reward_programs', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.membership_reward_programs : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.membership_reward_programs}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={16} md={16} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Reward Programs Description" >
                                                            {getFieldDecorator('membership_reward_programs_details', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.membership_reward_programs_details : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <TextArea disabled={this.state.fieldState.membership_reward_programs_details} rows={4} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Renewable Energy" >
                                                            {getFieldDecorator('renewable_energy', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.renewable_energy : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.renewable_energy}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={16} md={16} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Renewable Energy Description" >
                                                            {getFieldDecorator('renewable_energy_details', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.renewable_energy_details : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <TextArea disabled={this.state.fieldState.renewable_energy_details} rows={4} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Rate Freeze" >
                                                            {getFieldDecorator('rate_freeze', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.rate_freeze : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.rate_freeze}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={16} md={16} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Rate Freeze Description" >
                                                            {getFieldDecorator('rate_freeze_details', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.rate_freeze_details : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <TextArea disabled={this.state.fieldState.rate_freeze_details} rows={4} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Pay on Time" >
                                                            {getFieldDecorator('pay_on_time', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.pay_on_time : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.pay_on_time}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="No Contract Plan" >
                                                            {getFieldDecorator('no_contract_plan', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.no_contract_plan : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.no_contract_plan}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={16} md={16} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="No Contract Plan Description" >
                                                            {getFieldDecorator('no_contract_plan_details', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.no_contract_plan_details : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <TextArea disabled={this.state.fieldState.no_contract_plan_details} rows={4} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Total Bill Discount" >
                                                            {getFieldDecorator('total_bill_discount', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.total_bill_discount : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.total_bill_discount}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Paper Billing" >
                                                            {getFieldDecorator('paper_billing', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.paper_billing : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.paper_billing}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="E-Billing" >
                                                            {getFieldDecorator('e_bill', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.e_bill : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.e_bill}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Billing Period" >
                                                            {getFieldDecorator('billing_period', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.billing_period : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.billing_period}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Elec: Quarterly, Gas: Bi-Monthly">Elec: Quarterly, Gas: Bi-Monthly</Option>
                                                                    <Option key="Elec: Monthly, Gas: Bi-Monthly">Elec: Monthly, Gas: Bi-Monthly</Option>
                                                                    <Option key="Bi-Monthly">Bi-Monthly</Option>
                                                                    <Option key="Quarterly">Quarterly</Option>
                                                                    <Option key="Monthly">Monthly</Option>
                                                                    <Option key="If you have an active smart meter your may be eligable for monthly billing for electricity & Quaterly for gas.">If you have an active smart meter your may be eligable for monthly billing for electricity & Quaterly for gas.</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                        {/* <FormItem {...formItemLayout2} label="No Contract Plan Description" >
                                                            {getFieldDecorator('no_contract_plan_details', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.no_contract_plan_details : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.no_contract_plan_details} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tab="Fee Details" key="6" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden"}}>
                                            <Row>
                                                <Col lg={12} md={12} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Late Payment Fees" >
                                                            {getFieldDecorator('late_payment_fee', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.late_payment_fee : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.late_payment_fee} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Dishonoured Payment Fees" >
                                                            {getFieldDecorator('dishonoured_payment_fee', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.dishonoured_payment_fee : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.dishonoured_payment_fee} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Card Payment Fees" >
                                                            {getFieldDecorator('card_payment_fee', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.card_payment_fee : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.card_payment_fee} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Other Fees" >
                                                            {getFieldDecorator('other_fees', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.other_fees : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.other_fees} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={16} md={16} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Solar Metering Charge" >
                                                            {getFieldDecorator('solar_metering_charge', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.solar_metering_charge : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.solar_metering_charge} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tab="Payment Options" key="7" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden"}}>
                                            <Row>
                                                <Col lg={24} md={24} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Payment Methods" >
                                                            {getFieldDecorator('payment_methods', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.payment_methods : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.payment_methods} style={{ width: '100%' }} placeholder="Enter Payment Methods" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Direct Debit" >
                                                            {getFieldDecorator('direct_debit', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.direct_debit : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.direct_debit}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="BPay" >
                                                            {getFieldDecorator('bpay', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.bpay : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.bpay}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Credit Card" >
                                                            {getFieldDecorator('credit_card', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.credit_card : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.credit_card}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Easi Pay" >
                                                            {getFieldDecorator('easipay', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.easipay : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.easipay}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Online" >
                                                            {getFieldDecorator('online', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.online : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.online}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Centre Pay" >
                                                            {getFieldDecorator('centrepay', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.centrepay : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.centrepay}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Cash" >
                                                            {getFieldDecorator('cash', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.cash : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.cash}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Cheque" >
                                                            {getFieldDecorator('cheque', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.cheque : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.cheque}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Post Bill Pay" >
                                                            {getFieldDecorator('post_billpay', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.post_billpay : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.post_billpay}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Pay by Phone" >
                                                            {getFieldDecorator('pay_by_phone', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.pay_by_phone : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.pay_by_phone}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={8} md={8} sm={24} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Amex" >
                                                            {getFieldDecorator('amex', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.amex : undefined,
                                                                rules: [{ required: true, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.amex}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tab="Plan Codes" key="8" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden"}}>
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Product Code Elec" >
                                                            {getFieldDecorator('product_code_elec', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.product_code_elec : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.product_code_elec} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Product Code Gas" >
                                                            {getFieldDecorator('product_code_gas', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.product_code_gas : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.product_code_gas} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Campaign Code Elec" >
                                                            {getFieldDecorator('campaign_code_elec', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.campaign_code_elec : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.campaign_code_elec} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Campaign Code Gas" >
                                                            {getFieldDecorator('campaign_code_gas', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.campaign_code_gas : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.campaign_code_gas} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Elec Product (For Velocify)" >
                                                            {getFieldDecorator('elec_product', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.elec_product : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.elec_product} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Gas Product (For Velocify)" >
                                                            {getFieldDecorator('gas_product', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.gas_product : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.gas_product} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tab="Solar Details" key="9" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden"}}>
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Solar Boost FIT" >
                                                            {getFieldDecorator('solar_boost_fit', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.solar_boost_fit : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.solar_boost_fit} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Solar Boost Cap" >
                                                            {getFieldDecorator('solar_boost_cap', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.solar_boost_cap : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.solar_boost_cap} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={24}>
                                                    <div className="gx-form-row0">
                                                        <FormItem {...formItemLayout2} label="Solar Inverter Limit" >
                                                            {/* {getFieldDecorator('solar_inverter_limit', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.solar_inverter_limit : "",
                                                                rules: [{ required: false, message: '' }],
                                                            })(
                                                                <Input disabled={this.state.fieldState.solar_inverter_limit} style={{ width: '100%' }} placeholder="Enter Details" />
                                                            )} */}
                                                            {getFieldDecorator('solar_inverter_limit', {
                                                                initialValue: !isEmpty(this.state.singleRecordDetails) ? this.state.singleRecordDetails.solar_inverter_limit : undefined,
                                                                rules: [{ required: false, message: 'Please select option!' }],
                                                            })(
                                                                <Select
                                                                    disabled={this.state.fieldState.solar_inverter_limit}
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Select Option"
                                                                >
                                                                    <Option key="Yes">Yes</Option>
                                                                    <Option key="No">No</Option>
                                                                </Select>
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </Tabs>
                                    {/* <Collapse accordion defaultActiveKey={['1']}>
                                        <Panel header="Basic Details" key="1">

                                        </Panel>
                                        <Panel header="Plan Details" key="2">

                                        </Panel>
                                        <Panel header="Discount Details" key="3">

                                        </Panel>
                                        <Panel header="Benefit Details" key="4">

                                        </Panel>
                                        <Panel header="Plan Features" key="6">

                                        </Panel>
                                        <Panel header="Fee Details" key="7">

                                        </Panel>
                                        <Panel header="Payment Options" key="8">

                                        </Panel>
                                        <Panel header="Plan Codes" key="9">

                                        </Panel>
                                        <Panel header="Solar Details" key="10">

                                        </Panel>
                                    </Collapse> */}

                                </Form>
                                <Row>
                                    <Col span={24} className="gx-text-right">
                                        <center>
                                            {this.state.formFunction == "Add" &&
                                                <Button type="primary" onClick={this.handleAddRecord}>
                                                    Add
                        </Button>
                                            }
                                            {this.state.formFunction == "Update" &&
                                                <Button type="primary" onClick={this.handleUpdateRecord}>
                                                    Update
                        </Button>
                                            }
                                            <Button style={{ marginLeft: 8 }} onClick={this.onAddUpdateCancel}>
                                                Cancel
                      </Button>
                                        </center>
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                    }

                    <Col span={24}>
                        <Card className="gx-card" title={`${moduleName} Details`}>
                            <Button onClick={this.handleAdd} type="primary" className="gx-btn-cyan" style={{ marginBottom: 16 }}>
                                <Icon type="plus" />  Add New {moduleName} Details
                            </Button>
                            <Button onClick={this.handleExportToCSV} type="primary" style={{ marginBottom: 16, marginRight: 0, float: "right" }}>
                                <Icon type="download" /> Export Displayed Results
                            </Button>
                            <Upload {...uploadProps}>
                                <Button type="primary">
                                    <Icon type="upload" /> Add/Update by Uploading a CSV
                                </Button>
                            </Upload>
                            <Upload {...uploadPropsWipe}>
                                <Button type="danger" style={{ marginLeft: "10px" }}>
                                    <Icon type="upload" /> Wipe and Add by Uploading a CSV
                                </Button>
                            </Upload>
                            <Table
                                className="gx-table-responsive"
                                {...this.state}
                                pagination={{ pageSize: 10 }}
                                columns={columns}
                                dataSource={dataSource} />
                        </Card>
                    </Col>


                </Row>
                <SweetAlert show={this.state.showWaiting}
                    title={'Please wait..'}
                    buttons={false}
                />
                <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showError} error title={this.state.errorMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showWarning}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={this.state.warningMessage}
                    onConfirm={this.handleDelete}
                    onCancel={this.onCancelDelete}
                ></SweetAlert>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    retailerList: state.retailers.retailerList,

    planRecords: state.plans.resultList,
    recordUpdateStatus: state.plans.recordUpdateStatus,
    error: state.plans.error,
});

const mapDispatchToProps = dispath => ({

    getRetailerList: (data) => dispath(getRetailerList(data)),

    getRecords: () => dispath(getRecords()),
    searchRecords: (data) => dispath(searchRecords(data)),
    updateSingleRecord: (data) => dispath(updateSingleRecord(data)),
    createRecord: (data) => dispath(createRecord(data)),
    deleteRecord: (data) => dispath(deleteRecord(data)),
    uploadRecords: (data) => dispath(uploadRecords(data)),
    wipeAndUploadRecords: (data) => dispath(wipeAndUploadRecords(data)),

    clearError: () => dispath(clearError()),

});

const WrappedAdvancedSearch = Form.create()(ManageConsumptions);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);