import React from "react";
import {Route, Switch} from "react-router-dom";
import PostcodeMapping from "./PostcodeMapping";
import Retailers from "./Retailers";
import Distributors from "./Distributors";
import Users from "./Users";
import DataManagement from "./DataManagement";
import Settings from "./Settings";
import Manage from "./Manage";
import ManageSales from "./ManageSales";

const Admin = ({match}) => (
  <Switch>
    <Route path={`${match.url}/postcodeMapping`} component={PostcodeMapping}/>
    <Route path={`${match.url}/dataManagement`} component={DataManagement}/>
    <Route path={`${match.url}/retailers`} component={Retailers}/>
    <Route path={`${match.url}/distributors`} component={Distributors}/>
    <Route path={`${match.url}/users`} component={Users}/>
    <Route path={`${match.url}/settings`} component={Settings}/>
    <Route path={`${match.url}/manage`} component={Manage}/>
    <Route path={`${match.url}/manageSales`} component={ManageSales}/>
  </Switch>
);

export default Admin;
