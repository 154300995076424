import { StreetType } from "../static/formSupport";
import isEmpty from "util/isEmpty";

export const getStreetTypeValue = (street) => {

    // check street Type availability
    if (street) {

        // convert street type  to camel case
        const camelCasedStreet = street.charAt(0).toUpperCase() + street.slice(1).toLowerCase();

        // filter street type
        const filteredStreet = StreetType.filter((streetItem) => {
            return streetItem.label.toUpperCase() === camelCasedStreet.toUpperCase()
        })

        // return the filtered Street value
        return filteredStreet && filteredStreet[0] && filteredStreet[0].value


    } else {

        return ""
    }

}


export const getStreetTypeLabel = (type) => {

    // check street Type availability
    if (type) {

        // filter street type
        let filteredStreet = null
        try{
            filteredStreet = StreetType.filter((streetItem) => {
                return streetItem.value.toUpperCase() === type.toUpperCase()
            })
        } catch (err) {

        }

        if(isEmpty(filteredStreet)) {
            try{
                filteredStreet = StreetType.filter((streetItem) => {
                    return streetItem.label.toUpperCase() === type.toUpperCase()
                })
            } catch (err) {
    
            }
        }

        // return the filtered Street value
        return filteredStreet[0].label


    } else {

        return ""
    }
}