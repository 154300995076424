const initialState = {
    leadDetails: null,
    leadAssignmentToAgentMessage: null,
    recordUpdateStatus: null,
    error: null,
    status: 'idl',
    redirect: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'SAVE_SINGLE_LEAD_DETAILS':

            console.log(action.leadDetails.returnToCreatePage);
            return {
                ...state,
                leadDetails: action.leadDetails.result,
                status: 'success',
                redirect: action.leadDetails.returnToCreatePage ? action.leadDetails.returnToCreatePage : false
            };

        case 'REQUEST_SINGLE_LEAD_DETAILS':
            return {
                ...state,
                leadDetails: null,
                status: 'loading'
            };
        case 'REST_LEAD_DETAILS':
            return {
                ...state,
                leadDetails: null,
                leadAssignmentToAgentMessage: null,
                recordUpdateStatus: null,
                error: null,
                status: 'idl',
                redirect: false,
            }

        case 'SAVE_LOCAL-SAVED_LEAD_DETAILS':
            return {
                ...state,
                leadDetails: action.leadDetails
            };
        case 'LEAD_ASSIGNED_TO_AGENT_MESSAGE':
            return {
                ...state,
                leadAssignmentToAgentMessage: action.leadAssignmentToAgentMessage
            };
        case 'SAVE_SINGLE_LEAD_CREATE':

            console.log(action.recordUpdateStatus);
            return {
                ...state,
                recordUpdateStatus: action.recordUpdateStatus,
                status: 'idl',
                leadDetails: null,

            };
        case 'REQUEST_SINGLE_LEAD_CREATE':

            console.log('loading');
            return {
                ...state,
                recordUpdateStatus: 'loading'
            };

        case 'ERROR':
            return {
                ...state,
                error: action.error,
                status: 'idl'
            };
        default:
            return state;
    }
}