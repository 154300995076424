import React from "react";
import { connect } from "react-redux";
import { Col, Form, Row,} from "antd";

import WelComeCard from "components/dashboard/CRM/WelComeCard";
import IconWithTextCard from "components/dashboard/CRM/IconWithTextCard";

import Portfolio from "./Portfolio";
import BalanceHistory from "./BalanceHistory";

class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showWarning: false,
            warningMessage: "",
            showSuccess: false,
            successMessage: "",
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-main-content">
                <Row>
                    <Col span={24}>
                        <div className="gx-card">
                            <div className="gx-card-body">
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <WelComeCard />
                                </Col>
                            </div>
                        </div>
                    </Col>
                </Row>
                {/* <Row>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <IconWithTextCard cardColor="orange" icon="chart-area" title="09" subTitle="Total Sales for the week"/>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <IconWithTextCard cardColor="green" icon="chart-area" title="09" subTitle="Total Sales for today"/>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <IconWithTextCard cardColor="blue" icon="chart-area" title="09" subTitle="Total Elec Sales for Today"/>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <IconWithTextCard cardColor="purple" icon="chart-area" title="09" subTitle="Total Gas Sales for Today"/>
                    </Col>
                </Row>
                <Row>
                    <Col xl={12} lg={24} md={12} sm={24} xs={24}>
                        <Portfolio/>
                    </Col>
                    <Col xl={12} lg={24} md={12} sm={24} xs={24}>
                        <BalanceHistory/>
                    </Col>
                </Row> */}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispath => ({
    
});

const WrappedAdvancedSearch = Form.create()(Dashboard);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);