import React from 'react'
import { Col, Row } from "antd";

import { FaStar } from 'react-icons/fa';

import styles from './BroadbandResult.module.less';

const FeatureList = (props) => {

    const { list, title } = props;

    return (
        <div>


            <div className={styles.featureListTitle}>

                {title || 'Features'}

            </div>

            {title === "Retailer Highlights" && <Row>
                <Col sm={24}>
                    <ul className={styles.list}>

                        {
                            list && list.length > 0 && list.map((feature, index) => {
                                return <li key={index}> <FaStar className={styles.listIcon} /> {feature.title}</li>
                            })
                        }

                    </ul>
                </Col>
            </Row>}
            {title !== "Retailer Highlights" && <Row>
                <Col sm={6}>
                    <ul className={styles.list}>

                        {
                            list && list.length > 0 && list.map((feature, index) => {
                                return index < 2 && <li key={index}> <FaStar className={styles.listIcon} /> {feature.title}</li>
                            })
                        }

                    </ul>
                </Col>
                <Col sm={6}>
                    <ul className={styles.list}>

                        {
                            list && list.length > 0 && list.map((feature, index) => {
                                return (index >= 2 && index < 4) && <li key={index}> <FaStar className={styles.listIcon} /> {feature.title}</li>
                            })
                        }

                    </ul>
                </Col>
                <Col sm={6}>
                    <ul className={styles.list}>

                        {
                            list && list.length > 0 && list.map((feature, index) => {
                                return (index >= 4 && index < 6) && <li key={index}> <FaStar className={styles.listIcon} /> {feature.title}</li>
                            })
                        }

                    </ul>
                </Col>
                <Col sm={6}>
                    <ul className={styles.list}>

                        {
                            list && list.length > 0 && list.map((feature, index) => {
                                return (index >= 6) && <li key={index}> <FaStar className={styles.listIcon} /> {feature.title}</li>
                            })
                        }

                    </ul>
                </Col>
            </Row>}



        </div>
    )
}

export default FeatureList
