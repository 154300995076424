import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';
import isEmpty from "util/isEmpty"



export const saveValidatedAddress = addressDetails => ({
  type: 'SAVE_VALIDATE_ADDRESS',
  addressDetails,
});

export const requestToValidateAddress = () => ({
  type: 'REQUEST_TO_VALIDATE_ADDRESS',
});


export const error = error => ({
  type: 'ERROR',
  error,
});


export const getSuburbList = suburbList => ({
  type: 'GET_SUBURB_LIST_ADDRESS',
  suburbList,
});


export const resetAddress = () => ({
  type: 'REST_ADDRESS',
});


const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

export const clearErrors = (data) => dispath => {

  dispath(error(null));
}

export const clearAll = () => dispath => {

  dispath(saveValidatedAddress(null));
  dispath(error(null));

}


export const validateAddress = (address) => dispath => {

  dispath(requestToValidateAddress());

  axios.post(apidetails.API_URL + `broadband/getAddressDetails`, address)
    .then(res => {

      dispath(saveValidatedAddress(res.data));

    })
    .catch(err => {

      dispath(error(!isEmpty(err.response.data.message) ? err.response.data.message : 'ERROR'));
    });
}

export const setAddressReset = () => dispath => {

  console.log("resetting address");
  dispath(resetAddress());

}

export const getSuburbByPostcodeId = (postcode) => dispath => {

  const data = { postcode }

  axios.post(`${apidetails.API_URL}postcodeSuburb/search`, qs.stringify(data))
    .then(res => {

      console.log(res);

      dispath(getSuburbList(res.data));

    })
    .catch(err => {

      //dispath(error(!isEmpty(err.response.data.message) ? err.response.data.message : 'ERROR'));
    });
}