import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, Upload, Divider, Tooltip, Icon, Alert, Tag, } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getDistributorTranslationList, updateDistributorTranslationList, addDistributorTranslationList, deleteDistributorTranslationList } from "appRedux/actions/admin/distributorTranslations";

import { getDistributorList } from "appRedux/actions/admin/distributors";
import { getRetailerList } from "appRedux/actions/admin/retailers";

import { getLeadDataListNames, uploadLeadData } from "appRedux/actions/admin/leadData";
import { getLeadData } from "appRedux/actions/admin/leadData";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const FormItem = Form.Item;
const Dragger = Upload.Dragger;
const Option = Select.Option;
const moment = extendMoment(Moment);
let retailerState = "";

//const title = () => 'Here is title';
const showHeader = true;
//const footer = () => 'Here is footer';
//const scroll = {y: 500};
const pagination = { position: 'bottom' };

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const EditableContext = React.createContext();

class EditableCell extends React.Component {

  getInput = () => {
    const {
      dataIndex,
      record,
    } = this.props;

    function onChange(checked) {
    }

    function handleChange(value) {
      retailerState = value.toString().split(',').join('|');
    }

    if (this.props.inputType === 'statusSelection') {
      return <Switch disabled={false} defaultChecked={record.isActive == 1 ? true : false} onChange={onChange} />;
    }
    else if (this.props.inputType === 'ewDbSelection') {
      return <Select
        style={{ width: '250px' }}
        placeholder="Please select Distributor"
        onChange={handleChange}
        defaultValue={record.ewDbId}
      >
        <Option value="-1">Select</Option>
        {!isEmpty(this.props.distributorList) && this.props.distributorList.map((distributor, key) => {
          return <option key={key} value={distributor.id}>{distributor.distributorName}</option>;
        })}
      </Select>;
    }
    else if (this.props.inputType === 'retailerSelection') {
      return <Select
        style={{ width: '250px' }}
        placeholder="Please select Retailer"
        onChange={handleChange}
        defaultValue={record.retailerId}
      >
        <Option value="-1">Select</Option>
        {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
          return <option key={key} value={retailer.id}>{retailer.retailerName}</option>;
        })}
      </Select>;
    }
    return <Input style={{ minWidth: '150px' }} />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    function getInitialValue(dataIndex) {
      if (dataIndex == "isActive") {
        return record[dataIndex] == 1 ? true : false;
      }
      else if (dataIndex == "state") {
        retailerState = record[dataIndex];
        return record[dataIndex].split("|");
      }
      else {
        return record[dataIndex];
      }
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0, width: '100%', display:'block', }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: getInitialValue(dataIndex),
            })(this.getInput())}
          </Form.Item>
        ) : (
            children
          )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class ManageLeads extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showAddNewLeadPanel: false,
      showUploadLeadsPanel: false,

      fileList: [],
      showSuccess: false,
      successMessage: "",
      uploading: false,
      showUploadPanel: false,
      fields: {
        listName: {
          value: '-1',
        },
        newListName: {
          value: '',
        },
        source: {
          value: '',
        },
        reqextwash: {
          value: false,
        },
      },

      editingKey: '',
      isAdding: false,
      isSearching: false,
      recordsEdited: false,
      expand: false,
      dataSource: [],
      addingNewRecord: false,
      leadRecordIdToDelete: null,

      showWarning: false,
      warningMessage: "",
      showSuccess: false,
      successMessage: "",
      bordered: true,
      loading: true,
      pagination,
      size: 'middle',
      title: undefined,
      showHeader,
      footer: undefined,
      rowSelection: {},
    };
    this.columns = [
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        width: 100,
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <center>
                <EditableContext.Consumer>
                  {form => (
                    <a
                      onClick={() => this.save(form, record.key)}

                    >
                      <i className="icon icon-check-circle-o" />
                    </a>
                  )}
                </EditableContext.Consumer>
                <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                  <a style={{ marginLeft: 10 }}><i className="icon icon-close-circle" /></a>
                </Popconfirm>
              </center>
            </span>
          ) : (
              <span>
                <center>
                  <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
                    <i className="icon icon-edit" />
                  </a>
                  <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.confirmDelete(record.id)}>
                    <i className="icon icon-trash" />
                  </a>
                </center>
              </span>
            );
        },
      },
      {
        title: 'Lead ID',
        dataIndex: 'leadID',
        key: 'leadID',
        sorter: (a, b) => a.leadID - b.leadID,
      },
      {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName',
      },
      {
        title: 'Street',
        dataIndex: 'street',
        key: 'street',
      },
      {
        title: 'Suburb',
        dataIndex: 'suburb',
        key: 'suburb',
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
      },
      {
        title: 'Postcode',
        dataIndex: 'postcode',
        key: 'postcode',
      },
      {
        title: 'Phone Mobile',
        dataIndex: 'phoneMobile',
        key: 'phoneMobile',
        align: "center",
        sorter: (a, b) => a.phoneMobile - b.phoneMobile,
        render: text => <span className="">{text}</span>,
      },
      {
        title: 'Phone Mobile In DNC',
        dataIndex: 'phoneMobileInDNC',
        key: 'phoneMobileInDNC',
        align: "center",
        render: (text, record) => {
          let color = text == "Not in DNC" ? 'green' : 'geekblue';
          if (text == 'In DNC') {
            color = 'volcano';
          }
          return text!="-" && <Tag color={color} key={text}>
                  {text.toUpperCase()}
                </Tag>
        },
      },
      {
        title: 'Phone Mobile Last Washed',
        dataIndex: 'phoneMobileLastWashed',
        key: 'phoneMobileLastWashed',
        align: "center",
        width: "200px",
        render: (text, record) => {
          return text!="-" && <Tag color={'purple'} key={text}>
                  {text.toUpperCase()}
                </Tag>
        },
      },
      {
        title: 'Phone Home',
        dataIndex: 'phoneHome',
        key: 'phoneHome',
        align: "center",
        sorter: (a, b) => a.phoneHome - b.phoneHome,
        render: text => <span className="">{text}</span>,
      },
      {
        title: 'Phone Mobile In DNC',
        dataIndex: 'phoneHomeInDNC',
        key: 'phoneHomeInDNC',
        align: "center",
        render: (text, record) => {
          let color = text == "Not in DNC" ? 'green' : 'geekblue';
          if (text == 'In DNC') {
            color = 'volcano';
          }
          return text!="-" && <Tag color={color} key={text}>
                  {text.toUpperCase()}
                </Tag>
        },
      },
      {
        title: 'Phone Mobile Last Washed',
        dataIndex: 'phoneHomeLastWashed',
        key: 'phoneHomeLastWashed',
        align: "center",
        width: "200px",
        render: (text, record) => {
          return text!="-" && <Tag color={'purple'} key={text}>
                  {text.toUpperCase()}
                </Tag>
        },
      },
      {
        title: 'Phone Work',
        dataIndex: 'phoneWork',
        key: 'phoneWork',
        align: "center",
        sorter: (a, b) => a.phoneWork - b.phoneWork,
        render: text => <span className="">{text}</span>,
      },
      {
        title: 'Phone Mobile In DNC',
        dataIndex: 'phoneWorkInDNC',
        key: 'phoneWorkInDNC',
        align: "center",
        render: (text, record) => {
          let color = text == "Not in DNC" ? 'green' : 'geekblue';
          if (text == 'In DNC') {
            color = 'volcano';
          }
          return text!="-" && <Tag color={color} key={text}>
                  {text.toUpperCase()}
                </Tag>
        },
      },
      {
        title: 'Phone Mobile Last Washed',
        dataIndex: 'phoneWorkLastWashed',
        key: 'phoneWorkLastWashed',
        align: "center",
        width: "200px",
        render: (text, record) => {
          return text!="-" && <Tag color={'purple'} key={text}>
                  {text.toUpperCase()}
                </Tag>
        },
      },
    ];

  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    // form.validateFields((error, row) => {
    //   if (error) {
    //     return;
    //   }

    //   const newData = [...this.state.dataSource];
    //   const index = newData.findIndex(item => key === item.key);

    //   if (index > -1) {
    //     const item = newData[index];

    //     const valuesToUpdate = {
    //       id: item.id,
    //       retailerId: row.retailerId,
    //       retailerDb: row.retailerDb,
    //       ewDbId: row.ewDbId,
    //       isActive: row.isActive,
    //     }

    //     this.setState({ loading: true });
    //     this.props.updateDistributorTranslationList(valuesToUpdate);

    //     retailerState = "";

    //     this.setState({
    //       editingKey: '',
    //       recordsEdited: true
    //     });
    //   }
    // });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  confirmDelete = (key) => {
    this.setState({ leadRecordIdToDelete: key });
    this.setState({ showWarning: true });

  };

  onCancelDelete = key => {
    this.setState({ leadRecordIdToDelete: null });
    this.setState({ showWarning: false });
  };

  handleDelete = () => {
    const deleteInfo = {
      id: this.state.leadRecordIdToDelete,
    }
    this.props.deleteDistributorTranslationList(deleteInfo);
    this.setState({ showWarning: false });
  };

  onConfirm = () => {
    this.setState({
      showSuccess: false,
      showAddNewLeadPanel: false,
      showUploadLeadsPanel: false,
    });
    this.handleReset();
  };

  handleAdd = () => {
    this.setState({
      showAddNewLeadPanel: true,
    });
  };

  handleUploadList = () => {
    this.setState({
      showUploadLeadsPanel: true,
    });
  };

  handleAddDistributorTranslationDetails = (e) => {

  };

  onAddCancel = () => {
    this.setState({
      showAddNewLeadPanel: false,
    });
    this.handleReset();
  };

  onUploadCancel = () => {
    this.setState({
      showUploadLeadsPanel: false,
    });
    this.handleReset();
  };

  handleListNameChange = (value) => {

    const { fields } = { ...this.state };
    const currentState = fields;

    currentState.listName.value = value;

    this.setState({ fields: currentState });

    if (value !== 0)
      this.showUploadPanel();
    else
      this.setState({ showUploadPanel: false });
  }

  handleNewListNameChange = (e) => {

    const { fields } = { ...this.state };
    const currentState = fields;

    currentState.newListName.value = e.target.value;
    this.setState({ fields: currentState });

    this.showUploadPanel();
  }

  handleSourceChange = (e) => {

    const { fields } = { ...this.state };
    const currentState = fields;

    currentState.source.value = e.target.value;

    this.setState({ fields: currentState });
  }

  handleRequireWashChange = (value) => {

    const { fields } = { ...this.state };
    const currentState = fields;

    currentState.reqextwash.value = value;

    this.setState({ fields: currentState });
  }

  showUploadPanel = () => {
    if (this.state.fields.listName.value === 0) {
      if (!isEmpty(this.state.fields.newListName.value)) {
        this.setState({ showUploadPanel: true });
      }
      else
        this.setState({ showUploadPanel: false });
    }
    else if (this.state.fields.listName.value !== -1) {
      this.setState({ showUploadPanel: true });
    }
    else {
      this.setState({ showUploadPanel: false });
    }
  }

  handleReset = () => {
    this.props.form.resetFields();
    this.props.getLeadData();
    this.props.getLeadDataListNames();
    this.setState({ showUploadPanel: false });

    const { fields } = { ...this.state };
    const currentState = fields;

    currentState.listName.value = '';
    currentState.source.value = '';
    currentState.reqextwash.value = false;
    currentState.newListName.value = '';

    this.setState({ fields: currentState });
  };

  uploadFile = (info) => {

    //console.log(info);
    this.setState({
      uploading: true,
    });

    const uploadInfo = {
      listId: this.state.fields.listName.value,
      source: this.state.fields.source.value,
      reqextwash: this.state.fields.reqextwash.value,
      csvData: null,
      userID: 0,
      fileType: 'Lead Data',
      fileName: null,
      listName: this.state.fields.newListName.value,
    }

    this.props.uploadLeadData(uploadInfo, info.file);
  };


  componentDidMount() {
    this.props.getDistributorList();
    this.props.getRetailerList();
    this.props.getDistributorTranslationList();

    this.props.getLeadData();
    this.props.getLeadDataListNames();
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      const filtersForSearch = {
        listId: values.drpListName,
        state: values.drpState,
        dncStatus: values.drpDNCType,
        numberType: values.drpTypeOfContact,
        contactNumber: values.txtContactNumber,
      }
      console.log(filtersForSearch);
      this.setState({ isSearching: true });
      this.props.getLeadData(filtersForSearch);
    });
  };


  checkColoumnType = (field) => {
    switch (field) {
      case 'retailerId': return "retailerSelection";
      case 'ewDbId': return "ewDbSelection";
      case 'isActive': return "statusSelection";
      default: return "text";
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.leadDataList)) {
      this.setState({ loading: true });
      this.state.dataSource = [];
      let count = 1;
      nextProps.leadDataList.map((leadData, key) => {
        let record = {
          key: count.toString(),
          leadID: !isEmpty(leadData.leadId) ? leadData.leadId : "-",
          customerName: (!isEmpty(leadData.firstName) ? leadData.firstName : "") + " " + (!isEmpty(leadData.lastName) ? leadData.lastName : ""),
          street: !isEmpty(leadData.street) ? leadData.street : "-",
          suburb: !isEmpty(leadData.suburb) ? leadData.suburb : "-",
          state: !isEmpty(leadData.state) ? leadData.state : "-",
          postcode: !isEmpty(leadData.postcode) ? leadData.postcode : "-",
          phoneMobile: leadData.phoneMobile != 0 ? leadData.phoneMobile : "-",
          phoneHome: leadData.phoneHome != 0 ? leadData.phoneHome : "-",
          phoneWork: leadData.phoneWork != 0 ? leadData.phoneWork : "-",
          phoneMobileInDNC: !isEmpty(leadData.phoneMobileInDNC) ? (leadData.phoneMobileInDNC == "Y" ? "In DNC" : "Not in DNC") : (leadData.phoneMobile != 0 ? "Not Washed" : "-"),
          phoneMobileLastWashed: !isEmpty(leadData.phoneMobileLastWashed) ? moment(leadData.phoneMobileLastWashed).format('YYYY-MM-DD h:mm:ss A') : "-",
          phoneHomeInDNC: !isEmpty(leadData.phoneHomeInDNC) ? (leadData.phoneHomeInDNC == "Y" ? "In DNC" : "Not in DNC") : (leadData.phoneHome != 0 ? "Not Washed" : "-"),
          phoneHomeLastWashed: !isEmpty(leadData.phoneHomeLastWashed) ? moment(leadData.phoneHomeLastWashed).format('YYYY-MM-DD h:mm:ss A') : "-",
          phoneWorkInDNC: !isEmpty(leadData.phoneWorkInDNC) ? (leadData.phoneWorkInDNC == "Y" ? "In DNC" : "Not in DNC") : (leadData.phoneWork != 0 ? "Not Washed" : "-"),
          phoneWorkLastWashed: !isEmpty(leadData.phoneWorkLastWashed) ? moment(leadData.phoneWorkLastWashed).format('YYYY-MM-DD h:mm:ss A') : "-",
        };
        this.state.dataSource.push(record);
        count++;
      });
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }
    else {
      this.setState({ loading: true });
      this.state.dataSource = [];
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }

    if (!isEmpty(nextProps.recordUpdateStatus)) {
      if (nextProps.recordUpdateStatus.status == "Success") {
        this.setState({
          successMessage: nextProps.recordUpdateStatus.message,
          showSuccess: true,
        });
        this.props.getDistributorTranslationList();
        this.setState({
          isAdding: false,
          showAddNewLeadPanel: false,
        });
      }
    }

    if (!isEmpty(nextProps.leadDataUploadStatus)) {
      if (nextProps.leadDataUploadStatus.status === 'Success') {
        this.setState({
          successMessage: nextProps.leadDataUploadStatus.message,
          showSuccess: true,
          uploading: false,
        });
      }
    }
  }


  render() {

    let { dataSource } = this.state;
    const { fileList } = this.state;

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          retailerList: this.props.retailerList,
          distributorList: this.props.distributorList,
          inputType: this.checkColoumnType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const draggerProps = {
      name: 'file',
      multiple: true,
      onChange: (this.uploadFile),
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-main-content">
        <ContainerHeader title="Manage Leads" />
        <Row>
          <Col span={24}>
            <Spin spinning={this.state.isSearching}>
              <Card className="gx-card" title="Search Leads">
                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleSearch}
                >

                  <Row>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="List Name">
                          {getFieldDecorator('drpListName', {
                            initialValue: "-1",
                            rules: [{ required: false, message: 'Please select a List!' }],
                          })(
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select a List"
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                              <Option value="-1">All</Option>
                              {this.props.leadDataListDetails != null && this.props.leadDataListDetails.map((listDataList, key) => {
                                return <option key={key} value={listDataList.listId}>{listDataList.listName}</option>;
                              })}

                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="DNC Status">
                          {getFieldDecorator('drpDNCType', {
                            initialValue: "-1",
                            rules: [{ required: false, message: 'Please select a DNC Status!' }],
                          })(
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select a DNC Status"
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                              <Option value="-1">All</Option>
                              <Option value="">Not Washed</Option>
                              <Option value="Y">Yes</Option>
                              <Option value="N">No</Option>

                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="State" >
                          {getFieldDecorator('drpState', {
                            rules: [{ required: false, message: 'Please select a State!' }],
                          })(
                            <Select
                              showSearch
                              mode="multiple"
                              style={{ width: "100%" }}
                              placeholder="Select a State"
                            >
                              <Option value="VIC">VIC</Option>
                              <Option value="NSW">NSW</Option>
                              <Option value="QLD">QLD</Option>
                              <Option value="ACT">ACT</Option>
                              <Option value="SA">SA</Option>
                              <Option value="WA">WA</Option>
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Contact Number" >
                          {getFieldDecorator('txtContactNumber', {
                            rules: [{ required: false, message: 'Please input Contact number!' }],
                          })(
                            <Input style={{ width: '100%' }} placeholder="Enter Contact Number" />
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Type of Contact" >
                          {getFieldDecorator('drpTypeOfContact', {
                            initialValue: "All",
                            rules: [{ required: false, message: 'Please input Contact number!' }],
                          })(
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select a DNC Status"
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                              <Option value="-1">All</Option>
                              <Option value="Mobile">Mobile</Option>
                              <Option value="Home">Home</Option>
                              <Option value="Work">Work</Option>

                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" htmlType="submit">Search</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                          Clear
                      </Button>
                      </center>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Spin>
          </Col>

          {!this.state.showAddNewLeadPanel &&
            <Col span={24}>
              <Button onClick={this.handleAdd} disabled type="primary" style={{ marginBottom: 16 }}>
                Add New Lead
              </Button>
              <Button onClick={this.handleUploadList} type="primary" style={{ marginBottom: 16 }}>
                Upload Lead List
              </Button>
            </Col>
          }

          {this.state.showUploadLeadsPanel &&
            <Col span={24}>
              <Spin spinning={this.state.uploading}>
                <Card className="gx-card" title="Lead Data Upload Details">
                  <Form
                    className="ant-advanced-search-form"
                    onSubmit={this.handleSearch}
                  >
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={24}>
                        <div className="gx-form-row0">
                          <FormItem {...formItemLayout} label="List">
                            {getFieldDecorator('listName', {
                              rules: [{ required: true, message: 'Please select a list to upload data to!' }],
                              onChange: (e) => this.handleListNameChange(e, 'listName')
                            })(
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Select a List"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              >
                                <Option value="-1">Select</Option>
                                <Option value="0">New</Option>
                                {this.props.leadDataListDetails != null && this.props.leadDataListDetails.map((listDataList, key) => {
                                  return <option key={key} value={listDataList.listId}>{listDataList.listName}</option>;
                                })}
                              </Select>
                            )}
                          </FormItem>
                        </div>
                      </Col>
                      {this.state.fields.listName.value === "0" && <Col lg={12} md={12} sm={12} xs={24}>
                        <div className="gx-form-row0">
                          <FormItem
                            {...formItemLayout}
                            label={(
                              <span>
                                New List Name&nbsp;
                                            <Tooltip title="Since you are creating a new list, provide a friendly name for referral.">
                                  <Icon type="question-circle-o" />
                                </Tooltip>
                              </span>
                            )}
                          >
                            {getFieldDecorator('newListName', {
                              rules: [{ required: this.state.fields.listName.value === 0 ? true : false, message: 'Please enter a List Name!', whitespace: true }],
                              onChange: (e) => this.handleNewListNameChange(e, 'newListName')
                            })(
                              <Input />
                            )}
                          </FormItem>
                        </div>
                      </Col>
                      }
                      <Col lg={12} md={12} sm={12} xs={24}>
                        <div className="gx-form-row0">
                          <FormItem
                            {...formItemLayout}
                            label={(
                              <span>
                                Source&nbsp;
                                            <Tooltip title="Where was this data sourced from?">
                                  <Icon type="question-circle-o" />
                                </Tooltip>
                              </span>
                            )}
                          >
                            {getFieldDecorator('source', {
                              rules: [{ required: false, message: 'Please enter a source for the data!', whitespace: true }],
                              onChange: (e) => this.handleSourceChange(e, 'source')
                            })(
                              <Input />
                            )}
                          </FormItem>
                        </div>
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={24}>
                        <div className="gx-form-row0">
                          <FormItem
                            {...formItemLayout}
                            label={(
                              <span>
                                Wash ext.&nbsp;
                                            <Tooltip title="Does this list require washing from the Government DNCR?">
                                  <Icon type="question-circle-o" />
                                </Tooltip>
                              </span>
                            )}
                          >
                            {getFieldDecorator('reqextwash', {
                              rules: [{ required: false }],
                              onChange: (e) => this.handleRequireWashChange(e, 'reqextwash')
                            })(
                              <Switch />
                            )}
                          </FormItem>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={7}>
                      </Col>
                      <Col span={10} className="gx-text-right">
                        <center>
                          <Button onClick={this.handleReset}>
                            Reset
                                    </Button>
                          <Button onClick={this.onUploadCancel}>
                            Cancel
                                    </Button>
                        </center>
                      </Col>
                      <Col span={7}>
                      </Col>
                    </Row>
                  </Form>
                </Card>
                {this.state.showUploadPanel && <Card className="gx-card" title="Drag and Drop CSV File here">
                  <p>Please make sure to upload only .csv files. You can <a target="_blank" href="/files/leadDataUpload_sample.csv" download>download</a> a sample format <a target="_blank" href="/files/leadDataUpload_sample.csv" download>here</a></p>
                  <Dragger {...draggerProps}>
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">Click or drag csv file to this area to upload</p>
                    <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                  </Dragger>
                </Card>
                }

              </Spin>

            </Col>
          }


          {this.state.showAddNewLeadPanel && <Col span={24}>
            <Spin spinning={this.state.isAdding}>
              <Card className="gx-card" title="Add New Leads">
                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleAddDistributorTranslationDetails}
                >
                  <Row>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Retailer">
                          {getFieldDecorator('drpRetailerAdd', {
                            rules: [{ required: true, message: 'Please select a Retailer!' }],
                          })(
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select a Retailer"
                            >
                              <Option value="-1">Select</Option>
                              {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                return <option key={key} value={retailer.id}>{retailer.retailerName}</option>;
                              })}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Retailer Distributor Name" >
                          {getFieldDecorator('txtRetailerDistributorNameAdd', {
                            rules: [{ required: true, message: 'Please enter Retailer Distributor Name!' }],
                          })(
                            <Input style={{ width: '100%' }} placeholder="Retailer Distributor Name" />
                          )}
                        </FormItem>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="EW Distributor Map">
                          {getFieldDecorator('drpDistributorAdd', {
                            rules: [{ required: true, message: 'Please select a EW Distributor to Map!' }],
                          })(
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select a EW Distributor"
                            >
                              <Option value="-1">Select</Option>
                              {!isEmpty(this.props.distributorList) && this.props.distributorList.map((distributor, key) => {
                                return <option key={key} value={distributor.id}>{distributor.distributorName}</option>;
                              })}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" htmlType="submit">Add Distributor Translation Details</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.onAddCancel}>
                          Cancel
                    </Button>
                      </center>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Spin>
          </Col>
          }
          <Col span={24}>
            <Card className="gx-card" title="All Lead Details">

              <EditableContext.Provider value={this.props.form}>
                <Table
                  components={components}
                  rowClassName="editable-row"
                  className="gx-table-responsive"
                  {...this.state}
                  columns={columns}
                  dataSource={dataSource} />
              </EditableContext.Provider>
            </Card>
          </Col>
        </Row>
        <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
        <SweetAlert show={this.state.showWarning}
          warning
          showCancel
          confirmBtnText="Yes Delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.handleDelete}
          onCancel={this.onCancelDelete}
        ></SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  recordUpdateStatus: state.users.recordUpdateStatus,
  distributorTranslationList: state.distributorTranslations.distributorTranslationList,
  retailerList: state.retailers.retailerList,
  distributorList: state.distributors.distributorList,

  leadDataListDetails: state.leadData.leadDataListDetails,
  leadDataUploadStatus: state.leadData.leadDataUploadStatus,

  leadDataList: state.leadData.leadDataList,
});

const mapDispatchToProps = dispath => ({
  getDistributorTranslationList: (data) => dispath(getDistributorTranslationList(data)),
  updateDistributorTranslationList: (data) => dispath(updateDistributorTranslationList(data)),
  addDistributorTranslationList: (data) => dispath(addDistributorTranslationList(data)),
  deleteDistributorTranslationList: (data) => dispath(deleteDistributorTranslationList(data)),

  getDistributorList: (data) => dispath(getDistributorList(data)),
  getRetailerList: (data) => dispath(getRetailerList(data)),

  uploadLeadData: (info, file) => dispath(uploadLeadData(info, file)),
  getLeadDataListNames: (data) => dispath(getLeadDataListNames(data)),

  getLeadData: (data) => dispath(getLeadData(data)),
});

const WrappedAdvancedSearch = Form.create({
  onValuesChange(_, values) {
    //console.log(values);
  },
})(ManageLeads);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);