import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';
import isEmpty from "util/isEmpty"

export const saveMIRNSearchResults = mirnList => ({
    type: 'SAVE_MIRN_SEARCH_RESULTS',
    mirnList,
});

export const error = error => ({
    type: 'ERROR',
    error,
});

const config = {
    headers: {
        'x-api-key': '2G3CtlTJj78tAQQRM4EYB2zeYlRY90SP3zLfHH0n',
        'Content-Type': 'application/json',
    }
}

export const clearErrors = (data) => dispath => {

    dispath(error(null));
}

export const clearAll = () => dispath => {

    dispath(saveMIRNSearchResults(null));
    dispath(error(null));

}


export const getRecordsByMIRN = (mirnNumber, checkDigit) => dispath => {

    dispath(saveMIRNSearchResults(null));
    axios.get(`https://n1ecc3lav3.execute-api.ap-southeast-2.amazonaws.com/prod/mirn?mirn=${mirnNumber}&checkDigit=${checkDigit}`, config)
        .then(res => {

            let tempArray = [];

            if (isEmpty(res.data.data.result)) {
                dispath(error(res.data.data.message));
            }
            else {
                if (Array.isArray(res.data.data.result)) {
                    tempArray = res.data.data.result
                }
                else {
                    tempArray.push(res.data.data.result)
                }
    
                dispath(saveMIRNSearchResults(tempArray));
            }

            
        })
        .catch(err => {
            dispath(error(!isEmpty(err.response.data.message) ? err.response.data.message : 'ERROR'));
        });
}

export const getRecordsByAddress = (data) => dispath => {

    dispath(saveMIRNSearchResults(null));
    axios.get(`https://n1ecc3lav3.execute-api.ap-southeast-2.amazonaws.com/prod/addresssearch?${qs.stringify(data)}`, config)
        .then(res => {

            let tempArray = [];
            
            if (isEmpty(res.data.data.result)) {
                dispath(error(res.data.data.message));
            }
            else {
                if (Array.isArray(res.data.data.result)) {
                    tempArray = res.data.data.result
                }
                else {
                    tempArray.push(res.data.data.result)
                }
    
                dispath(saveMIRNSearchResults(tempArray));
            }
        })
        .catch(err => {
            dispath(error(!isEmpty(err.response.data.message) ? err.response.data.message : 'ERROR'));
        });
}