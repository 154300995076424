import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';

export const saveDiallerPackList = diallerPackList => ({
    type: 'SAVE_DIALLER_PACK_LIST',
    diallerPackList,
});

export const saveDiallerPackGenerationStatus = diallerPackGenerationStatus => ({
    type: 'SAVE_DIALLER_PACK_GENERTION_STATUS',
    diallerPackGenerationStatus,
});

export const saveWashersAvailableCount = washersAvailable => ({
    type: 'SAVE_WASHERS_AVAILABLE_COUNT',
    washersAvailable,
});

export const saveRecordUpdateStatus = recordUpdateStatus => ({
    type: 'SAVE_RECORD_UPDATE_STATUS',
    recordUpdateStatus,
});


export const error = error => ({
    type: 'ERROR',
    error,
});

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const getDiallerPacks = (data) => dispath =>{

    axios.get(apidetails.API_URL + 'diallerPacks/')
    .then(res => {
        dispath(saveDiallerPackList(res.data.diallerPacks));
    })
    .catch(err => {
        dispath(error(err.message || 'ERROR'));
    });    
}

export const updateDiallerPacks = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'diallerPacks/update', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.message || 'ERROR'));
    });  
}

export const createNewDiallerPack = (data) => dispath =>{
    
    dispath(saveDiallerPackGenerationStatus("Washing and Exporting.. Please Wait.."));
    axios.post(apidetails.API_URL + 'diallerPacks/newDiallerPack', qs.stringify(data), config)
    .then(res => {
        dispath(saveDiallerPackGenerationStatus(res.data));
        dispath(saveDiallerPackGenerationStatus(null));
    })
    .catch(err => {
        dispath(error(err.message || 'ERROR'));
    });  
}

export const getWashersAvailableCount = (data) => dispath =>{
    
    dispath(saveWashersAvailableCount("Fetching"));
    axios.get(apidetails.API_URL + 'diallerPacks/remainingWashCount', qs.stringify(data), config)
    .then(res => {
        dispath(saveWashersAvailableCount(res.data.records));
    })
    .catch(err => {
        dispath(error(err.message || 'ERROR'));
    });  
}