import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Tag, Input, Row, Button, Select,InputNumber, Popconfirm, Switch, Collapse, Icon} from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getUsers, updateUserAccessLevel, getToolsUsers, getAllVelocifyUsers, createNewToolsUser } from "appRedux/actions/admin/users";
import { getAccessLevels } from "appRedux/actions/admin/accessLevels";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);


//const title = () => 'Here is title';
const showHeader = true;
//const footer = () => 'Here is footer';
//const scroll = {y: 500};
const pagination = { position: 'bottom' };

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  

  getInput = () => {
    const {
      dataIndex,
      record,
    } = this.props;

    function onChange(checked) {
    }

    if(this.props.inputType === 'accessLevelDropDown') {
      return  <Select
                showSearch
                style={{ width: '100%', minWidth: 300 }}
                placeholder="Select Access Level"
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value="-1">Select</Option>
                {!isEmpty(this.props.accessLevelList) && this.props.accessLevelList.map((accessLevel, key) => {
                    return <option key={key} value={accessLevel.id}>{accessLevel.id} - {accessLevel.name}</option>;
                })}
              </Select>;
    }
    else if (this.props.inputType === 'statusSelection') {

      return <Switch disabled={false} defaultChecked={record[dataIndex] == 1 ? true : false} onChange={onChange} />;

    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    function getInitialValue(dataIndex) {
      if (dataIndex == "isActive") {
        return record[dataIndex] == 1 ? true : false;
      }
      else {
        return record[dataIndex];
      }
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 , width: '100%', display:'block',}}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: getInitialValue(dataIndex),
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class ManageToolsUsers extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editingKey: '',
      isAdding : false,
      isSearching : false,
      isSearchingVelocifyUsers : false,
      recordsEdited: false,
      expand: false,
      dataSource: [],
      addingNewRecord : false,
      showAddEditPanel: false,
      formFunction: null,
      isAddingUpdating: false,
      showError: false,
      errorMessage: "",
      showWarning: false,
      warningMessage: "",
      showSuccess: false,
      successMessage: "",
      internalDNCToSearch: {
        dncType: null,
        number: null,
      },
      bordered: true,
      loading: false,
      pagination,
      size: 'middle',
      title: undefined,
      showHeader,
      footer: undefined,
      rowSelection: {},
    };
    this.columns = [
      
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        width: 100,
        render: (text, record) =>{
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <center>
                <EditableContext.Consumer>
                  {form => (
                    <a
                      onClick={() => this.save(form, record.key)}
                      
                    >
                      <i className="icon icon-check-circle-o"/>
                    </a>
                  )}
                </EditableContext.Consumer>
                <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                  <a style={{ marginLeft: 10 }}><i className="icon icon-close-circle"/></a>
                </Popconfirm>
              </center>
            </span>
          ) : (
            <span>
              <center>
                <a disabled={true} onClick={() => this.edit(record.key)}>
                  <i className="icon icon-edit"/>
                </a>
                {/* <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
                  <i className="icon icon-edit"/>
                </a> */}
                {/* <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.confirmDelete(record.id, record.postcode)}>
                  <i className="icon icon-trash"/>
                </a> */}
              </center>
            </span>
          );
        },
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 80,
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Velocify Agent ID',
        dataIndex: 'agent_id',
        key: 'agent_id',
        align: 'center',
        width: 80,
        sorter: (a, b) => a.agent_id - b.agent_id,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: 100,
        sorter: (a, b) => {return a.name.localeCompare(b.name)},
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        align: 'center',
        width: 100,
        sorter: (a, b) => {return a.email.localeCompare(b.email)},
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 150,  
        editable: true,
        sorter: (a, b) => a.status - b.status,
        render: (text, record) => {
          return <Switch disabled={true} checked={text == 1 ? true : false} onChange={{}} />
        }
      },
      {
        title: 'Created Date',
        dataIndex: 'created',
        key: 'created',
        align: 'center',
        width: 150,
        sorter: (a, b) => a.created - b.created,
        render: (text, record) => {
          return <Tag color={"geekblue"} key={text}>
                  {text.toUpperCase()}
                </Tag>
        }
      },
    ];

  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };
  
  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.dataSource];
      const index = newData.findIndex(item => key === item.key);   

      if (index > -1) {
        const item = newData[index];

        const valuesToUpdate = {
          id : item.id,
          accessLevel : row.accessLevel,
          isActive : row.isActive ? 1 : 0,
        }
  
        this.setState({ loading: true });
        this.props.updateUserAccessLevel(valuesToUpdate); 

        this.setState({ 
          editingKey: '',
          recordsEdited:true });
      } 
    });
  }

  edit(key) {
    //this.setState({ editingKey: key });
  }

  onConfirm = () => {
    this.setState({ 
      showSuccess: false,
      showAddEditPanel : false,
    });

    setTimeout(() => {        
        this.handleReset();
    }, 1000);

    
  };

  onErrorConfirm = () => {
    this.setState({ 
      showError: false,
      showAddEditPanel : false,
      dataSource: [],
      isSearching: true,
      loading: true,
    });
    
        
    setTimeout(() => {  
      this.props.getToolsUsers();
    }, 1000);
  };

  componentDidMount() {

    this.setState({
        dataSource: [],
        isSearching: true,
        loading: true,
      })

     setTimeout(() => {        
        this.props.getToolsUsers();
    }, 1000);


  }

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      const filtersForSearch = {
        ...(!isEmpty(values.txtName) && { name: values.txtName }),
        ...(!isEmpty(values.txtEmail) && { email: values.txtEmail }),
      }

      this.setState({
        dataSource: [],
        isSearching: true,
        loading: true,
      })

      setTimeout(() => {      
          
        if(!isEmpty(filtersForSearch))
            this.props.getToolsUsers(filtersForSearch);
        else
            this.props.getToolsUsers();

    }, 1000);
    });
  };

  handleReset = () => {

    this.setState({
      dataSource: [],
      isSearching: true,
      loading: true, 
    })


    this.props.form.resetFields();

    setTimeout(() => {        
        this.props.getToolsUsers();
    }, 1000);


  };

  checkColoumnType = (field) => {
    switch(field)
    {
      case 'status': return "statusSelection";
      case 'accessLevel' : return "accessLevelDropDown";
      default : return "text";
    }
  }

  handleAdd = () => {
        this.setState({
            showAddEditPanel: true,
            showSearchPanel: false,
            formFunction: "Add",
            isSearchingVelocifyUsers: true,
        });

        setTimeout(() => {   

            this.props.getAllVelocifyUsers();
        }, 1000);

    };

    removeSearchValuesFromPayload = (values) => {

        delete values.txtName;
        delete values.txtEmail;

        return values;
    };

    handleAddRecord = (e) => {

        e.preventDefault();
        this.props.form.validateFields((err, values) => {

            

            if (!err) {
                values = this.removeSearchValuesFromPayload(values);

                var velocifyUserObj = (this.props.velocifyUserList.find(obj => obj._attributes.AgentId == values.drpVelocifyUser))._attributes;

                var agentNameBreak = (velocifyUserObj.AgentName).split(",");

                var tempAgenObj = {
                    agent_id: velocifyUserObj.AgentId,
                    name: `${agentNameBreak[1].replace(/\s/g, '')} ${agentNameBreak[0].replace(/\s/g, '')}`,
                    email: velocifyUserObj.AgentEmail,
                }

                this.setState({
                    isAddingUpdating: true,
                    dataSource: [],
                });

                setTimeout(() => {   

                    this.props.createNewToolsUser(tempAgenObj);

                }, 1000);

                
            }


        });

    }

    onAddUpdateCancel = () => {
        this.setState({
            showAddEditPanel: false,
            showSearchPanel: true,
        });
        this.handleReset();
    };

  componentWillReceiveProps(nextProps) {
    if(!isEmpty(nextProps.toolsUserList) && this.state.isSearching == true)
    {
      let count = 1;      
      nextProps.toolsUserList.map((user, key) => {
        let record = {
          key: count.toString(),
          id: user.id,
          agent_id: user.agent_id,
          created: moment(user.created).format('YYYY-MM-DD h:mm:ss A'),
          name: !isEmpty(user.name) ? user.name : "-",
          email: user.email,
          status: !isEmpty(user.status) ? user.status : 1,
        };
        this.state.dataSource.push(record);
        count++; 
      });
      this.setState({ 
          loading: false, 
          isSearching: false  
        });
    }
    // else
    // {
    //     this.setState({ 
    //         loading: false, 
    //         isSearching: false  
    //     });
    // }

    if(!isEmpty(nextProps.velocifyUserList) && this.state.isSearchingVelocifyUsers == true)
    {
        this.setState({ 
            isSearchingVelocifyUsers: false  
          });
    }

    if(!isEmpty(nextProps.recordUpdateStatus))
    {
      if(nextProps.recordUpdateStatus.status == "Success" && this.state.showSuccess == false)
      {
        this.setState({
            successMessage: nextProps.recordUpdateStatus.message,
            showSuccess: true,
            isAdding: false,
            isAddingUpdating: false,
        });
        this.props.getToolsUsers();
      }
    }

    if(!isEmpty(nextProps.error))
    {
        this.setState({
            errorMessage: nextProps.error,
            showError: true,
        });
    }

  }


  render() {

    let { dataSource } = this.state;

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          accessLevelList: this.props.accessLevelList,
          inputType: this.checkColoumnType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-main-content">
        <ContainerHeader title="Manage Users"  />
        <Row>
          <Col span={24}>
          <Spin spinning={this.state.isSearching}>
            <Card  className="gx-card" title="Search User">
              <Form
                className="ant-advanced-search-form"
                onSubmit={this.handleSearch}
              >
                <Row>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Name" >
                        {getFieldDecorator('txtName', {
                          rules: [{ required: false, message: 'Enter a Name!' }],
                        })(
                          <Input 
                            
                            style={{ width: '100%' }} placeholder="Enter a Name" />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Email" >
                        {getFieldDecorator('txtEmail', {
                          rules: [{ required: false, message: 'Enter an Email!' }],
                        })(
                          <Input 
                          
                          style={{ width: '100%' }} placeholder="Enter an Email" />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col span={24} className="gx-text-right">
                    <center>
                      <Button type="primary" htmlType="submit">Search</Button>
                      <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                        Clear
                    </Button>
                    </center>
                  </Col>
                </Row>
              </Form>
            </Card>
            </Spin>
          </Col>
          {this.state.showAddEditPanel && <Col span={24}>
            <Spin spinning={this.state.isAddingUpdating}>
                <Card className="gx-card" title={`Add New User Details`} style={{ maxHeight: "650px", overflow: "hidden" }}>
                    <Form
                        className="ant-advanced-search-form"
                        style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                    >
                        <Collapse accordion defaultActiveKey={['1']}>
                            <Panel header="Basic Details" key="1">
                                <Row>
                                    <Col lg={8} md={8} sm={12} xs={24}>
                                        <div className="gx-form-row0">
                                            <Spin spinning={this.state.isSearchingVelocifyUsers}>
                                                <FormItem {...formItemLayout} label="Velocify User" >
                                                    {getFieldDecorator('drpVelocifyUser', {
                                                        rules: [{ required: true, message: 'Please select a Velocify User!' }],
                                                    })(
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Velocify User"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        >
                                                            {!isEmpty(this.props.velocifyUserList) && this.props.velocifyUserList.map((velocifyUser, key) => {
                                                                return <option key={key} value={velocifyUser._attributes.AgentId}>{velocifyUser._attributes.AgentName}</option>;
                                                            })}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </Spin>
                                        </div>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>

                    </Form>
                    <Row>
                        <Col span={24} className="gx-text-right">
                            <center>
                                {this.state.formFunction == "Add" &&
                                    <Button type="primary" onClick={this.handleAddRecord}>
                                        Add
            </Button>
                                }
                                <Button style={{ marginLeft: 8 }} onClick={this.onAddUpdateCancel}>
                                    Cancel
            </Button>
                            </center>
                        </Col>
                    </Row>
                </Card>
            </Spin>
        </Col>
        }
          <Col span={24}>
            <Card className="gx-card" title="User Details">
                <Button onClick={this.handleAdd} type="primary" className="gx-btn-cyan" style={{ marginBottom: 16 }}>
                    <Icon type="plus" />  Add New User
                </Button>
                <EditableContext.Provider value={this.props.form}>
                    <Table 
                    components={components}
                    rowClassName="editable-row"
                    className="gx-table-responsive" 
                    {...this.state} 
                    columns={columns} 
                    dataSource={dataSource} />
                </EditableContext.Provider>
            </Card>
          </Col>
        </Row>
        <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
        <SweetAlert show={this.state.showError} danger title={this.state.errorMessage}
          onConfirm={this.onErrorConfirm}>
        </SweetAlert>
        {/* <SweetAlert show={this.state.showWarning}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Are you sure?"
                    onConfirm={this.handleDelete}
                    onCancel={this.onCancelDelete}
        ></SweetAlert> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userList: state.users.userList,
  accessLevelList: state.accesslevels.accessLevelList,
  recordUpdateStatus: state.users.recordUpdateStatus,

  toolsUserList: state.users.toolsUserList,
  velocifyUserList: state.users.velocifyUserList,

  error: state.users.error,
});

const mapDispatchToProps = dispath => ({

  getUsers: (data) => dispath(getUsers(data)),
  getAccessLevels: (data) => dispath(getAccessLevels(data)),
  updateUserAccessLevel: (data) => dispath(updateUserAccessLevel(data)),

  getToolsUsers: (data) => dispath(getToolsUsers(data)),
  getAllVelocifyUsers: (data) => dispath(getAllVelocifyUsers(data)),
  createNewToolsUser: (data) => dispath(createNewToolsUser(data)),
});

const WrappedAdvancedSearch = Form.create()(ManageToolsUsers);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);