import React, {Component} from "react";
import { connect } from "react-redux";
import { Icon } from "antd";
import isEmpty from "util/isEmpty";

class WelComeCard extends Component {

  render() {

    const { userDetails } = this.props;

    return (

      !isEmpty(userDetails) && <div className="gx-wel-ema gx-pt-xl-2">
        <h1 className="gx-mb-3">Welcome {userDetails.name}!</h1>
        <p className="gx-fs-sm gx-text-uppercase">Exciting things are on its way to the new Electricity Wizard Content Management and Information System.</p>
        {/* <p className="gx-fs-sm gx-text-uppercase">You Have</p>
        <ul className="gx-list-group">
          <li>
            <Icon type="message" />
            <span>5 Unread messages</span>
          </li>
          <li>
            <Icon type="mail" />
            <span>2 Pending invitations</span>
          </li>
          <li>
            <Icon type="profile" />
            <span>7 Due tasks</span>
          </li>
          <li>
            <Icon type="bell" />
            <span>3 Other notifications</span>
          </li>
        </ul> */}
      </div>

    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { userDetails } = auth;
  return { userDetails }
};

export default connect(mapStateToProps)(WelComeCard);
