import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Input, Row, Button, Select, Popconfirm, Switch, Tag, Modal, Tooltip, Icon } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getSalesToProcess, processSale, getOrderUpdates, clearSales, resetAlintaTokenFn } from "appRedux/actions/sales/processSales";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { is } from "immutable";
import Swal from "sweetalert2";

const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
let retailerState = "";

var globalSalesToProcess = {};

//const title = () => 'Here is title';
const showHeader = true;
//const footer = () => 'Here is footer';
//const scroll = {y: 500};
const pagination = { position: 'bottom' };

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const formItemLayout2 = {
  labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      style: { textAlign: "left" },
  },
  wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
  },
};

const EditableContext = React.createContext();

function sendSingleSaleToProcess(saleKeys, key, reportId, userId, dataSource, processSale) {        
  setTimeout(function () {
      
    const saleToProcess = {
      reportId: reportId,
      userId: userId,
      salePayLoad: dataSource.find(data => data.key == saleKeys[key]).actualPayLoadData,
      batchId: dataSource.find(data => data.key == saleKeys[key]).batchNumber,
      batchRef: dataSource.find(data => data.key == saleKeys[key]).batchNumberRef,
    }

    processSale(saleToProcess);
    
    if ((key + 1) < saleKeys.length) {         
      sendSingleSaleToProcess(saleKeys, key+1, reportId, userId, dataSource, processSale);             
    }                      
  }, 1500)
}

class EditableCell extends React.Component {

  getInput = () => {
    const {
      dataIndex,
      record,
    } = this.props;

    function onChange(checked) {
    }

    function handleChange(value) {
      retailerState = value.toString().split(',').join('|');
    }

    if (this.props.inputType === 'statusSelection') {
      return <Switch disabled={false} defaultChecked={record.isActive == 1 ? true : false} onChange={onChange} />;
    }
    else if (this.props.inputType === 'stateSelection') {
      return <Select
        style={{ width: '250px' }}
        placeholder="Please select State"
        onChange={handleChange}
      >
        <Option key="VIC">VIC</Option>
        <Option key="NSW">NSW</Option>
        <Option key="ACT">ACT</Option>
        <Option key="QLD">QLD</Option>
        <Option key="SA">SA</Option>
        <Option key="WA">WA</Option>
      </Select>;
    }
    return <Input style={{ minWidth: '150px' }} />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    function getInitialValue(dataIndex) {
      if (dataIndex == "isActive") {
        return record[dataIndex] == 1 ? true : false;
      }
      else if (dataIndex == "state") {
        retailerState = record[dataIndex];
        return record[dataIndex].split("|");
      }
      else {
        return record[dataIndex];
      }
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0, width: '100%', display: 'block', }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: getInitialValue(dataIndex),
            })(this.getInput())}
          </Form.Item>
        ) : (
            children
          )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class ProcessSales extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showAlintaResetBtn: false,
      editingKey: '',
      isAdding: false,
      isExtracting: false,
      isProcessingSales: false,
      isFetchingOrderUpdates: false,
      currentReportId: null,

      singleLeadExtract: false,

      showInformation: false,
      informationDescription: "",
      informationTitle: "",

      selectedRowKeys: [],
      selectedRowKeysUpdated: 0,

      isSearching: false,
      recordsEdited: false,
      expand: false,
      dataSource: [],
      addingNewRecord: false,
      distributorRecordIdToDelete: null,
      showAddNewDistributorPanel: false,
      showWarning: false,
      warningMessage: "",
      showSuccess: false,
      successMessage: "",
      bordered: true,
      loading: false,
      pagination,
      size: 'middle',
      title: undefined,
      showHeader,
      footer: undefined,
      rowSelection: {},
    };
    this.columns = [
      {
        title: 'Record ID',
        dataIndex: 'recordID',
        key: 'recordID',
        align: 'center',
        width: 80,
        sorter: (a, b) => a.recordID - b.recordID,
      },
      {
        title: 'Velocify Status',
        dataIndex: 'velocifyStatus',
        key: 'velocifyStatus',
        align: 'center',
        width: 220,
      },
      {
        title: 'Order Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 220,
        render: (text, record) => {

          var textColor = "";

          switch (text) {
            case "Order successfully received by AGL for processing.":
              textColor = "#009688";
              break; //Validated by AGL
            case "Order successfully Submitted to Alinta.":
              textColor = "#009688";
              break; //Validated by Alinta
            case "Order successfully received by Origin for processing.":
              textColor = "#009688";
              break; //Validated by Origin
            case "Order successfully completed by Origin.":
              textColor = "#009688";
              break; //Validated by Origin
            case "Order successfully Accepted by Alinta.":
              textColor = "#009688";
              break; //Validated by Alinta
            case "Order successfully received by Lumo for processing.":
              textColor = "#009688";
              break; //Validated by Lumo
            case "Order successfully received by OVO for processing.":
              textColor = "#009688";
              break; //Validated by OVO
            case "Data validation in progress, please wait for confirmation.":
              textColor = "#ff9800";
              break; //Sent to Retailer
            case "Contain Errors!":
              textColor = "#ff4d4f";
              break;
            case "Contain Warnings!":
              textColor = "#ffc800";
              break;
            case "Contain Errors and Warnings!":
              textColor = "#ff4d4f";
              break;
            case "Order Errors Found. Please Fix and Resubmit":
              textColor = "#03a9f4";
              break;
            case "Order has been Parked by Alinta.":
              textColor = "#03a9f4";
              break;
            case "Error submitting Order. Please refere to Processing Errors":
              textColor = "#ff4d4f";
              break;
            case "Order cancelled by Origin. Please see cancellation notes":
              textColor = "#ff4d4f";
              break; //Origin Cancellation
            case "Error submitting Order. Please try again in a few minutes":
              textColor = "#ff4d4f";
              break;
            case "Ready for Processing":
              textColor = "#795548";
              break;
          }

          return <span style={{ color: textColor }}>{text}</span>
        },
      },
      {
        title: 'Velocify Lead ID',
        dataIndex: 'velocifyLeadID',
        key: 'velocifyLeadID',
        align: 'center',
        width: 220,
        sorter: (a, b) => a.velocifyLeadID - b.velocifyLeadID,
        render: (text, record) => {
          var url = `https://lm.prod.velocify.com/Web/LeadAddEdit.aspx?LeadId=${text}`;
          return <a href={url} target="_blank">{text}</a>
        },
      },
      {
        title: 'Transaction Type',
        dataIndex: 'transactionType',
        key: 'transactionType',
        align: 'center',
        width: 150,
        editable: false,
        sorter: (a, b) => { return a.transactionType.localeCompare(b.transactionType) },
      },
      {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName',
        align: 'center',
        width: 150,
        editable: false,
        sorter: (a, b) => { return a.customerName.localeCompare(b.customerName) },
      },
      {
        title: 'Customer Type',
        dataIndex: 'customerType',
        key: 'customerType',
        align: 'center',
        width: 150,
        editable: false,
        sorter: (a, b) => { return a.customerType.localeCompare(b.customerType) },
      },
      {
        title: 'Product Elec',
        dataIndex: 'productDescElec',
        key: 'productDescElec',
        align: 'center',
        width: 150,
        editable: false,
        sorter: (a, b) => { return a.productDescElec.localeCompare(b.productDescElec) },
      },
      {
        title: 'Product Gas',
        dataIndex: 'productDesceGas',
        key: 'productDesceGas',
        align: 'center',
        width: 150,
        editable: false,
        sorter: (a, b) => { return a.productDesceGas.localeCompare(b.productDesceGas) },
      },
      {
        title: 'Payload Data',
        dataIndex: 'payloadData',
        key: 'payloadData',
        align: 'center',
        width: 150,
        editable: false,
        sorter: (a, b) => { return a.payloadData.localeCompare(b.payloadData) },
        render: (text, record) => {
          return <Button type="primary" style={{ marginLeft: 8 }} onClick={() => this.handleShowPayLoadData(record.recordID)}>
            View
                </Button>
        },
      },
      {
        title: 'Validation Errors/Warnings',
        dataIndex: 'errorList',
        key: 'errorList',
        align: 'center',
        width: 150,
        editable: false,
        sorter: (a, b) => { return a.errorList.localeCompare(b.errorList) },
        render: (text, record) => {
          return <span>
            {!isEmpty(record.errorList) &&
              <Button type="danger" style={{ marginLeft: 8 }} onClick={() => this.handleShowErrorListData(record.recordID)}>
                View Errors
                </Button>
            }
            {!isEmpty(record.warningList) &&
              <Button type="danger" style={{ marginLeft: 8 }} onClick={() => this.handleShowWarningListData(record.recordID)}>
                View Warnings
                </Button>
            }
          </span>
        },
      },
      {
        title: 'Processing Notes/Errors',
        dataIndex: 'processingErrors',
        key: 'processingErrors',
        align: 'center',
        width: 150,
        editable: false,
        sorter: (a, b) => { return a.processingErrors.localeCompare(b.processingErrors) },
        render: (text, record) => {
          return !isEmpty(text) && <Button type="danger" style={{ marginLeft: 8 }} onClick={() => this.handleShowProcessingErrorListData(record.recordID)}>
            View
                </Button>
        },
      },
      {
        title: 'Order Notes/Errors',
        dataIndex: 'orderErrors',
        key: 'orderErrors',
        align: 'center',
        width: 150,
        editable: false,
        sorter: (a, b) => { return a.orderErrors.localeCompare(b.orderErrors) },
        render: (text, record) => {
          return !isEmpty(text) && <Button type="danger" style={{ marginLeft: 8 }} onClick={() => this.handleShowOrderErrorListData(record.recordID)}>
            View
                </Button>
        },
      },
    ];

  }


  onConfirm = () => {
    this.setState({
      showSuccess: false,
      successMessage: "",
      showInformation: false,
    });
    this.handleReset();
  };

  onProcessingConfirm = () => {
    this.setState({
      isProcessingSales: false,
    });
  };

  onFetchingConfirm = () => {
    this.setState({
      isFetchingOrderUpdates: false,
    });
  };

  handleAdd = () => {
    this.setState({
      showAddNewDistributorPanel: true,
    });
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleExtractSalesForProcessing = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      //console.log(err,values);
      if (!err) {
        //this.props.salesToProcess = [];
        //console.log("In")
        this.setState({
          dataSource: [],
        })
        const salesExtractDetails = {
          reportId: values.drpExtractReport,
        }

        if (values.drpExtractReport == "1235" || values.drpExtractReport == "1220" || values.drpExtractReport == "1358" || values.drpExtractReport == "1387" || values.drpExtractReport == "1393" || values.drpExtractReport == "1459" || values.drpExtractReport == "1461") {
          salesExtractDetails.leadID = values.txtLeadID;
          salesExtractDetails.addressOverride = values.txtAddressOverride ? true : false;
        }
          //console.log(salesExtractDetails);

          this.setState({
            isExtracting: true,
            loading: true,
            currentReportId: values.drpExtractReport
          });
          this.props.getSalesToProcess(salesExtractDetails);



      }


    });
  };

  handleStartProcessingSales = (e) => {
    if (this.state.selectedRowKeys.length > 0) {

      sendSingleSaleToProcess(this.state.selectedRowKeys, 0, this.state.currentReportId, this.props.userDetails.name, this.state.dataSource, this.props.processSale)

      this.setState({
        isProcessingSales: true,
      });

      // this.state.selectedRowKeys.map((saleKey, key) => {
       
      //   const saleToProcess = {
      //     reportId: this.state.currentReportId,
      //     userId: this.props.userDetails.name,
      //     salePayLoad: this.state.dataSource.find(data => data.key == saleKey).actualPayLoadData,
      //     batchId: this.state.dataSource.find(data => data.key == saleKey).batchNumber,
      //     batchRef: this.state.dataSource.find(data => data.key == saleKey).batchNumberRef,
      //   }

      //   this.setState({
      //     isProcessingSales: true,
      //   });       

      //   this.props.processSale(saleToProcess);
      // });
    }
  };

  // sendSaleToProcess = (saleToProcess) => {
  //   this.props.processSale(saleToProcess);
  // };

  onAddCancel = () => {
    this.setState({
      showAddNewDistributorPanel: false,
    });
    this.handleReset();
  };

  componentDidMount() {
  }

  handleSearch = (e) => {
    // e.preventDefault();
    // this.props.form.validateFields((err, values) => {

    //   const filtersForSearch = {
    //     email: values.txtEmail,
    //     name: values.txtName,
    //     accessLevel: values.drpAccessLevel,
    //   }
    //   this.setState({ isSearching: true });
    //   this.props.getUsers(filtersForSearch);
    // });
  };

  handleReset = () => {
    this.props.form.resetFields();
  };

  handleAlintaGetToken = () => {
    this.props.resetAlintaTokenFn();
    Swal.fire({
      title: 'Token Reset',
      text: 'Alinta token has been successfully reset.',
      icon: 'success',
    });
  };

  checkColoumnType = (field) => {
    switch (field) {
      case 'isActive': return "statusSelection";
      case 'state': return "stateSelection";
      default: return "text";
    }
  }

  handleShowPayLoadData = (recordID) => {

    var recordData = this.state.dataSource.find(data => data.recordID == recordID);

    // this.setState({
    //   showInformation: true,
    //   informationDescription: recordData.payloadData,
    //   informationTitle: "Payload Data",
    //  });

    Modal.info({
      title: 'Payload Data',
      width: '350',
      centered: true,
      maskClosable: true,
      content: (
        <div>
          <div style={{ overflow: 'auto', maxHeight: 450, minWidth: 600, maxWidth: 600 }}><pre style={{ whiteSpace: "pre-wrap" }}>{recordData.payloadData}</pre></div>
        </div>
      ),
      onOk() {
      },
    });
  };

  handleShowErrorListData = (recordID) => {

    var recordData = this.state.dataSource.find(data => data.recordID == recordID);

    // this.setState({
    //   showInformation: true,
    //   informationDescription: recordData.payloadData,
    //   informationTitle: "Payload Data",
    //  });

    Modal.info({
      title: 'Error List',
      width: '350',
      centered: true,
      maskClosable: true,
      content: (
        <div>
          <div style={{ overflow: 'auto', maxHeight: 450, minWidth: 600, maxWidth: 600 }}><pre style={{ whiteSpace: "pre-wrap" }}>{recordData.errorList}</pre></div>
        </div>
      ),
      onOk() {
      },
    });
  };

  handleShowWarningListData = (recordID) => {

    var recordData = this.state.dataSource.find(data => data.recordID == recordID);

    // this.setState({
    //   showInformation: true,
    //   informationDescription: recordData.payloadData,
    //   informationTitle: "Payload Data",
    //  });

    Modal.info({
      title: 'Error List',
      width: '350',
      centered: true,
      maskClosable: true,
      content: (
        <div>
          <div style={{ overflow: 'auto', maxHeight: 450, minWidth: 600, maxWidth: 600 }}><pre style={{ whiteSpace: "pre-wrap" }}>{recordData.warningList}</pre></div>
        </div>
      ),
      onOk() {
      },
    });
  };

  handleShowProcessingErrorListData = (recordID) => {

    var recordData = this.state.dataSource.find(data => data.recordID == recordID);

    // this.setState({
    //   showInformation: true,
    //   informationDescription: recordData.payloadData,
    //   informationTitle: "Payload Data",
    //  });

    Modal.info({
      title: 'Processing Error List',
      width: '350',
      centered: true,
      maskClosable: true,
      content: (
        <div>
          <div style={{ overflow: 'auto', maxHeight: 450, minWidth: 600, maxWidth: 600 }}><pre style={{ whiteSpace: "pre-wrap" }}>{recordData.processingErrors}</pre></div>
        </div>
      ),
      onOk() {
      },
    });
  };

  handleShowOrderErrorListData = (recordID) => {

    var recordData = this.state.dataSource.find(data => data.recordID == recordID);

    // this.setState({
    //   showInformation: true,
    //   informationDescription: recordData.payloadData,
    //   informationTitle: "Payload Data",
    //  });

    Modal.info({
      title: 'Order Error List',
      width: '350',
      centered: true,
      maskClosable: true,
      content: (
        <div>
          <div style={{ overflow: 'auto', maxHeight: 450, minWidth: 600, maxWidth: 600 }}><pre style={{ whiteSpace: "pre-wrap" }}>{recordData.orderErrors}</pre></div>
        </div>
      ),
      onOk() {
      },
    });
  };

  setSaleStatus = (saleStatus, errorList, warningList) => {

    var finalStatus = "";

    switch (saleStatus) {
      //AGL/PD
      case "BackEndValidationError":
        finalStatus =
          "Error submitting Order. Please refere to Processing Errors";
        break;
      case "BadRequest":
        finalStatus = "Error Processing Order. Please Speak to EW IT Team";
        break;
      case "Data validation in progress, please wait for confirmation.":
        finalStatus =
          "Data validation in progress, please wait for confirmation.";
        break;
      case "Errors in order":
        finalStatus = "Order Errors Found. Please Fix and Resubmit";
        break;
      case "Order successfully received by AGL for processing.":
        finalStatus = "Order successfully received by AGL for processing.";
        break;

      //Origin
      case "Submitted":
        finalStatus =
          "Data validation in progress, please wait for confirmation.";
        break;
      case "In Progress":
        finalStatus = "Order successfully received by Origin for processing.";
        break;
      case "error":
        finalStatus = "Order Errors Found. Please Fix and Resubmit";
        break;
      case "Complete (Electricity)":
        finalStatus = "Order successfully completed by Origin.";
        break;
      case "Complete (Gas)":
        finalStatus = "Order successfully completed by Origin.";
        break;
      case "In Progress (Electricity)":
        finalStatus = "Order successfully received by Origin for processing.";
        break;
      case "In Progress (Gas)":
        finalStatus = "Order successfully received by Origin for processing.";
        break;
      case "Cancelled (Electricity)":
        finalStatus =
          "Order cancelled by Origin. Please see cancellation notes.";
        break;
      case "Cancelled (Gas)":
        finalStatus =
          "Order cancelled by Origin. Please see cancellation notes.";
        break;

      // Lumo Energy
      case "Lumo Sale Submitted":
        finalStatus =
          "Data validation in progress, please wait for confirmation.";
        break;
      case "COMPLETED (Electricity)":
        finalStatus = "Order successfully received by Lumo for processing.";
        break;
      case "COMPLETED (Gas)":
        finalStatus = "Order successfully received by Lumo for processing.";
        break;
      case "PROCESSING (Electricity)":
        finalStatus = "Order successfully received by Lumo for processing.";
        break;
      case "PROCESSING (Gas)":
        finalStatus = "Order successfully received by Lumo for processing.";
        break;
      case "REJECTED (Electricity)":
        finalStatus = "Order Errors Found. Please Fix and Resubmit";
        break;
      case "REJECTED (Gas)":
        finalStatus = "Order Errors Found. Please Fix and Resubmit";
        break;

      // Alinta Energy
      case "Alinta Sale Submitted":
        finalStatus =
          "Data validation in progress, please wait for confirmation.";
        break;
      case "acknowledged (Electricity)":
        finalStatus = "Order successfully Submitted to Alinta.";
        break;
      case "acknowledged (Gas)":
        finalStatus = "Order successfully Submitted to Alinta.";
        break;
      case "accepted (Electricity)":
        finalStatus = "Order successfully Accepted by Alinta.";
        break;
      case "accepted (Gas)":
        finalStatus = "Order successfully Accepted by Alinta.";
        break;
      case "parked (Electricity)":
        finalStatus = "Order has been Parked by Alinta.";
        break;
      case "parked (Gas)":
        finalStatus = "Order has been Parked by Alinta.";
        break;
      case "rejected (Electricity)":
        finalStatus = "Order Errors Found. Please Fix and Resubmit";
        break;
      case "rejected (Gas)":
        finalStatus = "Order Errors Found. Please Fix and Resubmit";
        break;

      //OVO Energy
      case "Failed to enqueue sale":
        finalStatus =
          "Error submitting Order. Please try again in a few minutes";
        break;
      case "OVO Sale Submitted":
        finalStatus =
          "Data validation in progress, please wait for confirmation.";
        break;
      case "Order processed successfully":
        finalStatus = "Order successfully received by OVO for processing.";
        break;
      case "Failed to process sale, see errors field for details.":
        finalStatus = "Order Errors Found. Please Fix and Resubmit";
        break;

      //Default
      default:
        finalStatus = "Ready for Processing";
    }

    if (!isEmpty(errorList)) finalStatus = "Contain Errors!";

    if (!isEmpty(warningList) && finalStatus == "Ready for Processing") finalStatus = "Contain Warnings!";

    if (!isEmpty(errorList) && !isEmpty(warningList)) finalStatus = "Contain Errors and Warnings!";

    return finalStatus;

  };

  handleExtractReportChange = (value) => {
    //this.state.dataSource = [];
    this.props.clearSales();
    this.setState({ dataSource: [] });

    if (value == "1235" || value == "1220" || value == "1358" || value == "1387" || value == "1393" || value == "1459" || value == "1461") {

      this.setState({ singleLeadExtract: true });
    }
    else {
      this.setState({ singleLeadExtract: false });
    }
            
    if (["1458", "1459", "1460", "1461"].includes(value)) { // Show Alinta Token Rest Button
      this.setState({
        showAlintaResetBtn: true
      })
    }else{
      this.setState({
        showAlintaResetBtn: false
      })
    }

  }

  updateTableData = (tableData) => {

    this.setState({ loading: true });
    this.setState({ isExtracting: true });
    this.state.dataSource = [];
    let count = 1;
    tableData.map((saleToProcess, key) => {
      //console.log("Extracting Data", saleToProcess);

      let record = {
        key: count.toString(),
        recordID: saleToProcess.recordID,
        status: this.setSaleStatus(
          saleToProcess.status,
          saleToProcess.errorList,
          saleToProcess.warningList
        ),
        velocifyStatus: saleToProcess.velocifyStatus,
        velocifyLeadID: saleToProcess.velocifyLeadID,
        customSaleNumber: !isEmpty(saleToProcess.customSaleNumber) ? saleToProcess.customSaleNumber : '',
        transactionType: saleToProcess.transactionType,
        productDescElec: saleToProcess.productDescElec,
        productDesceGas: saleToProcess.productDesceGas,
        customerType: saleToProcess.customerType,
        customerName: saleToProcess.customerName,
        batchNumber: saleToProcess.batchNumber,
        batchNumberRef: saleToProcess.batchNumberRef,
        correlationId: !isEmpty(saleToProcess.correlationId)
          ? saleToProcess.correlationId
          : null,
        actualPayLoadData: !isEmpty(saleToProcess.payload)
          ? JSON.stringify(saleToProcess.payload)
          : saleToProcess.actualPayLoadData,
        payloadData: !isEmpty(saleToProcess.payload)
          ? JSON.stringify(saleToProcess.payload, null, 4)
          : saleToProcess.payloadData,
        errorList: !isEmpty(saleToProcess.errorList)
          ? JSON.stringify(saleToProcess.errorList, null, 4)
          : null,
        warningList: !isEmpty(saleToProcess.warningList)
          ? JSON.stringify(saleToProcess.warningList, null, 4)
          : null,
        processingErrors: !isEmpty(saleToProcess.processingErrors)
          ? saleToProcess.processingErrors
          : null,
        orderErrors: !isEmpty(saleToProcess.orderErrors)
          ? saleToProcess.orderErrors
          : null
      };
      //console.log("Updating Table Record", record);
      this.state.dataSource.push(record);
      count++;
    });
    this.setState({ loading: false });
    this.setState({ isExtracting: false });

  };


  getOrderStatus = () => {

    this.setState({
      isFetchingOrderUpdates: true,
    });

    let leadIdsForUpdates = [];
    let retailer = '';

    this.state.dataSource.map((saleToProcess, key) => {
      leadIdsForUpdates.push(saleToProcess.velocifyLeadID);
      if(isEmpty(retailer)){
        try{
          retailer = JSON.parse(saleToProcess.payloadData).ewRetailer;
        } catch (err) { }
        
      }
    });

    const ordersUpdateDetails = {
      leadIds: JSON.stringify(leadIdsForUpdates),
    }

    this.props.getOrderUpdates(ordersUpdateDetails, retailer);
  };

  // Receive updates of processed sales.
  componentDidUpdate(nextProps) {

    if (!isEmpty(nextProps.recordUpdateStatus)) {

      try {
        //console.log("Record Update Status", nextProps.recordUpdateStatus);
        var processingDetails = nextProps.recordUpdateStatus.details[0];

        var { dataSource } = this.state;
        console.log("dataSource", dataSource);

        var dataSourceRecord = null

        dataSourceRecord = dataSource.find(data =>
          processingDetails.leadId === data.customSaleNumber
        );

        if(dataSourceRecord == null){
          dataSourceRecord = dataSource.find(data =>
            processingDetails.leadId.includes(data.velocifyLeadID)
          );
        }        

        dataSourceRecord.status = this.setSaleStatus(processingDetails.status, dataSourceRecord.errorList, dataSourceRecord.warningList);
        dataSourceRecord.processingErrors = !isEmpty(processingDetails.details) ? JSON.stringify(processingDetails.details, null, 4) : null;

        //console.log("Updated Table Record", dataSourceRecord);
        try {
          if (nextProps.recordUpdateStatus.statusUpdate == "Success") {
            dataSourceRecord.velocifyStatus = "(Ops Status) Retailer CAF Sent";
          }
        }
        catch (err) { }

        this.setState({
          dataSource: dataSource,
        });

        //--------------------- OLD UPDATE -------------------------

        // //console.log("Record Update Details", processingDetails);
        // var dataSourceRecord = this.state.dataSource.find(data => data.velocifyLeadID == processingDetails.leadId);

        // //console.log("Table record to Update", dataSourceRecord);
        // dataSourceRecord.status = processingDetails.status;
        // dataSourceRecord.processingErrors = !isEmpty(processingDetails.details) ? JSON.stringify(processingDetails.details, null, 4) : null;

        // //console.log("Updated Table Record", dataSourceRecord);
        // try {
        //   if (nextProps.recordUpdateStatus.statusUpdate == "Success") {
        //     dataSourceRecord.velocifyStatus = "(Ops Status) Retailer CAF Sent";
        //   }
        // }
        // catch (err) { }

        // this.updateTableData(this.state.dataSource);

        //--------------------- OLD UPDATE -------------------------

        //this.state.dataSource[dataSourceRecord.key - 1].status = processingDetails.status;
        //this.state.dataSource[dataSourceRecord.key - 1].processingErrors = JSON.stringify(processingDetails.details, null, 4);

        //this.setState({ dataSource: this.state.dataSource });

        var { selectedRowKeysUpdated } = this.state;

        selectedRowKeysUpdated = selectedRowKeysUpdated + 1;
        this.setState({
          selectedRowKeysUpdated: selectedRowKeysUpdated,
        });

        if (this.state.selectedRowKeys.length == selectedRowKeysUpdated) {
          this.setState({
            isProcessingSales: false,
            selectedRowKeysUpdated: 0,
          });

          this.getOrderStatus();
        }

      }
      catch (err) {
        console.log(err);
      }

    }

    if (!isEmpty(nextProps.orderUpdates)) {
      try {

        nextProps.orderUpdates.map((orderToUpdate, key) => {

          var { dataSource } = this.state;

          var dataSourceRecord = null;

           dataSourceRecord = dataSource.find(
             data => data.customSaleNumber === orderToUpdate.leadId
           );

           if(dataSourceRecord == null){
              dataSourceRecord = dataSource.find(
                data => data.velocifyLeadID == orderToUpdate.leadId);
           }

          dataSourceRecord.status = this.setSaleStatus(orderToUpdate.status, dataSourceRecord.errorList, dataSourceRecord.warningList);

          if (!isEmpty(orderToUpdate.errors) && orderToUpdate.errors != "null") {
            if (!isEmpty(dataSourceRecord.orderErrors))
              dataSourceRecord.orderErrors = dataSourceRecord.orderErrors + "\n" + orderToUpdate.errors;
            else
              dataSourceRecord.orderErrors = orderToUpdate.errors;
          }

          this.setState({
            dataSource: dataSource,
          });

          //--------------------- OLD UPDATE -------------------------

          // var dataSourceRecord = this.state.dataSource.find(data => data.velocifyLeadID == orderToUpdate.leadId);
          // dataSourceRecord.status = orderToUpdate.status;
          // if (!isEmpty(orderToUpdate.errors) && orderToUpdate.errors != "null") {
          //   if (!isEmpty(dataSourceRecord.orderErrors))
          //     dataSourceRecord.orderErrors = dataSourceRecord.orderErrors + "\n" + orderToUpdate.errors;
          //   else
          //     dataSourceRecord.orderErrors = orderToUpdate.errors;
          // }

          // this.updateTableData(this.state.dataSource);

          //--------------------- OLD UPDATE -------------------------
        });

        this.setState({
          isFetchingOrderUpdates: false,
          selectedRowKeys: [],
        });
      }
      catch (Err) {
        this.setState({
          isFetchingOrderUpdates: false,
          selectedRowKeys: [],
        });
      }
    } 
    else if (
           Array.isArray(nextProps.orderUpdates) &&
           nextProps.orderUpdates.length === 0 &&
           this.state.isFetchingOrderUpdates
         ) {
           this.setState({
             isFetchingOrderUpdates: false,
             selectedRowKeys: []
           });
         }
  }

  componentWillReceiveProps(nextProps) {

    if (!isEmpty(nextProps.salesToProcess)) {
      // console.log(Object.values(nextProps.salesToProcess).length);
      if (Object.values(nextProps.salesToProcess).length > 0) {
        this.updateTableData(nextProps.salesToProcess);
      }
      else {
        // this.setState({ loading: true });
        // this.state.dataSource = [];
        // this.setState({ loading: false });
        // this.setState({ isExtracting: false });
      }
    }
    else {
      setTimeout(
        function () {
          this.setState({ loading: false });
          this.setState({ isExtracting: false });
        }
          .bind(this),
        5000
      );
    }


  }

  getDerivedStateFromProps(props, state) {

  }

  render() {

    let { dataSource, selectedRowKeys } = this.state;

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: record => ({
        disabled: record.status === 'Contain Errors!' || record.status === 'Contain Errors and Warnings!' || record.status === 'Order successfully received by AGL for processing.', // Column configuration not to be checked
        status: record.status,
      }),
    };
    const hasSelected = selectedRowKeys.length > 0;

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: this.checkColoumnType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-main-content">
        <ContainerHeader title="Process Sales" />
        <Row>
          {/* <Col span={24}>
            <Spin spinning={this.state.isSearching}>
              <Card className="gx-card" title="Search Retailer">
                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleSearch}
                >
                  <Row>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" htmlType="submit">Search</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                          Clear
                    </Button>
                      </center>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Spin>
          </Col> */}

          <Col span={24}>
            <Spin spinning={this.state.isExtracting}>
              <Card className="gx-card" title="Select Sales Extract Report for Sales Processing">
                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleExtractSalesForProcessing}
                >
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout2} label="Select Extract Report">
                          {getFieldDecorator('drpExtractReport', {
                            rules: [{ required: true, message: 'Please select an Extract Report!' }],
                            onChange: (e) => this.handleExtractReportChange(e, 'reference')
                          })(
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Select Extract Report"
                            >

                              <Option value="1233">Ops Extract_-_AGL All Others_(Current) (API)</Option>
                              <Option value="1234">Ops Extract_-_AGL Next Day_(Current) (API)</Option>
                              <Option value="1238">Ops Extract_-_AGL SDFI_(Current) (API)</Option>

                              <Option value="1235">Ops Extract_-_AGL/PD Single (Current)(API)</Option>

                              <Option value="1236">Ops Extract_-_PD All Others_(Current) (API)</Option>
                              <Option value="1237">Ops Extract_-_PD Next Day_(Current) (API)</Option>
                              <Option value="1239">Ops Extract_-_PD SDFI_(Current) (API)</Option>

                              <Option value="1219">Ops Extract - Origin Energy (Current v9.0) API</Option>
                              <Option value="1246">Ops Extract - Origin Energy Next Day (Current v9.0) API</Option>
                              <Option value="1341">Ops Extract - Origin Energy SDFI (Current v9.0) API</Option>
                              <Option value="1220">Ops Extract - Origin Energy SINGLE (Current v9.0) API</Option>

                              <Option value="1392">Ops Extract - Origin Energy - Deal Reveal (Current v9.0) API</Option>
                              <Option value="1393">Ops Extract - Origin Energy SINGLE - Deal Reveal (Current v9.0) API</Option>

                              <Option value="1357">Ops Extract - Lumo Energy Move Ins (Current) (API)</Option>
                              <Option value="1356">Ops Extract - Lumo Energy Ret/Acq (Current) (API)</Option>
                              <Option value="1358">Ops Extract - Lumo Energy SINGLE (Current) (API)</Option>

                              <Option value="1386">Ops Extract - OVO Energy Move Ins (Current) (API)</Option>
                              <Option value="1385">Ops Extract - OVO Energy Transfers (Current) (API)</Option>
                              <Option value="1387">Ops Extract - OVO Energy SINGLE (Current) (API)</Option>

                              <Option value="1458">Ops Extract - Alinta Energy (Current) (API)</Option>
                              <Option value="1459">Ops Extract - Alinta Energy SINGLE (Current) (API)</Option>

                              <Option value="1460">Ops Extract - Alinta Energy - Deal Reveal (Current) (API)</Option>
                              <Option value="1461">Ops Extract - Alinta Energy SINGLE - Deal Reveal (Current) (API)</Option>

                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                  </Row>
                  {this.state.singleLeadExtract &&
                    <Row>
                      <Col lg={12} md={12} sm={12} xs={24}>
                        <div className="gx-form-row0">
                          <FormItem {...formItemLayout2} label="Lead ID" >
                            {getFieldDecorator('txtLeadID', {
                              rules: [{ required: true, message: 'Enter Lead ID!' }],
                            })(
                              <Input

                                style={{ width: '100%' }} placeholder="Enter Lead ID" />
                            )}
                          </FormItem>
                        </div>
                      </Col>
                      <Col lg={12} md={12} sm={12} xs={24}>
                        <div className="gx-form-row0">
                          <FormItem {...formItemLayout2} label={(
                            <span>
                              Address Override&nbsp;
                              <Tooltip title="To Override Address Toggle here">
                                <Icon type="question-circle-o" />
                              </Tooltip>
                            </span>
                          )} >
                            {getFieldDecorator('txtAddressOverride')(
                              <Switch />
                            )}
                          </FormItem>
                        </div>
                      </Col>
                    </Row>
                  }
                  <Row>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" htmlType="submit">Extract Sales For Processing</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                          Clear
                        </Button>
                        {this.state.showAlintaResetBtn &&
                        <Button type="primary" className="ant-btn green-button" onClick={this.handleAlintaGetToken}>
                          Reset Alinta API
                        </Button>}
                      </center>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Spin>
          </Col>

          <Col span={24}>
            <Card className="gx-card" title="Extracted Sales Ready for Processing">
              {!isEmpty(dataSource) && hasSelected &&
                <Button style={{ marginTop: 15, minWidth: 250, minHeight: 50 }} size="large" type="primary" onClick={this.getOrderStatus}>Refresh Order Status</Button>
              }
              <EditableContext.Provider value={this.props.form}>
                <Table
                  components={components}
                  rowClassName="editable-row"
                  className="gx-table-responsive"
                  {...this.state}
                  columns={columns}
                  rowSelection={rowSelection}
                  dataSource={this.state.dataSource} />
              </EditableContext.Provider>
              {!isEmpty(dataSource) && hasSelected &&
                <center>
                  <Button style={{ marginTop: 15, minWidth: 250, minHeight: 50, backgroundColor: "#37D67A", color: "#FFFFFF" }} size="large" onClick={this.handleStartProcessingSales}>Process Sales</Button>
                </center>
              }
            </Card>
          </Col>
        </Row>
        <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
        <SweetAlert show={this.state.showInformation}
          customClass="gx-sweet-alert-top-space"
          title={this.state.informationTitle}
          style={{ maxHeight: 500 }}
          onConfirm={this.onConfirm}>
          {this.state.informationDescription}
        </SweetAlert>
        <SweetAlert show={this.state.isProcessingSales}
          customClass="gx-sweet-alert-top-space"
          title="Sales are being Processed"
          style={{ maxHeight: 500 }}
          closeOnClickOutside={false}
          buttons={false}>
          Sales are currently being processed. Please wait patiently. This box will close when all processing is complete.
        </SweetAlert>
        <SweetAlert show={this.state.isFetchingOrderUpdates}
          customClass="gx-sweet-alert-top-space"
          title="Fetching Order Updates"
          style={{ maxHeight: 500 }}
          onConfirm={this.onFetchingConfirm}>
          Currently Fetching Order Updates. Please wait patiently. This box will close when updates are fetched. Please allow upto 45 - 60 seconds.
        </SweetAlert>
        <SweetAlert show={this.state.showWarning}
          warning
          showCancel
          confirmBtnText="Yes Delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.handleDelete}
          onCancel={this.onCancelDelete}
        ></SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  salesToProcess: state.processSales.salesToProcess,
  recordUpdateStatus: state.processSales.recordUpdateStatus,
  orderUpdates: state.processSales.orderUpdates,
  alintaTokenResponse: state.processSales.alintaTokenResponse,
  userDetails: state.auth.userDetails,
});

const mapDispatchToProps = dispath => ({
  getSalesToProcess: data => dispath(getSalesToProcess(data)),
  resetAlintaTokenFn: () => dispath(resetAlintaTokenFn()),
  processSale: data => dispath(processSale(data)),
  getOrderUpdates: (data, retailer) => dispath(getOrderUpdates(data, retailer)),
  clearSales: data => dispath(clearSales(data))
});

const WrappedAdvancedSearch = Form.create()(ProcessSales);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);
