import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Tag, Input, Row, Button, Select, DatePicker, Descriptions, Badge, Switch } from "antd";

import AmCharts from "@amcharts/amcharts3-react";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";
import IconWithTextCard from "components/Metrics/IconWithTextCard";


import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { getSalesDistribution, getStatusList, getDemographicStats } from "appRedux/actions/sales/salesInformation";

import isEmpty from "util/isEmpty";

const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);
const RangePicker = DatePicker.RangePicker;

const { Column, ColumnGroup } = Table;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

let ageSummaryData;

function generateChartData2() {
  let chartData = [];
  if(!isEmpty(ageSummaryData))
  {
    for (let i = 0; i < ageSummaryData.length; i++) {
      if (i === selected) {
        for (let x = 0; x < ageSummaryData[i].subs.length; x++) {
          chartData.push({
            type: ageSummaryData[i].subs[x].type,
            percent: ageSummaryData[i].subs[x].percent,
            color: ageSummaryData[i].color,
            pulled: true
          });
        }
      } else {
        chartData.push({
          type: ageSummaryData[i].type,
          percent: ageSummaryData[i].percent,
          color: ageSummaryData[i].color,
          id: i
        });
      }
    }
  }
  
  return chartData;
}

let selected;

class DemographicStats extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isFetchingData: false,
      salesPerHourData: [],
      salesDistributionStats: [],
      retailerSummaryData:[],
      genderSummaryChartConfig:null,
      detailedRetailerSummaryData:[],
      demographicStats:[],
      ageSummaryChartConfig: [],
      isSalesData: false,
      salesSummaryData: {
        totalSales: 0,
        totalElec: 0,
        totalGas: 0,
      },
      isSearching: false,
      showWarning: false,
      warningMessage: "",
      showSuccess: false,
      successMessage: "",
      startDate: moment(moment().format('YYYY-MM-DD'),'YYYY-MM-DD'),
      endDate: moment(moment().format('YYYY-MM-DD'),'YYYY-MM-DD'),
    };
  }

  componentDidMount() {

    this.setState({
      isFetchingData: true,
    })

    //this.props.getSalesDistribution();
    this.props.getDemographicStats();
    this.props.getStatusList();
  }

  handleDateRangeChange = (dates, dateStrings) => {
    this.setState({
      startDate: dateStrings[0],
      endDate: dateStrings[1],
    })
  };

  handleSalesDataOnlySwitch = (checked) => {
    this.setState({
      isSalesData: checked,
    })
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
     
      const filtersForSearch = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        stateList: values.drpState,
        // saleOnly: values.txtSaleOnly,
        // statusId: values.drpStatus,
      }

      console.log(filtersForSearch);

      this.setState({
        isFetchingData: true,
        isSearching: true,
      });
      this.props.getDemographicStats(filtersForSearch);
    });
  };

  handleReset = () => {
    this.props.form.resetFields();

    this.props.getDemographicStats();
    this.props.getStatusList();
  };

  generateChartData = () => {
    let chartData = [];
    let types = this.state.ageSummaryData;
    for (let i = 0; i < types.length; i++) {
      if (i === selected) {
        for (let x = 0; x < types[i].subs.length; x++) {
          chartData.push({
            type: types[i].subs[x].type,
            percent: types[i].subs[x].percent,
            color: types[i].color,
            pulled: true
          });
        }
      } else {
        chartData.push({
          type: types[i].type,
          percent: types[i].percent,
          color: types[i].color,
          id: i
        });
      }
    }
    return chartData;
  }

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.demographicStats)) {
      this.setState({
        demographicStats: nextProps.demographicStats,
      });

      this.state.genderSummaryChartConfig = null;
      this.state.ageSummaryChartConfig = null;
      this.state.ageSummaryData = null;

      if(nextProps.demographicStats.hasData)
      {
        this.state.genderSummaryChartConfig = {
          "type": "pie",
          "theme": "light",
          "dataProvider": [{
            "type":"Male",
            "count": nextProps.demographicStats.gender.male,
          },{
            "type":"Female",
            "count": nextProps.demographicStats.gender.female,
          },],
          "valueField": "count",
          "titleField": "type",
          "balloon": {
            "fixedPosition": false
          },
          "export": {
            "enabled": true
          }
        };

        ageSummaryData = [
          {
            type: "18 to 25",
            percent: nextProps.demographicStats.age["18to25"].total,
            color: "#0f79aa", 
            subs: [{
              type: "Male",
              percent: nextProps.demographicStats.age["18to25"].male
            }, {
              type: "Female",
              percent: nextProps.demographicStats.age["18to25"].female
            },]
          },
          {
            type: "26 to 35",
            percent: nextProps.demographicStats.age["26to35"].total,
            color: "#fc570b",
            subs: [{
              type: "Male",
              percent: nextProps.demographicStats.age["26to35"].male
            }, {
              type: "Female",
              percent: nextProps.demographicStats.age["26to35"].female
            },]
          },
          {
            type: "36 to 45",
            percent: nextProps.demographicStats.age["36to45"].total,
            color: "#152967",
            subs: [{
              type: "Male",
              percent: nextProps.demographicStats.age["36to45"].male
            }, {
              type: "Female",
              percent: nextProps.demographicStats.age["36to45"].female
            },]
          },
          {
            type: "46 to 55",
            percent: nextProps.demographicStats.age["46to55"].total,
            color: "#e5948d",
            subs: [{
              type: "Male",
              percent: nextProps.demographicStats.age["46to55"].male
            }, {
              type: "Female",
              percent: nextProps.demographicStats.age["46to55"].female
            },]
          },
          {
            type: "56 to 65",
            percent: nextProps.demographicStats.age["56to65"].total,
            color: "#2614a6",
            subs: [{
              type: "Male",
              percent: nextProps.demographicStats.age["56to65"].male
            }, {
              type: "Female",
              percent: nextProps.demographicStats.age["56to65"].female
            },]
          },
          {
            type: "66 to 75",
            percent: nextProps.demographicStats.age["66to75"].total,
            color: "#bdd68d",
            subs: [{
              type: "Male",
              percent: nextProps.demographicStats.age["66to75"].male
            }, {
              type: "Female",
              percent: nextProps.demographicStats.age["66to75"].female
            },]
          },
          {
            type: "76 to 85",
            percent: nextProps.demographicStats.age["76to85"].total,
            color: "#e7ff59",
            subs: [{
              type: "Male",
              percent: nextProps.demographicStats.age["76to85"].male
            }, {
              type: "Female",
              percent: nextProps.demographicStats.age["76to85"].female
            },]
          },
          {
            type: "86 to 95",
            percent: nextProps.demographicStats.age["86to95"].total,
            color: "#fad76e",
            subs: [{
              type: "Male",
              percent: nextProps.demographicStats.age["86to95"].male
            }, {
              type: "Female",
              percent: nextProps.demographicStats.age["86to95"].female
            },]
          }
        ];

        this.state.ageSummaryChartConfig = {
          "type": "pie",
          "theme": "light",
          "dataProvider": generateChartData2(),
          // "labelText": "[[title]]: ([[value]])",
          // "balloonText": "[[title]]: ([[value]])",
          "valueField": "percent",
          "titleField": "type",
          "outlineColor": "#FFFFFF",
          "outlineAlpha": 0.8,
          "outlineThickness": 2,
          "colorField": "color",
          "pulledField": "pulled",
          "listeners": [{
            "event": "clickSlice",
            "method": function (event) {
              var chart = event.chart;
              if (event.dataItem.dataContext.id !== undefined) {
                selected = event.dataItem.dataContext.id;
              } else {
                selected = undefined;
              }
              chart.dataProvider = generateChartData2();
              chart.validateData();
            }
          }],
          "export": {
            "enabled": true
          }
        };

        console.log(this.state.ageSummaryChartConfig);
      } 

      this.setState({
        isFetchingData: false,
      });

    }

    if (!isEmpty(nextProps.fetchStatus)) {
      if (nextProps.fetchStatus == "Fetching") {
        this.setState({
          //isFetchingData: true,
          isSearching: true,
        });
      }
    }
    else {
      this.setState({
        //isFetchingData: false,
        isSearching: false,
      });
    }
  }

  render() {


    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-main-content" id="salesDistributionData">
        <ContainerHeader title="Demographic Stats" />
        <Row>
          <Col span={24}>
            <Spin spinning={this.state.isSearching}>
              <Card className="gx-card" title="Search Sales Distribution">
                <Form
                  className="ant-advanced-search-form"
                  onSubmit={this.handleSearch}
                >
                  <Row>
                    <Col lg={8} md={8} sm={8} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Sales Date Range">
                          {getFieldDecorator('drpDateRange', {
                            initialValue:[moment(moment().format('YYYY-MM-DD'),'YYYY-MM-DD'), moment(moment().format('YYYY-MM-DD'),'YYYY-MM-DD')],
                            rules: [{ required: false, message: 'Please select a Date Range' }],
                          })(
                            <RangePicker className="gx-w-100"
                              ranges={{ Today: [moment(), moment()], 'This Month': [moment(), moment().endOf('month')] }}
                              format="YYYY-MM-DD"
                              //defaultValue={[moment(moment().format('YYYY-MM-DD'),'YYYY-MM-DD'), moment(moment().format('YYYY-MM-DD'),'YYYY-MM-DD')]}
                              onChange={this.handleDateRangeChange}
                            />
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="State" >
                          {getFieldDecorator('drpState', {
                            rules: [{ required: false, message: 'Please select a State!' }],
                          })(
                            <Select
                              showSearch
                              mode="multiple"
                              style={{ width: "100%" }}
                              placeholder="Select a State"
                            >
                              <Option value="VIC">VIC</Option>
                              <Option value="NSW">NSW</Option>
                              <Option value="QLD">QLD</Option>
                              <Option value="ACT">ACT</Option>
                              <Option value="SA">SA</Option>
                              <Option value="WA">WA</Option>
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>
                    {/* <Col lg={12} md={12} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Sales Data Only">
                          {getFieldDecorator('txtSaleOnly', {
                            rules: [{ required: false, message: 'Please select a Status!' }],
                          })(
                            <Switch disabled={false}  onChange={this.handleSalesDataOnlySwitch} />
                          )}
                        </FormItem>
                      </div>
                    </Col>                     */}
                    {/* <Col lg={12} md={12} sm={12} xs={24}>
                      <div className="gx-form-row0">
                        <FormItem {...formItemLayout} label="Status">
                          {getFieldDecorator('drpStatus', {
                            rules: [{ required: false, message: 'Please select a Status!' }],
                          })(
                            <Select
                              showSearch
                              style={{ width: '100%' }}
                              placeholder="Select a Status"
                              optionFilterProp="children"
                              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                              <Option value="-1">Select</Option>
                              {!isEmpty(this.props.statusList) && this.props.statusList.map((status, key) => {
                                  return <option key={key}  value={status.statusID}>{status.name}</option>;
                              })}
                            </Select>
                          )}
                        </FormItem>
                      </div>
                    </Col>                      */}
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" htmlType="submit">Search</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                          Clear
                    </Button>
                      </center>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Spin>
          </Col>
          <Col span={12}>
           <Spin spinning={this.state.isFetchingData}>
              <Card className="gx-card" title="Gender Breakdown">                
                  {!isEmpty(this.state.genderSummaryChartConfig) && <AmCharts.React style={{width: "100%", height: "500px"}} options={this.state.genderSummaryChartConfig}/>}
                  {isEmpty(this.state.genderSummaryChartConfig) && "No data to populate charts"}
              </Card>
            </Spin>
          </Col>  
          <Col span={12}>
           <Spin spinning={this.state.isFetchingData}>
              <Card className="gx-card" title="Age Breakdown">                
                  {!isEmpty(this.state.ageSummaryChartConfig) && <AmCharts.React style={{width: "100%", height: "500px"}} options={this.state.ageSummaryChartConfig}/>}
                  {isEmpty(this.state.ageSummaryChartConfig) && "No data to populate charts"}
              </Card>
            </Spin>
          </Col>        
        </Row>
        <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
        <SweetAlert show={this.state.showWarning}
          warning
          showCancel
          confirmBtnText="Yes Delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={this.handleDelete}
          onCancel={this.onCancelDelete}
        ></SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  salesDistributionStats: state.salesinformation.salesDistributionStats,
  statusList: state.salesinformation.statusList,
  demographicStats: state.salesinformation.demographicStats,
  fetchStatus: state.salesinformation.fetchStatus,
});

const mapDispatchToProps = dispath => ({
  getSalesDistribution: (data) => dispath(getSalesDistribution(data)),
  getStatusList: (data) => dispath(getStatusList(data)),
  getDemographicStats: (data) => dispath(getDemographicStats(data)),
});

const WrappedAdvancedSearch = Form.create()(DemographicStats);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);