const initialState = {
    distributorList: {},
    nmiPrefixList: {},
    recordUpdateStatus: null,
    error: null,
}

export default function (state = initialState, action){
    switch(action.type)
    {
        case 'SAVE_DISTRIBUTOR_LIST':
            return{
                ...state,
                distributorList: action.distributorList
            };
        case 'SAVE_NMI_PREFIX_LIST':
            return{
                ...state,
                nmiPrefixList: action.nmiPrefixList
            };
        case 'SAVE_RECORD_UPDATE_STATUS':
            return {
                ...state,
                recordUpdateStatus: action.recordUpdateStatus
            };
        case 'ERROR':
            return{
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}