import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import isEmpty from "util/isEmpty";

class UserProfile extends Component {

  render() {

    const {userDetails} = this.props;

    const userMenuOptions = (
      <ul className="gx-user-popover">
        {/* <li>My Account</li> */}
        <li onClick={() => this.props.userSignOut()}>Logout
        </li>
      </ul>
    );

    return (



      !isEmpty(userDetails) && 
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <Avatar src={userDetails.picture}
                  className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
          <span className="gx-avatar-name">{userDetails.name}<i
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
        </Popover>
      </div>
    

    )

  }
}

const mapStateToProps = ({auth}) => {
  const {userDetails} = auth;
  return {userDetails}
};

export default connect(mapStateToProps, {userSignOut})(UserProfile);
