const initialState = {
    fiveWorkingDaysaleStats: {},
    threeWeekDaySaleStats: {},
    salesPerHourList: {},
    salesSummaryData: {},
    agentSaleSummary: {},
    agentMTDSaleSummary: {},
    singleAgentSummaryData: {},
    elecRetailerStats: {},
    mtdElecRetailerStats: {},
    gasRetailerStats: {},
    mtdGasRetailerStats: {},
    moveInTransferStats: {
        moveIn: 0,
        transfer: 0,
        moveInMTD: 0,
        transferMTD: 0,
    },
    elecProductStats: {},
    mtdElecProductStats: {},
    gasProductStats: {},
    mtdGasProductStats: {},
    salesDistributionStats: {},
    statusList: {},
    demographicStats: {},
    leadVolumeSummary: {},
    fetchStatus: null,
    error: null,
}

export default function (state = initialState, action){
    switch(action.type)
    {
        case 'SAVE_SALES_PER_HOUR_LIST':
            return{
                ...state,
                salesPerHourList: action.salesPerHourList
            };
        case 'SAVE_THREE_WEEK_SALES_STATS':
            return{
                ...state,
                threeWeekDaySaleStats: action.threeWeekDaySaleStats
            };
        case 'SAVE_FIVE_WORKING_DAY_SALES_STATS':
            return{
                ...state,
                fiveWorkingDaysaleStats: action.fiveWorkingDaysaleStats
            };
        case 'SAVE_SALES_SUMMARY_DATA':
            return{
                ...state,
                salesSummaryData: action.salesSummaryData
            };
        case 'SAVE_FETCH_STATUS':
            return{
                ...state,
                fetchStatus: action.fetchStatus
            };
        case 'SAVE_AGENT_SALE_SUMMARY':
            return{
                ...state,
                agentSaleSummary: action.agentSaleSummary
            };
        case 'SAVE_MTD_AGENT_SALE_SUMMARY':
            return{
                ...state,
                agentMTDSaleSummary: action.agentMTDSaleSummary
            };
        case 'SAVE_SINGLE_AGENT_SUMMARY':
            return{
                ...state,
                singleAgentSummaryData: action.agentSummaryData
            };
        case 'SAVE_ELEC_RETAILER_SUMMARY':
            return{
                ...state,
                elecRetailerStats: action.elecRetailerStats
            };
        case 'SAVE_GAS_RETAILER_SUMMARY':
            return{
                ...state,
                gasRetailerStats: action.gasRetailerStats
            };
        case 'SAVE_MTD_ELEC_RETAILER_SUMMARY':
            return{
                ...state,
                mtdElecRetailerStats: action.mtdElecRetailerStats
            };
        case 'SAVE_MTD_GAS_RETAILER_SUMMARY':
            return{
                ...state,
                mtdGasRetailerStats: action.mtdGasRetailerStats
            };
        case 'SAVE_MOVE_IN_TRANSFER_SUMMARY':
            return{
                ...state,
                moveInTransferStats: action.moveInTransferStats
            };
        case 'SAVE_ELEC_PRODUCT_SUMMARY':
            return{
                ...state,
                elecProductStats: action.elecProductStats
            };
        case 'SAVE_GAS_PRODUCT_SUMMARY':
            return{
                ...state,
                gasProductStats: action.gasProductStats
            };
        case 'SAVE_SALES_DISTRIBUTION_STATS':
            return{
                ...state,
                salesDistributionStats: action.salesDistributionStats
            };
        case 'SAVE_DEMOGRAPHIC_STATS':
            return{
                ...state,
                demographicStats: action.demographicStats
            };
        case 'SAVE_STATUS_LIST':
            return{
                ...state,
                statusList: action.statusList
            };
        case 'SAVE_LEAD_VOLUME_SUMMARY':
            return{
                ...state,
                leadVolumeSummary: action.leadVolumeSummary
            };
        case 'ERROR':
            return{
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}