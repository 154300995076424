const initialState = {
    mirnList: null,
    error: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'SAVE_MIRN_SEARCH_RESULTS':
            return {
                ...state,
                mirnList: action.mirnList
            };
        case 'ERROR':
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}