import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Card, Col, Form, Input, InputNumber, Radio, Row, Select, message } from 'antd'
import TextArea from 'antd/lib/input/TextArea';

import isEmpty from "util/isEmpty";
import TextEditor from '../../../../components/TextEditor/TextEditor';
import { getRetailerList } from "appRedux/actions/admin/retailers";
import { createBroadbandModemOption, getBroadbandModemRecordById, setManageBroadbandModemAPIStatusToDefault, updateBroadbandModemOption } from '../../../../appRedux/actions/admin/broadbandModem';

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};

const { Option } = Select;

class NewBroadbandOption extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editMode: false,
            selectedPlan: {}
        }
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        if (id) {

            this.props.getBroadbandModemRecordById(id)

            this.setState({ editMode: true })

        } else {

            this.setState({ editMode: false })

        }

        this.props.getRetailerList();
    }

    componentWillReceiveProps(nextProps) {

        if (this.state.editMode) {

            console.log(nextProps.selectedFeePlan);
            this.setState({ selectedPlan: nextProps.selectedFeePlan })

        }
        // Check the broadband Create status and  if it is success redirect to broadband fee index page
        if (nextProps.createStatus === 'success') {

            this.props.setCreateStatusToDefault()

            message.success('New Broadband ModemOption Record Added!');

            setTimeout(() => {
                this.props.history.replace('/admin/manage/broadbandModems')
            }, 1000);

        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {

            if (this.state.editMode) {

                this.props.updateBroadbandModemOption({ ...values, id: this.state.selectedPlan.id })

            } else {

                this.props.createBroadbandModemOption({ ...values })

            }

        })

    }

    render() {
        const { getFieldDecorator } = this.props.form
        const { editMode, selectedPlan } = this.state
        return (
            <Col span={24}>

                <Card className="gx-card" title={`Add New Broadband Modem Options`}>
                    <Form
                        name="modemOptionForm"
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                    >
                        <Row>
                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Retailer" hasFeedback>
                                    {getFieldDecorator('retailer', {
                                        rules: [{ required: true, message: 'Please select Retailer' }],
                                        initialValue: editMode ? selectedPlan.retailer : ''
                                    })(
                                        <Select placeholder="Please select a Retailer">
                                            {/* <Option value="-1">All Retailers</Option> */}
                                            {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                                return <option key={key} value={retailer.retailerFriendlyName}>{retailer.retailerName}</option>;
                                            })}
                                        </Select>,
                                    )}
                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Modem Type" hasFeedback>
                                    {getFieldDecorator('modem_type', {
                                        rules: [{ required: true, message: 'Please select Modem Type' }],
                                        initialValue: editMode ? selectedPlan.modem_type : ''
                                    })(
                                        <Select placeholder="Please select a Modem Type">
                                            <Option value="BYO Modem">BYO Modem</Option>
                                            <Option value="Google Modem">Google Modem</Option>
                                        </Select>,
                                    )}
                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Modem Name" hasFeedback>
                                    {getFieldDecorator('modem_name', {
                                        rules: [{ required: true, message: 'Please select Modem Name' }],
                                        initialValue: editMode ? selectedPlan.modem_name : ''
                                    })(
                                        <Select placeholder="Please select a Modem Type">
                                            <Option value="BYO Modem">BYO Modem</Option>
                                            <Option value="Google Modem">Google Modem</Option>
                                        </Select>,
                                    )}
                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="States" hasFeedback mode="multiple">
                                    {getFieldDecorator('state', {
                                        rules: [{ required: true, message: 'Please select States' }],
                                        initialValue: editMode ? selectedPlan.state : ''
                                    })(
                                        <Select placeholder="Please select States">
                                            <Option value="VIC">Victoria</Option>
                                            <Option value="NSW">New South Wales</Option>
                                            <Option value="ACT">Australian Capital Territory</Option>
                                            <Option value="QLD">Queensland</Option>
                                            <Option value="SA">South Australia</Option>
                                            <Option value="WA">Western Australia</Option>
                                            <Option value="NT">Northern Territory</Option>
                                            <Option value="TAS">Tasmania</Option>
                                        </Select>,
                                    )}
                                </Form.Item>

                            </Col>


                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Res/sme" hasFeedback mode="multiple">
                                    {getFieldDecorator('res_sme', {
                                        rules: [{ required: true, message: 'Please select States' }],
                                        initialValue: editMode ? selectedPlan.res_sme : ''
                                    })(
                                        <Radio.Group>
                                            <Radio value="RES">RES</Radio>
                                            <Radio value="SME">SME</Radio>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Product Name" hasFeedback>
                                    {getFieldDecorator('product_name', {
                                        rules: [{ required: true, message: 'Please select Product Name' }],
                                        initialValue: editMode ? selectedPlan.product_name : ''
                                    })(
                                        <Input placeholder='Please Enter Product Name' />
                                    )}
                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Contract Name">
                                    {getFieldDecorator('contract_term', {
                                        rules: [{
                                            required: true,
                                            message: 'Please Enter Contract Name',
                                        }],
                                        initialValue: editMode ? selectedPlan.contract_term : ''
                                    })(<Input />)}

                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Technology Type">
                                    {getFieldDecorator('technology_type', {
                                        rules: [{
                                            required: true,
                                            message: 'Please Enter technology type',
                                        }],
                                        initialValue: editMode ? selectedPlan.technology_type : ''
                                    })(<Input />)}

                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Modem more info retailer url">
                                    {getFieldDecorator('modem_more_info_retailer_url', {
                                        rules: [{
                                            required: true,
                                            message: 'Please Enter Modem more info retailer url',
                                        }],
                                        initialValue: editMode ? selectedPlan.modem_more_info_retailer_url : ''
                                    })(<Input placeholder="Modem more info retailer url" />)}
                                </Form.Item>

                            </Col>


                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Modem image url">
                                    {getFieldDecorator('modem_image', {
                                        rules: [{
                                            required: true,
                                            message: 'Please Enter Modem image url',

                                        }],
                                        initialValue: editMode ? selectedPlan.modem_image : ''
                                    })(<Input placeholder="Modem image url" />)}
                                </Form.Item>

                            </Col>


                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Modem Cost">
                                    {getFieldDecorator('modem_cost', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please Enter Modem Cost',
                                            },
                                        ], initialValue: editMode ? selectedPlan.modem_cost : ''
                                    })(<Input addonBefore="$" />)}

                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Modem delivery fee">
                                    {getFieldDecorator('modem_delivery_fee', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please Enter Modem delivery fee',
                                            },
                                        ],
                                        initialValue: editMode ? selectedPlan.modem_delivery_fee : ''
                                    })(<Input addonBefore="$" />)}

                                </Form.Item>


                            </Col>


                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Modem technology support helper notes" hasFeedback mode="multiple">
                                    {getFieldDecorator('modem_technology_support_helper_notes', {
                                        rules: [{
                                            required: true,
                                            message: 'Please Enter Modem technology support helper notes',
                                        }],
                                        initialValue: editMode ? selectedPlan.modem_image : ''
                                    })(
                                        <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                    )}
                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item {...formItemLayout2} label="Modem technology support" hasFeedback mode="multiple">
                                    {getFieldDecorator('modem_technology_support', {
                                        rules: [{
                                            required: true,
                                            message: 'Please Enter Modem technology support Description',
                                        }],
                                        initialValue: editMode ? selectedPlan.modem_technology_support : ''
                                    })(
                                        <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                    )}
                                </Form.Item>

                            </Col>


                            <Col sm={24} xs={24}>

                                <Form.Item {...formItemLayout2} label="Modem description">
                                    {getFieldDecorator('modem_description', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please Enter Modem description',
                                            },
                                        ],
                                        initialValue: editMode ? selectedPlan.modem_description : ''
                                    })(<TextArea autoSize={{ minRows: 3, maxRows: 5 }} />)}

                                </Form.Item>

                            </Col>

                            <Col span={24} className="gx-text-right">

                                <center>

                                    <div className='contentCenter'>

                                        <Button type="primary" htmlType="submit">
                                            {editMode ? 'Update' : 'Save'}
                                        </Button>

                                    </div>
                                </center>
                            </Col>

                        </Row>

                    </Form>

                </Card>

            </Col>
        )
    }
}

const mapStateToProps = (state) => ({
    retailerList: state.retailers.retailerList,
    createStatus: state.broadbandModem.manageApiStatus,
    selectedFeePlan: state.broadbandModem.selectedPlan
})

const mapDispatchToProps = dispath => ({
    getRetailerList: (data) => dispath(getRetailerList(data)),
    createBroadbandModemOption: (data) => dispath(createBroadbandModemOption(data)),
    setCreateStatusToDefault: () => dispath(setManageBroadbandModemAPIStatusToDefault()),
    updateBroadbandModemOption: (data) => dispath(updateBroadbandModemOption(data)),
    getBroadbandModemRecordById: (id) => dispath(getBroadbandModemRecordById(id))

})

const WrappedNewBroadbandModemOption = Form.create()(NewBroadbandOption);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedNewBroadbandModemOption)