
import { Button, Popover, Row, Col } from 'antd'
import { DownloadOutlined } from '@ant-design/icons';
import { FaFilter, FaExclamationCircle } from 'react-icons/fa';

import styles from './BroadbandResult.module.less'


import React, { Component } from 'react'
import { connect } from 'react-redux';
import { clearProducts } from '../../../appRedux/actions/sales/products';
import { clearAll } from '../../../appRedux/actions/sales/leads';
import { resetAddress } from '../../../appRedux/actions/tools/address';


export class Header extends Component {
    constructor(props) {
        super(props)

        this.state = {
            leadDetails: []
        }
    }

    tryNewLead = () => {
        this.props.clearProducts()

        this.props.clearLeads()

        this.props.resetAddress()

        this.props.history.push(`/tools/broadbandComparison`);

    }
    render() {

        const { size, handleFilters, data, leadDetails } = this.props;

        return (
            <div className={styles.container}>

                <div className={styles.contentWrapper}>

                    <Row>
                        <Col span={16}>
                            {
                                leadDetails && leadDetails.comparisonDetails && leadDetails.comparisonDetails.broadbandDetails && leadDetails.comparisonDetails.broadbandDetails.addressDetails && leadDetails.comparisonDetails.broadbandDetails.addressDetails.data ? <h3>

                                    These are the available plans at <strong>{leadDetails.comparisonDetails.broadbandDetails.addressDetails.data.adress.SupplyFullAddress._text}</strong> for <strong>{leadDetails.comparisonDetails.broadbandDetails.addressDetails.data.nbn.servingArea.techType ? leadDetails.comparisonDetails.broadbandDetails.addressDetails.data.nbn.servingArea.techType : 'None'}</strong> Technology at the property

                                </h3> :
                                    <h3>
                                        Broadband Plans
                                    </h3>

                            }



                            {
                                data.productsDetails && (
                                    <>
                                        <p>
                                            Showing {data.productsDetails.planCount ? data.productsDetails.planCount : 0}  plans  from {data.productsDetails && data.productsDetails.retailerCount ? data.productsDetails.retailerCount : 0} retailers. <Popover placement="bottom" content={
                                                <>

                                                    {data.productsDetails.recommendedBroadbandPlansCount !== 0 && <p>
                                                        Results are listed as two seperate sections. The first section which contains plans with an orange border are the <b>{data.productsDetails.recommendedBroadbandPlansCount ? data.productsDetails.recommendedBroadbandPlansCount : '0'}</b>{" "}
                                                        recommended plans based on the information we have collected from you. The rest of the <b>{data.productsDetails.planCount ? data.productsDetails.planCount - data.productsDetails.recommendedBroadbandPlansCount : '0'}</b>{" "}
                                                        plans are all the remainings plans available with us prodivded by <b>{data.productsDetails.retailerCount ? data.productsDetails.retailerCount : '0'}</b> retailers. Feel free to choose and signup to the retailer that suits best to customer.<br /><br />
                                                        The estimated costs are based on the 1st month minimum cost or minimum term costs for plans with contracts.
                                                    </p>}

                                                    {data.productsDetails.recommendedBroadbandPlansCount === 0 && <p>
                                                        Feel free to choose and signup to the retailer that suits best to customer.<br /><br />
                                                        The estimated costs are based on the 1st month minimum cost or minimum term costs for plans with contracts.
                                                    </p>}
                                                </>} title="Result Summary" trigger="click">
                                                <FaExclamationCircle />
                                            </Popover>
                                        </p>

                                        <p>
                                            Lead ID : <a href={`https://lm.prod.velocify.com/Web/LeadAddEdit.aspx?LeadId=${leadDetails.leadDetails.Leads ? leadDetails.leadDetails.Leads.Lead._attributes.Id : leadDetails.leadDetails.Id._text}`} target="_blank">{leadDetails.leadDetails.Leads ? leadDetails.leadDetails.Leads.Lead._attributes.Id : leadDetails.leadDetails.Id._text}</a>
                                        </p>
                                    </>

                                )
                            }
                        </Col>
                        <Col span={8} style={{textAlign:"right"}}>
                            <Button type="secondary" size={size || 'large'} className={styles.mb0} onClick={this.tryNewLead}>
                                Re-start Comparison
                            </Button>

                            <Button type="primary" size={size || 'large'} className={styles.mb0} onClick={handleFilters}>
                                <FaFilter className="gx-mr-3" /> Filter Result
                            </Button>
                        </Col>
                    </Row>

                    {/* <div className={styles.textSection}>



                    </div>

                    <div>

                    </div> */}


                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispath => ({
    clearProducts: () => dispath(clearProducts()),
    clearLeads: () => dispath(clearAll()),
    resetAddress: () => dispath(resetAddress())
});



export default connect(mapStateToProps, mapDispatchToProps)(Header)
