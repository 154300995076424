import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';
import isEmpty from "util/isEmpty"

export const saveProducts = productsDetails => ({
  type: 'SAVE_PRODUCTS',
  productsDetails,
});

export const planRetrieveIssue = productsDetails => ({
  type: 'FAILED_RETRIEVE_PRODUCTS',
});

export const resetProductList = () => ({
  type: 'REST_PRODUCTS',
})

export const LoadingProductList = () => ({
  type: 'LOADING_PRODUCTS',
})


export const error = error => ({
  type: 'ERROR',
  error,
});

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

export const clearErrors = (data) => dispath => {

  dispath(error(null));
}

export const clearAll = () => dispath => {

  dispath(saveProducts(null));
  dispath(error(null));

}


export const clearProducts = () => dispath => {
  localStorage.removeItem('broadbandPlans')
  dispath(resetProductList())
}

export const getProductDetails = (data) => dispath => {

  localStorage.setItem('leadDetails', JSON.stringify(data));

  dispath(LoadingProductList())


  axios.post(apidetails.API_URL + `broadband/getProducts`, { ...data })
    .then(res => {

      localStorage.setItem("broadbandPlans", JSON.stringify(res.data))

      console.log(res);
      dispath(saveProducts(res.data));
    })
    .catch(err => {
      //console.log(err);
      dispath(error(!isEmpty(err.response.data.message) ? err.response.data.message : 'ERROR'));
    });
}


export const getMatchingProducts = (uid) => dispath => {

  dispath(LoadingProductList(products))

  let products = {};


  if (uid) {

  } else {

    const products = JSON.parse(localStorage.getItem('broadbandPlans'));


    if (products) {

      setTimeout(() => {
        dispath(saveProducts(products))
      }, 2000);

    } else {

      setTimeout(() => {
        dispath(planRetrieveIssue())
      }, 2000);

    }


  }

}