const initialState = {
    internalDNCDetails: {},
    internalDNCDetailsList :null,
    internalDNCTypes :null,
    internalDNCUploadStatus : null,
    deleteInternalDNCStatus : null,
}

export default function (state = initialState, action){
    switch(action.type)
    {
        case 'SAVE_INTERNALDNC_DETAILS':
            return{
                ...state,
                internalDNCDetails: action.internalDNCDetails
            };
        case 'SAVE_INTERNALDNC_DETAILS_LIST':
            return{
                ...state,
                internalDNCDetailsList: action.internalDNCDetailsList
            };
        case 'SAVE_INTERNALDNC_TYPES':
            return{
                ...state,
                internalDNCTypes: action.internalDNCTypes
            };
        case 'SAVE_INTERNALDNC_UPLOAD_STATUS':
            return{
                ...state,
                internalDNCUploadStatus: action.internalDNCUploadStatus
            };
        case 'DELETE_INTERNAL_DNC_STATUS':
            return{
                ...state,
                deleteInternalDNCStatus: action.deleteInternalDNCStatus
            };
        default:
            return state;
    }
}