import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Spin, Card, Col, Form, Input, Row, Button, Steps, Select, message } from "antd";
import GeneralDetails from './GeneralDetails';
import LinksDetails from './LinksDetails';
import { clearError } from "appRedux/actions/admin/dmovdo";
import { getRetailerList } from "appRedux/actions/admin/retailers";
import { getUniqueTariffs } from "appRedux/actions/admin/tariff";
import isEmpty from "util/isEmpty";
import { deleteBroadbandPlans, getAllBroadbandPlanDetails, searchBroadbandPlanRecords, setCreateBroadbandStatusToDefault, setSelectedBroadbandPlan } from '../../../../appRedux/actions/admin/broadbandPlans';
import ContainerHeader from "components/ContainerHeader/index";
import BroadbandPlansTable from './BroadbandPlansTable';


const { Step } = Steps;
const FormItem = Form.Item;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};


const formItemLayout2 = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const pagination = { position: 'bottom' };
export class BroadbandPlans extends Component {
  constructor(props) {
    super(props)

    this.state = {
      current: 0,
      newPlan: false,
      isSearching: false,
      pagination,
      dataSource: [],
    }
  }

  componentDidMount() {
    this.props.getAllBroadbandPlanDetails()
    this.props.getRetailerList();
    this.props.getUniqueTariffs();
  }

  componentWillReceiveProps(nextProps) {

    console.log(nextProps.deleteStatus);
    if (nextProps.deleteStatus === 'success') {

      message.success('Record Deleted Successfully');

      this.props.setCreateBroadbandStatusToDefault()

      this.props.getAllBroadbandPlanDetails()

    }
  }

  next = () => {
    this.setState({ current: this.state.current + 1 })
  }

  back = () => {
    this.setState({ current: this.state.current - 1 })
  }

  navigateToAddNewBroadbandPlan = () => {
    this.props.history.replace('/admin/manage/broadbandPlan/new')
  }

  navigateToUpdate = (data) => {
    this.props.setSelectedBroadbandPlan(data)
    this.props.history.replace(`/admin/manage/broadbandPlan/${data.id}`)
  }

  deleteBroadbandFeeRecord = (id) => {

    this.props.deleteBroadbandPlan(id)

  }

  handleSearch = (e) => {

    e.preventDefault();

    this.props.form.validateFields((err, values) => {

      const filtersForSearch = {
        ...(values.txtRetailerNameSearch != "-1" && { retailer: values.txtRetailerNameSearch }),
        ...(values.txtStateSearch != "-1" && { state: values.txtStateSearch }),
        ...(values.txtResSmeSearch != "-1" && { res_sme: values.txtResSmeSearch }),
        ...(values.recommended_household_sizes.length > 0 && { recommended_household_sizes: values.recommended_household_sizes.toString() }),
        ...(values.recommended_usage_level.length > 0 && { recommended_usage_level: values.recommended_usage_level.toString() }),
        ...(values.connection_type.length > 0 && { connection_type: values.connection_type.toString() }),
        ...(values.technology.length > 0 && { technology: values.technology.toString() }),
        ...(values.typical_evening_download_speed !== "" && { typical_evening_download_speed: values.typical_evening_download_speed }),
        ...(values.upload_speed !== "" && { upload_speed: values.upload_speed }),
        ...(values.discount !== '' && { discount: values.discount }),
      }

      this.setState({ isSearching: true });

      if (!isEmpty(filtersForSearch)) {

        this.props.searchBroadbandPlanRecords(filtersForSearch);

      }
      else {

        this.props.getAllBroadbandPlanDetails();

        this.setState({ isSearching: false });

      }

    });

  };

  handleClearSearch = () => {

    this.props.form.resetFields();

    this.props.getAllBroadbandPlanDetails();
  }

  componentWillReceiveProps(nextProps) {

    if (!isEmpty(nextProps.broadbandPlans) && nextProps.broadbandPlans.result && nextProps.broadbandPlans.result.length > 0) {

      this.state.dataSource = nextProps.broadbandPlans;

      this.setState({ isSearching: false });
    }
    else {

      this.setState({ dataSource: [] })

      this.setState({ isSearching: false });
    }
  }

  render() {
    const { current, newPlan, dataSource } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <div>
        {
          newPlan && <div className='StepperSection'>

            <Steps current={current}>
              <Step key={0}></Step>
              <Step key={1}></Step>
              <Step key={2}></Step>
              <Step key={3}></Step>
            </Steps>

            <div style={{ marginTop: '1rem' }}>
              {
                current === 0 && <GeneralDetails next={this.next} />
              }
              {
                current === 1 && <LinksDetails next={this.next} back={this.back} />
              }
            </div>

          </div>
        }

        {
          !newPlan && <div className='searchSection'>
            <ContainerHeader title="Manage Broadband Plans" />
            <Col span={24}>
              <Spin spinning={this.state.isSearching}>
                <Card className="gx-card" title={`Search Broadband Plans`} style={{ maxHeight: "550px", overflow: "hidden" }}>
                  <Form
                    className="ant-advanced-search-form"
                    style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", marginBottom: "20px" }}
                  >
                    <Row>
                      <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                          <FormItem {...formItemLayout2} label="Retailer" >
                            {getFieldDecorator('txtRetailerNameSearch', {
                              initialValue: "-1",
                            })(
                              <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a Retailer"
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              >
                                <Option value="-1">All Retailers</Option>
                                {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                  return <option key={key} value={retailer.retailerFriendlyName}>{retailer.retailerName}</option>;
                                })}
                              </Select>
                            )}
                          </FormItem>
                        </div>
                      </Col>
                      <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                          <FormItem {...formItemLayout2} label="State" >
                            {getFieldDecorator('txtStateSearch', {
                              initialValue: "-1",
                            })(
                              <Select
                                style={{ width: '100%' }}
                                placeholder="Select State"
                              >
                                <Option value="-1">All States</Option>
                                <Option value="VIC">Victoria</Option>
                                <Option value="NSW">New South Wales</Option>
                                <Option value="ACT">Australian Capital Territory</Option>
                                <Option value="QLD">Queensland</Option>
                                <Option value="SA">South Australia</Option>
                                <Option value="WA">Western Australia</Option>
                                <Option value="NT">Northern Territory</Option>
                                <Option value="TAS">Tasmania</Option>
                              </Select>
                            )}
                          </FormItem>
                        </div>
                      </Col>
                      <Col lg={8} md={8} sm={12} xs={24}>
                        <div className="gx-form-row0">
                          <FormItem {...formItemLayout2} label="RES/SME" >
                            {getFieldDecorator('txtResSmeSearch', {
                              initialValue: "-1",
                            })(
                              <Select
                                style={{ width: '100%' }}
                                placeholder="Select Options"
                              >
                                <Option value="-1">All Options</Option>
                                <Option value="RES">RES</Option>
                                <Option value="SME">SME</Option>
                              </Select>
                            )}
                          </FormItem>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={8} md={8} sm={8} xs={24}>
                        <div className="gx-form-row0">
                          <Form.Item {...formItemLayout2} label="Recommended Household Sizes" mode="multiple">
                            {getFieldDecorator('recommended_household_sizes', {
                              initialValue: []
                            })(
                              <Select placeholder="Please select Household Sizes" mode="multiple">
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                                <Option value="3">3</Option>
                                <Option value="4">4</Option>
                                <Option value="5">5</Option>
                                <Option value="6">6</Option>
                              </Select>,
                            )}
                          </Form.Item>
                        </div>
                      </Col>

                      <Col lg={8} md={8} sm={8} xs={24}>
                        <div className="gx-form-row0">
                          <Form.Item {...formItemLayout2} label="Recommended Usage Level" mode="multiple">
                            {getFieldDecorator('recommended_usage_level', {
                              initialValue: []
                            })(
                              <Select placeholder="Recommended Usage Level" mode="multiple">
                                <Option value="Low">Low</Option>
                                <Option value="Medium">Medium</Option>
                                <Option value="High">High</Option>
                              </Select>,
                            )}
                          </Form.Item>
                        </div>
                      </Col>

                      <Col lg={8} md={8} sm={8} xs={24}>
                        <div className="gx-form-row0">
                          <Form.Item {...formItemLayout2} label="Connection Type" mode="multiple">
                            {getFieldDecorator('connection_type', {
                              initialValue: []
                            })(
                              <Select placeholder="Connection Type" mode="multiple">
                                <Option value="NBN">NBN</Option>
                              </Select>,
                            )}
                          </Form.Item>
                        </div>
                      </Col>

                      <Col lg={8} md={8} sm={8} xs={24}>
                        <div className="gx-form-row0">
                          <Form.Item {...formItemLayout2} label="Technology" mode="multiple">
                            {getFieldDecorator('technology', {
                              initialValue: []
                            })(
                              <Select placeholder="Please select Technology" mode="multiple">
                                <Option value="FTTP">FTTP</Option>
                                <Option value="FTTC">FTTC</Option>
                                <Option value="FTTN">FTTN</Option>
                                <Option value="HFC">HFC</Option>
                                <Option value="FTTB">FTTB</Option>
                              </Select>,
                            )}
                          </Form.Item>

                        </div>
                      </Col>

                      <Col lg={8} md={8} sm={8} xs={24}>
                        <div className="gx-form-row0">
                          <Form.Item {...formItemLayout2} label="Typical Evening Download Speed">
                            {getFieldDecorator('typical_evening_download_speed', {
                              initialValue: ''
                            })(<Input placeholder='Download Speed' />)}

                          </Form.Item>
                        </div>
                      </Col>

                      <Col lg={8} md={8} sm={8} xs={24}>
                        <div className="gx-form-row0">
                          <Form.Item {...formItemLayout2} label="Upload Speed">
                            {getFieldDecorator('upload_speed', {
                              initialValue: ''
                            })(<Input placeholder='Upload Speed' />)}

                          </Form.Item>
                        </div>
                      </Col>

                      <Col lg={8} md={8} sm={8} xs={24}>
                        <div className="gx-form-row0">
                          <Form.Item {...formItemLayout2} label="Discounted Monthly Charge">
                            {getFieldDecorator('discount', {
                              initialValue: ''
                            })(<Select placeholder="Please select Technology">
                              <Option value="YES">Yes</Option>
                              <Option value="NO">No</Option>
                            </Select>,)}

                          </Form.Item>

                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <Row>
                    <Col span={24} className="gx-text-right">
                      <center>
                        <Button type="primary" onClick={this.handleSearch} loading={this.state.isSearching}>
                          Search
                        </Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleClearSearch}>
                          Clear
                        </Button>
                      </center>
                    </Col>
                  </Row>
                </Card>
              </Spin>
            </Col>
          </div>
        }

        <BroadbandPlansTable
          data={dataSource}
          navigate={this.navigateToAddNewBroadbandPlan}
          navigateToUpdate={(data) => this.navigateToUpdate(data)}
          deleteRecord={this.deleteBroadbandFeeRecord} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  error: state.dmoVdo.error,
  retailerList: state.retailers.retailerList,
  tariffList: state.tariff.tariffList,
  broadbandPlans: state.broadbandPlans.broadbandPlans,
  deleteStatus: state.broadbandPlans.deleteStatus,
})

const mapDispatchToProps = dispath => ({
  getRetailerList: (data) => dispath(getRetailerList(data)),
  getUniqueTariffs: () => dispath(getUniqueTariffs()),
  searchBroadbandPlanRecords: (data) => dispath(searchBroadbandPlanRecords(data)),
  getAllBroadbandPlanDetails: () => dispath(getAllBroadbandPlanDetails()),
  setSelectedBroadbandPlan: (data) => dispath(setSelectedBroadbandPlan(data)),
  deleteBroadbandPlan: (id) => dispath(deleteBroadbandPlans(id)),
  setCreateBroadbandStatusToDefault: () => dispath(setCreateBroadbandStatusToDefault()),
  clearError: () => dispath(clearError()),
})

const WrappedBroadbandGPlans = Form.create()(BroadbandPlans);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedBroadbandGPlans)