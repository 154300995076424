import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Tag, Input, Row, Button, Select,InputNumber, Popconfirm,} from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { getElecPostcodeDistributorList, updateElecPostcodeDistributorList, addElecPostcodeDetails, deleteElecPostcodeDetails } from "appRedux/actions/admin/postcodeMap";
import { getDistributorList } from "appRedux/actions/admin/distributors";

import Moment from 'moment';
import { extendMoment } from 'moment-range';

import isEmpty from "util/isEmpty";

const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);

const expandedRowRender = record => <p>{record.createdDate}<br />{record.reference}</p>;
//const title = () => 'Here is title';
const showHeader = true;
//const footer = () => 'Here is footer';
//const scroll = {y: 500};
const pagination = { position: 'bottom' };

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

const EditableContext = React.createContext();

class EditableCell extends React.Component {

  getInput = () => {
    if (this.props.inputType === 'distributorDropDown') {
      return  <Select
                showSearch
                style={{ width: 130 }}
                placeholder="Select Distributor"
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value="-1">Select</Option>
                {!isEmpty(this.props.distributorList) && this.props.distributorList.map((distributor, key) => {
                    return <option key={key} value={distributor.id}>{distributor.distributorName}</option>;
                })}
              </Select>;
    }
    else if(this.props.inputType === 'stateDropDown') {
      return  <Select
                showSearch
                style={{ width: 80 }}
                placeholder="Select State"
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value="-1">Select</Option>
                <Option value="VIC">VIC</Option>
                <Option value="NSW">NSW</Option>
                <Option value="QLD">QLD</Option>
                <Option value="ACT">ACT</Option>
                <Option value="SA">SA</Option>
                <Option value="WA">WA</Option>
              </Select>;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0, width: '100%' , display:'block',}}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class ViewPostCodeDistributorMap extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editingKey: '',
      isAdding : false,
      isSearching : false,
      recordsEdited: false,
      expand: false,
      dataSource: [],
      addingNewRecord : false,
      postcodeRecordIdToDelete: null,
      postcodeToDelete: null,
      showAddNewPostcodePanel: false,
      showWarning: false,
      warningMessage: "",
      showSuccess: false,
      successMessage: "",
      internalDNCToSearch: {
        dncType: null,
        number: null,
      },
      bordered: true,
      loading: true,
      pagination,
      size: 'middle',
      expandedRowRender,
      title: undefined,
      showHeader,
      footer: undefined,
      rowSelection: {},
    };
    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 80,
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Creted Date',
        dataIndex: 'createdDate',
        key: 'createdDate',
        width: 150,
        sorter: (a, b) => a.createdDate - b.createdDate,
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        width: 100,
        editable: true,
        sorter: (a, b) => {return a.state.localeCompare(b.state)},
      },
      {
        title: 'Postcode',
        dataIndex: 'postcode',
        key: 'postcode',
        width: 100,
        editable: true,
        sorter: (a, b) => a.postcode - b.postcode,
      },
      {
        title: 'Suburb',
        dataIndex: 'suburb',
        key: 'suburb',
        width: 150,  
        editable: true,
        sorter: (a, b) => {return a.suburb.localeCompare(b.suburb)},
      },
      {
        title: 'AGL Distributor',
        dataIndex: 'aglDistributor',
        key: 'aglDistributor',
        width: 150,
        editable: true,
        sorter: (a, b) => { return a.aglDistributor.localeCompare(b.aglDistributor)},
      },
      {
        title: 'Power Direct Distributor',
        dataIndex: 'powedirectDistributor',
        key: 'powedirectDistributor',
        width: 150,
        editable: true,
        sorter: (a, b) => {return a.powedirectDistributor.localeCompare(b.powedirectDistributor)},
      },
      {
        title: 'Origin Energy Distributor',
        dataIndex: 'originEnergyDistributor',
        key: 'originEnergyDistributor',
        width: 150,
        editable: true,
        sorter: (a, b) =>{return a.originEnergyDistributor.localeCompare(b.originEnergyDistributor)},
      },
      {
        title: 'Origin Energy Special Product Name',
        dataIndex: 'originEnergySpecialProductName',
        key: 'originEnergySpecialProductName',
        width: 150,
        editable: true,
        sorter: (a, b) =>{return a.originEnergySpecialProductName.localeCompare(b.originEnergySpecialProductName)},
      },
      {
        title: 'Origin Energy Origin Saver Patch',
        dataIndex: 'originEnergyOriginSaverPatch',
        key: 'originEnergyOriginSaverPatch',
        width: 150,
        editable: true,
        sorter: (a, b) => {return a.originEnergyOriginSaverPatch.localeCompare(b.originEnergyOriginSaverPatch)},
      },
      {
        title: 'Origin Energy Business Saver HV',
        dataIndex: 'originEnergyBusinessSaverHV',
        key: 'originEnergyBusinessSaverHV',
        width: 150,
        editable: true,
        sorter: (a, b) => {return a.originEnergyBusinessSaverHV.localeCompare(b.originEnergyBusinessSaverHV)},
      },
      {
        title: 'Lumo Energy Distributor',
        dataIndex: 'lumoEnergyDistributor',
        key: 'lumoEnergyDistributor',
        width: 150,
        editable: true,
        sorter: (a, b) => {return a.lumoEnergyDistributor.localeCompare(b.lumoEnergyDistributor)},
      },
      {
        title: 'Momentum Distributor',
        dataIndex: 'momentumDistributor',
        key: 'momentumDistributor',
        width: 150,
        editable: true,
        sorter: (a, b) => {return a.momentumDistributor.localeCompare(b.momentumDistributor)},
      },
      {
        title: 'Powershop Distributor',
        dataIndex: 'powershopDistributor',
        key: 'powershopDistributor',
        width: 150,
        editable: true,
        sorter: (a, b) => {return a.powershopDistributor.localeCompare(b.powershopDistributor)},
      },
      {
        title: 'Alinta Energy Distributor',
        dataIndex: 'alintaEnergyDistributor',
        key: 'alintaEnergyDistributor',
        width: 150,
        editable: true,
        sorter: (a, b) => {return a.alintaEnergyDistributor.localeCompare(b.alintaEnergyDistributor)},
      },
      {
        title: 'Energy Australia Distributor',
        dataIndex: 'energyAustraliaDistributor',
        key: 'energyAustraliaDistributor',
        width: 150,
        editable: true,
        sorter: (a, b) => {return a.energyAustraliaDistributor.localeCompare(b.energyAustraliaDistributor)},
      },
      {
        title: 'Sumo Power Distributor',
        dataIndex: 'sumoPowerDistributor',
        key: 'sumoPowerDistributor',
        width: 150,
        editable: true,
        sorter: (a, b) => {return a.sumoPowerDistributor.localeCompare(b.sumoPowerDistributor)},
      },
      {
        title: 'ERM Distributor',
        dataIndex: 'ermDistributor',
        key: 'ermDistributor',
        width: 150,
        editable: true,
        sorter: (a, b) => {return a.ermDistributor.localeCompare(b.ermDistributor)},
      },
      {
        title: 'PD AGL Distributor',
        dataIndex: 'pdAGLDistributor',
        key: 'pdAGLDistributor',
        width: 150,
        editable: true,
        sorter: (a, b) => {return a.pdAGLDistributor.localeCompare(b.pdAGLDistributor)},
      },
      {
        title: 'Next Business Energy Distributor',
        dataIndex: 'nextBusinessEnergyDistributor',
        key: 'nextBusinessEnergyDistributor',
        width: 150,
        editable: true,
        sorter: (a, b) => {return a.nextBusinessEnergyDistributor.localeCompare(b.nextBusinessEnergyDistributor)},
      },
      {
        title: 'Actew AGL Distributor',
        dataIndex: 'actewAGLDistributor',
        key: 'actewAGLDistributor',
        width: 150,
        editable: true,
        sorter: (a, b) => {return a.actewAGLDistributor.localeCompare(b.actewAGLDistributor)},
      },
      {
        title: 'Elysian Energy Distributor',
        dataIndex: 'elysianEnergyDistributor',
        key: 'elysianEnergyDistributor',
        width: 150,
        editable: true,
        sorter: (a, b) => {return a.elysianEnergyDistributor.localeCompare(b.elysianEnergyDistributor)},
      },
      {
        title: 'Testing Retailer Distributor',
        dataIndex: 'testingRetailerDistributor',
        key: 'testingRetailerDistributor',
        width: 150,
        editable: true,
        sorter: (a, b) => {return a.testingRetailerDistributor.localeCompare(b.testingRetailerDistributor)},
      },
      {
        title: 'Master Distributor',
        dataIndex: 'masterDistributor',
        key: 'masterDistributor',
        width: 150,
        sorter: (a, b) => {return a.masterDistributor.localeCompare(b.masterDistributor)},
      },
      {
        title: 'Action',
        key: 'action',
        width: 100,
        fixed: 'right',
        render: (text, record) =>{
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <center>
                <EditableContext.Consumer>
                  {form => (
                    <a
                      onClick={() => this.save(form, record.key)}
                      
                    >
                      <i className="icon icon-check-circle-o"/>
                    </a>
                  )}
                </EditableContext.Consumer>
                <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                  <a style={{ marginLeft: 10 }}><i className="icon icon-close-circle"/></a>
                </Popconfirm>
              </center>
            </span>
          ) : (
            <span>
              <center>
                <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
                  <i className="icon icon-edit"/>
                </a>
                <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.confirmDelete(record.id, record.postcode)}>
                  <i className="icon icon-trash"/>
                </a>
              </center>
            </span>
          );
        },
      }
    ];

  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };
  
  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.dataSource];
      const index = newData.findIndex(item => key === item.key);   

      if (index > -1) {
        const item = newData[index];

        const valuesToUpdate = {
          id : item.id,
          state : row.state,
          postcode : row.postcode,
          suburb : row.suburb,
          aglDistributor : isNaN(row.aglDistributor) ? null : row.aglDistributor,
          powedirectDistributor : isNaN(row.powedirectDistributor) ? null : row.powedirectDistributor,
          originEnergyDistributor : isNaN(row.originEnergyDistributor) ? null : row.originEnergyDistributor,
          originEnergySpecialProductName : isNaN(row.originEnergySpecialProductName) ? null : row.originEnergySpecialProductName,
          originEnergyOriginSaverPatch : isNaN(row.originEnergyOriginSaverPatch) ? null : row.originEnergyOriginSaverPatch,
          originEnergyBusinessSaverHV : isNaN(row.originEnergyBusinessSaverHV) ? null : row.originEnergyBusinessSaverHV,
          lumoEnergyDistributor : isNaN(row.lumoEnergyDistributor) ? null : row.lumoEnergyDistributor,
          momentumDistributor : isNaN(row.momentumDistributor) ? null : row.momentumDistributor,
          powershopDistributor : isNaN(row.powershopDistributor) ? null : row.powershopDistributor,
          alintaEnergyDistributor : isNaN(row.alintaEnergyDistributor) ? null : row.alintaEnergyDistributor,
          energyAustraliaDistributor : isNaN(row.energyAustraliaDistributor) ? null : row.energyAustraliaDistributor,
          sumoPowerDistributor : isNaN(row.sumoPowerDistributor) ? null : row.sumoPowerDistributor,
          ermDistributor : isNaN(row.ermDistributor) ? null : row.ermDistributor,
          pdAGLDistributor : isNaN(row.pdAGLDistributor) ? null : row.pdAGLDistributor,
          nextBusinessEnergyDistributor : isNaN(row.nextBusinessEnergyDistributor) ? null : row.nextBusinessEnergyDistributor,
          actewAGLDistributor : isNaN(row.actewAGLDistributor) ? null : row.actewAGLDistributor,
          elysianEnergyDistributor : isNaN(row.elysianEnergyDistributor) ? null : row.elysianEnergyDistributor,
          testingRetailerDistributor : isNaN(row.testingRetailerDistributor) ? null : row.testingRetailerDistributor,
        }
  
        this.setState({ loading: true });
        this.props.updateElecPostcodeDistributorList(valuesToUpdate); 

        this.setState({ 
          editingKey: '',
          recordsEdited:true });
      } 
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  confirmDelete = (key, postcode) => {
    this.setState({ postcodeRecordIdToDelete: key});
    this.setState({ postcodeToDelete: postcode});
    this.setState({ showWarning: true});

  };

  onCancelDelete = key => {
    this.setState({ postcodeRecordIdToDelete: null});
    this.setState({ postcodeToDelete: null});
    this.setState({ showWarning: false});
  };

  handleDelete = () => {
    const deleteInfo = {
      id: this.state.postcodeRecordIdToDelete,
      postcode: this.state.postcodeToDelete,
    }
    this.props.deleteElecPostcodeDetails(deleteInfo);
    this.setState({ showWarning: false});
  };

  onConfirm = () => {
    this.setState({ 
      showSuccess: false,
      showAddNewPostcodePanel : false,
    });
    this.handleReset();
  };

  componentDidMount() {
    this.props.getElecPostcodeDistributorList();
    this.props.getDistributorList();
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      const filtersForSearch = {
        state : values.drpState,
        postcode : values.txtPostcode,
        distributorId: values.drpDistributor,
      }
      this.setState({ isSearching: true });
      this.props.getElecPostcodeDistributorList(filtersForSearch);
    });
  };

  handleAddPostcodeDetails = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      const postcodeDetails = {
        state : values.drpStateToAdd,
        postcode : values.txtPostcodeToAdd,
        suburb : values.txtSuburbToAdd,
      }

      this.setState({ isAdding: true });
      this.props.addElecPostcodeDetails(postcodeDetails);
    });
  };

  handleAdd = (e) => {
    this.setState({ showAddNewPostcodePanel: true });
  };

  onAddCancel = (e) => {
    this.setState({ showAddNewPostcodePanel: false });
    this.handleReset();
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.props.getElecPostcodeDistributorList();
    this.props.getDistributorList();
  };

  checkColoumnType = (field) => {
    switch(field)
    {
      case 'state' : return "stateDropDown";
      case 'aglDistributor' : return "distributorDropDown";
      case 'powedirectDistributor' : return "distributorDropDown";
      case 'originEnergyDistributor' : return "distributorDropDown";
      case 'originEnergySpecialProductName' : return "distributorDropDown";
      case 'originEnergyOriginSaverPatch' : return "distributorDropDown";
      case 'originEnergyBusinessSaverHV' : return "distributorDropDown";
      case 'lumoEnergyDistributor' : return "distributorDropDown";
      case 'momentumDistributor' : return "distributorDropDown";
      case 'powershopDistributor' : return "distributorDropDown";
      case 'alintaEnergyDistributor' : return "distributorDropDown";
      case 'energyAustraliaDistributor' : return "distributorDropDown";
      case 'sumoPowerDistributor' : return "distributorDropDown";
      case 'ermDistributor' : return "distributorDropDown";
      case 'pdAGLDistributor' : return "distributorDropDown";
      case 'nextBusinessEnergyDistributor' : return "distributorDropDown";
      case 'actewAGLDistributor' : return "distributorDropDown";
      case 'elysianEnergyDistributor' : return "distributorDropDown";
      case 'testingRetailerDistributor' : return "distributorDropDown";
      default : return "text";
    }
  }

  componentWillReceiveProps(nextProps) {
    if(!isEmpty(nextProps.postcodeDistributorList))
    {
      this.setState({ loading: true });
      this.state.dataSource = [];
      let count = 1;      
      nextProps.postcodeDistributorList.map((elecPostcodeDistributor, key) => {
        let record = {
          key: count.toString(),
          id: elecPostcodeDistributor.id,
          createdDate: !isEmpty(elecPostcodeDistributor.created) ? moment(elecPostcodeDistributor.created).format('YYYY-MM-DD h:mm:ss A') : "-",          
          state: !isEmpty(elecPostcodeDistributor.state) ? elecPostcodeDistributor.state : "-",
          postcode: elecPostcodeDistributor.postcode,
          suburb: !isEmpty(elecPostcodeDistributor.suburb) ? elecPostcodeDistributor.suburb : "-",
          aglDistributor: !isEmpty(elecPostcodeDistributor.agl_distributor) ? elecPostcodeDistributor.agl_distributor : "-",
          powedirectDistributor: !isEmpty(elecPostcodeDistributor.powerdirect_distributor) ? elecPostcodeDistributor.powerdirect_distributor : "-",
          originEnergyDistributor: !isEmpty(elecPostcodeDistributor.origin_energy_distributor) ? elecPostcodeDistributor.origin_energy_distributor : "-",
          originEnergySpecialProductName: !isEmpty(elecPostcodeDistributor.origin_energy_special_product_name) ? elecPostcodeDistributor.origin_energy_special_product_name : "-",
          originEnergyOriginSaverPatch: !isEmpty(elecPostcodeDistributor.origin_energy_origin_saver_patch) ? elecPostcodeDistributor.origin_energy_origin_saver_patch : "-",
          originEnergyBusinessSaverHV: !isEmpty(elecPostcodeDistributor.origin_energy_businesssaver_hv) ? elecPostcodeDistributor.origin_energy_businesssaver_hv : "-",
          lumoEnergyDistributor: !isEmpty(elecPostcodeDistributor.lumo_energy_distributor) ? elecPostcodeDistributor.lumo_energy_distributor : "-",
          momentumDistributor: !isEmpty(elecPostcodeDistributor.momentum_distributor) ? elecPostcodeDistributor.momentum_distributor : "-",
          powershopDistributor: !isEmpty(elecPostcodeDistributor.powershop_distributor) ? elecPostcodeDistributor.powershop_distributor : "-",
          alintaEnergyDistributor: !isEmpty(elecPostcodeDistributor.alinta_energy_distributor) ? elecPostcodeDistributor.alinta_energy_distributor : "-",
          energyAustraliaDistributor: !isEmpty(elecPostcodeDistributor.energy_australia_distributor) ? elecPostcodeDistributor.energy_australia_distributor : "-",
          sumoPowerDistributor: !isEmpty(elecPostcodeDistributor.sumo_power_distributor) ? elecPostcodeDistributor.sumo_power_distributor : "-",
          ermDistributor: !isEmpty(elecPostcodeDistributor.erm_distributor) ? elecPostcodeDistributor.erm_distributor : "-",
          pdAGLDistributor: !isEmpty(elecPostcodeDistributor.pd_agl_distributor) ? elecPostcodeDistributor.pd_agl_distributor : "-",
          nextBusinessEnergyDistributor: !isEmpty(elecPostcodeDistributor.next_business_energy_distributor) ? elecPostcodeDistributor.next_business_energy_distributor : "-",
          actewAGLDistributor: !isEmpty(elecPostcodeDistributor.actewagl_distributor) ? elecPostcodeDistributor.actewagl_distributor : "-",
          elysianEnergyDistributor: !isEmpty(elecPostcodeDistributor.elysian_energy_distributor) ? elecPostcodeDistributor.elysian_energy_distributor : "-",
          testingRetailerDistributor: !isEmpty(elecPostcodeDistributor.testing_retailer_distributor) ? elecPostcodeDistributor.testing_retailer_distributor : "-",
          masterDistributor: !isEmpty(elecPostcodeDistributor.master_distributor) ? elecPostcodeDistributor.master_distributor : "-",
        };
        this.state.dataSource.push(record);
        count++; 
      });
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }
    else
    {
      this.setState({ loading: true });
      this.state.dataSource = [];
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }

    if(!isEmpty(nextProps.recordUpdateStatus))
    {
      if(nextProps.recordUpdateStatus.status == "Success")
      {
        this.setState({
            successMessage: nextProps.recordUpdateStatus.message,
            showSuccess: true,
        });
        this.props.getElecPostcodeDistributorList();
        this.setState({ isAdding: false });
      }
    }
  }


  render() {

    let { dataSource } = this.state;

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          distributorList: this.props.distributorList,
          inputType: this.checkColoumnType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-main-content">
        <ContainerHeader title="Manage Electricity Postcode Mapping"  />
        <Row>
          <Col span={24}>
          <Spin spinning={this.state.isSearching}>
            <Card className="gx-card" title="Search Postcode Mapping">
              <Form
                className="ant-advanced-search-form"
                onSubmit={this.handleSearch}
              >
                <Row>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="State">
                        {getFieldDecorator('drpState', {
                          rules: [{ required: false, message: 'Please select a State!' }],
                        })(
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a State"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          >
                            <Option value="-1">Select</Option>
                            <Option value="VIC">VIC</Option>
                            <Option value="NSW">NSW</Option>
                            <Option value="QLD">QLD</Option>
                            <Option value="ACT">ACT</Option>
                            <Option value="SA">SA</Option>
                            <Option value="WA">WA</Option>
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Distributor">
                        {getFieldDecorator('drpDistributor', {
                          rules: [{ required: false, message: 'Please select a State!' }],
                        })(
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a Distributor"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          >
                            <Option value="-1">Select</Option>
                            {!isEmpty(this.props.distributorList) && this.props.distributorList.map((distributor, key) => {
                                return <option key={key} value={distributor.id}>{distributor.distributorName}</option>;
                            })}
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Postcode" >
                        {getFieldDecorator('txtPostcode', {
                          rules: [{ required: false, message: 'Enter a postcode!' }],
                        })(
                          <Input style={{ width: '100%' }} placeholder="Enter a postcode" />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col span={24} className="gx-text-right">
                    <center>
                      <Button type="primary" htmlType="submit">Search</Button>
                      <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                        Clear
                    </Button>
                    </center>
                  </Col>
                </Row>
              </Form>
            </Card>
            </Spin>
          </Col>
          {this.state.showAddNewPostcodePanel && <Col span={24}>
          <Spin spinning={this.state.isAdding}>
            <Card className="gx-card" title="Add New Postcode Details">
              <Form
                className="ant-advanced-search-form"
                onSubmit={this.handleAddPostcodeDetails}
              >
                <Row>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="State">
                        {getFieldDecorator('drpStateToAdd', {
                          rules: [{ required: true, message: 'Please select a State!' }],
                        })(
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Select a State"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          >
                            <Option value="-1">Select</Option>
                            <Option value="VIC">VIC</Option>
                            <Option value="NSW">NSW</Option>
                            <Option value="QLD">QLD</Option>
                            <Option value="ACT">ACT</Option>
                            <Option value="SA">SA</Option>
                            <Option value="WA">WA</Option>
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Postcode" >
                        {getFieldDecorator('txtPostcodeToAdd', {
                          rules: [{ required: true, message: 'Please enter postcode!' }],
                        })(
                          <Input style={{ width: '100%' }} placeholder="Please enter postcode" />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Suburb" >
                        {getFieldDecorator('txtSuburbToAdd', {
                          rules: [{ required: false, message: 'Please enter suburb' }],
                        })(
                          <Input style={{ width: '100%' }} placeholder="Please enter suburb" />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col span={24} className="gx-text-right">
                    <center>
                      <Button type="primary" htmlType="submit">Add Postcode Details</Button>
                      <Button style={{ marginLeft: 8 }} onClick={this.onAddCancel}>
                        Cancel
                    </Button>
                    </center>
                  </Col>
                </Row>
              </Form>
            </Card>
            </Spin>
          </Col>
          }
          <Col span={24}>
            <Card className="gx-card" title="Postcode Mapping Records">
              <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Add New Postcode
              </Button>
              <EditableContext.Provider value={this.props.form}>
                <Table 
                components={components}
                rowClassName="editable-row"
                className="gx-table-responsive" 
                {...this.state} 
                scroll={{ x: 4000, y: 400 }} 
                columns={columns} 
                dataSource={dataSource} />
              </EditableContext.Provider>
            </Card>
          </Col>
        </Row>
        <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
        <SweetAlert show={this.state.showWarning}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Are you sure?"
                    onConfirm={this.handleDelete}
                    onCancel={this.onCancelDelete}
        ></SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  postcodeDistributorList: state.postcodemap.postcodeDistributorList,
  recordUpdateStatus: state.postcodemap.recordUpdateStatus,
  distributorList: state.distributors.distributorList,
});

const mapDispatchToProps = dispath => ({
  getElecPostcodeDistributorList: (data) => dispath(getElecPostcodeDistributorList(data)),
  getDistributorList: (data) => dispath(getDistributorList(data)),
  updateElecPostcodeDistributorList: (data) => dispath(updateElecPostcodeDistributorList(data)),
  addElecPostcodeDetails: (data) => dispath(addElecPostcodeDetails(data)),
  deleteElecPostcodeDetails: (data) => dispath(deleteElecPostcodeDetails(data)),
});

const WrappedAdvancedSearch = Form.create()(ViewPostCodeDistributorMap);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);