import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "util/isEmpty";
import { Form, Row, Col } from "antd";
import { Bar, BarChart, CartesianGrid, Legend, Line, LabelList, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Widget from "components/Widget";
import Aux from "util/Auxiliary";

import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { FcGraduationCap, FcHeadset, FcClock, FcDown, FcUp } from 'react-icons/fc';

const moment = extendMoment(Moment);
var momentTimezone = require('moment-timezone');


class CallStats extends Component {



    render() {

        let callData = this.props.callStats;
        let agentCallRank = !isEmpty(callData) ? callData.rank : 0;
        let totalCalls = !isEmpty(callData) ? callData.totalCalls : 0;
        let totalInbound = !isEmpty(callData) ? callData.totalInbound : 0;
        let totalInboundMins = !isEmpty(callData) ? (callData.totalInboundSec) / 60 : 0;
        let totalOutbound = !isEmpty(callData) ? callData.totalOutbound : 0;
        let totalOutboundMins = !isEmpty(callData) ? (callData.totalOutboundSec) / 60 : 0;

        let hourlyCallStats = [];
        let startHour = 7;

        !isEmpty(callData) && Object.keys(callData.callLogs).map((callLogBlock, key) => {

            let tempLog = {
                section: `${startHour}-${startHour + 1}`,
                callCount: callData.callLogs[callLogBlock]
            }

            hourlyCallStats.push(tempLog)

            startHour = startHour + 1;

        });

        return (
            <>
                <Row>
                    <Col md={12} xs={24} className="gx-col-full">
                        <Row>
                            <Col md={12} xs={24} className="gx-col-full">
                                <Widget>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-mr-lg-4 gx-mr-3">
                                            <FcGraduationCap size={45} />
                                        </div>
                                        <div className="gx-media-body">
                                            <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{agentCallRank}</h1>
                                            <p className="gx-text-grey gx-mb-0">Calls Rank</p>
                                        </div>
                                    </div>
                                </Widget>
                            </Col>
                            <Col md={12} xs={24} className="gx-col-full">
                                <Widget>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-mr-lg-4 gx-mr-3">
                                            <FcHeadset size={45} />
                                        </div>
                                        <div className="gx-media-body">
                                            <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalCalls}</h1>
                                            <p className="gx-text-grey gx-mb-0">Total Calls</p>
                                        </div>
                                    </div>
                                </Widget>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xs={24} className="gx-col-full">
                                <Widget>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-mr-lg-4 gx-mr-3">
                                            <FcDown size={45} />
                                        </div>
                                        <div className="gx-media-body">
                                            <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalInbound}</h1>
                                            <p className="gx-text-grey gx-mb-0">Total Inbound</p>
                                        </div>
                                    </div>
                                </Widget>
                            </Col>
                            <Col md={12} xs={24} className="gx-col-full">
                                <Widget>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-mr-lg-4 gx-mr-3">
                                            <FcClock size={45} />
                                        </div>
                                        <div className="gx-media-body">
                                            <h1 className="gx-fs-xxl gx-font-weight-medium gx-mb-1">{moment("2000-01-01 00:00:00", "YYYY-MM-DD HH:mm:ss").add(parseInt(totalInboundMins.toFixed(0)), "minutes").format("HH[h] mm[m]")}</h1>
                                            <p className="gx-text-grey gx-mb-0">Inbound Talktime</p>
                                        </div>
                                    </div>
                                </Widget>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xs={24} className="gx-col-full">
                                <Widget>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-mr-lg-4 gx-mr-3">
                                            <FcUp size={45} />
                                        </div>
                                        <div className="gx-media-body">
                                            <h1 className="gx-fs-xxxl gx-font-weight-medium gx-mb-1">{totalOutbound}</h1>
                                            <p className="gx-text-grey gx-mb-0">Total Outbound</p>
                                        </div>
                                    </div>
                                </Widget>
                            </Col>
                            <Col md={12} xs={24} className="gx-col-full">
                                <Widget>
                                    <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                        <div className="gx-mr-lg-4 gx-mr-3">
                                            <FcClock size={45} />
                                        </div>
                                        <div className="gx-media-body">
                                            <h1 className="gx-fs-xxl gx-font-weight-medium gx-mb-1">{moment("2000-01-01 00:00:00", "YYYY-MM-DD HH:mm:ss").add(parseInt(totalOutboundMins.toFixed(0)), "minutes").format("HH[h] mm[m]")}</h1>
                                            <p className="gx-text-grey gx-mb-0">Outbound Talktime</p>
                                        </div>
                                    </div>
                                </Widget>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} xs={24} className="gx-col-full">
                        <Widget title="Hourly Call Stats" >
                            <ResponsiveContainer width="100%" height={280}>
                                <LineChart data={hourlyCallStats}
                                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                    <XAxis dataKey="section" interval={"preserveStartEnd"} />
                                    <YAxis />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" name={'Total Calls'} dataKey="callCount" stroke="#66000c" activeDot={{ r: 8 }} />
                                    {/* <Line type="monotone" name={'Total Electricity'} dataKey="elec" stroke="#3293a8" activeDot={{ r: 6 }} />
                        <Line type="monotone" name={'Total Gas'} dataKey="gas" stroke="#3257a8" activeDot={{ r: 6 }} /> */}
                                </LineChart>
                            </ResponsiveContainer>
                        </Widget>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispath => ({

});

const WrappedAdvancedSearch = Form.create()(CallStats);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);
