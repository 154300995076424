const actionTypePrefix = "PLAN";

const initialState = {
    resultList: {},
    recordUpdateStatus: null,
    error: null,
  }
  
  export default function (state = initialState, action){
    switch(action.type)
    {
        case `SAVE_${actionTypePrefix}_RECORDS`:
            return{
                ...state,
                resultList: action.resultList
            };
        case 'SAVE_RECORD_UPDATE_STATUS':
            return {
                ...state,
                recordUpdateStatus: action.recordUpdateStatus
            };
        case 'ERROR':
            return{
                ...state,
                error: action.error
            };
        default:
            return state;
    }
  }