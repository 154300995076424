import React from "react";
import {Route, Switch} from "react-router-dom";
import HourlySalesStats from "./HourlySalesStats";
import SalesDistribution from "./SalesDistribution";
import DemographicStats from "./DemographicStats";

const SalesReports = ({match}) => (
  <Switch>
    <Route path={`${match.url}/hourlySalesStats`} component={HourlySalesStats}/>
    <Route path={`${match.url}/salesDistribution`} component={SalesDistribution}/>
    <Route path={`${match.url}/demographicStats`} component={DemographicStats}/>
  </Switch>
);

export default SalesReports;
