import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';
import isEmpty from "util/isEmpty";

export const saveLeadDataDetailsList = leadDataList => ({
    type: 'SAVE_LEAD_DATA_DETAILS_LIST',
    leadDataList,
});

export const saveLeadDataForNewDataPack = leadDataForNewDataPack => ({
    type: 'SAVE_LEAD_DATA_FOR_NEW_DATAPACK',
    leadDataForNewDataPack,
});

export const saveLeadDataListDetailsList = leadDataListDetails => ({
    type: 'SAVE_LEAD_DATA_LIST_DETAILS_LIST',
    leadDataListDetails,
});

export const saveLeadDataUploadStatus = leadDataUploadStatus => ({
    type: 'SAVE_LEAD_DATA_UPLOAD_STATUS',
    leadDataUploadStatus,
});

export const deleteLeadDataStatus = deleteLeadDataStatus => ({
    type: 'DELETE_LEAD_DATA_STATUS',
    deleteLeadDataStatus,
});

export const error = error => ({
    type: 'ERROR',
    error,
});

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const getLeadData = (data) => dispath =>{

    if(isEmpty(data))
    {   
        axios.get(apidetails.API_URL + 'leadData/')
        .then(res => {
            dispath(saveLeadDataDetailsList(res.data.records));
        })
        .catch(err => {
            dispath(error(err.message || 'ERROR'));
        });  
    }
    else
    {
        axios.post(apidetails.API_URL + 'leadData/', qs.stringify(data), config)
        .then(res => {
            dispath(saveLeadDataDetailsList(res.data.records));
        })
        .catch(err => {
            dispath(error(err.message || 'ERROR'));
        });  
    }
      
}

export const getLeadForNewDataPack = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'leadData/', qs.stringify(data), config)
    .then(res => {
        dispath(saveLeadDataForNewDataPack(res.data.records));
    })
    .catch(err => {
        dispath(error(err.message || 'ERROR'));
    });  
      
}

export const removeLeadForNewDataPack = (data) => dispath =>{
    dispath(saveLeadDataForNewDataPack(null));
}


export const getLeadDataListNames = (data) => dispath =>{

    axios.get(apidetails.API_URL + 'leadData/getLists')
    .then(res => {
        dispath(saveLeadDataListDetailsList(res.data.listRecords));
    })
    .catch(err => {
        dispath(error(err.message || 'ERROR'));
    });    
}

let csvResults = "";

function setCSVData(results) {
    csvResults = results;
}

export const uploadLeadData = (info, file) => dispath =>{

    dispath(saveLeadDataUploadStatus("Uploading"));
    Papa.parse(file,{        
        header: true,
        dynamicTyping: true,
        complete: function (results) {
            setCSVData(results)
            info.csvData = JSON.stringify(csvResults.data);
            info.fileName = file.name;

            if(info !== undefined)
            {
                let formData = new FormData();

                for(let key in info)
                {
                    formData.append(key, info[key]);
                }
                
                axios.post(apidetails.API_URL + 'leadData/uploadLeads', qs.stringify(info), config)
                .then(res => {
                    dispath(saveLeadDataUploadStatus(res.data));
                    dispath(saveLeadDataUploadStatus("Pending"));
                })
                .catch(err => {
                    dispath(error(err.message || 'ERROR'));
                });


            }  
        }
    });
    
}