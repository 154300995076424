import React from 'react'

import styles from './DataLoader.module.less'
const DataLoader = ({ message }) => {
    return (
        <div className={styles.loaderWrapper}>
            <div className={styles['lds-ellipsis']}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className={styles.mp0}>{message ? message : 'Data Fetching...'}</div>
        </div>

    )
}

export default DataLoader