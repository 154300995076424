import React from "react";
import {Route, Switch} from "react-router-dom";
import ManageStatus from "./ManageStatus";
import ManageMailingLists from "./ManageMailingLists";
import ManageModules from "./ManageModules";
import ManageAccessLevels from "./ManageAccessLevels";

const Settings = ({match}) => (
  <Switch>
    <Route path={`${match.url}/manageStatus`} component={ManageStatus}/>
    <Route path={`${match.url}/manageMailiningLists`} component={ManageMailingLists}/>
    <Route path={`${match.url}/manageModules`} component={ManageModules}/>
    <Route path={`${match.url}/manageAccessLevels`} component={ManageAccessLevels}/>
  </Switch>
);

export default Settings;
