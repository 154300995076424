import React from 'react'
import { Card, Tooltip } from "antd";
import styles from './BroadbandResult.module.less';
import classNames from 'classnames';

import { FaExclamationCircle } from 'react-icons/fa';
import isEmpty from "util/isEmpty";

const SummaryCard = (props) => {

    const {
        title,
        description,
        type,
        variant,
        icon,
        tooltip,
        discountPrice,
        actualPrice,
        textStyle,
        additionalText

    } = props
    return (

        <Card className={classNames(styles.summaryCard, variant ? styles[`card-${variant}`] : '')}>

            <div className={classNames(styles.summaryCardWrapper)}>

                <div className={classNames(styles.summaryCardContent)}>

                    {type === "package" && (
                        <>
                            <h3 className={classNames(styles.summaryCardTitle, textStyle && styles[textStyle])} >
                                {!isEmpty(discountPrice) && <>{discountPrice} <span className={classNames(styles.actualPriceText)}>{actualPrice}</span></> } 
                                {isEmpty(discountPrice) && <>{actualPrice}</> } 
                            </h3>
                            {
                                description && <p style={{fontSize:"10px"}} className={classNames(styles.light)}>
                                    {description}
                                </p>
                            }
                        </>
                    )}

                    {type !== "package" && (<>
                        <h3 className={classNames(styles.summaryCardTitle, textStyle && styles[textStyle])} >
                            {title} {tooltip && <Tooltip placement="topRight" title={tooltip} className={styles.summaryCardTooltipIcon}>
                                <FaExclamationCircle />
                            </Tooltip>}
                        </h3>
                        {
                            description && <p style={{fontSize:"10px"}} className={classNames(styles.dark)}>
                                {description}
                            </p>
                        }
                        {/* {
                            icon && <div style={{width:"100%"}} className={classNames(styles.summaryCardIcon, textStyle && styles[textStyle])}>
                                 <p style={{fontSize:'30px'}}>{icon}</p>
                            </div>
                        } */}
                    </>

                    )}



                </div>


                {
                    icon && <div className={classNames(styles.summaryCardIcon, textStyle && styles[textStyle])}>
                        {icon}
                    </div>
                }


            </div>

        </Card>
    )
}

export default SummaryCard