import React, { useEffect, useState } from 'react'
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Card, Table, Col, Button, Popconfirm, Tag, Icon, } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";

const EditableContext = React.createContext();
const moment = extendMoment(Moment);

const BroadbandPlansTable = (props) => {
    const editingKey = null
    const [dataSource, setDataSource] = useState([])
    const [isEditing, setIsEditing] = useState(null)
    const [showDelete, setShowDelete] = useState(false)
    const [selectedId, setSelectedId] = useState(null)

    useEffect(() => {
        if (props.data && props.data.result && props.data.result.length > 0) {

            setDataSource(props.data.result)

        }else{
            setDataSource([])
        }
    }, [props.data])

    const checkColoumnType = (field) => {
        switch (field) {
            case 'isActive': return "statusSelection";
            case 'state': return "stateSelection";
            default: return "text";
        }
    }

    const confirmDelete = () => {
        setShowDelete(false)
        props.deleteRecord(selectedId)
    }

    const columnsList = [
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 150,
            render: (text, record) => {
                const editable = setIsEditing(record)
                return editable ? (
                    <span>
                        <center>
                            <EditableContext.Consumer>
                                {form => (
                                    <a
                                        onClick={() => this.save(form, record.key)}

                                    >
                                        <i className="icon icon-check-circle-o" />
                                    </a>
                                )}
                            </EditableContext.Consumer>
                            <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                                <a style={{ marginLeft: 10 }}><i className="icon icon-close-circle" /></a>
                            </Popconfirm>
                        </center>
                    </span>
                ) : (
                    <span>
                        <center>
                            {/* <a onClick={() => props.navigateToUpdate(record)}>
                                <i className="icon icon-search-new" />
                            </a> */}
                            <a style={{ marginLeft: 10 }} onClick={() => props.navigateToUpdate(record)}>
                                <i className="icon icon-edit" />
                            </a>
                            {/* <a style={{ marginLeft: 10, color: "#52c41a" }} disabled={editingKey !== ''} onClick={() => this.duplicateRecord(record)}>
                                <i className="icon icon-copy" />
                            </a> */}
                            <a style={{ marginLeft: 10, color: "#f5222d" }} onClick={() => {
                                setSelectedId(record.id)
                                setShowDelete(true)
                            }}>
                                <i className="icon icon-close-circle" />
                            </a>

                        </center>
                    </span>
                );
            },
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 150,
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: 'Created Date',
            dataIndex: 'created',
            key: 'created',
            align: 'center',
            width: 150,
            sorter: (a, b) => moment(a.created, "YYYY-MM-DDTHH:mm:ss.SSSZ") - moment(b.created, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
            render: (text, record) => {
                return <Tag style={{ maxWidth: "120px", whiteSpace: "pre-wrap" }} color={"geekblue"} key={text}>
                    {moment(text).format('YYYY-MM-DD h:mm:ss A')}
                </Tag>
            }
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.state.localeCompare(b.state) },
        },
        {
            title: 'Retailer',
            dataIndex: 'retailer',
            key: 'retailer',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.retailer.localeCompare(b.retailer) },
        },
        {
            title: 'RES/SME',
            dataIndex: 'res_sme',
            key: 'res_sme',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.res_sme.localeCompare(b.res_sme) },
        },

        {
            title: 'Product Name',
            dataIndex: 'product_name',
            key: 'product_name',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.product_name.localeCompare(b.product_name) },
        },
        {
            title: 'Plan Eligibility',
            dataIndex: 'plan_eligibility',
            key: 'plan_eligibility',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.plan_eligibility.localeCompare(b.plan_eligibility) },
        },
        {
            title: 'Recommended Household Sizes',
            dataIndex: 'recommended_household_sizes',
            key: 'recommended_household_sizes',
            align: 'center',
            width: 100,
            editable: true,
            sorter: (a, b) => { return a.recommended_household_sizes.localeCompare(b.recommended_household_sizes) },
        },
        {
            title: 'Recommended Usage Level',
            dataIndex: 'recommended_usage_level',
            key: 'recommended_usage_level',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.recommended_usage_level.localeCompare(b.recommended_usage_level) },
        },
        {
            title: 'Connection Type',
            dataIndex: 'connection_type',
            key: 'connection_type',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.connection_type.localeCompare(b.connection_type) },
        },
        {
            title: 'Technology',
            dataIndex: 'technology',
            key: 'technology',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.technology.localeCompare(b.technology) },
        },
        {
            title: 'Contract Term',
            dataIndex: 'contract_term',
            key: 'contract_term',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.contract_term.localeCompare(b.contract_term) },
        },
        {
            title: 'Typical Evening Download Speed',
            dataIndex: 'typical_evening_download_speed',
            key: 'typical_evening_download_speed',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.typical_evening_download_speed.localeCompare(b.typical_evening_download_speed) },
        },
        {
            title: 'Satelllite Included',
            dataIndex: 'satelllite_included',
            key: 'satelllite_included',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.satelllite_included.localeCompare(b.satelllite_included) },
        },
        {
            title: 'Upload Speed',
            dataIndex: 'upload_speed',
            key: 'upload_speed',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.upload_speed.localeCompare(b.upload_speed) },
        },
        {
            title: 'Benefit Description',
            dataIndex: 'benefit_description',
            key: 'benefit_description',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.benefit_description.localeCompare(b.benefit_description) },
        },
        {
            title: 'Data Inclusion',
            dataIndex: 'data_inclusion',
            key: 'data_inclusion',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.data_inclusion.localeCompare(b.data_inclusion) },
        },
        {
            title: 'Monthly Charge',
            dataIndex: 'monthly_charge',
            key: 'monthly_charge',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.monthly_charge.localeCompare(b.monthly_charge) },
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.discount.localeCompare(b.discount) },
        },
        {
            title: 'Discount Type',
            dataIndex: 'discount_type',
            key: 'discount_type',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.discount_type.localeCompare(b.discount_type) },
        },
        {
            title: 'Discount Period Months',
            dataIndex: 'discount_period_months',
            key: 'discount_period_months',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.discount_period_months.localeCompare(b.discount_period_months) },
        },
        {
            title: 'Discount Applies',
            dataIndex: 'discount_applies',
            key: 'discount_applies',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.discount_applies.localeCompare(b.discount_applies) },
        },
        {
            title: 'Standard Activation fee',
            dataIndex: 'standard_activation_fee',
            key: 'standard_activation_fee',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.standard_activation_fee.localeCompare(b.standard_activation_fee) },
        },
        {
            title: 'Standard Activation Fee description',
            dataIndex: 'standard_activation_fee_description',
            key: 'standard_activation_fee_description',
            align: 'center',
            width: 150,
            editable: true,
            sorter: (a, b) => { return a.standard_activation_fee_description.localeCompare(b.standard_activation_fee_description) },
        },
        {
            title: 'Typical Evening Download Speed Description',
            dataIndex: 'typical_evening_download_speed_description',
            key: 'typical_evening_download_speed_description',
            align: 'center',
            width: 550,
            editable: true,
            sorter: (a, b) => { return a.typical_evening_download_speed_description.localeCompare(b.typical_evening_download_speed_description) },
        },
        {
            title: 'Product Description',
            dataIndex: 'product_description',
            key: 'product_description',
            align: 'center',
            width: 250,
            editable: true,
            sorter: (a, b) => { return a.product_description.localeCompare(b.product_description) },
        },
        {
            title: 'Standard Activation Fee Description',
            dataIndex: 'standard_activation_fee_description',
            key: 'standard_activation_fee_description',
            align: 'center',
            width: 250,
            editable: true,
            sorter: (a, b) => { return a.standard_activation_fee_description.localeCompare(b.standard_activation_fee_description) },
        },
        {
            title: 'Early Termination Charge Description',
            dataIndex: 'early_termination_charge_description',
            key: 'early_termination_charge_description',
            align: 'center',
            width: 250,
            editable: true,
            sorter: (a, b) => { return a.early_termination_charge_description.localeCompare(b.early_termination_charge_description) },
        },

    ];

    const columns = columnsList.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                inputType: checkColoumnType(col.dataIndex),
                dataIndex: col.dataIndex,
                title: col.title,
                editing: setIsEditing(record),
            }),
        };
    });

    return (
        <Col span={24}>
            <Card className="gx-card" title="Add New Broadband Plan">

                <Button onClick={props.navigate} type="primary" className="gx-btn-cyan" style={{ marginBottom: 16 }}>
                    <Icon type="plus" />  Add New Broadband Plan
                </Button>

                <Button onClick={props.handleExportToCSV} type="primary" style={{ marginBottom: 16, marginRight: 0, float: "right" }}>
                    <Icon type="download" /> Export Displayed Results
                </Button>


                <EditableContext.Provider value={props.form}>
                    <Table
                        rowClassName="editable-row"
                        className="gx-table-responsive"
                        pagination={{ pageSize: 10 }}
                        columns={columns}
                        dataSource={dataSource} />
                </EditableContext.Provider>

                <SweetAlert show={showDelete}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={`Are you sure you want delete record ID ${selectedId}`}
                    onConfirm={confirmDelete}
                    onCancel={() => setShowDelete(false)}
                ></SweetAlert>

            </Card>
        </Col>
    )
}

export default BroadbandPlansTable