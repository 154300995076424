import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';

export const savePostcodeDistributorList = postcodeDistributorList => ({
    type: 'SAVE_POSTCODE_DISTRIBUTOR_LIST',
    postcodeDistributorList,
});

export const savePostcodeDataUploadStatus = postcodeDataUploadStatus => ({
    type: 'SAVE_POSTCODE_DATA_UPLOAD_STATUS',
    postcodeDataUploadStatus,
});

export const saveRecordUpdateStatus = recordUpdateStatus => ({
    type: 'SAVE_RECORD_UPDATE_STATUS',
    recordUpdateStatus,
});

export const error = error => ({
    type: 'ERROR',
    error,
});

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const getElecPostcodeDistributorList = (data) => dispath =>{

    if(data !== undefined)
    {
        axios.post(apidetails.API_URL + 'elecPostcodeDistributors/search', qs.stringify(data), config)
        .then(res => {
            dispath(savePostcodeDistributorList(res.data.electricityPostcodeDistributors));
        })
        .catch(err => {
            dispath(error(err.message));
            dispath(error(null));
        });
    }
    else
    {
        axios.get(apidetails.API_URL + 'elecPostcodeDistributors/')
        .then(res => {
            dispath(savePostcodeDistributorList(res.data.electricityPostcodeDistributors));
        })
        .catch(err => {            
            dispath(error(err.message));
            dispath(error(null));
        });
    }    
}

export const updateElecPostcodeDistributorList = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'elecPostcodeDistributors/update', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {        
        dispath(error(err.message));
        dispath(error(null));
    });  
}

export const addElecPostcodeDetails = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'elecPostcodeDistributors/add', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {        
        dispath(error(err.message));
        dispath(error(null));
    });  
}

export const deleteElecPostcodeDetails = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'elecPostcodeDistributors/delete', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.message));
        dispath(error(null));
    });  
}

let csvResults = "";

function setCSVData(results) {
    csvResults = results;
}

export const uploadPostcodeData = (info, file) => dispath =>{

    dispath(error(null));

    dispath(savePostcodeDataUploadStatus("Uploading"));
    Papa.parse(file,{        
        header: true,
        dynamicTyping: true,
        complete: function (results) {
            setCSVData(results)
            info.csvData = JSON.stringify(csvResults.data);
            info.fileName = file.name;

            if(info !== undefined)
            {
                let formData = new FormData();

                for(let key in info)
                {
                    formData.append(key, info[key]);
                }
                
                axios.post(apidetails.API_URL + 'elecPostcodeDistributors/uploadData', qs.stringify(info), config)
                .then(res => {
                    dispath(savePostcodeDataUploadStatus(res.data));
                    dispath(savePostcodeDataUploadStatus("Pending"));
                })
                .catch(err => {
                    console.log(err.message);
                    dispath(error(err.message));
                    dispath(error(null));
                });


            }  
        }
    });
    
}