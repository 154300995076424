import React from "react";
import { connect } from "react-redux";
import { Spin, Button, Card, Col, Form, Icon, Input, Row, Upload, Tooltip, Select, Alert, Divider , DatePicker} from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { uploadPostcodeData } from "appRedux/actions/admin/postcodeMap";
import { getRetailerList } from "appRedux/actions/admin/retailers";

import isEmpty from "util/isEmpty";

let data = [];
const FormItem = Form.Item;
const Dragger = Upload.Dragger;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {
      xs: {span: 24},
      sm: {span: 8},
    },
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 16},
    },
  };

class UploadRetailerPostcodeFile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            expand: false,
            fileList: [],
            showSuccess: false,
            successMessage: "",
            showError: false,
            errorMessage: "",
            uploading: false,
            showUploadPanel: false,
            internalDNCToSearch: {
                dncType: null,
                number: null,
            },
            fields: {
                retailer: {
                    value: '',
                },
                fuelType: {
                    value: '',
                },
                dateReceived: {
                    value: '',
                },
                reference: {
                    value: '',
                },
            },
        };
    }

    handleRetailerChange = (value) => {

        const { fields } = { ...this.state };
        const currentState = fields;

        currentState.retailer.value = value;

        this.setState({ fields: currentState });

        this.showUploadPanel(this.state.fields.retailer.value, this.state.fields.fuelType.value, this.state.fields.dateReceived.value, this.state.fields.reference.value);
    }

    handleFuelTypeChange = (value) => {

        const { fields } = { ...this.state };
        const currentState = fields;

        currentState.fuelType.value = value;

        this.setState({ fields: currentState });

        this.showUploadPanel(this.state.fields.retailer.value, this.state.fields.fuelType.value, this.state.fields.dateReceived.value, this.state.fields.reference.value);
    }

    handleDateReceivedChange = (date, dateString) => {

        const { fields } = { ...this.state };
        const currentState = fields;

        currentState.dateReceived.value = dateString;

        this.setState({ fields: currentState });

        this.showUploadPanel(this.state.fields.retailer.value, this.state.fields.fuelType.value, this.state.fields.dateReceived.value, this.state.fields.reference.value);
    }

    handleReferenceChange = (e) => {

        const { fields } = { ...this.state };
        const currentState = fields;

        currentState.reference.value = e.target.value;

        this.setState({ fields: currentState });

        this.showUploadPanel(this.state.fields.retailer.value, this.state.fields.fuelType.value, this.state.fields.dateReceived.value, this.state.fields.reference.value);
    }

    showUploadPanel = (retailer, fuelType, receivedDate, reference) => {
        if (retailer != -1 && fuelType != -1 && !isEmpty(receivedDate) && !isEmpty(reference)) {
            this.setState({ showUploadPanel: true });
        }
        else {
            this.setState({ showUploadPanel: false });
        }
    }

    handleReset = () => {
        this.props.form.resetFields();
        this.setState({ 
            showUploadPanel: false,
            fields: {
                retailer: {
                    value: '',
                },
                fuelType: {
                    value: '',
                },
                dateReceived: {
                    value: '',
                },
                reference: {
                    value: '',
                },
            }
         });
    };

    uploadFile = (info) => {

        this.setState({
            uploading: true,
        });

        const uploadInfo = {
            retailerID: this.state.fields.retailer.value,
            fuelType: this.state.fields.fuelType.value,
            dateReceived: this.state.fields.dateReceived.value,
            reference: this.state.fields.reference.value,
            csvData: null,
        }

        this.props.uploadPostcodeData(uploadInfo, info.file);
    };

    onConfirm = () => {
        this.setState({ 
            showSuccess: false,
            showError: false,
         });
        this.handleReset();
    };

    componentDidMount() {
        this.props.getRetailerList();
    }

    componentWillReceiveProps(nextProps) {
        if (!isEmpty(nextProps.postcodeDataUploadStatus)) {
            if (nextProps.postcodeDataUploadStatus.status === 'Success') {
                this.setState({
                    successMessage: nextProps.postcodeDataUploadStatus.message,
                    showSuccess: true,
                    uploading: false,
                });
            }
        }

        if (!isEmpty(nextProps.error)) {
            this.setState({
                errorMessage: nextProps.error,
                showError: true,
                uploading: false,
            });
        }

    }

    render() {

        const { getFieldDecorator } = this.props.form;
        const { fileList } = this.state;

        const draggerProps = {
            name: 'file',
            multiple: true,
            onChange: (this.uploadFile),
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            fileList,
        };

        return (
            <div className="gx-main-content">
                <ContainerHeader title="Upload Retailer Postcode File" />
                <Row>
                    
                    <Col span={24}>
                    <Spin spinning={this.state.uploading}>
                        <Card className="gx-card" title="Retailer Postcode File Details">
                            <Form
                                className="ant-advanced-search-form"
                                onSubmit={this.handleSearch}
                            >
                                <Row>
                                    <Col lg={8} md={8} sm={12} xs={24}>
                                        <div className="gx-form-row0">
                                            <FormItem {...formItemLayout} label="Retailer">
                                                {getFieldDecorator('retailer', {
                                                    rules: [{ required: true, message: 'Please select a Retailer' }],
                                                    onChange: (e) => this.handleRetailerChange(e, 'retailer')
                                                })(
                                                    <Select
                                                        showSearch
                                                        style={{ width: 200 }}
                                                        placeholder="Select a Retailer"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    >
                                                        <Option value="-1">Select</Option>
                                                        {!isEmpty(this.props.retailerList) && this.props.retailerList.map((retailer, key) => {
                                                            return <option key={key} value={retailer.id}>{retailer.retailerName}</option>;
                                                        })}
                                                    </Select>
                                                )}
                                            </FormItem>
                                        </div>
                                    </Col>
                                    <Col lg={8} md={8} sm={12} xs={24}>
                                        <div className="gx-form-row0">
                                            <FormItem {...formItemLayout} label="Fuel Type">
                                                {getFieldDecorator('fuelType', {
                                                    rules: [{ required: true, message: 'Please select a Fuel Type' }],
                                                    onChange: (e) => this.handleFuelTypeChange(e, 'fuelType')
                                                })(
                                                    <Select
                                                        showSearch
                                                        style={{ width: 200 }}
                                                        placeholder="Select a Fuel Type"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    >
                                                        <Option value="-1">Select</Option>
                                                        <Option value="ELEC">Electricity</Option>
                                                        <Option value="GAS">Gas</Option>
                                                    </Select>
                                                )}
                                            </FormItem>
                                        </div>
                                    </Col>
                                    <Col lg={8} md={8} sm={8} xs={8}>
                                        <div className="gx-form-row0">
                                            <FormItem
                                                {...formItemLayout}
                                                label={(
                                                    <span>
                                                        Date Received&nbsp;
                                            <Tooltip title="The Date the file was received by retailer">
                                                            <Icon type="question-circle-o" />
                                                        </Tooltip>
                                                    </span>
                                                )}
                                            >
                                                {getFieldDecorator('dateReceived', {
                                                    rules: [{ required: false }],
                                                    onChange: this.handleDateReceivedChange
                                                })(                                                    
                                                    <DatePicker className="gx-mb-3 gx-w-100" format={'DD-MM-YYYY'}/>
                                                )}
                                            </FormItem>
                                        </div>
                                    </Col>
                                    <Col lg={8} md={8} sm={8} xs={8}>
                                        <div className="gx-form-row0">
                                            <FormItem
                                                {...formItemLayout}
                                                label={(
                                                    <span>
                                                        Reference&nbsp;
                                            <Tooltip title="Upload Reference Note">
                                                            <Icon type="question-circle-o" />
                                                        </Tooltip>
                                                    </span>
                                                )}
                                            >
                                                {getFieldDecorator('reference', {
                                                    rules: [{ required: true, message: 'Please enter a reference for the upload!', whitespace: true }],
                                                    onChange: (e) => this.handleReferenceChange(e, 'reference')
                                                })(
                                                    <Input />
                                                )}
                                            </FormItem>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={7}>
                                    </Col>
                                    <Col span={10} className="gx-text-right">
                                        <center>
                                            <Button onClick={this.handleReset}>
                                                Reset
                                    </Button>
                                        </center>
                                    </Col>
                                    <Col span={7}>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                        {this.state.showUploadPanel &&
                            <React.Fragment>
                                <Alert message="Please make sure to upload only .csv files. Click here to download a sample format." type="warning" />
                                <Divider />
                            </React.Fragment>
                        }
                        {this.state.showUploadPanel && <Card className="gx-card" title="Drag and Drop CSV File here">
                                <Dragger {...draggerProps}>
                                <p className="ant-upload-drag-icon">
                                    <Icon type="inbox" />
                                </p>
                                <p className="ant-upload-text">Click or drag csv file to this area to upload</p>
                                <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                            </Dragger>
                        </Card>
                        }
                        
                    </Spin>
                    </Col>
                </Row>
                <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
                <SweetAlert show={this.state.showError} danger title={this.state.errorMessage}
                    onConfirm={this.onConfirm}>
                </SweetAlert>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    postcodeDataUploadStatus: state.postcodemap.postcodeDataUploadStatus,
    error: state.postcodemap.error,
    retailerList: state.retailers.retailerList,
});

const mapDispatchToProps = dispath => ({
    uploadPostcodeData: (info, file) => dispath(uploadPostcodeData(info, file)),
    getRetailerList: (info, file) => dispath(getRetailerList(info, file)),
});

const WrappedAdvancedSearch = Form.create({
    onValuesChange(_, values) {
    },
})(UploadRetailerPostcodeFile);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);
