import React from "react";
import { Route, Switch } from "react-router-dom";
import Plans from "./Plans";
import GasRates from "./GasRates";
import ElecRates from "./ElecRates";
import DMOVDO from "./DMOVDO";
import Tariffs from "./Tariffs";
import Products from "./Products";
import PDFs from "./PDFs";
import Consumptions from "./Consumptions";
import ElecBPIDs from "./ElecBPIDs";
import GasBPIDs from "./GasBPIDs";
import DNCRegister from "./DNCRegister";
import RateToolUpdates from "./RateToolUpdates"
import BroadbandPlans from "./BroadbandPlans/BroadbandPlans";
import BroadbandModemOption from "./BroadbandModemOption/BroadbandModemOption";
import BroadbandFees from "./BroadbandFees/BroadbandFees";
import SignupScripts from "./SignupScripts";
import NewBroadbandFee from "./BroadbandFees/NewBroadbandFees";
import NewBroadbandOption from "./BroadbandModemOption/NewBroadbandOption";
import ManageBroadbandPlans from "./BroadbandPlans/ManageBroadbandPlans";

const Manage = ({ match }) => (
  <Switch >
    <Route path={`${match.url}/plans`} component={Plans} />
    <Route path={`${match.url}/signupScripts`} component={SignupScripts} />
    <Route path={`${match.url}/gasRates`} component={GasRates} />
    <Route path={`${match.url}/elecRates`} component={ElecRates} />
    <Route path={`${match.url}/dmoVdo`} component={DMOVDO} />
    <Route path={`${match.url}/tariffs`} component={Tariffs} />
    <Route path={`${match.url}/products`} component={Products} />
    <Route path={`${match.url}/PDFs`} component={PDFs} />
    <Route path={`${match.url}/consumptions`} component={Consumptions} />
    <Route path={`${match.url}/elecBPIDs`} component={ElecBPIDs} />
    <Route path={`${match.url}/gasBPIDs`} component={GasBPIDs} />
    <Route path={`${match.url}/dncRegister`} component={DNCRegister} />
    <Route path={`${match.url}/rateToolUpdates`} component={RateToolUpdates} />
    <Route path={`${match.url}/broadbandPlans`} component={BroadbandPlans} />
    <Route path={`${match.url}/broadbandModems`} component={BroadbandModemOption} />
    <Route path={`${match.url}/broadbandFees`} component={BroadbandFees} />
    <Route path={`${match.url}/broadbandFee/new`} component={NewBroadbandFee} />
    <Route path={`${match.url}/broadbandFee/:id`} component={NewBroadbandFee} />
    <Route path={`${match.url}/broadbandModems/new`} component={NewBroadbandOption} />
    <Route path={`${match.url}/broadbandModems/:id`} component={NewBroadbandOption} />
    <Route path={`${match.url}/broadbandPlan/new`} component={ManageBroadbandPlans} />
    <Route path={`${match.url}/broadbandPlan/:id`} component={ManageBroadbandPlans} />
  </Switch>
)


export default Manage;
