import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';

export const savePostcodeDistributorList = distributorList => ({
    type: 'SAVE_DISTRIBUTOR_LIST',
    distributorList,
});

export const saveNMIPrefixList = nmiPrefixList => ({
    type: 'SAVE_NMI_PREFIX_LIST',
    nmiPrefixList,
});

export const saveRecordUpdateStatus = recordUpdateStatus => ({
    type: 'SAVE_RECORD_UPDATE_STATUS',
    recordUpdateStatus,
});

export const error = error => ({
    type: 'ERROR',
    error,
});

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const getDistributorList = (data) => dispath =>{

    if(data !== undefined)
    {
        // axios.post(apidetails.API_URL + 'elecPostcodeDistributors/search', qs.stringify(data), config)
        // .then(res => {
        //     dispath(savePostcodeDistributorList(res.data.electricityPostcodeDistributors));
        // })
        // .catch(err => {
        //     dispath(error(err.message || 'ERROR'));
        // });
    }
    else
    {
        axios.get(apidetails.API_URL + 'distributors/')
        .then(res => {
            dispath(savePostcodeDistributorList(res.data.distributors));
        })
        .catch(err => {
            dispath(error(err.message || 'ERROR'));
        });
    }    
}

export const getNMIPrefixList = (data) => dispath =>{

    if(data !== undefined)
    {
        // axios.post(apidetails.API_URL + 'elecPostcodeDistributors/search', qs.stringify(data), config)
        // .then(res => {
        //     dispath(savePostcodeDistributorList(res.data.electricityPostcodeDistributors));
        // })
        // .catch(err => {
        //     dispath(error(err.message || 'ERROR'));
        // });
    }
    else
    {
        axios.get(apidetails.API_URL + 'distributors/nmiPrefix')
        .then(res => {
            dispath(saveNMIPrefixList(res.data.nmiPrefixList));
        })
        .catch(err => {
            dispath(error(err.message || 'ERROR'));
        });
    }    
}

export const updateDistributor = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'distributors/update', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.message || 'ERROR'));
    });  
}

export const addDistributor = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'distributors/create', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.message || 'ERROR'));
    });  
}

export const deleteDistributor = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'distributors/delete', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.message || 'ERROR'));
    });  
}