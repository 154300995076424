import React from "react";
import { connect } from "react-redux";
import { Spin, Card, Table, Col, Form, Tag, Input, Row, Button, Select,InputNumber, Popconfirm, Switch,} from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import ContainerHeader from "components/ContainerHeader/index";

import { addElecPostcodeDetails, deleteElecPostcodeDetails } from "appRedux/actions/admin/postcodeMap";
import { getUsers, updateUserAccessLevel } from "appRedux/actions/admin/users";
import { getAccessLevels } from "appRedux/actions/admin/accessLevels";

import isEmpty from "util/isEmpty";
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const FormItem = Form.Item;
const Option = Select.Option;
const moment = extendMoment(Moment);


//const title = () => 'Here is title';
const showHeader = true;
//const footer = () => 'Here is footer';
//const scroll = {y: 500};
const pagination = { position: 'bottom' };

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 16},
  },
};

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  

  getInput = () => {
    const {
      dataIndex,
      record,
    } = this.props;

    function onChange(checked) {
    }

    if(this.props.inputType === 'accessLevelDropDown') {
      return  <Select
                showSearch
                style={{ width: '100%', minWidth: 300 }}
                placeholder="Select Access Level"
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option value="-1">Select</Option>
                {!isEmpty(this.props.accessLevelList) && this.props.accessLevelList.map((accessLevel, key) => {
                    return <option key={key} value={accessLevel.id}>{accessLevel.id} - {accessLevel.name}</option>;
                })}
              </Select>;
    }
    else if (this.props.inputType === 'statusSelection') {

      return <Switch disabled={false} defaultChecked={record[dataIndex] == 1 ? true : false} onChange={onChange} />;

    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    function getInitialValue(dataIndex) {
      if (dataIndex == "isActive") {
        return record[dataIndex] == 1 ? true : false;
      }
      else {
        return record[dataIndex];
      }
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 , width: '100%', display:'block',}}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: getInitialValue(dataIndex),
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class ManageUsers extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editingKey: '',
      isAdding : false,
      isSearching : false,
      recordsEdited: false,
      expand: false,
      dataSource: [],
      addingNewRecord : false,
      postcodeRecordIdToDelete: null,
      postcodeToDelete: null,
      showAddNewPostcodePanel: false,
      showWarning: false,
      warningMessage: "",
      showSuccess: false,
      successMessage: "",
      internalDNCToSearch: {
        dncType: null,
        number: null,
      },
      bordered: true,
      loading: false,
      pagination,
      size: 'middle',
      title: undefined,
      showHeader,
      footer: undefined,
      rowSelection: {},
    };
    this.columns = [
      
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        width: 100,
        render: (text, record) =>{
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <center>
                <EditableContext.Consumer>
                  {form => (
                    <a
                      onClick={() => this.save(form, record.key)}
                      
                    >
                      <i className="icon icon-check-circle-o"/>
                    </a>
                  )}
                </EditableContext.Consumer>
                <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                  <a style={{ marginLeft: 10 }}><i className="icon icon-close-circle"/></a>
                </Popconfirm>
              </center>
            </span>
          ) : (
            <span>
              <center>
                <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
                  <i className="icon icon-edit"/>
                </a>
                {/* <a style={{ marginLeft: 10 }} disabled={editingKey !== ''} onClick={() => this.confirmDelete(record.id, record.postcode)}>
                  <i className="icon icon-trash"/>
                </a> */}
              </center>
            </span>
          );
        },
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 80,
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: 100,
        sorter: (a, b) => {return a.name.localeCompare(b.name)},
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        align: 'center',
        width: 100,
        sorter: (a, b) => {return a.email.localeCompare(b.email)},
      },
      {
        title: 'Access Level',
        dataIndex: 'accessLevel',
        key: 'accessLevel',
        align: 'center',
        width: 150,  
        editable: true,
        sorter: (a, b) => a.accessLevel - b.accessLevel,
        render: (text, record) => {

          return <Select
            disabled
              showSearch
              style={{ width: '100%', minWidth: 300 }}
              placeholder="Select Access Level"
              defaultValue = {text}
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Option value="-1">Select</Option>
              {!isEmpty(this.props.accessLevelList) && this.props.accessLevelList.map((accessLevel, key) => {
                  return <option key={key} value={accessLevel.id}>{accessLevel.id} - {accessLevel.name}</option>;
              })}
            </Select>
        },
      },
      {
        title: 'is Active',
        dataIndex: 'isActive',
        key: 'isActive',
        align: 'center',
        width: 150,  
        editable: true,
        sorter: (a, b) => a.isActive - b.isActive,
        render: (text, record) => {
          return <Switch disabled={true} checked={text == 1 ? true : false} onChange={{}} />
        }
      },
      {
        title: 'Last Login',
        dataIndex: 'lastlogin',
        key: 'lastlogin',
        align: 'center',
        width: 150,  
        sorter: (a, b) => a.lastlogin - b.lastlogin,
        render: (text, record) => {
          return <Tag color={"green"} key={text}>
                  {text.toUpperCase()}
                </Tag>
        }
      },
      {
        title: 'Created Date',
        dataIndex: 'createdDate',
        key: 'createdDate',
        align: 'center',
        width: 150,
        sorter: (a, b) => a.createdDate - b.createdDate,
        render: (text, record) => {
          return <Tag color={"geekblue"} key={text}>
                  {text.toUpperCase()}
                </Tag>
        }
      },
      {
        title: 'Updated Date',
        dataIndex: 'updatedDate',
        key: 'updatedDate',
        align: 'center',
        width: 150,
        sorter: (a, b) => a.updatedDate - b.updatedDate,
        render: (text, record) => {
          return <Tag color={"volcano"} key={text}>
                  {text.toUpperCase()}
                </Tag>
        }
      },
    ];

  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };
  
  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.dataSource];
      const index = newData.findIndex(item => key === item.key);   

      if (index > -1) {
        const item = newData[index];

        const valuesToUpdate = {
          id : item.id,
          accessLevel : row.accessLevel,
          isActive : row.isActive ? 1 : 0,
        }
  
        this.setState({ loading: true });
        this.props.updateUserAccessLevel(valuesToUpdate); 

        this.setState({ 
          editingKey: '',
          recordsEdited:true });
      } 
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  confirmDelete = (key, postcode) => {
    this.setState({ postcodeRecordIdToDelete: key});
    this.setState({ postcodeToDelete: postcode});
    this.setState({ showWarning: true});

  };

  onCancelDelete = key => {
    this.setState({ postcodeRecordIdToDelete: null});
    this.setState({ postcodeToDelete: null});
    this.setState({ showWarning: false});
  };

  handleDelete = () => {
    const deleteInfo = {
      id: this.state.postcodeRecordIdToDelete,
      postcode: this.state.postcodeToDelete,
    }
    this.props.deleteElecPostcodeDetails(deleteInfo);
    this.setState({ showWarning: false});
  };

  onConfirm = () => {
    this.setState({ 
      showSuccess: false,
      showAddNewPostcodePanel : false,
    });
    this.handleReset();
  };

  componentDidMount() {
    this.props.getUsers();
    this.props.getAccessLevels();
  }

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      const filtersForSearch = {
        email : values.txtEmail,
        name : values.txtName,
        accessLevel: values.drpAccessLevel,
      }
      this.setState({ isSearching: true });
      this.props.getUsers(filtersForSearch);
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
    this.props.getUsers();
    this.props.getAccessLevels();
  };

  checkColoumnType = (field) => {
    switch(field)
    {
      case 'isActive': return "statusSelection";
      case 'accessLevel' : return "accessLevelDropDown";
      default : return "text";
    }
  }

  componentWillReceiveProps(nextProps) {
    if(!isEmpty(nextProps.userList) && !isEmpty(nextProps.accessLevelList))
    {
      this.setState({ loading: true });
      this.state.dataSource = [];
      let count = 1;      
      nextProps.userList.map((user, key) => {
        let record = {
          key: count.toString(),
          id: user.id,
          createdDate: moment(user.createdDate).format('YYYY-MM-DD h:mm:ss A'),
          updatedDate: moment(user.updatedDate).format('YYYY-MM-DD h:mm:ss A'),
          name: !isEmpty(user.name) ? user.name : "-",
          email: user.email,
          //accessLevel: !isEmpty(user.accessLevel) ? nextProps.accessLevelList.find(data => data.id ==user.accessLevel).name + " (" + nextProps.accessLevelList.find(data => data.id ==user.accessLevel).id + ")"  : "-",
          accessLevel: !isEmpty(user.accessLevel) ? user.accessLevel  : -1,
          lastlogin: !isEmpty(user.lastLogin) ? moment(user.lastLogin).format('YYYY-MM-DD h:mm:ss A') : "-",
          isActive: user.isActive,
        };
        this.state.dataSource.push(record);
        count++; 
      });
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }
    else
    {
      this.setState({ loading: true });
      this.state.dataSource = [];
      this.setState({ loading: false });
      this.setState({ isSearching: false });
    }

    if(!isEmpty(nextProps.recordUpdateStatus))
    {
      if(nextProps.recordUpdateStatus.status == "Success")
      {
        this.setState({
            successMessage: nextProps.recordUpdateStatus.message,
            showSuccess: true,
        });
        this.props.getUsers();
        this.setState({ isAdding: false });
      }
    }
  }


  render() {

    let { dataSource } = this.state;

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          accessLevelList: this.props.accessLevelList,
          inputType: this.checkColoumnType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    const { getFieldDecorator } = this.props.form;

    return (
      <div className="gx-main-content">
        <ContainerHeader title="Manage Users"  />
        <Row>
          <Col span={24}>
          <Spin spinning={this.state.isSearching}>
            <Card  className="gx-card" title="Search User">
              <Form
                className="ant-advanced-search-form"
                onSubmit={this.handleSearch}
              >
                <Row>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Access Level">
                        {getFieldDecorator('drpAccessLevel', {
                          rules: [{ required: false, message: 'Please select an Access Level!' }],
                        })(
                          <Select
                            
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select an Access Level"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          >
                            <Option value="-1">Select</Option>
                            {!isEmpty(this.props.accessLevelList) && this.props.accessLevelList.map((accessLevel, key) => {
                                return <option key={key} value={accessLevel.id}>{accessLevel.id} - {accessLevel.name}</option>;
                            })}
                          </Select>
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Name" >
                        {getFieldDecorator('txtName', {
                          rules: [{ required: false, message: 'Enter a Name!' }],
                        })(
                          <Input 
                            
                            style={{ width: '100%' }} placeholder="Enter a Name" />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={24}>
                    <div className="gx-form-row0">
                      <FormItem {...formItemLayout} label="Email" >
                        {getFieldDecorator('txtEmail', {
                          rules: [{ required: false, message: 'Enter an Email!' }],
                        })(
                          <Input 
                          
                          style={{ width: '100%' }} placeholder="Enter an Email" />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col span={24} className="gx-text-right">
                    <center>
                      <Button type="primary" htmlType="submit">Search</Button>
                      <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                        Clear
                    </Button>
                    </center>
                  </Col>
                </Row>
              </Form>
            </Card>
            </Spin>
          </Col>
          <Col span={24}>
            <Card className="gx-card" title="User Details">
              <EditableContext.Provider value={this.props.form}>
                <Table 
                components={components}
                rowClassName="editable-row"
                className="gx-table-responsive" 
                {...this.state} 
                columns={columns} 
                dataSource={dataSource} />
              </EditableContext.Provider>
            </Card>
          </Col>
        </Row>
        <SweetAlert show={this.state.showSuccess} success title={this.state.successMessage}
          onConfirm={this.onConfirm}>
        </SweetAlert>
        <SweetAlert show={this.state.showWarning}
                    warning
                    showCancel
                    confirmBtnText="Yes Delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Are you sure?"
                    onConfirm={this.handleDelete}
                    onCancel={this.onCancelDelete}
        ></SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userList: state.users.userList,
  accessLevelList: state.accesslevels.accessLevelList,
  recordUpdateStatus: state.users.recordUpdateStatus,
});

const mapDispatchToProps = dispath => ({
  addElecPostcodeDetails: (data) => dispath(addElecPostcodeDetails(data)),
  deleteElecPostcodeDetails: (data) => dispath(deleteElecPostcodeDetails(data)),

  getUsers: (data) => dispath(getUsers(data)),
  getAccessLevels: (data) => dispath(getAccessLevels(data)),
  updateUserAccessLevel: (data) => dispath(updateUserAccessLevel(data)),
});

const WrappedAdvancedSearch = Form.create()(ManageUsers);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedAdvancedSearch);