export const stringToCapitalCase = (string) => {

    if (string) {

        const capitalCaseString = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

        return capitalCaseString

    } else {
        return ""
    }

}