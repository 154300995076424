import React, { useEffect } from 'react'
import { Card, Col, Row, Button, Select, Radio, Form, Checkbox, DatePicker } from "antd";

import styles from './styles.module.less'
import moment from 'moment';
import { useState } from 'react';


const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};


const BroadbandQuestions = (props) => {

    const { onComplete, address, goPrevious, apiLoading, data } = props
    const { getFieldDecorator, getFieldValue } = props.form

    const transferOrMove = getFieldValue('Move-inorTransfer');

    console.log(transferOrMove);

    const handleSubmit = (e) => {

        e.preventDefault();

        props.form.validateFields((err, values) => {

            if (!err) {

                const modifiedData = {
                    "broadbandDetails": {
                        "internetUsers": null, // 1
                        "importantFeature": null, //"speed"
                        "interested": {
                            "tv": false,
                            "browsing": false,
                            "work": false,
                            "socialMedia": false,
                            "gaming": false,
                            "study": false
                        },
                        "broadbandUsageLevel": null, //"Medium"
                        "addressDetails": {
                            // address details from address search
                        }
                    }
                }

                const leadDetailsObj = {
                    'Move-inorTransfer': { _text: '' },
                    'Move-inDate': { _text: '' },
                }


                for (const key in values) {


                    if (key === 'interested') {

                        const interestedItems = values[key]

                        for (let i = 0; i < interestedItems.length; i++) {

                            modifiedData.broadbandDetails[key] = {
                                ...modifiedData.broadbandDetails[key],
                                [interestedItems[i]]: interestedItems[i] ? true : false
                            }
                        }

                    } else if (key === 'Move-inorTransfer' || key === 'Move-inDate') {

                        if (values[key]) {

                            if (key === 'Move-inDate') {

                                const moveInDate = moment(values[key]).format('YYYY-MM-DD')

                                leadDetailsObj[key] = { _text: moveInDate }

                            } else {

                                leadDetailsObj[key] = { _text: values[key] }

                            }

                        }


                    } else {

                        modifiedData.broadbandDetails = {
                            ...modifiedData.broadbandDetails,
                            [key]: values[key]
                        }


                    }

                }

                modifiedData.broadbandDetails = {
                    ...modifiedData.broadbandDetails,
                    addressDetails: {
                        ...address
                    }
                }

                console.log(leadDetailsObj);

                onComplete({ ...modifiedData }, 'comparisonDetails', 'done', leadDetailsObj)
            }
        })
    }

    const [internetUsers, setInternetUsers] = useState('')

    const [broadbandUsageLevel, setBroadbandUsageLevel] = useState('')

    const [importantFeature, setImportantFeature] = useState('')

    const [moveInorTransfer, setMoveInorTransfer] = useState('')

    const [moveInDate, setMoveInDate] = useState('')


    useEffect(() => {

        if (data.broadbandDetails && Object.keys(data.broadbandDetails).length > 0) {

            if (data.broadbandDetails.broadbandUsageLevel) {

                setBroadbandUsageLevel(data.broadbandDetails.broadbandUsageLevel)
            }

            if (data.broadbandDetails.internetUsers) {

                setInternetUsers(data.broadbandDetails.internetUsers)
            }

            if (data.broadbandDetails.importantFeature) {

                setImportantFeature(data.broadbandDetails.importantFeature)

            }
            if (data.broadbandDetails['Move-inorTransfer']) {

                setMoveInorTransfer(data.broadbandDetails['Move-inorTransfer'])

            }
            if (data.broadbandDetails['Move-inDate']) {

                setMoveInDate(data.broadbandDetails['Move-inDate'])
            }
        } else {

            if (data.leadDetails.InternetUsage && data.leadDetails.InternetUsage._text) {

                setBroadbandUsageLevel(data.leadDetails.InternetUsage._text)
            }

            if (data.leadDetails.HouseholdSize && data.leadDetails.HouseholdSize._text) {

                setInternetUsers(parseInt(data.leadDetails.HouseholdSize._text))
            }

            if (data.leadDetails.ImportantFeature && data.leadDetails.ImportantFeature._text) {

                setImportantFeature(data.leadDetails.ImportantFeature._text)

            }
            if (data.leadDetails['Move-inorTransfer'] && data.leadDetails['Move-inorTransfer']._text) {

                setMoveInorTransfer(data.leadDetails['Move-inorTransfer']._text)

            }
            if (data.leadDetails['Move-InDate'] && data.leadDetails['Move-InDate']._text && data.leadDetails['Move-inorTransfer']._text != "Transfer") {

                setMoveInDate(moment(data.leadDetails['Move-InDate']._text, "D/M/YYYY"))
            }

        }


    }, [data])



    return (
        <Col span={24}>

            <Card className="gx-card" title={`Broadband Preferences`} style={{ maxHeight: "640px", overflow: "hidden" }}>

                <Form
                    name="basic"
                    labelCol={{
                        xs: { span: 24 },
                        sm: { span: 4 },
                    }}
                    wrapperCol={{
                        xs: { span: 24 },
                        sm: { span: 20 },
                    }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >

                    <div className={styles.customContainer}>

                        <Row>

                            <Col sm={12} xs={24}>

                                <Form.Item  {...formItemLayout2} name="internetUsers" label=" How many people use the internet at your home?">

                                    {getFieldDecorator('internetUsers', {
                                        initialValue: internetUsers,
                                        rules: [{ required: true, message: 'Select amount of Users!' }],
                                    })(
                                        <Radio.Group size="large" optionType="button" buttonStyle="solid">
                                            <Radio.Button value={1}>1</Radio.Button>
                                            <Radio.Button value={2}>2</Radio.Button>
                                            <Radio.Button value={3}>3</Radio.Button>
                                            <Radio.Button value={4}>4+</Radio.Button>
                                        </Radio.Group>

                                    )}

                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item  {...formItemLayout2} name="broadbandUsageLevel" label="On a daily basis what is your level of internet usage?" >

                                    {getFieldDecorator('broadbandUsageLevel', {
                                        initialValue: broadbandUsageLevel,
                                        rules: [{ required: true, message: 'Select Usage Level!' }],
                                    })(
                                        <Radio.Group size="large" optionType="button" buttonStyle="solid">
                                            <Radio.Button value="Low">Low</Radio.Button>
                                            <Radio.Button value="Medium">Medium</Radio.Button>
                                            <Radio.Button value="High">High</Radio.Button>
                                        </Radio.Group>
                                    )}

                                </Form.Item>

                            </Col>


                            <Col xs={24}>

                                <Form.Item  {...formItemLayout2} name="importantFeature" label=" What is the most important feature to you when it comes to the internet?" >

                                    {getFieldDecorator('importantFeature', {
                                        initialValue: importantFeature,
                                        rules: [{ required: true, message: 'Select Important Feature!' }],
                                    })(

                                        <Radio.Group size="large" optionType="button" buttonStyle="solid">
                                            <Radio.Button value="speed">Speed</Radio.Button>
                                            <Radio.Button value="price">Price</Radio.Button>
                                        </Radio.Group>
                                    )}

                                </Form.Item>

                            </Col>

                            <Col sm={12} xs={24}>

                                <Form.Item  {...formItemLayout2} name="Move-inorTransfer" label="Are you moving into a new house?">

                                    {getFieldDecorator('Move-inorTransfer', {
                                        initialValue: moveInorTransfer,
                                        rules: [{ required: true, message: 'Please Select Move-in or Transfer' }],
                                    })(
                                        <Radio.Group size="large" optionType="button" buttonStyle="solid">
                                            <Radio.Button value="MoveIn">Yes</Radio.Button>
                                            <Radio.Button value="Transfer">No</Radio.Button>
                                        </Radio.Group>
                                    )}

                                </Form.Item>

                            </Col>

                            {
                                (transferOrMove && transferOrMove === 'MoveIn' || moveInorTransfer === 'MoveIn') && <Col sm={12} xs={24}>

                                    <Form.Item  {...formItemLayout2} name="Move-inDate" label="Move-in Date" rules={[{ required: true }]}>

                                        {getFieldDecorator('Move-inDate', {
                                            initialValue: moveInDate,
                                            rules: [{ required: true, message: 'Please Select Move-in Date!' }],
                                        })(
                                            <DatePicker style={{ width: '100%' }} />
                                        )}

                                    </Form.Item>

                                </Col>
                            }


                            <Col span={24} className="gx-text-right">

                                <center>

                                    <div className='contentCenter'>

                                        <Button onClick={goPrevious}>
                                            Previous
                                        </Button>

                                        <Button type="primary" htmlType="submit" loading={apiLoading} disabled={apiLoading}>
                                            {apiLoading ? 'Fetching Products...' : "Continue"}
                                        </Button>

                                    </div>
                                </center>
                            </Col>

                        </Row>

                    </div>

                </Form>

            </Card>

        </Col>
    )
}

const WrappedBroadbandQuestions = Form.create()(BroadbandQuestions);

export default WrappedBroadbandQuestions