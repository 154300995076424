import React, { useEffect, useState } from 'react'
import { Modal, Col, Row, Button, Tag, Alert, Radio, Divider, Tabs, Collapse } from "antd";
import ModemOptionCard from './ModemOptionCard';

import styles from './BroadbandResult.module.less';

const ModemOptions = ({ data, setPackageUrl, techType }) => {

    const [select, setSelected] = useState(null)

    const [modemOptionList, setModemOptionList] = useState([])

    useEffect(() => {

        if (data.broadbandData && data.broadbandData.retailerModems) {

            setModemOptionList(data.broadbandData.retailerModems)

        }
    }, [])

    const handleSelect = (id, url, modem) => {

        setPackageUrl(url, modem)

        setSelected(id)
    }


    return (
        <div className={styles.optionCardContainer}>

            {
                modemOptionList && modemOptionList.length > 0 && modemOptionList.map((modem, index) => (

                    ((modem.technology_type && modem.technology_type.includes(techType)) || !modem.technology_type) && <ModemOptionCard
                        id={`modemOption-${index}`}
                        title={`${modem.modem_name}${modem.modem_delivery_fee !== '0.00' && modem.modem_cost !== '0' ? `<sup>1</sup>` : ''}`}
                        description={modem.modem_technology_support ? modem.modem_technology_support : ''}
                        tooltip={modem.modem_technology_support_helper_notes}
                        price={modem.modem_cost}
                        deliveryCharge={modem.modem_delivery_fee}
                        image={modem.modem_image}
                        key={index}
                        selected={select}
                        handleSelect={handleSelect}
                        url={(modem.modem_more_info_retailer_url && modem.modem_more_info_retailer_url != "N/A") ? modem.modem_more_info_retailer_url : ''}
                        data={modem}
                    />

                ))
            }

        </div>
    )
}

export default ModemOptions