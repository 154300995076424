import React, { Component } from 'react'
import FilterResultDrawer from './FilterResult/FilterResultDrawer';
import Header from './Header';
import ResultCard from './ResultCard';
import { connect } from "react-redux";
import DataLoader from '../../../components/DataLoader/DataLoader';
import AlertMessage from '../../../components/AlertMessage/AlertMessage';
import { getMatchingProducts, getProductDetails, clearProducts } from '../../../appRedux/actions/sales/products';
import { getSavedLeadDetails, updateLeadDetails, clearAll } from '../../../appRedux/actions/sales/leads';
import { resetAddress } from '../../../appRedux/actions/tools/address';
import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, notification, Radio, Row, Select, TimePicker, Alert } from 'antd';
import FiltersHeader from './FiltersHeader';
import Sticky from 'react-sticky-el'
import styles from './BroadbandResult.module.less'
import swal from 'sweetalert2';

import isEmpty from "util/isEmpty";

import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);


const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Singup ID Not found',
        description:
            'Please Enter signup Id and Try again!',
    });
};

const FormItem = Form.Item;
const { Option } = Select;

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};



class BroadbandResult extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showFilters: false,
            uID: null,
            products: null,
            leadDetails: null,
            showSignUp: false,
            loadingSingUpApi: false,
            signupId: null,
            selectedPlan: {},
            retailerList:[]
        }
        this.submitButton = React.createRef();
    }

    componentWillReceiveProps(nextProps) {


        if (nextProps.products) {

            this.setState({ products: nextProps.products })
            this.getRetailerListFromProducts(nextProps.products)

        }

        if (nextProps.leadDetails) {

            this.setState({ leadDetails: nextProps.leadDetails })

        }

        if (nextProps.products && nextProps.products.status === 'success') {

            const path = window.location.pathname
            const pathToArray = path.split('/')
            const uuid = pathToArray[pathToArray.length - 1]
            if (nextProps.products.productsDetails && uuid !== nextProps.products.productsDetails.uuid) {


                this.props.history.push(`/tools/broadband/result/${nextProps.products.productsDetails.uuid}`)
                this.getRetailerListFromProducts(nextProps.products)

            }

        }
        if (nextProps.products && nextProps.products.status === 'failed') {

            this.props.history.push(`/tools/broadbandComparison`);

        }

    }

    getRetailerListFromProducts = (products) => {

        let tempRetailerList = []

        if(!isEmpty(products.productsDetails)){
            products.productsDetails.result && products.productsDetails.result.map((plan, index) => {

                if(!tempRetailerList.includes(plan.retailerDetails.systemName)){
                    tempRetailerList.push(plan.retailerDetails.systemName)
                }
    
            })
        }

        this.setState({ retailerList: tempRetailerList })
        
    }

    componentDidMount() {
        this.props.getMatchingProducts();
        this.props.getSavedLeadDetails();
    }

    handleFilters = (filters) => {
        this.props.getProductDetails(filters);
    }

    tryNewLead = () => {
        this.props.clearProducts()

        this.props.clearLeads()

        this.props.resetAddress()

        this.props.history.push(`/tools/broadbandComparison`);

    }

    handleSingUp = (e) => {

        e.preventDefault();

        this.props.form.validateFields((err, values) => {

            if (!err) {
                console.log(values);

                const selectedPlan = this.state.selectedPlan
                const tempLeadDetails = { ...this.state.leadDetails }
                const userDetails = JSON.parse(localStorage.getItem('user_details'))
                const userValue = JSON.parse(userDetails.value)
                const name = userValue.name
                const date = new Date();
                const currentDateAndTime = moment().format("DD/MM/YYYY hh:mm A");
                tempLeadDetails.leadDetails = {
                    ...tempLeadDetails.leadDetails,
                    NewBroadbandRetailer: { _text: selectedPlan.retailerDetails.retailerName },
                    NewBroadbandPlan: { _text: selectedPlan.planDetails.product_name },
                    Telcosignuppromo: { _text: selectedPlan.planDetails.benefit_description },
                    BroadbandRefNumber: { _text: values.BroadbandRefNumber },
                    Cbacustomer: { _text: values.Cbacustomer },
                    MobileSold: { _text: values.MobileSold },
                    SalesRepName: { _text: name ? name : 'Guest' },
                    SaleDateTime: { _text: currentDateAndTime },
                    Status: { _text: "(Ops Status) Retailer CAF Sent" },
                    // DateReferedToBroadbandCompare: { _text: currentDate },
                    BroadbandSold: { _text: "Yes" }
                }

                if (values.MobileSold === 'Yes') {

                    tempLeadDetails.leadDetails = {
                        ...tempLeadDetails.leadDetails,
                        MobileSIMRetailer: { _text: values.MobileSIMRetailer },
                        NumberOfSIMSold: { _text: values.NumberOfSIMSold }
                    }
                } else {
                    tempLeadDetails.leadDetails = {
                        ...tempLeadDetails.leadDetails,
                        MobileSIMRetailer: { _text: "N/A" },
                        NumberOfSIMSold: { _text: "0" }
                    }
                }

                const leadDetailsFormObject = {
                    leadDetails: JSON.stringify(tempLeadDetails.leadDetails),
                    //comparisonDetails: JSON.stringify(tempLeadDetails.comparisonDetails),
                }

                this.props.updateLeadDetails(leadDetailsFormObject);

                this.setState({ loadingSingUpApi: true });

                setTimeout(() => {

                    this.setState({
                        loadingSingUpApi: false,
                        showSignUp: false
                    })
                    swal.fire({
                        icon: 'success',
                        title: "Record Updated",
                        text: `Signup Details saved Successfully`,
                        showConfirmButton: false,
                        timer: 3000
                    }).then((result) => {
                        this.tryNewLead();
                    });

                }, 2000);

            }
        })


    }

    showSingUpModel = () => {

        this.setState({ showSignUp: true })
    }

    handleSingUpModelClose = () => {

        this.setState({ showSignUp: false })
    }

    onChange = (e) => {

        const { value } = e.target;

        this.setState({ signupId: value })
    }

    setSelectedPlan = (plan) => {

        this.setState({ selectedPlan: plan })

    }

    clickOnTheSubmitButton = () => {

        console.log(this.submitButton.current);
        if (this.submitButton.current && this.submitButton.current.submit()) {
            this.submitButton.current.submit()
        }
    }

    render() {

        const { showFilters, products } = this.state

        const { getFieldDecorator, getFieldValue } = this.props.form

        const MobileSold = getFieldValue('MobileSold');

        return (
            <>
                {
                    products && products.status === 'loading' && <DataLoader />
                }

                {
                    products && products.status === 'failed' && <AlertMessage
                        message={"Error!"}
                        variant="error"
                        showIcon={true}
                        description={'Something Went Wrong'}
                    />
                }

                {
                    products && products.status === 'success' && <div className="gx-main-content">

                        {/* <Sticky className={styles.stickyHeader} topOffset={80}> */}

                        <div className={styles.stickyHeader}>

                            <Header handleFilters={() => this.setState({ showFilters: !showFilters })} data={products} {...this.props} leadDetails={this.state.leadDetails} />

                            <hr />

                            <FiltersHeader leadDetails={this.state.leadDetails} />

                        </div>



                        {/* </Sticky> */}

                        <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>

                            {
                                products.productsDetails && products.productsDetails.planCount > 0 ? <>

                                    {
                                        products.productsDetails.result && products.productsDetails.result.map((plan, index) => (
                                            <>

                                                {(index === 0 && products.productsDetails.recommendedBroadbandPlansCount > 0) ? <Alert style={{ marginBottom: "50px" }} message="Recommended Plans" type="success" /> : ``}

                                                <ResultCard
                                                    key={index}
                                                    data={plan}
                                                    index={index}
                                                    showSingUpModel={this.showSingUpModel}
                                                    recommendedPlanCount={products.productsDetails.recommendedBroadbandPlansCount ? products.productsDetails.recommendedBroadbandPlansCount : 0}
                                                    setSelectedPlan={this.setSelectedPlan}
                                                    techType={this.state.leadDetails.comparisonDetails.broadbandDetails.addressDetails.data.nbn.servingArea.techType}
                                                />

                                                {(index === products.productsDetails.recommendedBroadbandPlansCount - 1) ? <Alert style={{ marginBottom: "20px" }} message="All Other Plans" type="warning" /> : ``}
                                            </>
                                        ))
                                    }
                                </> : <AlertMessage
                                    message={"Warning!"}
                                    variant="warning"
                                    showIcon={true}
                                    description={'No Matching Record Found'}
                                />


                            }


                        </div>


                        <FilterResultDrawer
                            leadDetails={this.state.leadDetails}
                            visible={showFilters}
                            onClose={() => this.setState({ showFilters: false })}
                            width={720}
                            title={"Filter Results"}
                            onSubmit={this.handleFilters}
                            retailerList={this.state.retailerList}
                        />

                    </div>
                }

                <Modal
                    title="Signup Details"
                    visible={this.state.showSignUp}
                    centered
                    footer={[
                        <Button
                            key="back"
                            onClick={this.handleSingUpModelClose}
                        >
                            Return
                        </Button>,
                        <Button
                            htmlType="submit"
                            loading={this.state.loadingSingUpApi}
                            onClick={this.handleSingUp}
                        >
                            Submit
                        </Button>,
                    ]}
                >
                    <Row>
                        <Col sm={24}>
                            <Form
                                name="signupForm"
                                labelCol={{
                                    xs: { span: 24 },
                                    sm: { span: 4 },
                                }}
                                wrapperCol={{
                                    xs: { span: 24 },
                                    sm: { span: 20 },
                                }}
                                // initialValues={{ remember: true }}
                                autoComplete="off"
                                onSubmit={this.handleSubmit}
                                ref={this.submitButton}
                            >

                                <Row>
                                    {/* <Col sm={24} xs={24}>

                                        <Form.Item  {...formItemLayout2} name="signupID" label="Sign up ID">

                                            {getFieldDecorator('signupID', {
                                                initialValue: '',
                                                rules: [{ required: true, message: 'Enter Sign up ID' }],
                                            })(
                                                <Input placeholder="EX:XXXXXX" />
                                            )}

                                        </Form.Item>

                                    </Col> */}

                                    <Col sm={24} xs={24}>

                                        <Form.Item  {...formItemLayout2} name="BroadbandRefNumber" label="Signup Reference Number">

                                            {getFieldDecorator('BroadbandRefNumber', {
                                                initialValue: '',
                                                rules: [{ required: true, message: 'Enter Signup Reference Number' }],
                                            })(
                                                <Input placeholder="EX:XXXXXX" />
                                            )}

                                        </Form.Item>

                                    </Col>

                                    <Col sm={12} xs={24}>

                                        <Form.Item  {...formItemLayout2} name="importantFeature" label="CBA Customer" >

                                            {getFieldDecorator('Cbacustomer', {
                                                initialValue: '',
                                                rules: [{ required: true }],
                                            })(

                                                <Radio.Group size="large" optionType="button" buttonStyle="solid">
                                                    <Radio.Button value="Yes">Yes</Radio.Button>
                                                    <Radio.Button value="No">No</Radio.Button>
                                                </Radio.Group>
                                            )}

                                        </Form.Item>

                                    </Col>

                                    <Col sm={12} xs={24}>

                                        <Form.Item  {...formItemLayout2} name="MobileSold" label="Mobile SIM Sold" >

                                            {getFieldDecorator('MobileSold', {
                                                initialValue: '',
                                                rules: [{ required: true }],
                                            })(

                                                <Radio.Group size="large" optionType="button" buttonStyle="solid">
                                                    <Radio.Button value="Yes">Yes</Radio.Button>
                                                    <Radio.Button value="No">No</Radio.Button>
                                                </Radio.Group>
                                            )}

                                        </Form.Item>

                                    </Col>

                                    {

                                        MobileSold === 'Yes' && (
                                            <>
                                                <Col xs={24}>

                                                    <Form.Item  {...formItemLayout2} name="MobileSIMRetailer" label="Mobile SIM Retailer" rules={[{ required: true }]}>

                                                        {getFieldDecorator('MobileSIMRetailer', {
                                                            initialValue: 'Tangerine',
                                                            rules: [{ required: true, message: 'Please Select Mobile SIM Retailer!' }],
                                                        })(
                                                            <Select placeholder="Select Mobile" >

                                                                <Option value="Tangerine">Tangerine</Option>

                                                                <Option value="More Telecom">More Telecom</Option>

                                                                <Option value="Aussie Broadband">Aussie Broadband</Option>

                                                                <Option value="Dodo">Dodo</Option>

                                                                <Option value="AGL Telco">AGL Telco</Option>

                                                                <Option value="Origin Telco">Origin Telco</Option>

                                                            </Select>
                                                        )}

                                                    </Form.Item>

                                                </Col>

                                                <Col sm={24} xs={24}>

                                                    <Form.Item  {...formItemLayout2} name="NumberOfSIMSold" label="Mobile SIM Sold Count">

                                                        {getFieldDecorator('NumberOfSIMSold', {
                                                            initialValue: '',
                                                            rules: [{ required: true, message: 'Enter Mobile SIM Sold Count' }],
                                                        })(
                                                            <InputNumber style={{ width: '100%' }} min={1} max={20} placeholder="Amount Should Be a value between 1 - 20" />
                                                        )}

                                                    </Form.Item>

                                                </Col>

                                            </>
                                        )
                                    }
                                </Row>

                            </Form>

                        </Col>
                    </Row>
                </Modal>



            </>
        )
    }
}


const mapStateToProps = state => ({
    products: state.products,
    leadDetails: state.leads.leadDetails
});

const mapDispatchToProps = dispath => ({
    getMatchingProducts: (id) => dispath(getMatchingProducts(id)),
    getSavedLeadDetails: (id) => dispath(getSavedLeadDetails(id)),
    getProductDetails: (leadDetails) => dispath(getProductDetails(leadDetails)),
    updateLeadDetails: (leadDetails) => dispath(updateLeadDetails(leadDetails)),
    clearProducts: () => dispath(clearProducts()),
    clearLeads: () => dispath(clearAll()),
    resetAddress: () => dispath(resetAddress())
});

const WrappedBroadbandResult = Form.create()(BroadbandResult);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedBroadbandResult)