
import { Card, Col, Row, Button, Select, Radio, Form, Checkbox, Drawer } from "antd";
import React, { useState, useRef } from 'react'
import styles from '../BroadbandResult.module.less'

import isEmpty from "util/isEmpty";

const { Option } = Select;

const formItemLayout2 = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        style: { textAlign: "left" },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};



const FilterResultDrawer = (props) => {

    const { placement, onClose, width, visible, onSubmit, title, leadDetails, retailerList } = props;

    const { getFieldDecorator, getFieldsValue } = props.form

    const [form, setForm] = useState({})

    const formSubmitButton = useRef(null)

    const handleChange = (e) => {

        const name = e.target.name;
        const value = e.target.value;

        setForm({ ...form, [name]: value });

    }

    const handleSubmit = () => {

        formSubmitButton.current.click()

    }

    const handleFormSubmit = (e) => {

        e.preventDefault()

        const currentFormValues = getFieldsValue()

        const tempLeadDetails = { ...leadDetails }

        if (currentFormValues.Broaband_Retailers) {

            tempLeadDetails.comparisonDetails.broadbandDetails.Broaband_Retailers = currentFormValues.Broaband_Retailers
        }

        if (currentFormValues.activation_fee) {

            tempLeadDetails.leadDetails.activation_fee = { _text: currentFormValues.activation_fee }
        }
        if (currentFormValues.bb_discount) {

            tempLeadDetails.leadDetails.bb_discount = { _text: currentFormValues.bb_discount }
        }
        if (currentFormValues.broadbandUsageLevel) {

            tempLeadDetails.comparisonDetails.broadbandDetails.broadbandUsageLevel = currentFormValues.broadbandUsageLevel
        }
        if (currentFormValues.contract_type) {

            tempLeadDetails.leadDetails.contract_type = { _text: currentFormValues.contract_type }
        }
        if (currentFormValues.download_speed) {

            tempLeadDetails.leadDetails.download_speed = { _text: currentFormValues.download_speed }
        }

        if (currentFormValues.home_phone_plan) {

            tempLeadDetails.leadDetails.home_phone_plan = { _text: currentFormValues.home_phone_plan }
        }

        if (currentFormValues.importantFeature) {

            tempLeadDetails.comparisonDetails.broadbandDetails.importantFeature = currentFormValues.importantFeature
        }

        if (currentFormValues.internetUsers) {

            tempLeadDetails.comparisonDetails.broadbandDetails.internetUsers = currentFormValues.internetUsers
        }

        if (currentFormValues.plan_eligibility) {

            tempLeadDetails.leadDetails.plan_eligibility = { _text: currentFormValues.plan_eligibility }
        }

        onSubmit(tempLeadDetails);

        onClose()

    }

    return (
        <Drawer
            title={title}
            placement={placement || 'right'}
            onClose={onClose}
            visible={visible}
            className={'drawer'}
            bodyStyle={{ paddingBottom: 80 }}


        >
            <div className={styles.DrawerContainer}>

                <Row gutter={[0, 16]} style={{ marginTop: '2rem' }}>

                    <Form
                        name="basic"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        onSubmit={handleFormSubmit}
                    >

                        <div className={styles.customContainer}>

                            <Row gutter={[0, 16]} style={{ paddingLeft: '20px' }}>

                                <Col xs={24}>

                                    {!isEmpty(retailerList) && <Form.Item  {...formItemLayout2} name="Broaband_Retailers" label=" Filter by Retailers" rules={[{ required: true }]}>

                                        {getFieldDecorator('Broaband_Retailers', {
                                            initialValue: !isEmpty(leadDetails.comparisonDetails.broadbandDetails.Broaband_Retailers) ? leadDetails.comparisonDetails.broadbandDetails.Broaband_Retailers : retailerList,
                                            rules: [{ required: true, message: 'Select Retailers' }],
                                        })(
                                            <Select
                                                mode="multiple"
                                                style={{ width: '60%' }}
                                                placeholder="Please select"
                                                defaultValue={retailerList}
                                                // onChange={handleChange}
                                            >
                                                <Option key={1} value={"Tangerine"}>{"Tangerine"}</Option>
                                                <Option key={2} value={"More"}>{"More"}</Option>
                                                <Option key={3} value={"Origin Energy"}>{"Origin Energy"}</Option>
                                                <Option key={4} value={"Aussie Broadband"}>{"Aussie Broadband"}</Option>
                                            </Select>
                                        )}

                                    </Form.Item>}

                                </Col>

                                <Col xs={24}>

                                    <Form.Item  {...formItemLayout2} name="importantFeature" label=" What is the most important feature to you when it comes to the internet?" rules={[{ required: true }]}>

                                        {getFieldDecorator('importantFeature', {
                                            initialValue: leadDetails.comparisonDetails.broadbandDetails.importantFeature,
                                            rules: [{ required: true, message: 'Select Feature!' }],
                                        })(

                                            <Radio.Group size="large" optionType="button" buttonStyle="solid">
                                                <Radio.Button value="speed">Speed</Radio.Button>
                                                <Radio.Button value="price">Price</Radio.Button>
                                            </Radio.Group>
                                        )}

                                    </Form.Item>

                                </Col>

                                <Col xs={24}>

                                    <Form.Item  {...formItemLayout2} name="broadbandUsageLevel" label="On a daily basis what is your level of internet usage?">

                                        {getFieldDecorator('broadbandUsageLevel', {
                                            initialValue: leadDetails.comparisonDetails.broadbandDetails.broadbandUsageLevel,
                                            rules: [{ required: true, message: 'Select Usage!' }],
                                        })(
                                            <Radio.Group size="large" optionType="button" buttonStyle="solid">
                                                <Radio.Button value="Low">Low</Radio.Button>
                                                <Radio.Button value="Medium">Medium</Radio.Button>
                                                <Radio.Button value="High">High</Radio.Button>
                                            </Radio.Group>
                                        )}

                                    </Form.Item>

                                </Col>

                                <Col xs={24}>

                                    <Form.Item  {...formItemLayout2} name="internetUsers" label=" How many people use the internet at your home?" rules={[{ required: true }]}>

                                        {getFieldDecorator('internetUsers', {
                                            initialValue: leadDetails.comparisonDetails.broadbandDetails.internetUsers,
                                            rules: [{ required: true, message: 'Select Rough Amount of Users!' }],
                                        })(
                                            <Radio.Group size="large" optionType="button" buttonStyle="solid">
                                                <Radio.Button value={1}>1</Radio.Button>
                                                <Radio.Button value={2}>2</Radio.Button>
                                                <Radio.Button value={3}>3</Radio.Button>
                                                <Radio.Button value={4}>4+</Radio.Button>
                                            </Radio.Group>

                                        )}

                                    </Form.Item>

                                </Col>

                                <Col xs={24}>

                                    <Form.Item  {...formItemLayout2} name="plan_eligibility" label="Plan Eligibility" rules={[{ required: true }]}>

                                        {getFieldDecorator('plan_eligibility', {
                                            initialValue: leadDetails.leadDetails.plan_eligibility ? leadDetails.leadDetails.plan_eligibility._text : 'all',
                                        })(
                                            <Radio.Group size="large" optionType="button" buttonStyle="solid">
                                                <Radio.Button value={'all'}> All</Radio.Button>
                                                <Radio.Button value={'New_Customer'}> New Customers</Radio.Button>
                                                <Radio.Button value={'CBA_Customer'}> CBA Customer</Radio.Button>
                                                <Radio.Button value={'Bundles_With_Elec_Gas'}> Bundled with Elec/Gas</Radio.Button>
                                            </Radio.Group>

                                        )}

                                    </Form.Item>

                                </Col>

                                <Col xs={24}>

                                    <Form.Item  {...formItemLayout2} name="contract_type" label="Contract Type" rules={[{ required: true }]}>

                                        {getFieldDecorator('contract_type', {
                                            initialValue: leadDetails.leadDetails.contract_type ? leadDetails.leadDetails.contract_type._text : 'all',
                                        })(
                                            <Radio.Group size="large" optionType="button" buttonStyle="solid">
                                                <Radio.Button value={'all'}> All</Radio.Button>
                                                <Radio.Button value={'Month_to_Month'}> Month to Month</Radio.Button>
                                                <Radio.Button value={'12_Month_Contract'}> 12 Month Contract</Radio.Button>
                                                <Radio.Button value={'24_Month_Contract'}>24 Month Contract</Radio.Button>
                                            </Radio.Group>

                                        )}

                                    </Form.Item>

                                </Col>


                                <Col xs={24}>

                                    <Form.Item  {...formItemLayout2} label="Download Speed">

                                        {getFieldDecorator('download_speed', {
                                            initialValue: leadDetails.leadDetails.download_speed ? leadDetails.leadDetails.download_speed._text : 'all',
                                        })(
                                            <Radio.Group size="large" optionType="button" buttonStyle="solid">
                                                <Radio.Button value={'all'}> All</Radio.Button>
                                                <Radio.Button value={'50_Mbps'}> Less than 50 Mbps</Radio.Button>
                                                <Radio.Button value={'50_100_Mbps'}> Greater than 50 Mbps and less than 100 Mbps</Radio.Button>
                                                <Radio.Button value={'100_200_Mbps'}>Greater than 100 Mbps and less than 200 Mbps</Radio.Button>
                                                <Radio.Button value={'200_Mbps'}>Greater than 200 Mbps</Radio.Button>
                                            </Radio.Group>

                                        )}

                                    </Form.Item>

                                </Col>


                                <Col xs={24}>

                                    <Form.Item  {...formItemLayout2} label="Discount">

                                        {getFieldDecorator('bb_discount', {
                                            initialValue: leadDetails.leadDetails.bb_discount ? leadDetails.leadDetails.bb_discount._text : 'all',
                                        })(
                                            <Radio.Group size="large" optionType="button" buttonStyle="solid">
                                                <Radio.Button value={'all'}> All</Radio.Button>
                                                <Radio.Button value={'With_Discounts'}> With Discount</Radio.Button>
                                                <Radio.Button value={'Without_Discount'}> Without Discount</Radio.Button>
                                            </Radio.Group>

                                        )}

                                    </Form.Item>

                                </Col>


                                <Col xs={24}>

                                    <Form.Item  {...formItemLayout2} label="Activation Fee">

                                        {getFieldDecorator('activation_fee', {
                                            initialValue: leadDetails.leadDetails.activation_fee ? leadDetails.leadDetails.activation_fee._text : 'all',
                                        })(
                                            <Radio.Group size="large" optionType="button" buttonStyle="solid">
                                                <Radio.Button value={'all'}> All</Radio.Button>
                                                <Radio.Button value={'No_Activation_Fee'}> No Activation Fee</Radio.Button>
                                            </Radio.Group>

                                        )}

                                    </Form.Item>

                                </Col>

                                <Col xs={24}>

                                    <Form.Item  {...formItemLayout2} label="Home Phone Plan">

                                        {getFieldDecorator('home_phone_plan', {
                                            initialValue: leadDetails.leadDetails.home_phone_plan ? leadDetails.leadDetails.home_phone_plan._text : 'all',
                                        })(
                                            <Radio.Group size="large" optionType="button" buttonStyle="solid">
                                                <Radio.Button value={'all'}> All</Radio.Button>
                                                <Radio.Button value={'With_Plan'}> WithPlan</Radio.Button>
                                                <Radio.Button value={'Without_Plan'}> Without Plan</Radio.Button>
                                            </Radio.Group>

                                        )}

                                    </Form.Item>

                                </Col>

                                <Col span={24} className="gx-text-right">

                                    <center>
                                        <input type="submit" ref={formSubmitButton} style={{ display: 'none' }} />
                                    </center>
                                </Col>

                            </Row>

                        </div>

                    </Form>

                </Row>
            </div>


            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                }}
            >
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} type="primary">
                    Submit
                </Button>
            </div>
        </Drawer>
    )
}


const WrappedBroadbandFilters = Form.create()(FilterResultDrawer);

export default WrappedBroadbandFilters
