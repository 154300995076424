import React from 'react'
import { Card, Col, Form, Input, Row, Button, Select, } from "antd";
import className from 'classnames'
import isEmpty from "util/isEmpty";

import styles from './styles.module.less';
import { stringToCapitalCase } from '../../../util/handleString';

const { Option } = Select;

const AddressSummary = (props) => {

    const { onComplete, address, goPrevious } = props
    const { getFieldDecorator } = props.form

    const defaultSelect = [
        { value: 'a', Label: 'A' }
    ]

    const handleSubmit = (e) => {

        e.preventDefault();

        props.form.validateFields((err, values) => {

            if (!err) {
                onComplete(values)
            }
        })
    }

    return (
        <Col span={24}>
            <Card className="gx-card" title={`Broadband Details for the address '${address.data.nbn.addressDetail.formattedAddress}'`} style={{ maxHeight: "550px", overflow: "hidden" }}>

                {
                    address && address.data && address.data.nbn && <Form
                        name="basic"
                        labelCol={{
                            xs: { span: 24 },
                            sm: { span: 24 },
                        }}
                        wrapperCol={{
                            xs: { span: 24 },
                            sm: { span: 24 },
                        }}
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <div className={className("gx-form-row0", styles.centerContainer)} >
                            <Col span={24}>

                                <Row>
                                    <Col sm={6} xs={24}>

                                        <h3 style={{textAlign:"center"}}>Service Status</h3>

                                        <Form.Item
                                            name="serviceStatus"
                                        >

                                            <Input size='large' value={stringToCapitalCase(address.data.nbn.servingArea.serviceStatus)} disabled />

                                        </Form.Item>


                                    </Col>
                                    <Col sm={6} xs={24}>

                                        <h3 style={{textAlign:"center"}}>Connection Type</h3>

                                        <Form.Item
                                            name="connectionType"
                                        >

                                            <Input size='large' value={address.data.nbn.addressDetail.id.includes("OPC") ? 'Opticomm' : 'NBN'} disabled />

                                        </Form.Item>

                                    </Col>
                                    <Col sm={6} xs={24}>

                                        <h3 style={{textAlign:"center"}}>Service Type</h3>

                                        <Form.Item
                                            name="serviceType"
                                        >

                                            <Input size='large' value={address.data.nbn.servingArea.serviceType ? address.data.nbn.servingArea.serviceType : 'None'} disabled />

                                        </Form.Item>

                                    </Col>
                                    <Col sm={6} xs={24}>

                                        <h3 style={{textAlign:"center"}}>Technology Type</h3>

                                        <Form.Item
                                            name="technologyType"
                                        >

                                            <Input size='large' value={address.data.nbn.servingArea.techType ? address.data.nbn.servingArea.techType : 'None'} disabled />

                                        </Form.Item>

                                    </Col>
                                    <Col sm={6} xs={24}>

                                        <h3 style={{textAlign:"center"}}>Development Cost*</h3>

                                        <Form.Item
                                            name="developmentCost"
                                        >

                                            <Input size='large' value={!isEmpty(address.data.nbn.addressDetail.zeroBuildCost) ? address.data.nbn.addressDetail.zeroBuildCost ? "No" : 'Yes' : "Unknown"} disabled />

                                        </Form.Item>

                                    </Col>
                                    <Col sm={6} xs={24}>

                                        <h3 style={{textAlign:"center"}}>Field Type</h3>

                                        <Form.Item
                                            name="greenFields "
                                        >

                                            <Input size='large' value={address.data.nbn.servingArea.serviceCategory ? stringToCapitalCase(address.data.nbn.servingArea.serviceCategory) : "N/A"} disabled />

                                        </Form.Item>


                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={24} xs={24}>
                                        *Please note that Development Cost may not be applicable to customer at all times, as the NBN/Opticomm databases may not be upto date. Always check this and confirm to customer on retailer signup portal.
                                    </Col>
                                </Row>









                            </Col>

                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>

                                <div className='contentCenter'>

                                    <Button onClick={goPrevious}>
                                        Previous
                                    </Button>


                                    <Button type="primary" htmlType="submit">
                                        Continue
                                    </Button>

                                </div>

                            </Form.Item>
                        </div>

                    </Form>
                }

            </Card>
        </Col >
    )
}

const WrappedAddressSummary = Form.create()(AddressSummary);

export default WrappedAddressSummary