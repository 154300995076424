import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';

export const saveMailingList = mailingList => ({
    type: 'SAVE_MAILING_LIST',
    mailingList,
});

export const saveRecordUpdateStatus = recordUpdateStatus => ({
    type: 'SAVE_RECORD_UPDATE_STATUS',
    recordUpdateStatus,
});

export const error = error => ({
    type: 'ERROR',
    error,
});

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const getMailingList = (data) => dispath =>{

    if(data !== undefined)
    {
        // axios.post(apidetails.API_URL + 'elecPostcodeDistributors/search', qs.stringify(data), config)
        // .then(res => {
        //     dispath(savePostcodeDistributorList(res.data.electricityPostcodeDistributors));
        // })
        // .catch(err => {
        //     dispath(error(err.message || 'ERROR'));
        // });
    }
    else
    {
        axios.get(apidetails.API_URL + 'mailingList/')
        .then(res => {
            dispath(saveMailingList(res.data.mailingList));
        })
        .catch(err => {
            dispath(error(err.message || 'ERROR'));
        });
    }    
}

export const updateMailingList = (data) => dispath =>{

    axios.post(apidetails.API_URL + 'mailingList/update', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.message || 'ERROR'));
    });  
}
