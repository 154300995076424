export const titles = [
    { value: "mr", label: "Mr" },
    { value: "mrs", label: "Mrs" },
    { value: "ms", label: "Ms" },
    { value: "lady", label: "Lady" },
    { value: "jr", label: "Jr" },
    { value: "lady", label: "Lady" },
    { value: "dr", label: "Dr" },
    { value: "prof", label: "Prof" },
    { value: "rev", label: "Rev" },
    { value: "sir", label: "Sir" },
];

export const idTypes = [
    { value: "driversLicense", label: "Drivers License" },
]

export const licenseIssuedState = [
    { value: "vic", label: "Victoria (VIC)" },
    { value: "sa", label: "South Australia (SA)" },
    { value: "nsw", label: "New South Wales (NSW)" },
    { value: "qld", label: "Queensland (QLD)" },
    { value: "act", label: "Australian Capital Territory" },
    { value: "nt", label: "North Territory" },
    { value: "wa", label: "Western Australia" },
    { value: "tas", label: "Tasmania" },
]

export const lifeSupportFuelType = [
    { value: "elec", label: "Electricity" },
    { value: "gas", label: "Gas" },
    { value: "elecAndGas", label: "Electricity & Gas" },
]

export const lifeSupportEquipmentType = [
    { value: "An Oxygen Concentrator", label: "An Oxygen Concentrator" },
    { value: "A kidney dialysis machine", label: "A kidney dialysis machine" },
    { value: "A chronic positive airways pressure respirator (CPAP)", label: "A chronic positive airways pressure respirator (CPAP)" },
    { value: "Cigler najjar syndrome phototherapy equipment", label: "Cigler najjar syndrome phototherapy equipment" },
    { value: "A ventilator for life support", label: "A ventilator for life support" },
    { value: "Other", label: "Other" },
]

export const concessionCardIssuer = [
    { value: "Centrelink", label: "Centrelink" },
    { value: "Department of Veteran Affairs", label: "Department of Veteran Affairs" },
    { value: "QLD Government", label: "QLD Government" },
]

export const concessionCardType = {
    Centrelink: [
        { value: "Pension Card", label: "Pension Card" },
        { value: "Healthcare Card", label: "Healthcare Card" }
    ],
    'Department of Veteran Affairs': [
        { value: "Gold TPI Card", label: "Gold TPI Card" },
        { value: "Gold War Widow Card", label: "Gold War Widow Card" },
        { value: "DVA Pensioner Card", label: "DVA Pensioner Card" },
    ],
    'QLD Government': [
        { value: 'QLD Government Seniors Card', label: 'QLD Government Seniors Card' }
    ]

}

export const floorType = [
    { value: "B - BASEMENT", label: "B - BASEMENT" },
    { value: "FL - FLOOR", label: "FL - FLOOR" },
    { value: "G - GROUND FLOOR", label: "G - GROUND FLOOR" },
    { value: "L - LEVEL", label: "L - LEVEL" },
    { value: "LB - LOBBY", label: "LB - LOBBY" },
    { value: "LG - LOWER GROUND FLOOR", label: "LG - LOWER GROUND FLOOR" },
    { value: "M - MEZZANINE", label: "M - MEZZANINE" },
    { value: "OD - OBSERVATION DECK", label: "OD - OBSERVATION DECK" },
    { value: "P - PARKING", label: "P - PARKING" },
    { value: "RT - ROOFTOP", label: "RT - ROOFTOP" },
    { value: "SB - SUB-BASEMENT", label: "SB - SUB-BASEMENT" },
    { value: "UG - UPPER GROUND FLOOR", label: "UG - UPPER GROUND FLOOR" },
]

export const unitType = [
    { value: 'APT', label: 'Apartment' },
    { value: 'CTGE', label: 'Cottage' },
    { value: 'DUP', label: 'Duplex' },
    { value: 'F', label: 'Flat' },
    { value: 'FY', label: 'Factory' },
    { value: 'HSE', label: 'House' },
    { value: 'KSK', label: 'Kiosk' },
    { value: 'MB', label: 'Marine berth' },
    { value: 'MSNT', label: 'Maisonette' },
    { value: 'OFF', label: 'Office' },
    { value: 'PTHS', label: 'Penthouse' },
    { value: 'RM', label: 'Room' },
    { value: 'SE', label: 'Suite' },
    { value: 'SHED', label: 'Shed' },
    { value: 'SHOP', label: 'Shop' },
    { value: 'SITE', label: 'Site' },
    { value: 'SL', label: 'Stall' },
    { value: 'STU', label: 'Studio' },
    { value: 'TNHS', label: 'Townhouse' },
    { value: 'U', label: 'Unit' },
    { value: 'VLLA', label: 'Villa' },
    { value: 'WARD', label: 'Ward' },
    { value: 'WE', label: 'Warehouse' }
]


export const StreetType = [
    { value: 'ACCS', label: 'Access' },
    { value: 'ALLY', label: 'Alley' },
    { value: 'ALWY', label: 'Alleyway' },
    { value: 'AMBL', label: 'Amble' },
    { value: 'ANCG', label: 'Anchorage' },
    { value: 'APP', label: 'Approach' },
    { value: 'ARC', label: 'Arcade' },
    { value: 'ART', label: 'Artery' },
    { value: 'AVE', label: 'Avenue' },
    { value: 'BASN', label: 'Basin' },
    { value: 'BCH', label: 'Beach' },
    { value: 'BDGE', label: 'Bridge' },
    { value: 'BDWY', label: 'Broadway' },
    { value: 'BEND', label: 'Bend' },
    { value: 'BLK', label: 'Block' },
    { value: 'BRAE', label: 'Brae' },
    { value: 'BRCE', label: 'Brace' },
    { value: 'BRK', label: 'Break' },
    { value: 'BROW', label: 'Brow' },
    { value: 'BVD', label: 'Boulevard' },
    { value: 'BYPA', label: 'Bypass' },
    { value: 'BYWY', label: 'Byway' },
    { value: 'CAUS', label: 'Causeway' },
    { value: 'CCT', label: 'Circuit' },
    { value: 'CDS', label: 'Cul-De-Sac' },
    { value: 'CH', label: 'Chase' },
    { value: 'CIR', label: 'Circle' },
    { value: 'CL', label: 'Close' },
    { value: 'CLDE', label: 'Colonnade' },
    { value: 'CLT', label: 'Circlet' },
    { value: 'CMMN', label: 'Common' },
    { value: 'CNR', label: 'Corner' },
    { value: 'CNWY', label: 'Centreway' },
    { value: 'CON', label: 'Concourse' },
    { value: 'COVE', label: 'Cove' },
    { value: 'COWY', label: 'Crossway' },
    { value: 'CPS', label: 'Copse' },
    { value: 'CRCS', label: 'Circus' },
    { value: 'CRD', label: 'Crossroad' },
    { value: 'CRES', label: 'Crescent' },
    { value: 'CRSG', label: 'Crossing' },
    { value: 'CRSS', label: 'Cross' },
    { value: 'CRST', label: 'Crest' },
    { value: 'CSO', label: 'Corso' },
    { value: 'CT', label: 'Court' },
    { value: 'CTR', label: 'Centre' },
    { value: 'CTTG', label: 'Cutting' },
    { value: 'CTYD', label: 'Courtyard' },
    { value: 'CUWY', label: 'Cruiseway' },
    { value: 'DALE', label: 'Dale' },
    { value: 'DELL', label: 'Dell' },
    { value: 'DEVN', label: 'Deviation' },
    { value: 'DIP', label: 'Dip' },
    { value: 'DR', label: 'Drive' },
    { value: 'DRWY', label: 'Driveway' },
    { value: 'DSTR', label: 'Distributor' },
    { value: 'EDGE', label: 'Edge' },
    { value: 'ELB', label: 'Elbow' },
    { value: 'END', label: 'End' },
    { value: 'ENT', label: 'Entrance' },
    { value: 'ESP', label: 'Esplanade' },
    { value: 'EST', label: 'Estate' },
    { value: 'EXP', label: 'Expressway' },
    { value: 'EXTN', label: 'Extension' },
    { value: 'FAWY', label: 'Fairway' },
    { value: 'FITR', label: 'Firetrail' },
    { value: 'FLAT', label: 'Flat' },
    { value: 'FOLW', label: 'Follow' },
    { value: 'FORM', label: 'Formation' },
    { value: 'FRNT', label: 'Front' },
    { value: 'FRTG', label: 'Frontage' },
    { value: 'FSHR', label: 'Foreshore' },
    { value: 'FTRK', label: 'Fire Track' },
    { value: 'FTWY', label: 'Footway' },
    { value: 'FWY', label: 'Freeway' },
    { value: 'GAP', label: 'Gap' },
    { value: 'GDN', label: 'Garden' },
    { value: 'GDNS', label: 'Gardens' },
    { value: 'GLD', label: 'Glade' },
    { value: 'GLEN', label: 'Glen' },
    { value: 'GLY', label: 'Gully' },
    { value: 'GR', label: 'Grove' },
    { value: 'GRA', label: 'Grange' },
    { value: 'GRN', label: 'Green' },
    { value: 'GRND', label: 'Ground' },
    { value: 'GTE', label: 'Gate' },
    { value: 'GTES', label: 'Gates' },
    { value: 'HILL', label: 'Hill' },
    { value: 'HRD', label: 'Highroad' },
    { value: 'HTS', label: 'Heights' },
    { value: 'HWY', label: 'Highway' },
    { value: 'INTG', label: 'Interchange' },
    { value: 'INTN', label: 'Intersection' },
    { value: 'JNC', label: 'Junction' },
    { value: 'value', label: 'value' },
    { value: 'LANE', label: 'Lane' },
    { value: 'LDG', label: 'Landing' },
    { value: 'LEES', label: 'Lees' },
    { value: 'LINE', label: 'Line' },
    { value: 'LINK', label: 'Link' },
    { value: 'LKT', label: 'Lookout' },
    { value: 'LNWY', label: 'Laneway' },
    { value: 'LOOP', label: 'Loop' },
    { value: 'LT', label: 'Little' },
    { value: 'LWR', label: 'Lower' },
    { value: 'MALL', label: 'Mall' },
    { value: 'MEW', label: 'Mew' },
    { value: 'MEWS', label: 'Mews' },
    { value: 'MNDR', label: 'Meander' },
    { value: 'MT', label: 'Mount' },
    { value: 'MWY', label: 'Motorway' },
    { value: 'NOOK', label: 'Nook' },
    { value: 'OTLK', label: 'Outlook' },
    { value: 'PARK', label: 'Park' },
    { value: 'PART', label: 'Part' },
    { value: 'PASS', label: 'Pass' },
    { value: 'PATH', label: 'Path' },
    { value: 'PDE', label: 'Parade' },
    { value: 'PHWY', label: 'Pathway' },
    { value: 'PIAZ', label: 'Piazza' },
    { value: 'PKLD', label: 'Parklands' },
    { value: 'PKT', label: 'Pocket' },
    { value: 'PKWY', label: 'Parkway' },
    { value: 'PL', label: 'Place' },
    { value: 'PLAT', label: 'Plateau' },
    { value: 'PLZA', label: 'Plaza' },
    { value: 'PNT', label: 'Point' },
    { value: 'PORT', label: 'Port' },
    { value: 'PROM', label: 'Promenade' },
    { value: 'PSGE', label: 'Passage' },
    { value: 'QDGL', label: 'Quadrangle' },
    { value: 'QDRT', label: 'Quadrant' },
    { value: 'QUAD', label: 'Quad' },
    { value: 'QY', label: 'Quay' },
    { value: 'QYS', label: 'Quays' },
    { value: 'RAMP', label: 'Ramp' },
    { value: 'RCH', label: 'Reach' },
    { value: 'RD', label: 'Road' },
    { value: 'RDGE', label: 'Ridge' },
    { value: 'RDS', label: 'Roads' },
    { value: 'RDSD', label: 'Roadside' },
    { value: 'RDWY', label: 'Roadway' },
    { value: 'RES', label: 'Reserve' },
    { value: 'REST', label: 'Rest' },
    { value: 'RGWY', label: 'Ridgeway' },
    { value: 'RIDE', label: 'Ride' },
    { value: 'RING', label: 'Ring' },
    { value: 'RISE', label: 'Rise' },
    { value: 'RMBL', label: 'Ramble' },
    { value: 'RND', label: 'Round' },
    { value: 'RNDE', label: 'Ronde' },
    { value: 'RNGE', label: 'Range' },
    { value: 'ROW', label: 'Row' },
    { value: 'ROWY', label: 'Right of way' },
    { value: 'RSBL', label: 'Rosebowl' },
    { value: 'BOWL', label: 'Bowl' },
    { value: 'RTE', label: 'Route' },
    { value: 'RTT', label: 'Retreat' },
    { value: 'RTY', label: 'Rotary' },
    { value: 'RUE', label: 'Rue' },
    { value: 'RUN', label: 'Run' },
    { value: 'RVR', label: 'River' },
    { value: 'RVRA', label: 'Riviera' },
    { value: 'RVWY', label: 'Riverway' },
    { value: 'SBWY', label: 'Subway' },
    { value: 'SDNG', label: 'Siding' },
    { value: 'SHWY', label: 'State highway' },
    { value: 'SLPE', label: 'Slope' },
    { value: 'SND', label: 'Sound' },
    { value: 'SPUR', label: 'Spur' },
    { value: 'SQ', label: 'Square' },
    { value: 'ST', label: 'Street' },
    { value: 'STPS', label: 'Steps' },
    { value: 'STRA', label: 'Strand' },
    { value: 'STRP', label: 'Strip' },
    { value: 'STRS', label: 'Stairs' },
    { value: 'SWY', label: 'Service way' },
    { value: 'TARN', label: 'Tarn' },
    { value: 'TCE', label: 'Terrace' },
    { value: 'THOR', label: 'Thoroughfare' },
    { value: 'TKWY', label: 'Trunkway' },
    { value: 'TLWY', label: 'Tollway' },
    { value: 'TOP', label: 'Top' },
    { value: 'TOR', label: 'Tor' },
    { value: 'TRI', label: 'Triangle' },
    { value: 'TRK', label: 'Track' },
    { value: 'TRL', label: 'Trail' },
    { value: 'TRLR', label: 'Trailer' },
    { value: 'TURN', label: 'Turn' },
    { value: 'TWRS', label: 'Towers' },
    { value: 'UPAS', label: 'Underpass' },
    { value: 'UPR', label: 'Upper' },
    { value: 'VALE', label: 'Vale' },
    { value: 'VDCT', label: 'Viaduct' },
    { value: 'VIEW', label: 'View' },
    { value: 'VLLS', label: 'Villas' },
    { value: 'VSTA', label: 'Vista' },
    { value: 'WADE', label: 'Wade' },
    { value: 'WALK', label: 'Walk' },
    { value: 'WAY', label: 'Way' },
    { value: 'WHRF', label: 'Wharf' },
    { value: 'WKWY', label: 'Walkway' },
    { value: 'WYND', label: 'Wynd' },
    {
        value: 'YARD', label: 'Yard'
    }
]

export const streetTypeSuffix = [
    { value: 'CN', label: 'CENTRAL' },
    { value: 'DE', label: 'DEVIATION' },
    { value: 'E', label: 'EAST' },
    { value: 'EX', label: 'EXTENSION' },
    { value: 'IN', label: 'INNER' },
    { value: 'LR', label: 'LOWER' },
    { value: 'ML', label: 'MALL' },
    { value: 'N', label: 'NORTH' },
    { value: 'NE', label: 'NORTH EAST' },
    { value: 'NW', label: 'NORTH WEST' },
    { value: 'OFF', label: 'OFF' },
    { value: 'ON', label: 'ON' },
    { value: 'OP', label: 'OVERPASS' },
    { value: 'OT', label: 'OUTER' },
    { value: 'S', label: 'SOUTH' },
    { value: 'SE', label: 'SOUTH EAST' },
    { value: 'SW', label: 'SOUTH WEST' },
    { value: 'UP', label: 'UPPER' },
    { value: 'W', label: 'WEST' }
]

export const states = [
    { value: 'VIC', label: 'Victoria (VIC)' },
    { value: 'SA', label: 'South Australia (SA)' },
    { value: 'NSW', label: 'New South Wales (NSW)' },
    { value: 'QLD', label: 'Queensland (QLD)' },
    { value: 'ACT', label: 'Australian Capital Territory (ACT)' },
    { value: 'NT', label: 'Northern Territory (NT)' },
    { value: 'WA', label: 'Western Australia (WA)' },
    { value: 'TAS', label: 'Tasmania (TAS)' }
]