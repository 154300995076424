import axios from 'axios';
import qs from 'querystring';
import Papa from 'papaparse';
import * as apidetails from 'util/api';

const actionTypePrefix = "DNCREGISTER";
const APIPath = "dncRegister";

export const saveRecords = resultList => ({
    type: `SAVE_${actionTypePrefix}_RECORDS`,
    resultList,
});

export const saveRecordUpdateStatus = recordUpdateStatus => ({
    type: 'SAVE_RECORD_UPDATE_STATUS',
    recordUpdateStatus,
});

export const error = error => ({
    type: 'ERROR',
    error,
});

const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export const getRecords = () => dispath =>{

    axios.get(apidetails.API_URL + `${APIPath}/`)
    .then(res => {
        dispath(saveRecords(res.data.result));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const searchRecords = (data) => dispath =>{
    axios.post(apidetails.API_URL + `${APIPath}/` + 'search', qs.stringify(data), config)
    .then(res => {
        dispath(saveRecords(res.data.result));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const updateSingleRecord = (data) => dispath =>{

    axios.put(apidetails.API_URL + `${APIPath}/`, qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const createRecord = (data) => dispath =>{

    axios.post(apidetails.API_URL + `${APIPath}/`, qs.stringify(data), config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const uploadRecords = (data) => dispath =>{

    config.headers = {
        'Content-Type': 'multipart/form-data',
        'timeout': 600000
    }

    axios.post(apidetails.API_URL + `${APIPath}/` + 'upload', data, config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.error || 'ERROR'));
    });  
}

export const wipeAndUploadRecords = (data) => dispath =>{

    config.headers = {
        'Content-Type': 'multipart/form-data',
        'timeout': 600000
    }

    axios.post(apidetails.API_URL + `${APIPath}/` + 'wipeUpload', data, config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.error || 'ERROR'));
    });  
}

export const deleteRecord = (idToDelete) => dispath =>{

    axios.delete(apidetails.API_URL + `${APIPath}/${idToDelete}`, config)
    .then(res => {
        dispath(saveRecordUpdateStatus(res.data));
        dispath(saveRecordUpdateStatus(null));
    })
    .catch(err => {
        dispath(error(err.response.data.message || 'ERROR'));
    });  
}

export const clearError = () => dispath =>{
    dispath(error(''));
}