import React from "react";
import {Route, Switch} from "react-router-dom";
import SalesReports from "./SalesReports";

const Sales = ({match}) => (
  <Switch>
    <Route path={`${match.url}/salesReports`} component={SalesReports}/>
  </Switch>
);

export default Sales;
