import React from "react";
import {Route, Switch} from "react-router-dom";

import Admin from "./admin/index";
import Home from "./home/index";
import Sales from "./sales/index";
import Tools from "./tools/index";
import Leads from "./leads/index";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}home`} component={Home}/>
      <Route path={`${match.url}sales`} component={Sales}/>
      <Route path={`${match.url}admin`} component={Admin}/>
      <Route path={`${match.url}tools`} component={Tools}/>
      <Route path={`${match.url}leads`} component={Leads}/>
    </Switch>
  </div>
);

export default App; 
