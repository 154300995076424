const initialState = {
    leadDetailsBroadband: null,
    recordUpdateStatus: null,
    error: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case 'SAVE_SINGLE_LEAD_DETAILS_BROADBAND':
            return {
                ...state,
                leadDetailsBroadband: action.leadDetailsBroadband
            };
        case 'ERROR':
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}